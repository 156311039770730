import React, {Component} from 'react';
import {Row, Col, Input, Checkbox, Select, Empty} from 'antd';
import * as _ from 'lodash';

const Option = Select.Option;
class TabProperty extends Component {

    selectAllProperties = () => {
        this.props.onCheckAllSkuItem();
    };

    onCheckProperty = (index, e) => {
        let checked = e.target.checked;
        this.props.onCheckSkuItem(index, checked)
    };

    render() {
        let {attrItems, skuItems, t, onChangeAttrKey, onChangeAttrValue} = this.props;
        let chunkAttrItems = _.chunk(skuItems, 6);
        let countChecked = _.countBy(skuItems, 'checked')['true'];
        let isCheckAll = countChecked === skuItems.length;
        const { getFieldDecorator } = this.props.form;
        return (
            <>
                <div className="product-properties-container">
                    <Row gutter={20} className={"mg-bt-10"}>
                        <Col span={8}>
                            <span className="bold-400">{t("products.properties")}</span>
                        </Col>
                        <Col span={16}>
                            <span className="bold-400">{t("add_product_popup.detail_property")}</span>
                        </Col>
                    </Row>
                    {attrItems.map((item, index) => (
                        <Row key={index} gutter={20} className={"mg-bt-15"}>
                            <Col span={8}>
                                <Input
                                    value={item.key}
                                    placeholder={t("products.enter_attr_key")}
                                    className={`_product_attr_key _product_attr_key_${index}`}
                                    onChange={onChangeAttrKey.bind(this, index)}
                                />
                            </Col>
                            <Col span={16}>
                                <Select
                                    notFoundContent={<Empty description={t("table.empty")} />}
                                    placeholder={t("products.enter_attr_value")}
                                    className={`_product_attr_value _product_attr_value_${index}`}
                                    mode="tags"
                                    style={{width: "100%"}}
                                    tokenSeparators={[',',';']}
                                    value={item.attr}
                                    disabled={!item.key}
                                    allowClear={true}
                                    onChange={onChangeAttrValue.bind(this, index)}
                                />
                            </Col>
                        </Row>
                    ))}
                </div>

                <div className="properties-generated-container">
                    {skuItems.length > 0 && (
                        <div className="check-all">
                            <label className="pointer">
                                <Checkbox className="_check_all" checked={isCheckAll} onChange={this.selectAllProperties}>
                                    <span className="mg-l-15">
                                        {t("products.select_all")} {skuItems.length} {t("products.properties").toString().toLowerCase()}
                                    </span>
                                </Checkbox>
                            </label>
                        </div>
                    )}
                    <Row gutter={20}>
                        {chunkAttrItems.map((items, index) => (
                            <Col span={12} key={index}>
                                {items.map((item) => (
                                    <div className="property-generate-item" key={item.display+index}>
                                        <Checkbox
                                            checked={item.checked}
                                            onChange={this.onCheckProperty.bind(this, item.id)}
                                            className={`property-generate-item-checkbox _product_attr_checkbox_${index}`}
                                        >
                                            <span className={`property-generate-item-name pd-l-27 _product_attr_display_${index}`}>{item.display}</span>
                                        </Checkbox>
                                    </div>
                                ))}
                            </Col>
                        ))}
                    </Row>
                </div>
            </>
        )
    }
}

export {TabProperty};