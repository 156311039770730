import {LocalStore} from "./LocalStore";
import {objectGet} from "./helper";

export function checkTokenEx(createdAt, ttl) {
    let oneDay = 24 * 60 * 60 * 1000;
    let currentDate = new Date();
    let createdDay = new Date(createdAt);
    let deadDay = new Date(createdDay.getTime() + ttl * 1000);
    return (deadDay.getTime() - currentDate.getTime()) / (oneDay);
}

export function getLoggedId() {
    let ss = LocalStore.getInstance().read('loginSession');
    return objectGet(ss, 'userId');
}
