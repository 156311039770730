import React from 'react';
import {Row, Col, Icon, Select} from 'antd';
import {objectGet} from "../../../utils/helper";
import {QuickEditName} from "./QuickEditName";
import {toastr} from "../../../utils/toastr";
import {QuickEditAddress} from "./QuickEditAddress";
import {QuickEditDescription} from "./QuickEditDescription";
import {QuickEditStatus} from "./QuickEditStatus";

class BasicInfo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isEditStatus: false,
            isEditName: false,
            statuses: [
                {label: "ACTIVE", key: "ACTIVE"},
                {label: "INACTIVE", key: "INACTIVE"}
            ]
        }
    }

    toggleEditName = () => {
        this.setState({isEditName: !this.state.isEditName});
    };

    toggleEditStatus = () => {
        this.setState({isEditStatus: !this.state.isEditStatus});
    };

    toggleEditAddress = () => {
        this.setState({isEditAddress: !this.state.isEditAddress});
    };

    toggleEditDescription = () => {
        this.setState({isEditDescription: !this.state.isEditDescription});
    };

    onUpdateName = (error, response) => {
        const {t} = this.props;
        if(!error) {
            toastr.success(t('message.success'));
            this.toggleEditName();
            this.props.fetchWarehouse();
        } else {
            toastr.error(error.message);
        }
    };

    onUpdateStatus = (error, response) => {
        const {t} = this.props;
        if(!error) {
            toastr.success(t('message.success'));
            this.toggleEditStatus();
            this.props.fetchWarehouse();
        } else {
            toastr.error(error.message);
        }
    };

    onUpdateAddress = (error, response) => {
        const {t} = this.props;
        if(!error) {
            toastr.success(t('message.success'));
            this.toggleEditAddress();
            this.props.fetchWarehouse();
        } else {
            toastr.error(error.message);
        }
    };

    onUpdateDescription = (error, response) => {
        const {t} = this.props;
        if(!error) {
            toastr.success(t('message.success'));
            this.toggleEditDescription();
            this.props.fetchWarehouse();
        } else {
            toastr.error(error.message);
        }
    };

    render() {
        const {t, warehouse, loading} = this.props;
        const {statuses, isEditName, isEditStatus, isEditAddress, isEditDescription} = this.state;
        const formItem = (options) => {
            const {label, control, className} = options;
            return (
                <div className={`form-item ${className}`}>
                    <label className="form-item-label">{label}</label>
                    <div className="form-item-control">{control}</div>
                </div>
            );
        };
        const getDescription = () => {
            let desc = warehouse.description;
            if(desc) {
                desc = desc.toString().replace(/(?:\r\n|\r|\n)/g, '<br>');
            }
            return desc;
        };

        let controlName = warehouse.name;
        let controlStatus = <span className="text">{!loading && t(`warehouse.${warehouse.status}`)}</span>;
        let controlAddress = <span className="text-wrap">{warehouse.address}</span>;
        let controlDescription = <span className="text-wrap" dangerouslySetInnerHTML={{__html: getDescription()}} />;
        if(isEditName) {
            controlName = <QuickEditName
                {...this.props}
                onUpdate={this.onUpdateName}
            />
        }

        if(isEditStatus) {
            controlStatus = (
                <QuickEditStatus
                    {...this.props}
                    statuses={statuses}
                    onUpdate={this.onUpdateStatus}
                />
            );
        }

        if(isEditAddress) {
            controlAddress = (
                <QuickEditAddress
                    {...this.props}
                    onUpdate={this.onUpdateAddress}
                />
            )
        }

        if(isEditDescription) {
            controlDescription = (
                <QuickEditDescription
                    {...this.props}
                    onUpdate={this.onUpdateDescription}
                />
            )
        }

        return (
            <div className="warehouse-basic-info">
                <div className="white-box">
                    <Row gutter={20}>
                        <Col span={24}>
                            {formItem({
                                label: (
                                    <>
                                        {t('warehouse.name')}&nbsp;
                                        <Icon className="icon-edit _icon_edit_name" type="edit" onClick={this.toggleEditName}/>
                                    </>
                                ),
                                control: controlName,
                                className: 'name _warehouse_name'
                            })}
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col lg={6} xl={4}>
                            {formItem({
                                label: t('warehouse.code'),
                                control: warehouse.code,
                                className: '_warehouse_code'
                            })}
                        </Col>
                        <Col lg={6} xl={4}>
                            {formItem({
                                label: <>{t('warehouse.status')} <Icon className="icon-edit _icon_edit_status" type="edit" onClick={this.toggleEditStatus} /></>,
                                control: controlStatus,
                                className: `status ${warehouse.status} _warehouse_status`
                            })}
                        </Col>
                        <Col lg={6} xl={4}>
                            {formItem({
                                label: t('warehouse.type'),
                                control: !loading && t(`warehouse.${warehouse.type}`),
                                className: '_warehouse_type'
                            })}
                        </Col>
                        <Col lg={6} xl={4}>
                            {formItem({
                                label: t('warehouse.vendor'),
                                control: objectGet(warehouse, 'vendor.name'),
                                className: '_warehouse_vendor'
                            })}
                        </Col>
                        <Col lg={6} xl={4}>
                            {formItem({
                                label: <>{t('warehouse.address')} <Icon className="icon-edit _icon_edit_address" type="edit" onClick={this.toggleEditAddress} /></>,
                                control: controlAddress,
                                className: '_warehouse_address'
                            })}
                        </Col>
                        <Col lg={6} xl={4}>
                            {formItem({
                                label: <>{t('warehouse.description')} <Icon className="icon-edit _icon_edit_description" type="edit" onClick={this.toggleEditDescription} /></>,
                                control: controlDescription,
                                className: "_warehouse_description"
                            })}
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export {BasicInfo};