import React, {Component} from 'react';
import {FormAddEvent} from "./FormAddEvent";
import moment from 'moment';
import lodash from 'lodash';
import {Spin} from "antd";

class PopupAddEvent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            giftForm: []
        };
    }

    handleCancel = () => {
        this.props.showPopupAddEvent(false);
        this.formRef.props.form.resetFields();
        this.formRef.clearState();
    };

    getItemData = (data) => {
        let list = data;
        let orderItemsArr = [];
        for (let i = 0; i < list.length; i++) {
            for (let j = 0; j < list[i].productPrice.length; j++) {
                if(list[i].productPrice[j].selected){
                    orderItemsArr.push({
                        sku: list[i].productPrice[j].sku,
                        quantity: list[i].productPrice[j].configWholeSaleDisplay[0].quantity,
                        unitPrice: list[i].productPrice[j].configWholeSaleDisplay[0].unitPrice,
                    })
                }
            }
        }
        return orderItemsArr
    };

    handleCreate = () => {
        const {t} = this.props;
        const form = this.formRef.props.form;
        form.validateFields((err, values) => {
            if(!err) {
                let note = values.note?values.note:'';
                if(values.reason){
                    if (values.reason === "OTHER") {
                        note = values.other
                    }else{
                        note = t(`order_status_cancelled.${values.reason}`)
                    }
                }

                if([1,8].indexOf(values.status) >= 0){
                    let orderItemsArr = this.getItemData(this.props.listProductSku);

                    let address = (values.address) ? values.address.replace(/\n/g, "/") : '';
                    address = address.replace(/,/g, "/");
                    let payload = {
                        callStatusId: values.status ? parseInt(values.status, 10) : '',
                        callTime: moment().format(),
                        appointmentAt: values.appointment ? moment(values.appointment).format() : null,
                        note: note,
                        deliveryDate: (values.deliveryAt) ? moment(values.deliveryAt).format() : null,
                        phoneContactId: this.props.contact.id,
                        campaignId: this.props.contact.campaignId,
                        productId: this.props.contact.productId,
                        address: address,
                        items: orderItemsArr,
                        adsId: (values.ads) ? values.ads : '',
                        provinceId: (values.province) ? parseInt(values.province, 10) : '',
                        cityId: (values.city) ? parseInt(values.city, 10) : '',
                        orderData: {
                            campaignId: this.props.contact.campaignId,
                            productId: this.props.contact.productId,
                            address: address,
                            note: (values.orderNote) ? values.orderNote : '',
                            items: orderItemsArr,
                            provinceId: (values.province) ? parseInt(values.province, 10) : '',
                            cityId: (values.city) ? parseInt(values.city, 10) : '',
                            districtId: (values.areaId) ? parseInt(values.areaId, 10) : '',
                            districtName: values.areaName,
                            courierServiceId: (values.courierId) ? parseInt(values.courierId, 10) : '',
                            subStatus: 'OTHER',
                            cancelledReason: '',
                            deliveryFee: parseFloat(lodash.get(values, 'deliveryFee', 0)) || 0,
                            collectedCod: parseFloat(lodash.get(values, 'collectedCod', 0)) || 0,
							postalCode: values.postalCode
                        },
                        doNotNeedToKeepFollowing: this.formRef.state.doNotNeedToKeepFollowing,
                    };

                    // if (this.formRef.state.orderId) {
                    //     payload.orderData.orderId = this.formRef.state.orderId;
                    // }

                    if (this.state.giftForm.length) {
                        const validGift = this.state.giftForm.filter(item => item.quantity > 0);
                        if (validGift.length) {
                            payload.gifts = [...validGift];
                        }
                    }

                    this.props.handleAddEvent(payload)

                }else{
                    let payload = {
                        callStatusId: values.status ? parseInt(values.status, 10) : '',
                        callTime: moment().format(),
                        appointmentAt: values.appointment ? moment(values.appointment).format() : null,
                        note: note,
                        phoneContactId: this.props.contact.id,
                        adsId: (this.formRef.state.adId) ? this.formRef.state.adId : '',
                        doNotNeedToKeepFollowing: this.formRef.state.doNotNeedToKeepFollowing,
                    };

                    // if (values.status === 2) {
                    //     payload.orderData = {
                    //         orderId: this.formRef.state.orderId,
                    //         subStatus: 'OTHER',
                    //         cancelledReason: note
                    //     };
                    // }

                    this.props.handleAddEvent(payload)
                }
            }
        });
    };

    saveFormRef = (formRef) => {
        this.formRef = formRef;
    };

    handleChangeGift = (values) => {
        this.setState({giftForm: values})
    }

    render() {
        const {loading} = this.props;
        return (
            <Spin spinning={loading}>
                <div>
                    <FormAddEvent
                        {...this.props}
                        wrappedComponentRef={this.saveFormRef}
                        onCancel={this.handleCancel}
                        onCreate={this.handleCreate}
                        onChangeGift={this.handleChangeGift}
                    />
                </div>
            </Spin>
        );
    }
}

export {PopupAddEvent}
