import React, {Component} from 'react';
import { Tabs , Tag } from 'antd';
import {translate} from 'react-i18next';
import {Product as TabProduct} from './Product';
import {Project as TabProject} from './Project';
import {Campaign as TabCampaign} from './Campaign';
import {Adset as TabAdset} from './Adset';
import {Ad as TabAd} from './Ad';
import StandardLayout from "../../components/StandardLayout";
import {Header} from "./Header";
import UserService from "../../services/UserService";
import {CAMPAIGNS_SCOPES} from "../../utils/api/Scopes";
import {getCurrentModule, getUrlQueryString} from "../../utils/helper";
import * as URI from 'urijs';

const TabPane = Tabs.TabPane;
class Product extends Component {

    constructor(props) {
        super(props);
        this.moduleName = 'campaigns';
        this.mounted = true;
        const {t} = this.props;
        this.state = {
            productId: [],
            projectId: [],
            campaignId: [],
            adId: [],
            adsetId: [],
            activeKey: UserService.isAllowed(CAMPAIGNS_SCOPES.CAMPAIGNS_PRODUCT_VIEW)?"PRODUCT":"PROJECT",
            countProduct: [],
            countProject: [],
            countCampaign: [],
            countAdset: [],
            countAd : [],
            tabs: {
                product: t("product_list.tab_name"),
                project: t("project.title"),
                campaign: t("campaign.title"),
                adset: t("ads_group_list.tab_name"),
                ad: t("product_list.count_ad")
            },
        };

    }

    componentWillMount() {
        if (!UserService.isAllowed(CAMPAIGNS_SCOPES.CAMPAIGNS_VIEW) && !UserService.isAllowed(CAMPAIGNS_SCOPES.CAMPAIGNS_PRODUCT_VIEW)) {
            this.props.history.replace('/403')
        }
    }

    componentDidMount() {
        this.mounted = true;
        this.processFromQueryString();
        window.addEventListener("popstate", this.onPopState);
    }

    componentWillUnmount() {
        this.mounted = false;
        window.removeEventListener("popstate", this.onPopState);
    }

    onPopState = () => {
        if(this.mounted && getCurrentModule() === this.moduleName) {
            this.processFromQueryString();
        }
    };

    getQSObject() {
        let queryString = getUrlQueryString();
        let queryStringObject = URI.parseQuery(queryString);
        return queryStringObject;
    }

    processFromQueryString() {
        let queryStringObject = this.getQSObject();

        if(!!Object.keys(queryStringObject).length){
            if(queryStringObject.tab){
                let checkTab = ["PRODUCT","PROJECT","CAMPAIGN","ADSET","AD"];
                if(checkTab.indexOf(queryStringObject.tab) > -1){
                    if(queryStringObject.tab === "PRODUCT"){
                        if(UserService.isAllowed(CAMPAIGNS_SCOPES.CAMPAIGNS_PRODUCT_VIEW)){
                            this.setState({
                                activeKey: queryStringObject.tab
                            })
                        }else{
                            this.setState({
                                activeKey: "PROJECT"
                            })
                        }
                    }else{
                        if(UserService.isAllowed(CAMPAIGNS_SCOPES.CAMPAIGNS_VIEW)){
                            this.setState({
                                activeKey: queryStringObject.tab
                            })
                        }else{
                            this.setState({
                                activeKey: "PRODUCT"
                            })
                        }
                    }
                }else{
                    this.setState({
                        activeKey: UserService.isAllowed(CAMPAIGNS_SCOPES.CAMPAIGNS_PRODUCT_VIEW)?"PRODUCT":"PROJECT"
                    })
                }
            }else{
                this.setState({
                    activeKey: UserService.isAllowed(CAMPAIGNS_SCOPES.CAMPAIGNS_PRODUCT_VIEW)?"PRODUCT":"PROJECT"
                })
            }
        }else{
            this.setState({
                activeKey: UserService.isAllowed(CAMPAIGNS_SCOPES.CAMPAIGNS_PRODUCT_VIEW)?"PRODUCT":"PROJECT"
            })
        }
    }

    onChangeTab = (key) => {
        let uri = new URI("?"+getUrlQueryString());
        let url = uri.setSearch("tab", key);
        this.props.history.push('/campaigns' + url);
    };

    getProductId = (productId) => {
        this.setState({
            productId: productId,
            projectId: [],
            campaignId: [],
            adsetId: [],
            countProduct: productId,
            countProject: [],
            countCampaign: [],
            countAdset: [],
            countAd: []
        })
    };

    getProjectId = (projectId) => {
        this.setState({
            projectId: projectId,
            campaignId: [],
            adsetId: [],
            countProject: projectId,
            countCampaign: [],
            countAdset: [],
            countAd: []
        })
    };

    getCampaignId = (campaignId) => {
        this.setState({
            campaignId: campaignId,
            adsetId: [],
            countCampaign: campaignId,
            countAdset: [],
            countAd: []
        })
    };

    getAdsetId = (adsetId) => {
        this.setState({
            adsetId: adsetId,
            countAdset: adsetId,
            countAd: []
        })
    };

    getAdId = (adId) => {
        this.setState({
            adId: adId,
            countAd: adId
        })
    };

    countTabSelect = (count,name,id) => {
        const {t} = this.props;
        if(this.state[count].length){
            return <><span>{this.state.tabs[name]}</span><Tag
                closable
                visible={this.state[count].length}
                style={{float:"right",marginTop:10,marginRight:0,paddingRight:0}}
                onClose={this.closeTab.bind(this,count,id)}
            >
                <span>{this.state[count].length} {t("tab.count_selected")}</span>
            </Tag></>
        }else{
            return this.state.tabs[name];
        }
    };

    closeTab = (count,id) => {
        this.setState({
            [count] : [],
            [id] : []
        })
    };

    render() {
        const {t} = this.props;
        return (
            <StandardLayout {...this.props} title={t("campaign.title")}>
                <div className="campaign-list-page list-page tab-container">
                    <Header {...this.props}/>
                    <Tabs
                        onChange={this.onChangeTab}
                        type="card"
                        tabBarStyle={{
                          marginBottom: 0,
                          marginTop: 30,
                          marginLeft: 29,
                        }}
                        activeKey={this.state.activeKey}
                    >
                        {UserService.isAllowed(CAMPAIGNS_SCOPES.CAMPAIGNS_PRODUCT_VIEW) && (
                            <TabPane tab={this.countTabSelect("countProduct","product","productId")} key="PRODUCT">
                                <TabProduct
                                    {...this.props}
                                    getProductId={this.getProductId}
                                    countProduct={this.state.countProduct}
                                />
                            </TabPane>
                        )}
                        {UserService.isAllowed(CAMPAIGNS_SCOPES.CAMPAIGNS_VIEW) && (
                            <TabPane tab={this.countTabSelect("countProject","project","projectId")} key="PROJECT">
                                <TabProject
                                    {...this.props}
                                    productId={this.state.productId}
                                    getProjectId={this.getProjectId}
                                    countProject={this.state.countProject}
                                />
                            </TabPane>
                        )}
                        {UserService.isAllowed(CAMPAIGNS_SCOPES.CAMPAIGNS_VIEW) && (
                            <TabPane tab={this.countTabSelect("countCampaign","campaign","campaignId")} key="CAMPAIGN">
                                <TabCampaign
                                    {...this.props}
                                    productId={this.state.productId}
                                    projectId={this.state.projectId}
                                    getCampaignId={this.getCampaignId}
                                    countCampaign={this.state.countCampaign}
                                />
                            </TabPane>
                        )}
                        {UserService.isAllowed(CAMPAIGNS_SCOPES.CAMPAIGNS_VIEW) && (
                            <TabPane tab={this.countTabSelect("countAdset","adset","adsetId")} key="ADSET">
                                <TabAdset
                                    {...this.props}
                                    productId={this.state.productId}
                                    projectId={this.state.projectId}
                                    campaignId={this.state.campaignId}
                                    getAdsetId={this.getAdsetId}
                                    countAdset={this.state.countAdset}
                                />
                            </TabPane>
                        )}
                        {UserService.isAllowed(CAMPAIGNS_SCOPES.CAMPAIGNS_VIEW) && (
                            <TabPane tab={this.countTabSelect("countAd","ad","adId")} key="AD">
                                <TabAd
                                    {...this.props}
                                    productId={this.state.productId}
                                    projectId={this.state.projectId}
                                    campaignId={this.state.campaignId}
                                    adsetId={this.state.adsetId}
                                    getAdId={this.getAdId}
                                    countAd={this.state.countAd}
                                />
                            </TabPane>
                        )}

                    </Tabs>
                </div>
            </StandardLayout>
        );
    }
}

export default (translate())(Product);