import React, {Component} from 'react';
import {Select} from 'antd';
import LocationService from "../../services/LocationService";

class SelectProvince extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            value: undefined,
            provinces: []
        }
    }

    static getDerivedStateFromProps(props, state) {
        let newState = {...state};
        if ('value' in props) {
            newState.value = props.value;
        }

        return newState;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ('countryId' in this.props && this.props.countryId !== prevProps.countryId && this.props.countryId > 0) {
            this.fetchProvinces();
        }
    }

    componentDidMount() {
        this.fetchProvinces();
    }

    fetchProvinces = () => {
        const {countryId} = this.props;
        if (countryId) {
            this.setState({
                loading: true,
                value: undefined
            });
            LocationService.getProvinceByCountry(countryId, (error, response) => {
                this.setState({loading: false});
                if (!error) {
                    this.setState({
                        provinces: response
                    });
                }
            });
        }
    };

    handleChange = (value) => {
        this.setState({value});
        this.props.onChange(value);
    };

    render() {
        const {provinces, loading, value} = this.state;
        const {selectProps} = this.props;
        const {t} = this.props;
        return (
            <Select
                {...selectProps}
                allowClear={true}
                showSearch={true}
                loading={loading}
                value={value}
                onChange={this.handleChange}
                className={'width-100pc'}
                placeholder={t("placeholder.select_province")}
            >
                {provinces.map(item => (
                    <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                ))}
            </Select>
        );
    }
}

SelectProvince.defaultProps = {
    selectProps: {},
    onChange: (values) => {}
};

export default SelectProvince;

