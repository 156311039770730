import React, {Component} from 'react';
import { Select, Col, Icon, Row, Table, Tag, Pagination, Popover, Tooltip } from 'antd';
import * as moment from 'moment';
import {Link} from "react-router-dom";


const Option = Select.Option;

class List extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedRows: 0
        };
    }

    getConfigRowSelection = () => {
        return {
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({selectedRows: selectedRows.length});
            }
        }
    };

    getConfigTableColumns = () => {
        const {t} = this.props;
        const renderColumn = (key, text, record) => {
            switch (key) {
                case 'createdAt':
                    return record.createdAt ? moment(record.createdAt).format("DD/MM/YY HH:mm") : "";
                case 'country':
                    return record.country ? <span className="country">{record.country.name}</span>: "";
                case 'package':
                    return record.package ? <span className="package">{record.package.name}</span>: "";
                case 'name':
                    return record.name ? <Link to={`/vendors/${record.id}`} className="name">{record.name}</Link>: "";
            }
        };
        return [
            {title: t("vendor.name"),dataIndex: 'name', key: 'name',className:"vendor-name _item",render: renderColumn.bind(this, 'name'),width:250},
            {title: t("vendor.code"),dataIndex: 'code', key: 'code',className:"code _item",width:150},
            {title: t("vendor.manager"),dataIndex: 'owner', key: 'owner',className:"owner _item",width:150},
            {title: t("vendor.country"),dataIndex: 'country', key: 'country', render: renderColumn.bind(this, 'country'),className:"country _item",width:150},
            {title: t("vendor.created_by"),dataIndex: 'createdBy', key: 'createdBy',className:"createdBy _item",width:150},
            {title: t("vendor.created_at"),dataIndex: 'createdAt', key: 'createdAt',render: renderColumn.bind(this, 'createdAt'),className:"createdAt _item",width:150},
            {title: t("vendor.source_package"),dataIndex: 'package', key: 'package',render: renderColumn.bind(this, 'package'),className:"package _item",width:150},

        ];
    };

    onCheckBox = (priority) => {
        this.props.isShowCheckbox(priority);
    };


    // onChange = (pagination, filters, sorter)=>{
    //     this.props.loadContact(this.props.currentPage,this.props.pageSize,sorter);
    // };

    render() {
        const {t} = this.props;
        const {dataSource,currentPage,totalItems,pageSize,onShowSizeChange,onChangePage,pageCount,loading} = this.props;
        const {selectedRows} = this.state;
        let columns = this.getConfigTableColumns();
        const rowSelection = this.getConfigRowSelection();

        return (
            <div className="list-container">
                {!loading && !!totalItems && (
                    <Row className="pd-bt-15">
                        <Col span={5}>
                            <span>{t("contact.page")} {currentPage}/{pageCount} ({totalItems})</span>
                        </Col>
                    </Row>
                )}

                {!loading && !totalItems && (
                    <Row className="pd-bt-15">
                        <Col span={5}>
                            <span>{t("contact.page")} {1}/{1} ({totalItems})</span>
                        </Col>
                    </Row>
                )}

                <div className="a-table a-table--responsive">
                    <Table
                        columns={columns}
                        dataSource={dataSource}
                        rowKey={record => record.id}
                        pagination={false}
                        rowSelection={rowSelection}
                        loading={loading}
                        onChange={this.onChange}
                        locale={{
                            emptyText: t("table.empty")
                        }}
                        scroll={{y: 550}}
                    />
                </div>
                <Pagination
                    className="mg-t-15 text-al-r"
                    showSizeChanger
                    onShowSizeChange={onShowSizeChange}
                    onChange={onChangePage}
                    pageSizeOptions={['25', '50', '75', '100']}
                    defaultCurrent={1}
                    current={currentPage}
                    pageSize={pageSize}
                    total={totalItems}
                />
            </div>
        );
    }
}

export {List}