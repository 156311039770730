import React, {Component} from 'react'
import DocumentTitle from "react-document-title"
import {Layout, Spin} from 'antd';

import {LocalStore} from "../utils/LocalStore";
import UserService from "../services/UserService";
import SideBar from "./SideBar";
import Header from "./Header";
import {tracker} from "../utils/openReplayTracker";

const { Content } = Layout;

class StandardLayout extends Component {

    static defaultProps = {
        loading: false
    };

    constructor(props) {
        super(props);
        let collapsed = false;
        if(window.screen.width <= 1024) {
            collapsed = true;
        }
        this.state = {
            collapsed: collapsed,
            user: {}
        };
    }

    onClickLogout = () => {
        UserService.logout();
        LocalStore.getInstance().save("fobizUrlBack", window.location.href);
        window.location.href = "/#/login";
    };

    componentDidMount = () => {
        UserService.showMe((error, me) => {
            if(!error) {
                this.setState({
                    user: me
                });

                LocalStore.getInstance().save("authUser", me);

                tracker.setUserID(me.username);
            }
        });
    };


    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    render() {
        const {loading, t} = this.props;
        const {collapsed, user} = this.state;
        let content = this.props.children;
        return (
            <DocumentTitle title={this.props.hasOwnProperty('title') && this.props.title !== undefined ? this.props.title : 'Poly Eagle'}>
                <Layout className={"main-layout"}>
                    <SideBar
                        collapsed={collapsed}
                        {...this.props}
                    />
                    <Layout>
                        <Header
                            collapsed={collapsed}
                            toggle={this.toggle}
                            user={user}
                            {...this.props}
                        />
                        <Content className={"main-content"}>
                            <div className={loading ? 'loading-container' : 'main-inner'}>
                                <Spin tip={t('message.loading')} spinning={loading}>
                                    {content}
                                </Spin>
                            </div>
                        </Content>
                    </Layout>
                </Layout>
            </DocumentTitle>
        )
    }
}

export default StandardLayout