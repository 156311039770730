import React, {Component} from 'react';
import {Pagination, Table} from 'antd';
import * as moment from 'moment';
import {Link} from "react-router-dom";
import lodash from 'lodash';
import LandingLeadService from "../../services/LandingLeadService";

class List extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            leads: [],
            total: 0,
            pageSize: 100,
            currentPage: 1
        };
    }

    componentDidMount = () => {
        this.getLeads({
            ...this.props.getFilterFromLocation()
        })
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(this.props.location.search) !== JSON.stringify(prevProps.location.search) ||
            JSON.stringify(prevProps.match.params) !== JSON.stringify(this.props.match.params)) {
            this.getLeads({
                ...this.props.getFilterFromLocation()
            })
        }
    }

    getLeads = (filter = {}) => {
        this.setState({loading: true});
        LandingLeadService.getLeads(filter, (error, response) => {
            this.setState({loading: false});
            if (!error) {
                this.setState({
                    leads: response.list,
                    currentPage: response.metadata.currentPage,
                    pageSize: response.metadata.pageSize,
                    total: response.count
                })
            }
        })
    }

    getConfigTableColumns = () => {
        const {t} = this.props;
        return [
            {title: t("contact.fullname"),dataIndex: 'name', key: 'name'},
            {title: t("contact.phone"),dataIndex: 'phone', key: 'phone'},
            {title: t("contact.note"),dataIndex: 'note', key: 'note'},
            {title: t("contact.address"),dataIndex: 'address', key: 'addresss'},
            {
                title: t("contact.created_at"),
                key: 'createdAt',
                render: (row) => {
                    return moment(row.createdAt).format("DD/MM/YYYY HH:mm:ss")
                }
            },
            {
                title: t("contact.lead"),
                key: "lead",
                render: (row) => {
                    return <Link target={'_blank'} to={'/contacts/' + lodash.get(row, 'lead.id')}>{row.validPhone}</Link>
                }
            }
        ];
    };

    handlePageChange = (page, pageSize) => {
        this.getLeads({page, pageSize});
    }

    render() {
        const {loading, total, currentPage, pageSize, leads} = this.state;
        const {t} = this.props;

        return (
            <div className="list-container">
                <div className="a-table a-table--responsive">
                    <Table
                        columns={this.getConfigTableColumns()}
                        dataSource={leads}
                        rowKey={record => record.id}
                        pagination={false}
                        loading={loading}
                        locale={{
                            emptyText: t("table.empty")
                        }}
                    />
                </div>

                {!loading && total > 0 && (
                    <Pagination
                        className="mg-t-15 text-r"
                        showSizeChanger
                        onShowSizeChange={this.handlePageChange}
                        onChange={this.handlePageChange}
                        pageSizeOptions={['50', '100', '150', '200']}
                        defaultCurrent={1}
                        current={currentPage}
                        pageSize={pageSize}
                        total={total}
                    />
                )}
            </div>
        );
    }
}

export {List}