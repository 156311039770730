import React, {Component} from 'react';
import {Button, Col, DatePicker, Empty, Icon, Input, Row, Select, Spin} from 'antd';
import * as moment from 'moment';
import * as _ from 'lodash';
import {getParamsAsObject, getUrlQueryString} from '../../utils/helper';
import UserService from "../../services/UserService";
import {DELIVERYCASE} from '../../utils/api/Scopes';


const Option = Select.Option;
const RangePicker = DatePicker.RangePicker;

class Filter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
            orderCode: '',
            deliveryCaseIds: [],
            createdAt: [],
            statusCodes:[],
            assigneeIds:[],
            vendorIds: [],
            countryIds: [],
            reasonIds: [],
			createdBy: [],
			users: [],
			trackingNumber: undefined
        };
        this.mounted = false;
        this.handleSearchUsers = _.debounce(this.handleSearchUsers, 1500)
    }

    onPopstate = () => {
        if (this.mounted) {
            this.setStateFromQueryString();
        }
    };

    componentWillUnmount() {
        this.mounted = false;
        window.removeEventListener("popstate", this.onPopstate.bind(this), false);
    }

    componentDidMount = () => {
    	this.fetchUsers()
        this.mounted = true;
        window.addEventListener("popstate", this.onPopstate.bind(this), false);
        this.setStateFromQueryString();
    };

    fetchUsers = (filter = {}) => {
		UserService.getUsers(filter, (error, response) => {
			this.setState({loading: false});
			if (!error) {
				this.setState({
					users: response.list
				});
			}
		});
	}

    setStateFromQueryString = () => {
        let queryObject = getParamsAsObject(getUrlQueryString());
        _.forEach(queryObject, (value, key) => {
            if (key === 'expanded') {
                this.setState({
                    [key]: value === 'true'
                })
            }

            if (key === 'order_code') {
                this.setState({
                    [_.camelCase(key)]: value
                })
            }

            if (key === 'delivery_case_ids') {
                this.setState({
                    [_.camelCase(key)]: value.split(',')
                })
            }

            if (key === 'created_at_from') {
                let createdAt = this.state.createdAt;
                createdAt[0] = moment(value);
                this.setState({ createdAt });
            }

            if (key === 'created_at_to') {
                let createdAt = this.state.createdAt;
                createdAt[1] = moment(value);
                this.setState({ createdAt });
            }

            if (key === 'assignee_ids') {
                this.setState({
                    assigneeIds: value.split(',').map(id => parseInt(id))
                })
            }

            if (key === 'created_by') {
				this.setState({
					createdBy: value.split(',')
				})
			}

            if (key === 'vendor_ids') {
                this.setState({
                    vendorIds: value.split(',')
                })
            }

            if (key === 'country_ids') {
                this.setState({
                    countryIds: value.split(',')
                })
            }

            if (key === 'reason_ids') {
                this.setState({
                    reasonIds: value.split(',')
                })
            }

            if  (key === 'status_codes') {
                this.setState({
                    statusCodes: value.split(',')
                })
            }

            if (key === 'tracking_number') {
            	this.setState({
					trackingNumber: value
				})
			}
        })
    };

    toggleFormFilter = () => {
        this.setState({
            expanded: !this.state.expanded
        }, () => {
            let uri = this.props.getUrlInstance();
            uri.replaceParam('expanded', this.state.expanded);
            this.props.pushQueryString(uri.getQueryString());
        });
    };

    onChangeInput = (key, e) => {
        this.setState({
            [key]: e.target.value.trim()
        });
    };

    onSelect = (key, value) => {

    };

    onChangeRangePicker = (key, ranges) => {
        this.setState({
            [key]: ranges
        });
    };

    onFilter = () => {
        let uri = this.props.getUrlInstance(true);
        if (this.state.orderCode) {
            uri.replaceParam('orderCode', this.state.orderCode);
        }
        if (this.state.deliveryCaseIds && this.state.deliveryCaseIds.length > 0) {
            uri.replaceParam('deliveryCaseIds', this.state.deliveryCaseIds.toString());
        }
        if (this.state.createdAt.length > 0) {
            uri.replaceParam('createdAtFrom', this.state.createdAt[0].startOf('date').toISOString());
            uri.replaceParam('createdAtTo', this.state.createdAt[1].endOf('date').toISOString());
        }
        if (this.state.statusCodes.length > 0) {
            uri.replaceParam('statusCodes', this.state.statusCodes.toString());
        }
        if (this.state.assigneeIds.length > 0){
            uri.replaceParam('assigneeIds', this.state.assigneeIds.toString());
        }
		if (this.state.createdBy.length > 0){
			uri.replaceParam('createdBy', this.state.createdBy.toString());
		}
        if(this.state.vendorIds.length > 0){
            uri.replaceParam('vendorIds', this.state.vendorIds.toString());
        }
        if(this.state.countryIds.length > 0){
            uri.replaceParam('countryIds', this.state.countryIds.toString());
        }
        if(this.state.reasonIds.length > 0){
            uri.replaceParam('reasonIds', this.state.reasonIds.toString());
        }
        if (this.state.trackingNumber) {
        	uri.replaceParam('trackingNumber', this.state.trackingNumber)
		}
        uri.replaceParam('expanded', this.state.expanded);
        uri.replaceParam('page', 1);
        this.props.pushQueryString(uri.getQueryString());
    };

    resetStateFilter = (cb = null) => {
        this.setState({
            orderCode: '',
            deliveryCaseIds: [],
            createdAt: [],
            statusCodes: [],
            assigneeIds: [],
            vendorIds: [],
            countryIds: [],
            reasonIds: [],
			createdBy: []
        }, cb);
    };

    clearFilter = () => {
        this.resetStateFilter(() => {
            this.props.pushQueryString(`?expanded=${this.state.expanded}&page=1`)
        });
    };

    handleChange = (key,value) => {
        this.setState({
            [key]: value
        })
    }

    onSelectChange = (key, value) => {
        if(key === 'assigneeIds' || key === 'countryIds' || key === 'createdBy'){
            this.setState({
                [key]: value
            })
        }
    }

	handleSearchUsers = (username) => {
		this.fetchUsers({username})
	}

    render() {
        const { t, deliveryCasesStatus, loadingUsersInVendor, vendors, loadingVendor, countries, loadingCountries, listReason, loadListReason, assignees } = this.props
        const { expanded, orderCode, deliveryCaseIds, createdAt, statusCodes, assigneeIds, vendorIds, countryIds, reasonIds, trackingNumber, createdBy, users } = this.state;
        return (
            <div className="contact-filter filter _order-filter">
                <div>
                    <Row gutter={20} className="form-filter">
                        <Col span={8} className="form-filter-control">
                            <label>{t("contact-detail.order_code")}</label>
                            <Input
                                className={"_filter_phone"}
                                placeholder={t("contact-detail.order_code")}
                                suffix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                value={orderCode}
                                onChange={this.onChangeInput.bind(this, 'orderCode')}
                                onPressEnter={this.onFilter}
                            />
                        </Col>

                        <Col span={8} className="form-filter-control">
                            <label>{t("vendor.country")}</label>
                            <Select
                                filterOption={false}
                                className={"_filter_campaign"}
                                placeholder={t("vendor.select_country")}
                                notFoundContent={(loadingCountries ? <Spin size="small" /> : <Empty description={t("table.empty")} />)}
                                mode="multiple"
                                style={{ width: "100%" }}
                                value={countryIds}
                                loading={loadingCountries}
                                onChange={this.onSelectChange.bind(this, 'countryIds')}
                            >
                                {
                                countries && countries.map(value => {
                                    return <Option key={value.id} value={`${value.id}`}>{value.name}</Option>
                                })
                                }
                            </Select>
                        </Col>

                        {
                            UserService.isAllowed(DELIVERYCASE.DELIVERY_CASE_VIEW_ALL) &&
                            <Col span={8} className="form-filter-control">
                                <label>{t("delivery_case.assignee")}</label>
                                <Select
                                    filterOption={false}
                                    className={"_filter_campaign"}
                                    placeholder={t("delivery_case.assignee")}
                                    notFoundContent={(loadingUsersInVendor ? <Spin size="small" /> : <Empty description={t("table.empty")} />)}
                                    mode="multiple"
                                    style={{ width: "100%" }}
                                    value={assigneeIds}
                                    loading={loadingUsersInVendor}
                                    onChange={this.onSelectChange.bind(this, 'assigneeIds')}
                                >
                                    {
										assignees && assignees.map(value => {
                                            return <Option key={_.get(value, 'assignee.id')} value={_.get(value, 'assignee.id')}>{_.get(value, 'username')}</Option>
                                        })
                                    }
                                </Select>
                            </Col>
                        }

                        <Col span={8} className="form-filter-control">
                            <label>{t("delivery_case.code")}</label>
                            <Select
                                mode="tags"
                                style={{ width: '100%' }}
                                placeholder={t("delivery_case.code")}
                                onChange={this.handleChange.bind(this,'deliveryCaseIds')}
                                value={deliveryCaseIds}
                            >
                                {
                                    deliveryCaseIds ? deliveryCaseIds.map((value, index) => <Option key={index} value={value}>{value}</Option>) : []
                                }
                            </Select>
                        </Col>

                        {(expanded === true) && (
                            <>

                                <Col span={8} className="form-filter-control">
                                    <label>{t("delivery_case.createdAt")}</label>
                                    <RangePicker
                                        placeholder={[t("delivery_case.date_from"), t("delivery_case.date_to")]}
                                        className={"_filter_created_at"}
                                        value={createdAt}
                                        onChange={this.onChangeRangePicker.bind(this, 'createdAt')}
                                        style={{ width: '100%' }}
                                    />
                                </Col>

                                <Col span={8} className="form-filter-control">
                                    <label>{t("delivery_case.status")}</label>
                                    <Select
                                        mode='multiple'
                                        filterOption={false}
                                        className={"_filter_product"}
                                        placeholder={t("delivery_case.status")}
                                        style={{ width: "100%" }}
                                        value={statusCodes}
                                        onChange={this.handleChange.bind(this, 'statusCodes')}
                                    >
                                        {
                                            deliveryCasesStatus.map((status) => {
                                                return <Option key={status.key} value={status.key}>{status.value}</Option>
                                            })
                                        }
                                    </Select>
                                </Col>

                                <Col span={8} className="form-filter-control">
                                    <label>{t("vendor.title")}</label>
                                    <Select
                                        mode="multiple"
                                        style={{ width: '100%' }}
                                        placeholder={t("vendor.title")}
                                        onChange={this.handleChange.bind(this, 'vendorIds')}
                                        value={vendorIds}
                                        loading={loadingVendor}
                                        showSearch
                                        filterOption={(inputValue, option) => {
                                            return option.props.children.toLowerCase().includes(inputValue.toLowerCase())
                                        }}
                                    >
                                        {
                                            vendors ? vendors.map((value, index) => <Option key={value.id} value={`${value.id}`}>{value.name}</Option>) : []
                                        }
                                    </Select>
                                </Col>

                                <Col span={8} className="form-filter-control">
                                    <label>{t("delivery_case.reason")}</label>
                                    <Select
                                        mode="multiple"
                                        style={{ width: '100%' }}
                                        placeholder={t("delivery_case.select_reason")}
                                        onChange={this.handleChange.bind(this, 'reasonIds')}
                                        value={reasonIds}
                                        loading={loadListReason}
                                        showSearch
                                        filterOption={(inputValue, option) => {
                                            return option.props.children.toLowerCase().includes(inputValue.toLowerCase())
                                        }}
                                        maxTagCount={2}
                                    >
                                        {
                                            listReason ? listReason.map((value, index) => <Option key={value.id} value={`${value.id}`}>{value.name}</Option>) : []
                                        }
                                    </Select>
                                </Col>

								<Col span={8} className="form-filter-control">
									<label>{t("delivery_case.trackingNumber")}</label>
									<Input
										className={"_filter_tracking-number"}
										placeholder={t("delivery_case.trackingNumber")}
										suffix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
										value={trackingNumber}
										onChange={this.onChangeInput.bind(this, 'trackingNumber')}
										onPressEnter={this.onFilter}
									/>
								</Col>

								<Col span={8} className="form-filter-control">
									<label>{t("delivery_case.createdBy")}</label>
									<Select
										filterOption={false}
										className={"_filter_created_by"}
										placeholder={t("delivery_case.createdBy")}
										notFoundContent={(loadingUsersInVendor ? <Spin size="small" /> : <Empty description={t("table.empty")} />)}
										mode="multiple"
										style={{ width: "100%" }}
										value={createdBy}
										loading={loadingUsersInVendor}
										onChange={this.onSelectChange.bind(this, 'createdBy')}
										onSearch={value => this.handleSearchUsers(value)}
									>
										<Option value={"system"}>system</Option>
										{
											users && users.map(value => {
												return <Option key={_.get(value, 'username')} value={_.get(value, 'username')}>{_.get(value, 'username')}</Option>
											})
										}
									</Select>
								</Col>
                            </>
                        )}

                        <Col span={24} xs={24} className={`form-filter-control filter-button ${expanded ? 'expand' : ''}`}>
                            <Button type="primary" className="mg-r-10 _filter_button_filter" onClick={this.onFilter}>{t("button.filter")}</Button>
                            <Button type="default" className="mg-r-10 _filter_button_clear" onClick={this.clearFilter}>{t("contact.clear")}</Button>
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a className={"_filter_show_more"} onClick={this.toggleFormFilter}>{expanded ? t("contact.filter_less") : t("contact.filter_more")} <Icon className="expand-icon" type={expanded ? "up" : "down"} /></a>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export { Filter }
