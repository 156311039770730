import React, {Component} from 'react';
import {translate} from "react-i18next";
import StandardLayout from "../../components/StandardLayout";
import {Filter} from "./Filter";
import {List} from "./List";
import VendorService from "../../services/VendorService";
import {VENDORS_SCOPES} from "../../utils/api/Scopes";
import LocationService from "../../services/LocationService";
import {Header} from "./Header";
import UserService from "../../services/UserService";
import {getParamsAsObject, getUrlQueryString,getCurrentModule} from "../../utils/helper";
import {Url} from "../../utils/Url";

class Vendor extends Component {

    constructor(props) {
        super(props);
        this.mounted = true;
        this.moduleName = 'vendors';
        this.state = {
            dataSource: [],
            currentPage: 1,
            totalItems: 50,
            pageSize: 25,
            loading: true,
            pageCount: 0,
            countries: []
        };
    }


    componentWillMount() {
        if (!UserService.isAllowed(VENDORS_SCOPES.VENDORS_VIEW)) {
            this.props.history.replace('/403')
        }
    };

    componentDidMount = () => {
        this.mounted = true;
        window.addEventListener("popstate", this.onPopstate.bind(this));
        this.loadData();

    };

    componentWillUnmount = () => {
        this.mounted = false;
        window.removeEventListener("popstate", this.onPopstate.bind(this));
    };

    onPopstate() {
        // Url thay đổi thì chỉ load lại list
        if(this.mounted && getCurrentModule() === this.moduleName){
            let queryObject = getParamsAsObject(getUrlQueryString());
            this.loadContact(queryObject);
        }
    };

    callApiGetCountries = (cb) => {
        LocationService.getCountries({},(error, response) => {
            if(!error){
                this.setState({
                    countries: response
                });
            }
            if(typeof cb === 'function') cb(error, response);
        });
    };


    loadData = () => {
        let queryObject = getParamsAsObject(getUrlQueryString());
        this.loadContact(queryObject);
        this.callApiGetCountries();
    };

    loadContact = (filter = {}) => {
        if(!this.state.loading){
            this.setState({
                loading: true
            })
        }
        let defaultFilter = {
            include: ['country'],
            order: ["createdAt DESC"],
            page: filter.page || 1,
            page_size: filter.page_size || 25
        };

        if(Object.keys(filter).length) {
            defaultFilter = Object.assign(defaultFilter, filter);
        }

        VendorService.getVendorList(defaultFilter, (error, response) => {
            if(!error) {
                this.setState({
                    dataSource: response.list,
                    currentPage: response.metadata.currentPage,
                    totalItems: response.count,
                    pageSize: response.metadata.pageSize,
                    pageCount: response.metadata.pageCount,
                    loading: false
                })
            }
        });

    };

    onShowSizeChange = (current, pageSize) => {
        let uri = this.getUrlInstance();
        uri.replaceParam('page', 1);
        uri.replaceParam('page_size', pageSize);
        this.pushQueryString(uri.getQueryString());
    };

    onChangePage = (page, pageSize) => {
        let uri = this.getUrlInstance();
        uri.replaceParam('page', page);
        uri.replaceParam('page_size', pageSize);
        this.pushQueryString(uri.getQueryString());
    };

    getUrlInstance = () => {
        return new Url('?'+getUrlQueryString());
    };

    pushQueryString = (queryString) => {
        this.props.history.push(`/vendors${queryString}`);
    };

    render() {
        const {t} = this.props;
        const {dataSource,currentPage, pageSize,pageCount,totalItems,countries} = this.state;
        return (
            <StandardLayout {...this.props} title={t("vendor.title")}>
                <div className="vendor-list-page list-page">
                    <Header {...this.props} />
                    <Filter
                        {...this.props}
                        countries={countries}
                        loadContact={this.loadContact}
                        pageSize={pageSize}
                        getUrlInstance={this.getUrlInstance}
                        pushQueryString={this.pushQueryString}
                        loading={this.state.loading}
                    />
                    <List
                        {...this.props}
                        dataSource={dataSource}
                        currentPage={currentPage}
                        pageSize={pageSize}
                        pageCount={pageCount}
                        onChangePage={this.onChangePage}
                        onShowSizeChange={this.onShowSizeChange}
                        loading={this.state.loading}
                        loadContact={this.loadContact}
                        totalItems={totalItems}
                    />
                </div>
            </StandardLayout>
        );
    }
}

export default (translate())(Vendor)