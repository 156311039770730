import React, {Component} from 'react';
import {
	Button,
	Checkbox,
	Col,
	DatePicker,
	Drawer,
	Empty,
	Form,
	Icon,
	Input,
	InputNumber,
	Row,
	Select,
	Spin
} from 'antd';
import LocationService from "../../../services/LocationService";
import * as _ from "lodash";
import moment from 'moment';
import ContactService from "../../../services/ContactService";
import {formatCurrency} from "../../../utils/currency";
import UserService from "../../../services/UserService";
import {CONTACT_SCOPES} from "../../../utils/api/Scopes";
import VendorService from "../../../services/VendorService";
import {APP} from "../../../config/app";

const Option = Select.Option;
const { TextArea } = Input;

class FormAddEvent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dateTime: [],
            deliveryAt: "",
            callStatus: [],
            callStatusId: "",
            listAds: [],
            adId: "",
            provinces: [],
            provinceId: "",
            cities: [],
            cityId: "",
            areas: [],
            areaId: "",
            areaName: "",
            cancelReason: "",
            orderId: "",
            address: "",
            orderNote: "",
            deliveryFee: "",
            loadingCity: true,
            appointment: "",
            checkRule: false,
            country: "",
            currencyUnit: "",
            skuPriceDirty: [],
            courierId: "",
            couriers: [],
            giftForm: [],
            doNotNeedToKeepFollowing: false,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(prevProps.contact) !== JSON.stringify(this.props.contact)) {
            const {vendor, country} = this.props.contact;
            this.callApiGetProvince(vendor.countryId);
            this.fetchVendorCouriersByVendorId(vendor.id);
            this.setState({
                country: country.name,
                currencyUnit: country.currencyUnit
            })
        }
    }

    fetchVendorCouriersByVendorId = (vendorId) => {
        VendorService.couriers(vendorId, (error, response) => {
            if (!error) {
                this.setState({
                    couriers: response
                })
            }
        })
    };

    callApiGetProvince = (countryId, cb) => {
        if(countryId) {
            LocationService.getProvinceByCountry(countryId, (error, response) => {
                if (!error) {
                    this.setState({
                        provinces: response
                    });
                }
                if (typeof cb === 'function') cb(error, response);
            });
        }
    };

    callApiGetCity = (id) => {
        LocationService.getCity(id, (error, response) => {
            if (error === null) {
                this.setState({
                    cities: response,
                });
            }
        });
    };

    callApiGetAreas = (cityId) => {
        LocationService.getDistrict(cityId, (error, response) => {
            if (error === null) {
                this.setState({
                    areas: response,
                });
            }
        });
    };

    loadInfoByAds(adsId) {
        this.props.form.setFieldsValue({quantity:undefined, total: undefined, unitPrice: undefined});
        ContactService.getContactOrderByAds(this.props.contact.id, adsId, (err, res) => {
            if (!err) {
                if (Array.isArray(res) && res.length > 0) {
                    // let order = res[0];
                    // if ((order.status === 'NEW' && this.state.callStatusId === 2) || (order.status === 'DRAFT')) {
                    //     this.setState({orderId: order.id});
                    // }

                    // if (order.status === 'DRAFT') {
                        // this.setState({
                        //     provinceId: order.provinceId,
                        //     deliveryAt: order.deliveryDate,
                        //     cityId: order.cityId,
                        //     address: order.address ? order.address : '',
                        //     orderNote: order.note ? order.note : '',
                        // });
                        // this.props.onChangeOrderItems(order);
                        // this.callApiGetCity(order.provinceId);
                    // }
                }
            }
        });
    }

    onPressEnter = () => {
        if(this.props.isShowPopUpAddEvent){
            this.props.onCreate();
        }
    };

    onChangeDatePicker = (key, ranges) => {
        this.setState({
            [key]: ranges
        });
    };

    onSelect = (key, value) => {
        this.setState({
            [key]: value
        });

        const {contact} = this.props;

        if (key === 'courierId') {
            this.props.form.setFieldsValue({
                province:undefined,
                city: undefined,
                areaId: undefined,
                areaName: undefined,
                address: undefined
            });

            const countryId = _.get(contact, 'countryId');

            LocationService.getProvinceByCountry(countryId, (error, response) => {
                if (!error) {
                    this.setState({
                        provinces: response
                    })
                }
            })
        }

        if(key === "provinceId"){
            this.props.form.setFieldsValue({city:undefined, address: undefined});
            this.setState({
                cityId: ""
            });
            this.callApiGetCity(value);
        }

        if (key === 'cityId') {
            this.props.form.setFieldsValue({areaId:undefined, address: undefined});
            this.setState({
                areaId: ""
            });
            this.callApiGetAreas(value);
        }

        if(key === "callStatusId"){
            this.props.form.setFieldsValue({deliveryAt:undefined});
            if([1,8].indexOf(value) >= 0){

                if(value === 8){
                    this.setState({
                        checkRule: true,
                        deliveryAt: ""
                    });
                }else{
                    this.setState({
                        checkRule: false,
                        deliveryAt: ""
                    });
                }
                if(!this.state.adId){
                    let productId = this.props.contact && this.props.contact.hasOwnProperty('productId') ? this.props.contact.productId : null;
                    let adsId = this.props.contact && this.props.contact.hasOwnProperty('adsId') ? this.props.contact.adsId : null;
                    this.setState({
                        adId: adsId,
                    });
                    this.props.fetchProductSku({adsId:this.props.listAds[0].id, productId: productId}, () => this.loadInfoByAds(adsId));
                }
            }else{
                if(!this.state.adId){
                    let productId = this.props.contact && this.props.contact.hasOwnProperty('productId') ? this.props.contact.productId : null;
                    let adsId = this.props.contact && this.props.contact.hasOwnProperty('adsId') ? this.props.contact.adsId : null;
                    this.setState({adId: adsId});
                    this.props.fetchProductSku({adsId:adsId, productId: productId}, () => this.loadInfoByAds(adsId));
                }

            }
        }

        if(key === "adId"){
            let that = this;
            let contact = {productId:this.props.contact.productId,adsId: value};
            this.setState({
                deliveryAt: "",
                provinceId: "",
                cityId: "",
                orderId: "",
                address: "",
                orderNote: "",
            });
            this.props.deleteSkuBeforeFetch(function () {
                that.props.fetchProductSku(contact, () => that.loadInfoByAds(value));
            });

            this.props.fetchAdGifts(value);
        }

        if(key === 'doNotNeedToKeepFollowing'){
            this.setState({
                doNotNeedToKeepFollowing: value.target.checked
            })
        }
    };

    formatDisplaytoSku = (index,value)=> {
        let valueToSku = "";
        for (let i = 0; i < this.props.listProductSku.length; i++) {
            if(this.props.listProductSku[i].index === index){
                for (let j = 0; j < this.props.listProductSku[i].productSku.length; j++) {
                    if(this.props.listProductSku[i].productSku[j].display === value){
                        valueToSku = this.props.listProductSku[i].productSku[j].sku
                    }
                }
            }
        }
        return valueToSku
    };

    onSelectSku = (index, value) => {
        let valueToSku = this.formatDisplaytoSku(index,value);
        let {resetFields} = this.props.form;
        resetFields([`quantity-${index}`]);
        this.props.formatPriceWhenSelectSku(valueToSku,index);
    };

    addSku = () => {
        this.props.addSku();
    };

    checkSelected = (index) => {
        let list = _.cloneDeep(this.props.listProductSku);
        for (let i = 0; i < list.length; i++) {
            if(list[i].index === index){
                for (let j = 0; j < list[i].productSku.length; j++) {
                    if(list[i].productSku[j].disabled){
                        return list[i].productSku[j].disabled
                    }
                }
            }
        }
    };

    checkQuantityDisabled = (index) => {
        let list = _.cloneDeep(this.props.listProductSku);
        for (let i = 0; i < list.length; i++) {
            if(list[i].index === index){
                for (let j = 0; j < list[i].productPrice.length; j++) {
                    if(list[i].productPrice[j].disabled === false){
                        return false;
                        break;
                    }
                }
            }
        }
        return true
    };

    handleChangeSkuPrice = (index, value) => {
        let {skuPriceDirty} = this.state;
        skuPriceDirty.push(index);
        skuPriceDirty = _.uniq(skuPriceDirty);
        this.setState({skuPriceDirty});

        this.props.onChangeSkuPrice(index, value);
    };

    isSkuPriceDirty = (index) => {
        let {skuPriceDirty} = this.state;
        return skuPriceDirty.indexOf(index) >= 0;
    };

    onChangeQuantity = (index,value) => {
        // Khi giá chưa được sửa bới người dùng thì mới format theo cấu hình ad
        // Khi giá được sửa bởi người dùng thì tổng tiền cập nhật theo giá mới luôn
        if (!this.isSkuPriceDirty(index)) {
            this.props.formatPriceWhenChangeQuantityInput(index, value);
        } else {
            this.props.onChangeSkuQuantity(index, value);
        }
    };

    getMinMax = (wholeSalePrice) => {
        let value = {min: 1, max: 1};
        for (let i = 0; i < wholeSalePrice.length; i++) {
            if(i === 0){
                value.min = wholeSalePrice[i].from;
                if(wholeSalePrice.length === 1){
                    value.max = wholeSalePrice[i].to;
                }
            }else if(i === wholeSalePrice.length-1){
                value.max = wholeSalePrice[i].to;
            }
        }

        return value
    };

    deleteSku = (index) => {
        let sku;
        let skuSelected = this.props.form.getFieldValue(`sku-${index}`);
        if(skuSelected){
            this.props.form.setFieldsValue({[`quantity-${index}`]:0,[`total-${index}`]: 0});
            sku = this.formatDisplaytoSku(index,skuSelected);
        }else{
            sku = ""
        }
        this.props.deleteSku(index,sku);
    };

    clearState = () => {
        this.setState({
            dateTime: [],
            deliveryAt: "",
            callStatusId: "",
            adId: "",
            provinceId: "",
            cityId: "",
            cancelReason: "",
            orderId: "",
            address: "",
            orderNote: "",
            doNotNeedToKeepFollowing: false
        })
    };

    getItem = (item) => {
        const {t, form, listProductSku} = this.props;
        const { getFieldDecorator } = form;

        for (let i = 0; i < item.productPrice.length; i++) {
            if(item.productPrice[i].selected){
                let quantity = item.productPrice[i].configWholeSaleDisplay[0].quantity;
                let unitPrice = _.toString(item.productPrice[i].configWholeSaleDisplay[0].unitPrice);
                let total = _.toString(item.productPrice[i].configWholeSaleDisplay[0].total);
                let getMinMax = this.getMinMax(item.productPrice[i].wholeSalePrice);
                return <>
                    <td>
                        <Form.Item>
                            {getFieldDecorator(`unitPrice-${item.index}`, {
                                initialValue: unitPrice
                            })(
                                <InputNumber
                                    min={0}
                                    placeholder={formatCurrency(unitPrice)}
                                    style={{width: '100%'}}
                                    className={`_price`}
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    disabled={!UserService.isAllowed(CONTACT_SCOPES.CONTACT_UPDATE_PRICE)}
                                    onChange={this.handleChangeSkuPrice.bind(this, item.index)}
                                />
                            )}
                        </Form.Item>
                    </td>

                    <td>
                        <Form.Item>
                            {getFieldDecorator(`quantity-${item.index}`, {
                                rules: [{ required: true, message: t("contact-detail.validate_quantity")}],
                                initialValue: quantity
                            })(
                                <InputNumber
                                    disabled={this.checkQuantityDisabled(item.index)}
                                    min={getMinMax.min}
                                    max={getMinMax.max}
                                    parser={value => value.replace(/\D+/gm, '')}
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    className={"_quantity"}
                                    style={{width: "100%"}}
                                    onChange={this.onChangeQuantity.bind(this,item.index)}
                                />
                            )}
                        </Form.Item>
                    </td>

                    <td>
                        <Form.Item>
                            {getFieldDecorator(`total-${item.index}`, {
                                initialValue: total
                            })(
                                <span>{formatCurrency(total)}</span>
                            )}
                        </Form.Item>
                    </td>

                    {!!listProductSku.length && listProductSku.length > 1 && (
                        <td style={{paddingLeft: 0}}>
                            <Form.Item>
                                <Icon className="_delete-sku" type="delete" onClick={this.deleteSku.bind(this,item.index)}/>
                            </Form.Item>
                        </td>
                    )}
                </>
            }
        }

        return <>
            <td>
                <Form.Item>
                    <span>0</span>
                </Form.Item>
            </td>

            <td>
                <Form.Item>
                    {getFieldDecorator(`quantity-${item.index}`, {
                        rules: [{ required: true, message: t("contact-detail.validate_quantity")}],
                    })(
                        <InputNumber
                            disabled={this.checkQuantityDisabled(item.index)}
                            className={"_quantity"}
                            placeholder={0}
                            style={{width: "100%"}}
                        />
                    )}
                </Form.Item>
            </td>

            <td>
                <Form.Item>
                    <span>0</span>
                </Form.Item>
            </td>

            {!!listProductSku.length && listProductSku.length > 1 && (
                <td style={{paddingLeft: 0}}>
                    <Form.Item>
                        <Icon className="_delete-sku" type="delete" onClick={this.deleteSku.bind(this,item.index)}/>
                    </Form.Item>
                </td>
            )}
        </>
    };

    getProductSku = (productSku) => {
        let arr = [];
        for (let i = 0; i < productSku.length; i++) {
            if(productSku[i].selected){
                arr.push(productSku[i])
            }
        }
        return arr
    };

    getTotalQuantity = () => {
        const {form} = this.props;
        let allField = form.getFieldsValue();
        let arr = _.map(allField, (value, key) => ({ key, value }));
        let totalQuantity = 0;
        for(let i = 0; i < arr.length; i++) {
            if(arr[i].key.indexOf("quantity") >= 0){
                if(arr[i].value !== undefined){
                    totalQuantity += parseFloat(arr[i].value);
                }
            }
        }

        totalQuantity = formatCurrency(totalQuantity);
        return totalQuantity
    };

    getTotalPrice = () => {
        const {form} = this.props;
        let allField = form.getFieldsValue();
        let arr = _.map(allField, (value, key) => ({ key, value }));
        let totalPrice = 0;

        for(let i = 0; i < arr.length; i++) {
            if(arr[i].key.indexOf("total") >= 0){
                if(arr[i].value !== undefined){
                    totalPrice += parseFloat(arr[i].value);
                }
            }
        }

        if (totalPrice > 0) {
            return formatCurrency(totalPrice);
        }

        return totalPrice;
    };

    disabledDate = (current) => {
        if(this.state.callStatusId === 8){
            return current < moment().startOf('day').add(3, 'days');
        }else{
            return current < moment().startOf('day');
        }
    };

    range = (start,end) => {
        const result = [];
        for (let i = start; i < end; i++) {
            result.push(i);
        }
        return result;
    };

    disabledTime = () => {
        let startDate = moment(new Date()).format("DD/MM/YYYY");
        let endDate = moment(this.state.appointment).format("DD/MM/YYYY");
        if(this.state.callStatusId !== 8){
            let hour = moment(new Date()).format("H");
            let minute = moment(new Date()).format("m");
            if(moment(endDate).isSameOrBefore(startDate)){
                return {
                    disabledHours: this.range.bind(this, 0, hour),
                    disabledMinutes: this.range.bind(this, 0, minute),
                };
            }
        }
    };

    renderReasonDeclined = () => {
        const {t, form} = this.props;
        const { getFieldDecorator } = form;
        let cancelStatusArr = [];
        if (this.props.callStatus.length) {
            for (let status of this.props.callStatus) {
                if (status.id === this.state.callStatusId) {
                    cancelStatusArr = status.children;
                }

            }
        }

        return <>
            <Form.Item label={t("order_detail.reason")}>
                {getFieldDecorator('reason', {
                    rules: [{ required: true, message: t("contact-detail.validate_cancel_reason")}],
                })(
                    <Select
                        placeholder={!this.state.cancelReason?t("contact-detail.cancel_reason_pl"):undefined}
                        showSearch
                        className={"_cancel_Reason"}
                        notFoundContent={<Empty description={t("table.empty")} />}
                        style={{width: "100%"}}
                        onSelect={this.onSelect.bind(this, 'cancelReason')}
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                        {cancelStatusArr.length && cancelStatusArr.map((item,index) => (
                            <Option key={index} value={item.key} className={`_cancel_Reason_item _cancel_Reason_item_${item.id}`}>{t(`order_status_cancelled.${item.key}`)}</Option>
                        ))}
                    </Select>
                )}
            </Form.Item>

            {this.state.cancelReason === "OTHER" && (
                <Form.Item label={t("contact_detail.content")}>
                    {getFieldDecorator('other', {
                        rules: [
                            { required: true, message: t("contact-detail.validate_other_reason")},
                            { max: 255, message: t("contact-detail.validate_content_length")}
                            ],
                    })(
                        <TextArea
                            rows={4}
                            placeholder={t("contact-detail.content_pl")}
                        />
                    )}
                </Form.Item>
            )}
        </>
    };

    getInitialSkuDefault = (item) => {
        for (let i = 0; i < item.productSku.length; i++) {
            if(item.productSku[i].selected === false && item.productSku[i].existed){
                return item.productSku[i].display
            }
        }
    };

    getDeliveryAt = () => {
        if(this.state.deliveryAt){
            return moment(this.state.deliveryAt)
        }
    };

    handleChangeNumber = (field, value) => {
        this.setState({
            ...this.state.giftForm,
            [field]: value
        })
    }

    getGiftQuantityById = (giftId) => {
        const {giftForm} = this.state;
        const gift = _.find(giftForm, item => item.giftId === giftId);
        return _.get(gift, 'quantity');
    }

    handleChangeGift = (id, field, value) => {
        const {giftForm} = this.state;
        const gift = _.find(giftForm, item => item.giftId === id);
        if (gift) {
            const modifiedGift = {
                ...gift,
                [field]: value
            }

            const modifiedGiftForm = _.map(giftForm, item => {
                if (item.giftId === modifiedGift.giftId) {
                    item = {...modifiedGift}
                }
                return item;
            })

            this.setState({giftForm: modifiedGiftForm}, () => {
                this.props.onChangeGift([...modifiedGiftForm]);
            });
        }
        else {
            giftForm.push({
                giftId: id,
                [field]: value
            })
            this.setState({giftForm}, () => {
                this.props.onChangeGift([...giftForm]);
            });
        }
    }

    renderArea = () => {
        const {form, t, contact} = this.props;
        const { getFieldDecorator } = form;
        const {areas} = this.state;
        let isShowAreaId;
        if (contact.countryId === APP.COUNTRY_PHILIPPINE_ID) {
            isShowAreaId = true;
        }
        else {
            isShowAreaId = areas.length > 0;
        }

        if (isShowAreaId) {
            return (
                <Form.Item label={t("contact-detail.area")}>
                    {getFieldDecorator('areaId', {
                        initialValue: this.state.areaId?this.state.areaId:undefined,
                        rules: [{ required: true, message: t("contact-detail.validate_area")}],
                    })(
                        <Select
                            placeholder={!this.state.areaId?t("contact-detail.area"):undefined}
                            showSearch
                            disabled={!this.state.cityId}
                            className={"_area"}
                            notFoundContent={<Empty description={t("table.empty")} />}
                            style={{width: "100%"}}
                            // onSelect={this.onSelect.bind(this, 'areaId')}
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {areas.length && areas.map((item) => (
                                <Option key={item.id} value={item.id} className={`_area_item _area_item${item.key}`}>{item.name}</Option>
                            ))}
                        </Select>
                    )}
                </Form.Item>
            );
        }

        return (
            <Form.Item label={t("contact-detail.area")}>
                {getFieldDecorator('areaName', {
                    initialValue: this.state.areaName,
                    rules: [{ required: true, message: t("contact-detail.validate_area")}],
                })(
                    <Input
                        disabled={!this.state.cityId}
                        placeholder={t("contact-detail.area")}
                    />
                )}
            </Form.Item>
        );
    };

    render() {
        const {
            isShowPopUpAddEvent, onCancel, onCreate, form,t, listProductSku, contact, callStatus, listAds, adGifts, loading
        } = this.props;
        const {
            provinces, cities, couriers, giftForm
        } = this.state;
        const { getFieldDecorator } = form;

        return (
            <Drawer
                visible={isShowPopUpAddEvent}
                title={t("contact-detail.add_event").toUpperCase()}
                destroyOnClose={true}
                width={720}
                onClose={onCancel}
                style={{
                    overflow: 'auto',
                    height: 'calc(100%)',
                    paddingBottom: '108px',
                }}
                className={"popup-add-event _popup-add-event"}
            >
                <Spin spinning={loading}>
                    <Form layout="vertical" className="ad-add-page add-event-form _add-event-form">
                        <Form.Item label={t("contact-detail.date_time")}>
                            {getFieldDecorator('dateTime', {
                                rules: [{ required: true, message: t("contact-detail.validate_time")}],
                                initialValue: moment(new Date())
                            })(
                                <DatePicker
                                    format={'DD/MM/YYYY h:mm A'}
                                    disabled={true}
                                    className={"_dateTime"}
                                    onChange={this.onChangeDatePicker.bind(this, 'dateTime')}
                                    style={{width: '100%'}}
                                />
                            )}
                        </Form.Item>

                        <Form.Item label={t("contact-detail.status")}>
                            {getFieldDecorator('status', {
                                rules: [{ required: true, message: t("contact-detail.validate_status")}],
                            })(
                                <Select
                                    placeholder={!this.state.callStatusId?t("contact-detail.status_pl"):undefined}
                                    showSearch
                                    className={"_call_Status"}
                                    notFoundContent={<Empty description={t("table.empty")} />}
                                    style={{width: "100%"}}
                                    onSelect={this.onSelect.bind(this, 'callStatusId')}
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {callStatus.length && callStatus.map((item) => (
                                        <Option key={item.id} value={item.id} className={`_call_Status_item _call_Status_item_${item.id}`}>{item.value}</Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>

                        <Form.Item>
                            <Checkbox onChange={this.onSelect.bind(this, 'doNotNeedToKeepFollowing')}>Do not need to keep following</Checkbox>
                        </Form.Item>

                        {[1,2,3,8].indexOf(this.state.callStatusId) >= 0 && (
                            <Form.Item label={t("order_detail.ads")}>
                                {getFieldDecorator('ads', {
                                    rules: [{ required: true, message: t("contact-detail.validate_ad")}],
                                    initialValue: Object.keys(contact).length?contact.adsId:""
                                })(
                                    <Select
                                        showSearch
                                        className={"_ads"}
                                        notFoundContent={<Empty description={t("table.empty")} />}
                                        style={{width: "100%"}}
                                        onSelect={this.onSelect.bind(this, 'adId')}
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                        {listAds.length && listAds.map((item) => (
                                            <Option key={item.id} value={item.id} className={`_ads_item _ads_item_${item.id}`}>{item.name}</Option>
                                        ))}
                                    </Select>
                                )}
                            </Form.Item>
                        )}

                        {[3,5,6].indexOf(this.state.callStatusId) >= 0 && (
                            <Form.Item label={t("contact-detail.appointment")}>
                                {getFieldDecorator('appointment', {
                                })(
                                    <DatePicker
                                        placeholder={t("contact-detail.appointment_pl")}
                                        className={"_appointment"}
                                        onChange={this.onChangeDatePicker.bind(this, 'appointment')}
                                        style={{width: '100%'}}
                                        format={'DD/MM/YYYY h:mm A'}
                                        disabledDate={this.disabledDate}
                                        disabledTime={this.disabledTime}
                                        showTime={{ defaultValue: moment(new Date(), 'HH:mm:ss') }}
                                        allowClear={false}
                                    />
                                )}
                            </Form.Item>
                        )}

                        {this.state.callStatusId === 2 ? this.renderReasonDeclined() :
                            <Form.Item label={t("contact-detail.event_note")}>
                                {getFieldDecorator('note', {
                                    rules: [
                                        { max: 255, message: t("contact-detail.validate_note_length")}
                                    ],
                                })(
                                    <TextArea
                                        rows={4}
                                        placeholder={t("contact-detail.note_pl")}
                                    />
                                )}
                            </Form.Item>
                        }

                        {[1,8].indexOf(this.state.callStatusId) >= 0 && (
                            <>
                                {!this.props.loadingProduct && (
                                    <>
                                        <Form.Item label={t("contact-detail.delivery_at")} style={{marginTop: 50}}>
                                            {getFieldDecorator('deliveryAt', {
                                                initialValue: this.getDeliveryAt()
                                            })(
                                                <DatePicker
                                                    placeholder={t("contact-detail.delivery_pl")}
                                                    className={"_delivery_at"}
                                                    onChange={this.onChangeDatePicker.bind(this, 'deliveryAt')}
                                                    style={{width: '100%'}}
                                                    format={'DD/MM/YYYY h:mm A'}
                                                    disabledDate={this.disabledDate}
                                                    disabledTime={this.disabledTime}
                                                    allowClear={false}
                                                />
                                            )}
                                        </Form.Item>

                                        <Form.Item>
                                            <Col span={4}><span>{t("vendor.country")}</span></Col>
                                            <Col span={4}><span>{this.state.country}</span></Col>
                                        </Form.Item>

                                        <Form.Item label={t("contact-detail.courier")}>
                                            {getFieldDecorator('courierId', {
                                                rules: [{ required: true, message: t("contact-detail.validate_courier")}],
                                                initialValue: this.state.courierId?this.state.courierId:undefined
                                            })(
                                                <Select
                                                    placeholder={!this.state.courierId?t("contact-detail.courier"):undefined}
                                                    showSearch
                                                    className={"_province"}
                                                    notFoundContent={<Empty description={t("table.empty")} />}
                                                    style={{width: "100%"}}
                                                    onSelect={this.onSelect.bind(this, 'courierId')}
                                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                >
                                                    {Array.isArray(couriers) && couriers.map((item) => (
                                                        <Option key={item.id} value={item.id} className={`_courier_item _courier_item_${item.key}`}>{item.name}</Option>
                                                    ))}
                                                </Select>
                                            )}
                                        </Form.Item>

                                        <Form.Item label={t("contact-detail.province")}>
                                            {getFieldDecorator('province', {
                                                rules: [{ required: true, message: t("contact-detail.validate_province")}],
                                                initialValue: this.state.provinceId?this.state.provinceId:undefined
                                            })(
                                                <Select
                                                    placeholder={!this.state.provinceId?t("contact-detail.province_pl"):undefined}
                                                    showSearch
                                                    disabled={!this.state.courierId}
                                                    className={"_province"}
                                                    notFoundContent={<Empty description={t("table.empty")} />}
                                                    style={{width: "100%"}}
                                                    onSelect={this.onSelect.bind(this, 'provinceId')}
                                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                >
                                                    {provinces.length && provinces.map((item) => (
                                                        <Option key={item.id} value={item.id} className={`_province_item _province_item_${item.key}`}>{item.name}</Option>
                                                    ))}
                                                </Select>
                                            )}
                                        </Form.Item>

                                        <Form.Item label={t("contact-detail.district")}>
                                            {getFieldDecorator('city', {
                                                rules: [{ required: true, message: t("contact-detail.validate_city")}],
                                                initialValue: this.state.cityId?this.state.cityId:undefined
                                            })(
                                                <Select
                                                    placeholder={!this.state.cityId?t("contact-detail.district_pl"):undefined}
                                                    showSearch
                                                    disabled={!this.state.provinceId}
                                                    className={"_city"}
                                                    notFoundContent={<Empty description={t("table.empty")} />}
                                                    style={{width: "100%"}}
                                                    onSelect={this.onSelect.bind(this, 'cityId')}
                                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                >
                                                    {cities.length && cities.map((item) => (
                                                        <Option key={item.id} value={item.id} className={`_city_item _city_item_${item.key}`}>{item.name}</Option>
                                                    ))}
                                                </Select>
                                            )}
                                        </Form.Item>

                                        {this.renderArea()}

                                        <Form.Item label={t("contact-detail.address_detail")}>
                                            {getFieldDecorator('address', {
                                                rules: [
                                                    { required: true, message: t("contact-detail.validate_address")},
                                                    { max: 255, message: t("contact-detail.validate_address_length")}
                                                    ],
                                                initialValue: this.state.address
                                            })(
                                                <TextArea rows={4}
                                                   placeholder={t("contact-detail.address_pl")}
                                                />
                                            )}
                                        </Form.Item>

										<Form.Item label={t("contact-detail.postal_code")}>
											{getFieldDecorator('postalCode', {
												initialValue: this.state.postalCode
											})(
												<Input
													placeholder={t("contact-detail.postal_code")}
												/>
											)}
										</Form.Item>
                                    </>
                                )}

                                {this.props.loadingProduct && (
                                    <Form.Item style={{textAlign:"center", marginTop: 50}}>
                                        <Spin size="small"/>
                                    </Form.Item>
                                )}

                                <Form.Item style={{marginTop:50}}>
                                    <span style={{color:"#1890FF", fontWeight:600}}>{Object.keys(contact).length?contact.product.name:""}</span>
                                </Form.Item>

                                <div className="table-responsive mg-bt-15">
                                    <table className={"table-config _table_config table-add-event"}>
                                        <thead>
                                        <tr className={"_table_config_header"}>
                                            <th className="pd-10 pd-l-5" width="30%">
                                                <span className="property font-weight-word">{t("ads.properties")}</span>
                                            </th>
                                            <th className="pd-10 pd-l-5" width="30%">
                                                <p className="unit-price font-weight-word" style={{marginBottom: 0}}>{t("contact-detail.unit_price")} <span>{`(${this.state.currencyUnit})`}</span></p>
                                            </th>
                                            <th className="pd-10 pd-l-5" width="20%">
                                                <span className="quantity font-weight-word">{t("ads.quantity")}</span>
                                            </th>
                                            <th className="pd-10 pd-l-5" width="30%">
                                                <p className="price font-weight-word" style={{marginBottom: 0}}>{t("ads.price")} <span>{`(${this.state.currencyUnit})`}</span></p>
                                            </th>

                                            <th className="pd-10 pd-l-5" width="20%">
                                            </th>
                                        </tr>
                                        </thead>

                                        <tbody>
                                        {!!listProductSku.length && !this.props.loadingProduct && listProductSku.map((item,index) => (
                                            <tr key={index}>
                                                <td >
                                                    <Form.Item>
                                                        {getFieldDecorator(`sku-${item.index}`, {
                                                            rules: [{ required: true, message: t("contact-detail.validate_property")}],
                                                            initialValue: this.getInitialSkuDefault(item)
                                                        })(
                                                            <Select
                                                                placeholder={t("contact-detail.variant_pl")}
                                                                disabled={this.checkSelected(item.index)}
                                                                className={"_sku-select"}
                                                                notFoundContent={<Empty description={t("table.empty")} />}
                                                                style={{width: "100%"}}
                                                                onSelect={this.onSelectSku.bind(this, item.index)}
                                                            >
                                                                {this.getProductSku(item.productSku).map((item1) => (
                                                                    <Option key={item1.id} value={item1.display} className={`_sku _sku_item_${item.id}`}>{item1.display}</Option>
                                                                ))}

                                                            </Select>
                                                        )}
                                                    </Form.Item>
                                                </td>

                                                {this.getItem(item)}

                                            </tr>
                                        ))}
                                        {this.props.loadingProduct && (
                                            <tr style={{textAlign:"center"}}>
                                                <td colSpan={5}>
                                                    <Spin size="small"/>
                                                </td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>
                                </div>

                                {listProductSku.length < this.props.listSku.length && (
                                    <Form.Item style={{marginBottom:10}}>
                                        <Button style={{width:"100%", borderStyle: "dotted"}} onClick={this.addSku} icon="plus" className="_btn_add_properties">{t("contact-detail.add_property")}</Button>
                                        <div style={{height: 1, background: "#E9E9E9", marginTop: 10, opacity: 1}}/>
                                    </Form.Item>
                                )}

                                <Form.Item style={{textAlign:"center"}}>
                                    <Col span={9}/>
                                    <Col span={4}><span style={{fontWeight:600}}>Total quantity:</span></Col>
                                    <Col span={4}><span style={{color:"#1890FF", opacity: 1}}>{this.getTotalQuantity()}</span></Col>
                                </Form.Item>

                                <Form.Item style={{textAlign:"center"}}>
                                    <Col span={9}/>
                                    <Col span={4}><span style={{fontWeight:600}}>{t("contact-detail.total_amount")}</span></Col>
                                    <Col span={4}><span style={{color:"#1890FF", opacity: 1}}>{this.getTotalPrice()}</span></Col>
                                </Form.Item>

                                <Form.Item style={{textAlign:"center"}}>
                                    <Col span={9}/>
                                    <Col span={4}><span style={{fontWeight:600}}>Total payment <b style={{color: 'red'}}>*</b></span></Col>
                                    <Col span={8}>
                                        <Form.Item required={true} style={{paddingLeft: 10}}>
                                            {getFieldDecorator('collectedCod', {
                                                initialValue: this.state.collectedCod,
                                                rules: [
                                                    {required: true, message: "Total payment is required"}
                                                ]
                                            })(
                                                <InputNumber
                                                    style={{width: '100%'}}
                                                    parser={value => value.replace(/\D+/gm, '')}
                                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Form.Item>

                                {Array.isArray(adGifts) && adGifts.length > 0 && (
                                    <Form.Item label={<span style={{color:"#1890FF", fontWeight:600}}><Icon type={'gift'} /> Gifts</span>}>
                                            {adGifts.map(giftItem => (
                                                <Row gutter={20} key={giftItem.id} className={'mg-bt-10'}>
                                                    <Col xs={24} md={12}>
                                                        <Input
                                                            readOnly={true}
                                                            value={`${_.get(giftItem, 'product.name')} (${_.get(giftItem, 'sku')})`}
                                                            prefix={<Icon type={'gift'} />}
                                                        />
                                                    </Col>
                                                    <Col xs={24} md={12}>
                                                        <InputNumber
                                                            placeholder={t('ads.quantity')}
                                                            value={this.getGiftQuantityById(giftItem.id)}
                                                            onChange={this.handleChangeGift.bind(this, giftItem.id, 'quantity')}
                                                            style={{width: '100%'}}
                                                            parser={value => value.replace(/\D+/gm, '')}
                                                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                        />
                                                    </Col>
                                                </Row>
                                            ))}
                                    </Form.Item>
                                )}

                                <Form.Item label={t("contact-detail.order_note")}>
                                    {getFieldDecorator('orderNote', {
                                        initialValue: this.state.orderNote,
                                        rules: [
                                            { max: 255, message: t("contact-detail.validate_note_length")}
                                        ],
                                    })(
                                        <TextArea
                                            rows={4}
                                            placeholder={t("contact-detail.note_pl")}
                                        />
                                    )}
                                </Form.Item>

                                <Form.Item label={t("order.delivery_fee")}>
                                    {getFieldDecorator('deliveryFee', {
                                        initialValue: this.state.deliveryFee
                                    })(
                                        <Input
                                            placeholder={t("order.delivery_fee")}
                                        />
                                    )}
                                </Form.Item>
                            </>
                        )}

                        <Form.Item style={{textAlign:"right"}}>
                            <Button onClick={onCancel} style={{ marginRight: 8}}>
                                {t("contact-detail.cancel")}
                            </Button>
                            <Button onClick={onCreate} type="primary">
                                {t("contact-detail.finish")}
                            </Button>

                        </Form.Item>

                    </Form>
                </Spin>
            </Drawer>
        );
    }
}

FormAddEvent = Form.create()(FormAddEvent);
export {FormAddEvent}
