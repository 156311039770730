import React, {Component} from 'react';
import {Col, Divider, Empty, Form as AntForm, Input, Row, Select} from 'antd';
import {doCallBack} from "../../../../utils/helper";
import {WholeSaleConfig} from "./WholeSaleConfig";
import * as _ from "lodash";
import VendorService from "../../../../services/VendorService";

class Form extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: 1,
            projects: [],
            projectSelected: null,
            initialProjects: [],
            adsets: [],
            configWholeSalePrice: [],
            loadingProject: false,
        };
    }

    componentDidMount = () => {
        this.fetchProject({}, (error, response) => {
            if(!error) {
                this.setState({initialProjects: response.list});
            }
        })
    };

    resetDataSource = (key) => {
        this.setState({
            [key]: this.state[`initial${_.upperFirst(key)}`]
        });
    };

    fetchProject = (filter = {}, cb) => {
        this.setState({loadingProject: true});
        this.props.fetchProject(filter, (error, response) => {
            this.setState({loadingProject: false});
            if(!error) {
                this.setState({projects: response.list});
            }
            doCallBack(cb, [error, response]);
        });
    };

    fetchSku = (productId,cb) => {
        const {vendor} = this.props;
        VendorService.getInfoProduct(vendor.id, productId, (error, response) => {
            if(!error) {
                let data = response.productSku;
                for (let i = 0; i < data.length; i++) {
                    data[i].wholeSalePrice = [{
                        to:"",
                        from:"",
                        price: response.defaultPrice,
                        uid:this.getUid()
                    }]
                }
                this.setState({
                    configWholeSalePrice: data,
                });
            }
            if(typeof cb === 'function') cb(error, response);
        });
    };

    onChange = (e) => {
        this.setState({
            value: e.target.value,
        });
    };

    onChangeProject = (value) => {
        let project = JSON.parse(value);
        this.setState({
            projectSelected: project,
        },function(){
            this.fetchSku(project.productId)
        });
    };

    onSearchProject = (value) => {
        this.fetchProject({name: value});
    };

    getConfigWholeSale = (value) => {
        this.props.getConfigWholeSale(value);
    };

    onAddWholeSaleConfig = (index) => {
        const max = 5;
        let {configWholeSalePrice} = this.state;
        let config = configWholeSalePrice[index];
        let defaultPrice = config.product.defaultPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        if(config.wholeSalePrice.length <= max-1) {
            config.wholeSalePrice.push({
                from: "",
                to: "",
                price: defaultPrice,
                uid: this.getUid()
            });
            configWholeSalePrice[index] = config;
            this.setState({configWholeSalePrice});
        }
    };

    getUid = () => {
        return _.uniqueId('uid')
    };

    onRemoveWholeSaleConfig = (index,uid) => {
        let {configWholeSalePrice} = this.state;
        let config = configWholeSalePrice[index];

        let indexUid = _.findIndex(config.wholeSalePrice, (item) => item.uid == uid);
        config.wholeSalePrice.splice(indexUid, 1);
        configWholeSalePrice[index] = config;
        this.setState({configWholeSalePrice});
    };

    render() {
        const {t} = this.props;
        const {getFieldDecorator} = this.props.form;
        const {projects, loadingProject, configWholeSalePrice} = this.state;
        return (
            <>
                <div className="white-box">
                    <div className="form-header">
                        <h3 className="pd-l-30 pd-r-30 pd-t-15 pd-bt-15 mg-0">{t('campaign.add_campaign')}</h3>
                        <div className="h-line"/>
                    </div>
                    <div className="form-body pd-30 pd-t-10 pd-bt-10">
                        <Row gutter={20}>
                            <Col span={12}>
                                <div className="form-item">
                                    <AntForm.Item
                                        label={t("campaign.project")}
                                        className="project _project"
                                    >
                                        {getFieldDecorator('project', {
                                            rules: [
                                                {required: true, message: t("project.validate_select")},
                                            ],
                                        })(
                                            <Select
                                                loading={loadingProject}
                                                showSearch
                                                notFoundContent={<Empty description={t("table.empty")}/>}
                                                allowClear={true}
                                                placeholder={t("project.select_name")}
                                                onSearch={this.onSearchProject}
                                                onFocus={this.resetDataSource.bind(this, "projects")}
                                                onSelect={this.onChangeProject}
                                            >
                                                {projects.map((item, index) => (
                                                    <Select.Option
                                                        key={index}
                                                        value={JSON.stringify(item)}
                                                        className="_option-projects">{item.name}</Select.Option>
                                                ))}
                                            </Select>
                                        )}
                                    </AntForm.Item>
                                </div>
                                <div className="form-item">
                                    <AntForm.Item className={"form-item-item"}
                                                  label={<span className="bold-400">{t("campaign.add_name")}</span>}>
                                        {getFieldDecorator('name', {
                                            rules: [
                                                {required: true, message: t("campaign.validate_name")},
                                                {max: 160, message: t("products.name_length")}
                                            ],
                                        })(
                                            <Input placeholder={t("campaign.name_placeholder")} className="_project-name"/>
                                        )}
                                    </AntForm.Item>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div className="form-item">
                                    <AntForm.Item className={"form-item-item"}
                                                  label={<span className="bold-400">Facebook Campaign ID</span>}>
                                        {getFieldDecorator('facebookIds')(
                                            <Select mode={'tags'} placeholder={t("campaign.facebook_id")} className="_campaign-facebook-id"/>
                                        )}
                                    </AntForm.Item>
                                </div>
                            </Col>

                            <Col span={24}>
                                <Divider/>
                            </Col>

                            <Col span={24}>
                                {!!configWholeSalePrice.length && (
                                    <WholeSaleConfig
                                        {...this.props}
                                        configWholeSalePrice={configWholeSalePrice}
                                        onAddWholeSaleConfig={this.onAddWholeSaleConfig}
                                        onRemoveWholeSaleConfig={this.onRemoveWholeSaleConfig}
                                    />
                                )}
                            </Col>
                        </Row>
                    </div>
                </div>
            </>
        )
    }
}

export {Form};
