import React from 'react';
import * as _ from 'lodash';
import {Modal, Form, Upload, Button, Icon, Select, Empty, notification, Spin} from 'antd';
import {doCallBack} from "../../utils/helper";

class PopupImportContact extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            file: null,
            loading: false,
            loadingAds: false,
            adsItems: [],
            initialAdsItems: []
        };

        this.onSearch = _.debounce(this.onSearch, 400);
    }

    componentDidMount = () => {
        this.fetchAds({}, (error, response) => {
            if(!error) {
                this.setState({
                    initialAdsItems: response.list
                });
            }
        });
    };

    fetchAds = (filter = {}, cb) => {
        this.setState({loadingAds: true});
        this.props.fetchAds(filter, (error, response) => {
            this.setState({loadingAds: false});
            if(!error) {
                this.setState({
                    adsItems: response.list
                });
            }
            doCallBack(cb, [error, response]);
        });
    };

    onSearch = (value) => {
        this.fetchAds({keyword: value});
    };

    onFocus = () => {
        this.setState({
            adsItems: this.state.initialAdsItems
        });
    };

    normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    onSubmit = (e) => {
        const {form} = this.props;
        const {file} = this.state;
        form.validateFields((error, values) => {
            if(!error) {
                // Submit
                let data = {
                    file: file,
                    adsId: values.ads
                };
                this.setState({loading: true});
                this.props.importContact(data, (error, response) => {
                    this.setState({loading: false});
                    this.props.onImportResponse(error, response);
                });
            }
        })
    };

    render() {
        const {t, onCancel} = this.props;
        const {getFieldDecorator} = this.props.form;
        const {adsItems, loading, loadingAds, file} = this.state;
        const propsUpload = {
            beforeUpload: (file) => {
                this.setState({file: file});
                return false;
            },
            showUploadList: false,
            accept: '.csv,text/csv,application/vnd.ms-excel'
        };

        let renderFileList;
        if(file) {
            renderFileList = (
                <div style={{fontSize: 12, marginTop: 10}}>{file.name}</div>
            )
        }

        return (
            <div className="popup-import-contact _popup_import_contact">
                <Modal
                    title={t('contact.import_contact')}
                    okText={t('button.save')}
                    cancelText={t('button.cancel')}
                    onOk={this.onSubmit}
                    onCancel={onCancel}
                    visible={true}
                    okButtonProps={{
                        loading: loading,
                        className: "_btn_submit_import_contact"
                    }}
                    cancelButtonProps={{
                        className: "_btn_cancel_import_contact"
                    }}
                >

                    <Form layout="vertical">
                        <Form.Item>
                            {getFieldDecorator('file', {
                                rules: [
                                    {required: true, message: t('message.required')}
                                ],
                                valuePropName: 'fileList',
                                getValueFromEvent: this.normFile
                            })(
                                <Upload {...propsUpload}>
                                    <Button className={"_button_choose_file_upload"}>
                                        <Icon type="upload" /> {t('campaign.choose_file')}
                                    </Button>
                                </Upload>
                            )}
                            {renderFileList}
                            <div>
                                <a href="https://cdn.gobiz.vn/contact_import.csv">{t('contact.download_import_template')}</a>
                            </div>
                        </Form.Item>


                        <Form.Item>
                            {getFieldDecorator('ads', {
                                rules: [
                                    {required: true, message: t('message.required')}
                                ]
                            })(
                                <Select
                                    loading={loadingAds}
                                    className={'_select_ads_to_import_contact'}
                                    showSearch
                                    allowClear={true}
                                    defaultActiveFirstOption={false}
                                    placeholder={t('contact.select_ads')}
                                    notFoundContent={loadingAds ? <Spin size="small" /> : <Empty description={t("table.empty")}/>}
                                    onSearch={this.onSearch}
                                    onFocus={this.onFocus}
                                    filterOption={false}
                                >
                                    {adsItems.length > 0 && adsItems.map((item) => (
                                        <Select.Option
                                            key={item.id}
                                            value={item.id}
                                            className={"_select_ads_item"}
                                        >
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        )
    }
}

PopupImportContact = Form.create()(PopupImportContact);
export {PopupImportContact};