import React, {Component} from 'react';
import {Button, Checkbox, Col, DatePicker, Empty, Icon, Input, Row, Select} from 'antd';
import * as moment from 'moment';
import * as _ from 'lodash';
import queryString from 'query-string';
import {toBoolean} from "../../utils/helper";
import SelectCountry from "../../components/SelectCountry";
import SelectProvince from "../../components/SelectProvince";
import SelectCity from "../../components/SelectCity";
import SelectCourier from "../../components/SelectCourier";
import SelectVendor from "../../components/SelectVendor";
import SelectCampaign from "../../components/SelectCampaign";
import SelectProduct from "../../components/SelectProduct";
import SelectUser from "../../components/SelectUser";
import {LocalStore} from "../../utils/LocalStore";
import SelectRegion from "../../components/SelectRegion";
import LocationService from "../../services/LocationService";
import {HAS_DELIVERY_CASE_DATA} from '../../utils/const';

const Option = Select.Option;
const RangePicker = DatePicker.RangePicker;

const dateFormatFrom = "YYYY-MM-DD 00:00:00";
const dateFormatTo = "YYYY-MM-DD 23:59:59";

class Filter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            expanded : false,
            order: ["updatedAt desc"],
            gridKeyword: 8,
            gridCampaign: 8,
            gridProduct: 8,
            gridAssignTo: 8,
            gridTotalCall: 8,
            gridButton: 8,
            classButton: "",
            regions: [],
            form: {
                order: ["createdAt DESC"],
                ...this.getUrlQuery()
            },
            hasDeliveryCaseData: HAS_DELIVERY_CASE_DATA,
        };
    }

    componentDidMount() {
        const query = this.getUrlQuery();
        if (_.has(query, 'country_id')) {
            this.fetchRegions({
                countryId: query.country_id,
                parentId: 'null'
            })
        }
    }

    getUrlQuery = () => {
        const query = this.props.getFilterFromLocation();
        const fields = ['macro_region_ids'];
        for (let field of fields) {
            if (_.has(query, field)) {
                query[field] = query[field].toString().split(',').map(id => parseInt(id));
            }
        }

        return {...query};
    };

    toggleFormFilter = () => {
        const {form} = this.state;
        form.expanded = !toBoolean(this.state.expanded);
        this.setState({form}, () => {
            this.props.pushQueryString(queryString.stringify(form));
        });
    };

    onChangeInput = (key, e) => {
        const {form} = this.state;
        if ( key === 'code' ) {
            const trimCodeValues = [...e]?.map(value => value?.trim());
            form[key] = trimCodeValues.toString();
        } else {
            form[key] = e.target.value;
        }
        this.setState({form});
    };

    onChangeRangePicker = (key, ranges) => {
        const {form} = this.state;
        form[`${key}_from`] = ranges[0] ? ranges[0].format(dateFormatFrom): null;
        form[`${key}_to`] = ranges[1] ? ranges[1].format(dateFormatTo): null;
        this.setState({
            form
        });
    };

    onFilter = () => {
        const {form} = this.state;
        const filter = {...form};
        filter.time_zone = LocalStore.getInstance().read("timezone");

        _.map(filter, (v, k) => {
            if (Array.isArray(v)) {
                filter[k] = v.join(',');
            }

            if (['created_at_from', 'created_at_to', 'delivery_date_from', 'delivery_date_to'].indexOf(k) >= 0) {
                filter[k] = moment(v).toISOString()
            }
        });

        this.props.pushQueryString(queryString.stringify(filter));
    };

    clearFilter = () => {
        const expanded = this.state.form.expanded;
        this.setState({form: {expanded}});
        this.props.pushQueryString(`expanded=${expanded}`);
    };

    getOrderStatus = () => {
        const {t} = this.props;
        return [
            {key:'DRAFT', label: t("order_status.DRAFT")},
            {key:'NEW', label: t("order_status.NEW")},
            {key:'READY', label: t("order_status.READY")},
            {key:'HOLD', label: t("order_status.HOLD")},
            {key:'CANCELLED', label: t("order_status.CANCELLED")},
            {key:'PICKED_UP', label: t("order_status.PICKED_UP")},
            {key:'DELIVERING', label: t("order_status.DELIVERING")},
            {key:'DELIVERED', label: t("order_status.DELIVERED")},
            {key:'EXCHANGE', label: t("order_status.EXCHANGE")},
            {key:'RETURNING', label: t("order_status.RETURNING")},
            {key:'RETURNED', label: t("order_status.RETURNED")},
			{key:'DELIVERY_FAILED', label: t("order_status.DELIVERY_FAILED")},
        ]
    };

    getStockIssueStatus = () => {
        const {t} = this.props;
        return [
            {key: 'exported', value: "true", label: t("stock_issue.exported")},
            {key: 'not_export', value: "false", label: t("stock_issue.not_export")}
        ]
    };

    fetchRegions = (filter = {}) => {
        LocationService.getRegions(filter, (error, regions) => {
            if (!error) {
                this.setState({regions});
            }
        })
    };

    onSelectChange = (key, value) => {
        const {form} = this.state;
        form[key] = value;

        if(key === "country_id"){
            form.province_id = undefined;
            form.city_id = undefined;
            form.courier_service_id = undefined;
            form.macro_region_ids = undefined;

            this.fetchRegions({
                countryId: value,
                parentId: 'null'
            });
        }

        if (key === 'province_id') {
            form.city_id = undefined;
        }

        this.setState({form});
    };

    handleCheckbox = (field, e) => {
        const {form} = this.state;
        form[field] = e.target.checked;
        this.setState({form});
    };

    render() {
        const {t} = this.props;
        const {gridKeyword, gridCampaign, gridProduct,
            gridButton, form, regions, hasDeliveryCaseData} = this.state;
        let orderStatusesList = this.getOrderStatus();
        let StockIssueStatusList = this.getStockIssueStatus();

        return (
            <div className="contact-filter filter _order-filter">
                <div>
                    <Row gutter={20} className="form-filter">
                        <Col span={gridKeyword} className="form-filter-control">
                            <label>{t("order_list.code")}</label>
                            <Select
                                className={"_filter_code"}
                                placeholder={t("order_list.code")}
                                style={{width:'100%'}}
                                mode={'tags'}
                                value={form.code ? form.code?.split(',') : []}
                                onChange={this.onChangeInput.bind(this, 'code')}
                                maxTagCount={1}
                            >
                                {form.code && form.code?.split(',').map((value) => {
                                    if ( value ) {
                                        return <Option key={value}>{value}</Option>
                                    }
                                })}
                            </Select>
                        </Col>
                        <Col span={gridKeyword} className="form-filter-control">
                            <label>{t("contact.fullname_phone_number")}</label>
                            <Input
                                className={"_filter_phone"}
                                placeholder={t("contact.fullname_phone_number")}
                                suffix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                value={form.keyword}
                                onChange={this.onChangeInput.bind(this, 'keyword')}
                                onPressEnter={this.onFilter}
                            />
                        </Col>

                        <Col span={gridCampaign} className="form-filter-control">
                            <label>{t("contact.campaign")}</label>
                            <SelectCampaign
                                t={this.props.t}
                                value={form.campaign_id ? parseInt(form.campaign_id): undefined}
                                onChange={this.onSelectChange.bind(this, 'campaign_id')}
                            />
                        </Col>
                        <Col span={gridProduct} className="form-filter-control">
                            <label>{t("contact.product")}</label>
                            <SelectProduct
                                t={this.props.t}
                                value={form.product_id ? parseInt(form.product_id): undefined}
                                onChange={this.onSelectChange.bind(this, 'product_id')}
                            />
                        </Col>
                        {toBoolean(form.expanded) && (
                            <>
                                <Col span={8} className="form-filter-control">
                                    <label>{t("order_list.status")}</label>
                                    <Select
                                        className={"_filter_order_status"}
                                        placeholder={t("contact.select_status")}
                                        notFoundContent={<Empty description={t("table.empty")} />}
                                        mode="multiple"
                                        value={form.status ? form.status: undefined}
                                        style={{width: "100%"}}
                                        onChange={this.onSelectChange.bind(this, 'status')}
                                    >
                                        {orderStatusesList.map((item) => (
                                            <Option key={item.key} value={item.key} className={`_call_status_item _call_status_item_${item.key}`}>{item.label}</Option>
                                        ))}
                                    </Select>
                                </Col>

                                <Col span={8} className="form-filter-control">
                                    <label>{t("order_list.ad_creator")}</label>
                                    <SelectUser
                                        className={"_filter_ads_created_by"}
                                        value={form.ads_creator}
                                        onChange={this.onSelectChange.bind(this, 'ads_creator')}
                                        searchTerm={'username'}
                                        valueField={'username'}
                                        t={this.props.t}
                                    />
                                </Col>

                                <Col span={8} className="form-filter-control">
                                    <label>{t("order_detail.created_by")}</label>
                                    <SelectUser
                                        className={"_filter_created_by"}
                                        value={form.created_by ? parseInt(form.created_by): undefined}
                                        onChange={this.onSelectChange.bind(this, 'created_by')}
                                        searchTerm={'ids'}
                                        valueField={'id'}
                                        t={this.props.t}
                                    />
                                </Col>

                                <Col span={8} className="form-filter-control">
                                    <label>{t("order_list.country")}</label>
                                    <SelectCountry
                                        t={this.props.t}
                                        value={form.country_id ? parseInt(form.country_id): undefined}
                                        onChange={this.onSelectChange.bind(this, 'country_id')}
                                    />
                                </Col>

                                <Col span={8} className="form-filter-control">
                                    <label>{"Region"}</label>
                                    <SelectRegion
                                        selectProps={{
                                            disabled: !form.country_id,
                                            mode: 'multiple'
                                        }}
                                        items={regions}
                                        value={form.macro_region_ids}
                                        onChange={this.onSelectChange.bind(this, 'macro_region_ids')}
                                    />
                                </Col>

                                <Col span={8} className="form-filter-control">
                                    <label>{t("order_list.province")}</label>
                                    <SelectProvince
                                        t={this.props.t}
                                        value={form.province_id ? parseInt(form.province_id): undefined}
                                        countryId={form.country_id}
                                        onChange={this.onSelectChange.bind(this, 'province_id')}
                                        selectProps={{
                                            disabled: !form.country_id
                                        }}
                                    />
                                </Col>

                                <Col span={8} className="form-filter-control">
                                    <label>{t("order_list.district")}</label>
                                    <SelectCity
                                        t={this.props.t}
                                        value={form.city_id ? parseInt(form.city_id): undefined}
                                        provinceId={form.province_id}
                                        onChange={this.onSelectChange.bind(this, 'city_id')}
                                        selectProps={{
                                            disabled: !form.province_id
                                        }}
                                    />
                                </Col>

                                <Col span={8} className="form-filter-control">
                                    <label>{t("order_list.courier")}</label>
                                    <SelectCourier
                                        t={this.props.t}
                                        value={form.courier_service_id ? parseInt(form.courier_service_id): undefined}
                                        countryId={form.country_id}
                                        onChange={this.onSelectChange.bind(this, 'courier_service_id')}
                                        selectProps={{
                                            disabled: !form.country_id
                                        }}
                                    />
                                </Col>

                                <Col span={8} className="form-filter-control">
                                    <label>{t("contact.created_at")}</label>
                                    <RangePicker
                                        placeholder={[t("contact.from"), t("contact.to")]}
                                        className={"_filter_created_at"}
                                        value={[
                                            form.created_at_from ? moment(form.created_at_from): null,
                                            form.created_at_to ? moment(form.created_at_to): null
                                        ]}
                                        onChange={this.onChangeRangePicker.bind(this, 'created_at')}
                                        style={{width: '100%'}}
                                        disabledDate={current => current.isSameOrBefore(moment('2021-01-01'))}
                                    />
                                </Col>

                                <Col span={8} className="form-filter-control">
                                    <label>{t("order_list.deliveryDate")}</label>
                                    <RangePicker
                                        placeholder={[t("contact.from"), t("contact.to")]}
                                        className={"_filter_delivery_date"}
                                        value={[
                                            form.delivery_date_from ? moment(form.delivery_date_from): null,
                                            form.delivery_date_to ? moment(form.delivery_date_to): null
                                        ]}
                                        onChange={this.onChangeRangePicker.bind(this, 'delivery_date')}
                                        style={{width: '100%'}}
                                        disabledDate={current => current.isSameOrBefore(moment('2021-01-01'))}
                                    />
                                </Col>

                                <Col span={8} className="form-filter-control">
                                    <label>{t("order_list.vendor")}</label>
                                    <SelectVendor
                                        t={this.props.t}
                                        selectProps={{
                                            mode: 'multiple'
                                        }}
                                        value={form.vendor_ids}
                                        onChange={this.onSelectChange.bind(this, 'vendor_ids')}
                                    />
                                </Col>

                                {this.props.countryId && this.props.countryId === 1726 && (
                                    <Col span={8} className="form-filter-control pd-t-30">
                                        <Checkbox
                                            className="_filter_manila"
                                            checked={form.city_metro}
                                            onChange={this.handleCheckbox.bind(this, 'city_metro')}
                                            size="small"
                                        >
                                            <span>{t("stock_issue_detail.not_in_manila")}</span>
                                        </Checkbox>

                                    </Col>
                                )}

                                <Col span={8} className="form-filter-control">
                                    <label>{t("order.delivery_case")}</label>
                                    <Select
                                        className={"_filter_calls"}
                                        placeholder={t("order.delivery_case")}
                                        notFoundContent={(<Empty description={t("table.empty")} />)}
                                        style={{width: "100%"}}
                                        value={form && form.has_delivery_case ? form.has_delivery_case : []}
                                        onChange={this.onSelectChange.bind(this, 'has_delivery_case')}
                                    >
                                        {hasDeliveryCaseData.map((item, index) => (
                                            <Option
                                                key={index}
                                                value={item.value}
                                                style={{color:"black"}}
                                            >
                                                {t(item.key)}
                                            </Option>
                                        ))}
                                    </Select>
                                </Col>
                            </>
                        )}

                        <Col span={gridButton} xs={24} className={`form-filter-control filter-button ${toBoolean(form.expanded) ? 'expand' : ''}`}>
                            <Button type="primary" className="mg-r-10 _filter_button_filter" onClick={this.onFilter}>{t("button.filter")}</Button>
                            <Button type="default" className="mg-r-10 _filter_button_clear" onClick={this.clearFilter}>{t("contact.clear")}</Button>
                            <a className={"_filter_show_more"} onClick={this.toggleFormFilter}>{toBoolean(form.expanded) ? t("contact.filter_less") : t("contact.filter_more")} <Icon className="expand-icon" type={toBoolean(form.expanded) ? "up" : "down"} /></a>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export {Filter}
