import React from 'react';
import {Form, Button} from "antd";
import VendorService from "../../../services/VendorService";
import {toastr} from "../../../utils/toastr";
import {SelectRole} from "./SelectRole";
import UserService from "../../../services/UserService";
import {VENDORS_SCOPES} from "../../../utils/api/Scopes";

class Role extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: null,
            loading: false
        };
    }

    onSave = () => {
        const {t, form, vendor} = this.props;
        form.validateFields((error, values) => {
            if(!error) {
                this.setState({loading: true});
                let value = values.roles;
                VendorService.updateRoles(vendor.id, {roles: value}, (error, response) => {
                    this.setState({loading: false});
                    if (!error) {
                        toastr.success(t('message.success'));
                        this.props.fetchVendor();
                    } else {
                        toastr.error(t('message.fail'));
                    }
                });
            }
        });
    };

    render() {
        const {t, defaultRoles, vendor} = this.props;
        const {getFieldDecorator} = this.props.form;
        const {loading} = this.state;
        return (
            <div className="roles-container">
                <Form layout="vertical">
                    <Form.Item>
                        {getFieldDecorator('roles', {
                            rules: [
                                {required: true, message: t('message.required')}
                            ],
                            initialValue: vendor.roles
                        })(
                            <SelectRole
                                {...this.props}
                                roles={defaultRoles}
                            />
                        )}
                    </Form.Item>
                </Form>

                {UserService.isAllowed(VENDORS_SCOPES.VENDORS_UPDATE_ROLES) && (
                    <div className="mg-t-10">
                        <Button
                            type="primary"
                            onClick={this.onSave}
                            loading={loading}
                        >{t("button.save")}</Button>
                    </div>
                )}
            </div>
        )
    }
}

Role = Form.create()(Role);

export {Role};