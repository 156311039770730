import React, {Component} from 'react';
import {FormUpdateProject} from "./FormUpdateProject";

class PopupUpdateProject extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    handleCancel = () => {
        this.props.showPopupUpdateProject(false);
        this.formRef.props.form.resetFields();
    };

    handleUpdate = () => {
        const form = this.formRef.props.form;
        form.validateFields((err, values) => {
            if(!err) {
                values.name = values.name.trim();
                this.props.onUpdateProject(values);
                form.resetFields();
            }
        });
    };

    saveFormRef = (formRef) => {
        this.formRef = formRef;
    };

    render() {
        return (
            <>
                <div>
                    <FormUpdateProject
                        {...this.props}
                        wrappedComponentRef={this.saveFormRef}
                        onCancel={this.handleCancel}
                        onUpdate={this.handleUpdate}
                        products={this.props.products}
                    />
                </div>
            </>
        );
    }
}

export {PopupUpdateProject}