import React, {Component} from 'react';
import {Col, Divider, Form as AntForm, Input, Row, Select} from 'antd';
import {arrayConcat} from "../../../../utils/helper";
import {WholeSaleConfig} from "./WholeSaleConfig";
import CampaignService from "../../../../services/CampaignService";
import * as _ from "lodash";

const Option = Select.Option;
class Form extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: 1,
            projectSelected: null,
            adsets: [],
            configWholeSalePrice: [],
            loadingProject: false,
        };
    }

    componentDidMount = () => {
        this.fetchCampaign();
    };

    fetchCampaign = (filter = {}, cb) => {
        let id = this.props.match.params.id;
        let filterCond = {
            include: ['project'],
        };
        filterCond = _.mergeWith(filterCond, filter, arrayConcat);
        CampaignService.getById(id,filterCond,(error, response) => {

            if(!error) {
                let configWholeSale = response.configWholeSale;
                _.forEach(configWholeSale, (item) => {
                    _.forEach(item.wholeSalePrice, (row) => {row.uid = this.getUid()});
                });

                this.setState({
                    configWholeSalePrice: configWholeSale,
                    facebookIds: response.facebookIds,
                    name: response.name,
                    project: _.get(response, 'project.name'),
                    projectId : response.projectId
                });
            }
            if(typeof cb === 'function') cb(error, response);
        });
    };

    onChange = (e) => {
        this.setState({
            value: e.target.value,
        });
    };

    getConfigWholeSale = (value) => {
        this.props.getConfigWholeSale(value);
    };

    resetDataSource = (key) => {
        this.props.resetDataSource(key);
    };

    onAddWholeSaleConfig = (index) => {
        const max = 5;
        let {configWholeSalePrice} = this.state;
        let config = configWholeSalePrice[index];
        if(config.wholeSalePrice.length <= max-1) {
            config.wholeSalePrice.push({
                from: "",
                to: "",
                price: "",
                uid: this.getUid()
            });
            configWholeSalePrice[index] = config;
            this.setState({configWholeSalePrice});
        }
    };

    getUid = () => {
        return _.uniqueId('uid')
    };

    onRemoveWholeSaleConfig = (index,uid) => {
        let {configWholeSalePrice} = this.state;
        let config = configWholeSalePrice[index];

        let indexUid = _.findIndex(config.wholeSalePrice, (item) => item.uid == uid);
        config.wholeSalePrice.splice(indexUid, 1);
        configWholeSalePrice[index] = config;
        this.setState({configWholeSalePrice});
    };

    render() {
        const {t} = this.props;
        const {getFieldDecorator} = this.props.form;
        const {configWholeSalePrice} = this.state;
        console.log(this.state)
        return (
            <>
                <div className="white-box">
                    <div className="form-header">
                        <h3 className="pd-l-30 pd-r-30 pd-t-15 pd-bt-15 mg-0">{t('campaign.update_campaign')}</h3>
                        <div className="h-line"/>
                    </div>
                    <div className="form-body pd-30 pd-t-10 pd-bt-10">
                        <Row gutter={20}>
                            <Col span={12}>
                                <div className="form-item">
                                    <AntForm.Item
                                        label={t("campaign.project")}
                                        className="project _project"
                                    >
                                        {getFieldDecorator('project', {
                                            rules: [
                                                {required: true, message: t("project.validate_select")},
                                            ],
                                            initialValue: this.state.projectId
                                        })(
                                            <Select
                                                showSearch
                                                disabled={true}
                                            >
                                                <Option value={this.state.projectId}>{this.state.project}</Option>
                                            </Select>
                                        )}
                                    </AntForm.Item>
                                </div>
                                <div className="form-item">
                                    <AntForm.Item className={"form-item-item"}
                                                  label={<span className="bold-400">{t("campaign.add_name")}</span>}>
                                        {getFieldDecorator('name', {
                                            rules: [
                                                {required: true, message: t("campaign.validate_name")},
                                                {max: 160, message: t("products.name_length")}
                                            ],
                                            initialValue: this.state.name
                                        })(
                                            <Input placeholder={t("campaign.name_placeholder")} className="_project-name"/>
                                        )}
                                    </AntForm.Item>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div className="form-item">
                                    <AntForm.Item className={"form-item-item"}
                                                  label={<span className="bold-400">Facebook Campaign ID</span>}>
                                        {getFieldDecorator('facebookIds', {
                                            initialValue: this.state.facebookIds
                                        })(
                                            <Select mode={'tags'} placeholder={t("campaign.facebook_id")} className="_campaign-facebook-id"/>
                                        )}
                                    </AntForm.Item>
                                </div>
                            </Col>

                            <Col span={24}>
                                <Divider/>
                            </Col>

                            <Col span={24}>
                                {!!configWholeSalePrice.length && (
                                    <WholeSaleConfig
                                        {...this.props}
                                        configWholeSalePrice={configWholeSalePrice}
                                        onAddWholeSaleConfig={this.onAddWholeSaleConfig}
                                        onRemoveWholeSaleConfig={this.onRemoveWholeSaleConfig}
                                    />
                                )}
                            </Col>
                        </Row>
                    </div>
                </div>
            </>
        )
    }
}

export {Form};
