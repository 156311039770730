import EagleClient from "../utils/api/EagleClient";
export default class ReportService {

    /**
     * Lấy danh sách báo cáo
     */
    static getAllReport(filter, cb) {
        EagleClient.request({
            endpoint: `/Reports`,
            method: "GET",
            queryString: filter
        }, cb);
    }

    /**
     * Tạo báo cáo
     * @param data
     * @param cb
     */
    static createReport(data, cb) {
        EagleClient.request({
            endpoint: `/Reports`,
            method: "POST",
            body: data
        }, cb);
    }

    /**
     * Chỉnh sửa báo cáo
     * @param id
     * @param data
     * @param cb
     */
    static updateReport(id, data, cb) {
        EagleClient.request({
            endpoint: `/Reports/${id}`,
            method: "PATCH",
            body: data
        }, cb);
    }

    /**
     * Xóa báo cáo
     * @param id
     * @param cb
     */

    static detailReportById(id, filter = {}, cb) {
        EagleClient.request({
            endpoint: `/Reports/${id}`,
            method: "GET",
            queryString: {filter:filter}
        }, cb);
    }

    static usersWatchStatistic(filter,id, cb) {
        EagleClient.request({
            endpoint: `/Reports/${id}/users${filter}`,
            method: "GET",
        }, cb);
    }

    static addUsersWatchStatistic(statistic_id,userId, cb) {
        EagleClient.request({
            endpoint: `/Reports/${statistic_id}/users`,
            method: "POST",
            body: userId
        }, cb);
    }

    static deleteUserFromStatistic(statistic_id,userId, cb) {
        EagleClient.request({
            endpoint: `/Reports/${statistic_id}/users`,
            method: "DELETE",
            body: userId
        }, cb);
    }

    static getListUserNotInStatistic(filter,id, cb) {
        EagleClient.request({
            endpoint: `/Reports/${id}/users-not-in${filter}`,
            method: "GET",
        }, cb);
    }

    static deleteReport(id, cb) {
        EagleClient.request({
            endpoint: `/Reports`,
            method: "DELETE",
            body: id
        }, cb);
    }

    static addGroups = (id, payload, cb) => {
        EagleClient.request({
            endpoint: `/Reports/${id}/groups`,
            method: "POST",
            body: payload
        }, cb);
    };

    static removeGroups = (id, payload, cb) => {
        EagleClient.request({
            endpoint: `/Reports/${id}/groups`,
            method: "DELETE",
            body: payload
        }, cb);
    };

    static getGroups = (id, filter, cb) => {
        EagleClient.request({
            endpoint: `/Reports/${id}/groups`,
            method: "GET",
            queryString: filter
        }, cb);
    };

    static getGroupsNotIn = (id, filter, cb) => {
        EagleClient.request({
            endpoint: `/Reports/${id}/groups-not-in`,
            method: "GET",
            queryString: filter
        }, cb);
    };

}