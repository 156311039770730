import React, {Component} from 'react';
import {translate} from "react-i18next";
import StandardLayout from "../../components/StandardLayout";
import {Header} from "./Header";
import {FormStringeeAdd} from "./FormStringeeAdd";
import UserService from '../../services/UserService';
import { STRINGEE } from '../../utils/api/Scopes';

class Add extends Component {

    constructor(props) {
        super(props);
        this.state = {
            countries: [],
            package:[],
            defaultRoles:[],
            managers:[]
        };

    }

    componentWillMount() {
        if (!UserService.isAllowed(STRINGEE.VIEW_ADD_PAGE)) {
            this.props.history.replace('/403')
        }
    };

    componentDidMount = () => {

    };

    render() {
        const {t} = this.props;
        return (
            <StandardLayout {...this.props} title={"Stringee"}>
                <div className="vendorAdd-list-page list-page">
                    <Header {...this.props} iscreate={true}/>
                    <FormStringeeAdd
                        {...this.props}
                    />
                </div>
            </StandardLayout>
        );
    }
}

export default (translate())(Add)
