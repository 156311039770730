import React from 'react';
import {Select} from 'antd';
import {objectGet} from "../../../utils/helper";

class SummaryContactStatuses extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            contactStatusSelected: null,
            visible: true
        };
        this.refInput = React.createRef();
    }

    static getDerivedStateFromProps(nextProps, state) {
        const {contactStatusSelected, visible} = state;
        if (!contactStatusSelected && ('value' in nextProps) && Object.keys(nextProps.value).length) {
            let item = nextProps.value;
            let contactStatusSelected = {
                id: item.id,
                key: item.key,
                isEnd: item.isEnd
            };
            return {
                contactStatusSelected
            };
        }

        return null;
    }

    onChange = (value, option) => {
        let contactStatusSelected = JSON.parse(value);
        this.setState({
            contactStatusSelected,
        }, () => {
            this.props.onChange(contactStatusSelected, option);
        });
    };

    render() {
        const {items} = this.props;
        const {contactStatusSelected, visible} = this.state;
        return (
            <>
                <div ref={this.refInput} className={!visible ? 'hide' : 'com-contact-statuses-container'}>
                    <Select
                        style={{width: 200}}
                        value={JSON.stringify(contactStatusSelected)}
                        onChange={this.onChange}
                        className={"_select_contact_status"}
                        onBlur={this.onBlur}
                        getPopupContainer={() => this.refInput.current}
                    >
                        {Array.isArray(items) && items.map((item) => (
                            <Select.Option
                                className={`_select_contact_status_item _select_contact_status_item_${item.key.toString().replace(/[.]+/gm,'_')}`}
                                key={item.key}
                                value={JSON.stringify({
                                    id: item.id,
                                    key: item.key,
                                    isEnd: item.isEnd
                                })}
                            >{item.key}</Select.Option>
                        ))}
                    </Select>
                </div>
            </>
        )
    }

    handleClickOutSide = (e) => {
        if(this.refInput && !this.refInput.current.contains(e.target)) {
            this.setState({visible: false});
            this.props.onHide(e);
        }
    };

    componentDidMount = () => {
        document.addEventListener('click', this.handleClickOutSide, false)
    };

    componentWillUnmount = () => {
        document.removeEventListener('click', this.handleClickOutSide, false);
    };
}

SummaryContactStatuses.defaultProps = {
    items: [],
    value: [],
    onChange: (value, option) => {},
    onHide: (e) => {}
};

export {SummaryContactStatuses};