import EagleClient from "../utils/api/EagleClient";
import _ from 'lodash';

export default class ProjectService {

    static getProjects(filter, cb) {
        let endpoint = "/CampaignProjects";
        EagleClient.request({
            endpoint: endpoint,
            method: "GET",
            mode: "free",
            queryString: filter
        }, cb);
    }

    static createProject(data, cb) {
        EagleClient.request({
            endpoint: "/CampaignProjects",
            method: "POST",
            body: data
        }, cb);
    }

    static updateProject(data, cb) {
        EagleClient.request({
            endpoint: "/CampaignProjects/"+data.id,
            method: "PATCH",
            body: data
        }, cb);
    }

    static getById(id, cb) {
        let endpoint = "/CampaignProjects/"+id;
        EagleClient.request({
            endpoint: endpoint,
            method: "GET"
        }, cb);
    }
}