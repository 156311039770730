import React, {Component} from 'react';
import {Button, Col, DatePicker, Icon, Input, Row} from 'antd';
import moment from 'moment';
import {LocalStore} from "../../utils/LocalStore";

const RangePicker = DatePicker.RangePicker;
const dateFormat = 'DD/MM/YYYY';
export class Filter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            form: {}
        };
    }

    componentDidMount = () => {
        this.setState({
            form: {...this.props.getFilterFromLocation()}
        })
    };

    onChangeInput = (key, e) => {
        this.setState({
            form: {
                ...this.state.form,
                [key]: e.target.value
            }
        });
    };

    onChangeRangePicker = (fromField, toField, values) => {
        this.setState({
            form: {
                ...this.state.form,
                [fromField]: values[0] ? values[0].format('YYYY-MM-DD 00:00:00') : null,
                [toField]: values[1] ? values[1].format('YYYY-MM-DD 23:59:59') : null
            }
        });
    };

    onFilter = () => {
        this.props.pushCleanFilterToHistory({
            ...this.state.form,
            timeZone: LocalStore.getInstance().read('timezone')
        });
    };

    resetStateFilter = (cb = null) => {
        this.setState({
            form: {}
        }, cb)
    };

    clearFilter = () => {
        this.resetStateFilter(() => {
            this.props.pushCleanFilterToHistory({})
        });
    };


    render() {
        const {t} = this.props;
        const {form} = this.state;
        return (
            <div className="contact-filter filter">
                <div>
                    <Row gutter={20} className="form-filter">
                        <Col span={8} className="form-filter-control">
                            <label>{t("contact.phone")}</label>
                            <Input
                                placeholder={t("contact.phone")}
                                suffix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                value={form.phone}
                                onChange={this.onChangeInput.bind(this, 'phone')}
                                onPressEnter={this.onFilter}
                            />
                        </Col>

                        <Col span={8} className="form-filter-control">
                            <label>{t("contact.created_at")}</label>
                            <RangePicker
                                format={dateFormat}
                                placeholder={[t("contact.from"), t("contact.to")]}
                                value={[
                                    form.createdAtFrom ? moment(form.createdAtFrom): null,
                                    form.createdAtTo ? moment(form.createdAtTo): null
                                ]}
                                onChange={this.onChangeRangePicker.bind(this, 'createdAtFrom', 'createdAtTo')}
                                style={{width: '100%'}}
                            />
                        </Col>

                        <Col span={24} className={`form-filter-control filter-button`}>
                            <Button type="primary" className="mg-r-10 _filter_button_filter" onClick={this.onFilter}>{t("button.filter")}</Button>
                            <Button type="default" className="mg-r-10 _filter_button_clear" onClick={this.clearFilter}>{t("contact.clear")}</Button>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}