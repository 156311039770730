import React, {Component} from 'react';
import {Button, Col, DatePicker, Empty, Icon, Input, InputNumber, Row, Select, Spin} from 'antd';
import * as _ from 'lodash';
import moment from 'moment';
import {ButtonToggle} from "../../components/ButtonToggle";
import {LocalStore} from "../../utils/LocalStore";
import SelectCountry from "../../components/SelectCountry";

const Option = Select.Option;
const InputGroup = Input.Group;
const RangePicker = DatePicker.RangePicker;

const defaultForm = {
    vendor_id: "",
    vendor_ids: [],
    keyword: "",
    campaign_ids: [],
    product_ids: [],
    contact_statuses: [],
    call_statuses: [],
    ads_created_by: [],
    assign_to: [],
    appointment_at_from: "",
    appointment_at_to: "",
    created_at_from: "",
    created_at_to: "",
    assigned_at_from: "",
    assigned_at_to: "",
    delivery_date_from: "",
    delivery_date_to: "",
    last_call_from: "",
    last_call_to: "",
    total_call_operation: "",
    total_call_value: "",
    total_call_value_from: "",
    total_call_value_to: "",
    order: JSON.stringify(["updatedAt desc"]),
};

class Filter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            checkAllContactStatus: false,
            callOperations: [
                {key: "contact.search", value: ""},
                {key: "contact.equal", value: "eq"},
                {key: "contact.more", value: "gte"},
                {key: "contact.less", value: "lte"},
                {key: "contact.between", value: "between"}
            ],

            gridKeyword: 8,
            gridCampaign: 8,
            gridProduct: 8,
            gridAssignTo: 8,
            gridTotalCall: 8,
            gridButton: 8,
            classButton: "",

            form: {
                ...defaultForm,
                ...this.getUrlQuery()
            }
        };
        this.onSelectSearch = _.debounce(this.onSelectSearch, 500);
    }

    componentDidMount = () => {

    };

    getUrlQuery = () => {
        const query = this.props.getFilterFromLocation();
        const fields = ['assign_to', 'call_statuses', 'vendor_ids', 'product_ids', 'campaign_ids'];
        for (let field of fields) {
            if (_.has(query, field)) {
                query[field] = query[field].toString().split(',');
            }
        }

        return {...query};
    };

    toggleAllContactStatus = () => {
        let {contactStatuses} = this.props;
        let localContactStatuses = this.state.form.contact_statuses;
        if(localContactStatuses.length === contactStatuses.length) {
            this.setState({
                form: {
                    ...this.state.form,
                    contact_statuses: []
                }
            });
        } else {
            for(let item of contactStatuses) {
                if(localContactStatuses.indexOf(item.id) < 0) {
                    localContactStatuses.push(item.id);
                }
            }
            this.setState({
                form: {
                    ...this.state.form,
                    contact_statuses: localContactStatuses
                }
            });
        }
    };

    onToggleContactStatus = (item) => {
        let {contact_statuses} = this.state.form;
        let index = contact_statuses.indexOf(item.id);
        let exist = index >= 0;
        if(exist) {
            contact_statuses.splice(index, 1);
        } else {
            contact_statuses.push(item.id);
        }
        this.setState({
            form: {
                ...this.state.form,
                contact_statuses
            }
        });
    };

    checkContactStatusActive = (item) => {
        let {contactStatuses} = this.props;
        for(let id of contactStatuses) {
            if(parseInt(id) === parseInt(item.id)) {
                return true;
            }
        }
        return false;
    };

    onChangeInput = (key, e) => {
        this.setState({
            form: {
                ...this.state.form,
                [key]: e.target.value
            }
        });
    };

    onChangeInputNumber = (key, value) => {
        this.setState({
            form: {
                ...this.state.form,
                [key]: value
            }
        })
    };

    onSelectSearch = (key, value) => {
        if(key === 'campaign_ids') {
            this.props.fetchCampaign({
                name: value
            });
        }

        if(key === 'product_ids') {
            this.props.fetchProduct({
                name: value
            });
        }
        if(key === 'ads_created_by') {
            this.props.fetchUserAdsCreate({
                username: value
            });
        }
        if(key === 'assign_to') {
            this.props.fetchUserAssignTo({
                username: value
            });
        }
        if(key === 'call_statuses') {
            this.props.fetchCallStatus({
                value: value
            });
        }
    };

    onSelectChange = (key, value) => {
        this.setState({
            form: {
                ...this.state.form,
                [key]: value
            }
        });

        if (key === 'country_id') {
            this.props.fetchVendors({
                country_id: value
            });

            this.props.fetchRegions({
                countryId: value,
                parentId: 'null'
            });
        }

        if (key === 'vendor_ids' && value.length) {
            const vendorIds = Array.isArray(value) ? value.join(',') : -1;

            this.props.fetchProduct({
                vendor_ids: vendorIds
            });

            this.props.fetchCampaign({
                vendor_ids: vendorIds
            });

            this.props.fetchUserAdsCreate({
                vendor_ids: vendorIds
            });

            this.props.fetchUserAssignTo({
                vendor_ids: vendorIds
            });
        }

        if (key === 'total_call_operation') {
            if (value === 'between') {
                this.setState({
                    gridAssignTo: 6,
                    gridTotalCall: 10,
                    gridButton: 8
                })
            }
            else {
                this.setState({
                    gridAssignTo: 8,
                    gridTotalCall: 8,
                    gridButton: 8
                })
            }
        }
    };

    onChangeRangePicker = (key, values) => {
        const {form} = this.state;
        const beginFormat = 'YYYY-MM-DD 00:00:00';
        const endFormat = 'YYYY-MM-DD 23:59:59';
        if (key === 'created_at') {
            form.created_at_from = values[0] ? values[0].format(beginFormat) : null;
            form.created_at_to = values[1] ? values[1].format(endFormat) : null;
        }
        else if (key === 'delivery_date') {
            form.delivery_date_from = values[0] ? values[0].format(beginFormat) : null;
            form.delivery_date_to = values[1] ? values[1].format(endFormat) : null;
        }
        else if (key === 'assigned_at') {
            form.assigned_at_from = values[0] ? values[0].format(beginFormat) : null;
            form.assigned_at_to = values[1] ? values[1].format(endFormat): null;
        }
        else if (key === 'last_call') {
            form.last_call_from = values[0] ? values[0].format(beginFormat) : null;
            form.last_call_to = values[1] ? values[1].format(endFormat) : null;
        }
        else if (key === 'appointment_at') {
            form.appointment_at_from = values[0] ? values[0].format(beginFormat) : null;
            form.appointment_at_to = values[1] ? values[1].format(endFormat) : null;
        }

        this.setState({form});
    };

    onFilter = () => {
        let filter = {
            ...this.state.form,
            time_zone: LocalStore.getInstance().read("timezone")
        };

         _.map(filter, (v, k) => {
            if (Array.isArray(v)) {
                filter[k] = v.join(',');
            }

            if (['created_at_from', 'created_at_to', 'delivery_date_from', 'delivery_date_to',
                'assigned_at_from', 'assigned_at_to', 'last_call_from', 'last_call_to',
                'appointment_at_from', 'appointment_at_to'
            ].indexOf(k) >= 0) {
                filter[k] = moment(v).toISOString()
            }
        });

         filter = _.omitBy(filter, value => value === "" || value === 'null' || value === null);

         this.props.pushCleanFilterToHistory(filter);
    };

    clearFilter = () => {
        this.setState({
            form: {...defaultForm}
        });
        this.props.pushCleanFilterToHistory({});
    };

    render() {
        const {contactStatuses, campaigns, products, userAssignTo, userAdsCreate, callStatuses ,t,
            loadingCampaign, loadingProduct, loadingUserAdsCreate, loadingUserAssignTo,
            loadingCallStatus, loadingContactStatus, vendors, queryResultSet} = this.props;

        const {callOperations, gridKeyword, gridCampaign, gridProduct,
            gridAssignTo, gridButton, gridTotalCall, form} = this.state;

        let checkAllContactStatus = _.size(form.contact_statuses) === contactStatuses.length;

        return (
            <div className="contact-filter filter">
                <div>
                    <span className="mg-r-20">{t("contact.state")}:</span>
                    <Button
                        onClick={this.toggleAllContactStatus}
                        type={checkAllContactStatus ? 'primary' : 'default'}
                        size="small"
                        className="mg-r-20 _state_all"
                        loading={loadingContactStatus}
                    >{checkAllContactStatus ? t("contact.uncheck") :t("contact.all_state")}
                    </Button>

                    {contactStatuses.map((item, index) => (
                        <ButtonToggle
                            key={index}
                            active={checkAllContactStatus ? checkAllContactStatus : this.checkContactStatusActive(item)}
                            onToggle={this.onToggleContactStatus.bind(this, item)}
                            className={`mg-r-15 _state_item _state_item_${item.key.replace('.', '_')}`}
                        >{item.key}
                        </ButtonToggle>
                    ))}
                </div>
                <div className="divider" />
                <div>
                    <Row gutter={20} className="form-filter">
                        <Col span={gridKeyword} className="form-filter-control">
                            <label>{t("contact.fullname_phone_number")}</label>
                            <Input
                                className={"_keyword"}
                                placeholder={t("contact.fullname_phone_number")}
                                suffix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                value={form.keyword}
                                onChange={this.onChangeInput.bind(this, 'keyword')}
                                onPressEnter={this.onFilter}
                            />
                        </Col>
                        <Col span={8} className="form-filter-control">
                            <label>{t("contact.created_at")}</label>
                            <RangePicker
                                placeholder={[t("contact.from"), t("contact.to")]}
                                className={"_filter_created_at"}
                                value={[
                                    form.created_at_from ? moment(form.created_at_from) : null,
                                    form.created_at_to ? moment(form.created_at_to) : null,
                                ]}
                                disabledDate={current => current.isSameOrBefore(moment('2021-01-01'))}
                                onChange={this.onChangeRangePicker.bind(this, 'created_at')}
                                style={{width: '100%'}}
                            />
                        </Col>
                        <Col span={8} className="form-filter-control">
                            <label>{t("contact.assigned_at")}</label>
                            <RangePicker
                                placeholder={[t("contact.from"), t("contact.to")]}
                                className={"_filter_assigned_at"}
                                value={[
                                    form.assigned_at_from ? moment(form.assigned_at_from) : null,
                                    form.assigned_at_to ? moment(form.assigned_at_to) : null,
                                ]}
                                disabledDate={current => current.isSameOrBefore(moment('2021-01-01'))}
                                onChange={this.onChangeRangePicker.bind(this, 'assigned_at')}
                                style={{width: '100%'}}
                            />
                        </Col>
                        <Col span={8} className="form-filter-control">
                            <label>{"Country"}</label>
                            <SelectCountry
                                t={this.props.t}
                                value={form.country_id}
                                onChange={this.onSelectChange.bind(this, 'country_id')}
                            />
                        </Col>

                        <Col span={8} className="form-filter-control">
                            <label>{t("order_list.vendor")}</label>
                            <Select
                                filterOption={false}
                                className={"_filter_vendor"}
                                placeholder={t("contact.vendor")}
                                mode="multiple"
                                style={{width: "100%"}}
                                value={form.vendor_ids}
                                onChange={this.onSelectChange.bind(this, 'vendor_ids')}
                            >
                                {vendors.map((item, index) => (
                                    <Option key={index} value={item.id.toString()} className={`_vendor_item _vendor_item_${item.id}`}>{item.name}</Option>
                                ))}
                            </Select>
                        </Col>
                        <Col span={gridProduct} className="form-filter-control">
                            <label>{t("contact.product")}</label>
                            <Select
                                filterOption={false}
                                className={"_filter_product"}
                                placeholder={t("contact.select_product")}
                                notFoundContent={loadingProduct ? <Spin size="small" /> :(<Empty description={t("table.empty")} />)}
                                mode="multiple"
                                style={{width: "100%"}}
                                value={form.product_ids}
                                loading={loadingProduct}
                                onSearch={this.onSelectSearch.bind(this, 'product_ids')}
                                onChange={this.onSelectChange.bind(this, 'product_ids')}
                            >
                                {_.uniqBy([...products, ...queryResultSet.products], 'id').map((item, index) => (
                                    <Option key={index} value={item.id.toString()} className={`_product_item _product_item_${item.id}`}>{item.name}</Option>
                                ))}
                            </Select>
                        </Col>
                        <Col span={gridCampaign} className="form-filter-control">
                            <label>{t("contact.campaign")}</label>
                            <Select
                                filterOption={false}
                                className={"_filter_campaign"}
                                placeholder={t("contact.select_campaign")}
                                notFoundContent={(loadingCampaign ? <Spin size="small" /> : <Empty description={t("table.empty")} />)}
                                mode="multiple"
                                style={{width: "100%"}}
                                value={form.campaign_ids}
                                loading={loadingCampaign}
                                onSearch={this.onSelectSearch.bind(this, 'campaign_ids')}
                                onChange={this.onSelectChange.bind(this, 'campaign_ids')}
                            >
                                {_.uniqBy([...campaigns, ...queryResultSet.campaigns], 'id').map((item) => (
                                    <Option key={item.id} value={item.id.toString()} className={`_campaign_item _campaign_item_${item.id}`}>{item.name}</Option>
                                ))}
                            </Select>
                        </Col>

                        <Col span={8} className="form-filter-control">
                            <label>{t("contact.ad_creator")}</label>
                            <Select
                                filterOption={false}
                                className={"_filter_ads_created_by"}
                                placeholder={t("contact.select_ad_creator")}
                                notFoundContent={(loadingUserAdsCreate ? <Spin size="small" /> : <Empty description={t("table.empty")} />)}
                                mode="multiple"
                                style={{width: "100%"}}
                                value={form.ads_created_by}
                                loading={loadingUserAdsCreate}
                                onSearch={this.onSelectSearch.bind(this, 'ads_created_by')}
                                onChange={this.onSelectChange.bind(this, 'ads_created_by')}
                            >
                                {_.uniqBy([...userAdsCreate, ...queryResultSet.createdAdUsers], 'username').map((item, index) => (
                                    <Option key={index} value={item.username} className={`_ads_created_by_item _ads_created_by_item_${item.id}`}>{item.username}</Option>
                                ))}
                            </Select>
                        </Col>
                        <Col span={gridAssignTo} className="form-filter-control">
                            <label>{t("contact.assign_to")}</label>
                            <Select
                                filterOption={false}
                                className={"_filter_assign_to"}
                                placeholder={t("contact.select_assign_to")}
                                notFoundContent={(loadingUserAssignTo ? <Spin size="small" /> : <Empty description={t("table.empty")} />)}
                                mode="multiple"
                                style={{width: "100%"}}
                                value={form.assign_to}
                                loading={loadingUserAssignTo}
                                onSearch={this.onSelectSearch.bind(this, 'assign_to')}
                                onChange={this.onSelectChange.bind(this, 'assign_to')}
                            >
                                {_.uniqBy([...userAssignTo, ...queryResultSet.assignedUsers], 'id').map((item, index) => (
                                    <Option key={index} value={item.id.toString()} className={`_assign_to_item _assign_to_item_${item.id}`}>{item.username}</Option>
                                ))}
                            </Select>
                        </Col>
                        <Col span={8} className="form-filter-control">
                            <label>{t("contact.deliveryDate")}</label>
                            <RangePicker
                                placeholder={[t("contact.from"), t("contact.to")]}
                                className={"_filter_delivery_date"}
                                value={[
                                    form.delivery_date_from ? moment(form.delivery_date_from) : null,
                                    form.delivery_date_to ? moment(form.delivery_date_to) : null,
                                ]}
                                disabledDate={current => current.isSameOrBefore(moment('2021-01-01'))}
                                onChange={this.onChangeRangePicker.bind(this, 'delivery_date')}
                                style={{width: '100%'}}
                            />
                        </Col>
                        <Col span={8} className="form-filter-control">
                            <label>{t("contact.appointment")}</label>
                            <RangePicker
                                placeholder={[t("contact.from"), t("contact.to")]}
                                className={"_filter_appointment_at"}
                                value={[
                                    form.appointment_at_from ? moment(form.appointment_at_from) : null,
                                    form.appointment_at_to ? moment(form.appointment_at_to) : null,
                                ]}
                                disabledDate={current => current.isSameOrBefore(moment('2021-01-01'))}
                                onChange={this.onChangeRangePicker.bind(this, 'appointment_at')}
                                style={{width: '100%'}}
                            />
                        </Col>
                        <Col span={8} className="form-filter-control">
                            <label>{t("contact.callTime")}</label>
                            <RangePicker
                                placeholder={[t("contact.from"), t("contact.to")]}
                                className={"_filter_last_call"}
                                allowClear={true}
                                value={[
                                    form.last_call_from ? moment(form.last_call_from) : null,
                                    form.last_call_to ? moment(form.last_call_to) : null,
                                ]}
                                disabledDate={current => current.isSameOrBefore(moment('2021-01-01'))}
                                onChange={this.onChangeRangePicker.bind(this, 'last_call')}
                                style={{width: '100%'}}
                            />
                        </Col>
                        <Col span={8} className="form-filter-control">
                            <label>{t("contact.status")}</label>
                            <Select
                                allowClear={true}
                                className={"_filter_call_status"}
                                placeholder={t("contact.select_status")}
                                notFoundContent={(loadingCallStatus ? <Spin size="small" /> : <Empty description={t("table.empty")} />)}
                                mode="multiple"
                                style={{width: "100%"}}
                                value={form.call_statuses}
                                loading={loadingCallStatus}
                                onSearch={this.onSelectSearch.bind(this, 'call_statuses')}
                                onChange={this.onSelectChange.bind(this, 'call_statuses')}
                                filterOption={false}
                            >
                                {callStatuses.map((item) => (
                                    <Option key={item.id} value={item.id.toString()} className={`_call_status_item _call_status_item_${item.id}`}>{item.value}</Option>
                                ))}
                            </Select>
                        </Col>
                        <Col span={gridTotalCall} className="form-filter-control">
                            <div>
                                <label>{t("contact.calls")}</label>
                                <InputGroup compact>
                                    <Select
                                        className={"_filter_calls"}
                                        placeholder={t("contact.search")}
                                        notFoundContent={(<Empty description={t("table.empty")} />)}
                                        style={{width: "50%"}}
                                        value={form.total_call_operation}
                                        onChange={this.onSelectChange.bind(this, 'total_call_operation')}
                                    >
                                        {callOperations.map((item, index) => (
                                            <Option
                                                key={index}
                                                value={item.value}
                                                className={`_call_operation_item _call_operation_item_${item.id}`}>{t(item.key)}</Option>
                                        ))}
                                    </Select>

                                    {form.total_call_operation === 'between' ? (
                                        <>
                                            <InputNumber
                                                style={{ width: 80, textAlign: 'center' }}
                                                placeholder={t('contact.from')}
                                                className={"_total_call_from"}
                                                value={form.total_call_value_from}
                                                min={0}
                                                max={100}
                                                onChange={this.onChangeInputNumber.bind(this, 'total_call_value_from')}
                                            />
                                            <Input
                                                style={{
                                                    width: 30, borderLeft: 0, pointerEvents: 'none', backgroundColor: '#fff',
                                                }}
                                                placeholder="~"
                                                disabled
                                            />
                                            <InputNumber
                                                style={{ width: 80, textAlign: 'center', borderLeft: 0 }}
                                                placeholder={t('contact.to')}
                                                className={"_total_call_to"}
                                                value={form.total_call_value_to}
                                                min={0}
                                                max={100}
                                                onChange={this.onChangeInputNumber.bind(this, 'total_call_value_to')}
                                            />
                                        </>
                                    ): (
                                        <InputNumber
                                            defaultValue={""}
                                            min={0}
                                            max={100}
                                            style={{textAlign: 'center'}}
                                            className={"_total_call_value"}
                                            value={form.total_call_value}
                                            onChange={this.onChangeInputNumber.bind(this, 'total_call_value')}
                                        />
                                    )}
                                </InputGroup>
                            </div>
                        </Col>

                        <Col span={gridButton} xs={24} className={`form-filter-control filter-button`}>
                            <Button type="primary" className="mg-r-10 _filter_button_filter" onClick={this.onFilter}>{t("button.filter")}</Button>
                            <Button type="default" className="mg-r-10 _filter_button_clear" onClick={this.clearFilter}>{t("contact.clear")}</Button>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export {Filter}
