import React, {Component} from 'react'
import {
    CAMPAIGNS_SCOPES,
    CONTACT_SCOPES,
    DELIVERYCASE,
    GROUPS_SCOPES,
    ORDER_SCOPES,
    PRODUCT_SCOPES,
    REPORT_SCOPES,
    STRINGEE,
    VENDORS_SCOPES,
    WAREHOUSE_SCOPES
} from "../../utils/api/Scopes";
import {LocalStore} from "../../utils/LocalStore";
import {Icon, Layout, Menu} from 'antd';
import logo from '../../resources/images/logo.png';
import logoTiny from '../../resources/images/logo32.png';
import {getCurrentModule} from "../../utils/helper";
import UserService from "../../services/UserService";

const { Sider } = Layout;

class SideBar extends Component {

    static defaultProps = {
        isShowSideBarItems: true
    };

    constructor(props) {
        super(props);
        this.state = {
            packageValue:{useWareHouse:false,userOrder:false},
            items: [
                {key: "DASHBOARD", title: "sidebar.dashboard", icon: "dashboard", url: "/", permissions: ["DEFAULT"]},
                {key: "CONTACT", title: "sidebar.customer_contact", icon: 'idcard', url: "/contacts", permissions: [CONTACT_SCOPES.CONTACT_VIEW, CONTACT_SCOPES.CONTACT_VIEW_ALL]},
                {key: "ALL_CONTACT", title: "sidebar.all_contact", icon: 'idcard', url: "/all-contacts", permissions: [CONTACT_SCOPES.CONTACT_VIEW_ALL]},
                {key: "RETURN_LEAD", title: 'Return lead', icon: 'idcard', url: "/returned-leads", permissions: [CONTACT_SCOPES.CONTACT_VIEW_ALL]},
                {key: "TELESALE", title: "Telesale", icon: 'idcard', url: "/telesale", permissions: [CONTACT_SCOPES.CONTACT_VIEW, CONTACT_SCOPES.CONTACT_VIEW_ALL]},
                {
                    key: "WAREHOUSE", title: "sidebar.warehouse", icon: 'skin', url: "/warehouse", permissions: [
                        WAREHOUSE_SCOPES.WAREHOUSE_STOCKS_VIEW, WAREHOUSE_SCOPES.WAREHOUSE_ISSUES_VIEW,
                        WAREHOUSE_SCOPES.WAREHOUSE_RECEIPTS_VIEW,WAREHOUSE_SCOPES.WAREHOUSE_VIEW
                    ]
                },
                {key: "ORDER", title: "sidebar.order", icon: 'solution', url: "/orders", permissions: [ORDER_SCOPES.ORDER_VIEW]},
                {key: "ALL_ORDER", title: "sidebar.all_order", icon: 'solution', url: "/all-orders", permissions: [ORDER_SCOPES.ORDER_VIEW_ALL]},
                {key: "DELIVERY_CASES", title: "delivery_case.title", icon: 'issues-close', url: "/delivery-cases", permissions: [DELIVERYCASE.DELIVERY_CASE_VIEW_ALL, DELIVERYCASE.DELIVERY_CASE_VIEW_RESTRICT]},
                {key: "PRODUCT", title: "sidebar.product", icon: "dashboard", url: "/products", permissions: [PRODUCT_SCOPES.PRODUCT_MANAGE]},
                {key: "CAMPAIGN", title: "sidebar.campaign", icon: 'notification', url: "/campaigns", permissions: [CAMPAIGNS_SCOPES.CAMPAIGNS_VIEW,CAMPAIGNS_SCOPES.CAMPAIGNS_PRODUCT_VIEW]},
                {key: "GROUP", title: "sidebar.groups", icon: 'team', url: "/groups", permissions: [GROUPS_SCOPES.GROUPS_VIEW]},
                {key: "REPORT", title: "statistics.title", icon: 'line-chart', url: "/statistics", permissions: [REPORT_SCOPES.REPORT_VIEW]},
                {key: "VENDOR", title: "vendor.title", icon: 'shop', url: "/vendors", permissions: [VENDORS_SCOPES.VENDORS_VIEW]},
                {key: "SETTING", title: "sidebar.setting", icon: 'setting', url: "/setting", permissions: ["DEFAULT"]},
                {key: "STRINGEE", title: "stringee", icon: 'setting', url: "/stringee", permissions: [STRINGEE.VIEW_ADD_PAGE]},
            ],
            menuSelected: ["DASHBOARD"]
        };
    }

    componentWillMount = () => {
        let module = getCurrentModule();
        let key = "DASHBOARD";
        switch (module) {
            case "contacts":
                key = "CONTACT";
                break;
            case "warehouse":
                key = "WAREHOUSE";
                break;
            case "orders":
                key = "ORDER";
                break;
            case "campaigns":
            case "ads":
                key = "CAMPAIGN";
                break;
            case "groups":
                key = "GROUP";
                break;
            case "statistics":
                key = "REPORT";
                break;
            case "setting":
                key = "SETTING";
                break;
            case "vendors":
                key = "VENDOR";
                break;
            case "products/create":
            case "products":
                key = "PRODUCT";
                break;
            case "telesale":
                key = "TELESALE";
                break;
            case "all-orders":
                key = "ALL_ORDER";
                break;
            case "all-contacts":
                key = "ALL_CONTACT";
                break;
            case "returned-leads":
                key = "RETURN_LEAD";
                break;
            case "delivery-cases":
                key = "DELIVERY_CASES";
                break;
            case "stringee":
                key = "STRINGEE";
                break;
            default:
                key = "DASHBOARD";
                break;
        }
        this.setState({menuSelected: [key]});
    };

    componentDidMount = () => {
        const vendor = LocalStore.getInstance().read('vendor');
        if(vendor && vendor.hasOwnProperty('package')) {
            this.setState({
                packageValue: vendor.package?vendor.package.value:{useWareHouse:false,userOrder:false}
            });
        }
    };

    isShowItem = (item) => {
        let packageValue = this.state.packageValue;
        if(item.key === "ORDER" && UserService.isAllowed(item.permissions) && packageValue.userOrder) {
            return true;
        }

        if(item.key === "WAREHOUSE" && UserService.isAllowed(item.permissions) && packageValue.useWareHouse) {
            return true;
        }

        if(UserService.isAllowed(item.permissions)) {
            return true
        }

        return false;
    };

    onClickMenuItem = (item) => {
        this.props.history.push(item.url);
    };

    render() {
        const {collapsed, t, isShowSideBarItems} = this.props;
        const {items, menuSelected} = this.state;
        return (
            <Sider
                trigger={null}
                collapsible
                collapsed={collapsed}
                className={"sidebar"}
            >
                <div className={`logo pointer ${collapsed ? 'collapsed' : ''}`}>
                    <img
                        src={collapsed ? logoTiny : logo}
                    />
                </div>
                {isShowSideBarItems && (
                    <Menu mode="inline" selectedKeys={menuSelected}>
                        {items.map((item) => {
                            if(this.isShowItem(item)) {
                                return (
                                    <Menu.Item key={item.key} className={`_sidebar_menu_${item.key.toLowerCase()}`} onClick={this.onClickMenuItem.bind(this, item)}>
                                        <Icon type={item.icon}/>
                                        <span className="_link-menu-dashboard sidebar-item">
                                            <span className="sidebar-dashboard">{t(item.title)}</span>
                                        </span>
                                    </Menu.Item>
                                )
                            }
                        })}
                    </Menu>
                )}
            </Sider>
        );
    }
}

export default SideBar;
