import React from 'react';
import {translate} from 'react-i18next';
import StandardLayout from "../../../../../components/StandardLayout";
import {StockReceiptInfo} from "./StockReceiptInfo";
import {ProductList} from "./ProductList";
import {LocalStore} from "../../../../../utils/LocalStore";
import {Bcrumb} from "./Breadcrumb";
import {doCallBack, objectGet} from "../../../../../utils/helper";
import StockReceiptService from "../../../../../services/StockReceiptService";
import * as _ from "lodash";
import {notification} from "antd/lib/index";

class ReceiptDetail extends React.Component {

    constructor(props) {
        super(props);
        const {t} = this.props;
        this.state = {
            vendor: LocalStore.getInstance().read('vendor'),
            loading: false,
            receipt: {},
            warehouse: {},
            receiptProducts: []
        };
    }

    componentDidMount = () => {
        this.loadData();
    };

    fetchStockReceiptDetail = (cb) => {
        const id = this.getId();
        this.setState({loading: true});
        StockReceiptService.getStockReceiptDetail(id, (error, response) => {
            this.setState({loading: false});
            if(!error) {
                this.setState({
                    receipt: response,
                    warehouse: objectGet(response, 'warehouse', {}),
                    receiptProducts: _.orderBy(response.products, 'id' , 'desc')
                });
            }
            doCallBack(cb, [error, response]);
        });
    };
    

    getId = () => {
        return this.props.match.params.id;
    };

    loadData = () => {
        this.fetchStockReceiptDetail();
    };

    changeReceiptType = (receiptType,cb) => {
        const {t} = this.props;
        const id = this.getId();
        StockReceiptService.updateTypeStockReceipt(id,receiptType, (error, response) => {
            if(!error) {
                this.fetchStockReceiptDetail();
                notification.success({
                    message: t('message.success')
                });
            }else{
                notification.error({
                    message: error.message
                });
            }
            doCallBack(cb, [error, response]);
        });
    };

    changeReceiptStatus = (receiptStatus,cb) => {
        const {t} = this.props;
        const id = this.getId();
        if(this.state.receipt.status === 'NEW' && receiptStatus === 'finish'){
            notification.error({
                message: t('error.receipt_detail_status')
            });
        }else if(this.state.receipt.status === 'PROCESSING' && receiptStatus === 'process'){
            return false
        }else{
            StockReceiptService.updateStatus(id,receiptStatus, (error, response) => {
                if(!error) {
                    this.fetchStockReceiptDetail();
                    notification.success({
                        message: t('message.success')
                    });
                }else{
                    notification.error({
                        message: error.message
                    });
                }
                doCallBack(cb, [error, response]);
            });
        }
    };

    render() {
        const {t} = this.props;
        const {loading} = this.state;
        return (
            <StandardLayout
                {...this.props}
                title={t('stock_receipts_detail.title')}
                loading={loading}
            >
                <div className="receipt-detail-page">
                    <Bcrumb
                        {...this.props}
                        {...this.state}
                    />
                    <StockReceiptInfo
                        {...this.props}
                        {...this.state}
                        getId={this.getId}
                        changeReceiptType={this.changeReceiptType}
                        changeReceiptStatus={this.changeReceiptStatus}
                    />

                    <div className="list-container">
                        <ProductList
                            {...this.props}
                            {...this.state}
                            getId={this.getId}
                            fetchStockReceiptDetail={this.fetchStockReceiptDetail}
                        />
                    </div>
                </div>
            </StandardLayout>
        );
    }
}

export default (translate())(ReceiptDetail);