import React, {Component} from 'react';
import {translate} from "react-i18next";
import StandardLayout from "../../components/StandardLayout";
import {Header} from "./Header";
import { notification, Table } from 'antd';
import StringeeService from '../../services/StringeeService';
import UserService from '../../services/UserService';
import { STRINGEE } from '../../utils/api/Scopes';

class Stringee extends Component {

    constructor(props) {
        super(props);
        this.mounted = true;
        this.moduleName = 'stringee';
        this.state = {
            dataSource: [],
            loading: true,
        };
    }


    componentWillMount() {
        if (!UserService.isAllowed(STRINGEE.VIEW_ADD_PAGE)) {
            this.props.history.replace('/403')
        }
    };

    componentDidMount = () => {
        this.loadData();

    };

    loadData = async () => {
        this.setState({
            loading: true
        })
        try {
            const projects = await (await StringeeService.getListProject()).json();
            this.setState({
                loading: false
            })
            if(projects.code === 1){
                notification.success({
                    message: projects.message,
                    duration: 0.5
                });

                this.setState({
                    dataSource: projects.data
                })
            }
        } catch (error) {
            this.setState({
                loading: false
            })

            notification.error({
                message: error
            })
        }
    };

    getColumns = () => {
        return [
            {title: "Stringee Project Name", dataIndex: 'stringee_project_name', key: 'stringee_project_name'},
            {title: "Stringee Project Id", dataIndex: 'stringee_project_id', key: 'stringee_project_id'},
            {title: "Stringee Vendor Id", dataIndex: 'vendor_id', key: 'vendor_id', render: (_, record) => record.vendor_id ? record.vendor_id.toString() : ''},
        ]
    }

    render() {
        const {dataSource} = this.state;
        return (
            <StandardLayout {...this.props} title={"Stringee"}>
                <div className="vendor-list-page list-page">
                    <Header {...this.props} iscreate={false}/>
                    <div className="list-container">
                        <Table 
                            columns={this.getColumns()}
                            dataSource={dataSource}
                            pagination={false}
                        />
                    </div>
                </div>
            </StandardLayout>
        );
    }
}

export default (translate())(Stringee)