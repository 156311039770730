import React from 'react';
import {Button, Card, Form, Input, notification} from 'antd';
import {objectGet} from "../../../utils/helper";
import UserService from "../../../services/UserService";
import {ORDER_SCOPES} from "../../../utils/api/Scopes";
import {ORDER_STATUS} from "../../../utils/const";
import lodash from 'lodash';

class Note extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editMode: false,
            submitting: false
        }
    }

    toggleEditMode = () => {
        this.setState({editMode: !this.state.editMode});
    };

    onSubmit = () => {
        const {form, order, updateOrder, fetchOrder, t} = this.props;
        form.validateFields((error, values) => {
            if(!error) {
                values.note = values.note || "";
                let data = Object.assign(order, values);
                data = lodash.omitBy(data, v => v === undefined || v === 0);
                this.setState({submitting: true});
                updateOrder(data, (error) => {
                    this.setState({submitting: false});
                    if(!error) {
                        fetchOrder(() => {
                            this.toggleEditMode();
                            notification.success({
                                message: t('message.success')
                            })
                        });
                    } else {
                        notification.error({
                            message: error.message
                        })
                    }
                })
            }
        });
    };

    renderTextMode = () => {
        let {order} = this.props;
        return <div className="form-item-text _order_note">{objectGet(order, 'note')}</div>;
    };

    renderEditMode = () => {
        const {submitting} = this.state;
        const {t, order} = this.props;
        const {getFieldDecorator} = this.props.form;
        return (
            <Form>
                <Form.Item>
                    {getFieldDecorator('note', {
                        rules: [
                            { max: 255, message: t("error.length_255")}
                        ],
                        initialValue: objectGet(order, 'note')
                    })(
                        <Input.TextArea
                            disabled={submitting}
                            onPressEnter={this.onSubmit}
                        />
                    )}
                </Form.Item>
            </Form>
        )
    };

    render() {
        const {t, order} = this.props;
        const {editMode, submitting} = this.state;
        const status = objectGet(order, 'status');
        const hasIssue = objectGet(order, 'hasIssue');
        let hasPermission = UserService.isAllowed(ORDER_SCOPES.ORDER_UPDATE_NOTE);
        const statusAllowEdit = [ORDER_STATUS.NEW.key, ORDER_STATUS.HOLD.key, ORDER_STATUS.READY.key, ORDER_STATUS.PICKED_UP.key];
        let showButtonEdit = false;
        if(statusAllowEdit.indexOf(status) >= 0 && hasPermission && !hasIssue) {
            showButtonEdit = true;
        }

        let renderButton = (
            <Button
                size="small"
                icon="edit"
                onClick={this.toggleEditMode}
                className="_btn_toggle_edit_note"
            >
                {t('button.edit')}
            </Button>
        );
        if(editMode) {
            renderButton = (
                <>
                    <Button
                        size="small"
                        type="primary"
                        onClick={this.onSubmit}
                        className="mg-r-5 _btn_update_note"
                        loading={submitting}
                    >
                        {t('button.update')}
                    </Button>
                    <Button
                        size="small"
                        onClick={this.toggleEditMode}
                        className="_btn_cancel_note"
                    >
                        {t('button.cancel')}
                    </Button>
                </>
            )
        }
        return (
            <Card
                className={'card-block'}
                title={t('order_detail.note')}
                extra={showButtonEdit && renderButton}
            >
                {editMode ? this.renderEditMode() : this.renderTextMode()}
            </Card>
        )
    }
}

Note = Form.create()(Note);

export {Note};