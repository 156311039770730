import React from 'react';
import {translate} from 'react-i18next';
import {Tabs} from "antd";
import {Breadcrumb} from "./Breadcrumb";
import StandardLayout from "../../../components/StandardLayout";
import VendorService from "../../../services/VendorService";
import {toastr} from "../../../utils/toastr";
import {doCallBack} from "../../../utils/helper";
import {Info} from "./Info";
import {User} from "./User";
import {Role} from "./Role";

const TabPane = Tabs.TabPane;

class VendorDetail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            vendor: {},
            defaultRoles: []
        };
    }

    componentDidMount() {
        this.fetchVendor();
    }

    getId = () => {
        return this.props.match.params.id;
    };

    fetchVendor = (cb) => {
        VendorService.getVendorById(this.getId(), {include: ['country', 'roles']}, (error, response) => {
            if(!error) {
                this.setState({vendor: response});
            } else {
                if(error.statusCode === 403) {
                    this.props.history.replace('/403');
                } else if(error.statusCode === 404) {
                    this.props.history.replace('/404');
                } else {
                    toastr.error(error.message);
                }
            }
            doCallBack(cb, [error, response]);
        });
    };

    onChangeTab = (key) => {

    };

    render() {
        const {t} = this.props;
        const {vendor} = this.state;
        return (
            <StandardLayout {...this.props} title={`${t("vendor.title")} | ${vendor.name}`}>
                <div className="vendor-detail-page">
                    <Breadcrumb
                        {...this.props}
                        {...this.state}
                    />

                    <Info
                        {...this.props}
                        {...this.state}
                    />

                    <div className="tabs-container">
                        <Tabs
                            defaultActiveKey="user"
                            animated={false}
                            onChange={this.onChangeTab}
                        >
                            <TabPane tab={t('vendor.add_user')} key="user">
                                <User
                                    {...this.props}
                                    {...this.state}
                                    vendorId={this.getId()}
                                />
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </StandardLayout>
        )
    }
}

export default (translate())(VendorDetail);