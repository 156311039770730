import React, {Component} from 'react';
import {translate} from 'react-i18next';
import {Form as AntForm} from 'antd';
import StandardLayout from "../../components/StandardLayout";
import {Header} from "./Header";
import {Form as FormAdd} from "./Form";
import {LocalStore} from "../../utils/LocalStore";

class Setting extends Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.refForm = React.createRef();
    };

    componentDidMount(){
        let readZone = LocalStore.getInstance().read("timezone");
        if(!readZone){
            LocalStore.getInstance().save('timezone', this.state.valueZone);
            let readZoneG8 = LocalStore.getInstance().read("timezone");
            this.setState({
                valueZone: readZoneG8,
            })
        }else {
            this.setState({
                valueZone: readZone,
            })
        }
    };




    render() {
        const {t} = this.props;
        return (
            <StandardLayout title={t('setting.setting')} {...this.props}>
                <div className="setting-page _create-setting">
                    <AntForm onSubmit={this.onSubmit} >
                        <Header
                            {...this.props}
                            {...this.state}
                        />
                        <FormAdd
                            {...this.props}
                        />
                    </AntForm>
                </div>
            </StandardLayout>
        )
    }
}

Setting = AntForm.create()(Setting);
export default (translate())(Setting);
