import React from 'react';
import {Col, Row} from 'antd';
import {Product} from "./Product";
import {Shipping} from "./Shipping";
import {Note} from "./Note";
import {WarehouseNote} from "./WarehouseNote";
import {ShippingAddress} from "./ShippingAddress";
import {objectGet} from "../../../utils/helper";
import {SyncM28} from "./SyncM28";
import UserService from "../../../services/UserService";
import {ORDER_SCOPES} from "../../../utils/api/Scopes";

class Summary extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isFullSizeOrderItem: false
        }
    }


    onToggleFullSizeOrderItem = () => {
        this.setState({
            isFullSizeOrderItem: !this.state.isFullSizeOrderItem
        })
    };

    render() {
        const {order} = this.props;
        const orderStatus = objectGet(order, 'status');
        const showBoxShipping = ['PICKED_UP', 'DELIVERING', 'DELIVERED', 'EXCHANGE', 'RETURNING', 'RETURNED'].indexOf(orderStatus) >= 0;
        const {isFullSizeOrderItem} = this.state;
        const colLeft = {
            xs: 24,
            lg: isFullSizeOrderItem ? 24 : 13,
            xl: isFullSizeOrderItem ? 24 : 15
        };
        const colRight = {
            xs: 24,
            lg: isFullSizeOrderItem ? 24 : 11,
            xl: isFullSizeOrderItem ? 24 : 9
        };
        return (
            <div className={"summary"}>
                <Row gutter={40}>
                    <Col {...colLeft}>
                        <Product
                            {...this.props}
                            isFullSizeOrderItem={isFullSizeOrderItem}
                            onToggleFullSizeOrderItem={this.onToggleFullSizeOrderItem}
                        />
                    </Col>
                    <Col {...colRight}>
                        <ShippingAddress
                            {...this.props}
                        />
                        <Note
                            {...this.props}
                        />
                        {showBoxShipping && (
                            <Shipping
                                {...this.props}
                            />
                        )}

                        <WarehouseNote
                            {...this.props}
                        />

                        {order && !order.syncM28 && UserService.isAllowed(ORDER_SCOPES.ORDER_SYNC_M28) && (
                            <SyncM28
                                order={order}
                            />
                        )}
                    </Col>
                </Row>
            </div>
        )
    }
}

export {Summary};