import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {Icon} from 'antd';
import {formatQuantity} from "../../utils/currency";

class DashBoardBox extends Component {
    static defaultProps = {
        number: 0,
        label: "",
        className: "",
        icon: "fa-shopping-cart",
        showFooter: true,
        url: "/"
    };

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const {t, className, number, numberFooter, icon, label, url, showFooter, iconBgColor} = this.props;
        return (
            <div  className={"small-box " + className}>
                <Link to={url} className="inner dashboard-box">
                    <div className={"_box-label box-label"}>{label}</div>
                    <div className={"_box-number box-number"}>{formatQuantity(number)}</div>
                </Link>
                <div className={`icon _box-icon-container ${iconBgColor}`}>
                    <Icon type={icon} />
                </div>
                <span className="small-box-footer" style={{minHeight: 23}}>
                    {showFooter && t("dashboard.yesterday") +': '+ formatQuantity(numberFooter)}
                </span>
            </div>
        );
    }
}

DashBoardBox.defaultProps = {
    className: "",
    label: "",
    icon: "file",
    iconBgColor: "bg-color-blue",
    number: 0,
    numberFooter: 0,
    showFooter: true
};

export {DashBoardBox}