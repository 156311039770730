import EagleClient from "../utils/api/EagleClient";
import {LocalStore} from "../utils/LocalStore";
import queryString from 'query-string';

export default class UserService {
    static getDashBoardInfo(filter,cb) {
        EagleClient.request({
            endpoint: `/UserEagles/dashboard?${queryString.stringify(filter, {arrayFormat: 'bracket'})}`,
            method: "GET"
        }, cb);
    }

    /**
     * lấy danh sách telesale active
     * @param cb
     */
    static getListUser(cb) {
        EagleClient.request({
            endpoint: "/UserEagles",
            method: "GET",
            queryString: {
                active: true,
                page_size: 1000,
            }
        }, cb);
    }

    static getListAllUser(cb) {
        EagleClient.request({
            endpoint: "/UserEagles",
            method: "GET",
            queryString: {filter: {limit: 1000, fields: ['id', 'username','fullName', 'email', 'address','active']}}
        }, cb);
    }

    /**
     * lấy danh sách user theo tên
     * @param name
     * @param cb
     */
    static getListUserByName(name, cb) {
        let filter = {
            where: {
                and: [
                    {active: 1},
                    {
                        or: [{
                            address: {
                                ilike: `%${name}%`
                            }
                        }, {
                            username: {
                                ilike: `%${name}%`
                            }
                        }]
                    }]
            }
        }
        EagleClient.request({
            endpoint: "/UserEagles",
            method: "GET",
            queryString: {filter: filter}
        }, cb);
    }

    static getUsers(filter, cb) {
        let endpoint = "/UserEagles";
        EagleClient.request({
            endpoint: endpoint,
            method: "GET",
            mode: 'free',
            queryString: filter
        }, cb);
    }

    static UpdateAvatar(base64Img,cb){
        EagleClient.request({
            endpoint: "/UserEagles/me/avatar",
            method: "POST",
            body: {avatar:base64Img}
        }, cb);
    }

    static showMe(cb){
        EagleClient.request({
            endpoint: "/UserEagles/me",
            method: "GET",
        }, cb);
    }

    static updateMe(data,cb){
        EagleClient.request({
            endpoint: "/UserEagles/me",
            method: "PATCH",
            body: data,
        }, cb);
    }

    static changePassword(data,cb){
        EagleClient.request({
            endpoint: "/UserEagles/change-password",
            method: "POST",
            body: data,
        }, cb);
    }

    /**
     * Lấy thông tin dashboard TLS
     * @param filter
     * @param cb
     */
    static getDashBoardTLSInfo(filter, cb) {
        EagleClient.request({
            endpoint: `/UserEagles/dashboardTLS?${queryString.stringify(filter, {arrayFormat: 'bracket'})}`,
            method: "GET"
        }, cb);
    }

    static isAllowed(permissions) {
        let permissionToCheck = [];

        if (!Array.isArray(permissions)) {
            if (typeof permissions === 'object') {
                for (let key in permissions) {
                    if (permissions.hasOwnProperty(key))
                        permissionToCheck.push(permissions[key]);
                }
            }
            else {
                permissionToCheck = [permissions];
            }
        }
        else {
            permissionToCheck = permissions;
        }
        let session = LocalStore.getInstance().read('loginSession');
        if (session) {
            if (!Array.isArray(session.scopes)) {
                session.scopes = [];
            }

            for (let permission of permissionToCheck) {
                if (session.scopes.indexOf(permission) >= 0) {
                    return true;
                }
            }
        }

        return false;
    }

    static getListGroup(filter,cb) {
        EagleClient.request({
            endpoint: "/SaleGroups",
            method: "GET",
            queryString: {filter: filter}
        }, cb);
    }

    static getUserInGroupById(id,filter,cb) {
        EagleClient.request({
            endpoint: `/SaleGroups/${id}/members`,
            method: "GET",
            queryString: {filter: filter}
        }, cb);
    }


    static getGroupUserIn(filter,userId, cb) {
        EagleClient.request({
            endpoint: `/UserEagles/${userId}/group`,
            method: "GET",
            queryString: filter
        }, cb);
    }

    /**
     * Danh sách user cùng vendor với userId
     * @param userId
     * @param cb
     */
    static getListUserOfTheSameVendors(userId, cb) {
        EagleClient.request({
            endpoint: `/UserEagles/${userId}/list-users-of-the-same-vendors`,
            method: "GET"
        }, cb);
    }

    static isLogged() {
        let localStore = LocalStore.getInstance();
        let logginSession = localStore.read('logginSession');
        return logginSession !== null;
    }

    static logout(cb) {
        LocalStore.getInstance().save("loginSession", null);
        LocalStore.getInstance().save("vendor", null);
        LocalStore.getInstance().save("menuVendor", null);
        LocalStore.getInstance().save("authUser", null);

        LocalStore.getInstance().save("visibleColumnsContact", null);
        LocalStore.getInstance().save('visibleColumnsAllContact', null);
        LocalStore.getInstance().save('visibleColumnsContactTelesale', null);
        if(typeof cb === 'function') cb();
    }

    static getAvailableProducts(filter = {}, cb) {
        EagleClient.request({
            endpoint: `/UserEagles/me/available-products`,
            method: "GET",
            queryString: filter
        }, cb);
    }
}
