import React, {Component} from 'react';
import {translate} from "react-i18next";
import StandardLayout from "../../components/StandardLayout";
import {BreadCrumb} from "../../components/BreadCrumb";
import {Breadcrumb as AntBreadCrumb, Tabs} from "antd";
import {Link} from "react-router-dom";
import Lead from "./Lead";
import ReturnLead from "./ReturnLead";
import withLocationFilter from "../../utils/withLocationFilter";

const TabPane = Tabs.TabPane;

class Telesale extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    handleTabChange = (key) => {
        this.props.pushCleanFilterToHistory({
            tab: key
        })
    }

    render() {
        const {t} = this.props;
        const urlQuery = this.props.getFilterFromLocation();
        return (
            <StandardLayout
                {...this.props}
                title={t("contact.title")}
                showSelectVendor={false}
            >
                <div className="telesale-page list-page">
                    <BreadCrumb
                        {...this.props}
                        leftContent={
                            <AntBreadCrumb>
                                <AntBreadCrumb.Item>
                                    <Link to={"/"}>{t('page.home')}</Link>
                                </AntBreadCrumb.Item>
                                <AntBreadCrumb.Item>{t("contact.contact_customer")}</AntBreadCrumb.Item>
                            </AntBreadCrumb>
                        }
                    />

                    <div className="mg-30">
                        <Tabs defaultActiveKey={urlQuery.tab ? urlQuery.tab : 'lead'} type={'card'} onChange={this.handleTabChange}>
                            <TabPane tab={t("contact.contact_customer")} key="lead">
                                <Lead {...this.props} />
                            </TabPane>
                            <TabPane tab={t("contact.return_lead")} key="returnLead">
                                <ReturnLead {...this.props} />
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </StandardLayout>
        );
    }
}

export default (translate())(withLocationFilter(Telesale))