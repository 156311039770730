import React from 'react';
import {Form, InputNumber, Modal, notification, Select} from "antd";
import SelectProduct from "../../../components/SelectProduct";
import ProductService from "../../../services/ProductService";
import {replaceString} from "../../../utils/helper";
import {formatQuantity} from "../../../utils/currency";
import OrderService from "../../../services/OrderService";

class AddGiftModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            form: {

            },
            skuItems: []
        }
    }

    handleChangeProduct = (value) => {
        this.setState({
            form: {
                ...this.state.form,
                productId: value
            }
        })

        this.setState({loading: true});
        ProductService.getSku(value, (error, response) => {
            if (!error) {
                this.setState({skuItems: response});
            }
            this.setState({loading: false});
        })
    }

    handleChangeSku = (sku) => {
        this.setState({
            form: {
                ...this.state.form,
                sku: sku
            }
        })
    }

    handleChangeNumber = (field, value) => {
        this.setState({
            form: {
                ...this.state.form,
                [field]: value
            }
        })
    }

    handleSubmit = () => {
        const {form} = this.state;
        const {order} = this.props;
        if (form.productId && form.sku && form.quantity) {
            this.setState({loading: true});
            OrderService.addGift(order.id, {
                sku: form.sku,
                unitPrice: 0,
                quantity: form.quantity,
                amount: 0
            }, (error) => {
                if (!error) {
                    notification.success({
                        message: 'Add gift success'
                    })

                    this.props.fetchOrder();
                    this.props.onVisibleChange(false);
                }
                else {
                    notification.error({
                        message: error.message
                    })
                }
                this.setState({loading: false});
            })
        }
        else {
            notification.error({
                message: 'Please fill out this form'
            })
        }
    }

    render() {
        const {form, loading, skuItems} = this.state;
        return (
            <Modal
                visible={true}
                title={'Add Gift'}
                okText={'Add Gift'}
                cancelText={'Cancel'}
                okButtonProps={{
                    loading: loading,
                    disabled: loading
                }}
                cancelButtonProps={{
                    loading: loading,
                    disabled: loading
                }}
                onOk={this.handleSubmit}
                onCancel={() => this.props.onVisibleChange(false)}
            >
                <Form layout={'vertical'}>
                    <Form.Item label={'Product'} required={true}>
                        <SelectProduct
                            t={this.props.t}
                            value={form.productId}
                            onChange={this.handleChangeProduct}
                        />
                    </Form.Item>

                    <Form.Item label={'Sku'} required={true}>
                        <Select
                            className={'width-100pc'}
                            value={form.sku}
                            onChange={this.handleChangeSku}
                        >
                            {skuItems.map(item => (
                                <Select.Option key={item.sku} value={item.sku}>{item.display} ({item.sku})</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item label={'Quantity'} required={true}>
                        <InputNumber
                            value={form.quantity}
                            onChange={this.handleChangeNumber.bind(this, 'quantity')}
                            parser={value => replaceString(value)}
                            formatter={value => formatQuantity(value)}
                            style={{width: '100%'}}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

AddGiftModal.defaultProps = {
    onVisibleChange: () => {},
    fetchOrder: () => {}
};


export default AddGiftModal;