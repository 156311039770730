import React, {Component} from 'react';
import {
    Modal, Form, Input, Select, Empty
} from 'antd';

const Option = Select.Option;

class FormUpdateProject extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    onPressEnter = () => {
        if(this.props.isShowPopUpUpdateProject){
            this.props.onUpdate();
        }
    };

    render() {
        const {
            isShowPopUpUpdateProject, onCancel, onUpdate, form, t, projectSelected
        } = this.props;
        const { getFieldDecorator } = form;

        return (
            <Modal
                visible={isShowPopUpUpdateProject}
                title={t("project.update")}
                onCancel={onCancel}
                onOk={onUpdate}
                okButtonProps={{
                    className: "_update-project-save"
                }}
                cancelButtonProps={{
                    className: "_update-project-cancel"
                }}
                cancelText={t("button.cancel")}
                okText={t("button.save")}
                destroyOnClose={true}
            >
                <Form layout="vertical">
                    <Form.Item label={t("project.name")}>
                        {getFieldDecorator('name', {
                            rules: [{ required: true, message: t("project.validate_name")},{ max: 160, message: t("products.name_length")},{whitespace:true, message: t("project.validate_name")}],
                            initialValue: projectSelected?projectSelected.name:""
                        })(
                            <Input
                                autoFocus
                                className="_project_name_update"
                                placeholder={t("project.name_placeholder")}
                                onPressEnter={this.onPressEnter}
                                disabled={!isShowPopUpUpdateProject}
                            />
                        )}
                    </Form.Item>
                    <Form.Item label={t("products.product")}>
                        {getFieldDecorator('productId', {
                            initialValue: projectSelected.product?projectSelected.product.id:""
                        })(
                            <Select
                                className="_select-update-project"
                                disabled={true}
                            >
                                <Select.Option value={projectSelected.product?projectSelected.product.id:""} className="_option-products">{projectSelected.product?projectSelected.product.name:""}</Select.Option>
                            </Select>
                        )}
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

FormUpdateProject = Form.create()(FormUpdateProject);
export {FormUpdateProject}