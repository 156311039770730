import EagleClient from "../utils/api/EagleClient";

export default class LocationService {

    /**
     * @deprecated
     * @param cb
     */
    static getProvince(cb) {
        let endpoint = "/Locations/provinces";
        let defaultFilter = {
            order: "id DESC"
        };
        endpoint += "?filter="+encodeURIComponent(JSON.stringify(defaultFilter));
        EagleClient.request({
            endpoint: endpoint,
            method: "GET",
            mode: "free"
        }, cb);
    }

    static getCity(id,cb) {
        let endpoint = "/Locations/"+id+"/cities";
        let defaultFilter = {
            order: "id DESC"
        };
        endpoint += "?filter="+encodeURIComponent(JSON.stringify(defaultFilter));
        EagleClient.request({
            endpoint: endpoint,
            method: "GET",
            mode: "free"
        }, cb);
    }

    static getDistrict(parentId,cb) {
        let endpoint = "/Locations/"+parentId+"/districts";
        EagleClient.request({
            endpoint: endpoint,
            method: "GET",
            mode: "free"
        }, cb);
    }

    /**
     * @deprecated
     * @param filter
     * @param cb
     */
    static filter(filter, cb) {
        let endpoint = "/Locations";
        let defaultFilter = {
            order: "id DESC"
        };
        if(filter) defaultFilter = Object.assign(filter, defaultFilter);
        endpoint += "?filter="+encodeURIComponent(JSON.stringify(defaultFilter));
        EagleClient.request({
            endpoint: endpoint,
            method: "GET",
            mode: "free"
        }, cb);
    }

    static getCountries = (filter = {}, cb) => {
        let endpoint = "/Locations/countries";
        EagleClient.request({
            endpoint: endpoint,
            method: "GET",
            mode: "free",
            queryString: filter
        }, cb);
    };

	static getCountriesAutocomplete = (filter = {}, cb) => {
		let endpoint = "/Locations/countries/autocomplete";
		EagleClient.request({
			endpoint: endpoint,
			method: "GET",
			mode: "free",
			queryString: filter
		}, cb);
	};

    static getProvinceByCountry = (countryId, cb) => {
        let endpoint = `/Locations/${countryId}/provinces`;
        EagleClient.request({
            endpoint: endpoint,
            method: "GET",
            mode: "free",
        }, cb);
    };

    static getCityByManyProvince(filter = {}, cb) {
        let endpoint = "/Locations/city";
        EagleClient.request({
            endpoint: endpoint,
            method: "GET",
            mode: "free",
            queryString: filter
        }, cb);
    }

    static getRegions = (filter = {}, cb) => {
        let endpoint = "/Regions";
        EagleClient.request({
            endpoint: endpoint,
            method: "GET",
            mode: "free",
            queryString: filter
        }, cb);
    };

}
