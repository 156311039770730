import React, {Component} from 'react';
import {translate} from 'react-i18next';
import {Form as AntForm} from 'antd';
import StandardLayout from "../../../components/StandardLayout";
import {Header} from "./Header";
import {Form as FormAdd} from "./Form";
import {LocalStore} from "../../../utils/LocalStore";
import {notification} from "antd/lib/index";
import {REPORT_SCOPES} from "../../../utils/api/Scopes";
import UserService from "../../../services/UserService";
import ReportService from "../../../services/ReportService";


class ReportAdd extends Component {

    constructor(props) {
        super(props);
        this.state = {
            vendor: LocalStore.getInstance().read('vendor'),
            isLoading: false
        };
        this.refForm = React.createRef();
    };

    componentWillMount() {
        if (!UserService.isAllowed(REPORT_SCOPES.REPORT_CREATE)) {
            this.props.history.replace('/403')
        }
    };

    createReport = (payload) => {
        const {t} = this.props;
        this.setState({
            isLoading: true
        });
        ReportService.createReport(payload, (error, response) => {
            if (!error) {
                notification.success({
                    message: t('message.success')
                });
                this.props.history.push('/statistics-list');
            } else {
                notification.error({
                    message: error.message
                });
            }
            this.setState({
                isLoading: false
            });
        });
    };

    onSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let payload = values;
                payload.vendorId = this.state.vendor.id;
                payload.script = payload.script.trim();
                payload.title = payload.title.trim();
                this.createReport(payload);
            }
        });
    };

    render() {
        const {t} = this.props;
        return (
            <StandardLayout title={t('statistic.title_add')} {...this.props}>
                <div className="ad-add-page _create-report">
                    <AntForm onSubmit={this.onSubmit}>
                        <Header
                            {...this.props}
                            {...this.state}
                        />
                        <FormAdd
                            {...this.props}
                            {...this.state}
                            ref={this.refForm}
                        />
                    </AntForm>
                </div>
            </StandardLayout>
        )
    }
}

ReportAdd = AntForm.create()(ReportAdd);
export default (translate())(ReportAdd);