import React, {Component} from 'react';

import {Filter} from "./Filter";
import {LocalStore} from "../../../utils/LocalStore";
import {List} from "./List";
import {PopupCreateProject} from "./Add/PopupCreateProject";
import {PopupUpdateProject} from "./Update/PopupUpdateProject";
import ProjectService from "../../../services/ProjectService";
import * as _ from "lodash";
import {arrayConcat} from "../../../utils/helper";
import VendorService from "../../../services/VendorService";
import ProductService from "../../../services/ProductService";
import {notification} from "antd/lib/index";

class Project extends Component {

    constructor(props) {
        super(props);
        this.state = {
            vendor: LocalStore.getInstance().read('vendor'),
            currentPage: 1,
            totalItems: 25,
            pageSize: 100,
            pageCount: 0,
            initialUsers: [],
            projects: [],
            users: [],
            products: [],
            initialProducts: [],
            loadingProject: true,
            loadingUser: true,
            isShowPopUpCreateProject: false,
            projectSelected: {},
            isShowPopUpUpdateProject: false,
            loadingProduct: true
        };
        this.filterProject = null;
    }

    componentDidMount = () => {
        this.loadData();
    };

    resetDataSource = (key) => {
        this.setState({
            [key]: this.state[`initial${_.upperFirst(key)}`]
        });
    };

    loadData = () => {
        this.fetchProject({
            product_id:this.props.productId
        });
        this.fetchUser({}, (error, response) => {
            if(!error) {
                this.setState({
                    initialUsers: response.list
                });
            }
        });
        this.fetchProduct({}, (error, response) => {
            if(!error) {
                this.setState({
                    initialProducts: response.list
                });
            }
        })
    };

    fetchUser = (filter = {}, cb) => {
        this.setState({loadingUser: true});
        const {vendor} = this.state;
        VendorService.getUserInVendor(vendor.id, filter, (error, response) => {
            if(!error) {
                this.setState({
                    loadingUser: false,
                    users: response.list,
                });
            }
            if(typeof cb === 'function') cb(error, response);
        });
    };

    fetchProject = (filter = {}, cb) => {
        this.setState({loadingProject: true});
        let {vendor} = this.state;
        let filterCond = {
            vendor_id: vendor.id,
            include: ['product'],
            order: ["createdAt DESC"],
        };
        filterCond = _.mergeWith(filterCond, filter, arrayConcat);
        ProjectService.getProjects(filterCond, (error, response) => {
            if(!error) {
                this.setState({
                    projects: response.list,
                    loadingProject: false,
                    currentPage: response.metadata.currentPage,
                    totalItems: response.count,
                    pageSize: response.metadata.pageSize,
                    pageCount: response.metadata.pageCount
                });
            }
            if(typeof cb === 'function') cb(error, response);
        });
    };

    fetchProduct = (filter = {}, cb) => {
        this.setState({loadingProduct: true});
        let {vendor} = this.state;
        let filterCond = {
            order: ["createdAt DESC"],
            active: true,
            ids:this.props.productId.length? this.props.productId.join(','): ""
        };
        filterCond = _.mergeWith(filterCond, filter, arrayConcat);

        VendorService.getProducts(vendor.id, filterCond, (error, response) => {
            if(!error) {
                this.setState({
                    products: response.list,
                    loadingProduct: false
                });
            }
            if(typeof cb === 'function') cb(error, response);
        });
    };

    onShowSizeChange = (current, pageSize) => {
        this.fetchProject({
            page: 1,
            page_size: pageSize,
            product_id:this.props.productId,
            keyword:this.filterProject.getFilterCon("keyword"),
            created_by:this.filterProject.getFilterCon("createdBy"),
            created_at_from:this.filterProject.getFilterCon("created_at_from"),
            created_at_to:this.filterProject.getFilterCon("created_at_to")
        });
    };

    onChangePage = (page, pageSize) => {
        this.fetchProject({
            page: page,
            page_size: pageSize,
            product_id:this.props.productId,
            keyword:this.filterProject.getFilterCon("keyword"),
            created_by:this.filterProject.getFilterCon("createdBy"),
            created_at_from:this.filterProject.getFilterCon("created_at_from"),
            created_at_to:this.filterProject.getFilterCon("created_at_to")
        });
    };

    onFilter = (filter = {}, cb) => {
        this.fetchProject(filter, cb);
    };

    componentDidUpdate(prevProps) {
        if(JSON.stringify(prevProps.productId) !== JSON.stringify(this.props.productId)){
            this.fetchProject({
                product_id:this.props.productId,
                keyword:this.filterProject.getFilterCon("keyword"),
                created_by:this.filterProject.getFilterCon("createdBy"),
                created_at_from:this.filterProject.getFilterCon("created_at_from"),
                created_at_to:this.filterProject.getFilterCon("created_at_to")
            });

            this.fetchProduct({},(error, response) => {
                if(!error) {
                    this.setState({
                        initialProducts: response.list
                    });
                }
            })
        }
    }

    setRefFilter = (ref) => {
        this.filterProject = ref;
    };

    showPopupCreateProject = (active) => {
        this.setState({
            isShowPopUpCreateProject: active,
        })
    };

    onCreateProject = (data) => {
        const {vendor} = this.state;
        const {t} = this.props;
        this.setState({
            isShowPopUpCreateProject: false
        });
        data.vendorId = vendor.id;
        ProjectService.createProject(data, (error, response) => {
            if(!error) {
                notification['success']({
                    message: t("message.success"),
                });
                this.filterProject.resetStateFilter();
                this.fetchProject({
                    product_id:this.props.productId
                });
            } else {
                notification['error']({
                    message: error.message,
                });
            }
        });
    };

    showPopupUpdateProject = (active,item = {}) => {
        this.setState({
            isShowPopUpUpdateProject: active,
            projectSelected: item
        })
    };

    onUpdateProject = (data) => {
        data.id = this.state.projectSelected.id;
        const {t} = this.props;
        this.setState({
            isShowPopUpUpdateProject: false
        });
        ProjectService.updateProject(data, (error, response) => {
            if(error === null) {
                notification['success']({
                    message: t("message.success"),
                });
                this.filterProject.resetStateFilter();
                this.fetchProject({
                    product_id:this.props.productId
                });
            } else {
                notification['error']({
                    message: error.message,
                });
            }
        });
    };
    render() {
        const {vendor, totalItems, currentPage, pageSize,pageCount, users, projects, loadingProject} = this.state;

        return (
            <div className="product-container _project-container">
                <Filter
                    {...this.props}
                    users={users}
                    resetDataSource={this.resetDataSource}
                    onFilter={this.onFilter}
                    fetchUser={this.fetchUser}
                    currentPage={currentPage}
                    pageSize={pageSize}
                    pageCount={pageCount}
                    totalItems={totalItems}
                    loading={loadingProject}
                    productId={this.props.productId}
                    ref={this.setRefFilter}
                    showPopupCreateProject={this.showPopupCreateProject}
                />
                <List
                    {...this.props}
                    dataSource={projects}
                    currentPage={currentPage}
                    pageSize={pageSize}
                    pageCount={pageCount}
                    totalItems={totalItems}
                    vendor={vendor}
                    onChangePage={this.onChangePage}
                    onShowSizeChange={this.onShowSizeChange}
                    loadingProject={loadingProject}
                    onToggleProductStatus={this.onToggleProductStatus}
                    getProjectId={this.props.getProjectId}
                    countProject={this.props.countProject}
                    showPopupUpdateProject={this.showPopupUpdateProject}
                />
                <PopupCreateProject
                    {...this.props}
                    isShowPopUpCreateProject={this.state.isShowPopUpCreateProject}
                    showPopupCreateProject={this.showPopupCreateProject}
                    products={this.state.products}
                    onCreateProject={this.onCreateProject}
                    resetDataSource={this.resetDataSource}
                    fetchProduct={this.fetchProduct}
                    loadingProduct={this.state.loadingProduct}
                />
                <PopupUpdateProject
                    {...this.props}
                    isShowPopUpUpdateProject={this.state.isShowPopUpUpdateProject}
                    showPopupUpdateProject={this.showPopupUpdateProject}
                    products={this.state.products}
                    onUpdateProject={this.onUpdateProject}
                    resetDataSource={this.resetDataSource}
                    fetchProduct={this.fetchProduct}
                    projectSelected={this.state.projectSelected}
                />
            </div>
        );
    }
}

export {Project}