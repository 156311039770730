import React from 'react';
import "./style.scss";

class ButtonToggle extends React.Component {

    static defaultProps = {
        className: 'button',
        onToggle: (status, e) => {},
        active: null
    };

    constructor(props) {
        super(props);
        this.state = {
            active: this.props.active
        };
    }

    componentDidUpdate = (prevProps) => {
        if(prevProps.active !== this.props.active) {
            this.setState({
                active: this.props.active
            });
        }
    };

    onClick = (e) => {
        this.setState({
            active: !this.state.active
        }, () => {
            this.props.onToggle(this.state.active, e);
        });
    };

    render() {
        const {className} = this.props;
        let {active} = this.state;
        return (
            <button
                className={`button-toggle ${active === true ? 'active' : 'not-active'} ${className}`}
                onClick={this.onClick}
            >
                {this.props.children}
            </button>
        )
    }
}

export {ButtonToggle};