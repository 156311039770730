import React, {Component} from 'react';
import {translate} from "react-i18next";
import * as _ from 'lodash';
import StandardLayout from "../../components/StandardLayout";
import {Filter} from "./Filter";
import ContactService from "../../services/ContactService";
import {LocalStore} from "../../utils/LocalStore";
import {CONTACT_SCOPES} from "../../utils/api/Scopes";
import UserService from "../../services/UserService";
import {Header} from "./Header";
import {List} from "./List";
import {arrayConcat, getUrlQueryString} from "../../utils/helper";
import CampaignService from "../../services/CampaignService";
import VendorService from "../../services/VendorService";
import {Url} from "../../utils/Url";
import AdService from "../../services/AdService";
import withLocationFilter from "../../utils/withLocationFilter";

class AllContact extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            currentPage: 1,
            totalItems: 50,
            pageSize: 100,
            contactStatuses: [],
            callStatuses: [],
            visibleColumns:[
                {name:"all",language:"contact.check_all",priority:0,selected:false,isShow:true},
                {name:"customerName",language:"contact.fullname",priority:1,selected:true,isShow:false},
                {name:"vendorId",language:"contact.vendor",priority:1,selected:true,isShow:true},
                {name:"campaignId",language:"contact.campaign",priority:2,selected:true,isShow:false},
                {name:"createdAt",language:"contact.created_at",priority:3,selected:false,isShow:true},
                {name:"customerPhone",language:"contact.phone",priority:4,selected:true,isShow:false},
                {name:"assignTo",language:"contact.assign_to",priority:5,selected:true,isShow:false},
                {name:"assignedAt",language:"contact.assigned_by",priority:6,selected:true,isShow:false},
                {name:"assignedBy",language:"contact.assigned_by",priority:7,selected:false,isShow:true},
                {name:"contactStatusId",language:"contact.stage",priority:8,selected:false,isShow:true},
                {name:"totalCall",language:"contact.calls",priority:9,selected:false,isShow:true},
                {name:"callStatusId",language:"contact.status",priority:10,selected:false,isShow:true},
                {name:"orderStatus",language:"contact.order_status",priority:11,selected:false,isShow:true},
                {name:"callTime",language:"contact.callTime",priority:12,selected:false,isShow:true},
                {name:"deliveryDate",language:"contact.deliveryDate",priority:13,selected:false,isShow:true},
                {name:"note",language:"contact.note",priority:14,selected:false,isShow:true}
            ],
            loading: false,
            loadingCampaign: false,
            loadingProduct: false,
            loadingUserAssignTo: false,
            loadingUserAdsCreate: false,
            loadingCallStatus: false,
            loadingContactStatus: false,
            pageCount: 0,
            vendors: [],
            regions: [],
            campaigns: [],
            products: [],
            userAssignTo: [],
            userAdsCreate: [],
            initialCampaigns: [],
            initialProducts: [],
            initialUserAssignTo: [],
            initialUserAdsCreate: [],
            initialCallStatuses: [],
            isShowPopupImportContact: false,
            isShowPopupImportContactFailed: false,
            importContactFailedItems: [],
            isShowPopupCreateContact: false,
            queryResultSet: {
                vendors: [],
                products: [],
                campaigns: [],
                createdAdUsers: [],
                assignedUsers: []
            }
        };
    }

    componentDidUpdate(prevProps) {
        if (
            JSON.stringify(this.props.location.search) !== JSON.stringify(prevProps.location.search)
            || JSON.stringify(this.props.match.params) !== JSON.stringify(prevProps.match.params)
        ) {
            this.loadContact({
                ...this.props.getFilterFromLocation()
            })
        }
    }

    componentDidMount = () => {
        if (!UserService.isAllowed(CONTACT_SCOPES.CONTACT_VIEW)) {
            this.props.history.replace('/403')
        }
        if(localStorage.getItem("visibleColumnsAllContact") !== null){
            const defaultVisibleColumns = [...this.state.visibleColumns];
            const oldVisibleColumns = JSON.parse(localStorage.getItem("visibleColumnsAllContact")) || this.state.visibleColumns;

            for (let config of defaultVisibleColumns) {
                const exist = _.find(oldVisibleColumns, item => item.name === config.name);
                if (!exist) {
                    oldVisibleColumns.push(config);
                }
            }
            this.setState({
                visibleColumns: [...oldVisibleColumns]
            });
        }

        this.loadData({
            ...this.props.getFilterFromLocation()
        });
    };

    loadData = (filter = {}) => {
        this.loadContact(filter);

        this.callApiGetContactStatuses();

        this.fetchVendors();

        this.fetchCallStatus({}, (error, response) => {
            if(!error) {
                this.setState({initialCallStatuses: response});
            }
        });

        if (filter.vendor_ids) {
            this.fetchProduct({
                vendor_ids: filter.vendor_ids
            });

            this.fetchCampaign({
                vendor_ids: filter.vendor_ids
            });

            this.fetchUserAdsCreate({
                vendor_ids: filter.vendor_ids
            });

            this.fetchUserAssignTo({
                vendor_ids: filter.vendor_ids
            });
        }
        else {
            this.fetchProduct();

            this.fetchCampaign();

            this.fetchUserAdsCreate();

            this.fetchUserAssignTo();
        }
    };

    fetchVendors = (filter = {}) => {
        this.setState({loading: true});
        filter = {
            ...filter,
            me_in: true,
            page_size: 1000
        };

        VendorService.getVendorList(filter, (error, response) => {
            this.setState({loading: false});
            if (!error) {
                this.setState({
                    vendors: response.list
                });
            }
        });
    };

    fetchCampaign = (filter = {}, cb) => {
        this.setState({loadingCampaign: true});
        let defaultFilter = {page_size: 150};
        defaultFilter = Object.assign(defaultFilter, filter);
        CampaignService.suggest(defaultFilter, (error, response) => {
            if(!error) {
                this.setState({campaigns: response.list, loadingCampaign: false});
            }
            if(typeof cb === 'function') cb(error, response);
        });
    };

    fetchProduct = (filter = {}, cb) => {
        this.setState({loadingProduct: true});
        let defaultFilter = {page_size: 100, order: ["id DESC"]};
        defaultFilter = Object.assign(defaultFilter, filter);
        UserService.getAvailableProducts(defaultFilter, (error, response) => {
            if(!error) {
                this.setState({products: response.list, loadingProduct: false});
            }
            if(typeof cb === 'function') cb(error, response);
        });
    };

    fetchUserAssignTo = (filter = {}, cb) => {
        const {t} = this.props;
        this.setState({loadingUserAssignTo: true});
        UserService.getUsers(filter, (error, response) => {
            if(!error) {
                let hasUnassign = false;
                if(!Object.keys(filter).length) {
                    hasUnassign = true;
                } else if(response.list.length === 0) {
                    if(t('contact.unassign_to').indexOf(filter.username) >= 0) {
                        hasUnassign = true;
                    } else if('ids' in filter) {
                        let arr = filter.ids.split(',');
                        if(arr.indexOf('0') >= 0) {
                            hasUnassign = true;
                        }
                    }
                } else {
                    for(let k in filter) {
                        let v = filter[k];
                        if(k === 'ids') {
                            let arr = v.split(',');
                            if(arr.indexOf('0') >= 0) {
                                hasUnassign = true;
                                break;
                            }
                        } else if(k === 'username' && !v) {
                            hasUnassign = true;
                        }
                    }
                }

                if(hasUnassign) {
                    response.list = [{id: 0, username: t('contact.unassign_to')}, ...response.list];
                }
                this.setState({userAssignTo: response.list, loadingUserAssignTo: false});
            }
            if(typeof cb === 'function') cb(error, response);
        });
    };

    fetchUserAdsCreate = (filter = {}, cb) => {
        this.setState({loadingUserAdsCreate: true});
        UserService.getUsers(filter, (error, response) => {
            this.setState({loadingUserAdsCreate: false});
            if(!error) {
                this.setState({userAdsCreate: response.list});
            }
            if(typeof cb === 'function') cb(error, response);
        });
    };

    callApiGetContactStatuses = (cb) => {
        this.setState({loadingContactStatus: true});
        ContactService.getContactStatuses((error, response) => {
            if(!error) this.setState({contactStatuses: response, loadingContactStatus: false});
            if(typeof cb === 'function') cb(error, response);
        });
    };

    fetchCallStatus = (filter = {}, cb) => {
        this.setState({loadingCallStatus: true});
        ContactService.getCallStatuses((error, response) => {
            let data = response;
            if(!error){
                for(let item of data) {
                    if(item.id === 3){
                        item.color = "blue"
                    }
                    if(item.id === 1){
                        item.color = "green"
                    }
                    if(item.id === 2){
                        item.color = "red"
                    }
                    if(item.id === 4 || item.id === 7){
                        item.color = "gold"
                    }
                    if(item.id === 5 || item.id === 6){
                        item.color = "purple"
                    }
                    if(item.id === 8){
                        item.color = "magenta"
                    }
                }

                if(Object.keys(filter).length) {
                    _.forEach(filter, (v, k) => {
                        if(k === 'value') {
                            data = _.filter(data, (item) => {return item.value.toString().indexOf(v) >= 0});
                        } else if(k === 'id') {
                            data = _.filter(data, (item) => {return item.id == v});
                        } else if(k === 'ids') {
                            let arr = v.toString().split(',');
                            data = _.filter(data, (item) => {return arr.indexOf(item.id.toString()) >= 0});
                        }
                    });
                }

                this.setState({callStatuses: data, loadingCallStatus: false})
            }

            if(typeof cb === 'function') cb(error, data);
        });
    };

    fetchAds = (filter = {}, cb) => {
        let defaultFilter = {
            product_active: true
        };
        filter = _.mergeWith(defaultFilter, filter, arrayConcat);
        AdService.getAds(filter, cb);
    };

    loadContact = (filter = {}) => {
        this.setState({loading: true});
        let defaultFilter = {
            include: ['vendor', 'country', 'campaign', 'product', 'user', 'userCreated', 'lastOrder'],
            order: ["updatedAt DESC"],
            page: filter.page || 1,
            page_size: filter.page_size || 100,
            time_zone: LocalStore.getInstance().read("timezone")
        };

        if(Object.keys(filter).length) {
            defaultFilter = Object.assign(defaultFilter, filter);
        }

        ContactService.getContacts(defaultFilter, (error, response) => {
            if(!error) {
                this.setState({
                    dataSource: response.list,
                    currentPage: response.metadata.currentPage,
                    totalItems: response.count,
                    pageSize: response.metadata.pageSize,
                    pageCount: response.metadata.pageCount,
                    loading: false,
                    queryResultSet: response.extra
                })
            }
        });
    };

    onShowSizeChange = (current, pageSize) => {
        let uri = this.getUrlInstance();
        uri.replaceParam('page', 1);
        uri.replaceParam('page_size', pageSize);
        this.pushQueryString(uri.getQueryString());
    };

    onChangePage = (page, pageSize) => {
        let uri = this.getUrlInstance();
        uri.replaceParam('page', page);
        uri.replaceParam('page_size', pageSize);
        this.pushQueryString(uri.getQueryString());
    };

    isShowCheckbox = (priority) => {
        let visibleColumns = this.state.visibleColumns;
        let visibleColumnsDefault = [1,2,4,5,6];
        if(priority === 0){
            let selected = !visibleColumns[0].selected;
            visibleColumns[0].selected = selected;
            for (let i = 1; i < visibleColumns.length; i++) {
                if (visibleColumnsDefault.indexOf(i) > -1) { continue; }
                visibleColumns[i].selected = selected;
            }
        }else{
            for (let i = 1; i < visibleColumns.length; i++) {
                if(visibleColumns[i].priority === priority){
                    visibleColumns[i].selected = !visibleColumns[i].selected;
                }
            }

            let checkAll = false;
            for (let i = 1; i < visibleColumns.length; i++) {
                if(visibleColumns[i].selected === false){
                    checkAll = true;
                }
            }
            if(checkAll){
                visibleColumns[0].selected = false;
            }else{
                visibleColumns[0].selected = true;
            }
        }


        localStorage.setItem("visibleColumnsAllContact",JSON.stringify(visibleColumns));
        this.setState({
            visibleColumns:visibleColumns
        })
    };

    getColumnTable = () => {
        let visibleColumnsDisplay = [];
        let visibleColumns = this.state.visibleColumns;
        for (let i = 0; i < visibleColumns.length; i++) {
            if(visibleColumns[i].priority === 0){
                continue;
            }
            if(visibleColumns[i].selected){
                visibleColumnsDisplay.push(visibleColumns[i].name)
            }
        }
        return visibleColumnsDisplay;
    };

    IsShowColumnCheckbox = () => {
        let listColumn = [];
        let columnAllowSelected = this.state.visibleColumns;
        for (let i = 0; i < columnAllowSelected.length; i++) {
            if(columnAllowSelected[i].isShow){
                listColumn.push(columnAllowSelected[i])
            }
        }
        return listColumn;
    };

    getUrlInstance = (newInstance = false) => {
        if(newInstance) {
            return new Url();
        }
        return new Url('?'+getUrlQueryString());
    };

    pushQueryString = (queryString) => {
        this.props.history.push(`/all-contacts${queryString}`);
    };

    resetDataSource = (key) => {
        this.setState({
            [key]: this.state[`initial${_.upperFirst(key)}`]
        });
    };

    togglePopupImportContact = () => {
        this.setState({isShowPopupImportContact: !this.state.isShowPopupImportContact});
    };

    togglePopupCreateContact = () => {
        this.setState({isShowPopupCreateContact: !this.state.isShowPopupCreateContact});
    };

    countContactUnassign = (groupIds, cb) => {
        const {vendor} = this.state;
        ContactService.getCountContactUnassignInGroups({
            groupIds: groupIds,
            vendorId: vendor.id
        },cb);
    };

    assignContactAuto = (quantity, groupId, cb) => {
        const {vendor} = this.state;
        const vendorId = vendor.id;
        ContactService.assignContactAuto(quantity, [groupId], vendorId, cb);
    };

    render() {
        const {t} = this.props;
        let visibleColumnsDisplay = this.getColumnTable();
        let columnAllowSelected = this.IsShowColumnCheckbox();
        const {dataSource, vendor, totalItems, currentPage, pageSize, callStatuses,
            contactStatuses,pageCount, campaigns, products,
            loadingCampaign, loadingProduct, loadingUserAdsCreate, loadingUserAssignTo, loadingCallStatus,
            loadingContactStatus, userAdsCreate, userAssignTo, vendors, queryResultSet, regions} = this.state;
        return (
            <StandardLayout
                {...this.props}
                title={t("contact.title")}
                showSelectVendor={false}
            >
                <div className="contact-list-page list-page">
                    <Header
                        {...this.props}
                        togglePopupImportContact={this.togglePopupImportContact}
                        togglePopupCreateContact={this.togglePopupCreateContact}
                    />
                    <Filter
                        {...this.props}
                        contactStatuses={contactStatuses}
                        callStatuses={callStatuses}
                        vendors={vendors}
                        campaigns={campaigns}
                        products={products}
                        userAdsCreate={userAdsCreate}
                        userAssignTo={userAssignTo}
                        queryResultSet={queryResultSet}
                        loadingCampaign={loadingCampaign}
                        loadingProduct={loadingProduct}
                        loadingUserAdsCreate={loadingUserAdsCreate}
                        loadingUserAssignTo={loadingUserAssignTo}
                        loadingCallStatus={loadingCallStatus}
                        loadingContactStatus={loadingContactStatus}
                        fetchCampaign={this.fetchCampaign}
                        fetchProduct={this.fetchProduct}
                        fetchUserAssignTo={this.fetchUserAssignTo}
                        fetchUserAdsCreate={this.fetchUserAdsCreate}
                        fetchCallStatus={this.fetchCallStatus}
                        fetchVendors={this.fetchVendors}
                    />
                    <List
                        {...this.props}
                        dataSource={dataSource}
                        currentPage={currentPage}
                        pageSize={pageSize}
                        pageCount={pageCount}
                        callStatuses={callStatuses}
                        contactStatuses={contactStatuses}
                        totalItems={totalItems}
                        vendor={vendor}
                        onChangePage={this.onChangePage}
                        onShowSizeChange={this.onShowSizeChange}
                        visibleColumns={columnAllowSelected}
                        isShowCheckbox={this.isShowCheckbox}
                        visibleColumnsDisplay={visibleColumnsDisplay}
                        loading={this.state.loading}
                        loadContact={this.loadContact}
                        getUrlInstance={this.getUrlInstance}
                        pushQueryString={this.pushQueryString}
                    />
                </div>
            </StandardLayout>
        );
    }
}

export default (translate())(withLocationFilter(AllContact))