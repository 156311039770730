import EagleClient from "../utils/api/EagleClient";

export default class TotalSaleService {
    static getTotalSaleByCountryId(countryId, params = {}, cb) {
        EagleClient.request({
            endpoint: "/v2/total-sale/countries/" + countryId,
            method: "GET",
            queryString: params,
            mode: "free"
        }, cb);
    }
}
