import React, {Component} from 'react';
import {Button} from 'antd';
import {objectGet} from "../../../utils/helper";
import {Editor} from "../../../components/Editor";

class MarketingTarget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editMode: false,
            loading: false
        }
    }

    toggleEditMode = () => {
        this.setState({
            editMode: !this.state.editMode
        });
    };

    onSave = (data) => {
        this.setState({loading: true});
        this.props.updateCampaign({marketingTarget: data}, () => {
            this.setState({loading: false});
            this.toggleEditMode();
        });
    };

    renderEditor = () => {
        const {campaign} = this.props;
        const {loading} = this.state;
        return (
            <Editor
                {...this.props}
                data={objectGet(campaign, 'marketingTarget')}
                loading={loading}
                onCancel={this.toggleEditMode}
                onSave={this.onSave}
                buttonOkClassName="_btn_save_marketing_target"
                buttonCancelClassName="_btn_cancel_marketing_target"
            />
        )
    };

    renderText = () => {
        const {campaign, t} = this.props;
        return (
            <>
                <div dangerouslySetInnerHTML={{__html: objectGet(campaign, 'marketingTarget')}} />
                <div className="flr">
                    <Button type="primary" onClick={this.toggleEditMode} className={"_btn_show_edit_marketing_target"}>{t('button.edit')}</Button>
                </div>
                <div className="clearfix"></div>
            </>
        )
    };

    render() {
        const {editMode} = this.state;
        return (
            <div className="box-container">
                {editMode ? this.renderEditor() : this.renderText()}
            </div>
        )
    }
}

export {MarketingTarget};