import React, {Component} from 'react';
import {Col, Divider, Form as AntForm, Input, Row, Select, Switch} from 'antd';
import * as _ from 'lodash';
import {doCallBack, objectGet} from "../../../../utils/helper";
import {WholeSaleConfig} from "./WholeSaleConfig";
import LandingUrls from "./LandingUrls";
import GiftConfig from "./GiftConfig";

class Form extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: 1,
            campaigns: [],
            campaignSelected: null,
            initialCampaigns: [],
            adsets: [],
            initialAdsets: [],
            configWholeSalePrice: [],
            loadingCampaign: false,
            loadingAdset: false,
            landingUrls: []
        };

        this.onSearchAdset = _.debounce(this.onSearchAdset, 300);
    }

    componentDidUpdate = (prevProps, prevState) => {
        if(JSON.stringify(prevProps.ads) !== JSON.stringify(this.props.ads) && this.props.ads) {
            this.props.fetchCampaign(this.props.ads.campaignId, (error, campaign) => {
                if(!error) {
                    // Override config campaign default
                    let configWholeSale = campaign.configWholeSale;
                    for(let c of configWholeSale) {
                        if(!('checked' in c)) c.checked = false;
                        for(let c1 of this.props.ads.configWholeSalePrice) {
                            if(c.sku == c1.sku) {
                                c.checked = true;
                                c.wholeSalePrice = c1.wholeSalePrice;
                            }
                        }
                    }
                    _.forEach(configWholeSale, (item) => {
                        _.forEach(item.wholeSalePrice, (row) => {row.uid = this.getUid()});
                    });

                    this.props.form.resetFields();

                    this.setState({
                        configWholeSalePrice: configWholeSale
                    });
                }
            });
            this.fetchAdsets({campaign_id: [this.props.ads.campaignId], product_active: true}, (error, response) => {
                if(!error) {
                    this.setState({
                        adsets: response.list,
                        initialAdsets: response.list
                    });
                }
            });
        }

        if (JSON.stringify(prevProps.landingUrls) !== JSON.stringify(this.props.landingUrls) && !this.state.landingUrls.length) {
            this.setState({
                landingUrls: this.props.landingUrls.map(item => item.landingUrl)
            })
        }
    };

    componentDidMount = () => {

    };

    getCheckedConfigItems = () => {
        let {configWholeSalePrice} = this.state;
        return _.filter(configWholeSalePrice, (item) => {
            return item.checked;
        });
    };

    fetchAdsets = (filter = {}, cb) => {
        this.setState({loadingAdset: true});
        this.props.fetchAdsets(filter, (error, response) => {
            this.setState({loadingAdset: false});
            if(!error) {
                this.setState({adsets: response.list});
            }
            doCallBack(cb, [error, response]);
        });
    };

    onChange = (e) => {
        this.setState({
            value: e.target.value,
        });
    };

    onSearchAdset = (value) => {
        if(value) {
            this.fetchAdsets({keyword: value});
        }
    };

    getConfigWholeSale = (value) => {
        this.props.getConfigWholeSale(value);
    };

    resetDataSource = (key) => {
        let data = [];
        if(key === 'adsets') {
            data = this.state.initialAdsets;
        }
        this.setState({
            [key]: data
        });
    };

    onAddWholeSaleConfig = (index) => {
        const max = 5;
        let {configWholeSalePrice} = this.state;
        let config = configWholeSalePrice[index];
        if(config.wholeSalePrice.length <= max-1) {
            config.wholeSalePrice.push({
                from: "",
                to: "",
                price: "",
                uid: this.getUid()
            });
            configWholeSalePrice[index] = config;
            this.setState({configWholeSalePrice});
        }
    };

    onRemoveWholeSaleConfig = (index,uid) => {
        let {configWholeSalePrice} = this.state;
        let config = configWholeSalePrice[index];

        let indexUid = _.findIndex(config.wholeSalePrice, (item) => item.uid == uid);
        config.wholeSalePrice.splice(indexUid, 1);
        configWholeSalePrice[index] = config;
        this.setState({configWholeSalePrice});
    };

    toggleCheckAllConfig = () => {
        let {configWholeSalePrice} = this.state;
        let countChecked = 0;
        for(let config of configWholeSalePrice) {
            if(config.checked) countChecked ++;
        }
        let isCheckAll = countChecked === configWholeSalePrice.length;

        for(let config of configWholeSalePrice) {
            if(typeof config.checked === 'undefined') {
                config.checked = false;
            }
            config.checked = !isCheckAll;
        }
        this.setState({configWholeSalePrice}, () => {
            if(this.getCheckedConfigItems().length) {
                this.props.resetValidateErrors();
            }
        });
    };

    toggleCheckConfig = (index) => {
        let {configWholeSalePrice} = this.state;
        let config = configWholeSalePrice[index];
        config.checked = !config.checked;
        configWholeSalePrice[index] = config;
        this.setState({configWholeSalePrice});
        if(config.checked) {
            this.props.resetValidateErrors();
        }
    };

    getUid = () => {
        return _.uniqueId('uid')
    };

    handleChangeLandingUrl = (urls) => {
        this.setState({landingUrls: urls});
    };

    getState = (key) => {
        return this.state[key];
    };

    render() {
        const {t, ads} = this.props;
        let campaign = objectGet(ads, 'campaign', {});
        const {getFieldDecorator} = this.props.form;
        const {configWholeSalePrice, landingUrls} = this.state;
        return (
            <>
                <div className="white-box">
                    <div className="form-header">
                        <h3 className="pd-l-30 pd-r-30 pd-t-15 pd-bt-15 mg-0">{t('ads.edit')}</h3>
                        <div className="h-line"/>
                    </div>
                    <div className="form-body pd-30 pd-t-10 pd-bt-10">
                        <Row gutter={20}>
                            <Col span={12}>
                                <div className="form-item">
                                    <AntForm.Item
                                        label={t("campaign.title")}
                                        className="campaign _campaign"
                                    >
                                        <Input value={campaign.name} className="_campaign" disabled />
                                    </AntForm.Item>
                                </div>
                                <div className="form-item">
                                    <AntForm.Item
                                        className={"form-item-item"}
                                        label={<span className="bold-400">{t("ads.name")}</span>}
                                    >
                                        {getFieldDecorator('name', {
                                            rules: [
                                                {required: true, message: t("message.required")},
                                                {max: 160, message: t("error.length_160")}
                                            ],
                                            initialValue: objectGet(ads, 'name')
                                        })(
                                            <Input placeholder={t("ads.input_name")} className="_ads-name"/>
                                        )}
                                    </AntForm.Item>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div className="form-item">
                                    <AntForm.Item
                                        label={t("ads_group_list.tab_name")}
                                        className="adset _adset"
                                    >
                                        <Input value={objectGet(ads, 'campaignAdSet.name')} disabled={true} />
                                    </AntForm.Item>
                                </div>
                                <div className="form-item">
                                    <Row gutter={20}>
                                        <Col span={12}>
                                            <div className="form-item">
                                                <AntForm.Item
                                                    className={"form-item-item"}
                                                    label={<span className="bold-400">{t("ads.fbadid")}</span>}
                                                >
                                                    {getFieldDecorator('facebookIds', {
                                                        initialValue: objectGet(ads, 'facebookIds')
                                                    })(
                                                        <Select mode={'tags'} placeholder={t('ads.input_fbadid')} className={"_fb_ads_id"} />
                                                    )}
                                                </AntForm.Item>
                                            </div>
                                        </Col>
                                        <Col span={12}>
                                            <div className="form-item">
                                                <AntForm.Item className={"form-item-item"} label={<span
                                                    className="bold-400">{t("ads.default")}</span>}>
                                                    {getFieldDecorator('adDefault', {
                                                        valuePropName: 'checked',
                                                        initialValue: objectGet(ads, 'adDefault', false)
                                                    })(
                                                        <Switch size={"small"} className="_ad_default"/>
                                                    )}
                                                </AntForm.Item>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>

                            <Col span={24}>
                                <Divider/>
                            </Col>

                            <Col span={24}>
                            {!!configWholeSalePrice.length && (
                                <WholeSaleConfig
                                    {...this.props}
                                    configWholeSalePrice={configWholeSalePrice}
                                    onAddWholeSaleConfig={this.onAddWholeSaleConfig}
                                    onRemoveWholeSaleConfig={this.onRemoveWholeSaleConfig}
                                    toggleCheckAllConfig={this.toggleCheckAllConfig}
                                    toggleCheckConfig={this.toggleCheckConfig}
                                />
                            )}
                            </Col>
                        </Row>
                    </div>
                </div>

                <div className="white-box mg-t-20">
                    <LandingUrls
                        {...this.props}
                        urls={landingUrls}
                        onChange={this.handleChangeLandingUrl}
                    />
                </div>

                <div className="white-box mg-t-20">
                    <GiftConfig
                        t={this.props.t}
                        adId={this.props.adId}
                    />
                </div>
            </>
        )
    }
}

export {Form};
