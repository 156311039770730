import React, {Component} from 'react';
import {translate} from "react-i18next";
import * as _ from 'lodash';
import {Filter} from "./Filter";
import ContactService from "../../../services/ContactService";
import {LocalStore} from "../../../utils/LocalStore";
import {CONTACT_SCOPES} from "../../../utils/api/Scopes";
import UserService from "../../../services/UserService";
import {List} from "./List";
import {arrayConcat, getCurrentModule, getParamsAsObject, getUrlQueryString} from "../../../utils/helper";
import CampaignService from "../../../services/CampaignService";
import VendorService from "../../../services/VendorService";
import {Url} from "../../../utils/Url";
import AdService from "../../../services/AdService";
import {notification} from "antd/lib/index";
import UserGroupService from "../../../services/UserGroupService";

class Lead extends Component {

    constructor(props) {
        super(props);
        this.mounted = true;
        this.moduleName = 'telesale';
        this.state = {
            dataSource: [],
            vendor: LocalStore.getInstance().read('vendor'),
            currentPage: 1,
            totalItems: 50,
            pageSize: 100,
            contactStatuses: [],
            callStatuses: [],
            visibleColumns:[
                {name:"all",language:"contact.check_all",priority:0,selected:false,isShow:true},
                {name:"customerName",language:"contact.fullname",priority:1,selected:true,isShow:false},
                {name:"campaignId",language:"contact.campaign",priority:2,selected:true,isShow:false},
                {name:"createdAt",language:"contact.created_at",priority:3,selected:false,isShow:true},
                {name:"customerPhone",language:"contact.phone",priority:4,selected:true,isShow:false},
                {name:"assignTo",language:"contact.assign_to",priority:5,selected:true,isShow:false},
                {name:"assignedAt",language:"contact.assigned_by",priority:6,selected:true,isShow:false},
                {name:"assignedBy",language:"contact.assigned_by",priority:7,selected:false,isShow:true},
                {name:"contactStatusId",language:"contact.stage",priority:8,selected:false,isShow:true},
                {name:"totalCall",language:"contact.calls",priority:9,selected:false,isShow:true},
                {name:"callStatusId",language:"contact.status",priority:10,selected:false,isShow:true},
                {name:"callTime",language:"contact.callTime",priority:11,selected:false,isShow:true},
                {name:"deliveryDate",language:"contact.deliveryDate",priority:12,selected:false,isShow:true},
                {name:"note",language:"contact.note",priority:13,selected:false,isShow:true}
            ],
            loading: true,
            loadingCampaign: true,
            loadingProduct: true,
            loadingUserAssignTo: true,
            loadingUserAdsCreate: true,
            loadingCallStatus: true,
            loadingContactStatus: true,
            pageCount: 0,
            campaigns: [],
            products: [],
            userAssignTo: [],
            userAdsCreate: [],
            initialCampaigns: [],
            initialProducts: [],
            initialUserAssignTo: [],
            initialUserAdsCreate: [],
            initialCallStatuses: [],
            isShowPopupImportContact: false,
            isShowPopupImportContactFailed: false,
            importContactFailedItems: [],
            isShowPopupCreateContact: false,
            authUser: LocalStore.getInstance().read('authUser')
        };
    }


    componentWillMount() {
        if (!UserService.isAllowed(CONTACT_SCOPES.CONTACT_VIEW)) {
            this.props.history.replace('/403')
        }

        if(localStorage.getItem("visibleColumnsContactTelesale") !== null){
            const defaultVisibleColumns = [...this.state.visibleColumns];
            const oldVisibleColumns = JSON.parse(localStorage.getItem("visibleColumnsContactTelesale")) || this.state.visibleColumns;

            for (let config of defaultVisibleColumns) {
                const exist = _.find(oldVisibleColumns, item => item.name === config.name);
                if (!exist) {
                    oldVisibleColumns.push(config);
                }
            }
            this.setState({
                visibleColumns: [...oldVisibleColumns]
            });
        }
    };

    componentDidMount = () => {
        this.mounted = true;
        window.addEventListener("popstate", this.onPopstate.bind(this), false);
        this.loadData();
    };

    componentWillUnmount() {
        this.mounted = false;
        window.removeEventListener("popstate", this.onPopstate.bind(this), false);
    }

    onPopstate() {
        // Url thay đổi thì chỉ load lại list
        if(this.mounted && getCurrentModule(null, true) === this.moduleName) {
            let queryObject = getParamsAsObject(getUrlQueryString());
            this.loadContact(queryObject);
        }
    };

    loadData = () => {
        let queryObject = getParamsAsObject(getUrlQueryString());
        this.loadContact(queryObject);
        this.callApiGetContactStatuses();
        this.fetchCallStatus({}, (error, response) => {
            if(!error) {
                this.setState({initialCallStatuses: response});
            }
        });
        this.fetchCampaign({}, (error, response) => {
            if(!error) {
                this.setState({initialCampaigns: response.list});
            }
        });
        this.fetchProduct({}, (error, response) => {
            if(!error) {
                this.setState({initialProducts: response.list});
            }
        });
        this.fetchUserAdsCreate({}, (error, response) => {
            if(!error) {
                this.setState({initialUserAdsCreate: response.list});
            }
        });
        this.fetchUserAssignTo({}, (error, response) => {
            if(!error) {
                this.setState({initialUserAssignTo: response.list});
            }
        });
    };

    fetchCampaign = (filter = {}, cb) => {
        this.setState({loadingCampaign: true});
        const {vendor} = this.state;
        let defaultFilter = {vendor_id: vendor.id, page_size: 50};
        defaultFilter = Object.assign(defaultFilter, filter);
        CampaignService.suggest(defaultFilter, (error, response) => {
            if(!error) {
                this.setState({campaigns: response.list, loadingCampaign: false});
            }
            if(typeof cb === 'function') cb(error, response);
        });
    };

    fetchProduct = (filter = {}, cb) => {
        this.setState({loadingProduct: true});
        const {vendor} = this.state;
        let defaultFilter = {vendor_id: vendor.id, page_size: 20, order: ["id DESC"]};
        defaultFilter = Object.assign(defaultFilter, filter);
        VendorService.getProducts(vendor.id, defaultFilter, (error, response) => {
            if(!error) {
                this.setState({products: response.list, loadingProduct: false});
            }
            if(typeof cb === 'function') cb(error, response);
        });
    };

    fetchUserAssignTo = (filter = {}, cb) => {
        const {t} = this.props;
        this.setState({loadingUserAssignTo: true});
        const {vendor} = this.state;
        VendorService.getUserInVendor(vendor.id, filter, (error, response) => {
            if(!error) {
                let hasUnassign = false;
                if(!Object.keys(filter).length) {
                    hasUnassign = true;
                } else if(response.list.length === 0) {
                    if(t('contact.unassign_to').indexOf(filter.username) >= 0) {
                        hasUnassign = true;
                    } else if('ids' in filter) {
                        let arr = filter.ids.split(',');
                        if(arr.indexOf('0') >= 0) {
                            hasUnassign = true;
                        }
                    }
                } else {
                    for(let k in filter) {
                        let v = filter[k];
                        if(k === 'ids') {
                            let arr = v.split(',');
                            if(arr.indexOf('0') >= 0) {
                                hasUnassign = true;
                                break;
                            }
                        } else if(k === 'username' && !v) {
                            hasUnassign = true;
                        }
                    }
                }

                if(hasUnassign) {
                    response.list = [{id: 0, username: t('contact.unassign_to')}, ...response.list];
                }
                this.setState({userAssignTo: response.list, loadingUserAssignTo: false});
            }
            if(typeof cb === 'function') cb(error, response);
        });
    };

    fetchUserAdsCreate = (filter = {}, cb) => {
        this.setState({loadingUserAdsCreate: true});
        const {vendor} = this.state;
        VendorService.getUserInVendor(vendor.id, filter, (error, response) => {
            if(!error) {
                this.setState({userAdsCreate: response.list, loadingUserAdsCreate: false});
            }
            if(typeof cb === 'function') cb(error, response);
        });
    };

    callApiGetContactStatuses = (cb) => {
        this.setState({loadingContactStatus: true});
        ContactService.getContactStatuses((error, response) => {
            if(!error) this.setState({contactStatuses: response, loadingContactStatus: false});
            if(typeof cb === 'function') cb(error, response);
        });
    };

    fetchCallStatus = (filter = {}, cb) => {
        this.setState({loadingCallStatus: true});
        ContactService.getCallStatuses((error, response) => {
            let data = response;
            if(!error){
                for(let item of data) {
                    if(item.id === 3){
                        item.color = "blue"
                    }
                    if(item.id === 1){
                        item.color = "green"
                    }
                    if(item.id === 2){
                        item.color = "red"
                    }
                    if(item.id === 4 || item.id === 7){
                        item.color = "gold"
                    }
                    if(item.id === 5 || item.id === 6){
                        item.color = "purple"
                    }
                    if(item.id === 8){
                        item.color = "magenta"
                    }
                }

                if(Object.keys(filter).length) {
                    _.forEach(filter, (v, k) => {
                        if(k === 'value') {
                            data = _.filter(data, (item) => {return item.value.toString().indexOf(v) >= 0});
                        } else if(k === 'id') {
                            data = _.filter(data, (item) => {return item.id == v});
                        } else if(k === 'ids') {
                            let arr = v.toString().split(',');
                            data = _.filter(data, (item) => {return arr.indexOf(item.id.toString()) >= 0});
                        }
                    });
                }

                this.setState({callStatuses: data, loadingCallStatus: false})
            }

            if(typeof cb === 'function') cb(error, data);
        });
    };

    fetchAds = (filter = {}, cb) => {
        const {vendor} = this.state;
        let defaultFilter = {
            vendor_id: vendor.id,
            product_active: true
        };
        filter = _.mergeWith(defaultFilter, filter, arrayConcat);
        AdService.getAds(filter, cb);
    };

    loadContact = (filter = {}) => {
        this.setState({loading: true});
        const {authUser} = this.state;
        let defaultFilter = {
            include: ['vendor', 'country', 'campaign', 'product', 'user', 'userCreated'],
            order: ["updatedAt DESC"],
            assign_to: authUser.id,
            page: filter.page || 1,
            page_size: filter.page_size || 100
        };

        if(Object.keys(filter).length) {
            defaultFilter = Object.assign(defaultFilter, filter);
        }

        defaultFilter.assign_to = authUser.id;

        ContactService.getContacts(defaultFilter, (error, response) => {
            if(!error) {
                this.setState({
                    dataSource: response.list,
                    currentPage: response.metadata.currentPage,
                    totalItems: response.count,
                    pageSize: response.metadata.pageSize,
                    pageCount: response.metadata.pageCount,
                    loading: false
                })
            }
        });
    };

    fetchGroupsByProductId = (productId, filter = {}, cb) => {
        const {vendor} = this.state;
        VendorService.getGroupInProduct(vendor.id, productId, filter, cb);
    };

    fetchUsersByGroupId = (groupId, cb) => {
        UserGroupService.getMembers(groupId, cb);
    };

    assignContact = (contactId, userId, cb) => {
        ContactService.asignContactManual(contactId, userId, cb);
    };

    importContact = (data, cb) => {
        ContactService.importFile(data, cb);
    };

    requestContact = (data, cb) => {
        ContactService.requestContact(data, cb);
    };

    onImportResponse = (error, response) => {
        const {t} = this.props;
        if(!error) {
            this.togglePopupImportContact();
            let queryObject = getParamsAsObject(getUrlQueryString());
            this.loadContact(queryObject);
            if(response.success) {
                notification.success({
                    message: t('message.success')
                });
            } else {
                this.setState({
                    importContactFailedItems: response.failedItems
                }, () => {
                    this.togglePopupImportContactFailed();
                });
            }
        } else {
            let message = error.message;
            if(error.code === 'FILE_COLUMN_NOT_VALID') {
                message = t('contact.import_contact_error_file_column');
            } else if(error.code === 'FILE_HAS_NOT_ROW') {
                message = t('contact.import_contact_error_file_has_not_row');
            } else if(error.code === 'FILE_MAX_200_ROWS') {
                message = t('contact.import_contact_error_file_max_200_row');
            }
            notification.error({
                message: message
            });
        }
    };

    togglePopupImportContactFailed = () => {
        this.setState({
            isShowPopupImportContactFailed: !this.state.isShowPopupImportContactFailed
        });
    };

    onShowSizeChange = (current, pageSize) => {
        let uri = this.getUrlInstance();
        uri.replaceParam('page', 1);
        uri.replaceParam('page_size', pageSize);
        this.pushQueryString(uri.getQueryString());
    };

    onChangePage = (page, pageSize) => {
        let uri = this.getUrlInstance();
        uri.replaceParam('page', page);
        uri.replaceParam('page_size', pageSize);
        this.pushQueryString(uri.getQueryString());
    };

    isShowCheckbox = (priority) => {
        let visibleColumns = this.state.visibleColumns;
        let visibleColumnsDefault = [1,2,4,5,6];
        if(priority === 0){
            let selected = !visibleColumns[0].selected;
            visibleColumns[0].selected = selected;
            for (let i = 1; i < visibleColumns.length; i++) {
                if (visibleColumnsDefault.indexOf(i) > -1) { continue; }
                visibleColumns[i].selected = selected;
            }
        }else{
            for (let i = 1; i < visibleColumns.length; i++) {
                if(visibleColumns[i].priority === priority){
                    visibleColumns[i].selected = !visibleColumns[i].selected;
                }
            }

            let checkAll = false;
            for (let i = 1; i < visibleColumns.length; i++) {
                if(visibleColumns[i].selected === false){
                    checkAll = true;
                }
            }
            if(checkAll){
                visibleColumns[0].selected = false;
            }else{
                visibleColumns[0].selected = true;
            }
        }


        localStorage.setItem("visibleColumnsContactTelesale",JSON.stringify(visibleColumns));
        this.setState({
            visibleColumns:visibleColumns
        })
    };

    getColumnTable = () => {
        let visibleColumnsDisplay = [];
        let visibleColumns = this.state.visibleColumns;
        for (let i = 0; i < visibleColumns.length; i++) {
            if(visibleColumns[i].priority === 0){
                continue;
            }
            if(visibleColumns[i].selected){
                visibleColumnsDisplay.push(visibleColumns[i].name)
            }
        }
        return visibleColumnsDisplay;
    };

    IsShowColumnCheckbox = () => {
        let listColumn = [];
        let columnAllowSelected = this.state.visibleColumns;
        for (let i = 0; i < columnAllowSelected.length; i++) {
            if(columnAllowSelected[i].isShow){
                listColumn.push(columnAllowSelected[i])
            }
        }
        return listColumn;
    };

    getUrlInstance = (newInstance = false) => {
        if(newInstance) {
            return new Url();
        }
        return new Url('?'+getUrlQueryString());
    };

    pushQueryString = (queryString) => {
        this.props.history.push(`/telesale${queryString}`);
    };

    resetDataSource = (key) => {
        this.setState({
            [key]: this.state[`initial${_.upperFirst(key)}`]
        });
    };

    togglePopupImportContact = () => {
        this.setState({isShowPopupImportContact: !this.state.isShowPopupImportContact});
    };

    togglePopupCreateContact = () => {
        this.setState({isShowPopupCreateContact: !this.state.isShowPopupCreateContact});
    };

    fetchGroups = (filter = {}, cb) => {
        const {vendor} = this.state;
        let defaultFilter = {vendor_id: vendor.id};
        filter = _.mergeWith(defaultFilter, filter, arrayConcat);
        UserGroupService.getUserGroup(filter, cb);
    };

    countContactUnassign = (groupIds, cb) => {
        const {vendor} = this.state;
        ContactService.getCountContactUnassignInGroups({
            groupIds: groupIds,
            vendorId: vendor.id
        },cb);
    };

    assignContactAuto = (quantity, groupId, cb) => {
        const {vendor} = this.state;
        const vendorId = vendor.id;
        ContactService.assignContactAuto(quantity, [groupId], vendorId, cb);
    };

    render() {
        const {t} = this.props;
        let visibleColumnsDisplay = this.getColumnTable();
        let columnAllowSelected = this.IsShowColumnCheckbox();
        const {dataSource, vendor, totalItems, currentPage, pageSize, callStatuses,
            contactStatuses,pageCount, campaigns, products,
            loadingCampaign, loadingProduct, loadingUserAdsCreate, loadingUserAssignTo, loadingCallStatus,
            loadingContactStatus, userAdsCreate, userAssignTo} = this.state;
        return (

                <div className="contact-list-page list-page">
                    <Filter
                        {...this.props}
                        contactStatuses={contactStatuses}
                        callStatuses={callStatuses}
                        campaigns={campaigns}
                        products={products}
                        userAdsCreate={userAdsCreate}
                        userAssignTo={userAssignTo}
                        loadingCampaign={loadingCampaign}
                        loadingProduct={loadingProduct}
                        loadingUserAdsCreate={loadingUserAdsCreate}
                        loadingUserAssignTo={loadingUserAssignTo}
                        loadingCallStatus={loadingCallStatus}
                        loadingContactStatus={loadingContactStatus}
                        fetchCampaign={this.fetchCampaign}
                        fetchProduct={this.fetchProduct}
                        fetchUserAssignTo={this.fetchUserAssignTo}
                        fetchUserAdsCreate={this.fetchUserAdsCreate}
                        fetchCallStatus={this.fetchCallStatus}
                        getUrlInstance={this.getUrlInstance}
                        pushQueryString={this.pushQueryString}
                        resetDataSource={this.resetDataSource}
                    />
                    <List
                        {...this.props}
                        dataSource={dataSource}
                        currentPage={currentPage}
                        pageSize={pageSize}
                        pageCount={pageCount}
                        callStatuses={callStatuses}
                        contactStatuses={contactStatuses}
                        totalItems={totalItems}
                        vendor={vendor}
                        onChangePage={this.onChangePage}
                        onShowSizeChange={this.onShowSizeChange}
                        visibleColumns={columnAllowSelected}
                        isShowCheckbox={this.isShowCheckbox}
                        visibleColumnsDisplay={visibleColumnsDisplay}
                        loading={this.state.loading}
                        loadContact={this.loadContact}
                        getUrlInstance={this.getUrlInstance}
                        pushQueryString={this.pushQueryString}
                        requestContact={this.requestContact}
                        fetchGroupsByProductId={this.fetchGroupsByProductId}
                        fetchUsersByGroupId={this.fetchUsersByGroupId}
                        assignContact={this.assignContact}
                        fetchGroups={this.fetchGroups}
                        countContactUnassign={this.countContactUnassign}
                        assignContactAuto={this.assignContactAuto}
                    />
                </div>
        );
    }
}

export default (translate())(Lead)