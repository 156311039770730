
/**
 * Chuyển error khi gọi api về dạng object để có thể lưu trong store
 * @param errors
 * @returns {{}}
 */
export const parseErrors = errors => {
    const submitErrors = {};
    if (errors.details) {
        const {messages} = errors.details;
        Object.keys(messages).map(field =>
            submitErrors[field] = (messages[field][0])
        );
    }
    if (Object.keys(submitErrors).length === 0) {
        submitErrors['_error'] = errors.message;
    }
    return submitErrors;
};
/**
 * Cộng thêm days ngày vào ngày hiện tịa
 * @param date
 * @param days
 * @returns {Date}
 */
export const addDays=(days)=> {
    var result = new Date();
    result.setDate(result.getDate() + days);
    return result;
}

export const parseSecondToTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const second = Math.floor(seconds - minutes * 60);
    const time = `${minutes < 10 ? `0${minutes}` : minutes}:${second < 10 ? `0${second}` : second}`;
    return time;
}