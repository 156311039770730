import React from 'react';
import {Button, Empty, notification, Select, Spin} from "antd";
import * as _ from 'lodash';
import {doCallBack, objectGet} from "../../utils/helper";
import ContactService from "../../services/ContactService";

const styles = {
    btnUnAssignOrder: {
        backgroundColor: '#FAAD14',
        color: "#fff",
        borderRadius: '4px',
        width: '100%',
        display: 'block',
        marginBottom: '10px',
        border: 'transparent'
    }
};

class AssignContactManual extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            groups: [],
            initialGroups: [],
            users: [],
            initialUsers: [],
            groupSelected: null,
            userSelected: null,
            loadingGroup: false
        };
        this.onSearchGroup = _.debounce(this.onSearchGroup, 300);
    }

    resetState = () => {
        this.setState({
            groupSelected: null,
            userSelected: null,
            groups: [],
            initialGroups: [],
            users: [],
            initialUsers: [],
            loadingGroup: false
        }, () => {
            this.fetchGroups({}, (error, response) => {
                if(!error) {
                    this.setState({initialGroups: response.list});
                }
            });
        });
    };

    componentDidMount = () => {
        this.fetchGroups({}, (error, response) => {
            if(!error) {
                this.setState({initialGroups: response.list});
            }
        });
    };

    fetchGroups = (filter = {}, cb) => {
        this.props.fetchGroups(filter, (error, response) => {
            if(!error) {
                this.setState({groups: response.list});
            }
            doCallBack(cb, [error, response]);
        })
    };

    fetchUsersInGroup = (cb) => {
        let {groupSelected} = this.state;
        this.props.fetchUsersByGroupId(groupSelected.id, (error, response) => {
            if(!error) {
                this.setState({users: response.list});
            }
            doCallBack(cb, [error, response]);
        });
    };

    onChangeGroup = (value) => {
        this.setState({
            groupSelected: JSON.parse(value)
        }, () => {
            this.fetchUsersInGroup();
        });
    };

    onSearchGroup = (value) => {
        this.fetchGroups({name: value});
    };

    onFocusGroup = () => {
        this.setState({
            groups: this.state.initialGroups
        });
    };

    onChangeUser = (value) => {
        const {t} = this.props;
        this.setState({
            userSelected: JSON.parse(value)
        }, () => {
            let {userSelected} = this.state;
            this.props.onChangeUser(userSelected);

            this.props.assignContact(userSelected.id, (error, response) => {
                if(!error) {
                    notification.success({
                        message: t('message.success')
                    });
                } else {
                    if(error.code === 'PRODUCT_NOT_ACTIVE') {
                        notification.error({
                            message: t('error.product_is_not_active')
                        });
                    } else {
                        notification.error({
                            message: error.message
                        });
                    }
                }
                this.props.onAssignContact(error, response);
            });
        });
    };

    unAssign = () => {
        const {t, contact} = this.props;
        ContactService.asignContactManual(contact.id, 0, (error, response) => {
            if(!error) {
                notification.success({
                    message: t('message.success')
                });
            } else {
                if(error.code === 'PRODUCT_NOT_ACTIVE') {
                    notification.error({
                        message: t('error.product_is_not_active')
                    });
                } else {
                    notification.error({
                        message: error.message
                    });
                }
            }
            this.setState({
                groupSelected: null,
                userSelected: null
            });
            this.props.onAssignContact(error, response);
        });
    };

    render() {
        const {t, showButtonUnAssign, contact} = this.props;
        const {groupSelected, groups, users, loadingGroup} = this.state;
        let userSelected = objectGet(contact, 'user', null);
        return (
            <div className="popup-assign-contact-manual-container">
                <div className="mg-bt-10">
                    {showButtonUnAssign && (
                        <Button
                            className="btn-unassign-order"
                            style={styles.btnUnAssignOrder}
                            onClick={this.unAssign}
                        >
                            <span>{t('contact.unassign_btn')}</span>
                        </Button>
                    )}
                    <Select
                        className={'_select_group_user'}
                        style={{width: 200}}
                        showSearch={true}
                        placeholder={t('contact-detail.select_group')}
                        onFocus={this.onFocusGroup}
                        onChange={this.onChangeGroup}
                        onSearch={this.onSearchGroup}
                        value={groupSelected ? [JSON.stringify(groupSelected)] : []}
                        notFoundContent={(loadingGroup ? <Spin size="small" /> : <Empty description={t("table.empty")} />)}
                    >
                        {Array.isArray(groups) && groups.map((item) => (
                            <Select.Option
                                key={item.id}
                                value={JSON.stringify(item)}
                                className={`_group_user_item _group_user_item_${item.id}`}
                            >{item.name}</Select.Option>
                        ))}
                    </Select>
                </div>
                <div>
                    <Select
                        defaultActiveFirstOption={false}
                        showSearch={true}
                        className={`_select_user`}
                        style={{width: 200}}
                        placeholder={userSelected ? userSelected.username: t('contact-detail.select_user')}
                        disabled={!groupSelected}
                        onChange={this.onChangeUser}
                        value={[]}
                        notFoundContent={<Empty description={t("table.empty")} />}
                    >
                        {Array.isArray(users) && users.map((item) => (
                            <Select.Option
                                key={item.id}
                                value={JSON.stringify(item)}
                                className={`_user_item _user_item_${item.id}`}
                            >{item.username}</Select.Option>
                        ))}
                    </Select>
                </div>
            </div>
        );
    }
}

AssignContactManual.defaultProps = {
    contact: {},
    fetchGroups: () => {},
    fetchUsersByGroupId: () => {},
    assignContact: () => {},
    fetchContact: () => {},
    onChangeUser: (user) => {},
    onAssignContact: (error, response) => {},
    showButtonUnAssign: false
};

export {AssignContactManual};
