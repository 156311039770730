import React, {Component} from 'react';
import {Col, Divider, Empty, Form as AntForm, Input, Row, Select, Switch} from 'antd';
import * as _ from 'lodash';
import {arrayConcat, doCallBack} from "../../../../utils/helper";
import {WholeSaleConfig} from "./WholeSaleConfig";

class Form extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: 1,
            campaigns: [],
            campaignSelected: null,
            initialCampaigns: [],
            adsets: [],
            initialAdsets: [],
            configWholeSalePrice: [],
            loadingCampaign: false,
            loadingAdset: false
        };
        this.onSearchAdset = _.debounce(this.onSearchAdset, 300);
    }

    componentDidMount = () => {
        this.fetchCampaigns({product_active: true}, (error, response) => {
            if(!error) {
                this.setState({initialCampaigns: response.list});
            }
        })
    };

    getCheckedConfigItems = () => {
        let {configWholeSalePrice} = this.state;
        return _.filter(configWholeSalePrice, (item) => {
            return item.checked;
        });
    };

    fetchCampaigns = (filter = {}, cb) => {
        this.setState({loadingCampaign: true});
        this.props.fetchCampaigns(filter, (error, response) => {
            this.setState({loadingCampaign: false});
            if(!error) {
                this.setState({campaigns: response.list});
            }
            doCallBack(cb, [error, response]);
        });
    };

    fetchAdsets = (filter = {}, cb) => {
        this.setState({loadingAdset: true});
        const {campaignSelected} = this.state;
        let defaultFilter = {campaign_id: [campaignSelected.id]};
        filter = _.mergeWith(defaultFilter, filter, arrayConcat);
        filter.campaign_id = _.uniq(filter.campaign_id);
        this.props.fetchAdsets(filter, (error, response) => {
            this.setState({loadingAdset: false});
            if(!error) {
                this.setState({adsets: response.list});
            }
            doCallBack(cb, [error, response]);
        });
    };

    onChange = (e) => {
        this.setState({
            value: e.target.value,
        });
    };

    getUid = () => {
        return _.uniqueId('uid')
    };

    onChangeCampaign = (value, e) => {
        let campaign = JSON.parse(value);
        let {form} = this.props;
        form.resetFields('adset');
        let configWholeSalePrice = campaign.configWholeSale || [];
        _.forEach(configWholeSalePrice, (item) => {
            item.checked = true;
            _.forEach(item.wholeSalePrice, (row) => {
                row.uid = this.getUid();
            });
        });

        this.setState({
            campaignSelected: campaign,
            configWholeSalePrice: configWholeSalePrice
        }, () => {
            this.fetchAdsets({campaign_id: [campaign.id], product_active: true}, (error, response) => {
                if(!error) {
                    this.setState({
                        initialAdsets: response.list
                    });
                }
            });
        });
    };

    onSearchCampaign = (value) => {
        this.fetchCampaigns({name: value});
    };

    onSearchAdset = (value) => {
        this.fetchAdsets({keyword: value});
    };


    getConfigWholeSale = (value) => {
        this.props.getConfigWholeSale(value);
    };

    resetDataSource = (key) => {
        let data = [];
        if(key === 'adsets') {
            data = this.state.initialAdsets;
        } else if(key === 'campaigns') {
            data = this.state.initialCampaigns;
        }

        this.setState({
            [key]: data
        });
    };

    onAddWholeSaleConfig = (index) => {
        const max = 5;
        let {configWholeSalePrice} = this.state;
        let config = configWholeSalePrice[index];
        if(config.wholeSalePrice.length <= max-1) {
            config.wholeSalePrice.push({
                from: "",
                to: "",
                price: "",
                uid: this.getUid()
            });
            configWholeSalePrice[index] = config;
            this.setState({configWholeSalePrice});
        }
    };

    onRemoveWholeSaleConfig = (index,uid) => {
        let {configWholeSalePrice} = this.state;
        let config = configWholeSalePrice[index];

        let indexUid = _.findIndex(config.wholeSalePrice, (item) => item.uid == uid);
        config.wholeSalePrice.splice(indexUid, 1);
        configWholeSalePrice[index] = config;
        this.setState({configWholeSalePrice});
    };

    toggleCheckAllConfig = () => {
        let {configWholeSalePrice} = this.state;
        let countChecked = 0;
        for(let config of configWholeSalePrice) {
            if(config.checked) countChecked ++;
        }
        let isCheckAll = countChecked === configWholeSalePrice.length;

        for(let config of configWholeSalePrice) {
            if(typeof config.checked === 'undefined') {
                config.checked = false;
            }
            config.checked = !isCheckAll;
        }
        this.setState({configWholeSalePrice}, () => {
            if(this.getCheckedConfigItems().length) {
                this.props.resetValidateErrors();
            }
        });
    };

    toggleCheckConfig = (index) => {
        let {configWholeSalePrice} = this.state;
        let config = configWholeSalePrice[index];
        config.checked = !config.checked;
        configWholeSalePrice[index] = config;
        this.setState({configWholeSalePrice});
        if(config.checked) {
            this.props.resetValidateErrors();
        }
    };

    render() {
        const {t} = this.props;
        const {getFieldDecorator} = this.props.form;
        const {campaigns, adsets, loadingCampaign, loadingAdset, configWholeSalePrice} = this.state;
        return (
            <>
                <div className="white-box">
                    <div className="form-header">
                        <h3 className="pd-l-30 pd-r-30 pd-t-15 pd-bt-15 mg-0">{t('ads.create')}</h3>
                        <div className="h-line"/>
                    </div>
                    <div className="form-body pd-30 pd-t-10 pd-bt-10">
                        <Row gutter={20}>
                            <Col span={12}>
                                <div className="form-item">
                                    <AntForm.Item
                                        label={t("campaign.title")}
                                        className="campaign _campaign"
                                    >
                                        {getFieldDecorator('campaign', {
                                            rules: [
                                                {required: true, message: t("message.required")},
                                            ],
                                        })(
                                            <Select
                                                loading={loadingCampaign}
                                                showSearch
                                                notFoundContent={<Empty description={t("table.empty")}/>}
                                                allowClear={true}
                                                placeholder={t("ads.select_campaign")}
                                                onSearch={this.onSearchCampaign}
                                                onFocus={this.resetDataSource.bind(this, "campaigns")}
                                                onSelect={this.onChangeCampaign}
                                                defaultActiveFirstOption={false}
                                            >
                                                {campaigns.map((item, index) => (
                                                    <Select.Option
                                                        key={index}
                                                        value={JSON.stringify(item)}
                                                        className="_option-campaigns">{item.name}</Select.Option>
                                                ))}
                                            </Select>
                                        )}
                                    </AntForm.Item>
                                </div>
                                <div className="form-item">
                                    <AntForm.Item
                                        className={"form-item-item"}
                                        label={<span className="bold-400">{t("ads.name")}</span>}
                                    >
                                        {getFieldDecorator('name', {
                                            rules: [
                                                {required: true, message: t("message.required")},
                                                {max: 160, message: t("error.length_160")}
                                            ],
                                        })(
                                            <Input placeholder={t("ads.input_name")} className="_ads-name"/>
                                        )}
                                    </AntForm.Item>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div className="form-item">
                                    <AntForm.Item
                                        label={t("ads_group_list.tab_name")}
                                        className="adset _adset"
                                    >
                                        {getFieldDecorator('adset')(
                                            <Select
                                                loading={loadingAdset}
                                                showSearch
                                                notFoundContent={<Empty description={t("table.empty")}/>}
                                                allowClear={true}
                                                placeholder={t("ads.select_adset")}
                                                onSearch={this.onSearchAdset}
                                                onFocus={this.resetDataSource.bind(this, "adsets")}
                                                onChange={this.onChangeAdset}
                                                defaultActiveFirstOption={false}
                                            >
                                                {adsets.map((item, index) => (
                                                    <Select.Option
                                                        key={item.id}
                                                        value={JSON.stringify(item)}
                                                        className="_option_adset">{item.name}</Select.Option>
                                                ))}
                                            </Select>
                                        )}
                                    </AntForm.Item>
                                </div>
                                <div className="form-item">
                                    <Row gutter={20}>
                                        <Col span={12}>
                                            <div className="form-item">
                                                <AntForm.Item
                                                    className={"form-item-item"}
                                                    label={<span className="bold-400">{t("ads.fbadid")}</span>}
                                                >
                                                    {getFieldDecorator('facebookIds')(
                                                        <Select mode={'tags'} placeholder={t('ads.input_fbadid')} className={"_fb_ads_id"} />
                                                    )}
                                                </AntForm.Item>
                                            </div>
                                        </Col>
                                        <Col span={12}>
                                            <div className="form-item">
                                                <AntForm.Item className={"form-item-item"} label={<span
                                                    className="bold-400">{t("ads.default")}</span>}>
                                                    {getFieldDecorator('adDefault', {
                                                        valuePropName: 'checked',
                                                        initialValue: false
                                                    })(
                                                        <Switch size={"small"} className="_ad_default"/>
                                                    )}
                                                </AntForm.Item>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>

                            <Col span={24}>
                                <Divider/>
                            </Col>

                            <Col span={24}>
                            {!!configWholeSalePrice.length && (
                                <WholeSaleConfig
                                    {...this.props}
                                    configWholeSalePrice={configWholeSalePrice}
                                    onAddWholeSaleConfig={this.onAddWholeSaleConfig}
                                    onRemoveWholeSaleConfig={this.onRemoveWholeSaleConfig}
                                    toggleCheckAllConfig={this.toggleCheckAllConfig}
                                    toggleCheckConfig={this.toggleCheckConfig}
                                />
                            )}
                            </Col>
                        </Row>
                    </div>
                </div>
            </>
        )
    }
}

export {Form};
