import React, {Component} from 'react';
import {translate} from "react-i18next";
import StandardLayout from "../../components/StandardLayout";
import {HeaderAdd} from "./HeaderAdd";
import LocationService from "../../services/LocationService";
import PackageService from "../../services/PackageService";
import VendorService from "../../services/VendorService";
import UserService from "../../services/UserService";
import {FormVendorAdd} from "./FormVendorAdd";
import {notification} from 'antd';
import {VENDORS_SCOPES} from "../../utils/api/Scopes";

class Add extends Component {

    constructor(props) {
        super(props);
        this.state = {
            countries: [],
            package:[],
            defaultRoles:[],
            managers:[]
        };

    }

    componentWillMount() {
        if (!UserService.isAllowed(VENDORS_SCOPES.VENDORS_CREATE)) {
            this.props.history.replace('/403')
        }
    };

    componentDidMount = () => {
        this.callApiGetCountries();
        this.callApiGetPackage();
        this.callApiGetUser();
    };

    callApiGetCountries = (filter = {}, cb) => {
        LocationService.getCountriesAutocomplete(filter, (error, response) => {
            if(!error) {
                this.setState({
                    countries: response
                });
            }
            if(typeof cb === 'function') cb(error, response);
        });
    };

    callApiGetPackage = (filter = {}, cb) => {
        PackageService.getPackage(filter, (error, response) => {
            if(!error) {
                this.setState({
                    package: response
                });
            }
            if(typeof cb === 'function') cb(error, response);
        });
    };

    callApiGetUser = (cb) => {
        UserService.getListUser((error, response) => {
            if(!error) {
                this.setState({
                    managers:response.list,
                })
            }
            if(typeof cb === 'function') cb(error, response);
        });
    };

    handleCreateVendor = (data,cb) => {
        const {t} = this.props;
        VendorService.createVendor(data, (err, res) => {
            if (!err) {
                notification['success']({
                    message: t("message.success"),
                });
                this.props.history.push(`/vendors`);
            } else {
                notification['error']({
                    message: err.message,
                });
                if(typeof cb === 'function') cb();
            }
        });
    };

    render() {
        const {t} = this.props;
        return (
            <StandardLayout {...this.props} title={t("vendor.create")}>
                <div className="vendorAdd-list-page list-page">
                    <HeaderAdd {...this.props} />
                    <FormVendorAdd
                        {...this.props}
                        countries={this.state.countries}
                        package={this.state.package}
                        defaultRoles={this.state.defaultRoles}
                        managers={this.state.managers}
                        handleCreateVendor={this.handleCreateVendor}
                    />
                </div>
            </StandardLayout>
        );
    }
}

export default (translate())(Add)
