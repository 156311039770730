import React from 'react';
import {Input, notification, Form} from 'antd';

class EditableName extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: null,
            loading: false
        };
        this.refInput = React.createRef();
    }

    static getDerivedStateFromProps(nextProps, state) {
        if (('value' in nextProps) && state.value === null) {
            return {
                value: nextProps.value
            };
        }

        return null;
    }

    onSubmit = (e) => {
        e.preventDefault();
        let {form} = this.props;
        form.validateFields((error, values) => {
            if(!error) {
                this.setState({loading: true});
                const {t} = this.props;
                this.props.updateGroup(values, (error) => {
                    this.props.onHide();
                    if(!error) {
                        notification.success({
                            message: t('message.success')
                        });
                        this.props.fetchGroup();
                    } else {
                        notification.error({
                            message: error.message
                        });
                    }
                });
            }
        });
    };

    handleClickOutSide = (e) => {
        if(this.refInput && !this.refInput.current.contains(e.target)) {
            this.props.onHide(e);
        }
    };

    componentDidMount = () => {
        document.addEventListener('click', this.handleClickOutSide, false)
    };

    componentWillUnmount = () => {
        document.removeEventListener('click', this.handleClickOutSide, false);
    };

    render() {
        const {value, loading} = this.state;
        const {t} = this.props;
        const {getFieldDecorator} = this.props.form;
        return (
            <span ref={this.refInput}>
                <Form>
                    <Form.Item>
                        {getFieldDecorator('name', {
                            rules: [
                                {required: true, message: t('message.required')},
                                { max: 160, message: t("error.length_160")}
                            ],
                            initialValue: value
                        })(
                            <Input
                                style={{width: 200}}
                                onPressEnter={this.onSubmit}
                                disabled={loading}
                            />
                        )}
                    </Form.Item>
                </Form>
            </span>
        )
    }
}

EditableName = Form.create()(EditableName);

export {EditableName};