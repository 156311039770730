import i18n from "i18next";
import { reactI18nextModule } from "react-i18next";
import transitionEN from './resources/localization/locals/en/en';
import transitionVN from './resources/localization/locals/vn/vn';
import transitionIndonesia from './resources/localization/locals/indonesia/indonesia';
import transitionThailand from './resources/localization/locals/thailand/thailand';
import {LocalStore} from "./utils/LocalStore";
import _ from 'lodash';

const localStore = LocalStore.getInstance();
let defaultLang = localStore.read("language");

if(_.isEmpty(defaultLang)){
    defaultLang = 'en';
}

// the translations
const resources = {
    en: {
        translation: transitionEN
    },
    vi: {
        translation: transitionVN
    },
    indonesia: {
        translation: transitionIndonesia
    },
    thailand: {
        translation: transitionThailand
    }
};

i18n
    .use(reactI18nextModule) // passes i18n down to react-i18next
    .init({
        resources,
        lng: defaultLang,

        keySeparator: '.', // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;