import React from 'react';
import {translate} from 'react-i18next';
import {Layout} from "../Layout";
import {ListGroup} from "./ListGroup";

class WarehousePermission extends React.Component {
    render() {
        const content = (
            <div className="tab-pane-inner-box">
                <ListGroup
                    {...this.props}
                />
            </div>
        );
        return (
            <Layout
                {...this.props}
                tabActive={'permission'}
                tabContent={content}
            />
        )
    }
}

export default (translate())(WarehousePermission)