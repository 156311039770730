import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './i18n';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {LocalStore} from "./utils/LocalStore";
import {tracker} from "./utils/openReplayTracker";

let readZone = LocalStore.getInstance().read("timezone");
if(!readZone){
    LocalStore.getInstance().save('timezone', 'Asia/Manila');
    readZone = LocalStore.getInstance().read("timezone");
}
let moment = require('moment-timezone');
moment.tz.setDefault(readZone);

// Open Replay Tracker
tracker.start();

const loggedUser = LocalStore.getInstance().read("authUser");
if (loggedUser && loggedUser.username) {
    tracker.setUserID(loggedUser.username);
}

ReactDOM.render(
    <App />,
document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
