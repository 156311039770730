import React, {Component} from 'react';
import {Select} from 'antd';
import LocationService from "../../services/LocationService";

class SelectCountryAutocomplete extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            value: undefined,
            countries: []
        }
    }

    static getDerivedStateFromProps(props, state) {
        let newState = {...state};
        if ('value' in props) {
            newState.value = props.value;
        }

        return newState;
    }

    componentDidMount() {
        this.fetchCountries();
    }

    fetchCountries = (filter = {}) => {
        this.setState({loading: true});
        LocationService.getCountriesAutocomplete(filter, (error, response) => {
            this.setState({loading: false});
            if (!error) {
                this.setState({
                    countries: response
                });
            }
        });
    };

    handleChange = (value) => {
        this.setState({value});
        this.props.onChange(value);
    };

    render() {
        const {countries, loading, value} = this.state;
        const {t} = this.props;

        return (
            <Select
                allowClear={true}
                loading={loading}
                value={value}
                onChange={this.handleChange}
                className={'width-100pc'}
                placeholder={t("placeholder.select_country")}
            >
                {countries.map(country => (
                    <Select.Option key={country.id} value={country.id}>{country.name}</Select.Option>
                ))}
            </Select>
        );
    }
}

SelectCountryAutocomplete.defaultProps = {
    onChange: (values) => {}
};

export default SelectCountryAutocomplete;

