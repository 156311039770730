import React from 'react';
import lodash from 'lodash';
import {Modal, Input, Form, notification, Empty, Select} from 'antd';
import VendorService from "../../../services/VendorService";
import {LocalStore} from "../../../utils/LocalStore";

class PopupCreateGroupUser extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            users: [],
            vendor: LocalStore.getInstance().read('vendor')
        }
    }

    componentDidMount() {
        this.fetchUsers();
    }

    onCreate = () => {
        const {t, form} = this.props;
        const {createGroup} = this.props;
        form.validateFields((error, values) => {
            if(!error) {
                this.setState({loading: true});
                createGroup(values, (error, response) => {
                    this.setState({loading: false});
                    if(!error) {
                        notification.success({
                            message: t('message.success')
                        });

                        this.props.history.push(`/groups/${response.id}`);
                    } else {
                        let message = error.message;
                        if(error.code === 'NAME_EXISTED') {
                            message = t('group_user.name_existed');
                        }
                        notification.error({
                            message: message
                        })
                    }
                })
            }
        })
    };

    onCancel = () => {
        this.props.onCancel();
    };

    fetchUsers = (filter = {}, cb) => {
        const {vendor} = this.state;
        VendorService.getUserInVendor(vendor.id, filter,(error, response) => {
            if(!error) {
                this.setState({
                    users:response.list,
                })
            }
            if(typeof cb === 'function') cb(error, response);
        });
    };

    onSearchUser = (value) => {
        this.fetchUsers({username: value});
    };

    render() {
        const {loading, users} = this.state;
        const {t} = this.props;
        const {getFieldDecorator} = this.props.form;
        return (
            <div className="popup-create-group-user _popup_create_group_user">
                <Modal
                    visible={true}
                    title={t("group_user.create")}
                    onCancel={this.onCancel}
                    onOk={this.onCreate}
                    okButtonProps={{
                        className: "_btn_save_group",
                        loading: loading
                    }}
                    cancelButtonProps={{
                        className: "_btn_cancel_create_group",
                    }}
                    cancelText={t("button.cancel")}
                    okText={t("button.save")}
                >
                    <Form layout="vertical">
                        <Form.Item
                            label={t('group_user.name')}
                        >
                            {getFieldDecorator('name', {
                                rules: [
                                    {required: true, message: t('message.required')},
                                    { max: 160, message: t("error.length_160")}
                                ]
                            })(
                                <Input
                                    autoFocus={true}
                                    placelhoder={t('group_user.name')}
                                    onPressEnter={this.onCreate}
                                />
                            )}
                        </Form.Item>
                        <Form.Item
                            label={t('group_user.leader')}
                        >
                            {getFieldDecorator('leader_id', {
                                rules: [
                                    {required: true, message: t('message.required')}
                                ]
                            })(
                                <Select
                                    placeholder={t('group_user.leader')}
                                    style={{width: '100%'}}
                                    filterOption={false}
                                    defaultActiveFirstOption={false}
                                    notFoundContent={<Empty description={t("table.empty")} />}
                                    onSearch={this.onSearchUser}
                                    showSearch={true}
                                >
                                    {users.map(item => (
                                        <Select.Option key={item.id} value={item.id}>{item.username}</Select.Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        )
    }
}

PopupCreateGroupUser = Form.create()(PopupCreateGroupUser);

export {PopupCreateGroupUser};