import React, {Component} from 'react';
import {translate} from "react-i18next";
import * as moment from 'moment';
import {Button, Col, Row} from 'antd';
import lodash from 'lodash';
import StandardLayout from "../../components/StandardLayout";
import {LocalStore} from "../../utils/LocalStore";
import UserService from "../../services/UserService";
import {DashBoardBox} from "./DashBoardBox";
import {CONTACT_SCOPES, ORDER_SCOPES, WAREHOUSE_SCOPES} from "../../utils/api/Scopes";
import SelectVendor from "../../components/SelectVendor";
import withLocationFilter from "../../utils/withLocationFilter";
import VendorService from "../../services/VendorService";
import LocationService from "../../services/LocationService";
import TotalSaleService from "../../services/TotalSaleService";
import SelectCountryAutocomplete from "../../components/SelectCountryAutocomplete";

const totalSaleCountriesIgnore = [
	'Myanmar', 'Thailand (old data)'
]

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            countAllOrder: 0,
            countAllOrderYesterday: 0,
            countNewOrder: 0,
            countNewOrderYesterday: 0,
            countDeliveredOrder: 0,
            countDeliveredOrderYesterday: 0,
            countScheduledOrder: 0,
            countScheduledOrderYesterday: 0,
            countNewContact: 0,
            countNewContactYesterday: 0,
            countPickedUpOrder: 0,
            countPickedUpOrderYesterday: 0,
            countDraftOrder: 0,
            countDraftOrderYesterday: 0,
            countCancelledOrder: 0,
            countCancelledOrderYesterday: 0,
            countCalled: 0,
            countCalledYesterday: 0,
            stockIssueYesterday: [],
            stockIssueDoneStatusYesterday: [],
            stockIssueToday: [],
            stockIssueDoneStatusToday: [],
            stockIssueNewAndProStatus: [],
			totalSaleYesterday: 0,
			totalSaleToday: 0,
            vendor: LocalStore.getInstance().read("vendor"),
            filterForm: {
                ...this.prepareQueryCriteria({...this.props.getFilterFromLocation()})
            },
            vendors: [],
			countries: [],
			totalSaleByCountries: {}
        };
    };

    componentDidMount() {
        this.fetchVendors();
        this.fetchCountries()
        this.loadData({
            ...this.prepareQueryCriteria({...this.props.getFilterFromLocation()})
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            JSON.stringify(this.props.location.search) !== JSON.stringify(prevProps.location.search)
            || JSON.stringify(this.props.match.params) !== JSON.stringify(prevProps.match.params)
        ) {
            this.loadData({
                ...this.prepareQueryCriteria({...this.props.getFilterFromLocation()})
            });
        }

        if (JSON.stringify(prevState.countries) !== JSON.stringify(this.state.countries)) {
        	const {countries} = this.state
			const totalSaleByCountries = {}
			const formatDateBegin = "YYYY-MM-DD 00:00:00";
			const formatDateEnd = "YYYY-MM-DD 23:59:59";

			let beginToday = moment().startOf('day')
			let endToday = moment().endOf('day')
			let beginYesterday = moment().add(-1, "day").startOf('day')

			beginToday = moment(beginToday.format(formatDateBegin)).toISOString()
			endToday = moment(endToday.format(formatDateEnd)).toISOString()
			beginYesterday = moment(beginYesterday.format(formatDateBegin)).toISOString()
			let endYesterday = moment(moment(beginYesterday).format(formatDateEnd)).toISOString()
			for (let country of countries) {
				if (totalSaleCountriesIgnore.indexOf(country.name) < 0) {
					if (!lodash.has(totalSaleByCountries, country.id)) {
						totalSaleByCountries[country.id] = {
							today: 0,
							yesterday: 0
						}
					}
					let params = {
						dateFrom: beginToday,
						dateTo: endToday
					}
					TotalSaleService.getTotalSaleByCountryId(country.id, params, (error, response) => {
						if (!error) {
							totalSaleByCountries[country.id].today = response.totalAmount
							this.setState({totalSaleByCountries})
						}
					})

					params = {
						dateFrom: beginYesterday,
						dateTo: endYesterday
					}
					TotalSaleService.getTotalSaleByCountryId(country.id, params, (error, response) => {
						if (!error) {
							totalSaleByCountries[country.id].yesterday = response.totalAmount
							this.setState({totalSaleByCountries})
						}
					})
				}
			}
		}
    }

    prepareQueryCriteria = (query = {}) => {
        const newQuery = {...query};
        if (lodash.get(query, 'vendorIds')) {
            newQuery.vendorIds = query.vendorIds.split(",");
        }
        return newQuery;
    };

    loadData = (filter = {}) => {
        const formatDateBegin = "YYYY-MM-DD 00:00:00";
        const formatDateEnd = "YYYY-MM-DD 23:59:59";

        let beginToday = moment().startOf('day')
        let endToday = moment().endOf('day')
        let beginYesterday = moment().add(-1, "day").startOf('day')

        beginToday = moment(beginToday.format(formatDateBegin)).toISOString()
        endToday = moment(endToday.format(formatDateEnd)).toISOString()
        beginYesterday = moment(beginYesterday.format(formatDateBegin)).toISOString()
        let endYesterday = moment(moment(beginYesterday).format(formatDateEnd)).toISOString()

        filter = {
            ...filter,
            vendorIds: filter.vendorIds && filter.vendorIds.length && Array.isArray(filter.vendorIds) ? filter.vendorIds.join(",") : "",
            beginToday: beginToday,
            endToday: endToday,
            beginYesterday: beginYesterday,
            endYesterday: endYesterday,
            time_zone: LocalStore.getInstance().read("timezone"),
        };
        UserService.getDashBoardTLSInfo(filter,(error, response) => {
            if (error === null) {
                this.setState({
                    countContactAssign: response.contact.NEW_ASSIGN.today,
                    countContactAssignYesterday: response.contact.NEW_ASSIGN.yesterday,
                    countContactCalled: response.contact.CALLED.today,
                    countContactCalledYesterday: response.contact.CALLED.yesterday,
                    countContactReservation: response.contact.RESERVATION.today,
                    countContactReservationYesterday: response.contact.RESERVATION.yesterday,
                    countContactAppointment: response.contact.APPOINTMENT.today,
                    countContactAppointmentYesterday: response.contact.APPOINTMENT.yesterday
                });
            }
        });

        UserService.getDashBoardInfo(filter,(error, response) => {
            if (error === null) {
                this.setState({
                    countAllOrder: response.order.ALL.today,
                    countAllOrderYesterday: response.order.ALL.yesterday,
                    countNewOrder: response.order.NEW.today,
                    countNewOrderYesterday: response.order.NEW.yesterday,
                    countDeliveredOrder: response.order.DELIVERED.today,
                    countDeliveredOrderYesterday: response.order.DELIVERED.yesterday,
                    countScheduledOrder: response.order.SCHEDULED_DELIVERY.today,
                    countScheduledOrderYesterday: response.order.SCHEDULED_DELIVERY.yesterday,
                    countPickedUpOrder: response.order.PICKED_UP.today,
                    countPickedUpOrderYesterday: response.order.PICKED_UP.yesterday,
                    countDraftOrder: response.order.DRAFT.today,
                    countDraftOrderYesterday: response.order.DRAFT.yesterday,
                    countCancelledOrder: response.order.CANCELLED.today,
                    countCancelledOrderYesterday: response.order.CANCELLED.yesterday,
                    countNewContact: response.contact.NEW.today,
                    countNewContactYesterday: response.contact.NEW.yesterday,
                    countCalled: response.contact.CALLED.today,
                    countCalledYesterday: response.contact.CALLED.yesterday,
                    countNewStockIssueToday: response.issue.NEW.today,
                    countNewStockIssueYesterday: response.issue.NEW.yesterday,
                    countProcessingStockIssueToday: response.issue.PROCESSING.today,
                    countProcessingStockIssueYesterday: response.issue.PROCESSING.yesterday,
                    countDoneStockIssueToday: response.issue.DONE.today,
                    countDoneStockIssueYesterday: response.issue.DONE.yesterday
                });
            }
        });
    };

    fetchVendors = (filter = {}) => {
        this.setState({loading: true});
        filter = {
            ...filter,
            me_in: true,
            page_size: 1000
        };

        VendorService.getVendorList(filter, (error, response) => {
            this.setState({loading: false});
            if (!error) {
                this.setState({
                    vendors: response.list
                });
            }
        });
    };

	fetchCountries = (filter = {}) => {
		this.setState({loading: true});
		LocationService.getCountriesAutocomplete(filter, (error, response) => {
			this.setState({loading: false});
			if (!error) {
				this.setState({
					countries: response
				});
			}
		});
	};

    handleChangeSelect = (field, value) => {
        this.setState({
            filterForm: {
                ...this.state.filterForm,
                [field]: value
            }
        }, () => {
            if (field === 'countryId') {
                const {filterForm} = this.state;
                lodash.unset(filterForm, 'vendorIds');
                this.setState({filterForm});
                this.fetchVendors({
                    country_id: value
                })
            }
        });
    };

    handleFilter = () => {
        const {filterForm} = this.state;
        this.props.pushCleanFilterToHistory({
            ...filterForm,
            vendorIds: filterForm.vendorIds && filterForm.vendorIds.length ? filterForm.vendorIds.join(",") : ""
        })
    };

    renderDashboard() {
        const {t} = this.props;
        const {filterForm, vendors, countries, totalSaleByCountries} = this.state;
        return (
            <div className="dashboard-component-inner">
                <Row gutter={10} className={'mg-bt-20'}>
                    <Col xs={24} md={8}>
                        <SelectCountryAutocomplete
                            t={t}
                            value={filterForm.countryId ? parseInt(filterForm.countryId) : undefined}
                            onChange={this.handleChangeSelect.bind(this, 'countryId')}
                        />
                    </Col>
                    <Col xs={24} md={8}>
                        <SelectVendor
                            t={t}
                            value={filterForm.vendorIds}
                            items={vendors}
                            onChange={this.handleChangeSelect.bind(this, 'vendorIds')}
                            selectProps={{
                                mode: 'multiple'
                            }}
                        />
                    </Col>
                    <Col xs={24} md={4}>
                        <Button onClick={this.handleFilter} type={'primary'}>{t('button.filter')}</Button>
                    </Col>
                </Row>

				<React.Fragment>
					<h2 className={"page-header"}>Total Sale</h2>
					<Row gutter={20}>
						{countries.filter(item => totalSaleCountriesIgnore.indexOf(item.name) < 0).map(item => (
							<Col xs={24} md={8} lg={8} key={`country-key-${item.id}`}>
								<DashBoardBox
									{...this.props}
									url={"/"}
									number={lodash.get(totalSaleByCountries, [item.id, 'today'])}
									numberFooter={lodash.get(totalSaleByCountries, [item.id, 'yesterday'])}
									label={`${item.name} (${item.currencyUnit})`}
									icon={"file"}
									iconBgColor={"bg-color-blue"}
									className={"box-total-sale-by-country bg-green _box-new-order"}
								/>
							</Col>
						))}
					</Row>
				</React.Fragment>

                {
                    UserService.isAllowed(ORDER_SCOPES.ORDER_VIEW) &&
                    <React.Fragment>
                        <h2 className={"page-header"}>{t("dashboard.order")}</h2>
                        <Row gutter={20}>
                            <Col xs={24} md={8}>
                                <DashBoardBox
                                    {...this.props}
                                    url={"/"}
                                    number={this.state.countAllOrder}
                                    numberFooter={this.state.countAllOrderYesterday}
                                    label={t("dashboard.order")}
                                    icon={"file"}
                                    iconBgColor={"bg-color-blue"}
                                    className={"box-new-order bg-green _box-new-order"}
                                />
                            </Col>
                            <Col xs={24} md={8}>
                                <DashBoardBox
                                    {...this.props}
                                    url={"/"}
                                    number={this.state.countNewOrder}
                                    numberFooter={this.state.countNewOrderYesterday}
                                    label={t("dashboard.new_order")}
                                    icon={"file"}
                                    iconBgColor={"bg-color-blue"}
                                    className={"box-new-order bg-green _box-new-order"}
                                />
                            </Col>
                            <Col xs={24} md={8}>
                                <DashBoardBox
                                    {...this.props}
                                    url={"/"}
                                    number={this.state.countPickedUpOrder}
                                    numberFooter={this.state.countPickedUpOrderYesterday}
                                    label={"Picked up"}
                                    icon={"file"}
                                    iconBgColor={"bg-color-blue"}
                                    className={"box-new-order bg-green _box-new-order"}
                                />
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col xs={24} md={6}>
                                <DashBoardBox
                                    {...this.props}
                                    url={"/"}
                                    number={this.state.countDraftOrder}
                                    numberFooter={this.state.countDraftOrderYesterday}
                                    label={"Draft"}
                                    icon={"file"}
                                    iconBgColor={"bg-color-blue"}
                                    className={"box-new-order bg-green _box-new-order"}
                                />
                            </Col>
                            <Col xs={24} md={6}>
                                <DashBoardBox
                                    {...this.props}
                                    url={"/"}
                                    number={this.state.countCancelledOrder}
                                    numberFooter={this.state.countCancelledOrderYesterday}
                                    label={"Cancelled"}
                                    icon={"file"}
                                    iconBgColor={"bg-color-blue"}
                                    className={"box-new-order bg-green _box-new-order"}
                                />
                            </Col>
                            <Col xs={24} md={6}>
                                <DashBoardBox
                                    {...this.props}
                                    url={"/"}
                                    number={this.state.countDeliveredOrder}
                                    numberFooter={this.state.countDeliveredOrderYesterday}
                                    label={t("dashboard.new_delivered_order")}
                                    icon={"file"}
                                    iconBgColor={"bg-color-blue"}
                                    className={"box-new-delivery-order bg-blue _box-new-delivery-order"}
                                />
                            </Col>
                            <Col xs={24} md={6}>
                                <DashBoardBox
                                    {...this.props}
                                    url={"/"}
                                    number={this.state.countScheduledOrder}
                                    numberFooter={this.state.countScheduledOrderYesterday}
                                    label={t("dashboard.new_scheduled_order")}
                                    icon={"file"}
                                    iconBgColor={"bg-color-blue"}
                                    showFooter={false}
                                    className={"box-new-scheduled-order _box-new-scheduled"}
                                />
                            </Col>
                        </Row>
                    </React.Fragment>
                }

                {
                    UserService.isAllowed(CONTACT_SCOPES.CONTACT_VIEW_ALL) &&
                    <React.Fragment>
                        <h2 className={"page-header"}>{t("dashboard.contact")}</h2>
                        <Row gutter={20}>
                            <Col xs={24} md={12}>
                                <DashBoardBox
                                    {...this.props}
                                    url={"/"}
                                    number={this.state.countNewContact}
                                    numberFooter={this.state.countNewContactYesterday}
                                    label={t("dashboard.new_contact")}
                                    icon={"idcard"}
                                    iconBgColor={"bg-color-green"}
                                    className={"box-new-contact bg-aqua _box-new-contact"}
                                />
                            </Col>
                            <Col xs={24} md={12}>
                                <DashBoardBox
                                    {...this.props}
                                    url={"/"}
                                    number={this.state.countCalled}
                                    numberFooter={this.state.countCalledYesterday}
                                    label={t("dashboard.new_call")}
                                    icon={"phone"}
                                    iconBgColor={"bg-color-green"}
                                    className={"box-new-call bg-yellow _box-new-call"}
                                />
                            </Col>
                        </Row>
                    </React.Fragment>
                }

                {UserService.isAllowed(CONTACT_SCOPES.CONTACT_VIEW) && (
                    <React.Fragment>
                        <h2 className={"page-header"}>{t("dashboard.my_contact")}</h2>
                        <Row gutter={20}>
                            <Col xs={24} md={6}>
                                <DashBoardBox
                                    {...this.props}
                                    url={"/contacts"}
                                    number={this.state.countContactAssign}
                                    numberFooter={this.state.countContactAssignYesterday}
                                    label={t("dashboard.contact_assign")}
                                    icon={"idcard"}
                                    iconBgColor={"bg-color-violet"}
                                    className={"box-new-contact bg-aqua _box-new-contact"}
                                />
                            </Col>
                            <Col xs={24} md={6}>
                                <DashBoardBox
                                    {...this.props}
                                    url={"/contacts"}
                                    number={this.state.countContactCalled}
                                    numberFooter={this.state.countContactCalledYesterday}
                                    label={t("dashboard.new_call")}
                                    icon={"phone"}
                                    iconBgColor={"bg-color-violet"}
                                    className={"box-new-call bg-yellow _box-new-call"}
                                />
                            </Col>
                            <Col xs={24} md={6}>
                                <DashBoardBox
                                    {...this.props}
                                    url={`/contacts?contact_status=19&delivery_date.between=${moment().format("YYYY-MM-DD")}&delivery_date.between=${moment().add(3,'day').endOf('day').format("YYYY-MM-DD HH:mm:ss")}`}
                                    number={this.state.countContactReservation}
                                    label={t("dashboard.contact_reservation")}
                                    icon={"schedule"}
                                    iconBgColor={"bg-color-violet"}
                                    showFooter={false}
                                    className={"box-new-contact bg-aqua _box-new-contact"}
                                />
                            </Col>
                            <Col xs={24} md={6}>
                                <DashBoardBox
                                    {...this.props}
                                    url={`/contacts?appointment_at.between=${moment().format("YYYY-MM-DD")}&appointment_at.between=${moment().endOf('day').format("YYYY-MM-DD HH:mm:ss")}`}
                                    number={this.state.countContactAppointment}
                                    numberFooter={this.state.countContactAppointmentYesterday}
                                    label={t("dashboard.contact_appointment")}
                                    icon={"schedule"}
                                    iconBgColor={"bg-color-violet"}
                                    className={"box-new-call bg-yellow _box-new-call"}
                                />
                            </Col>
                        </Row>
                    </React.Fragment>
                )}

                {
                    UserService.isAllowed(WAREHOUSE_SCOPES.WAREHOUSE_ISSUES_VIEW) &&
                    <React.Fragment>
                        <h2 className={"page-header"}>{t("dashboard.warehouse")}</h2>
                        <Row gutter={20}>
                            <Col xs={24} md={8}>
                                <DashBoardBox
                                    {...this.props}
                                    url={"/"}
                                    number={this.state.countNewStockIssueToday}
                                    numberFooter={this.state.countNewStockIssueYesterday}
                                    label={t("dashboard.new_stock_issue")}
                                    icon={"shop"}
                                    iconBgColor={"bg-color-pink"}
                                    className={"box-new-order bg-green _box-new-order"}
                                />
                            </Col>

                            <Col xs={24} md={8}>
                                <DashBoardBox
                                    {...this.props}
                                    url={"/"}
                                    number={this.state.countProcessingStockIssueToday}
                                    numberFooter={this.state.countProcessingStockIssueYesterday}
                                    label={t("dashboard.stock_issue_need_processed")}
                                    icon={"shop"}
                                    iconBgColor={"bg-color-pink"}
                                    showFooter={false}
                                    className={"box-new-delivery-order bg-blue _box-new-delivery-order"}
                                />
                            </Col>
                            <Col xs={24} md={8}>
                                <DashBoardBox
                                    {...this.props}
                                    url={"/"}
                                    number={this.state.countDoneStockIssueToday}
                                    numberFooter={this.state.countDoneStockIssueYesterday}
                                    label={t("dashboard.stock_issue_processed")}
                                    icon={"shop"}
                                    iconBgColor={"bg-color-pink"}
                                    className={"box-new-scheduled-order _box-new-scheduled"}
                                />
                            </Col>
                        </Row>
                    </React.Fragment>
                }
            </div>
        );
    }

    render() {
        return (
            <StandardLayout {...this.props} showSelectVendor={false}>
                {this.renderDashboard()}
            </StandardLayout>
        );
    }
}

export default (translate())(withLocationFilter(Home))
