import React from 'react';
import {Form, Input} from 'antd';

class QuickEditDescription extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    }

    onKeyDown = (e) => {
        let keyCode = e.keyCode || e.which;
        if(keyCode === 13 && !e.shiftKey) {
            this.onSubmit(e);
        }
    };

    onSubmit = (e) => {
        e.preventDefault();
        const {form, warehouse} = this.props;
        form.validateFields((error, values) => {
            if(!error) {
                let data = Object.assign(warehouse, values);
                if(data.name) data.name = data.name.toString().trim();
                if(data.address) data.address = data.address.toString().trim();
                if(data.description) {
                    data.description = data.description.toString().trim();
                    data.description = data.description.replace(/\n$/,'');
                }
                this.setState({loading: true});
                this.props.updateWarehouse(data, (error, response) => {
                    this.setState({loading: false});
                    this.props.onUpdate(error, response);
                });
            }
        });
    };

    render() {
        const {loading} = this.state;
        const {t, warehouse} = this.props;
        const {getFieldDecorator} = this.props.form;
        return (
            <Form onSubmit={this.onSubmit}>
                <Form.Item>
                    {getFieldDecorator('description', {
                        rules: [
                            {max: 255, message: t('error.length_255')}
                        ],
                        initialValue: warehouse.description
                    })(
                        <Input.TextArea
                            disabled={loading}
                            onKeyDown={this.onKeyDown}
                            style={{width: '100%'}} />
                    )}
                </Form.Item>
            </Form>
        )
    }
}

QuickEditDescription = Form.create()(QuickEditDescription);
export {QuickEditDescription};