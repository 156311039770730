import React from 'react';
import {Breadcrumb as AntBreadCrumb} from 'antd';
import {Link} from "react-router-dom";
import {BreadCrumb} from "../../../components/BreadCrumb";

class Bcrumb extends React.Component {

    render() {
        const {t, warehouse} = this.props;
        const leftContent = (
            <AntBreadCrumb>
                <AntBreadCrumb.Item>
                    <Link to={"/"}>{t('page.home')}</Link>
                </AntBreadCrumb.Item>
                <AntBreadCrumb.Item>
                    <Link to={"/warehouse"}>{t('warehouse.title')}</Link>
                </AntBreadCrumb.Item>
                <AntBreadCrumb.Item><span className="breadcrumb-wrap-text">{warehouse.name}</span></AntBreadCrumb.Item>
            </AntBreadCrumb>
        );


        return (
            <BreadCrumb
                {...this.props}
                leftContent={leftContent}
            />
        );
    }
}

export {Bcrumb};