import React from 'react';
import {Row, Col, Input, Select, DatePicker, Empty, Spin, Icon, Button} from 'antd';
import * as moment from "moment/moment";
import {getParamsAsObject, getUrlQueryString} from "../../../../../utils/helper";
import * as _ from "lodash";

const {RangePicker} = DatePicker;
const {Option} = Select;

class OrderFilter extends React.Component {

    constructor(props) {
        super(props);
        this.mounted = false;
        this.state = {
            code: "",
            customerName: "",
            customerPhone: "",
            status: [],
            campaignId: [],
            createdAt: [],
            createdBy: []
        };
        this.onSearchSelect = _.debounce(this.onSearchSelect, 300);
    }

    componentWillUnmount() {
        this.mounted = false;
        window.removeEventListener("popstate", this.onPopstate.bind(this), false);
    }

    componentDidMount = () => {
        this.mounted = true;
        window.addEventListener("popstate", this.onPopstate.bind(this), false);
        this.setStateFromQueryString();
    };

    onPopstate = () => {
        if(this.mounted) {
            this.setStateFromQueryString();
        }
    };

    setStateFromQueryString = () => {
        let queryObject = getParamsAsObject(getUrlQueryString());
        this.resetStateFilter(() => {
            _.forEach(queryObject, (v, k) => {
                if (v) {
                    if(k === 'order_statuses') {
                        this.setState({status: v.toString().split(',')});
                    }
                    else if(k === 'created_by') {
                        this.props.fetchUsers({ids: v}, (error, response) => {
                            let createdBy = [];
                            let users = response.list;
                            for(let u of users) {
                                createdBy.push(u.id);
                            }
                            this.setState({createdBy: createdBy});
                        });
                    }
                    else if(k === 'campaign_ids') {
                        this.props.fetchCampaign({ids: v.toString().split(',')}, (error, response) => {
                            let ids = [];
                            let items = response.list;
                            for(let u of items) {
                                ids.push(u.id);
                            }
                            this.setState({campaignId: ids});
                        });
                    }
                    else if (k === 'created_at_from') {
                        let createdAt = this.state.createdAt;
                        createdAt[0] = moment(v);
                        this.setState({createdAt});
                    }
                    else if (k === 'created_at_to') {
                        let createdAt = this.state.createdAt;
                        createdAt[1] = moment(v);
                        this.setState({createdAt});
                    } else {
                        this.setState({
                            [_.camelCase(k)]: v
                        })
                    }
                }
            });
        });
    };

    onChangeInput = (key, e) => {
        this.setState({
            [key]: e.target.value
        });
    };

    onChangeSelect = (key, values) => {
        this.setState({
            [key]: values
        });
    };

    onFocusSelect = (key) => {
        this.props.resetData('campaigns');
    };

    onSearchSelect = (keyword) => {
        this.props.fetchCampaign({name: keyword});
    };

    onChangeRangePicker = (key, ranges) => {
        this.setState({
            [key]: ranges
        });
    };

    onFilter = () => {
        let uri = this.props.getUrlInstance(true);
        const {code, campaignId, createdAt, createdBy, status, customerName, customerPhone} = this.state;
        uri.replaceParam('code', code.toString().trim());
        uri.replaceParam('customerName', customerName.toString().trim());
        uri.replaceParam('customerPhone', customerPhone.toString().trim());
        uri.replaceParam('orderStatuses', status.join(','));
        uri.replaceParam('campaignIds', campaignId.join(','));
        uri.replaceParam('createdBy', createdBy.join(','));
        if(Array.isArray(createdAt)) {
            if(this.state.createdAt[0]) {
                uri.replaceParam('createdAtFrom', createdAt[0].format("YYYY-MM-DD 00:00:00"));
            }
            if(this.state.createdAt[1]) {
                uri.replaceParam('createdAtTo', createdAt[1].format("YYYY-MM-DD 23:59:59"));
            }
        }
        uri.replaceParam('page', 1);
        uri.replaceParam('filter', true);

        this.props.pushQueryString(uri.getQueryString());
    };

    clearFilter = () => {
        this.resetStateFilter(() => {
            this.props.pushQueryString("");
        });
    };

    resetStateFilter = (cb = null) => {
        this.setState({
            code: "",
            customerName: "",
            customerPhone: "",
            status: [],
            campaignId: [],
            createdAt: [],
            createdBy: []
        }, cb)
    };

    render() {
        const {t, campaigns, loadingCampaign, loadingCreatedBy, userCreatedBy, orderStatuses} = this.props;
        const {code, customerPhone, status, customerName, campaignId, createdAt, createdBy} = this.state;

        return (
            <div className="order-filter filter filter-inside-box">
                <Row gutter={20} className="form-filter">
                    <Col xl={6} lg={6} className="form-filter-control">
                        <label>{t('order.code')}</label>
                        <Input.Search
                            value={code}
                            onChange={this.onChangeInput.bind(this, 'code')}
                            placeholder={t('order.code')}
                            className={"_filter_code"}
                            onPressEnter={this.onFilter}
                        />
                    </Col>
                    <Col xl={6} lg={6} className="form-filter-control">
                        <label>{t("contact.fullname")}</label>
                        <Input.Search
                            className={"_filter_customer_name"}
                            placeholder={t("contact.fullname")}
                            value={customerName}
                            onChange={this.onChangeInput.bind(this, 'customerName')}
                            onPressEnter={this.onFilter}
                        />
                    </Col>
                    <Col xl={6} lg={6} className="form-filter-control">
                        <label>{t("order_list.customerPhone")}</label>
                        <Input.Search
                            className={"_filter_phone"}
                            placeholder={t("contact.phone")}
                            value={customerPhone}
                            onChange={this.onChangeInput.bind(this, 'customerPhone')}
                            onPressEnter={this.onFilter}
                        />
                    </Col>
                    <Col xl={6} lg={6} className="form-filter-control">
                        <label>{t("order_list.status")}</label>
                        <Select
                            className={"_filter_order_status"}
                            placeholder={t("contact.select_status")}
                            notFoundContent={<Empty description={t("table.empty")} />}
                            mode="multiple"
                            value={status}
                            style={{width: "100%"}}
                            onChange={this.onChangeSelect.bind(this, 'status')}
                        >
                            {orderStatuses.map((item) => (
                                <Option key={item.key} value={item.key} className={`_order_status_item _order_status_item_${item.key}`}>{item.label}</Option>
                            ))}
                        </Select>
                    </Col>
                    <Col xl={6} lg={6} className="form-filter-control">
                        <label>{t("contact.campaign")}</label>
                        <Select
                            filterOption={false}
                            className={"_filter_campaign"}
                            placeholder={t("contact.select_campaign")}
                            notFoundContent={(loadingCampaign ? <Spin size="small" /> : <Empty description={t("table.empty")} />)}
                            mode="multiple"
                            style={{width: "100%"}}
                            value={loadingCampaign ? [] : campaignId}
                            loading={loadingCampaign}
                            onChange={this.onChangeSelect.bind(this, 'campaignId')}
                            onSearch={this.onSearchSelect.bind(this, 'campaignId')}
                            onFocus={this.onFocusSelect.bind(this, 'campaignId')}
                        >
                            {campaigns.map((item) => (
                                <Option key={item.id} value={item.id} className={`_campaign_item _campaign_item_${item.id}`}>{item.name}</Option>
                            ))}
                        </Select>
                    </Col>
                    <Col xl={6} lg={6} className="form-filter-control">
                        <label>{t("contact.created_at")}</label>
                        <RangePicker
                            placeholder={[t("contact.from"), t("contact.to")]}
                            className={"_filter_created_at"}
                            value={createdAt}
                            onChange={this.onChangeRangePicker.bind(this, 'createdAt')}
                            style={{width: '100%'}}
                        />
                    </Col>
                    <Col xl={6} lg={6} className="form-filter-control">
                        <label>{t("order_detail.created_by")}</label>
                        <Select
                            filterOption={false}
                            className={"_filter_created_by"}
                            placeholder={t("order_list.created_by_placeholder")}
                            notFoundContent={(loadingCreatedBy ? <Spin size="small" /> : <Empty description={t("table.empty")} />)}
                            mode="multiple"
                            style={{width: "100%"}}
                            value={loadingCreatedBy ? [] : createdBy}
                            loading={loadingCreatedBy}
                            onChange={this.onChangeSelect.bind(this, 'createdBy')}
                            onSearch={this.onSearchSelect.bind(this, 'createdBy')}
                            onFocus={this.onFocusSelect.bind(this, 'createdBy')}
                        >
                            {userCreatedBy.map((item, index) => (
                                <Option key={index} value={item.id} className={`_created_by_item _created_by_item_${item.id}`}>{item.username}</Option>
                            ))}
                        </Select>
                    </Col>
                    <Col xl={6} lg={6} className="form-filter-control filter-button">
                        <label>&nbsp;</label>
                        <div>
                            <span className="mg-r-10 pointer _filter_button_clear" onClick={this.clearFilter}>
                                <Icon type="sync" /> {t("contact.clear")}
                            </span>
                            <Button type="primary" className="_filter_button_filter" onClick={this.onFilter}>
                                {t("button.filter")}
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export {OrderFilter}