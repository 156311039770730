import React from 'react';
import {Row, Col, Icon, Dropdown, Menu} from 'antd';
import {formatDate} from "../../../../../utils/helper";

class Info extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    getCarrier = () => {
        const {t} = this.props;
        const menuCarrier = (
            <Menu>
                <Menu.Item>
                    <span onClick={this.props.changeIssueCourier.bind(this,1)}>LBC</span>
                </Menu.Item>
                <Menu.Item>
                    <span onClick={this.props.changeIssueCourier.bind(this,2)}>Shippo</span>
                </Menu.Item>
            </Menu>
        );

        if(!this.props.issue.courierService && this.props.issue.status !== 'DONE'){
            return <Dropdown overlay={menuCarrier} trigger={['click']}>
                    <span className="text pointer">{t('stock_issue_detail.select')}
                        <Icon type="down" size="small" style={{fontSize: 10}} />
                    </span>
            </Dropdown>
        }else if(this.props.issue.courierService && this.props.issue.status !== 'DONE'){
            return <Dropdown overlay={menuCarrier} trigger={['click']}>
                    <span className="text pointer">{this.props.issue.courierService.name}
                        <Icon type="down" size="small" style={{fontSize: 10}} />
                    </span>
            </Dropdown>
        }else if(this.props.issue.courierService && this.props.issue.status === 'DONE'){
            return <span className="text">{this.props.issue.courierService.name}</span>
        }
    };

    getStatus = () => {
        const {t} = this.props;
        const menuStatus = (
            <Menu>
                <Menu.Item>
                    <span>{t("stock_receipt_status.NEW")}</span>
                </Menu.Item>
                <Menu.Item>
                    <span onClick={this.props.changeIssueStatus.bind(this,"process")}>{t("stock_receipt_status.PROCESSING")}</span>
                </Menu.Item>
                <Menu.Item>
                    <span onClick={this.props.changeIssueStatus.bind(this,"finish")}>{t("stock_receipt_status.DONE")}</span>
                </Menu.Item>
            </Menu>
        );

        if(this.props.issue.orderNumber === 0){
            return <span className="text">{t(`stock_issue_status.${this.props.issue.status}`)}</span>
        }else if(this.props.issue.orderNumber && this.props.issue.status !== 'DONE'){
            if(this.props.issue.courierService && Object.keys(this.props.issue.courierService).length){
                return <Dropdown overlay={menuStatus} trigger={['click']}>
                    <span className="text pointer">{this.props.issue.courierService?t(`stock_issue_status.${this.props.issue.status}`):t('stock_issue_detail.select')}
                        <Icon type="down" size="small" style={{fontSize: 10}} />
                    </span>
                </Dropdown>
            }else{
                return <span className="text">{t(`stock_issue_status.${this.props.issue.status}`)}</span>
            }
        }else if(this.props.issue.orderNumber && this.props.issue.status === 'DONE') {
            return <span className="text">{t(`stock_issue_status.${this.props.issue.status}`)}</span>
        }
    };

    render() {
        const {t, issue} = this.props;
        const formItem = (props) => {
            const {label, control, className} = props;
            return (
                <div className={`form-item ${className}`}>
                    <div className="form-item-label">{label}</div>
                    <div className="form-item-control">{control}</div>
                </div>
            )
        };
        return (
           <div className="warehouse-basic-info">
               <div className="white-box">
                   <h1>{issue.issueNumber}</h1>
                   <Row gutter={20}>
                       <Col span={4}>
                           {formItem({
                               label: t('stock_issue.carrier'),
                               control: this.getCarrier(),
                               className: "_warehouse_carrier",
                           })}
                       </Col>
                       <Col span={4}>
                           {formItem({
                               label: t('stock_issue.status'),
                               control: this.getStatus(),
                               // control: <span className="text">{t(`stock_issue_status.${issue.status}`)}</span>,
                               className: `_warehouse_status status ${issue.status}`
                           })}
                       </Col>
                       <Col span={4}>
                           {formItem({
                               label: t('warehouse.created_at'),
                               control: formatDate(issue.createdAt),
                               className: "_warehouse_status"
                           })}
                       </Col>
                       <Col span={4}>
                           {formItem({
                               label: t('warehouse.createdBy'),
                               control: <span>{issue.createdBy}</span>,
                               className: "_warehouse_status"
                           })}
                       </Col>
                   </Row>
               </div>
           </div>
        );
    }
}

export {Info}