import React from 'react';
import {Table, Pagination, Row, Col, Button} from 'antd';
import {formatDate, objectGet} from "../../../../utils/helper";
import {Link} from "react-router-dom";
import {toastr} from "../../../../utils/toastr";

class List extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loadingCreate: false
        }
    }

    createStockIssue = () => {
        const {t} = this.props;
        this.setState({loadingCreate: true});
        this.props.createStockReceipt((error,response) => {
            this.setState({loadingCreate: false});
            if (!error) {
                toastr.success(t("message.success"));
                this.props.history.push(`/warehouse/receipts/${response.id}`);
            }
            else {
                toastr.error(error.message);
            }
        });
    };

    render() {
        const {t, loading, items, totalItems, onShowSizeChange, onChangePage, currentPage, pageSize, pageCount} = this.props;
        const {loadingCreate} = this.state;
        const renderCode = (text, record) => {
            return <Link to={`/warehouse/receipts/${record.id}`}>{text}</Link>;
        };
        const renderCreatedAt = (text) => {
            return formatDate(text);
        };
        const renderStatus = (text) => {
            return <span className={`status ${text}`}>{t(`stock_receipt.${text.toString().toLowerCase()}`)}</span>;
        };
        let columns = [
            {title: t('stock_receipt.id'), dataIndex: 'receiptNumber', key: 'code', render: renderCode, width:120, className:"_code _item"},
            {title: t('stock_receipt.created_at'), dataIndex: 'createdAt', key: 'createdAt', render: renderCreatedAt, width:120, className:"_created_at _item"},
            {title: t('stock_receipt.created_by'), dataIndex: 'createdBy', key: 'createdBy', width:120, className:"_created_by _item"},
            {title: t('stock_receipt.status'), dataIndex: 'status', key: 'status', width:120, render: renderStatus, className:"_status _item"},
        ];
        return (
            <div className="receipt-list-table">
                <Row className="pd-bt-15">
                    <Col span={5}>
                        {!loading && !!totalItems && (
                            <span>{t("contact.page")} {currentPage}/{pageCount} ({totalItems})</span>
                        )}

                        {!loading && !totalItems && (
                            <span>{t("contact.page")} {1}/{1} ({totalItems})</span>
                        )}
                    </Col>
                    <Col span={19} className="text-right">
                        <Button
                            loading={loadingCreate}
                            onClick={this.createStockIssue}
                            type="primary"
                            className="_btn_add_stock_receipt"
                        >{t('stock_receipt.add_stock_receipt')}</Button>
                    </Col>
                </Row>

                <Table
                    columns={columns}
                    dataSource={items}
                    rowKey={record => record.id}
                    pagination={false}
                    loading={loading}
                    locale={{
                        emptyText: t("table.empty")
                    }}
                />

                {!loading && totalItems > 0 && (
                    <Pagination
                        className="mg-t-15 text-right"
                        showSizeChanger
                        onShowSizeChange={onShowSizeChange}
                        onChange={onChangePage}
                        pageSizeOptions={['25', '50', '75', '100', '150', '200']}
                        defaultCurrent={1}
                        current={currentPage}
                        pageSize={pageSize}
                        total={totalItems}
                    />
                )}
            </div>
        )
    }
}

export {List}