import React from 'react';
import {Breadcrumb as AntBreadCrumb, Button} from 'antd';
import {BreadCrumb} from "../../../components/BreadCrumb";
import {Link} from "react-router-dom";
import UserService from "../../../services/UserService";
import {VENDORS_SCOPES} from "../../../utils/api/Scopes";

class Breadcrumb extends React.Component {

    render() {
        const {t, vendor} = this.props;
        const leftContent = (
            <AntBreadCrumb>
                <AntBreadCrumb.Item>
                    <Link to={"/"}>{t("sidebar.dashboard")}</Link>
                </AntBreadCrumb.Item>
                <AntBreadCrumb.Item>
                    <Link to={`/vendors`}>{t("vendor.title")}</Link>
                </AntBreadCrumb.Item>
                <AntBreadCrumb.Item>{vendor.name}</AntBreadCrumb.Item>
            </AntBreadCrumb>
        );

        return (
            <BreadCrumb
                {...this.props}
                leftContent={leftContent}
            />
        );
    }
}

export {Breadcrumb};