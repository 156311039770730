import React from 'react';
import {Pagination, Table, Row, Col, Menu, Dropdown, Icon} from "antd";
import {formatDate} from "../../utils/helper";
import * as moment from "moment/moment";
import {GROUPS_SCOPES} from "../../utils/api/Scopes";
import UserService from "../../services/UserService";
import {Link} from "react-router-dom";

class List extends React.Component {

    onChange = (pagination, filters, sorter)=>{
        let sortCondition = sorter.order;
        if(sortCondition === "descend"){
            sortCondition = "DESC";
        }else if(sortCondition === "ascend"){
            sortCondition = "ASC";
        }
        let uri = this.props.getUrlInstance();
        uri.replaceParam('order', `["${sorter.field} ${sortCondition}"]`);
        this.props.pushQueryString(uri.getQueryString());
    };

    getConfigTableColumns = () => {
        const {t} = this.props;
        const renderColumn = (key, text, record) => {
            switch (key) {
                case 'name':
                    return <Link to={`/groups/${record.id}`}>{record.name}</Link>;
                case 'createdAt':
                    return formatDate(record[key]);
                case 'action':
                    const menu = (
                        <Menu>
                            {UserService.isAllowed(GROUPS_SCOPES.GROUPS_UPDATE) && (
                                <Menu.Item key="1">
                                    <Link to={`/groups/${record.id}`}><Icon type="edit" /> {t('button.edit')}</Link>
                                </Menu.Item>
                            )}
                        </Menu>
                    );
                    return (
                        <Dropdown overlay={menu} trigger={['contextMenu', 'click']}>
                            <span className="pointer"><Icon type="dash" /></span>
                        </Dropdown>
                    )
            }
        };


        let columns = [
            {title: t("group_user.group"),dataIndex: 'name', key: 'name',render: renderColumn.bind(this, 'name'),sorter: true,sortDirections: ['descend','ascend','descend'],width:200,className:"_name _item"},
            {title: t("group_user.members"),dataIndex: 'countMember', key: 'countMember',width:250,className:"_members _item"},
            {title: t("group_user.created_by"),dataIndex: 'createdBy', key: 'createdBy',width:120,className:"_created_by _item"},
            {title: t("group_user.created_at"),dataIndex: 'createdAt', key: 'createdAt', render: renderColumn.bind(this, 'createdAt'),sorter: (a, b) => moment(a.createdAt) - moment(b.createdAt), sortDirections: ['ascend','descend','ascend'],width:150,className:"_createdAt _item"},
            {title: t("table.action"),dataIndex: 'action', key: 'action',render: renderColumn.bind(this, 'action'),width:120,className:"_action _item"},
        ];

        return columns;
    };

    render() {
        const {t, loading} = this.props;
        const {dataSource,currentPage,totalItems,pageSize,onShowSizeChange,onChangePage,pageCount} = this.props;
        return (
            <div className="list-container">
                <Row className="pd-bt-15">
                    <Col span={5}>
                        {!loading && !!totalItems && (
                            <span>{t("contact.page")} {currentPage}/{pageCount} ({totalItems})</span>
                        )}

                        {!loading && !totalItems && (
                            <span>{t("contact.page")} {1}/{1} ({totalItems})</span>
                        )}
                    </Col>
                </Row>

                <Table
                    columns={this.getConfigTableColumns()}
                    dataSource={dataSource}
                    rowKey={record => record.id}
                    pagination={false}
                    loading={loading}
                    onChange={this.onChange}
                    locale={{
                        emptyText: t("table.empty")
                    }}
                />

                {!loading && totalItems > 0 && (
                    <Pagination
                        className="mg-t-15 text-right"
                        showSizeChanger
                        onShowSizeChange={onShowSizeChange}
                        onChange={onChangePage}
                        pageSizeOptions={['25', '50', '75', '100', '150', '200']}
                        defaultCurrent={1}
                        current={currentPage}
                        pageSize={pageSize}
                        total={totalItems}
                    />
                )}
            </div>
        )
    }
}

export {List};