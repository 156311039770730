import React from 'react';
import {Pagination, Table, Row, Col, Icon} from "antd";
import {formatDate} from "../../utils/helper";
import {WAREHOUSE_SCOPES} from "../../utils/api/Scopes";
import UserService from "../../services/UserService";
import {Link} from "react-router-dom";

class List extends React.Component {

    getConfigTableColumns = () => {
        const {t} = this.props;
        const renderColumn = (key, text, record) => {
            switch (key) {
                case 'createdAt':
                    return formatDate(record[key]);
                case 'type':
                    return t(`warehouse.${record.type}`);
                case 'status':
                    return t(`warehouse.${record.status}`);
                case 'action':
                    return UserService.isAllowed(WAREHOUSE_SCOPES.WAREHOUSE_UPDATE) &&
                        (<Link to={`/warehouse/${record.code}`}><Icon type="edit" /> {t('button.edit')}</Link>);
                case 'code':
                    return <Link to={`/warehouse/${record.code}`}>{record.code}</Link>;
                case 'name':
                    return <Link className={"nowrap-css"} to={`/warehouse/${record.code}`}>{record.name}</Link>;
                case 'address':
                    return <span className={"nowrap-css"}>{record.address}</span>;
            }
        };

        return [
            {title: t("warehouse.code"),dataIndex: 'code', key: 'code',render: renderColumn.bind(this, 'code'),width:120,className:"_code _item"},
            {title: t("warehouse.name"),dataIndex: 'name', key: 'name',render: renderColumn.bind(this, 'name'),width:120,className:"_name _item"},
            {title: t("warehouse.type"),dataIndex: 'type', key: 'type',render: renderColumn.bind(this, 'type'), width:120,className:"_type _item"},
            {title: t("warehouse.status"),dataIndex: 'status', key: 'status',render: renderColumn.bind(this, 'status'), width:120,className:"_status _item"},
            {title: t("warehouse.address"),dataIndex: 'address', key: 'address',render: renderColumn.bind(this, 'address'),width:120,className:"_address _item"},
            {title: t("warehouse.createdBy"),dataIndex: 'createdBy', key: 'createdBy',width:120,className:"_createdBy _item"},
            {title: "",dataIndex: 'action', key: 'action',render: renderColumn.bind(this, 'action'),width:120,className:"_action _item"},
        ];
    };

    render() {
        const {t, loading} = this.props;
        const {warehouses,currentPage,totalItems,pageSize,onShowSizeChange,onChangePage,pageCount} = this.props;

        return (
            <div className="list-container">
                <Row className="pd-bt-15">
                    <Col span={5}>
                        {!loading && !!totalItems && (
                            <span>{t("contact.page")} {currentPage}/{pageCount} ({totalItems})</span>
                        )}

                        {!loading && !totalItems && (
                            <span>{t("contact.page")} {1}/{1} ({totalItems})</span>
                        )}
                    </Col>
                </Row>

                <Table
                    columns={this.getConfigTableColumns()}
                    dataSource={warehouses}
                    rowKey={record => record.id}
                    pagination={false}
                    loading={loading}
                    locale={{
                        emptyText: t("table.empty")
                    }}
                />

                {!loading && totalItems > 0 && (
                    <Pagination
                        className="mg-t-15 text-right"
                        showSizeChanger
                        onShowSizeChange={onShowSizeChange}
                        onChange={onChangePage}
                        pageSizeOptions={['25', '50', '75', '100', '150', '200']}
                        defaultCurrent={1}
                        current={currentPage}
                        pageSize={pageSize}
                        total={totalItems}
                    />
                )}
            </div>
        )
    }
}

export {List};