import React, {Component} from 'react';
import {Button, Col, DatePicker, Empty, Icon, Input, InputNumber, Row, Select, Spin} from 'antd';
import * as moment from 'moment';
import * as _ from 'lodash';
import {ButtonToggle} from "../../../components/ButtonToggle";
import {getParamsAsObject, getUrlQueryString} from "../../../utils/helper";
import {LocalStore} from "../../../utils/LocalStore";
import SelectVendor from "../../../components/SelectVendor";
import SelectCountry from "../../../components/SelectCountry";

const Option = Select.Option;
const InputGroup = Input.Group;
const RangePicker = DatePicker.RangePicker;

class Filter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            expanded : false,
            checkAllContactStatus: false,
            keyword: "",
            campaignIds: [],
            productIds: [],
            contactStatuses: [],
            callStatuses: [],
            callStatusIds: [],
            adsCreatedBy: [],
            lastCallFrom: "",
            lastCallTo: "",
            assignTo: [],
            vendorId: undefined,
            countryId: undefined,
            createdAt: [],
            assignedAt: [],
            deliveryDate: [],
            appointmentAt: [],
            lastCall: [],
            order: ["updatedAt desc"],
            callOperations: [
                {key: "contact.search", value: ""},
                {key: "contact.equal", value: "eq"},
                {key: "contact.more", value: "gte"},
                {key: "contact.less", value: "lte"},
                {key: "contact.between", value: "between"}
            ],
            totalCallOperation: "",
            totalCallValueFrom: 0,
            totalCallValueTo: 0,
            totalCallValue: 0,
            gridKeyword: 8,
            gridCampaign: 8,
            gridProduct: 8,
            gridAssignTo: 8,
            gridTotalCall: 8,
            gridButton: 8,
            classButton: ""
        };
        this.onSelectSearch = _.debounce(this.onSelectSearch, 500);
        this.mounted = false;
    }

    onPopstate = () => {
        if(this.mounted) {
            this.setStateFromQueryString();
        }
    };

    componentWillUnmount() {
        this.mounted = false;
        window.removeEventListener("popstate", this.onPopstate.bind(this), false);
    }

    componentDidMount = () => {
        this.mounted = true;
        window.addEventListener("popstate", this.onPopstate.bind(this), false);
        this.setStateFromQueryString();
    };

    setStateFromQueryString = () => {
        let queryObject = getParamsAsObject(getUrlQueryString());
        this.resetStateFilter(() => {
            _.forEach(queryObject, (v, k) => {
                if (v) {
                    let arr = [];
                    if (['contact_statuses'].indexOf(k) >= 0) {
                        arr = v.split(',');
                        _.forEach(arr, (vv, kk) => {
                            arr[kk] = parseInt(vv);
                        });
                        v = arr;
                        this.setState({
                            [_.camelCase(k)]: v
                        });
                    }
                    if (['ads_created_by'].indexOf(k) >= 0) {
                        this.props.fetchUserAdsCreate({username: v}, (error, response) => {
                            if (!error) {
                                let arr = [];
                                for (let item of response.list) {
                                    arr.push({
                                        key: item.username,
                                        label: item.username
                                    });
                                }
                                this.setState({
                                    [_.camelCase(k)]: arr
                                })
                            }
                        });
                    }
                    else if (['assign_to'].indexOf(k) >= 0) {
                        this.props.fetchUserAssignTo({ids: v}, (error, response) => {
                            if (!error) {
                                let arr = [];
                                for (let item of response.list) {
                                    arr.push({
                                        key: item.id,
                                        label: item.username
                                    });
                                }
                                this.setState({
                                    [_.camelCase(k)]: arr
                                })
                            }
                        });
                    }
                    else if (['expanded'].indexOf(k) >= 0) {
                        v = v === 'true';
                        this.setState({
                            [_.camelCase(k)]: v
                        });
                    }
                    else if (k === 'campaign_ids') {
                        this.props.fetchCampaign({ids: v.toString().split(',')}, (error, response) => {
                            if (!error) {
                                let arr = [];
                                for (let item of response.list) {
                                    arr.push({
                                        key: item.id,
                                        label: item.name
                                    });
                                }
                                this.setState({
                                    [_.camelCase(k)]: arr
                                })
                            }
                        });
                    }
                    else if (k === 'product_ids') {
                        this.props.fetchProduct({ids: v}, (error, response) => {
                            if (!error) {
                                let arr = [];
                                for (let item of response.list) {
                                    arr.push({
                                        key: item.id,
                                        label: item.name
                                    });
                                }
                                this.setState({
                                    [_.camelCase(k)]: arr
                                })
                            }
                        });
                    }
                    else if (k === 'call_statuses') {
                        this.props.fetchCallStatus({ids: v}, (error, response) => {
                            if (!error) {
                                let arr = [];
                                for (let item of response) {
                                    arr.push({
                                        key: item.id,
                                        label: item.name
                                    });
                                }
                                this.setState({
                                    [_.camelCase(k)]: arr
                                })
                            }
                        });
                    }
                    else if (k === 'created_at_from') {
                        let createdAt = this.state.createdAt;
                        createdAt[0] = moment(v);
                        this.setState({createdAt});
                    }
                    else if (k === 'created_at_to') {
                        let createdAt = this.state.createdAt;
                        createdAt[1] = moment(v);
                        this.setState({createdAt});
                    }
                    else if (k === 'assigned_at_from') {
                        let assignedAt = this.state.assignedAt;
                        assignedAt[0] = moment(v);
                        this.setState({assignedAt});
                    }
                    else if (k === 'assigned_at_to') {
                        let assignedAt = this.state.assignedAt;
                        assignedAt[1] = moment(v);
                        this.setState({assignedAt});
                    }
                    else if (k === 'delivery_date_from') {
                        let deliveryDate = this.state.deliveryDate;
                        deliveryDate[0] = moment(v);
                        this.setState({deliveryDate});
                    }
                    else if (k === 'delivery_date_to') {
                        let deliveryDate = this.state.deliveryDate;
                        deliveryDate[1] = moment(v);
                        this.setState({deliveryDate});
                    }
                    else if (k === 'appointment_at_from') {
                        let appointmentAt = this.state.appointmentAt;
                        appointmentAt[0] = moment(v);
                        this.setState({appointmentAt: appointmentAt});
                    }
                    else if (k === 'appointment_at_to') {
                        let appointmentAt = this.state.appointmentAt;
                        appointmentAt[1] = moment(v);
                        this.setState({appointmentAt: appointmentAt});
                    }
                    else if (k === 'last_call_from') {
                        let lastCall = this.state.lastCall;
                        lastCall[0] = moment(v);
                        this.setState({lastCall});
                    }
                    else if (k === 'last_call_to') {
                        let lastCall = this.state.lastCall;
                        lastCall[1] = moment(v);
                        this.setState({lastCall});
                    }
                    else {
                        this.setState({
                            [_.camelCase(k)]: v
                        });
                    }
                }
            });
        });
    };

    toggleFormFilter = () => {
        this.setState({
            expanded: !this.state.expanded
        }, () => {
            let uri = this.props.getUrlInstance();
            uri.replaceParam('expanded', this.state.expanded);
            this.props.pushQueryString(uri.getQueryString());
        });
    };

    toggleAllContactStatus = () => {
        let {contactStatuses} = this.props;
        let localContactStatuses = this.state.contactStatuses;
        if(localContactStatuses.length === contactStatuses.length) {
            this.setState({contactStatuses: []});
        } else {
            for(let item of contactStatuses) {
                if(localContactStatuses.indexOf(item.id) < 0) {
                    localContactStatuses.push(item.id);
                }
            }
            this.setState({contactStatuses: localContactStatuses});
        }
    };

    onToggleContactStatus = (item, status, e) => {
        let {contactStatuses} = this.state;
        let index = contactStatuses.indexOf(item.id);
        let exist = index >= 0;
        if(exist) {
            contactStatuses.splice(index, 1);
        } else {
            contactStatuses.push(item.id);
        }
        this.setState({
            contactStatuses
        });
    };

    checkContactStatusActive = (item) => {
        let {contactStatuses} = this.state;
        for(let id of contactStatuses) {
            if(id == item.id) {
                return true;
            }
        }
        return false;
    };

    onChangeInput = (key, e) => {
        this.setState({
            [key]: e.target.value
        });
    };

    onChangeInputNumber = (key, value) => {
        this.setState({
            [key]: value
        })
    };

    onChangeSelectSingle = (key, value, option) => {
        this.setState({
            [key]: value
        }, () => {
            if(this.state.totalCallOperation === 'between') {
                this.setState({
                    gridAssignTo: 6,
                    gridTotalCall: 10,
                    gridButton: 8
                })
            } else {
                this.setState({
                    gridAssignTo: 8,
                    gridTotalCall: 8,
                    gridButton: 8
                })
            }
        })
    };

    onSelect = (key, value, option) => {
        let oldList = this.state[key] || [];
        oldList.push(value);
        this.setState({
            [key]: oldList
        });
    };

    onDeselect = (key, value, option) => {
        let oldList = this.state[key] || [];
        let index = oldList.indexOf(value);
        if(index >= 0) {
            oldList.splice(index, 1);
        }
        this.setState({
            [key]: oldList
        });
    };

    onSelectSearch = (key, value) => {
        if(key === 'campaignIds') {
            this.props.fetchCampaign({
                name: value
            });
        }

        if(key === 'productIds') {
            this.props.fetchProduct({
                name: value
            });
        }
        if(key === 'adsCreatedBy') {
            this.props.fetchUserAdsCreate({
                username: value
            });
        }
        if(key === 'assignTo') {
            this.props.fetchUserAssignTo({
                username: value
            });
        }
        if(key === 'callStatuses') {
            this.props.fetchCallStatus({
                value: value
            });
        }
    };

    onSelectChange = (key, value) => {
        this.setState({
            [key]: value
        });
    };

    onChangeRangePicker = (key, ranges) => {
        this.setState({
            [key]: ranges
        });
    };

    extractIdsFrom = (key) => {
        let items = this.state[key];
        let arr = [];
        for(let item of items) {
            arr.push(item.key);
        }
        return arr;
    };

    onFilter = () => {
        let uri = this.props.getUrlInstance(true);
        uri.replaceParam('expanded', this.state.expanded);
        uri.replaceParam('keyword', this.state.keyword);
        uri.replaceParam('campaignIds', this.extractIdsFrom('campaignIds').join(','));
        uri.replaceParam('productIds', this.extractIdsFrom('productIds').join(','));
        uri.replaceParam('adsCreatedBy', this.extractIdsFrom('adsCreatedBy').join(','));
        uri.replaceParam('callStatuses', this.extractIdsFrom('callStatuses').join(','));
        uri.replaceParam('assignTo', this.extractIdsFrom('assignTo').join(','));
        uri.replaceParam('contactStatuses', this.state.contactStatuses.join(','));
        uri.replaceParam('totalCallOperation', this.state.totalCallOperation);
        uri.replaceParam('totalCallValue', this.state.totalCallValue);
        uri.replaceParam('totalCallValueFrom', this.state.totalCallValueFrom);
        uri.replaceParam('totalCallValueTo', this.state.totalCallValueTo);
        uri.replaceParam('page', 1);
        uri.replaceParam('filter', true);
        uri.replaceParam('timeZone', LocalStore.getInstance().read("timezone"));
        uri.replaceParam('vendorId', this.state.vendorId);
        uri.replaceParam('countryId', this.state.countryId);
        uri.replaceParam('tab', 'lead');

        if(Array.isArray(this.state.createdAt)) {
            if(this.state.createdAt[0]) {
                uri.replaceParam('createdAtFrom', this.state.createdAt[0].format("YYYY-MM-DD 00:00:00"));
            }
            if(this.state.createdAt[1]) {
                uri.replaceParam('createdAtTo', this.state.createdAt[1].format("YYYY-MM-DD 23:59:59"));
            }
        }

        if(Array.isArray(this.state.assignedAt)) {
            if(this.state.assignedAt[0]) {
                uri.replaceParam('assignedAtFrom', this.state.assignedAt[0].format("YYYY-MM-DD 00:00:00"));
            }
            if(this.state.assignedAt[1]) {
                uri.replaceParam('assignedAtTo', this.state.assignedAt[1].format("YYYY-MM-DD 23:59:59"));
            }
        }

        if(Array.isArray(this.state.deliveryDate)) {
            if(this.state.deliveryDate[0]) {
                uri.replaceParam('deliveryDateFrom', this.state.deliveryDate[0].format("YYYY-MM-DD 00:00:00"));
            }
            if(this.state.deliveryDate[1]) {
                uri.replaceParam('deliveryDateTo', this.state.deliveryDate[1].format("YYYY-MM-DD 23:59:59"));
            }
        }
        if(Array.isArray(this.state.lastCall)) {
            if(this.state.lastCall[0]) {
                uri.replaceParam('lastCallFrom', this.state.lastCall[0].format("YYYY-MM-DD 00:00:00"));
            }
            if(this.state.lastCall[1]) {
                uri.replaceParam('lastCallTo', this.state.lastCall[1].format("YYYY-MM-DD 23:59:59"));
            }
        }
        if(Array.isArray(this.state.appointmentAt)) {
            if(this.state.appointmentAt[0]) {
                uri.replaceParam('appointmentAtFrom', this.state.appointmentAt[0].format("YYYY-MM-DD 00:00:00"));
            }
            if(this.state.appointmentAt[1]) {
                uri.replaceParam('appointmentAtTo', this.state.appointmentAt[1].format("YYYY-MM-DD 23:59:59"));
            }
        }

        this.props.pushQueryString(uri.getQueryString());
    };

    resetStateFilter = (cb = null) => {
        this.setState({
            keyword: "",
            campaignIds: [],
            productIds: [],
            createdAt: [],
            adsCreatedBy: [],
            contactStatuses: [],
            callStatuses: [],
            assignTo: [],
            deliveryDate: [],
            appointmentAt: [],
            totalCallOperation: '',
            totalCallValue: 0,
            totalCallValueFrom: 0,
            totalCallValueTo: 0,
            lastCall: [],
            vendorId: undefined,
            countryId: undefined
        }, cb)
    };

    clearFilter = () => {
        this.resetStateFilter(() => {
            this.props.pushQueryString(`?expanded=${this.state.expanded}`)
        });
    };

    onFocus = (key, e) => {
        switch (key) {
            case 'campaignIds':
                this.props.resetDataSource('campaigns');
                break;
            case 'productIds':
                this.props.resetDataSource('products');
                break;
            case 'adsCreatedBy':
                this.props.resetDataSource('userAdsCreate');
                break;
            case 'assignTo':
                this.props.resetDataSource('userAssignTo');
                break;
            case 'callStatuses':
                this.props.resetDataSource('callStatuses');
                break;
        }
    };

    render() {
        const {contactStatuses, campaigns, products, userAssignTo, userAdsCreate, callStatuses, t,
            loadingCampaign, loadingProduct, loadingUserAdsCreate, loadingUserAssignTo,
            loadingCallStatus, loadingContactStatus} = this.props;
        const {expanded, keyword, campaignIds,
            productIds, adsCreatedBy, assignTo, createdAt, deliveryDate, lastCall, appointmentAt, callOperations,
            totalCallOperation, totalCallValue, totalCallValueFrom, totalCallValueTo, gridKeyword, gridCampaign, gridProduct,
            gridAssignTo, gridButton, gridTotalCall, assignedAt, vendorId, countryId} = this.state;

        let defaultCallStatuses = this.state.callStatuses;
        let checkAllContactStatus = contactStatuses.length === this.state.contactStatuses.length;

        return (
            <div className="contact-filter filter">
                <div>
                    <span className="mg-r-20">{t("contact.state")}:</span>
                    <Button
                        onClick={this.toggleAllContactStatus}
                        type={checkAllContactStatus ? 'primary' : 'default'}
                        size="small"
                        className="mg-r-20 _state_all"
                        loading={loadingContactStatus}
                    >{checkAllContactStatus ? t("contact.uncheck") :t("contact.all_state")}
                    </Button>

                    {contactStatuses.map((item, index) => (
                        <ButtonToggle
                            key={index}
                            active={checkAllContactStatus ? checkAllContactStatus : this.checkContactStatusActive(item)}
                            onToggle={this.onToggleContactStatus.bind(this, item)}
                            className={`mg-r-15 _state_item _state_item_${item.key.replace('.', '_')}`}
                        >{item.key}
                        </ButtonToggle>
                    ))}
                </div>
                <div className="divider" />
                <div>
                    <Row gutter={20} className="form-filter">
                        <Col span={gridKeyword} className="form-filter-control">
                            <label>{t("contact.fullname_phone_number")}</label>
                            <Input
                                className={"_keyword"}
                                placeholder={t("contact.fullname_phone_number")}
                                suffix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                value={keyword}
                                onChange={this.onChangeInput.bind(this, 'keyword')}
                                onPressEnter={this.onFilter}
                            />
                        </Col>
                        <Col span={gridCampaign} className="form-filter-control">
                            <label>{t("contact.campaign")}</label>
                            <Select
                                labelInValue
                                filterOption={false}
                                className={"_filter_campaign"}
                                placeholder={t("contact.select_campaign")}
                                notFoundContent={(loadingCampaign ? <Spin size="small" /> : <Empty description={t("table.empty")} />)}
                                mode="multiple"
                                style={{width: "100%"}}
                                value={loadingCampaign ? [] : campaignIds}
                                loading={loadingCampaign}
                                onSelect={this.onSelect.bind(this, 'campaignIds')}
                                onDeselect={this.onDeselect.bind(this, 'campaignIds')}
                                onSearch={this.onSelectSearch.bind(this, 'campaignIds')}
                                onChange={this.onSelectChange.bind(this, 'campaignIds')}
                                onFocus={this.onFocus.bind(this, 'campaignIds')}
                            >
                                {campaigns.map((item, index) => (
                                    <Option key={item.id} value={item.id} className={`_campaign_item _campaign_item_${item.id}`}>{item.name}</Option>
                                ))}
                            </Select>
                        </Col>
                        <Col span={gridProduct} className="form-filter-control">
                            <label>{t("contact.product")}</label>
                            <Select
                                labelInValue
                                filterOption={false}
                                className={"_filter_product"}
                                placeholder={t("contact.select_product")}
                                notFoundContent={loadingProduct ? <Spin size="small" /> :(<Empty description={t("table.empty")} />)}
                                mode="multiple"
                                style={{width: "100%"}}
                                value={loadingProduct ? [] : productIds}
                                loading={loadingProduct}
                                onSelect={this.onSelect.bind(this, 'productIds')}
                                onDeselect={this.onDeselect.bind(this, 'productIds')}
                                onSearch={this.onSelectSearch.bind(this, 'productIds')}
                                onChange={this.onSelectChange.bind(this, 'productIds')}
                                onFocus={this.onFocus.bind(this, 'productIds')}
                            >
                                {products.map((item, index) => (
                                    <Option key={index} value={item.id} className={`_product_item _product_item_${item.id}`}>{item.name}</Option>
                                ))}
                            </Select>
                        </Col>
                        {(expanded === true) && (
                            <>
                                <Col span={8} className="form-filter-control">
                                    <label>{t("contact.created_at")}</label>
                                    <RangePicker
                                        placeholder={[t("contact.from"), t("contact.to")]}
                                        className={"_filter_created_at"}
                                        value={createdAt}
                                        onChange={this.onChangeRangePicker.bind(this, 'createdAt')}
                                        style={{width: '100%'}}
                                    />
                                </Col>
                                <Col span={8} className="form-filter-control">
                                    <label>{t("contact.assigned_at")}</label>
                                    <RangePicker
                                        placeholder={[t("contact.from"), t("contact.to")]}
                                        className={"_filter_assigned_at"}
                                        value={assignedAt}
                                        onChange={this.onChangeRangePicker.bind(this, 'assignedAt')}
                                        style={{width: '100%'}}
                                    />
                                </Col>
                                <Col span={8} className="form-filter-control">
                                    <label>{t("contact.ad_creator")}</label>
                                    <Select
                                        labelInValue
                                        filterOption={false}
                                        className={"_filter_ads_created_by"}
                                        placeholder={t("contact.select_ad_creator")}
                                        notFoundContent={(loadingUserAdsCreate ? <Spin size="small" /> : <Empty description={t("table.empty")} />)}
                                        mode="multiple"
                                        style={{width: "100%"}}
                                        value={loadingUserAdsCreate ? [] : adsCreatedBy}
                                        loading={loadingUserAdsCreate}
                                        onSelect={this.onSelect.bind(this, 'adsCreatedBy')}
                                        onDeselect={this.onDeselect.bind(this, 'adsCreatedBy')}
                                        onSearch={this.onSelectSearch.bind(this, 'adsCreatedBy')}
                                        onChange={this.onSelectChange.bind(this, 'adsCreatedBy')}
                                        onFocus={this.onFocus.bind(this, 'adsCreatedBy')}
                                    >
                                        {userAdsCreate.map((item, index) => (
                                            <Option key={index} value={item.username} className={`_ads_created_by_item _ads_created_by_item_${item.id}`}>{item.username}</Option>
                                        ))}
                                    </Select>
                                </Col>
                                <Col span={8} className="form-filter-control">
                                    <label>{t("contact.deliveryDate")}</label>
                                    <RangePicker
                                        placeholder={[t("contact.from"), t("contact.to")]}
                                        className={"_filter_delivery_date"}
                                        value={deliveryDate}
                                        onChange={this.onChangeRangePicker.bind(this, 'deliveryDate')}
                                        style={{width: '100%'}}
                                    />
                                </Col>
                                <Col span={8} className="form-filter-control">
                                    <label>{t("contact.appointment")}</label>
                                    <RangePicker
                                        placeholder={[t("contact.from"), t("contact.to")]}
                                        className={"_filter_appointment_at"}
                                        value={appointmentAt}
                                        onChange={this.onChangeRangePicker.bind(this, 'appointmentAt')}
                                        style={{width: '100%'}}
                                    />
                                </Col>
                                <Col span={8} className="form-filter-control">
                                    <label>{t("contact.callTime")}</label>
                                    <RangePicker
                                        placeholder={[t("contact.from"), t("contact.to")]}
                                        className={"_filter_last_call"}
                                        value={lastCall}
                                        onChange={this.onChangeRangePicker.bind(this, 'lastCall')}
                                        style={{width: '100%'}}
                                    />
                                </Col>
                                <Col span={8} className="form-filter-control">
                                    <label>{t("order_list.country")}</label>
                                    <SelectCountry
                                        t={this.props.t}
                                        value={countryId ? parseInt(countryId): undefined}
                                        onChange={this.onSelectChange.bind(this, 'countryId')}
                                    />
                                </Col>
                                <Col span={8} className="form-filter-control">
                                    <label>{t("order_list.vendor")}</label>
                                    <SelectVendor
                                        t={this.props.t}
                                        value={vendorId ? parseInt(vendorId) : undefined}
                                        onChange={this.onSelectChange.bind(this, 'vendorId')}
                                    />
                                </Col>
                                <Col span={8} className="form-filter-control">
                                    <label>{t("contact.status")}</label>
                                    <Select
                                        labelInValue
                                        className={"_filter_call_status"}
                                        placeholder={t("contact.select_status")}
                                        notFoundContent={(loadingCallStatus ? <Spin size="small" /> : <Empty description={t("table.empty")} />)}
                                        mode="multiple"
                                        style={{width: "100%"}}
                                        value={loadingCallStatus ? [] : defaultCallStatuses}
                                        loading={loadingCallStatus}
                                        onSelect={this.onSelect.bind(this, 'callStatuses')}
                                        onDeselect={this.onDeselect.bind(this, 'callStatuses')}
                                        onFocus={this.onFocus.bind(this, 'callStatuses')}
                                        onSearch={this.onSelectSearch.bind(this, 'callStatuses')}
                                        onChange={this.onSelectChange.bind(this, 'callStatuses')}
                                        filterOption={false}
                                    >
                                        {callStatuses.map((item, index) => (
                                            <Option key={item.id} value={item.id} className={`_call_status_item _call_status_item_${item.id}`}>{item.value}</Option>
                                        ))}
                                    </Select>
                                </Col>
                                <Col span={gridTotalCall} className="form-filter-control">
                                    <div>
                                        <label>{t("contact.calls")}</label>
                                        <InputGroup compact>
                                            <Select
                                                className={"_filter_calls"}
                                                placeholder={t("contact.search")}
                                                notFoundContent={(<Empty description={t("table.empty")} />)}
                                                style={{width: "50%"}}
                                                value={totalCallOperation}
                                                onChange={this.onChangeSelectSingle.bind(this, 'totalCallOperation')}
                                            >
                                                {callOperations.map((item, index) => (
                                                    <Option
                                                        key={index}
                                                        value={item.value}
                                                        className={`_call_operation_item _call_operation_item_${item.id}`}>{t(item.key)}</Option>
                                                ))}
                                            </Select>

                                            {totalCallOperation === 'between' ? (
                                                <>
                                                    <InputNumber
                                                        style={{ width: 80, textAlign: 'center' }}
                                                        placeholder={t('contact.from')}
                                                        className={"_total_call_from"}
                                                        value={totalCallValueFrom}
                                                        min={0}
                                                        max={100}
                                                        onChange={this.onChangeInputNumber.bind(this, 'totalCallValueFrom')}
                                                    />
                                                    <Input
                                                        style={{
                                                            width: 30, borderLeft: 0, pointerEvents: 'none', backgroundColor: '#fff',
                                                        }}
                                                        placeholder="~"
                                                        disabled
                                                    />
                                                    <InputNumber
                                                        style={{ width: 80, textAlign: 'center', borderLeft: 0 }}
                                                        placeholder={t('contact.to')}
                                                        className={"_total_call_to"}
                                                        value={totalCallValueTo}
                                                        min={0}
                                                        max={100}
                                                        onChange={this.onChangeInputNumber.bind(this, 'totalCallValueTo')}
                                                    />
                                                </>
                                            ): (
                                                <InputNumber
                                                    defaultValue={""}
                                                    min={0}
                                                    max={100}
                                                    style={{textAlign: 'center'}}
                                                    className={"_total_call_value"}
                                                    value={totalCallValue}
                                                    onChange={this.onChangeInputNumber.bind(this, 'totalCallValue')}
                                                />
                                            )}
                                        </InputGroup>
                                    </div>
                                </Col>
                            </>
                        )}

                        <Col span={gridButton} xs={24} className={`form-filter-control filter-button ${expanded ? 'expand' : ''}`}>
                            <Button type="primary" className="mg-r-10 _filter_button_filter" onClick={this.onFilter}>{t("button.filter")}</Button>
                            <Button type="default" className="mg-r-10 _filter_button_clear" onClick={this.clearFilter}>{t("contact.clear")}</Button>
                            <a className={"_filter_show_more"} onClick={this.toggleFormFilter}>{expanded ? t("contact.filter_less") : t("contact.filter_more")} <Icon className="expand-icon" type={expanded ? "up" : "down"} /></a>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export {Filter}