import React from 'react';
import {Button, Card, Dropdown, Icon, Menu, Modal, notification, Table, Tooltip} from "antd";
import lodash from 'lodash';
import moment from 'moment';
import AdService from "../../../../services/AdService";
import AddGiftModal from "./AddGiftModal";
import UserService from "../../../../services/UserService";
import {CAMPAIGNS_SCOPES} from "../../../../utils/api/Scopes";
import {Link} from "react-router-dom";
import UpdateGiftModal from "./UpdateGiftModal";

class GiftConfig extends React.Component {

    constructor(props) {
        super(props);
        const {t} = this.props;
        this.state = {
            loading: false,
            showAddGiftModal: false,
            showUpdateGiftModal: false,
            lastEditGift: undefined,
            columns: [
                {
                    key: 'product',
                    title: t('ads.product'),
                    render: (row) => {
                        return <span>{lodash.get(row, 'product.name')}</span>
                    }
                },
                {
                    key: 'sku',
                    title: 'Sku',
                    render: (row) => {
                        return <span>{lodash.get(row, 'sku')}</span>
                    }
                },
                {
                    key: 'createdBy',
                    title: t('ads.gift_created_by'),
                    render: (row) => {
                        return <Tooltip title={lodash.get(row, 'creator.fullName')}>{lodash.get(row, 'creator.username')}</Tooltip>
                    }
                },
                {
                    key: 'updatedBy',
                    title: t('ads.gift_updated_by'),
                    render: (row) => {
                        return <Tooltip title={lodash.get(row, 'updater.fullName')}>{lodash.get(row, 'updater.username')}</Tooltip>
                    }
                },
                {
                    key: 'createdAt',
                    title: t('ads.gift_created_at'),
                    render: (row) => {
                        return <span>{row.createdAt ? moment(row.createdAt).format('DD/MM/YYYY HH:mm') : '--'}</span>
                    }
                },
                {
                    key: 'updatedAt',
                    title: t('ads.gift_updated_at'),
                    render: (row) => {
                        return <span>{row.updatedAt ? moment(row.updatedAt).format('DD/MM/YYYY HH:mm') : '--'}</span>
                    }
                },
                {
                    key: "Edit",
                    title: '--',
                    render: (row) => {
                        return (
                            <span onClick={this.handleShowUpdateGiftModal.bind(this, row)} className={'pointer'}><Icon type="edit" /></span>
                        )
                    }
                },
                {
                    key: "Delete",
                    title: '--',
                    render: (row) => {
                        return (
                            <span onClick={this.handleDeleteGift.bind(this, row)} className={'pointer color-red'}><Icon type="delete" /></span>
                        )
                    }
                }
            ],
            gifts: []
        }
    }

    componentDidMount() {
        this.fetchGifts();
    }

    fetchGifts = () => {
        const {adId} = this.props;
        this.setState({loading: true});
        AdService.getGifts(adId, {}, (error, response) => {
            if (!error) {
                this.setState({gifts: response});
            }
            this.setState({loading: false});
        })
    }

    handleShowUpdateGiftModal = (row) => {
        this.setState({
            lastEditGift: row
        }, () => {
            this.handleUpdateGiftModalVisibleChange(true);
        })
    }

    handleDeleteGift = (row) => {
        const {t, adId} = this.props;
        const {loading} = this.state;
        Modal.confirm({
            title: t('message.confirm_delete'),
            okText: t('button.yes'),
            cancelText: t('button.no'),
            okButtonProps:{
                loading: loading,
                disabled: loading
            },
            cancelButtonProps:{
                loading: loading,
                disabled: loading
            },
            onOk: () => {
                this.setState({loading: true});
                AdService.deleteGift(adId, row.id, (error, response) => {
                    if (!error) {
                        notification.success({
                            message: t('message.success')
                        });

                        this.fetchGifts();
                    }
                    else {
                        notification.error({
                            message: t('message.fail')
                        })
                    }
                    this.setState({loading: false});
                })
            }
        })
    }

    handleAddGiftModalVisibleChange = (visible) => {
        this.setState({showAddGiftModal: visible});
    }

    handleUpdateGiftModalVisibleChange = (visible) => {
        this.setState({showUpdateGiftModal: visible});
    }

    render() {
        const {columns, loading, gifts, showAddGiftModal, showUpdateGiftModal, lastEditGift} = this.state;
        const {t, adId} = this.props;
        return (
            <Card
                title={t('ads.gifts')}
                extra={
                    <Button
                        size={'small'}
                        type={'primary'}
                        onClick={() => this.handleAddGiftModalVisibleChange(true)}
                    >{t('ads.add_gift')}</Button>
                }
            >
                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={gifts}
                    pagination={false}
                    rowKey={'id'}
                />

                <AddGiftModal
                    t={t}
                    visible={showAddGiftModal}
                    adId={adId}
                    fetchGifts={this.fetchGifts}
                    onVisibleChange={this.handleAddGiftModalVisibleChange}
                />

                {showUpdateGiftModal && lastEditGift !== undefined && (
                    <UpdateGiftModal
                        t={t}
                        visible={true}
                        adId={adId}
                        gift={lastEditGift}
                        fetchGifts={this.fetchGifts}
                        onVisibleChange={this.handleUpdateGiftModalVisibleChange}
                    />
                )}
            </Card>
        );
    }
}

export default GiftConfig;