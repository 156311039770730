import EagleClient from "../utils/api/EagleClient";
import _ from 'lodash';

export default class CampaignService {

    static getCampaigns(filter, cb) {
        let endpoint = "/Campaigns";
        EagleClient.request({
            endpoint: endpoint,
            method: "GET",
            mode: "free",
            queryString: filter
        }, cb);
    }

    static suggest(filter, cb) {
        let endpoint = "/Campaigns/suggest";
        EagleClient.request({
            endpoint: endpoint,
            method: "GET",
            mode: "free",
            queryString: filter
        }, cb);
    }

    static createCampaign(data, cb) {
        EagleClient.request({
            endpoint: "/Campaigns",
            method: "POST",
            body: data
        }, cb);
    }

    static updateCampaign(data, cb) {
        EagleClient.request({
            endpoint: "/Campaigns/"+data.id,
            method: "PATCH",
            body: data
        }, cb);
    }

    static getById(id,filter,cb) {
        let endpoint = "/Campaigns/"+id;
        EagleClient.request({
            endpoint: endpoint,
            method: "GET",
            queryString: {filter:filter}
        }, cb);
    }

    static countAdset(id, cb) {
        let endpoint = "/Campaigns/"+id+"/adSets/count";
        EagleClient.request({
            endpoint: endpoint,
            method: "GET"
        }, cb);
    }
    static getCampaignDetail(id,cb) {
        EagleClient.request({
            endpoint: `/Campaigns/${id}`,
            method: "GET",
        }, cb);
    }
    static updateCampaignDetail(id,data,cb) {
        EagleClient.request({
            endpoint: `/Campaigns/${id}`,
            method: "PATCH",
            body:JSON.stringify(data)
        }, cb);
    }

    static importDataFromFacebook(projectId, file, cb) {
        let formData = new FormData();
        formData.append('file', file);
        EagleClient.request({
            endpoint: `/Campaigns/import-facebook-ad-data?projectId=${projectId}`,
            method: "POST",
            file:formData
        }, cb);
    }

    static quickUpdateCampaign(id,data,cb) {
        EagleClient.request({
            endpoint: `/Campaigns/${id}/quick-edit`,
            method: "PATCH",
            body:JSON.stringify(data)
        }, cb);
    }

    static deleteCampaign(id, cb) {
        EagleClient.request({
            endpoint: `/Campaigns/${id}`,
            method: "DELETE",
        }, cb);
    }
}