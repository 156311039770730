import React from 'react';
import {Row, Col, Select, Spin} from "antd";
import {LocalStore} from "../../../utils/LocalStore";
import ReportService from "../../../services/ReportService";
import {notification} from "antd/lib/index";
const Option = Select.Option;

class List extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            statistic: [],
            SelectedItem: null,
            loading: false
        };
    }

    componentDidMount = () => {
        this.apiGetAllReport();
    };

    apiGetAllReport = () => {
        let vendorId = LocalStore.getInstance().read('vendor').id;
        let filter={
            order: "createdAt DESC",
            limit: 1000,
            vendor_id:vendorId
        };

        ReportService.getAllReport(filter,(err, response) => {
            if (!err) {
                this.setState({
                    statistic: response.list,
                    loading: true
                })
            } else {
                notification.error({
                    message: err.message
                });
            }
        });
    };

    onChangeItem = (value) => {
        this.setState({
            SelectedItem: JSON.parse(value).script
        })
    };

    rawMarkup(){
        let rawMarkup = this.state.SelectedItem !== null ? this.state.SelectedItem : this.state.statistic[0].script;
        return {__html: rawMarkup};
    }

    render() {
        const {t} = this.props;
        return (
            <div className="list-container" style={{marginTop:30, minHeight: 800}}>
                <Row className="pd-bt-15">
                    {this.state.loading && !!this.state.statistic.length && (
                        <>
                            <Col span={5}>
                                <Select
                                    className={"_statistics-select"}
                                    style={{width: 500}}
                                    onSelect={this.onChangeItem.bind(this)}
                                    defaultValue={JSON.stringify(this.state.statistic[0])}
                                >
                                    {this.state.statistic.map((item, index) => (
                                        <Option key={item.id} value={JSON.stringify(item)} className={`_statistics-select-value_${item.id}`}>{item.title}</Option>
                                    ))}
                                </Select>
                            </Col>

                            <Col span={24} style={{marginTop:10}}>
                                <div className="col-xs-12" dangerouslySetInnerHTML={this.rawMarkup()}/>
                            </Col>
                        </>
                    )}

                    {!this.state.loading && (
                        <div style={{textAlign:"center",marginTop:30}}>
                            <Spin />
                        </div>
                    )}

                </Row>
            </div>
        )
    }
}

export {List};