import React, {Component} from 'react';
import {Button, Popover, Select, Table, Popconfirm, Empty, Dropdown} from "antd";
import * as moment from "moment/moment";
import * as _ from 'lodash';
import {CAMPAIGNS_SCOPES} from "../../../utils/api/Scopes";
import UserService from "../../../services/UserService";

const Option = Select.Option;
class TabPermission extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isShowPopup: false,
            isShowPopupDelete: false,
            selectedRowKeys: [],
            selectedRows: []
        }
    }

    getConfigTableColumns = () => {
        const {t} = this.props;
        const renderColumn = (key, text, record) => {
            switch (key) {
                case 'created_at':
                    return record.created_at ? moment(record.created_at).format("DD/MM/YY HH:mm") : "";
            }
        };

        let columns = [
            {title: t("products.group_id"),dataIndex: 'id', key: 'id',className:"group-id _item",width:120},
            {title: t("products.group_name"),dataIndex: 'name', key: 'name',className:"name _item",width:200},
            {title: t("products.added_by"),dataIndex: 'created_by', key: 'created_by',className:"created_by _item",width:150},
            {title: t("products.added_at"),dataIndex: 'created_at', key: 'created_at',className:"created_at _item",width:150,render: renderColumn.bind(this, 'created_at')},
            {title: "",dataIndex: 'action', key: 'action',className:"_action _item", width:150},
        ];

        return columns;
    };

    isShowButtonAddGroup = () => {
        let {groupsNotInReportDefault} = this.props;
        return groupsNotInReportDefault.length > 0;
    };

    onSearchGroup = (value) => {
        this.props.onSearchGroup(value);
    };

    onVisibleChange = (visible) => {
        this.setState({
            isShowPopup: visible
        }, () => {
            if(visible) {
                this.onSearchGroup("");
            }
        });
    };

    onPopconfirmChange = (visible) => {
        let {selectedRows} = this.state;
        if(selectedRows.length) {
            this.setState({
                isShowPopupDelete: visible
            })
        }
    };

    onRemoveGroups = () => {
        let {selectedRows, selectedRowKeys} = this.state;
        this.props.apiDeleteGroups(selectedRowKeys);

        selectedRows = _.filter(selectedRows, (item) => {
            return selectedRowKeys.indexOf(item.id) < 0;
        });

        selectedRowKeys = _.filter(selectedRows, (item) => {
            return selectedRowKeys.indexOf(item.id) < 0;
        });

        this.setState({selectedRows, selectedRowKeys});
    };

    getConfigRowSelection = () => {
        return {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({selectedRowKeys, selectedRows});
            }
        }
    };

    onChangeGroup = (value) => {
        this.setState({
            isShowPopup: false
        }, () => {
            this.props.apiAddGroup([value]);
        })
    };


    render() {
        const {isShowPopup, isShowPopupDelete, selectedRows} = this.state;
        const {groups, groupsNotInReport, loadingButtonAdd, loadingButtonDelete, t} = this.props;
        let columns = this.getConfigTableColumns();

        let renderOptions = () => {
            let rs = [];
            for(let item of groupsNotInReport) {
                rs.push(<Option key={item.id} value={item.id}>{item.name}</Option>);
            }
            return rs;
        };

        let content = (
            <Select
                style={{width: 200}}
                placeholder={t("products.select_group")}
                notFoundContent={<Empty description={t("table.empty")} />}
                onChange={this.onChangeGroup}
                onSearch={this.onSearchGroup}
                showSearch={true}
                value={[]}
            >
                {renderOptions()}
            </Select>
        );

        return (
            <div className="permission-container">
                <div className="mg-bt-20">
                    <div className="flr">
                        {this.isShowButtonAddGroup() && (
                            <Popover placement="right" onVisibleChange={this.onVisibleChange} content={content} trigger="click" visible={isShowPopup}>
                                <Button loading={loadingButtonAdd} type="primary" className="_btn_add_group mg-r-10">{t("products.add_group")}</Button>
                            </Popover>
                        )}
                        <Popconfirm
                            onVisibleChange={this.onPopconfirmChange}
                            onConfirm={() => {this.onRemoveGroups()}}
                            visible={isShowPopupDelete}
                            title={t("group_user.confirm_delete")}
                            okText={t("popup-confirm.confirm")}
                            cancelText={t("popup-confirm.cancel")}>
                            <Button
                                loading={loadingButtonDelete}
                                type="danger"
                                icon="delete"
                                className="_btn_remove_group"
                                disabled={!selectedRows.length}
                            />
                        </Popconfirm>
                    </div>
                    <div className="clearfix" />
                </div>

                <Table
                    dataSource={groups}
                    columns={columns}
                    pagination={false}
                    rowKey={(record) => record.id}
                    locale={{
                        emptyText: t("table.empty")
                    }}
                    rowSelection={this.getConfigRowSelection()}
                />
            </div>
        )
    }
}

export {TabPermission};