import React from 'react';
import {Form, Select} from 'antd';

class QuickEditStatus extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    }

    onChange = (value) => {
        const {warehouse} = this.props;
        this.setState({loading: true});
        let data = Object.assign(warehouse, {status: value});
        this.props.updateWarehouse(data, (error, response) => {
            this.setState({loading: false});
            this.props.onUpdate(error, response);
        });
    };

    render() {
        const {loading} = this.state;
        const {t, warehouse, statuses} = this.props;
        const {getFieldDecorator} = this.props.form;
        return (
            <Form>
                <Form.Item>
                    {getFieldDecorator('name', {
                        initialValue: warehouse.status
                    })(
                        <Select
                            style={{width: '100%'}}
                            className={'_select_warehouse_status'}
                            showSearch={false}
                            defaultActiveFirstOption={false}
                            placeholder={t('warehouse.status')}
                            filterOption={false}
                            loading={loading}
                            onChange={this.onChange}
                        >
                            {statuses.map((item) => (
                                <Select.Option key={item.key}>{t(`warehouse.${item.key}`)}</Select.Option>
                            ))}
                        </Select>
                    )}
                </Form.Item>
            </Form>
        )
    }
}

QuickEditStatus = Form.create()(QuickEditStatus);
export {QuickEditStatus};