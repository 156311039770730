import React, {Component} from 'react';
import {Form, Input, Modal, notification} from "antd";
import {objectGet} from "../../../utils/helper";
import {EditorInput} from "../../../components/EditorInput";

class FaqEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    }

    onSave = () => {
        const {form, item, t} = this.props;
        form.validateFields((err, values) => {
            if(!err) {
                this.setState({loading: true});
                let data = {
                    question: values.question,
                    answer: values.answer
                };
                this.props.updateFaq(item.id, data, (error, response) => {
                    this.setState({loading: false});
                    if(!error) {
                        notification.success({
                            message: t('message.success')
                        });
                        this.props.onSave();
                    } else {
                        if(error.code === 'PRODUCT_NOT_ACTIVE') {
                            notification.error({
                                message: t('error.product_is_not_active')
                            });
                        } else {
                            notification.error({
                                message: error.message
                            });
                        }
                    }
                });
            }
        });
    };

    render() {
        const {t, item, onCancel} = this.props;
        const { getFieldDecorator } = this.props.form;
        const {loading} = this.state;
        return (
            <Modal
                width={1000}
                title={t('contact-detail.fqa')}
                visible={true}
                onOk={this.onSave}
                onCancel={onCancel}
                okButtonProps={{
                    loading: loading
                }}
            >
                <Form layout="vertical">
                    <Form.Item label={t("contact-detail.question")}>
                        {getFieldDecorator('question', {
                            rules: [
                                {required: true, message: t('message.required')}
                            ],
                            initialValue: objectGet(item, 'question')
                        })(
                            <Input />
                        )}
                    </Form.Item>
                    <Form.Item label={t("contact-detail.answer")}>
                        {getFieldDecorator('answer', {
                            rules: [
                                {required: true, message: t('message.required')}
                            ],
                            initialValue: objectGet(item, 'answer')
                        })(
                            <EditorInput
                                {...this.props}
                                showButton={false}
                            />
                        )}
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

FaqEdit = Form.create()(FaqEdit);

export {FaqEdit};