import React, {Component} from 'react';
import {Row, Col} from 'antd';
import * as moment from 'moment';

class BasicInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    renderInfo = (key, value, keyWrapperClassName = "", valueWrapperClassName = "") => {
        return (
            <div className="info-line">
                <label className="label">{key}:</label> <span className={`text ${valueWrapperClassName}`}>{value}</span>
            </div>
        );
    };

    render() {
        const {contact, t} = this.props;
        return (
            <div className="white-box no-margin">
                <div className="basic-info-container">
                    <h1 className="font-size-20px _contact_header_info_contact_name">{contact.customerName}</h1>
                    <Row gutter={20}>
                        <Col span={8}>
                            {this.renderInfo(t('contact-detail.campaign'), contact.campaign ? contact.campaign.name : "", "", "_contact_header_info_campaign")}
                        </Col>
                        <Col span={8}>
                            {this.renderInfo(t('contact-detail.date'), contact.createdAt ? moment(contact.createdAt).format("DD/MM/YYYY") : "", "", "_contact_header_info_created_at")}
                        </Col>
                        <Col span={8}>
                            {this.renderInfo(t('contact-detail.created_by'), contact.userCreated ? contact.userCreated.username : "", "", "_contact_header_info_created_by")}
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

export {BasicInfo};