import React from 'react';
import {translate} from 'react-i18next';
import {notification} from 'antd';
import StandardLayout from "../../../components/StandardLayout";
import {Header} from "./Header";
import UserGroupService from "../../../services/UserGroupService";
import {doCallBack, objectGet} from "../../../utils/helper";
import {BasicInfo} from "./BasicInfo";
import {User} from "./User";
import UserService from "../../../services/UserService";
import {GROUPS_SCOPES} from "../../../utils/api/Scopes";
import {LocalStore} from "../../../utils/LocalStore";

class GroupUserDetail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            group: null,
            vendor: LocalStore.getInstance().read('vendor')
        }
    }

    getId = () => {
        return this.props.match.params.id;
    };

    componentDidMount = () => {
        if (!UserService.isAllowed(GROUPS_SCOPES.GROUPS_VIEW)) {
            this.props.history.replace('/403');
        }

        this.fetchGroup();
        this.fetchUsers();
    };

    updateGroup = (data, cb) => {
        UserGroupService.updateUserGroup(data, this.getId(), cb);
    };

    removeGroup = (cb) => {
        UserGroupService.deleteGroup([this.getId()], cb);
    };

    fetchGroup = (cb) => {
        let {vendor} = this.state;
        UserGroupService.getSpecialGroup(this.getId(), (error, group) => {
            if(!error) {
                if(group.vendorId !== vendor.id) {
                    this.props.history.replace('/404');
                    return;
                }
                this.setState({group});
            } else {
                if(error.statusCode === 404) {
                    this.props.history.replace('/404');
                } else {
                    notification.error({
                        message: error.message
                    });
                }
            }
            doCallBack(cb, [error, group]);
        });
    };

    fetchUsers = (filter = {}, cb) => {
        UserGroupService.getMembers(this.getId(), cb);
    };

    fetchUsersNotIn = (filter = {}, cb) => {
        UserGroupService.getMembersNotInGroup(this.getId(), filter, cb);
    };

    addUser = (userId, type, cb) => {
        let data = {
            id: userId,
            type: type
        };
        UserGroupService.addMembers(this.getId(), data, cb);
    };

    removeUser = (userId, cb) => {
        UserGroupService.deleteUserFromGroup(this.getId(), userId, cb);
    };

    render() {
        const {t} = this.props;
        const {group} = this.state;
        return (
            <StandardLayout {...this.props} title={`${t("group_user.page_detail_title")} | ${objectGet(group, 'name', '')}`}>
                <div className="group-user-detail-page">
                    <Header
                        {...this.props}
                        {...this.state}
                    />

                    <BasicInfo
                        {...this.props}
                        {...this.state}
                        removeGroup={this.removeGroup}
                        updateGroup={this.updateGroup}
                        fetchGroup={this.fetchGroup}
                    />

                    <User
                        {...this.props}
                        {...this.state}
                        fetchUsers={this.fetchUsers}
                        fetchUsersNotIn={this.fetchUsersNotIn}
                        addUser={this.addUser}
                        removeUser={this.removeUser}
                        fetchGroup={this.fetchGroup}
                    />
                </div>
            </StandardLayout>
        )
    }
}

export default (translate())(GroupUserDetail);