import React, {Component} from 'react';
import {translate} from 'react-i18next';
import {Form as AntForm} from 'antd';
import StandardLayout from "../../../../components/StandardLayout";
import {Header} from "./Header";
import {Form as FormAdd} from "./Form";
import {arrayConcat} from "../../../../utils/helper";
import * as _ from "lodash";
import {LocalStore} from "../../../../utils/LocalStore";
import ProjectService from "../../../../services/ProjectService";
import CampaignService from "../../../../services/CampaignService";
import {notification} from "antd/lib/index";
import {CAMPAIGNS_SCOPES} from "../../../../utils/api/Scopes";
import UserService from "../../../../services/UserService";


class CampaignAdd extends Component {

    constructor(props) {
        super(props);
        this.state = {
            vendor: LocalStore.getInstance().read('vendor'),
            ads: [],
            wholeSale: [],
            productSku: "",
            configWholeSalePrice: [],
            validateErrors: {},
            isLoading: false
        };
        this.refForm = React.createRef();
    };

    componentWillMount() {
        if (!UserService.isAllowed(CAMPAIGNS_SCOPES.CAMPAIGNS_CREATE)) {
            this.props.history.replace('/403')
        }
    };

    fetchProject = (filter = {}, cb) => {
        let {vendor} = this.state;
        let filterCond = {
            vendor_id: vendor.id,
            include: ['product'],
            order: ["createdAt DESC"],
            product_active:true
        };
        filterCond = _.mergeWith(filterCond, filter, arrayConcat);
        ProjectService.getProjects(filterCond,cb);
    };

    saveValueInput = (key, index, value) => {
        let wholeSale = _.cloneDeep(this.state.wholeSale);
        wholeSale[index][key] = parseInt(value);
        this.setState({
            wholeSale: wholeSale
        });
    };

    resetValidateErrors = () => {
        this.setState({validateErrors: {}});
    };

    getCheckedConfigItems = () => {
        let {configWholeSalePrice} = this.state;
        return _.filter(configWholeSalePrice, (item) => {
            return item.checked;
        });
    };

    onSubmit = (e) => {
        const {t} = this.props;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let project = JSON.parse(values.project);
                let configWholeSalePrice = [];
                let wholeSalePrice = values.wholeSalePrice || [];

                _.forEach(wholeSalePrice, (v,k) => {
                    let rowPrice = [];
                        _.forEach(v, (value) => {
                           rowPrice.push(value);
                        });
                    let item = {
                        sku: k,
                        wholeSalePrice: rowPrice
                    };
                    configWholeSalePrice.push(item);
                });

                let data = {
                    projectId: project.id,
                    name: values.name,
                    facebookIds: values.facebookIds,
                    configWholeSale: configWholeSalePrice
                };

                this.setState({isLoading: true});

                CampaignService.createCampaign(data, (error, response) => {
                    if (!error) {
                        notification.success({
                            message: t('message.success')
                        });
                        this.props.history.push('/campaigns?tab=CAMPAIGN');
                    } else {
                        if (error.code === 'PRODUCT_NOT_ACTIVE') {
                            notification.error({
                                message: t('error.product_is_not_active')
                            });
                        } else {
                            notification.error({
                                message: error.message
                            });
                        }
                    }

                    this.setState({isLoading: false});
                });
            }
        });
    };

    render() {
        const {t} = this.props;
        return (
            <StandardLayout title={t('campaign.add_campaign')} {...this.props}>
                <div className="ad-add-page">
                    <AntForm>
                        <Header
                            {...this.props}
                            {...this.state}
                            onSubmit={this.onSubmit}
                        />
                        <FormAdd
                            {...this.props}
                            {...this.state}
                            onSearchCampaign={this.onSearchCampaign}
                            getConfigWholeSale={this.getConfigWholeSale}
                            saveValueInput={this.saveValueInput}
                            fetchProject={this.fetchProject}
                            ref={this.refForm}
                            validateErrors={this.state.validateErrors}
                            resetValidateErrors={this.resetValidateErrors}
                        />
                    </AntForm>
                </div>
            </StandardLayout>
        )
    }
}

CampaignAdd = AntForm.create()(CampaignAdd);
export default (translate())(CampaignAdd);
