import React, {Component} from 'react';
import {Select} from 'antd';
import lodash from 'lodash';
import UserService from "../../services/UserService";

class SelectProduct extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: undefined,
            loading: false,
            products: [],
            extraProducts: []
        }
    }

    static getDerivedStateFromProps(props, state) {
        let newState = {...state};
        if ('value' in props) {
            newState.value = props.value;
        }

        return newState;
    }

    componentDidMount() {
        if ('value' in this.props && this.props.value > 0) {
            this.setState({loading: true});
            UserService.getAvailableProducts({
                id: this.props.value
            }, (error, response) => {
                this.setState({loading: false});
                if(!error) {
                    this.setState({
                        extraProducts: response.list
                    });
                }
            });
        }

        this.fetchProducts();
    }

    fetchProducts = (filter = {}, cb) => {
        this.setState({loading: true});
        let defaultFilter = {page_size: 20};
        defaultFilter = Object.assign(defaultFilter, filter);
        UserService.getAvailableProducts(defaultFilter, (error, response) => {
            this.setState({loading: false});
            if(!error) {
                this.setState({
                    products: response.list
                });
            }
            if(typeof cb === 'function') cb(error, response);
        });
    };

    handleChange = (values) => {
        this.setState({value: values});
        this.props.onChange(values);
    };

    handleSearch = (value) => {
        if (value.toString().length >= 3) {
            this.fetchProducts({
                q: value
            })
        }
    };

    render() {
        const {loading, value, extraProducts} = this.state;
        let {products} = this.state;
        if (extraProducts.length) {
            products = lodash.unionBy(products, extraProducts, 'id');
        }

        const {t} = this.props;
        return (
            <Select
                allowClear={true}
                loading={loading}
                value={value}
                onChange={this.handleChange}
                className={'width-100pc'}
                placeholder={t("contact.select_product")}
                filterOption={false}
                showSearch={true}
                onSearch={this.handleSearch}
            >
                {products.map(item => (
                    <Select.Option key={item.id} value={item.id}>{item.name}---{item.code}</Select.Option>
                ))}
            </Select>
        );
    }
}

SelectProduct.defaultProps = {
    onChange: (values) => {}
};

export default SelectProduct;

