import React from 'react'
import {LocalStore} from "../../utils/LocalStore/index";
import {AUTH} from "../../config/auth";
import {translate} from 'react-i18next';

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentWillMount() {
        // Nếu đăng nhập rồi thì chuyển luôn
        let isLoggedIn = LocalStore.getInstance().read("loginSession");
        if (isLoggedIn) {
            LocalStore.getInstance().save("fobizUrlBack", null);
            window.location.href = "/";
        }

        let urlBack = LocalStore.getInstance().read("fobizUrlBack") || "/#/";
        LocalStore.getInstance().save("fobizUrlBack", urlBack);

        // Chuyển sang trang login GOBIZ ID
        window.location.href = AUTH.LOGIN_PATH;
    }

    render() {
        return (
            <div className={'mg-r-5 mg-l-5'}>Đang chuyển trang vui lòng đợi một chút...</div>
        )
    }

}

export default (translate())(Login);

