import React, {Component} from 'react';
import {Button, Col, DatePicker, Divider, Empty, Icon, Input, Row, Select, Spin} from 'antd';
import * as moment from 'moment';
import * as _ from 'lodash';
import {CAMPAIGNS_SCOPES} from "../../../utils/api/Scopes";
import UserService from "../../../services/UserService";

const Option = Select.Option;
const RangePicker = DatePicker.RangePicker;

class Filter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            keyword: "",
            createdBy: [],
            createdAt: []
        };
        this.onSelectSearch = _.debounce(this.onSelectSearch, 500);
    }

    onChangeInput = (key, e) => {
        this.setState({
            [key]: e.target.value
        });
    };

    onSelect = (key, value, option) => {
        let oldList = this.state[key] || [];
        oldList.push(value);
        this.setState({
            [key]: oldList
        });
    };

    onDeselect = (key, value, option) => {
        let oldList = this.state[key] || [];
        let index = oldList.indexOf(value);
        if(index >= 0) {
            oldList.splice(index, 1);
        }
        this.setState({
            [key]: oldList
        },function () {
            this.onFilter();
        });
    };

    onSelectSearch = (key, value) => {
        if(key === 'createdBy') {
            this.props.fetchUser({
                username: value
            });
        }
    };

    onSelectChange = (key, value) => {
        this.setState({
            [key]: value
        });
    };

    onFilter = () => {
        this.props.onFilter({
            keyword: this.state.keyword,
            created_by: _.map(this.state.createdBy, 'key').join(','),
            created_at_from: this.state.createdAt[0] ? moment(moment(this.state.createdAt[0]).format("YYYY-MM-DD 00:00:00")).toISOString() : "",
            created_at_to: this.state.createdAt[0] ? moment(moment(this.state.createdAt[1]).format("YYYY-MM-DD 23:59:59")).toISOString() : "",
            campaign_id: this.props.campaignId,
            project_id: this.props.projectId,
            product_id: this.props.productId,
        });
    };

    resetStateFilter = (cb = null) => {
        this.setState({
            keyword: "",
            createdBy: [],
            createdAt: []
        }, cb)
    };

    clearFilter = () => {
        this.resetStateFilter(() => {
            this.onFilter();
        });
    };

    onFocus = (key, e) => {
        switch (key) {
            case 'createdBy':
                this.props.resetDataSource('users');
                break;
        }
    };

    onChangeRangePicker = (key, ranges) => {
        this.setState({
            [key]: ranges
        });
    };

    getFilterCon = (key) => {
        if(key === "keyword"){
            return this.state.keyword?this.state.keyword:""
        }else if(key === "createdBy"){
            return this.state.createdBy?_.map(this.state.createdBy, 'key').join(','):""
        }else if(key === "created_at_from"){
            return this.state.createdAt[0]?moment(this.state.createdAt[0]).format("YYYY-MM-DD 00:00:00"):""
        }else if(key === "created_at_to"){
            return this.state.createdAt[1]?moment(this.state.createdAt[1]).format("YYYY-MM-DD 23:59:59"):""
        }
    };

    render() {
        const {t, loadingProduct, totalItems, currentPage, pageCount, users, loadingUser} = this.props;
        const {keyword, createdBy, createdAt} = this.state;
        return (
            <div className="product-filter filter _adset-filter adset-filter">
                <div>
                    <Row gutter={20} className="form-filter">
                        <Col md={4} lg={24} xl={24} style={{lineHeight:"32px"}}>
                            {!loadingProduct && !!totalItems && (
                                <span>{t("contact.page")} {currentPage}/{pageCount} ({totalItems})</span>
                            )}
                            {!loadingProduct && !totalItems && (
                                <span>{t("contact.page")} {1}/{1} ({totalItems})</span>
                            )}
                        </Col>

                        <Col md={20} lg={24} xl={24} className="form-filter-control mg-bt-0 filter-items-wrapper" style={{paddingRight: 6}}>
                            <div className="wrapper-left">
                                <div className="code-name wrapper-filter-control">
                                    <Input
                                        className={"_filter_keyword filter-input mg-r-5"}
                                        placeholder={t("products.name_or_code")}
                                        suffix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                        value={keyword}
                                        onChange={this.onChangeInput.bind(this, 'keyword')}
                                        onPressEnter={this.onFilter}
                                        style={{width: '100%'}}
                                    />
                                </div>

                                <div className="created-by wrapper-filter-control">
                                    <Select
                                        labelInValue
                                        filterOption={false}
                                        className={"_filter_created_by filter-input mg-r-5"}
                                        placeholder={t("products.created_by")}
                                        notFoundContent={(loadingUser ? <Spin size="small" /> : <Empty description={t("table.empty")} />)}
                                        mode="multiple"
                                        value={loadingUser ? [] : createdBy}
                                        loading={loadingUser}
                                        style={{width: '100%'}}
                                        onSelect={this.onSelect.bind(this, 'createdBy')}
                                        onDeselect={this.onDeselect.bind(this, 'createdBy')}
                                        onSearch={this.onSelectSearch.bind(this, 'createdBy')}
                                        onChange={this.onSelectChange.bind(this, 'createdBy')}
                                        onFocus={this.onFocus.bind(this, 'createdBy')}
                                    >
                                        {users.map((item, index) => (
                                            <Option key={index} value={item.username} className={`_created_by_item _created_by_item_${item.id}`}>{item.username}</Option>
                                        ))}
                                    </Select>
                                </div>

                                <div className="created-at wrapper-filter-control">
                                    <RangePicker
                                        placeholder={[t("contact.from"), t("contact.to")]}
                                        className={"_filter_created_at mg-r-5"}
                                        value={createdAt}
                                        onChange={this.onChangeRangePicker.bind(this, 'createdAt')}
                                    />
                                </div>

                                <div style={{display: 'inline-block'}} className={"wrapper-filter-control"}>
                                    <Button type="primary"  onClick={this.onFilter} className="_filter_button" style={{marginRight:5}}>{t("vendor.filter_button")}</Button>
                                    <Button onClick={this.clearFilter} className="_clear_button">{t("vendor.clear_button")}</Button>
                                </div>
                            </div>

                            {UserService.isAllowed(CAMPAIGNS_SCOPES.CAMPAIGNS_CREATE) && (
                                <div className="extra">
                                    <Divider type="vertical" className="pdivider" style={{margin: "0 15px"}}/>
                                    <Button type="primary" className="_btn_add_adset btn-add-adset mg-r-5" onClick={this.props.showPopupCreateAdset.bind(this,true)}>{t("ads_group_list.add")}</Button>
                                </div>
                            )}
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export {Filter}
