import React, { Component } from 'react';
import { Button, Col, Empty, Form, Input, notification, Select, Spin } from 'antd';
import * as _ from "lodash";
import { CourierService } from "../../services/CourierService";
import VendorService from '../../services/VendorService';
import StringeeService from '../../services/StringeeService';

const Option = Select.Option;

class FormStringeeAdd extends Component {

    constructor(props) {
        super(props);
        this.state = {
            vendors: [],
            loadingVendor: false,
        };
    }

    componentDidMount(){
        this.getAllVendor()
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        let that = this;
        this.props.form.validateFields(async (err, values) => {
            if(!err){
                try {
                    await StringeeService.createProject(values);
                    notification.success({
                        message: "Cấu hình thành công"
                    });
                    that.props.history.push(`/stringee`);
                } catch (error) {
                    notification.error({
                        message: error
                    });
                }
            }
        })
    };

    resetFields = () => {
        this.props.form.resetFields();
        this.props.history.push(`/stringee`)
    };

    getAllVendor = () => {
        this.setState({
            loadingVendor: true
        });

        VendorService.getVendorList({
            page: 1,
            page_size: 9999
        }, (error, response) => {
            this.setState({
                loadingVendor: false
            });

            if (!error) {
                this.setState({
                    vendors: response.list
                })
            }
        })
    }

    render() {
        const { t } = this.props;
        const {vendors, loadingVendor} = this.state;
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: { span: 16, offset: 8, textalign: "initial" },
            wrapperCol: { span: 8, offset: 8 },
        };
        return (
            <>
                <Form onSubmit={this.handleSubmit} className="pd-t-40 pd-bt-60 form-data">
                    <Col span={24} style={{ textAlign: "center", marginBottom: 20 }}>
                        <span className="add-vendor-title">{"Cấu hình stringee"}</span>
                    </Col>

                    <Form.Item
                        {...formItemLayout}
                        label={"Api Sid Key"}
                        className="name _name"
                    >
                        {getFieldDecorator('api_sid_key', {
                            rules: [{ required: true, message: 'Api Sid Key is required' }],
                        })(
                            <Input
                                autoFocus
                                placeholder={"Api Sid Key"}
                            />
                        )}
                    </Form.Item>

                    <Form.Item
                        {...formItemLayout}
                        label={"Api Secret Key"}
                        className="code _code"
                    >
                        {getFieldDecorator('api_secret_key', {
                            rules: [{ required: true, message: 'Api Secret Key is required' }],
                        })(
                            <Input
                                placeholder={"Api Secret Key"}
                            />
                        )}
                    </Form.Item>

                    <Form.Item
                        {...formItemLayout}
                        label={"Stringee Project Id"}
                        className="code _code"
                    >
                        {getFieldDecorator('stringee_project_id', {
                            rules: [{ required: true, message: 'Stringee Project Id is required' }],
                        })(
                            <Input
                                placeholder={"Stringee Project Id"}
                            />
                        )}
                    </Form.Item>

                    <Form.Item
                        {...formItemLayout}
                        label={"Stringee Project Name"}
                        className="code _code"
                    >
                        {getFieldDecorator('stringee_project_name', {
                            rules: [{ required: true, message: 'Stringee Project Name is required' }],
                        })(
                            <Input
                                placeholder={"Stringee Project Name"}
                            />
                        )}
                    </Form.Item>

                    <Form.Item
                        {...formItemLayout}
                        label={t("vendor.title")}
                        className="manager _manager"
                    >
                        {getFieldDecorator('vendor_id', {
                            rules: [{ required: true, message: `${t("vendor.title")} is required` }],
                        })(
                            <Select
                                mode="multiple"
                                style={{ width: '100%' }}
                                placeholder={t("vendor.title")}
                                loading={loadingVendor}
                                showSearch
                                filterOption={(inputValue, option) => {
                                    return option.props.children.toLowerCase().includes(inputValue.toLowerCase())
                                }}
                                maxTagCount={2}
                            >
                                {
                                    vendors ? vendors.map((value, index) => <Option key={value.id} value={`${value.id}`}>{value.name}</Option>) : []
                                }
                            </Select>
                        )}
                    </Form.Item>

                    <Form.Item
                        wrapperCol={{ span: 8, offset: 8 }}
                        className={"text-al-r"}
                    >
                        <Button type="primary" htmlType="submit">{t("button.save")}</Button>
                        <Button className="mg-l-10" onClick={this.resetFields}>{t("button.cancel")}</Button>
                    </Form.Item>
                </Form>
            </>
        );
    }
}

FormStringeeAdd = Form.create({})(FormStringeeAdd);
export { FormStringeeAdd }