import numeral from 'numeral';

var _lang = 'en';

numeral.register('locale', 'vi', {
    delimiters: {
        thousands: '.',
        decimal: ','
    },
    abbreviations: {
        thousand: ' nghìn',
        million: ' triệu',
        billion: ' tỷ',
        trillion: ' nghìn tỷ'
    },
    ordinal: function (number) {
        const b = number % 10;
        return (~~(number % 100 / 10) === 1) ? 'th' :
            (b === 1) ? 'st' :
                (b === 2) ? 'nd' :
                    (b === 3) ? 'rd' : 'th';
    },
    currency: {
        symbol: '₫'
    }
});


export const loadLocalCurrency = (lang) => {
    _lang = lang;
    // Không hiểu sao en bị lỗi, vì vậy để tạm vi ở đây thì sẽ hết lỗi
    numeral.locale('vi');
};

export const formatCurrency = (value) => {
    if(!value) return "";
    switch (_lang){
        case 'en' :
        default:
            // return numeral(value).format('0.0,[00]') + 'PHP';
            return numeral(value).format('0.0,[00]');

        case 'vi' :
            // return numeral(value).format('0,0.00') + 'PHP';
            return numeral(value).format('0,0.00');

    }
};

export const formatQuantity = (value) => {
    if(value === 0) return 0;
    if(!value) return "";
    switch (_lang) {
        case 'en' :
        default: {
            return numeral(value).format();
        }
        case 'vi' : {
            return numeral(value).format();
        }
    }
};
export const formatPrice=(value)=>{
    if(!value) return "";
    value = value.toString().replace(/[^0-9.]+/gm, '');
    if (value.indexOf(".") === 0) value = '';
    if (value.indexOf(".") !== value.lastIndexOf(".")) {
        value = value.substr(0, value.lastIndexOf("."));
    }
    if (value.indexOf(".") > 0 && (value.length - value.indexOf(".") > 3)) {
        value = value.substring(0, value.indexOf(".") + 3);
    }
    if (value <= 0) value = "";
    return value;
}


export const isNegative = value => /^-\d*/g.test(value);
export const convertCurrency = (value, isNe) => {
    const t = (value >= 0) ? value : '';
    return isNe ? '-' + t : t;
};

export const convertQuantity = (value, isNe) => {
    const t = (value > 0) ? value : '';
    return isNe ? '-' + t : t;
};

export const convertCurrencyBlur = (value, isNe) => {
    if(!value) return "";
    const t = (value > 0) ? formatCurrency(value) : '';
    return isNe ? '-' + t : t;
};

export const reConvert = (value, isNe) => {
    if(!value) return '';
    value = value.toString().replace(/[,]/g,'');
    const t = (value > 0) ? numeral(value).value() : null;
    return isNe && t !== null ? -t : t;
};

export const converQuantityBlur = (value, isNe) => {
    if(!value) return "";
    const t = (value > 0 ) ? formatQuantity(value) : '';
    return isNe ? '-' + t : t;
};
export const quantityFormat = (x) => {
    let temp = x.toString().replace(/[,.-]/g, "");
    if(temp.length>6) temp = temp.substr(0,6);
    let str = temp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return str;
};