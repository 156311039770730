export const COURIERS = {
    LBC: {id: 1, code: 'lbcexpress', name: "LBC" },
    SHIPPO: {id: 2, code:'SHIPPO', name: "Shippo"}
};

export const ORDER_STATUSES = [
    {key: 'DRAFT', value: 'D0', code: 90},
    {key: 'NEW', value: 'D1', code: 100},
    {key: 'READY', value: 'D1.1', code: 200},
    {key: 'HOLD', value: 'D1.3', code: 300},
    {
        key: 'CANCELLED', value: 'D1.4', code: 400,
        children: [
            {key: 'OUT_OF_DELIVERY_ZONE'},
            {key: 'CANCELLED_BY_CUSTOMER'},
            {key: 'OTHER'},
        ]
    },
    {key: 'PICKED_UP', value: 'D2', code: 500},
    {key: 'DELIVERING', value: 'D3', code: 600},
    {key: 'DELIVERED', value: 'D4.1', code: 700},
    {key: 'EXCHANGE', value: 'D4.2', code: 800},
    {key: 'RETURNING', value: 'D4.3', code: 900,
        children:[
            {key: 'CUSTOMER',children:[
                    {key:'CAN_NOT_REACH'},
                    {key:'INVALID_NUMBER'},
                    {key:'CHANGE_OF_MIND'},
                    {key:'PAYMENT_NOT_READY'},
                    {key:'NOT_AT_HOME'},
                    {key:'ITEM_NOT_THE_SAME_ADS'}
                ]},
            {key: 'LBC',children:[
                    {key:'DELIVERY_DELAY'},
                    {key:'NO_CALL_FROM_LBC'}
                ]},
            {key: 'TLS',children:[
                    {key:'JUST_IN_QUIRING'},
                    {key:'INCOMPLETE_ADDRESS'}
                ]},
            {key: 'ODZ',children:[
                    {key:'BY_ODZ'},
                    {key:'INCORRECT_ADDRESS'},
                ]},
            {key: 'OPERATION',children:[
                    {key:'WRONG_ITEM'}
                ]},
            {key: 'OTHER'},
        ]
    },
    {key: 'RETURNED', value: 'D5', code: 1000},
];

export function getStatus(key) {
    for (let status of ORDER_STATUSES) {
        if (status.key === key) {
            return status;
        }
    }
}

export const ORDER_STATUS = {
    'NEW': getStatus('NEW'),
    'READY': getStatus('READY'),
    'HOLD': getStatus('HOLD'),
    'CANCELLED': getStatus('CANCELLED'),
    'PICKED_UP': getStatus('PICKED_UP'),
    'DELIVERING': getStatus('DELIVERING'),
    'DELIVERED': getStatus('DELIVERED'),
    'EXCHANGE': getStatus('EXCHANGE'),
    'RETURNING': getStatus('RETURNING'),
    'RETURNED': getStatus('RETURNED')
};

export function getOrderStatusName(t, lang) {
    let statuses = getOrderStatuses(t, lang);
    let result = {};
    for (let status of statuses) {
        result[status.key] = status.name;
    }
    return result;
}

export function getOrderStatuses(t) {
    return [
        {key:'DRAFT', name: t('order_status.DRAFT')},
        {key:'NEW', name: t('order_status.NEW')},
        {key:'READY', name: t('order_status.READY')},
        {key:'HOLD', name: t('order_status.HOLD')},
        {key:'CANCELLED', name: t('order_status.CANCELLED')},
        {key:'PICKED_UP', name: t('order_status.PICKED_UP')},
        {key:'DELIVERING', name: t('order_status.DELIVERING')},
        {key:'DELIVERED', name: t('order_status.DELIVERED')},
        {key:'EXCHANGE', name: t('order_status.EXCHANGE')},
        {key:'RETURNING', name: t('order_status.RETURNING')},
        {key:'RETURNED', name: t('order_status.RETURNED')},
    ]
}

export const DELIVERY_CASE_STATUS = [
    { key: 'NEW', value: "New" },
    // { key: "PROCESSING", value: "On Process"},
    // { key: "DONE", value: "Done" },
	{ key: 'ASSIGNED', value: 'Assigned'},
	{ key: 'CONTACTED', value: 'Contacted'},
	{ key: 'COMPLETED', value: 'Completed'},
	{ key: 'RETURNED', value: 'Returned'}
];

export const HAS_DELIVERY_CASE_DATA = [
    {key: "delivery_case.has_delivery_case", value: 'true'},
    {key: "delivery_case.no_delivery_case", value: 'false'}
];

export const STATUS_UPLOAD_FILE = {
    DONE: 'done',
    ERROR: 'error',
    UPLOADING: 'uploading',
}

export const MESSAGE_ERROR_DELIVERY_CASE = {
    DUPLICATE_ERROR: 'Delivery case already exists',
    NOT_FOUND_ORDER_ERROR: 'Not found order',
    FILE_NOT_ROWS: 'File has not rows',
    FILE_MAXIMUN_ROWS: 'File allow maximum 200 rows',
    FILE_EXPECT_ORDER_CODE_COLUMN: 'File expect column Order Code',
    ORDER_STARUS_NOT_CORRECT: 'Only orders have the status READY or DELIVERING or DELIVERY FAILED can be uploaded',
    ACCESS_DENIED: 'Access Denied'
}
