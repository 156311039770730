import React from 'react';
import {Modal, Table} from 'antd';

class PopupImportContactFailed extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            items: []
        }
    }

    render() {
        const {items, t, onCancel} = this.props;
        const columns = [
            {title: t('order_list.lbc_import_line'), dataIndex: 'line', key: 'line'},
            {title: t('order_list.lbc_import_error'), dataIndex: 'error', key: 'error'}
        ];
        return (
            <Modal
                title={t('order_list.import_tracking_number_fail_list_title')}
                footer={null}
                visible={true}
                onCancel={onCancel}
            >
                <Table
                    columns={columns}
                    dataSource={items}
                    rowKey={record => record.line}
                    pagination={false}
                />
            </Modal>
        )
    }
}

export {PopupImportContactFailed};