import React, {Component} from 'react';
import {translate} from "react-i18next";
import * as _ from 'lodash';
import StandardLayout from "../../components/StandardLayout";
import {Filter} from "./Filter";
import OrderService from "../../services/OrderService";
import {LocalStore} from "../../utils/LocalStore";
import {ORDER_SCOPES} from "../../utils/api/Scopes";
import UserService from "../../services/UserService";
import {Header} from "./Header";
import {List} from "./List";
import {arrayConcat, getCurrentModule, getParamsAsObject, getUrlQueryString} from "../../utils/helper";
import CampaignService from "../../services/CampaignService";
import VendorService from "../../services/VendorService";
import {Url} from "../../utils/Url";
import AdService from "../../services/AdService";
import LocationService from "../../services/LocationService";
import UserGroupService from "../../services/UserGroupService";
import {Modal, notification} from "antd";

class Order extends Component {

    constructor(props) {
        super(props);
        this.mounted = true;
        this.moduleName = 'orders';
        let vendor = LocalStore.getInstance().read('vendor');
        this.state = {
            dataSource: [],
            currentPage: 1,
            totalItems: 50,
            pageSize: 100,
            visibleColumns:[
                {name:"all",language:"contact.check_all",priority:0,selected:false,isShow:true},
                {name:"code",language:"order_list.code",priority:1,selected:true,isShow:false},
                {name:"campaign",language:"order_list.campaign",priority:2,selected:true,isShow:false},
                {name:"contactName",language:"order_list.customerName",priority:3,selected:true,isShow:false},
                {name:"contactPhone",language:"order_list.customerPhone",priority:4,selected:true,isShow:false},
                {name:"assignTo",language:"order_list.assign_to",priority:5,selected:true,isShow:false},
                {name:"provinceName",language:"order_list.province",priority:6,selected:false,isShow:true},
                {name:"cityName",language:"order_list.district",priority:7,selected:false,isShow:true},
                {name:"createdAt",language:"order_list.created_at",priority:8,selected:false,isShow:true},
                {name:"deliveryDate",language:"order_list.deliveryDate",priority:9,selected:false,isShow:true},
                {name:"createdBy",language:"order_list.createdBy",priority:10,selected:false,isShow:true},
                {name:"status",language:"order_list.status",priority:11,selected:true,isShow:false},
                {name:"issueId",language:"order_list.stock_issue",priority:12,selected:true,isShow:false},
                {name:"paid",language:"order_list.payment_status",priority:13,selected:true,isShow:false}
            ],
            loading: true,
            loadingCampaign: true,
            loadingProduct: true,
            loadingUserAssignTo: true,
            loadingUserAdsCreate: true,
            loadingUserCreatedBy: true,
            loadingCallStatus: true,
            loadingOrderStatus: true,
            loadingProvinces: true,
            loadingCities: false,
            pageCount: 0,
            campaigns: [],
            products: [],
            userAssignTo: [],
            userAdsCreate: [],
            userCreatedBy: [],
            initialCampaigns: [],
            initialProducts: [],
            initialUserAssignTo: [],
            initialUserAdsCreate: [],
            initialUserCreatedBy: [],
            initialCallStatuses: [],
            vendorId: vendor.id,
            vendor: vendor,
            provinces: [],
            cities: [],
            countryId: vendor.countryId,
			couriers: []
        };
    }


    componentWillMount() {
        if (!UserService.isAllowed(ORDER_SCOPES.ORDER_VIEW)) {
            this.props.history.replace('/403')
        }
        if(localStorage.getItem("visibleColumnsOrder") !== null){
            this.setState({
                visibleColumns: JSON.parse(localStorage.getItem("visibleColumnsOrder"))
            });
        }
    };

    componentDidMount = () => {
        this.mounted = true;
        window.addEventListener("popstate", this.onPopstate.bind(this), false);
        this.loadData();
    };

    componentWillUnmount() {
        this.mounted = false;
        window.removeEventListener("popstate", this.onPopstate.bind(this), false);
    }

    onPopstate() {
        // Url thay đổi thì chỉ load lại list
        if(this.mounted && getCurrentModule(null, true) === this.moduleName) {
            let queryObject = getParamsAsObject(getUrlQueryString());
            this.loadOrder(queryObject);
        }
    };

    loadData = () => {
    	let {vendor} = this.state
        let queryObject = getParamsAsObject(getUrlQueryString());
        this.loadOrder(queryObject);
        this.fetchProvinces();
        this.fetchCampaign({}, (error, response) => {
            if(!error) {
                this.setState({initialCampaigns: response.list});
            }
        });
        this.fetchProduct({}, (error, response) => {
            if(!error) {
                this.setState({initialProducts: response.list});
            }
        });
        this.fetchUserAdsCreate({}, (error, response) => {
            if(!error) {
                this.setState({initialUserAdsCreate: response.list});
            }
        });
        this.fetchUserCreatedBy({}, (error, response) => {
            if(!error) {
                this.setState({initialUserCreatedBy: response.list});
            }
        });
        this.fetchUserAssignTo({}, (error, response) => {
            if(!error) {
                this.setState({initialUserAssignTo: response.list});
            }
        });

        this.fetchCouriers(vendor.id)
    };

    fetchCampaign = (filter = {}, cb) => {
        this.setState({loadingCampaign: true});
        const {vendor} = this.state;
        let defaultFilter = {vendor_id: vendor.id, page_size: 20};
        defaultFilter = Object.assign(defaultFilter, filter);
        CampaignService.suggest(defaultFilter, (error, response) => {
            if(!error) {
                this.setState({campaigns: response.list, loadingCampaign: false});
            }
            if(typeof cb === 'function') cb(error, response);
        });
    };

    fetchProduct = (filter = {}, cb) => {
        this.setState({loadingProduct: true});
        const {vendor} = this.state;
        let defaultFilter = {vendor_id: vendor.id, page_size: 20, order: ["id DESC"]};
        defaultFilter = Object.assign(defaultFilter, filter);
        VendorService.getProducts(vendor.id, defaultFilter, (error, response) => {
            if(!error) {
                this.setState({products: response.list, loadingProduct: false});
            }
            if(typeof cb === 'function') cb(error, response);
        });
    };

    fetchUserAssignTo = (filter = {}, cb) => {
        const {t} = this.props;
        this.setState({loadingUserAssignTo: true});
        const {vendor} = this.state;
        VendorService.getUserInVendor(vendor.id, filter, (error, response) => {
            if(!error) {
                let hasUnassign = false;
                if(!Object.keys(filter).length) {
                    hasUnassign = true;
                } else if(response.list.length === 0) {
                    if(["Unassign","Chưa phụ trách"].indexOf(filter.username) >= 0) {
                        hasUnassign = true;
                    }
                } else {
                    for(let k in filter) {
                        let v = filter[k];
                        if(k === 'ids') {
                            let arr = v.split(',');
                            if(arr.indexOf('0') >= 0) {
                                hasUnassign = true;
                                break;
                            }
                        } else if(k === 'username') {
                            let arr = v.split(',');
                            if(arr.indexOf('Unassign') >= 0) {
                                hasUnassign = true;
                                break;
                            }
                        }
                    }
                }

                if(hasUnassign) {
                    response.list = [{id: "Unassign", username: t('contact.unassign_to')}, ...response.list];
                }
                this.setState({userAssignTo: response.list, loadingUserAssignTo: false});
            }
            if(typeof cb === 'function') cb(error, response);
        });
    };

    fetchUserAdsCreate = (filter = {}, cb) => {
        this.setState({loadingUserAdsCreate: true});
        const {vendor} = this.state;
        VendorService.getUserInVendor(vendor.id, filter, (error, response) => {
            if(!error) {
                this.setState({userAdsCreate: response.list, loadingUserAdsCreate: false});
            }
            if(typeof cb === 'function') cb(error, response);
        });
    };

    fetchUserCreatedBy = (filter = {}, cb) => {
        this.setState({loadingUserCreatedBy: true});
        const {vendor} = this.state;
        VendorService.getUserInVendor(vendor.id, filter, (error, response) => {
            if(!error) {
                this.setState({userCreatedBy: response.list, loadingUserCreatedBy: false});
            }
            if(typeof cb === 'function') cb(error, response);
        });
    };

    fetchProvinces = (cb) => {
        this.setState({loadingProvinces: true});
        const {vendor} = this.state;
        LocationService.getProvinceByCountry(vendor.countryId, (error, response) => {
            if(!error) {
                this.setState({provinces: response, loadingProvinces: false});
            }
            if(typeof cb === 'function') cb(error, response);
        });
    };

    fetchCities = (provincesId = {},cityId = {},cb) => {
        this.setState({loadingCities: true});
        let defaultFilter = {};
        if(Object.keys(provincesId).length) {
            defaultFilter = Object.assign(defaultFilter, provincesId);
        }
        if(Object.keys(cityId).length) {
            defaultFilter = Object.assign(defaultFilter, cityId);
        }

        LocationService.getCityByManyProvince(defaultFilter, (error, response) => {
            if(!error) {
                this.setState({cities: response, loadingCities: false});
            }
            if(typeof cb === 'function') cb(error, response);
        });
    };

    fetchCouriers = (vendorId) => {
    	VendorService.couriers(vendorId, (error, response) => {
    		if (!error) {
    			this.setState({couriers: response})
			}
    		else {
    			this.setState({couriers: []})
			}
		})
	}

    resetCities = () => {
        this.setState({
            cities: []
        })
    };

    fetchAds = (filter = {}, cb) => {
        const {vendor} = this.state;
        let defaultFilter = {
            vendor_id: vendor.id,
            product_active: true
        };
        filter = _.mergeWith(defaultFilter, filter, arrayConcat);
        AdService.getAds(filter, cb);
    };

    loadOrder = (filter = {}) => {
        this.setState({loading: true});
        const {vendor} = this.state;
        let defaultFilter = {
            include: ['campaign', 'creator'],
            vendor_id: vendor.id,
            order: ["createdAt DESC"],
            page: filter.page || 1,
            page_size: filter.page_size || 100
        };

        if(Object.keys(filter).length) {
            defaultFilter = Object.assign(defaultFilter, filter);
        }
        OrderService.getList(defaultFilter, (error, response) => {
            if(!error) {
                this.setState({
                    dataSource: response.list,
                    currentPage: response.metadata.currentPage,
                    totalItems: response.count,
                    pageSize: response.metadata.pageSize,
                    pageCount: response.metadata.pageCount,
                    loading: false
                })
            }
        });
    };

    onShowSizeChange = (current, pageSize) => {
        let uri = this.getUrlInstance();
        uri.replaceParam('page', 1);
        uri.replaceParam('page_size', pageSize);
        this.pushQueryString(uri.getQueryString());
    };

    onChangePage = (page, pageSize) => {
        let uri = this.getUrlInstance();
        uri.replaceParam('page', page);
        uri.replaceParam('page_size', pageSize);
        this.pushQueryString(uri.getQueryString());
    };

    isShowCheckbox = (priority) => {
        let visibleColumns = this.state.visibleColumns;
        let visibleColumnsDefault = [1,2,3,4,5,11,12,13];
        if(priority === 0){
            let selected = !visibleColumns[0].selected;
            visibleColumns[0].selected = selected;
            for (let i = 1; i < visibleColumns.length; i++) {
                if (visibleColumnsDefault.indexOf(i) > -1) { continue; }
                visibleColumns[i].selected = selected;
            }
        }else{
            for (let i = 1; i < visibleColumns.length; i++) {
                if(visibleColumns[i].priority === priority){
                    visibleColumns[i].selected = !visibleColumns[i].selected;
                }
            }

            let checkAll = false;
            for (let i = 1; i < visibleColumns.length; i++) {
                if(visibleColumns[i].selected === false){
                    checkAll = true;
                }
            }
            if(checkAll){
                visibleColumns[0].selected = false;
            }else{
                visibleColumns[0].selected = true;
            }
        }


        localStorage.setItem("visibleColumnsOrder",JSON.stringify(visibleColumns));
        this.setState({
            visibleColumns:visibleColumns
        })
    };

    getColumnTable = () => {
        let visibleColumnsDisplay = [];
        let visibleColumns = this.state.visibleColumns;
        for (let i = 0; i < visibleColumns.length; i++) {
            if(visibleColumns[i].priority === 0){
                continue;
            }
            if(visibleColumns[i].selected){
                visibleColumnsDisplay.push(visibleColumns[i].name)
            }
        }
        return visibleColumnsDisplay;
    };

    IsShowColumnCheckbox = () => {
        let listColumn = [];
        let columnAllowSelected = this.state.visibleColumns;
        for (let i = 0; i < columnAllowSelected.length; i++) {
            if(columnAllowSelected[i].isShow){
                listColumn.push(columnAllowSelected[i])
            }
        }
        return listColumn;
    };

    getUrlInstance = (newInstance = false) => {
        if(newInstance) {
            return new Url();
        }
        return new Url('?'+getUrlQueryString());
    };

    pushQueryString = (queryString) => {
        this.props.history.push(`/orders${queryString}`);
    };

    resetDataSource = (key) => {
        this.setState({
            [key]: this.state[`initial${_.upperFirst(key)}`]
        });
    };

    onFilterByName = (id) => {
        this.filterRef.onFilterByCreatedByFromTable(id)
    };

    getFilterRfe = (ref) => {
        this.filterRef = ref;
    };

    fetchGroupsByProductId = (productId, filter = {}, cb) => {
        const {vendor} = this.state;
        VendorService.getGroupInProduct(vendor.id, productId, filter, cb);
    };

    fetchUsersByGroupId = (groupId, cb) => {
        UserGroupService.getMembers(groupId, cb);
    };

    assignOrder = (orderId, userId, cb) => {
        OrderService.assignOrderToSaleNormal({
            orderId: orderId,
            userId: userId
        }, cb);
    };

    handleExportFinancial = () => {
        const {vendor} = this.state;
        let filter = getParamsAsObject(getUrlQueryString());
        filter.vendor_id = vendor.id;

        this.setState({loading: true});

        OrderService.exportOrderForFinancial(filter, (error, response) => {
            this.setState({loading: false});
            if (!error) {
                window.open(response.downloadUrl, '_blank');
            }
            else {
                notification.error({
                    message: error.message || 'Có lỗi xảy ra. Vui lòng thử lại sau'
                })
            }
        })
    }

    handleExport = () => {
        const {vendor} = this.state;
        let filter = getParamsAsObject(getUrlQueryString());
        filter.vendor_id = vendor.id;
        filter.time_zone = LocalStore.getInstance().read("timezone");

        this.setState({loading: true});

        OrderService.exportOrder(filter, (error, response) => {
            this.setState({loading: false});
            if (!error) {
                window.open(response.downloadUrl, '_blank');
            }
            else {
                notification.error({
                    message: error.message || 'Có lỗi xảy ra. Vui lòng thử lại sau'
                })
            }
        })
    }

    handleExportForM28 = () => {
        const {vendor} = this.state;
        let filter = getParamsAsObject(getUrlQueryString());
        filter.vendor_id = vendor.id;
        filter.m28 = 1;
        filter.time_zone = LocalStore.getInstance().read("timezone");
        this.setState({loading: true});
        OrderService.exportOrder(filter, (error, response) => {
            this.setState({loading: false});
            if (!error) {
                window.open(response.downloadUrl, '_blank');
            }
            else {
                notification.error({
                    message: error.message
                })
            }
        })
    }

    handleExportForCarrierUnit = () => {
        const {t} = this.props;
        Modal.confirm({
            title: t('message.confirm_export_order_for_shipping_partner'),
            onOk: () => {
                const {vendor} = this.state;
                let filter = getParamsAsObject(getUrlQueryString());
                filter.vendor_id = vendor.id;
                filter.time_zone = LocalStore.getInstance().read("timezone");
                this.setState({loading: true});
                OrderService.exportOrderForCarrierUnit(filter, (error, response) => {
                    this.setState({loading: false});
                    if (!error) {
                        window.open(response.downloadUrl, '_blank');
                    }
                    else {
                        notification.error({
                            message: error.message
                        })
                    }
                });
            }
        })
    }

    render() {
        const {t} = this.props;
        let visibleColumnsDisplay = this.getColumnTable();
        let columnAllowSelected = this.IsShowColumnCheckbox();
        const {dataSource, vendor, totalItems, currentPage, pageSize,
            pageCount, campaigns, products, provinces, cities,userCreatedBy,
            loadingCampaign, loadingProduct, loadingUserAdsCreate, loadingUserCreatedBy, loadingUserAssignTo, loadingCallStatus,
            loadingOrderStatus, userAdsCreate, userAssignTo, loadingProvinces, loadingCities, couriers} = this.state;
        return (
            <StandardLayout {...this.props} title={t("order.page_title")}>
                <div className="contact-list-page list-page _order-container">
                    <Header
                        {...this.props}
                    />
                    <Filter
                        {...this.props}
                        campaigns={campaigns}
                        products={products}
                        provinces={provinces}
                        cities={cities}
                        userAdsCreate={userAdsCreate}
                        userCreatedBy={userCreatedBy}
                        userAssignTo={userAssignTo}
                        loadingCampaign={loadingCampaign}
                        loadingProduct={loadingProduct}
                        loadingUserAdsCreate={loadingUserAdsCreate}
                        loadingUserCreatedBy={loadingUserCreatedBy}
                        loadingUserAssignTo={loadingUserAssignTo}
                        loadingCallStatus={loadingCallStatus}
                        loadingOrderStatus={loadingOrderStatus}
                        loadingProvinces={loadingProvinces}
                        loadingCities={loadingCities}
                        fetchCampaign={this.fetchCampaign}
                        fetchProduct={this.fetchProduct}
                        fetchUserAssignTo={this.fetchUserAssignTo}
                        fetchUserAdsCreate={this.fetchUserAdsCreate}
                        fetchUserCreatedBy={this.fetchUserCreatedBy}
                        fetchProvinces={this.fetchProvinces}
                        fetchCities={this.fetchCities}
						fetchCouriers={this.fetchCouriers}
                        getUrlInstance={this.getUrlInstance}
                        pushQueryString={this.pushQueryString}
                        resetDataSource={this.resetDataSource}
                        resetCities={this.resetCities}
                        vendorId={this.state.vendorId}
                        countryId={this.state.countryId}
                        ref={this.getFilterRfe}
						couriers={couriers}
                    />
                    <List
                        {...this.props}
                        dataSource={dataSource}
                        currentPage={currentPage}
                        pageSize={pageSize}
                        pageCount={pageCount}
                        totalItems={totalItems}
                        vendor={vendor}
                        onChangePage={this.onChangePage}
                        onShowSizeChange={this.onShowSizeChange}
                        visibleColumns={columnAllowSelected}
                        isShowCheckbox={this.isShowCheckbox}
                        visibleColumnsDisplay={visibleColumnsDisplay}
                        loading={this.state.loading}
                        loadOrder={this.loadOrder}
                        getUrlInstance={this.getUrlInstance}
                        pushQueryString={this.pushQueryString}
                        userCreatedBy={this.state.initialUserCreatedBy}
                        onFilterByName={this.onFilterByName}
                        fetchGroupsByProductId={this.fetchGroupsByProductId}
                        fetchUsersByGroupId={this.fetchUsersByGroupId}
                        assignOrder={this.assignOrder}
                        onExportForFinancial={this.handleExportFinancial}
                        onExport={this.handleExport}
                        onExportForM28={this.handleExportForM28}
                        onExportForCarrierUnit={this.handleExportForCarrierUnit}
                    />
                </div>
            </StandardLayout>
        );
    }
}

export default (translate())(Order)
