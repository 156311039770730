import React from 'react';
import {Row, Col, Input, Icon, Button} from 'antd';
import * as _ from "lodash";
import {getParamsAsObject, getUrlQueryString} from "../../../../utils/helper";

class Filter extends React.Component {

    constructor(props) {
        super(props);
        this.mounted = false;
        this.state = {
            sku: "",
            productName: "",
        };
    }

    onPopstate = () => {
        if(this.mounted) {
            this.setStateFromQueryString();
        }
    };

    setStateFromQueryString = () => {
        let queryObject = getParamsAsObject(getUrlQueryString());
        this.resetStateFilter(() => {
            _.forEach(queryObject, (v, k) => {
                if (v) {
                    if(k === 'sku') {
                        this.setState({sku: v});
                    }
                    if(k === 'product_name') {
                        this.setState({productName: v});
                    }
                }
            });
        });
    };

    resetStateFilter = (cb = null) => {
        this.setState({
            sku: "",
            productName: ""
        }, cb)
    };

    componentWillUnmount() {
        this.mounted = false;
        window.removeEventListener("popstate", this.onPopstate.bind(this), false);
    }

    componentDidMount = () => {
        this.mounted = true;
        window.addEventListener("popstate", this.onPopstate.bind(this), false);
        this.setStateFromQueryString();
    };

    onFilter = () => {
        let uri = this.props.getUrlInstance(true);
        const {sku, productName} = this.state;
        uri.replaceParam('sku', sku.trim());
        uri.replaceParam('productName', productName.trim());
        uri.replaceParam('page', 1);
        uri.replaceParam('filter', true);

        this.props.pushQueryString(uri.getQueryString());
    };

    clearFilter = () => {
        this.resetStateFilter(() => {
            this.props.pushQueryString("");
        });
    };


    onChangeInput = (key, e) => {
        this.setState({
            [key]: e.target.value
        });
    };

    render() {
        const {t} = this.props;
        const {sku, productName} = this.state;
        return (
            <div className="issue-filter filter filter-inside-box">
                <Row gutter={20} className="form-filter">
                    <Col xl={4} lg={8} className="form-filter-control">
                        <label>{t('products.search_product')}</label>
                        <Input.Search
                            value={productName}
                            onChange={this.onChangeInput.bind(this, 'productName')}
                            placeholder={t('products.search_product')}
                            onPressEnter={this.onFilter}
                        />
                    </Col>
                    <Col xl={4} lg={8} className="form-filter-control">
                        <label>{t('products.search_sku')}</label>
                        <Input.Search
                            value={sku}
                            onChange={this.onChangeInput.bind(this, 'sku')}
                            placeholder={t('products.search_sku')}
                            onPressEnter={this.onFilter}
                        />
                    </Col>

                    <Col xl={16} lg={8} className="form-filter-control filter-button text-right">
                        <label>&nbsp;</label>
                        <div>
                            <span className="mg-r-10 pointer _filter_button_clear" onClick={this.clearFilter}>
                                <Icon type="sync" /> {t("contact.clear")}
                            </span>
                            <Button type="primary" className="_filter_button_filter" onClick={this.onFilter}>
                                {t("button.filter")}
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}

export {Filter}