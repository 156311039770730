import React, {Component} from 'react';
import {Breadcrumb as AntBreadCrumb} from 'antd';
import {BreadCrumb as ComBreadcrumb} from "../../../components/BreadCrumb";
import {Link} from "react-router-dom";
import {objectGet} from "../../../utils/helper";

class BreadCrumb extends Component {

    render() {
        const {t, order} = this.props;
        const leftContent = (
            <AntBreadCrumb>
                <AntBreadCrumb.Item>
                    <Link to={"/"}>{t('page.home')}</Link>
                </AntBreadCrumb.Item>
                <AntBreadCrumb.Item><Link to={"/orders"}>{t("order.page_title")}</Link></AntBreadCrumb.Item>
                <AntBreadCrumb.Item>{objectGet(order, 'code')}</AntBreadCrumb.Item>
            </AntBreadCrumb>
        );

        return (
            <ComBreadcrumb
                {...this.props}
                leftContent={leftContent}
            />
        );
    }
}

export {BreadCrumb};