import React from 'react';
import * as _ from 'lodash';
import {Button, Empty, Icon, Input, InputNumber, Popconfirm, Select, Spin} from 'antd';
import {objectGet, replaceString} from "../../../utils/helper";
import {formatCurrency, formatQuantity} from "../../../utils/currency";
import {EditQuantityOrderItem} from "./EditQuantityOrderItem";
import {toastr} from "../../../utils/toastr";
import UserService from "../../../services/UserService";
import {ORDER_SCOPES} from "../../../utils/api/Scopes";
import AddGiftModal from "./AddGiftModal";

class Product extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isAdd: false,
            loadingAddOrderItem: false,
            loadingDeleteOrderItem: false,
            isEditDeliveryFee: false,
            skuSelected: {},
            deliveryFree: "",
            dirtyNewOrderItemPrice: [],
            showAddGiftModal: false
        };
    }

    onConfirmDeleteOrderItem = (item) => {
        const {t} = this.props;
        this.setState({loadingDeleteOrderItem: true});
        this.props.deleteOrderItem(item.id, (error) => {
            this.setState({loadingDeleteOrderItem: false});
            this.props.fetchOrder();
            if (!error) {
                toastr.success(t('message.success'));
            } else {
                toastr.error(error.message);
            }
        });
    };

    addOrderItem = () => {
        this.setState({isAdd: true});
    };

    onSubmitAddOrderItem = () => {
        const {t} = this.props;
        this.setState({loadingAddOrderItem: true});
        const {skuSelected} = this.state;
        const data = {
            quantity: skuSelected.quantity,
            sku: skuSelected.sku,
            unitPrice: skuSelected.unitPrice
        };
        this.props.addOrderItem(data, (error) => {
            this.setState({loadingAddOrderItem: false});
            this.onCancelAddOrderItem();
            if (!error) {
                toastr.success(t('message.success'));
                this.props.fetchOrder();
            } else {
                toastr.error(error.message);
            }
        });
    };

    onCancelAddOrderItem = () => {
        this.setState({
            isAdd: false,
            skuSelected: {}
        });
    };

    onChangeSkuItem = (value) => {
        let skuSelected = JSON.parse(value);
        const {order} = this.props;
        const adsConfig = objectGet(order, 'adsConfig', []);
        skuSelected.quantity = 1;
        for (let conf of adsConfig) {
            if (conf.sku == skuSelected.sku) {
                let priceConfig = conf.wholeSalePrice || [];
                for (let priceCon of priceConfig) {
                    if (skuSelected.quantity >= priceCon.from && skuSelected.quantity <= priceCon.to) {
                        skuSelected.unitPrice = priceCon.price;
                        skuSelected.amount = skuSelected.unitPrice * skuSelected.quantity;
                        break;
                    }
                }
                break;
            }
        }
        this.setState({skuSelected});
    };

    isDirtyNewOrderItemPrice = (orderItem) => {
        const {dirtyNewOrderItemPrice} = this.state;
        return dirtyNewOrderItemPrice.indexOf(orderItem.id) >= 0;
    };

    onChangeQuantityNewSku = (value) => {
        let {skuSelected} = this.state;
        skuSelected.quantity = value;
        skuSelected.amount = skuSelected.unitPrice * skuSelected.quantity;

        if (!this.isDirtyNewOrderItemPrice(skuSelected)) {
            const {order} = this.props;
            const adsConfig = objectGet(order, 'adsConfig', []);
            for (let conf of adsConfig) {
                if (conf.sku == skuSelected.sku) {
                    let priceConfig = conf.wholeSalePrice || [];
                    for (let priceCon of priceConfig) {
                        if (skuSelected.quantity >= priceCon.from && skuSelected.quantity <= priceCon.to) {
                            skuSelected.unitPrice = priceCon.price;
                            skuSelected.amount = skuSelected.unitPrice * skuSelected.quantity;
                            break;
                        }
                    }
                    break;
                }
            }
        }

        this.setState({skuSelected});
    };

    handleChangeNewOrderItemPrice = (value) => {
        let {skuSelected, dirtyNewOrderItemPrice} = this.state;
        skuSelected.unitPrice = value;
        skuSelected.amount = skuSelected.unitPrice * skuSelected.quantity;

        dirtyNewOrderItemPrice.push(skuSelected.id);
        dirtyNewOrderItemPrice = _.uniq(dirtyNewOrderItemPrice);

        this.setState({
            skuSelected,
            dirtyNewOrderItemPrice
        });
    };

    handleSubmitOrderItem = (item) => {
        const {t} = this.props;
        this.props.updateOrderItem(item.id, item, (error) => {
            if (error === null) {
                toastr.success(t('message.success'));
                this.props.fetchOrder();
            }
            else {
                if(error.code !== 'INVALID_PHONE') {
                    toastr.error(error.message);
                }
            }
        });
    }

    toggleEditDeliveryFee = () => {
        this.setState({isEditDeliveryFee: !this.state.isEditDeliveryFee});
    };

    handleUpdateDeliveryFee = (e) => {
        const {t, order} = this.props;
        const data = _.pick(order, [
            'deliveryFee',
            'contactName',
            'contactPhone',
            'address'
        ]);
        this.props.updateOrder(data, (error, response) => {
            if (!error) {
                toastr.success(t('message.success'));
            } else {
                toastr.error(error.message);
            }
            this.toggleEditDeliveryFee();
        });
    };

    handleUpdateCollectedCod = () => {
        const {t, order} = this.props;
        this.props.onUpdateOrderCollectedCod(order.collectedCod, (error, response) => {
            if (!error) {
                toastr.success(t('message.success'));
            } else {
                toastr.error(error.message);
            }
        });
    };

    handleChangeOrderItemPrice = (item, value) => {
        let orderItem = {...item};
        orderItem.unitPrice = value;
        orderItem.amount = orderItem.unitPrice * orderItem.quantity;
        this.props.updateOrderItemState(item.id, {...orderItem});
    };

    handleAddGiftModalVisibleChange = (visible) => {
        this.setState({
            showAddGiftModal: visible
        })
    }

    getGiftName = (orderItem) => {
        const {orderItemGifts} = this.props;
        const exist = orderItemGifts.find(oi => objectGet(oi, 'sku.sku') === orderItem.sku);
        return <span>
            <Icon type="gift"/> ({objectGet(exist, 'product.name')}) {orderItem.skuDisplay}
        </span>;
    }

    render() {
        const {
            isAdd,
            skuSelected,
            loadingAddOrderItem,
            loadingDeleteOrderItem,
            isEditDeliveryFee,
            showAddGiftModal
        } = this.state;
        const {t, order, currencyUnit, productSkuItems, isFullSizeOrderItem} = this.props;
        const product = objectGet(order, 'product', {});
        const orderItems = objectGet(order, 'orderItems', []);
        let totalAmount = 0;
        for (let orderItem of orderItems) {
            totalAmount += parseFloat(orderItem.amount);
        }

        const orderStatus = objectGet(order, 'status');
        let isShowEdit = false;
        let listStatusAllowEdit = ["DRAFT", "NEW", "HOLD", "READY", "PICKED_UP"];
        if (order && !order.hasIssue && order.type !== "EXCHANGE" && orderItems.length >= 1 && listStatusAllowEdit.indexOf(orderStatus) >= 0) {
            isShowEdit = true;
        }

        // Lọc những sku ko thuộc order
        let orderItemSku = _.map(orderItems, 'sku');
        let skuItems = _.filter(productSkuItems, (item) => {
            return orderItemSku.indexOf(item.sku) < 0;
        });

        let isShowAddGiftBtn = false;
        if (listStatusAllowEdit.indexOf(orderStatus) >= 0) {
            isShowAddGiftBtn = true;
        }

        // Get min-quantity and max-quantity from ads config
        // const adsConfig = objectGet(order, 'adsConfig', []);
        // let minQuantity = 1;
        // let maxQuantity = 100000000;
        // for(let conf of adsConfig) {
        //     if(conf.sku == skuSelected.sku) {
        //         let priceConfig = conf.wholeSalePrice || [];
        //         minQuantity = _.minBy(priceConfig, 'from').from;
        //         maxQuantity = _.maxBy(priceConfig, 'to').to;
        //         break;
        //     }
        // }

        return (
            <Spin tip={t('message.loading')} spinning={loadingDeleteOrderItem}>
                <div className={"product-container"}>
                    <table className={"table-order-items"}>
                        <thead>
                        <tr>
                            <th>{t('order_detail.product_name')}</th>
                            <th style={{width: isFullSizeOrderItem ? 300 : 220}}>{t('order_detail.properties')}</th>
                            <th>{t('order_detail.price')} <span className="currency">({currencyUnit})</span></th>
                            <th>{t('order_detail.quantity')}</th>
                            <th>{t('order_detail.total')} <span className="currency">({currencyUnit})</span></th>
                            <th>
                                <Button type={'link'} onClick={this.props.onToggleFullSizeOrderItem}>
                                    <Icon type="fullscreen"/>
                                </Button>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {orderItems.map((item, index) => (
                            <tr key={index}>
                                <td className="no-border-bottom _product_name">
                                    <span
                                        className="product-name text-blue _product_name">{index === 0 ? product.name : ""}</span>
                                </td>
                                <td><span className="_sku_name">{item.gift ? this.getGiftName(item) : item.skuDisplay}</span></td>
                                <td>
                                    <InputNumber
                                        min={0}
                                        placeholder={formatCurrency(item.unitPrice)}
                                        style={{width: '100%'}}
                                        value={item.unitPrice}
                                        className={`_price`}
                                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        disabled={!UserService.isAllowed(ORDER_SCOPES.ORDER_UPDATE_PRICE)}
                                        onChange={this.handleChangeOrderItemPrice.bind(this, item)}
                                        onPressEnter={this.handleSubmitOrderItem.bind(this, item)}
                                    />
                                </td>
                                <td>
                                    {isShowEdit ? (
                                        <EditQuantityOrderItem
                                            {...this.props}
                                            orderItem={item}
                                        />
                                    ) : (
                                        <span className="_quantity">{item.quantity}</span>
                                    )}
                                </td>
                                <td><span className="_amount">{formatCurrency(item.amount)}</span></td>
                                <td>
                                    {isShowEdit && (
                                        <Popconfirm
                                            title={t("group_user.confirm_delete")}
                                            okText={t("popup-confirm.confirm")}
                                            cancelText={t("popup-confirm.cancel")}
                                            onConfirm={this.onConfirmDeleteOrderItem.bind(this, item)}
                                        >
                                            <Icon type="delete" className="pointer"/>
                                        </Popconfirm>
                                    )}
                                </td>
                            </tr>
                        ))}

                        {isAdd && (
                            <>
                                <tr>
                                    <td/>
                                    <td>
                                        <Select
                                            filterOption={false}
                                            className={"_select_sku filter-input mg-r-5"}
                                            notFoundContent={<Empty description={t("table.empty")}/>}
                                            loading={false}
                                            style={{width: '100%'}}
                                            defaultActiveFirstOption={false}
                                            onChange={this.onChangeSkuItem}
                                            showSearch={false}
                                        >
                                            {skuItems.map((skuItem) => (
                                                <Select.Option key={skuItem.sku}
                                                               value={JSON.stringify(skuItem)}>{skuItem.display}</Select.Option>
                                            ))}
                                        </Select>
                                    </td>
                                    <td>
                                        <InputNumber
                                            min={0}
                                            placeholder={formatCurrency(skuSelected.unitPrice)}
                                            style={{width: '100%'}}
                                            value={skuSelected.unitPrice}
                                            className={`_add_sku_price`}
                                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            disabled={!UserService.isAllowed(ORDER_SCOPES.ORDER_UPDATE_PRICE)}
                                            onChange={this.handleChangeNewOrderItemPrice}
                                        />
                                    </td>
                                    <td>
                                        <InputNumber
                                            min={1}
                                            value={skuSelected.quantity}
                                            onChange={this.onChangeQuantityNewSku}
                                            parser={value => replaceString(value)}
                                            formatter={value => formatQuantity(value)}
                                            style={{width: '100%'}}
                                        />
                                    </td>
                                    <td>
                                        <span
                                            className="_add_sku_total_amount">{formatCurrency(skuSelected.amount)}</span>
                                    </td>
                                </tr>
                            </>
                        )}

                        {orderItems.length < productSkuItems.length && UserService.isAllowed(ORDER_SCOPES.ORDER_UPDATE_ATTRS) && isShowEdit && (
                            <tr>
                                <td/>
                                <td colSpan={2}>
                                    {!isAdd && (
                                        <span
                                            style={{color: 'rgba(24, 144, 255, 0.65)'}}
                                            className="pointer"
                                            onClick={this.addOrderItem}
                                        >
                                                <Icon type="plus"/> {t('order_detail.add_property')}
                                            </span>
                                    )}
                                </td>
                                <td colSpan={4} className="text-right">
                                    {isAdd && (
                                        <>
                                            <Button
                                                type="cancel"
                                                className="mg-r-10"
                                                loading={loadingAddOrderItem}
                                                onClick={this.onCancelAddOrderItem}
                                            >{t('button.cancel')}</Button>
                                            <Button
                                                type="primary"
                                                loading={loadingAddOrderItem}
                                                onClick={this.onSubmitAddOrderItem}
                                            >{t('button.update')}</Button>
                                        </>
                                    )}
                                </td>
                            </tr>
                        )}

                        {isShowAddGiftBtn && (
                            <tr>
                                <td colSpan={6}>
                                    <Button
                                        size={'small'}
                                        type={'link'}
                                        onClick={() => this.handleAddGiftModalVisibleChange(true)}
                                    ><Icon type="gift"/> Add Gift</Button>
                                </td>
                            </tr>
                        )}

                        <tr>
                            <td colSpan={4} className="no-border-bottom text-right">
                                <span className="_total_amount_label">{t('order_detail.total_amount')}</span>
                            </td>
                            <td colSpan={2} className="no-border-bottom">
                                <span
                                    className="text-blue _total_amount_value">{formatCurrency(totalAmount)} {currencyUnit}</span>
                            </td>
                        </tr>

                        <tr>
                            <td colSpan={4} className="no-border-bottom text-right">
                                <span className="_delivery_fee_label">{t('order.delivery_fee')}</span>
                            </td>
                            <td colSpan={2} className="no-border-bottom">
                                {isEditDeliveryFee ? (
                                    <React.Fragment>
                                        <Input
                                            value={_.get(order, 'deliveryFee')}
                                            style={{width: "80%"}}
                                            onChange={this.props.onChangeOrderField.bind(this, 'deliveryFee')}
                                            onPressEnter={this.handleUpdateDeliveryFee}
                                        />
                                        <Icon
                                            type={"check"}
                                            className="icon-quick-edit icon-check-quick-edit _icon_check_quick_edit_delivery_fee"
                                            style={{marginLeft: 3}}
                                            onClick={this.handleUpdateDeliveryFee}
                                        />
                                        <Icon
                                            type={"close"}
                                            className="icon-quick-edit icon-close-quick-edit _icon_close_quick_edit_delivery_fee"
                                            onClick={this.toggleEditDeliveryFee}
                                        />
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                            <span
                                                className="text-blue _delivery_fee_value"
                                            >
                                                {_.get(order, 'deliveryFee') ? formatCurrency(order.deliveryFee) : 0} {currencyUnit}
                                            </span>
                                        <Icon
                                            type={"edit"}
                                            className={"icon-quick-edit icon-close-quick-edit pointer"}
                                            onClick={this.toggleEditDeliveryFee}
                                        />
                                    </React.Fragment>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={4} className="no-border-bottom text-right">
                                <span className="_collected_cod_label">Total payment</span>
                            </td>
                            <td colSpan={2} className="no-border-bottom">
                                <InputNumber
                                    min={0}
                                    value={_.get(order, 'collectedCod')}
                                    style={{width: "80%"}}
                                    parser={value => replaceString(value)}
                                    formatter={value => formatQuantity(value)}
                                    onChange={this.props.onChangeOrderField.bind(this, 'collectedCod')}
                                    onPressEnter={this.handleUpdateCollectedCod}
                                />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                {showAddGiftModal && (
                    <AddGiftModal
                        {...this.props}
                        order={order}
                        fetchOrder={this.props.fetchOrder}
                        onVisibleChange={this.handleAddGiftModalVisibleChange}
                    />
                )}
            </Spin>
        )
    }
}

export {Product};