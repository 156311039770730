import EagleClient from "../utils/api/EagleClient";
import * as moment from "moment/moment";

export default class ContactService {
    static countContact(filter, cb) {
        EagleClient.request({
            endpoint: "/PhoneContacts/count",
            queryString: filter,
            method: "GET",
            mode: "free"
        }, cb);
    }

    static countNewContactToday(cb) {
        let start = moment().format("YYYY-MM-DD 00:00:00");
        let end = moment().format("YYYY-MM-DD 23:59:59");
        let filter = {
            where: {
                and: [
                    {createdAt: {gt: start}},
                    {createdAt: {lt: end}}
                ]
            }
        };
        return this.countContact(filter, cb);
    }

    static countNewContactYesterday(cb) {
        let start = moment().subtract(1, 'days').format("YYYY-MM-DD 00:00:00");
        let end = moment().subtract(1, 'days').format("YYYY-MM-DD 23:59:59");
        let filter = {
            where: {
                and: [
                    {createdAt: {gt: start}},
                    {createdAt: {lt: end}}
                ]
            }
        };
        return this.countContact(filter, cb);
    }

    static countNewCallToday(cb) {
        let start = moment().format("YYYY-MM-DD 00:00:00");
        let end = moment().format("YYYY-MM-DD 23:59:59");
        let filter = {
            where: {
                and: [
                    {callTime: {gt: start}},
                    {callTime: {lt: end}}
                ]
            }
        };
        return this.countContact(filter, cb);
    }

    static countNewCallYesterday(cb) {
        let start = moment().subtract(1, 'days').format("YYYY-MM-DD 00:00:00");
        let end = moment().subtract(1, 'days').format("YYYY-MM-DD 23:59:59");
        let filter = {
            where: {
                and: [
                    {callTime: {gt: start}},
                    {callTime: {lt: end}}
                ]
            }
        };
        return this.countContact(filter, cb);
    }

    /**
     * Get customer detail
     * @param filter
     * @param phoneContactId
     * @param cb
     */
    static getCustomerDetail(filter, phoneContactId, cb) {
        EagleClient.request({
            endpoint: `/PhoneContacts/${phoneContactId}`,
            method: 'GET',
            queryString: {filter: filter},
            mode: "free"
        }, cb);
    }

    /**
     * Add event
     * @param phoneContactId
     * @param data
     * @param cb
     */

    static addEvent(phoneContactId, data, cb) {
        EagleClient.request({
            endpoint: `/PhoneContacts/${phoneContactId}/addEvent`,
            method: 'POST',
            body: data
        }, cb);
    }

    static getAds(phoneContactId, cb) {
        let filter = {
            order: "id DESC"
        };
        filter = encodeURIComponent(JSON.stringify(filter));
        EagleClient.request({
            endpoint: `/PhoneContacts/${phoneContactId}/ads?filter=${filter}`,
            method: 'GET',
            mode: 'free'
        }, cb);
    }

    /**
     * lấy lịch sử cuộc gọi
     * @param phoneContactId
     * @param cb
     */
    static getCallHistory(phoneContactId, cb) {
        let filter = {
            where: {phoneContactId: phoneContactId},
            order: "callTime DESC",
            include: ["updatedUser", "callStatus", "ad", 'creator']
        };
        EagleClient.request({
            endpoint: `/CallHistories`,
            method: 'GET',
            queryString: {filter: filter}
        }, cb);
    }

    /**
     * Lấy timeline call history của 1 contact
     * @param phoneContactId
     * @param cb
     */
    static getCallHistoriesTimeLines(phoneContactId, cb) {
        let filter = {
            where: {phoneContactId: phoneContactId},
            order: "callTime DESC",
            include: ["updatedUser", "callStatus"]
        };
        EagleClient.request({
            endpoint: `/CallHistories/timeLines`,
            method: 'GET',
            queryString: {filter: filter}
        }, cb);
    }

    /**
     * lấy danh sách contact status
     * @param cb
     */
    static getContactStatus(cb) {
        let filter = {
            order: "code ASC"
        };
        EagleClient.request({
            endpoint: `/ContactStatuses`,
            method: 'GET',
            queryString: {filter: filter}
        }, cb);
    }

    /**
     * Tìm contact status theo id
     * @param contactId
     * @param cb
     */
    static getContactStatusById(contactId,cb){
        let filter = {
            order: "code ASC"
        };
        EagleClient.request({
            endpoint: `/ContactStatuses/${contactId}`,
            method: 'GET'
        }, cb);
    }

    /**
     * Lấy danh sách trạng thái cuộc gọi
     * @param cb
     */
    static callStatuses(cb){
        EagleClient.request({
            endpoint: `CallStatuses`,
            method: 'GET'
        }, cb);
    }

    /**
     * thay đổi trạng thái contact
     * @param phoneContactId
     * @param data
     * @param cb
     */
    static updateContactStatus(phoneContactId,data,cb){
        EagleClient.request({
            endpoint: `PhoneContacts/${phoneContactId}/status`,
            method: 'PATCH',
            body: data
        }, cb);
    }

    /**
     * thay đổi ngày hẹn giao contact
     * @param phoneContactId
     * @param data
     * @param cb
     */
    static updateContactDelivery(phoneContactId,data,cb){
        EagleClient.request({
            endpoint: `PhoneContacts/${phoneContactId}/deliveryDate`,
            method: 'PATCH',
            body: data
        }, cb);
    }
    /**
     * Lấy thông tin đơn của 1 contact
     * @param contactId
     * @param cb
     */
    static getContactOrder(contactId, cb) {
        let filter = {
            where: {phoneContactId: contactId},
            include: ["orderItems", "product"],
            limit: 1,
            order: "createdAt desc"
        };
        EagleClient.request({
            endpoint: `/Orders`,
            method: 'GET',
            queryString: {filter: filter}
        }, cb);
    }


    /**
     * Lấy thông tin đơn của 1 contact theo mã quảng cáo
     * @param adsId
     * @param contactId
     * @param cb
     */
    static getContactOrderByAds(contactId,adsId, cb) {
        let filter = {
            where: {and: [{phoneContactId: contactId}, {adsId: adsId}]},
            include: ["orderItems", "product"],
            order: "createdAt desc"
        };
        EagleClient.request({
            endpoint: `/PhoneContacts/${contactId}/getOrders`,
            method: 'GET',
            mode: 'free',
            queryString:{filter: filter}
        }, cb);
    }

    /**
     * phân contact bằng tay
     * @param contactId
     * @param saleId
     * @param cb
     */
    static asignContactManual(contactId,saleId,cb){
        let body = {
            type: 'normal',
            phoneContactId: contactId,
            userIds: [saleId]
        };
        EagleClient.request({
            endpoint: `/PhoneContacts/assignContactToSale`,
            method: 'POST',
            body: body
        }, cb);
    }

    static assignContactAuto(contactQuantity, groupIds, vendorId, cb) {
        EagleClient.request({
            endpoint: 'PhoneContacts/assignContactToSale',
            method: 'POST',
            body: {contactQuantity, groupIds, vendorId, type: 'auto'},
            mode: EagleClient.REQUEST_MODE.ALL
        }, cb);
    };

    static getContactStatuses = (cb) => {
        EagleClient.request({
            endpoint: `/ContactStatuses`,
            method: 'GET',
        }, cb);
    };

    static importFile(data, cb) {
        let formData = new FormData();
        formData.append('file', data.file);
        EagleClient.request({
            endpoint: `/PhoneContacts/import?adsId=${data.adsId}`,
            method: "POST",
            file: formData
        }, cb);
    }

    /**
     * lấy lịch sử hành động của Contact
     * @param phoneContactId
     * @param cb
     */
    static getContactActivityHistory(phoneContactId,cb){
        EagleClient.request({
            endpoint: `PhoneContacts/${phoneContactId}/activityHistory`,
            method: 'GET'
        }, cb);
    }

    static updateContact(data, cb) {
        EagleClient.request({
            endpoint: "/PhoneContacts",
            method: "PATCH",
            mode: "free",
            body: data
        }, cb);
    }

    static requestContact(payload, cb) {
        EagleClient.request({
            endpoint: `PhoneContacts/request-contact`,
            method: "POST",
            body: payload
        }, cb);
    }

    static getAllContactOrder(contactId, cb) {
        let filter = {
            where: {and: [{phoneContactId: contactId}]},
            include: ["orderItems", "product"],
            order: "createdAt desc"
        };
        EagleClient.request({
            endpoint: `/PhoneContacts/${contactId}/getOrders`,
            method: 'GET',
            queryString:{filter: filter},
            mode: 'free'
        }, cb);
    }

    static getCountContactUnassignInGroups(data,cb){
        EagleClient.request({
            endpoint: `/PhoneContacts/count-contacts-unassign-in-groups`,
            method: "POST",
            body: data
        }, cb);
    }

    static getContacts(filter = {}, cb) {
        EagleClient.request({
            endpoint: `/PhoneContacts`,
            method: "GET",
            queryString: filter
        }, cb);
    }

    static requestToExportContact(payload, cb) {
        EagleClient.request({
            endpoint: `/PhoneContacts/request-export-contacts`,
            method: "POST",
            body: payload
        }, cb);
    }

    static deleteContact(id, cb) {
        EagleClient.request({
            endpoint: `/PhoneContacts/${id}`,
            method: "DELETE",
        }, cb);
    }

    static countAd(id, filter = {}, cb) {
        EagleClient.request({
            endpoint: `/PhoneContacts/${id}/count-ad`,
            method: "GET",
            queryString: {filter: filter}
        }, cb);
    }

    static getCallStatuses(cb) {
        EagleClient.request({
            endpoint: 'CallStatuses',
            method: 'GET',
            queryString: {filter: {order: 'key ASC'}}
        }, cb);
    }

    static createPhoneContacts(contact, cb) {
        EagleClient.request({
            endpoint: 'PhoneContacts',
            method: 'POST',
            body: contact,
            mode: EagleClient.REQUEST_MODE.ALL
        }, cb);
    }

    static exportOrder(filter, cb) {
        EagleClient.request({
            endpoint: 'PhoneContacts/exportFile',
            method: 'POST',
            body: {
                ...filter,
                type: 'csv',
                kind: 'order'
            },
            mode: EagleClient.REQUEST_MODE.ALL
        }, cb);
    }

    static getDemands(id, filter = {}, cb) {
        EagleClient.request({
            endpoint: `PhoneContacts/${id}/demands`,
            method: 'GET',
            queryString: {...filter}
        }, cb);
    }

}