import React, {Component} from 'react';
import {FormUpdateAdset} from "./FormUpdateAdset";

class PopupUpdateAdset extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    handleCancel = () => {
        this.props.showPopupUpdateAdset(false);
        this.formRef.props.form.resetFields();
    };

    handleUpdate = () => {
        const form = this.formRef.props.form;
        form.validateFields((err, values) => {
            if(!err) {
                this.props.onUpdateAdset(values);
            }
        });
    };

    saveFormRef = (formRef) => {
        this.formRef = formRef;
    };

    render() {
        return (
            <>
                <div>
                    <FormUpdateAdset
                        {...this.props}
                        wrappedComponentRef={this.saveFormRef}
                        onCancel={this.handleCancel}
                        onUpdate={this.handleUpdate}
                        campaigns={this.props.campaigns}
                    />
                </div>
            </>
        );
    }
}

export {PopupUpdateAdset}