import React from 'react';
import {Col, Row, Button, Table, Pagination, Icon, Popconfirm, Tooltip} from "antd";
import * as _ from 'lodash';

import UserService from "../../../services/UserService";
import {VENDORS_SCOPES} from "../../../utils/api/Scopes";
import VendorService from "../../../services/VendorService";
import {doCallBack, formatDate} from "../../../utils/helper";
import {AddUser} from "./AddUser";
import {toastr} from "../../../utils/toastr";
import {getLoggedId} from "../../../utils/AuthUtils";
import { FilterUser } from './FilterUser';

class User extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            users: [],
            currentPage: 1,
            pageCount: 1,
            pageSize: 25,
            totalItems: 0,
            loading: false,
            isShowPopupAddUser: false,
            isShowConfirmRemoveUser: {},
            keyword:''
        }
    }

    componentDidMount() {
        this.fetchUsers();
    }

    fetchUsers = (filter = {}, cb) => {
        const {vendorId} = this.props;
        this.setState({loading: true});
        VendorService.getUserInVendor(vendorId, filter, (error, response) => {
            this.setState({loading: false});
            if(!error) {
                this.setState({
                    users: response.list,
                    currentPage: response.metadata.currentPage,
                    totalItems: response.count,
                    pageSize: response.metadata.pageSize,
                    pageCount: response.metadata.pageCount
                });
            }
            doCallBack(cb, [error, response]);
        });
    };

    togglePopupAddUser = () => {
        this.setState({isShowPopupAddUser: !this.state.isShowPopupAddUser});
    };

    getFilter = (page, pageSize, keyword) => {
        const filter = {
            page: page ? page : this.state.currentPage, 
            page_size: pageSize ? pageSize : this.state.pageSize
        };

        if ( keyword !== '' ) {
            filter.username = keyword;
        } else {
            delete filter.username;
        }

        return filter;
    }

    onShowSizeChange = (page, pageSize) => {
        this.fetchUsers(this.getFilter(page, pageSize, this.state.keyword));
    };

    onChangePage = (page) => {
        this.fetchUsers(this.getFilter(page, this.state.pageSize, this.state.keyword));
    };

    onFilter = (keyword) => {
        this.setState({
            keyword: keyword
        })
        this.fetchUsers(this.getFilter(1, null, keyword));
    }

    removeUser = (user) => {
        const {vendorId, t} = this.props;
        this.setState({loading: true});
        VendorService.deleteUserFromVendor(vendorId, [user.id], (error, response) => {
            this.setState({loading: false});
            if(!error) {
                toastr.success(t('message.success'));
                let {isShowConfirmRemoveUser} = this.state;
                if(isShowConfirmRemoveUser.hasOwnProperty(user.id)) {
                    delete isShowConfirmRemoveUser[user.id];
                }
                this.setState({isShowConfirmRemoveUser});
                this.fetchUsers();
            }
        });
    };

    onPopconfirmRemoveUserVisibleChange = (user, visible) => {
        let isShowConfirmRemoveUser = {};
        if(visible) {
            isShowConfirmRemoveUser[user.id] = true;
        } else {
            isShowConfirmRemoveUser[user.id] = false;
        }
        this.setState({isShowConfirmRemoveUser});
    };

    onAddUser = (error, repsonse) => {
        this.togglePopupAddUser();
        this.fetchUsers();
    };

    render() {
        const {t, vendorId} = this.props;
        const {users, currentPage, pageCount, pageSize, totalItems, loading, isShowPopupAddUser, isShowConfirmRemoveUser} = this.state;
        const renderRoles = (text, record) => {
            return _.map(text, 'name').join(', ');
        };
        const renderCreatedAt = (text) => {
            return formatDate(text, true);
        };
        const renderEmail = (text) => {
            return (
                <Tooltip title={text}>
                    <span className="user-email">{text}</span>
                </Tooltip>
            )
        };
        const renderAction = (text, record) => {
            let visible = isShowConfirmRemoveUser[record.id] === true;
            if(UserService.isAllowed(VENDORS_SCOPES.VENDORS_ADD_USER) && getLoggedId() != record.id) {
                return (
                    <Popconfirm
                        onVisibleChange={this.onPopconfirmRemoveUserVisibleChange.bind(this, record)}
                        onConfirm={this.removeUser.bind(this, record)}
                        visible={visible}
                        title={t("group_user.confirm_delete")}
                        okText={t("popup-confirm.confirm")}
                        cancelText={t("popup-confirm.cancel")}
                    >
                        <Icon
                            type="delete"
                            className="pointer"
                            style={{color: "red"}}
                        />
                    </Popconfirm>
                )
            }
        };
        const columns = [
            {title: t("vendor.username"),dataIndex: 'username', key: 'username',className:"_username _item", width: 200},
            {title: t("vendor.email"),dataIndex: 'email', key: 'email', render: renderEmail, className:"_email _item", width: 200},
            {title: t("vendor.created_by"),dataIndex: 'created_by', key: 'created_by',className:"_created_by _item", width: 200},
            {title: t("vendor.created_at"),dataIndex: 'created_at', key: 'created_at', render: renderCreatedAt, className:"_created_at _item", width: 200},
            {title: "--",dataIndex: 'action', key: 'action', render: renderAction, className:"_action _item", width: 200},
        ];
        return (
            
            <div className="user-container">
                <div className="vendor-list-page list-page">
                    <FilterUser
                        {...this.props}
                        {...this.state}
                        onFilter={this.onFilter}
                    />
                </div>
                <Row gutter={20} style={{marginBottom: 15}}>
                    <Col span={5}>
                        {!loading && !!totalItems && (
                            <span>{t("contact.page")} {currentPage}/{pageCount} ({totalItems})</span>
                        )}

                        {!loading && !totalItems && (
                            <span>{t("contact.page")} {1}/{1} ({totalItems})</span>
                        )}
                    </Col>
                    <Col span={19}>
                        <div className="text-right">
                            {UserService.isAllowed(VENDORS_SCOPES.VENDORS_ADD_USER) && (
                                <Button type="primary" onClick={(e) => this.togglePopupAddUser()}>{t('vendor.add_user')}</Button>
                            )}
                        </div>
                    </Col>
                </Row>

                <Table
                    className="table-user"
                    columns={columns}
                    dataSource={users}
                    rowKey={record => record.id}
                    pagination={false}
                    loading={loading}
                    locale={{
                        emptyText: t("table.empty")
                    }}
                    scroll={{x: 1400}}
                />
                <Pagination
                    className="mg-t-15 text-al-r"
                    showSizeChanger
                    onShowSizeChange={this.onShowSizeChange}
                    onChange={this.onChangePage}
                    pageSizeOptions={['25', '50', '75', '100']}
                    defaultCurrent={1}
                    current={currentPage}
                    pageSize={pageSize}
                    total={totalItems}
                />

                {UserService.isAllowed(VENDORS_SCOPES.VENDORS_ADD_USER) && isShowPopupAddUser && (
                    <AddUser
                        {...this.props}
                        {...this.state}
                        vendorId={vendorId}
                        onCancel={(e) => this.togglePopupAddUser()}
                        onSubmit={this.onAddUser}
                    />
                )}
            </div>
        )
    }
}

export {User};