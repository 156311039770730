import React, {Component} from 'react';
import {
    Modal, Form, Table, Select, Empty, Spin, InputNumber, Input
} from 'antd';

const Option = Select.Option;

class FormCreateProduct extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isShowTable: false,
            productSku: []
        };
    }

    onSelectProduct = (data) => {
        if(this.props.listProductInWarehouse.length){
            let products = this.props.listProductInWarehouse;
            let value = "";
            for(let i = 0; i < products.length; i++){
                if(products[i].id === data.key) {
                    value = products[i].productSku;
                }
            }

            this.setState({
                isShowTable: true,
                productSku: value
            })
        }
    };

    getConfigTableColumns = () => {
        const {t} = this.props;
        const {getFieldDecorator} = this.props.form;
        const renderColumn = (key, text, record) => {
            switch (key) {
                case 'quantity':
                    return (
                        <Form.Item style={{marginBottom: 0}}>
                            {getFieldDecorator(`${record.sku}[quantity]`)(
                                <InputNumber
                                    min={1}
                                    max={999999}
                                    className="_product_quantity"
                                    style={{width: '100%'}}
                                    parser={value => value.replace(/\D+/gm, '')}
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                />
                            )}
                        </Form.Item>
                    );
                case 'price':
                    return (
                        <Form.Item style={{marginBottom: 0}}>
                            {getFieldDecorator(`${record.sku}[price]`)(
                                <InputNumber
                                    step={0}
                                    min={1}
                                    max={999999}
                                    className="_product_price"
                                    style={{width: '100%'}}
                                    parser={value => value.replace(/\D+/gm, '')}
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                />
                            )}
                        </Form.Item>
                    );

                case 'sku':
                    return (
                        <Form.Item style={{marginBottom: 0}}>
                            {getFieldDecorator(`${record.sku}[sku]`,{
                                initialValue: record.sku
                            })(
                                <span>{record.sku}</span>
                            )}
                        </Form.Item>
                    );

            }
        };
        return [
            {title: t("products.properties"),dataIndex: 'display', key: 'display',className:"_properties _item", width:150},
            {title: "SKU",dataIndex: 'sku', key: 'sku',className:"_sku _item", width:200, render: renderColumn.bind(this, 'sku')},
            {title: t("ads.quantity"),dataIndex: 'quantity', key: 'quantity',className:"_quantity _item", width:150, render: renderColumn.bind(this, 'quantity')},
            {title: t("ads.price"),dataIndex: 'price', key: 'price',className:"_price _item", width:200, render: renderColumn.bind(this, 'price')},
        ];
    };

    closeTable = () => {
        this.setState({
            isShowTable: false
        })
    };

    onSearchProduct = (value) => {
        this.props.onFilterProduct({
            name: value
        });
    };

    onFocus = () => {
        this.props.resetDataSource();
    };

    render() {
        const {
            isShowPopUpCreateProduct, onCancel, onCreate, form,t,listProductInWarehouse,loadingProductInWarehouse
        } = this.props;
        const {
            isShowTable, productSku
        } = this.state;
        const { getFieldDecorator } = form;
        const columns = this.getConfigTableColumns();
        return (
            <Modal
                className="modal-create-product-receipt"
                visible={isShowPopUpCreateProduct}
                title={t("stock_receipts_detail.add_propduct")}
                onCancel={onCancel}
                onOk={onCreate}
                okButtonProps={{
                    className: "_create-product-save"
                }}
                cancelButtonProps={{
                    className: "_create-product-cancel",
                }}
                cancelText={t("button.cancel")}
                okText={t("button.save")}
                destroyOnClose={true}
                width={800}
                maskClosable={false}
            >
                <Form layout="vertical">
                    <Form.Item>
                        {getFieldDecorator('productInWarehouse', {
                            rules: [{ required: true, message: t("project.validate_product") }],
                        })(
                            <Select
                                filterOption={false}
                                showSearch
                                notFoundContent={loadingProductInWarehouse ? <div style={{textAlign:"center",}} className="mg-t-50 mg-bt-50"><Spin size="small"/> </div>:(<Empty description={t("table.empty")} />)}
                                placeholder={t("stock_receipt.select_product")}
                                className="_select-create-product"
                                onSelect={this.onSelectProduct}
                                onSearch={this.onSearchProduct}
                                onFocus={this.onFocus}
                                labelInValue
                            >
                                {listProductInWarehouse.length && listProductInWarehouse.map((item, index) => (
                                    <Select.Option key={index} value={item.id} className="_option-products">{item.name}</Select.Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>

                    {isShowTable && !!this.state.productSku.length && (
                        <Table
                            columns={columns}
                            dataSource={productSku}
                            rowKey={record => `table_sku_${record.sku}`}
                            locale={{
                                emptyText: t("table.empty")
                            }}
                            pagination={false}
                            scroll={{y: 400}}
                        />
                    )}
                </Form>
            </Modal>
        );
    }
}

FormCreateProduct = Form.create()(FormCreateProduct);
export {FormCreateProduct}