import React from 'react';
import {Checkbox} from 'antd';
import * as _ from 'lodash';
import UserService from "../../../services/UserService";
import {VENDORS_SCOPES} from "../../../utils/api/Scopes";

class SelectRole extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: []
        };
    }

    static getDerivedStateFromProps(nextProps) {
        // Should be a controlled component.
        if ('value' in nextProps && Array.isArray(nextProps.value)) {
            return {
                value: nextProps.value
            }
        }
        return null;
    }

    triggerChange = changedValue => {
        // Should provide an event to pass value to Form.
        const onChange = this.props.onChange;
        if (onChange) {
            onChange(changedValue);
        }
    };

    onChange = (item, checkedValue) => {
        if(UserService.isAllowed(VENDORS_SCOPES.VENDORS_UPDATE_ROLES)) {
            let {value} = this.state;
            let exist, existIndex;
            for (let i in value) {
                let v = value[i];
                if (v.code === item.code) {
                    exist = true;
                    existIndex = i;
                    break;
                }
            }

            if (exist && existIndex >= 0) {
                value.splice(existIndex, 1);
            } else {
                value.push(item);
            }

            this.setState({value});
            this.triggerChange(value);
        }
    };

    onCheckAll = () => {
        if(UserService.isAllowed(VENDORS_SCOPES.VENDORS_UPDATE_ROLES)) {
            const {roles} = this.props;
            let {value} = this.state;

            if (value.length === roles.length) {
                value = [];
            } else {
                value = _.clone(roles);
            }

            this.setState({value});
            this.triggerChange(value);
        }
    };

    render() {
        const {t, roles} = this.props;
        const {value} = this.state;
        const chunkRoles = _.chunk(roles, 2);
        const checkedItems = _.map(value, 'code');
        const checkedAll = checkedItems.length === roles.length;
        let notAllowEditClass = UserService.isAllowed(VENDORS_SCOPES.VENDORS_UPDATE_ROLES) ? "" : ' not-allowed';
        return (
            <span>
                <div className="chunk-role-container check-all">
                    <Checkbox
                        onChange={this.onCheckAll}
                        checked={checkedAll}
                        className={`checkbox-role ${notAllowEditClass}`}
                    >
                        {t('checkbox.all')}
                    </Checkbox>
                </div>
                {chunkRoles.map((arr) => (
                    <div className="chunk-role-container" key={_.uniqueId()}>
                        {arr.map((item) => (
                            <Checkbox
                                className={`checkbox-role _checkbox_role _checkbox_role_${item.code} ${notAllowEditClass}`}
                                onChange={this.onChange.bind(this, item)}
                                key={item.code}
                                checked={checkedItems.indexOf(item.code) >= 0}
                            >
                                <span className="role-name">{item.name}</span>
                                <i className="role-code">{item.code}</i>
                            </Checkbox>
                        ))}
                    </div>
                ))}
            </span>
        )
    }
}

SelectRole.defaultProps = {
    roles: [],
    onChange: (value) => {}
};

export {SelectRole};