import React from 'react';
import {Table, Pagination, Row, Col, Icon} from 'antd';

class List extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

     NestedTable = (record) => {
         let idRow = record.id;
         const {t} = this.props;
         const columns = [
             {title: '', dataIndex: 'code_empty', key: 'code_empty',width: '15%',className:"_code_empty _item"},
             {title: t("products.variant"), dataIndex: 'properties', key: 'properties',width: '25%',className:"_properties _item"},
             {title: 'SKU', dataIndex: 'sku', key: 'sku',width: '20%',className:"_sku _item"},
             {title: '', dataIndex: 'qualityInStock', key: 'qualityInStock',width: '20%',className:"_qualityInStock _item"},
             {title: '', dataIndex: 'invoices', key: 'invoices',width: '20%',className:"_invoices _item"},
         ];

         const data = [];
         if(this.props.items.length){
             let items = this.props.items;
             for (let j = 0; j < items.length; j++) {
                 for (let i = 0; i < items[j].skus.length; i++) {
                     if(items[j].id === idRow){
                         data.push({
                             properties: items[j].skus[i].properties,
                             sku: items[j].skus[i].sku,
                             qualityInStock: items[j].skus[i].qualityInStock,
                             invoices: items[j].skus[i].invoices
                         })
                     }
                 }
             }
         }

         return (
             <Table
                 columns={columns}
                 dataSource={data}
                 pagination={false}
                 rowKey={record => `second_table_${record.id}`}
             />
         );
     };

    getConfigTableColumns = () => {
        const {t} = this.props;
        const renderColumn = (key, text, record) => {
            switch (key) {
                case 'totalQtyInStock':
                    return (
                        this.setColor(record.totalQtyInStock)
                    );
                case 'totalInvoices':
                    return (
                        this.setColor(record.totalInvoices)
                    );

            }
        };
        return  [
            {title: t('warehouse.product_code'), dataIndex: 'code', key: 'code', className:"_code _item",width: '15%'},
            {title: t('products.product'), dataIndex: 'name', key: 'name', className:"_product _item",width: '25%'},
            {title: '', dataIndex: 'sku_empty', key: 'sku_empty', className:"_sku_empty _item",width: '20%'},
            {title: t('products.instock'), dataIndex: 'totalQtyInStock', key: 'totalQtyInStock', className:"_totalQtyInStock _item",width: '20%',render: renderColumn.bind(this, 'totalQtyInStock')},
            {title: t('products.invoices'), dataIndex: 'totalInvoices', key: 'totalInvoices', className:"_totalInvoices _item",width: '20%',render: renderColumn.bind(this, 'totalInvoices')},
        ];
    };

    setColor = (value) => {
        if(value > 0){
            return <span style={{color:"green"}}>{value}</span>
        }else if(value < 0){
            return <span style={{color:"red"}}>{value}</span>
        }else if(value === 0){
            return <span style={{color:"orange"}}>{value}</span>
        }
    };

    render() {
        const {t, loading, items, totalItems, onShowSizeChange, onChangePage, currentPage, pageSize, pageCount} = this.props;
        const columns = this.getConfigTableColumns();
        return (
            <div className="issue-list-table">
                <Row className="pd-bt-15">
                    <Col span={5}>
                        {!loading && !!totalItems && (
                            <span>{t("contact.page")} {currentPage}/{pageCount} ({totalItems})</span>
                        )}

                        {!loading && !totalItems && (
                            <span>{t("contact.page")} {1}/{1} ({totalItems})</span>
                        )}
                    </Col>
                </Row>

                <Table
                    columns={columns}
                    dataSource={items}
                    rowKey={record => record.id}
                    pagination={false}
                    loading={loading}
                    locale={{
                        emptyText: t("table.empty")
                    }}
                    expandedRowRender={this.NestedTable}
                />

                {!loading && totalItems > 0 && (
                    <Pagination
                        className="mg-t-15 text-right"
                        showSizeChanger
                        onShowSizeChange={onShowSizeChange}
                        onChange={onChangePage}
                        pageSizeOptions={['25', '50', '75', '100', '150', '200']}
                        defaultCurrent={1}
                        current={currentPage}
                        pageSize={pageSize}
                        total={totalItems}
                    />
                )}
            </div>
        )
    }
}

export {List}