import React, {Component} from 'react';
import {translate} from "react-i18next";
import StandardLayout from "../../components/StandardLayout";
import {Filter} from "./Filter";
import {List} from "./List";
import ReportService from "../../services/ReportService";
import {REPORT_SCOPES} from "../../utils/api/Scopes";
import {Header} from "./Header";
import UserService from "../../services/UserService";
import {getParamsAsObject, getUrlQueryString,getCurrentModule} from "../../utils/helper";
import {Url} from "../../utils/Url";
import {LocalStore} from "../../utils/LocalStore";

class Vendor extends Component {

    constructor(props) {
        super(props);
        this.mounted = true;
        this.moduleName = 'statistics-list';
        this.state = {
            dataSource: [],
            currentPage: 1,
            totalItems: 50,
            pageSize: 25,
            loading: true,
            pageCount: 0,
            countries: [],
            users: [],
            vendorId : LocalStore.getInstance().read('vendor').id
        };
    }


    componentWillMount() {
        if (!UserService.isAllowed(REPORT_SCOPES.REPORT_VIEW)) {
            this.props.history.replace('/')
        }
    };

    componentDidMount = () => {
        this.mounted = true;
        window.addEventListener("popstate", this.onPopstate.bind(this));
        this.loadData();

    };

    componentWillUnmount = () => {
        this.mounted = false;
        window.removeEventListener("popstate", this.onPopstate.bind(this));
    };

    onPopstate() {
        // Url thay đổi thì chỉ load lại list
        if(this.mounted && getCurrentModule() === this.moduleName){
            let queryObject = getParamsAsObject(getUrlQueryString());
            this.loadReport(queryObject);
        }
    };

    callApiGetUser = (cb) => {
        const localStore = LocalStore.getInstance();
        let loginSession = localStore.read("loginSession");
        UserService.getListUserOfTheSameVendors(loginSession.userId, (error, response) => {
            if(!error) {
                this.setState({
                    users: response.list
                });
            }
            if(typeof cb === 'function') cb(error, response);
        });
    };


    loadData = () => {
        let queryObject = getParamsAsObject(getUrlQueryString());
        this.loadReport(queryObject);
        this.callApiGetUser();
    };

    loadReport = (filter = {}) => {
        if(!this.state.loading){
            this.setState({
                loading: true
            })
        }
        let defaultFilter = {
            include: ['vendor'],
            order: ["createdAt DESC"],
            page: filter.page || 1,
            page_size: filter.page_size || 25,
            vendor_id:this.state.vendorId
        };

        if(Object.keys(filter).length) {
            if(filter.name){
                filter.name = filter.name.trim();
            }
            defaultFilter = Object.assign(defaultFilter, filter);
        }

        ReportService.getAllReport(defaultFilter, (error, response) => {
            if(!error) {
                this.setState({
                    dataSource: response.list,
                    currentPage: response.metadata.currentPage,
                    totalItems: response.count,
                    pageSize: response.metadata.pageSize,
                    pageCount: response.metadata.pageCount,
                    loading: false
                })
            }
        });

    };

    onShowSizeChange = (current, pageSize) => {
        let uri = this.getUrlInstance();
        uri.replaceParam('page', 1);
        uri.replaceParam('page_size', pageSize);
        this.pushQueryString(uri.getQueryString());
    };

    onChangePage = (page, pageSize) => {
        let uri = this.getUrlInstance();
        uri.replaceParam('page', page);
        uri.replaceParam('page_size', pageSize);
        this.pushQueryString(uri.getQueryString());
    };

    getUrlInstance = () => {
        return new Url('?'+getUrlQueryString());
    };

    pushQueryString = (queryString) => {
        this.props.history.push(`/statistics-list${queryString}`);
    };

    render() {
        const {t} = this.props;
        const {dataSource,currentPage, pageSize,pageCount,totalItems,users} = this.state;
        return (
            <StandardLayout {...this.props} title={t("statistic.list")}>
                <div className="statistics-list-page list-page">
                    <Header {...this.props} />
                    <Filter
                        {...this.props}
                        users={users}
                        loadReport={this.loadReport}
                        pageSize={pageSize}
                        loading={this.state.loading}
                        getUrlInstance={this.getUrlInstance}
                        pushQueryString={this.pushQueryString}
                    />
                    <List
                        {...this.props}
                        dataSource={dataSource}
                        currentPage={currentPage}
                        pageSize={pageSize}
                        pageCount={pageCount}
                        onChangePage={this.onChangePage}
                        onShowSizeChange={this.onShowSizeChange}
                        loading={this.state.loading}
                        loadReport={this.loadReport}
                        totalItems={totalItems}
                        getUrlInstance={this.getUrlInstance}
                        pushQueryString={this.pushQueryString}
                    />
                </div>
            </StandardLayout>
        );
    }
}

export default (translate())(Vendor)