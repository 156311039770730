import React from 'react';
import {Breadcrumb as AntBreadCrumb, Button, Icon} from 'antd';
import {BreadCrumb} from "../../components/BreadCrumb";
import {Link} from "react-router-dom";
import UserService from "../../services/UserService";
import {CONTACT_SCOPES} from "../../utils/api/Scopes";

class Header extends React.Component {

    render() {
        const {t, togglePopupImportContact, togglePopupCreateContact} = this.props;
        const leftContent = (
            <AntBreadCrumb>
                <AntBreadCrumb.Item>
                    <Link to={"/"}>{t('page.home')}</Link>
                </AntBreadCrumb.Item>
                <AntBreadCrumb.Item>{t("contact.contact_customer")}</AntBreadCrumb.Item>
            </AntBreadCrumb>
        );

        let rightContent;
        if(UserService.isAllowed(CONTACT_SCOPES.CONTACT_CREATE)) {
            rightContent = (
                <Button.Group>
                    <Button type="primary" onClick={togglePopupCreateContact} className="_btn_toggle_popup_create_contact">{t("contact.add_contact")}</Button>
                    <Button type="primary" onClick={togglePopupImportContact} className="_btn_toggle_popup_import_contact">
                        <Icon type={"cloud-upload"} />
                    </Button>
                </Button.Group>
            );
        }

        return (
            <BreadCrumb
                {...this.props}
                leftContent={leftContent}
                rightContent={rightContent}
            />
        );
    }
}

export {Header};