import {notification} from 'antd';

class toastr {
    static success = (message) => {
        notification.success({message: message});
    };

    static error = (message) => {
        notification.error({message: message});
    };

    static warn = (message) => {
        notification.warn({message: message});
    };

    static warning = (message) => {
        notification.warning({message: message});
    };
}

export {toastr}