import React from 'react';
import {Input, Checkbox, Spin, Button, InputNumber} from "antd";
import * as _ from 'lodash';
import "./style.scss";
import {doCallBack} from "../../../utils/helper";
import {toastr} from "../../../utils/toastr";

class AutoAssignContact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            groups: [],
            quantity: 0,
            countContactUnassign: 0,
            loadingGroup: false,
            groupSelected: {},
            submitting: false
        };
        this.onSearch = _.debounce(this.onSearch, 400);
    }

    componentDidMount() {
        this.fetchGroups();
    }

    fetchGroups = (filter = {}, cb) => {
        this.setState({loadingGroup: true});
        this.props.fetchGroups(filter, (error, response) => {
            this.setState({loadingGroup: false});
            if(!error) {
                this.setState({
                    groups: response.list
                });
            } else {
                this.setState({groups: []});
            }
            doCallBack(cb, [error, response]);
        });
    };

    onSearch = (e) => {
        let keyword = e.target.value || "";
        this.fetchGroups({name: keyword}, () => {
            this.setState({groupSelected: {}});
        });
    };

    onChangeGroup = (group, e) => {
        this.setState({
            groupSelected: group,
            quantity: 0
        });

        // Fetch api count contact unassign
        this.props.countContactUnassign([group.id], (error, response) => {
            if(!error) {
                this.setState({
                    countContactUnassign: response.count
                });
            }
        });
    };

    onChangeQuantity = (number) => {
        this.setState({quantity: number});
    };

    onSubmit = (e) => {
        // this.props.onAssigned(error, response);
        this.setState({submitting: true});
        const {t} = this.props;
        const {quantity, groupSelected} = this.state;
        const groupId = groupSelected.id;
        this.props.assignContactAuto(quantity, groupId, (error, response) => {
            this.setState({submitting: false});
            if (error) {
                if (error.code === 'INVALID_PHONE') {
                    toastr.error(t('contact.invalid_phone'));
                }
                else if (error.code === 'CONTACT_IS_NOT_ENOUGH') {
                    toastr.error(t('contact.is_not_enough'));
                }
                else {
                    toastr.error(error.message);
                }
            }
            else if (response) {
                // noinspection JSUnresolvedFunction
                toastr.success(t('message.success'));
            }
            this.props.onAssigned(error, response);
        });
    };

    render() {
        const {t} = this.props;
        const {groups, loadingGroup, groupSelected, quantity, countContactUnassign, submitting} = this.state;
        let renderGroups = (
            <Spin spinning={loadingGroup}>
                {Array.isArray(groups) && groups.map((item) => (
                    <div key={item.id}>
                        <Checkbox
                            checked={groupSelected.id === item.id}
                            className={`group-item _auto_assign_checkbox_group _auto_assign_checkbox_group_${item.id}`}
                            onChange={this.onChangeGroup.bind(this, item)}
                        >
                            <span>{item.name} <span className="count-member">{item.countMember}</span></span>
                        </Checkbox>
                    </div>
                ))}
            </Spin>
        );

        return (
            <div className="auto-assign-contact _popup_auto_assign_contact">
                {groupSelected.id > 0 && (
                    <div className="input-quantity-container">
                        <span>{countContactUnassign} {t('contact.contact_not_assign')}</span>
                        <div className="flr">
                            <InputNumber
                                className="_input_quantity"
                                value={quantity}
                                min={0}
                                max={countContactUnassign}
                                onChange={this.onChangeQuantity}
                            />
                        </div>
                    </div>
                )}
                <div className="search-container">
                    <Input.Search
                        className="_input_search_group"
                        autoFocus={true}
                        allowClear={true}
                        onChange={(e) => {e.persist(); this.onSearch(e)}}
                    />
                </div>
                <div className="groups">
                    {renderGroups}
                </div>
                <div className="footer">
                    <Button
                        type="default"
                        className="_btn_cancel_auto_assign_contact mg-r-10"
                        onClick={this.props.onClose}
                    >
                        {t('button.cancel')}
                    </Button>
                    <Button
                        type="primary"
                        className="_btn_save_auto_assign_contact"
                        loading={submitting}
                        disabled={!quantity || !Object.keys(groupSelected).length}
                        onClick={this.onSubmit}
                    >
                        {t('button.save')}
                    </Button>
                </div>
            </div>
        );
    }
}

AutoAssignContact.defaultProps = {
    fetchGroups: (filter, cb) => {},
    onClose: (e) => {},
    onAssigned: (error, response) => {},
    countContactUnassign: (groupIds, cb) => {},
    assignContactAuto: (quantity, groupId, cb) => {}
};

export {AutoAssignContact};