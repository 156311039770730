import React from 'react';
import {Form, Input} from 'antd';

class QuickEditName extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    }

    onSubmit = (e) => {
        e.preventDefault();
        const {form, warehouse} = this.props;
        form.validateFields((error, values) => {
            if(!error) {
                this.setState({loading: true});
                let data = Object.assign(warehouse, values);
                if(data.name) data.name = data.name.toString().trim();
                if(data.address) data.address = data.address.toString().trim();
                if(data.description) data.description = data.description.toString().trim();
                this.props.updateWarehouse(data, (error, response) => {
                    this.setState({loading: false});
                    this.props.onUpdate(error, response);
                });
            }
        });
    };

    render() {
        const {loading} = this.state;
        const {t, warehouse} = this.props;
        const {getFieldDecorator} = this.props.form;
        return (
            <Form onSubmit={this.onSubmit}>
                <Form.Item>
                    {getFieldDecorator('name', {
                        rules: [
                            {required: true, message: t('message.required')},
                            {max: 160, message: t('error.length_160')},
                            {whitespace:true, message: t("message.required")}
                        ],
                        initialValue: warehouse.name
                    })(
                        <Input
                            disabled={loading}
                            onPressEnter={this.onSubmit}
                            style={{width: 250}} />
                    )}
                </Form.Item>
            </Form>
        )
    }
}

QuickEditName = Form.create()(QuickEditName);
export {QuickEditName};