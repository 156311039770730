import React, {Component} from 'react';
import {Button, Col, Empty, Form, Input, Select, Spin} from 'antd';
import * as _ from "lodash";
import {CourierService} from "../../services/CourierService";

const Option = Select.Option;

class FormVendorAdd extends Component {

    constructor(props) {
        super(props);
        this.state = {
            buttonStatus: false,
            formLoading: true,
            couriers: []
        };
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let that = this;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let fieldValue = this.props.form.getFieldsValue(['name','code','owner','packageId','countryId']);
                // Biến fieldValue lưu lại giá trị các trường người dùng nhập vào, nếu server trả về error thì dùng để resort value
                this.setState({
                    buttonStatus: true,
                    formLoading: false
                },function () {
                    let data = _.cloneDeep(values);
                    data.code = data.code.trim();
                    data.name = data.name.trim();

                    this.props.handleCreateVendor(data,()=>{that.changeButtonStatus(fieldValue)});
                });

            }
        });
    };

    changeButtonStatus = (value) => {
        this.setState({
            buttonStatus:false,
            formLoading:true
        },function () {
            this.props.form.setFieldsValue(value)
            // Nếu server bắt lỗi validate thì set lại giá trị cho form khi người dùng đã nhập trước đó
        })
    };

    resetFields = () => {
        this.props.form.resetFields();
    };

    handleChangeCountry = (value) => {

        this.props.form.setFieldsValue({
            courierIds: undefined
        });

        CourierService.getList({
            countryId: value
        }, (error, response) => {
            if (!error) {
                this.setState({
                    couriers: response.list
                })
            }
        })
    };

    render() {
        const {t} = this.props;
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: { span: 16, offset: 8, textalign: "initial" },
            wrapperCol: { span: 8, offset: 8 },
        };
        return (
            <>
                {this.state.formLoading?(<Form onSubmit={this.handleSubmit} className="pd-t-40 pd-bt-60 form-data">
                    <Col span={24} style={{textAlign:"center", marginBottom:20}}>
                        <span className="add-vendor-title">{t("vendor.create")}</span>
                    </Col>

                    <Form.Item
                        {...formItemLayout}
                        label={t("vendor.name")}
                        className="name _name"
                    >
                        {getFieldDecorator('name', {
                            rules: [{ required: true, message: t("vendor.input_name") },{max: 160, message: t("vendor.name_length")},{whitespace:true, message: t("vendor.input_name")}],
                        })(
                            <Input
                                autoFocus
                                placeholder={t("vendor.name_placeholder")}
                            />
                        )}
                    </Form.Item>

                    <Form.Item
                        {...formItemLayout}
                        label={t("vendor.code")}
                        className="code _code"
                    >
                        {getFieldDecorator('code', {
                            rules: [{ required: true, message: t("vendor.input_code") },{max: 10, message: t("vendor.code_length")},{whitespace:true, message: t("vendor.input_code")}],
                        })(
                            <Input
                                placeholder={t("vendor.code_placeholder")}
                            />
                        )}
                    </Form.Item>

                    <Form.Item
                        {...formItemLayout}
                        label={t("vendor.manager")}
                        className="manager _manager"
                    >
                        {getFieldDecorator('owner', {
                            rules: [
                                { required: true, message: t("vendor.select_manager_validate") },
                            ],
                        })(
                            <Select
                                notFoundContent={<Empty description={t("table.empty")} />}
                                showSearch
                                placeholder={t("vendor.select_manager")}
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                                {this.props.managers.map((item, index) => (
                                    <Select.Option key={index} value={item.username} className="_option-manager">{item.username}</Select.Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>

                    <Form.Item
                        {...formItemLayout}
                        label={t("vendor.package")}
                        className="package _package"
                    >
                        {getFieldDecorator('packageId', {
                            rules: [
                                { required: true, message: t("vendor.select_package_validate") },
                            ],
                        })(
                            <Select
                                notFoundContent={<Empty description={t("table.empty")} />}
                                showSearch
                                placeholder={t("vendor.select_package")}
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                                {this.props.package.map((item, index) => (
                                    <Select.Option key={index} value={item.id} className="_option-package">{item.name}</Select.Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>

                    <Form.Item
                        {...formItemLayout}
                        label={t("vendor.country")}
                        className="country _country"
                    >
                        {getFieldDecorator('countryId', {
                            rules: [
                                { required: true, message: t("vendor.select_country_validate") },
                            ],
                        })(
                            <Select
                                notFoundContent={<Empty description={t("table.empty")} />}
                                showSearch
                                placeholder={t("vendor.select_country")}
                                onChange={this.handleChangeCountry}
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                                {this.props.countries.map((item, index) => (
                                    <Select.Option key={index} value={item.id} className="_option-country">{item.name}</Select.Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>

                    <Form.Item
                        {...formItemLayout}
                        label={t("vendor.courier")}
                        className="courier _courier"
                    >
                        {getFieldDecorator('courierIds', {
                            rules: [
                                { required: true, message: t("vendor.select_courier_validate") },
                            ],
                        })(
                            <Select
                                notFoundContent={<Empty description={t("table.empty")} />}
                                showSearch
                                mode={"multiple"}
                                placeholder={t("vendor.select_courier")}
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                                {Array.isArray(this.state.couriers) && this.state.couriers.map((item, index) => (
                                    <Select.Option key={index} value={item.id} className="_option-courier">{item.name}</Select.Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>

                    <Form.Item
                        wrapperCol={{ span: 8, offset: 8}}
                        className={"text-al-r"}
                    >
                        <Button type="primary" htmlType="submit" disabled={this.state.buttonStatus}>{t("button.save")}</Button>
                        <Button className="mg-l-10" onClick={this.resetFields}>{t("button.cancel")}</Button>
                    </Form.Item>
                </Form>):(<div style={{textAlign:"center",marginTop:300}}><Spin tip="Loading..."/></div>)}
            </>
        );
    }
}

FormVendorAdd = Form.create({})(FormVendorAdd);
export {FormVendorAdd}