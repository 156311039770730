import EagleClient from "../utils/api/EagleClient";

export default class ProductService {
    static getProducts(filter, cb) {
        let endpoint = "/Products";
        EagleClient.request({
            endpoint: endpoint,
            method: "GET",
            mode: "free",
            queryString: filter
        }, cb);
    }

    static changeProductStatus(productId, cb){
        let endpoint = "/Products/"+productId+"/active";
        EagleClient.request({
            endpoint: endpoint,
            method: "POST",
            mode: "free",
        }, cb);
    }

    static getProductById(id, cb) {
        let endpoint = "/Products/"+id;
        EagleClient.request({
            endpoint: endpoint,
            method: "GET",
        }, cb);
    }

    /**
     * Get Product Sku
     * @param productId
     * @param cb
     */
    static getSku(productId, cb) {
        let endpoint = "/Products/"+productId+"/productSku";
        EagleClient.request({
            endpoint: endpoint,
            method: "GET",
            queryString: {filter:{include:"product"}}
        }, cb);
    }
    static getProductDetail(id, cb) {
        EagleClient.request({
            endpoint: `/Products/${id}`,
            method: "GET",
        }, cb);
    }

    static updateProductDetail(id, data, cb) {
        EagleClient.request({
            endpoint: `/Products/${id}`,
            method: "PATCH",
            body: JSON.stringify(data)
        }, cb);
    }

    static getProductFAQ(productId,filter, cb) {
        EagleClient.request({
            endpoint: `/Products/${productId}/productFaq`,
            queryString: filter,
            method: "GET",
        }, cb);
    }

    static postProductFAQ(productId, data, cb) {
        EagleClient.request({
            endpoint: `/Products/${productId}/productFaq`,
            method: "POST",
            body: JSON.stringify(data)
        }, cb);
    }

    static putProductFAQ(productId, fqaId,data, cb) {
        EagleClient.request({
            endpoint: `/Products/${productId}/productFaq/${fqaId}`,
            method: "PUT",
            body: JSON.stringify(data)
        }, cb);
    }

    /**
     * thêm product
     * @param data
     * @param cb
     */
    static createProduct(data, cb) {
        EagleClient.request({
            endpoint: `/Products`,
            method: "POST",
            body: data
        }, cb);
    }

    /**
     * Get users assign Product
     * @param filter
     * @param cb
     */
    static getProductUser(filter, cb) {
        EagleClient.request({
            endpoint: '/ProductUsers',
            method: 'GET',
            queryString: {filter: filter}
        }, cb);
    }

    /**
     * add users assign Product
     * @param data
     * @param cb
     */
    static addUserToProduct(data, cb) {
        EagleClient.request({
            endpoint: '/ProductUsers',
            method: 'POST',
            body: data
        }, cb);
    }
    /**
     * delete users assign Product
     * @param id
     * @param cb
     */
    static deleteUserFromProduct(id, cb) {
        EagleClient.request({
            endpoint: `/ProductUsers/${id}`,
            method: 'DELETE'
        }, cb);
    }
    /**
     * delete users assign Product
     * @param data
     * @param cb
     */
    static deleteUsersFromProduct(data, cb) {
        EagleClient.request({
            endpoint: `/ProductUsers/deleteUsers`,
            method: 'DELETE',
            body:data
        }, cb);
    }
    /**
     * Lấy danh sách property theo mã attrs
     * @param filter
     * @param cb
     */
    static getProperty(filter, cb) {
        EagleClient.request({
            endpoint: `/ProductProperties`,
            method: "GET",
            queryString:{
                filter:filter
            }
        }, cb);
    }

    /**
     * Ds user không được phân quyền vào sản phẩm
     * @param productId
     * @param filter
     * @param cb
     */
    static getUsersNotInProduct = (productId, filter = {}, cb) => {
        EagleClient.request({
            endpoint: `/Products/${productId}/users-not-in`,
            method: "GET",
            queryString:filter
        }, cb);
    };

    static deleteGroupFromProduct = (productId, payload, cb) => {
        EagleClient.request({
            endpoint: `/Products/${productId}/groups`,
            method: "DELETE",
            body: payload
        }, cb);
    };

    static addGroupToProduct = (productId, payload, cb) => {
        EagleClient.request({
            endpoint: `/Products/${productId}/groups`,
            method: "POST",
            body: payload
        }, cb);
    };

    static getGroupInProduct = (productId, filter, cb) => {
        EagleClient.request({
            endpoint: `/Products/${productId}/groups`,
            method: "GET",
            queryString: filter
        }, cb);
    };

    static getGroupNotInProduct = (productId, filter, cb) => {
        EagleClient.request({
            endpoint: `/Products/${productId}/groups-not-in`,
            method: "GET",
            queryString: filter
        }, cb);
    };

    static addVendors = (productId, vendorIds, cb) => {
        EagleClient.request({
            endpoint: `/Products/${productId}/vendors`,
            method: "POST",
            body: {
                vendorIds: vendorIds
            }
        }, cb);
    };

    static removeVendors = (productId, vendorIds, cb) => {
        let q = [];
        for (let vendorId of vendorIds) {
            q.push(`vendorIds[]=${vendorId}`);
        }
        EagleClient.request({
            endpoint: `/Products/${productId}/vendors?${q.join('&')}`,
            method: "DELETE",
        }, cb);
    };

    static getVendors = (productId, filter = {} , cb) => {
        EagleClient.request({
            endpoint: `/Products/${productId}/vendors`,
            method: "GET",
            queryString: filter
        }, cb);
    };

    static mappingM28Sku = (productId, data = [] , cb) => {
        EagleClient.request({
            endpoint: `/Products/${productId}/mapping-m28-sku`,
            method: "PATCH",
            body: data
        }, cb);
    };

    static getProductGroup = (cb) => {
        EagleClient.request({
            endpoint: `ProductGroups`,
            method: "GET"
        }, cb)
    }
}