import React, { Component } from 'react';
import { Col, Row } from 'antd';
import { SummaryEvent } from "./SummaryEvent";
import { SummaryProduct } from "./SummaryProduct";
import { SummaryInfo } from "./SummaryInfo";
import { CONTACT_STATUS } from "../../../utils/api/Consts";
import { Invoice } from "./Invoice";
import { SummaryOrderDuplicate } from './SummaryOrderDuplicate';

class Summary extends Component {
    render() {
        const { contact } = this.props;
        let isShowInvoice = [CONTACT_STATUS['L7.1'].id,
        CONTACT_STATUS['L8.1'].id, CONTACT_STATUS['L8.3'].id,
        CONTACT_STATUS['L6.1'].id, CONTACT_STATUS['L7.10'].id].indexOf(contact.contactStatusId) >= 0;
        return (
            <div className="summary-container">
                <Row gutter={30}>
                    <Col span={8} md={12}>
                        <div className="info-container">
                            <SummaryInfo {...this.props} />
                        </div>
                        <div className="product-container">
                            {isShowInvoice ? (
                                <Invoice {...this.props} />
                            ) : (
                                <SummaryProduct {...this.props} />
                            )}
                        </div>
                    </Col>
                    <Col span={16} md={12}>
                        <div className="call-histories-container">
                            <SummaryEvent {...this.props} />
                        </div>
                    </Col>
                    
                    <Col span={16} md={12} className={'flr'}>
                        <div className="product-container">
                            <SummaryOrderDuplicate {...this.props} />
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}

export { Summary };