import { API } from "../config/api";
import { LocalStore } from "../utils/LocalStore";

export default class StringeeService {
    static getAccessToken(vendorId){
        const { id } = LocalStore.getInstance().read("loginSession");

        return fetch(`${API.STRINGEE_BASE_URL}/call/access_token?vendor_id=${vendorId}`,{
            headers:{
                authorization: id
            }
        });
    }

    static getHistoryCall(page, pageSize, number, campaignCode, username = '', vendor){
        const { id } = LocalStore.getInstance().read("loginSession");

        let url = `${API.STRINGEE_BASE_URL}/call/history?page=${page || 1}&page_size=${pageSize || 50}&vendor_code=${vendor.code}&number=${number}&campaign_code=${campaignCode}`;

        if(username) {
            url += `&username=${username}`;
        }

        return fetch(url,{
            headers:{
                authorization: id
            }
        });
    }

    static getListNumber(page, limit, vendor){
        const { id } = LocalStore.getInstance().read("loginSession");

        let url = `${API.STRINGEE_BASE_URL}/projects/numbers?page=${page || 1}&limit=${limit || 999}&vendor_id=${vendor.id}`;
        return fetch(url,{
            headers:{
                authorization: id
            }
        })
    }

    static createProject(project){
        const url = `${API.STRINGEE_BASE_URL}/projects`;
        return fetch(url,{
            method: 'POST',
            body: JSON.stringify(project)
        })
    }

    static getListProject(){
        const url = `${API.STRINGEE_BASE_URL}/projects`;
        return fetch(url, {
            method: 'GET',
        })
    }
}