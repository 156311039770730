import React from 'react';
import {translate} from 'react-i18next';
import {Layout} from "../Layout";
import {doCallBack, getParamsAsObject, getUrlQueryString, getUrlSegment} from "../../../../utils/helper";
import {LocalStore} from "../../../../utils/LocalStore";
import * as _ from "lodash";
import WareHouseService from "../../../../services/WareHouseService";
import {Url} from "../../../../utils/Url";
import VendorService from "../../../../services/VendorService";
import {Filter} from "./Filter";
import {List} from "./List";
import StockReceiptService from "../../../../services/StockReceiptService";

class WarehouseReceipt extends React.Component {

    constructor(props) {
        super(props);
        this.moduleName = 'receipts';
        this.mounted = false;
        this.state = {
            items: [],
            vendor: LocalStore.getInstance().read('vendor'),
            currentPage: 1,
            totalItems: 0,
            pageSize: 100,
            pageCount: 0,
            loading: false,
            loadingCreatedBy: false,
            loadingCourierService: false,
            users: [],
            statuses: ["NEW", "PROCESSING", "DONE"],
            initialUsers: []
        };
    }

    componentDidMount = () => {
        this.mounted = true;
        window.addEventListener("popstate", this.onPopstate.bind(this), false);
        this.loadData();
    };

    componentWillUnmount() {
        this.mounted = false;
        window.removeEventListener("popstate", this.onPopstate.bind(this), false);
    }

    onPopstate() {
        let currentModule = getUrlSegment(3);
        // Url thay đổi thì chỉ load lại list
        if(this.mounted && currentModule === this.moduleName) {
            let queryObject = getParamsAsObject(getUrlQueryString());
            this.fetchReceipt(queryObject);
        }
    };

    loadData = () => {
        let queryObject = getParamsAsObject(getUrlQueryString());
        this.fetchReceipt(queryObject);
        this.fetchUsers({}, (error, response) => {
            if(!error) {
                this.setState({initialUsers: response.list});
            }
        });
    };

    fetchUsers = (filter = {}, cb) => {
        this.setState({loadingCreatedBy: true});
        const {vendor} = this.state;
        VendorService.getUserInVendor(vendor.id, filter, (error, response) => {
            this.setState({loadingCreatedBy: false});
            if(!error) {
                this.setState({
                    users: response.list
                });
            }
            doCallBack(cb, [error, response]);
        });
    };

    fetchReceipt = (filter = {}, cb) => {
        this.setState({loading: true});
        WareHouseService.getWareHouseReceipts(this.getCode(), filter, (error, response) => {
            this.setState({loading: false});
            if(error === null) {
                this.setState({
                    items: response.list,
                    currentPage: response.metadata.currentPage+1,
                    totalItems: response.count,
                    pageSize: response.metadata.pageSize,
                    pageCount: response.metadata.pageCount
                });
            } else {
                this.setState({
                    items: [],
                    currentPage: 1,
                    totalItems: 0,
                    pageSize: 25,
                    pageCount: 0
                })
            }
            doCallBack(cb, [error, response]);
        });
    };

    resetData = (key) => {
        let initialKey = `initial${_.upperFirst(key)}`;
        this.setState({
            [key]: this.state[initialKey]
        })  ;
    };

    getCode = () => {
        return this.props.match.params.code;
    };

    pushQueryString = (queryString) => {
        this.props.history.push(`/warehouse/${this.getCode()}/receipts${queryString}`);
    };

    getUrlInstance = (newInstance = false) => {
        if(newInstance) {
            return new Url();
        }
        return new Url('?'+getUrlQueryString());
    };

    onShowSizeChange = (current, pageSize) => {
        let uri = this.getUrlInstance();
        uri.replaceParam('page', 1);
        uri.replaceParam('pageSize', pageSize);
        this.pushQueryString(uri.getQueryString());
    };

    onChangePage = (page, pageSize) => {
        let uri = this.getUrlInstance();
        uri.replaceParam('page', page);
        uri.replaceParam('pageSize', pageSize);
        this.pushQueryString(uri.getQueryString());
    };

    createStockReceipt = (cb)=>{
        let warehouseCode = this.getCode();
        StockReceiptService.createStockReceipt(warehouseCode, (err, response) => {
            doCallBack(cb, [err, response]);
        });
    };

    render() {
        const {t} = this.props;
        const content = (
            <div className="tab-pane-inner-box">
                <div className="list-page receipt-list-page">
                    <Filter
                        {...this.props}
                        {...this.state}
                        pushQueryString={this.pushQueryString}
                        getUrlInstance={this.getUrlInstance}
                        resetData={this.resetData}
                        fetchUsers={this.fetchUsers}
                    />
                    <List
                        {...this.props}
                        {...this.state}
                        onShowSizeChange={this.onShowSizeChange}
                        onChangePage={this.onChangePage}
                        createStockReceipt={this.createStockReceipt}
                        getCode={this.getCode}
                    />
                </div>
            </div>
        );

        return (
            <Layout
                {...this.props}
                pageTitle={t('stock_issue_detail.title')}
                tabActive={'receipt'}
                tabContent={content}
            />
        )
    }
}

export default (translate())(WarehouseReceipt)