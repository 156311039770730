import EagleClient from "../utils/api/EagleClient";

export default class LandingLeadService {
    static getLeads(filter, cb) {
        let endpoint = "/PhoneContactLandings";
        EagleClient.request({
            endpoint: endpoint,
            method: "GET",
            mode: "free",
            queryString: filter
        }, cb);
    }
}