import React from 'react';
import {Modal, Select, Form, Empty, Input, notification} from "antd";

class PopupCancelledReason extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    }

    onOk = () => {
        const {validateFields} = this.props.form;
        validateFields((error, values) => {
            if(!error) {
                this.setState({loading: true});
                let payload = {
                    status: 'CANCELLED',
                    subStatus: values.subStatus,
                    cancelledReason: values.subStatus==='OTHER' ? values.cancelledReason : values.subStatus
                };

                this.props.updateOrderStatus(payload, (error, response) => {
                    this.setState({loading: false});
                    if(!error) {
                        this.props.togglePopupCancelled();
                    }
                    this.props.onUpdateOrderStatusResponse(error, response);
                });
            }
        });
    };

    onCancel = () => {
        const {resetFields} = this.props.form;
        resetFields();
        this.props.togglePopupCancelled();
    };

    render() {
        const {loading} = this.state;
        const {t, visible, orderStatuses} = this.props;
        const {getFieldDecorator, getFieldValue} = this.props.form;
        let reasonArr = [];
        orderStatuses.map((item) => {
            if(item.key === 'CANCELLED') {
                reasonArr = item.children;
            }
        });
        return (
            <Modal
                className="_modal_cancelled_reason"
                title={t('order_detail.add_reason')}
                okText={t('button.save')}
                cancelText={t('button.cancel')}
                visible={visible}
                onOk={this.onOk}
                onCancel={this.onCancel}
                okButtonProps={{
                    loading: loading,
                    className: "_btn_submit_order_status_cancelled"
                }}
                cancelButtonProps={{
                    className: "_btn_cancel_order_status_cancelled"
                }}
            >
                <Form layout="vertical">
                    <Form.Item label={t('order.source')}>
                        {getFieldDecorator('subStatus', {
                            rules: [
                                {required: true, message: t('message.required')}
                            ]
                        })(
                            <Select
                                className="_select_sub_status_cancelled"
                                placeholder={'-Select-'}
                                defaultActiveFirstOption={false}
                                notFoundContent={<Empty description={t("table.empty")}/>}
                            >
                                {reasonArr.map((item) => (
                                    <Select.Option
                                        key={item.key}
                                        value={item.key}
                                        className="_option_sub_status_cancelled"
                                    >
                                        {t(`order_status_cancelled.${item.key}`)}
                                    </Select.Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>

                    {getFieldValue('subStatus') === 'OTHER' && (
                        <Form.Item label={t('order_detail.reason')}>
                            {getFieldDecorator('cancelledReason', {
                                rules: [
                                    {required: true, message: t('message.required')},
                                    {max: 255, message: t('error.length_255')}
                                ]
                            })(
                                <Input.TextArea className="_other_cancelled_reason" />
                            )}
                        </Form.Item>
                    )}
                </Form>
            </Modal>
        )
    }
}

PopupCancelledReason = Form.create()(PopupCancelledReason);

export {PopupCancelledReason};