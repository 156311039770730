import React from 'react';
import {translate} from 'react-i18next';
import * as _ from 'lodash';
import StandardLayout from "../../components/StandardLayout";
import {Header} from "./Header";
import {Filter} from "./Filter";
import {List} from "./List";
import {Url} from "../../utils/Url";
import {arrayConcat, doCallBack, getCurrentModule, getParamsAsObject, getUrlQueryString} from "../../utils/helper";
import UserService from "../../services/UserService";
import {GROUPS_SCOPES} from "../../utils/api/Scopes";
import {LocalStore} from "../../utils/LocalStore";
import UserGroupService from "../../services/UserGroupService";
import VendorService from "../../services/VendorService";
import {PopupCreateGroupUser} from "./Add/PopupCreateGroupUser";

class GroupUser extends React.Component {

    constructor(props) {
        super(props);
        this.mounted = true;
        this.moduleName = 'groups';
        this.state = {
            groups: [],
            vendor: LocalStore.getInstance().read('vendor'),
            currentPage: 1,
            totalItems: 50,
            pageSize: 100,
            pageCount: 0,
            loading: true,
            loadingCreatedBy: true,
            users: [],
            initialUsers: [],
            isShowPopupCreate: false
        }
    }

    componentWillMount() {
        if (!UserService.isAllowed(GROUPS_SCOPES.GROUPS_VIEW)) {
            this.props.history.replace('/403')
        }
    };

    componentDidMount = () => {
        this.mounted = true;
        window.addEventListener("popstate", this.onPopstate.bind(this), false);
        this.loadData();
    };

    componentWillUnmount() {
        this.mounted = false;
        window.removeEventListener("popstate", this.onPopstate.bind(this), false);
    }

    onPopstate() {
        // Url thay đổi thì chỉ load lại list
        if(this.mounted && getCurrentModule(null, true) === this.moduleName) {
            let queryObject = getParamsAsObject(getUrlQueryString());
            this.fetchGroups(queryObject);
        }
    };

    loadData = () => {
        let queryObject = getParamsAsObject(getUrlQueryString());
        this.fetchGroups(queryObject);
        this.fetchUsers({}, (error, response) => {
            if(!error) {
                this.setState({initialUsers: response.list});
            }
        });
    };

    fetchGroups = (filter = {}, cb) => {
        this.setState({loading: true});
        let {vendor} = this.state;
        let defaultFilter = {
            order: ["createdAt DESC"],
            vendor_id: vendor.id
        };
        filter = _.mergeWith(defaultFilter, filter);
        UserGroupService.getUserGroup(filter, (error, response) => {
            this.setState({loading: false});
            if(!error) {
                this.setState({
                    groups: response.list,
                    currentPage: response.metadata.currentPage,
                    totalItems: response.count,
                    pageSize: response.metadata.pageSize,
                    pageCount: response.metadata.pageCount
                });
            }
            doCallBack(cb, [error, response]);
        });
    };

    fetchUsers = (filter = {}, cb) => {
        this.setState({loadingCreatedBy: true});
        const {vendor} = this.state;
        VendorService.getUserInVendor(vendor.id, filter, (error, response) => {
            this.setState({loadingCreatedBy: false});
            if(!error) {
                this.setState({
                    users: response.list
                });
            }
            doCallBack(cb, [error, response]);
        });
    };

    pushQueryString = (queryString) => {
        this.props.history.push(`/groups${queryString}`);
    };

    getUrlInstance = (newInstance = false) => {
        if(newInstance) {
            return new Url();
        }
        return new Url('?'+getUrlQueryString());
    };

    onShowSizeChange = (current, pageSize) => {
        let uri = this.getUrlInstance();
        uri.replaceParam('page', 1);
        uri.replaceParam('page_size', pageSize);
        this.pushQueryString(uri.getQueryString());
    };

    onChangePage = (page, pageSize) => {
        let uri = this.getUrlInstance();
        uri.replaceParam('page', page);
        uri.replaceParam('page_size', pageSize);
        this.pushQueryString(uri.getQueryString());
    };

    resetData = (key) => {
        let initialKey = `initial${_.upperFirst(key)}`;
        this.setState({
            [key]: this.state[initialKey]
        })  ;
    };

    createGroup = (data, cb) => {
        const {vendor} = this.state;
        data.vendorId = vendor.id;
        UserGroupService.createUserGroup(data, cb);
    };

    togglePopupCreate = () => {
        this.setState({
            isShowPopupCreate: !this.state.isShowPopupCreate
        });
    };

    render() {
        const {t} = this.props;
        const {groups, isShowPopupCreate} = this.state;
        return (
            <StandardLayout {...this.props} title={t("group_user.title")}>
                <div className="group-user-list-page list-page">
                    <Header
                        {...this.props}
                        togglePopupCreate={this.togglePopupCreate}
                    />
                    <Filter
                        {...this.props}
                        {...this.state}
                        getUrlInstance={this.getUrlInstance}
                        pushQueryString={this.pushQueryString}
                        fetchUsers={this.fetchUsers}
                        resetData={this.resetData}
                    />
                    <List
                        {...this.props}
                        {...this.state}
                        dataSource={groups}
                        onShowSizeChange={this.onShowSizeChange}
                        onChangePage={this.onChangePage}
                        getUrlInstance={this.getUrlInstance}
                        pushQueryString={this.pushQueryString}
                    />
                </div>

                {isShowPopupCreate && (
                    <PopupCreateGroupUser
                        {...this.props}
                        createGroup={this.createGroup}
                        onCancel={this.togglePopupCreate}
                        fetchGroups={this.fetchGroups}
                    />
                )}
            </StandardLayout>
        )
    }
}

export default (translate())(GroupUser);