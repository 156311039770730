import React from 'react';
import {withNamespaces} from 'react-i18next';

import Routes from "./Routes";
import {LocalStore} from "./utils/LocalStore/index";
import UserService from "./services/UserService";
import Loading from "./components/Loading";

import './app.less';
import './resources/sass/main.scss';

const SESSION_KEY = 'loginSession';
const STATE_SUCCESS = 'success';
const STATE_FAILURE = 'failure';
const STATE_LOADING = 'loading';

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            initAppStatus: STATE_LOADING
        }
    }

    componentDidMount() {
        const localStore = LocalStore.getInstance();
        let loginSession = localStore.read(SESSION_KEY);

        if (loginSession) {
            if (loginSession.created && loginSession.ttl) {
                let created = new Date(loginSession.created);
                let expired = new Date(created.getTime() + loginSession.ttl*1000);
                if (expired < new Date()) {
                    localStore.save(SESSION_KEY, null);
                    return this.setState({initAppStatus: STATE_SUCCESS});
                }
            }

            //token not full or not expired, must fetch me to verify
            UserService.showMe((err, me)=> {
                if (err) {
                    //khi gọi me thất bại thì coi như là lỗi hệ thống vì có thể có vấn đề xảy ra mà khó lường trước
                    localStore.save(SESSION_KEY, null);
                    return this.setState({initAppStatus: STATE_FAILURE});
                }
                else {
                    if(!LocalStore.getInstance().read("vendor")){
                        LocalStore.getInstance().save("vendor", me.vendors[0]);
                    }
                    localStore.save(SESSION_KEY, me.accessToken);
                    this.setState({initAppStatus: STATE_SUCCESS});
                }
            });
        } else {
            return this.setState({initAppStatus: STATE_SUCCESS});
        }
    }

    render() {
        switch (this.state.initAppStatus) {
            case STATE_SUCCESS:
                return (<Routes {...this.props} />);

            case STATE_FAILURE:
                // TODO: change content of failure page
                return (
                    <span>Truy cập bị lỗi, vui lòng thử lại</span>
                );

            default:
                // show loading page
                return (
                    <Loading/>
                );
        }
    }
}

export default (withNamespaces())(App);
