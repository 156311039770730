import EagleClient from "../utils/api/EagleClient";

export default class AdSetService {

    static getAdSets(filter, cb) {
        let endpoint = "/CampaignAdsets";
        EagleClient.request({
            endpoint: endpoint,
            method: "GET",
            mode: "free",
            queryString: filter
        }, cb);
    }

    static createAdSet(data, cb) {
        EagleClient.request({
            endpoint: "/CampaignAdsets",
            method: "POST",
            body: data,
        }, cb);
    }

    static updateAdSet(data, cb) {
        EagleClient.request({
            endpoint: "/CampaignAdsets/" + data.id,
            method: "PATCH",
            body: data
        }, cb);
    }

    static deleteAdset(id, cb) {
        EagleClient.request({
            endpoint: `/CampaignAdsets/${id}`,
            method: "DELETE",
        }, cb);
    }

    static findById(id, cb) {
        EagleClient.request({
            endpoint: `/CampaignAdsets/${id}`,
            method: "GET",
        }, cb);
    }
}
