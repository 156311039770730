import React from 'react';
import {Row, Col, Checkbox, Icon, Dropdown, Menu, Tooltip} from 'antd';
import {formatDate, objectGet} from "../../../utils/helper";
import {ORDER_STATUS} from "../../../utils/const";
import {ORDER_SCOPES} from "../../../utils/api/Scopes";
import UserService from "../../../services/UserService";

class Header extends React.Component {

    onChangeOrderStatus = (item) => {
        if(item.key === ORDER_STATUS.CANCELLED.key) {
            this.props.togglePopupCancelled();
        } else if(item.key === ORDER_STATUS.RETURNING.key) {
            this.props.togglePopupReturning();
        } else {
            this.props.updateOrderStatus({status: item.key}, this.props.onUpdateOrderStatusResponse);
        }
    };

    isAllowPay = () => {
        const {order} = this.props;
        const hasIssue = objectGet(order, 'hasIssue');
        const status = objectGet(order, 'status');
        let statusAllow = ["NEW", "READY", "HOLD", "CANCELLED"];

        let isAllow = statusAllow.indexOf(status) >= 0;
        if(!hasIssue && isAllow && UserService.isAllowed(ORDER_SCOPES.ORDER_PAY_STATUS)) {
            return true;
        }
        return false;
    };

    onPay = () => {
        if(this.isAllowPay()) {
            this.props.togglePay();
        }
    };

    render() {
        const {t, order, orderStatuses} = this.props;
        const deliveryDate = objectGet(order, 'deliveryDate');
        const createdAt = objectGet(order, 'createdAt');
        const status = objectGet(order, 'status', 'NEW');
        const ad = objectGet(order, 'campaignAd', {});
        const creator = objectGet(order, 'creator', {});
        const paid = objectGet(order, 'paid');

        let renderOrderStatus = (
            <Menu className="_order_status_list" onClick={this.onChangeOrderStatus}>
                {orderStatuses.map((item) => (
                    <Menu.Item className="_order_status_item" key={item.key}>
                        <span>{t(`order_status.${item.key}`)}</span>
                    </Menu.Item>
                ))}
            </Menu>
        );

        let classNamePay = this.isAllowPay() ? 'ok' : 'not-allowed';
        let iconStatus;
        const cancelledReason = objectGet(order, 'cancelledReason');
        if((status === "CANCELLED" || status === "RETURNING") && cancelledReason) {
            let keyTrans = `order_status_cancelled.${cancelledReason}`;
            let reason = t(keyTrans);
            if(reason === keyTrans) reason = cancelledReason;
            iconStatus = (
                <Tooltip title={reason} placement="top">
                    <Icon type="question-circle" />
                </Tooltip>
            );
        }
        return (
            <div className="order-detail-header">
                <div className="white-box">
                    <Row gutter={20}>
                        <Col className="code block-item _block_item" lg={3} md={6}>
                            <label>{t('order.code')}</label>
                            <div className="text">{objectGet(order, 'code')}</div>
                        </Col>
                        <Col className="ads block-item _block_item" lg={3} md={6}>
                            <label>{t('order_detail.ads')}</label>
                            <Tooltip title={ad.name} placement="top">
                                <div className="text">{ad.name}</div>
                            </Tooltip>
                        </Col>
                        <Col className="delivery-date block-item _block_item" lg={3} md={6}>
                            <label>{t('order_detail.due_date')}</label>
                            <div className="text">{deliveryDate && formatDate(deliveryDate)}</div>
                        </Col>
                        <Col className="status block-item _block_item" lg={3} md={6}>
                            <label>{t('order_detail.status')} {iconStatus}</label>

                            {status !== "DRAFT" && (
                                <div>
                                    <Dropdown overlay={renderOrderStatus} trigger={['click']}>
                                        <Tooltip title={t(`order_status.${status}`)} placement="top">
                                            <span className={`order-status status_${status}`}>{t(`order_status.${status}`)} <Icon type="down"/></span>
                                        </Tooltip>
                                    </Dropdown>
                                </div>
                            )}

                            {status === "DRAFT" && (
                                <Tooltip title={t(`order_status.${status}`)} placement="top">
                                    <div className={`order-status status_${status}`}>{t(`order_status.${status}`)}</div>
                                </Tooltip>
                            )}

                        </Col>
                        <Col className="create-time block-item _block_item" lg={3} md={6}>
                            <label>{t('order_detail.created_time')}</label>
                            <div className="text">{createdAt && formatDate(createdAt)}</div>
                        </Col>
                        <Col className="created-by block-item _block_item" lg={3} md={6}>
                            <label>{t('order_detail.created_by')}</label>
                            <div className="text">{creator.username}</div>
                        </Col>
                        <Col className="issue block-item _block_item" lg={3} md={6}>
                            <label>{t('stock_detail.code')}</label>
                            <div className="text">{objectGet(order, 'issueId')}</div>
                        </Col>
                        <Col className="toggle-paid" lg={3} md={6}>
                            <Checkbox onChange={this.onPay} checked={paid} className={classNamePay}>{t('order_detail.paid')}</Checkbox>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export {Header};