import EagleClient from "../utils/api/EagleClient";

export default class SettingService {
    static updateSetting(id, data,cb) {
        EagleClient.request({
            endpoint: "/Settings/" + id,
            method: "PATCH",
            body: data,
        }, cb);
    }

    static getContactSetting(cb) {
        EagleClient.request({
            endpoint: "/Settings",
            method: "GET",
            queryString: {key: "contact"}
        }, cb);
    }

    static getList(filter = {}, cb) {
        EagleClient.request({
            endpoint: "/Settings",
            method: "GET",
            queryString: {...filter}
        }, cb)
    }
}
