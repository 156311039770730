import URI from 'urijs';
import _ from 'lodash';

class Url {

    constructor(url = "") {
        if(!url) url = window.location.href;
        this.uri = new URI(url);
    }

    addParam(key, value) {
        key = _.snakeCase(key);
        this.uri.addSearch(key, value);
    }

    replaceParam(key, value) {
        key = _.snakeCase(key);
        this.uri.setSearch(key, value);
    }

    removeParam(key) {
        key = _.snakeCase(key);
        this.uri.removeSearch(key);
    }

    getQueryString() {
        return this.uri.search();
    }
}

export {Url};