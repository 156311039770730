import React from 'react';
import {Tabs} from 'antd';
import {WAREHOUSE_SCOPES} from "../../../utils/api/Scopes";
import UserService from "../../../services/UserService";
import StandardLayout from "../../../components/StandardLayout";
import {Bcrumb} from "./Breadcrumb";
import {doCallBack} from "../../../utils/helper";
import WareHouseService from "../../../services/WareHouseService";
import {toastr} from "../../../utils/toastr";
import {BasicInfo} from "./BasicInfo";
import {LocalStore} from "../../../utils/LocalStore";

const TabPane = Tabs.TabPane;

class Layout extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            warehouse: {},
            vendor: LocalStore.getInstance().read('vendor')
        }
    }

    componentDidMount = () => {
        if (!UserService.isAllowed(WAREHOUSE_SCOPES.WAREHOUSE_VIEW)) {
            this.props.history.replace('/403');
            return;
        }

        this.fetchWarehouse();
    };

    getCode = () => {
        return this.props.match.params.code;
    };

    fetchWarehouse = (cb) => {
        const {vendor} = this.state;
        this.setState({loading: true});
        WareHouseService.getDetailWareHouse(this.getCode(), (error, response) => {
            this.setState({loading: false});
            if(!error) {
                if(response.vendor.id !== vendor.id){
                    this.props.history.replace('/404');
                }
                this.setState({warehouse: response});
            } else {
                if(error.statusCode === 404) {
                    this.props.history.replace('/404');
                } else {
                    toastr.error(error.message);
                }
            }
            doCallBack(cb, [error, response]);
        });
    };

    updateWarehouse = (data, cb) => {
        const {warehouse} = this.state;
        WareHouseService.updateWareHouse(warehouse.code, data, cb);
    };

    onChangeTab = (activeKey) => {
        const {warehouse} = this.state;
        switch (activeKey) {
            case 'stock':
                return this.props.history.push(`/warehouse/${warehouse.code}/stocks`);
            case 'issue':
                return this.props.history.push(`/warehouse/${warehouse.code}/issues`);
            case 'receipt':
                return this.props.history.push(`/warehouse/${warehouse.code}/receipts`);
            default:
            case 'permission':
                return this.props.history.push(`/warehouse/${warehouse.code}/permissions`);
        }
    };

    render() {
        const {t, tabActive, tabContent, pageTitle} = this.props;
        const {warehouse, loading} = this.state;
        return (
            <StandardLayout
                {...this.props}
                title={pageTitle ? pageTitle : t('warehouse.title')}
                loading={loading}
            >
                <div className="warehouse-detail-page">
                    <Bcrumb
                        {...this.props}
                        {...this.state}
                    />
                    <BasicInfo
                        {...this.props}
                        {...this.state}
                        fetchWarehouse={this.fetchWarehouse}
                        updateWarehouse={this.updateWarehouse}
                    />
                    <div className="white-box">
                        <Tabs
                            activeKey={tabActive}
                            animated={false}
                            className="fobiz-ant-tab"
                            onChange={this.onChangeTab}
                        >
                            {UserService.isAllowed(WAREHOUSE_SCOPES.WAREHOUSE_STOCKS_VIEW) && (
                                <TabPane key="stock" tab={t("warehouse.stock")} className="_tab_pane_stock">{tabContent}</TabPane>
                            )}

                            {UserService.isAllowed(WAREHOUSE_SCOPES.WAREHOUSE_ISSUES_VIEW) && (
                                <TabPane key="issue" tab={t("warehouse.issue")} className="_tab_pane_issue">{tabContent}</TabPane>
                            )}

                            {UserService.isAllowed(WAREHOUSE_SCOPES.WAREHOUSE_RECEIPTS_VIEW) && (
                                <TabPane key="receipt" tab={t("warehouse.receipt")} className="_tab_pane_receipt">{tabContent}</TabPane>
                            )}

                            <TabPane key="permission" tab={t("warehouse.permission")} className="_tab_pane_permission">{tabContent}</TabPane>
                        </Tabs>
                    </div>
                </div>
            </StandardLayout>
        )
    }
}

export {Layout};