import React from 'react';
import {Card, Form, Tooltip} from 'antd';
import lodash from 'lodash'
import {objectGet} from "../../../utils/helper";
import {COURIERS} from "../../../utils/const";
import {formatCurrency} from "../../../utils/currency";

class Shipping extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    renderTrackingNumber = () => {
    	let {order, t} = this.props
		const trackingNumberUrlPattern = objectGet(order, 'courierService.trackingNumberUrlPattern');
		const trackingNumber = objectGet(order, 'trackingNumber')
		if (trackingNumber) {
			if (trackingNumberUrlPattern) {
				return (
					<Tooltip title={t('order_detail.tooltip')}>
						<a href={lodash.replace(trackingNumberUrlPattern, '{{trackingNumber}}', trackingNumber)}
						   target="_blank">
							{trackingNumber}
						</a>
					</Tooltip>
				)
			}
			return trackingNumber
		}
	}

    render() {
        const {order, t, estimateShippingCod, currencyUnit} = this.props;
        const formOptions = {
            layout: "horizontal",
            className: "form-text-mode",
            labelCol: {
                xs: { span: 24 },
                md: { span: 24 },
                lg: { span: 13 },
            },
            wrapperCol: {
                xs: { span: 24 },
                md: { span: 24 },
                lg: { span: 11 },
            },
        };
        const courierServiceId = objectGet(order, 'courierServiceId');
        const courierServiceName = objectGet(order, 'courierService.name');
        const trackingNumber = objectGet(order, 'trackingNumber');
        const trackingStatus = objectGet(order, 'trackingStatus');
        let trackingStatusName;
        let trackingStatusLbc = t('tracking_status.pending').toUpperCase();
        let trackingStatusShippo = t('tracking_status.waiting').toUpperCase();

        if(courierServiceName === COURIERS.LBC.name) {
            trackingStatusName = trackingStatusLbc;
        } else if(courierServiceName === COURIERS.SHIPPO.name) {
            trackingStatusName = trackingStatusShippo;
        }
        const packageType = objectGet(order, 'packageType');
        const totalAmount = objectGet(order, 'totalAmount', 0);

        return (
            <Card
                className={'card-block'}
                title={t('order_detail.shipping')}
            >
                <div className="shipping-form">
                    <Form {...formOptions}>
                        <Form.Item label={t('order_detail.carrier')}>
                            <span className="_text_courier_name form-item-text">{courierServiceName}</span>
                        </Form.Item>

                        <Form.Item label={t('order_detail.tracking_number')}>
                            <span className="_text_tracking_numer">
								{this.renderTrackingNumber()}
                            </span>
                        </Form.Item>

                        <Form.Item label={t('order_detail.status')}>
                            <span className="_text_tracking_status form-item-text">{trackingStatus ? trackingStatus : trackingStatusName}</span>
                        </Form.Item>

                        {packageType && courierServiceId === COURIERS.LBC.id && (
                            <Form.Item label={t('order_detail.package_type')}>
                                <span className="_text_package_type form-item-text">{packageType}</span>
                            </Form.Item>
                        )}

                        {trackingNumber && estimateShippingCod !== 0 && (
                            <>
                                <Form.Item label={t('order_detail.shipping_fee')}>
                                    <span className="_text_shipping_fee form-item-text">{formatCurrency(estimateShippingCod)} {currencyUnit}</span>
                                </Form.Item>

                                <Form.Item label={t('order_detail.temp_total_amount')}>
                                    <span className="_text_total_amount form-item-text">{formatCurrency(parseFloat(totalAmount)+parseFloat(estimateShippingCod))} {currencyUnit}</span>
                                </Form.Item>
                            </>
                        )}
                    </Form>
                </div>
            </Card>
        )
    }
}

Shipping = Form.create()(Shipping);
export {Shipping};
