/**
 * created by Taobao dev team - 15/1/2018
 */

import {API} from "../config/api";

const HTTP_NO_CONTENT = 204;

/**
 * function fetch api from server
 * @param endpoint
 * @param payload
 * @returns {Promise<*>}
 */

export default async(endpoint, payload) => {

    if (!global.fetchOverwrite) {
        global.fetch = null;
        require('whatwg-fetch-timeout');
        global.fetchOverwrite = true;
    }

    // validate endpoint
    endpoint = endpoint.startsWith('/') ? endpoint : '/' + endpoint;

    // calc url
    const fullURL = endpoint.indexOf(API.BASE_URL) === -1 ? API.BASE_URL + endpoint : endpoint;

    let res;

    // Nếu có mất mạng thì vẫn trả về mã lỗi như bình thường
    if(!navigator.onLine) {
        return {
            statusCode: "000",
            name: "Error",
            message: "No network connection"
        }
    }

    try {
        res = await fetch(fullURL,{...payload, timeout: 120000});
    } catch (err) {
        let error  = new Error('Có lỗi xảy ra, vui lòng thử lại!');
        error.statusCode = 'timeout';
        throw error;
    }

    // check status HTTP_NO_CONTENT
    if (res.status === HTTP_NO_CONTENT) {
        return true;
    }

    const json = await res.json();
    if (!res.ok) {
        throw json.error;
    }

    return json;
};

