import React from 'react';
import {Modal, Form, Input, Select, Empty} from "antd";
import {LocalStore} from "../../utils/LocalStore";

class PopupCreateWarehouse extends React.Component {

    constructor(props) {
        super(props);
        const {t} = this.props;
        this.state = {
            typeOptions: [
                {key: 'PHYSICAL', value: t("warehouse.PHYSICAL")},
                {key: 'LOGICAL', value: t("warehouse.LOGICAL")}

            ],
            vendorId: LocalStore.getInstance().read('vendor').id
        };
    }

    componentDidMount = () => {
    };

    onSubmit = () => {
        const {form} = this.props;
        form.validateFields((error, values) => {
            if(!error) {
                let payload = {
                    name: values.name.trim(),
                    address: values.address?values.address.trim(): '',
                    description: values.description?values.description.trim(): '',
                    type: values.type ? values.type.toUpperCase() : '',
                    status: 'ACTIVE',
                    vendorId: this.state.vendorId
                };
                this.props.handleCreateWarehouse(payload);
            }
        });
    };

    onCancel = () => {
        const {form} = this.props;
        this.props.isShowPopUpCreateWarehouse();
        form.resetFields();
    };
    

    render() {
        const {t, visible} = this.props;
        const {getFieldDecorator} = this.props.form;
        return (
            <>
                <Modal
                    maskClosable={false}
                    className="_modal_create_warehouse"
                    title={t('warehouse.add_warehouse')}
                    okText={t('button.save')}
                    cancelText={t('button.cancel')}
                    onOk={this.onSubmit}
                    onCancel={this.onCancel}
                    visible={visible}
                    okButtonProps={{
                        className: "_btn_submit_create_warehouse"
                    }}
                    cancelButtonProps={{
                        className: "_btn_cancel_create_warehouse"
                    }}
                >
                    <Form layout="vertical">
                        <Form.Item label={t('warehouse.name')}>
                            {getFieldDecorator('name', {
                                rules: [
                                    {required: true, message: t('message.required')},
                                    {whitespace:true, message: t("message.required")},
                                    {max: 160, message: t('error.length_160')}
                                ]
                            })(
                                <Input
                                    autofocus
                                    className="_name"
                                    placeholder={t('warehouse.name')}
                                />
                            )}
                        </Form.Item>
                        <Form.Item label={t('warehouse.type')}>
                            {getFieldDecorator('type', {
                                rules: [
                                    {required: true, message: t('message.required')},
                                    {whitespace:true, message: t("message.required")}
                                ]
                            })(
                                <Select
                                    className={'_select_type_to_create_warehouse'}
                                    showSearch
                                    allowClear={true}
                                    defaultActiveFirstOption={false}
                                    placeholder={t('warehouse.type')}
                                    notFoundContent={<Empty description={t("table.empty")}/>}
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {this.state.typeOptions.map((item) => (
                                        <Select.Option key={item.key} value={item.key}>{item.value}</Select.Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item label={t('warehouse.address')}>
                            {getFieldDecorator('address', {
                                rules: [
                                    {max: 255, message: t('error.length_255')}
                                ]
                            })(
                                <Input
                                    className="_address"
                                    placeholder={t('warehouse.address')}
                                />
                            )}
                        </Form.Item>
                        <Form.Item label={t('warehouse.description')}>
                            {getFieldDecorator('description', {
                                rules: [
                                    {max: 255, message: t('error.length_255')}
                                ]
                            })(
                                <Input.TextArea
                                    className="_description"
                                    placeholder={t('warehouse.description')}
                                />
                            )}
                        </Form.Item>
                    </Form>
                </Modal>

            </>
        )
    }
}

PopupCreateWarehouse = Form.create()(PopupCreateWarehouse);

export {PopupCreateWarehouse};