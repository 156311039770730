import React from 'react';
import {Breadcrumb as AntBreadCrumb, Button, Icon} from 'antd';
import {BreadCrumb} from "../../components/BreadCrumb";
import {Link} from "react-router-dom";
import UserService from "../../services/UserService";
import {VENDORS_SCOPES} from "../../utils/api/Scopes";

class Header extends React.Component {

    render() {
        const {t} = this.props;
        const leftContent = (
            <AntBreadCrumb>
                <AntBreadCrumb.Item>
                    <Link to={"/"}>{t("sidebar.dashboard")}</Link>
                </AntBreadCrumb.Item>
                <AntBreadCrumb.Item>{t("vendor.title")}</AntBreadCrumb.Item>
            </AntBreadCrumb>
        );

        const rightContent = (
            <Button.Group>
                {UserService.isAllowed(VENDORS_SCOPES.VENDORS_CREATE) && (
                    <Button type="primary" className={"_add-vendor-button"}><Link to={"/vendors/add"}>{t("vendor.create")}</Link></Button>
                )}
            </Button.Group>
        );

        return (
            <BreadCrumb
                {...this.props}
                leftContent={leftContent}
                rightContent={rightContent}
            />
        );
    }
}

export {Header};