import React, {Component} from 'react';
import {Table, Pagination, Switch, Button, Icon, Menu, Dropdown} from 'antd';
import UserService from "../../../services/UserService";
import {CAMPAIGNS_SCOPES} from "../../../utils/api/Scopes";
import {Link} from "react-router-dom";

class List extends Component {

    constructor(props) {
        super(props);
        this.state = {
            sorter: {}
        };
    }

    componentDidMount = () => {

    };

    getConfigRowSelection = () => {
        return {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: (selectedRowKeys) => {
                this.props.getProductId(selectedRowKeys);
                this.setState({
                    selectedRowKeys
                });
            }
        }
    };

    componentDidUpdate(prevProps) {
        if(JSON.stringify(prevProps.countProduct) !== JSON.stringify(this.props.countProduct)){
            if(!this.props.countProduct.length){
                this.setState({
                    selectedRowKeys: [],
                })
            }
        }
    }

    checkStatus = (item) => {
        this.props.onToggleProductStatus(item.id, item);
    };

    getConfigTableColumns = () => {
        const {t} = this.props;
        const renderColumn = (key, text, record) => {
            switch (key) {
                case 'status':
                    return (<Switch
                                className="_status"
                                disabled={!UserService.isAllowed(CAMPAIGNS_SCOPES.CAMPAIGNS_PRODUCT_UPDATE)}
                                checked={record.active===true}
                                onChange={() => this.checkStatus(record)}
                                size="small"
                            />);
                case 'name':
                    return (
                        <>
                            <span className="product-name">{record.name}</span>
                            <div><span className="code"><i>{record.code}</i></span></div>
                        </>
                    );

                case 'countOrder':
                case 'countProject':
                case 'countCampaign':
                case 'countAdset':
                case 'countAd':
                case 'countContact':
                    return (<span>{record[key]}</span>);
                case 'action':
                    const menu = (
                        <Menu>
                            {UserService.isAllowed(CAMPAIGNS_SCOPES.CAMPAIGNS_PRODUCT_UPDATE) && (
                                <Menu.Item key="1">
                                    <Link to={`/v-products/${record.id}`}><Icon type="edit" /> Edit</Link>
                                </Menu.Item>
                            )}
                        </Menu>
                    );
                    return (
                        <Dropdown overlay={menu} trigger={['contextMenu', 'click']}>
                            <span className="pointer"><Icon type="dash" /></span>
                        </Dropdown>
                    );
                default:
                    return record[key];
            }
        };

        return [
            {title: t("product_list.name"),dataIndex: 'name', key: 'name',width:150,className:"_name _item",render: renderColumn.bind(this, 'name')},
            {title: t("products.local_name"),dataIndex: 'localName', key: 'localName',width:150,className:"_local_name _item",render: renderColumn.bind(this, 'localName')},
            {title: t("product_list.code"),dataIndex: 'code', key: 'code',width:150,className:"_code _item",render: renderColumn.bind(this, 'code')},
            {title: t("product_list.count_order"),dataIndex: 'countOrder', key: 'countOrder',width:120,className:"_countOrder _item", render: renderColumn.bind(this, 'countOrder')},
            {title: t("product_list.count_project"),dataIndex: 'countProject', key: 'countProject',width:120,className:"_countProject _item", render: renderColumn.bind(this, 'countProject')},
            {title: t("product_list.count_campaign"),dataIndex: 'countCampaign', key: 'countCampaign',width:120,className:"_countCampaign _item", render: renderColumn.bind(this, 'countCampaign')},
            {title: t("campaign_list.count_adset"),dataIndex: 'countAdset', key: 'countAdSet',width:120,className:"_countAdset _item", render: renderColumn.bind(this, 'countAdset')},
            {title: t("campaign_list.count_ad"),dataIndex: 'countAd', key: 'countAd',width:120,className:"_countAd _item", render: renderColumn.bind(this, 'countAd')},
            {title: t("campaign_list.count_contact"),dataIndex: 'countContact', key: 'countContact',width:120,className:"_countContact _item", render: renderColumn.bind(this, 'countContact')},
            {title: t("products.selling"),dataIndex: 'status', key: 'status',render: renderColumn.bind(this, 'status'),width:120,className:"_status _item"},
            {title: t("table.action"),dataIndex: 'action', key: 'action',render: renderColumn.bind(this, 'action'),width:120,className:"_action _item"},
        ];
    };

    render() {
        const {t, loadingProduct} = this.props;
        const {dataSource,currentPage,totalItems,pageSize,onShowSizeChange,onChangePage} = this.props;
        let columns = this.getConfigTableColumns();
        const rowSelection = this.getConfigRowSelection();

        return (
            <div className="list-container _list-product">
                <div className="a-table a-table--responsive">
                    <Table
                        columns={columns}
                        dataSource={dataSource}
                        rowKey={record => record.id}
                        pagination={false}
                        rowSelection={rowSelection}
                        loading={loadingProduct}
                        locale={{
                            emptyText: t("table.empty")
                        }}
                    />
                </div>

                {!loadingProduct && totalItems > 0 && (
                    <Pagination
                        className="mg-t-15 text-al-r"
                        showSizeChanger
                        onShowSizeChange={onShowSizeChange}
                        onChange={onChangePage}
                        pageSizeOptions={['25', '50', '120', '150', '200']}
                        defaultCurrent={1}
                        current={currentPage}
                        pageSize={pageSize}
                        total={totalItems}
                    />
                )}
            </div>
        );
    }
}

export {List}