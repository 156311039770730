import React, {Component} from 'react';
import {Button, Divider, Empty, Select, Spin} from 'antd';
import { Input, Icon, Row, Col } from 'antd';
import * as _ from 'lodash';
import UserService from "../../../services/UserService";
import {CAMPAIGNS_SCOPES} from "../../../utils/api/Scopes";

const Option = Select.Option;

class Filter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            keyword: "",
            createdBy: []
        };
        this.onSelectSearch = _.debounce(this.onSelectSearch, 500);
    }

    onChangeInput = (key, e) => {
        this.setState({
            [key]: e.target.value
        });
    };

    onSelect = (key, value, option) => {
        let oldList = this.state[key] || [];
        oldList.push(value);
        this.setState({
            [key]: oldList
        });
    };

    onDeselect = (key, value, option) => {
        let oldList = this.state[key] || [];
        let index = oldList.indexOf(value);
        if(index >= 0) {
            oldList.splice(index, 1);
        }
        this.setState({
            [key]: oldList
        },function () {
            this.onFilter();
        });
    };

    onSelectSearch = (key, value) => {
        if(key === 'createdBy') {
            this.props.fetchUser({
                username: value
            });
        }
    };

    onSelectChange = (key, value) => {
        this.setState({
            [key]: value
        });
    };

    onFilter = () => {
        this.props.onFilter({
            keyword: this.state.keyword,
            created_by: _.map(this.state.createdBy, 'key').join(',')
        });
    };

    resetStateFilter = (cb = null) => {
        this.setState({
            keyword: "",
            createdBy: []
        }, cb)
    };

    clearFilter = () => {
        this.resetStateFilter(() => {
           this.onFilter();
        });
    };

    onFocus = (key, e) => {
        switch (key) {
            case 'createdBy':
                this.props.resetDataSource('users');
                break;
        }
    };

    onAddProduct = () => {
        this.props.history.push('/products/add-product');
    };

    getFilterCon = (key) => {
        if(key === "keyword"){
            return this.state.keyword?this.state.keyword:""
        }else if(key === "createdBy"){
            return this.state.createdBy?_.map(this.state.createdBy, 'key').join(','):""
        }
    };

    render() {
        const {t, loadingProduct, totalItems, currentPage, pageCount, users, loadingUser} = this.props;
        const {keyword, createdBy} = this.state;
        return (
            <div className="product-filter filter _product-filter">
                <div>
                    <Row gutter={20} className="form-filter">
                        <Col md={4} lg={24} xl={24} style={{lineHeight:"32px"}}>
                            {!loadingProduct && !!totalItems && (
                                <span>{t("contact.page")} {currentPage}/{pageCount} ({totalItems})</span>
                            )}
                            {!loadingProduct && !totalItems && (
                                <span>{t("contact.page")} {1}/{1} ({totalItems})</span>
                            )}
                        </Col>

                        <Col md={20} lg={24} xl={24} className="form-filter-control mg-bt-0 filter-items-wrapper" style={{paddingRight: 6}}>
                            <Input
                                className={"_filter_keyword filter-input mg-r-5"}
                                placeholder={t("products.name_or_code")}
                                suffix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                value={keyword}
                                onChange={this.onChangeInput.bind(this, 'keyword')}
                                onPressEnter={this.onFilter}
                                style={{width: 250}}
                            />

                            <Select
                                labelInValue
                                filterOption={false}
                                className={"_filter_created_by filter-input mg-r-5"}
                                placeholder={t("products.created_by")}
                                notFoundContent={(loadingUser ? <Spin size="small" /> : <Empty description={t("table.empty")} />)}
                                mode="multiple"
                                value={loadingUser ? [] : createdBy}
                                loading={loadingUser}
                                style={{width: 250}}
                                onSelect={this.onSelect.bind(this, 'createdBy')}
                                onDeselect={this.onDeselect.bind(this, 'createdBy')}
                                onSearch={this.onSelectSearch.bind(this, 'createdBy')}
                                onChange={this.onSelectChange.bind(this, 'createdBy')}
                                onFocus={this.onFocus.bind(this, 'createdBy')}
                            >
                                {users.map((item, index) => (
                                    <Option key={index} value={item.username} className={`_created_by_item _created_by_item_${item.id}`}>{item.username}</Option>
                                ))}
                            </Select>

                            <div style={{display: 'inline-block'}}>
                                <Button type="primary"  onClick={this.onFilter} className="_filter_button" style={{marginRight:5}}>{t("vendor.filter_button")}</Button>
                                <Button onClick={this.clearFilter} className="_clear_button">{t("vendor.clear_button")}</Button>
                            </div>

                            {/*{UserService.isAllowed(CAMPAIGNS_SCOPES.CAMPAIGNS_PRODUCT_CREATE) && (*/}
                                {/*<>*/}
                                    {/*<Divider type="vertical" className="pdivider" style={{margin: "0 15px"}}/>*/}
                                    {/*<Button type="primary" onClick={this.onAddProduct} className="_btn_add_product btn-add-product mg-r-5">{t("stock_receipts_detail.add_propduct")}</Button>*/}
                                {/*</>*/}
                            {/*)}*/}
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export {Filter}