import React, {Component} from 'react';
import {Col, Form as AntForm, Input, InputNumber, Row, Switch, Tabs} from 'antd';
import {TabProperty} from "./TabProperty";
import {TabPermission} from "./TabPermission";
import {LocalStore} from "../../../../utils/LocalStore";
import UserService from "../../../../services/UserService";
import {CAMPAIGNS_SCOPES} from "../../../../utils/api/Scopes";

const TabPane = Tabs.TabPane;

class Form extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const lang = LocalStore.getInstance().read('language') || 'en';
        const {t, active, onChangeStatus, name, code, defaultPrice, description, manufactory, localName} = this.props;

        const { getFieldDecorator } = this.props.form;
        let statusText = active ? t("products.active") : t("products.inactive");
        return (
            <>
                <div className="white-box">
                    <div className="form-header">
                        <h3 className="pd-l-30 pd-r-30 pd-t-15 pd-bt-15 mg-0">{t('products.page_edit_title')}</h3>
                        <div className="h-line" />
                    </div>
                    <div className="form-body pd-30 pd-t-10 pd-bt-10">
                        <Row gutter={20}>
                            <Col span={8}>
                                <div className="form-item">
                                    <AntForm.Item className={"form-item-item"} label={<span className="bold-400">{t("products.name")}</span>}>
                                        <div>{name}</div>
                                    </AntForm.Item>
                                </div>
                            </Col>

                            <Col span={8}>
                                <div className="form-item">
                                    <AntForm.Item className={"form-item-item"} label={<span className="bold-400">{t("products.code")}</span>}>
                                        <div>{code}</div>
                                    </AntForm.Item>
                                </div>
                            </Col>

                            <Col span={8}>
                                <div className="form-item">
                                    <AntForm.Item className={"form-item-item"} label={<span className="bold-400">{t("products.price")}</span>}>
                                        {getFieldDecorator('defaultPrice', {
                                            rules: [
                                                { required: true, message: t("message.required") }
                                            ],
                                            initialValue: defaultPrice
                                        })(
                                            <InputNumber placeholder={t("products.enter_price")} className="_product_price" style={{width: '100%'}} />
                                        )}
                                    </AntForm.Item>
                                </div>
                            </Col>
                        </Row>

                        <Row gutter={20}>
                            <Col span={8}>
                                <div className="form-item">
                                    <AntForm.Item className={"form-item-item"} label={<span className="bold-400">{t("products.local_name")}</span>}>
                                        {getFieldDecorator('name', {
                                            rules: [
                                                { required: true, message: t("message.required") }
                                            ],
                                            initialValue: localName
                                        })(
                                            <Input className="_product_local_name" style={{width: '100%'}} />
                                        )}
                                    </AntForm.Item>
                                </div>
                            </Col>

                            <Col span={8}>
                                <div className="form-item">
                                    <AntForm.Item className={"form-item-item"} label={<span className="bold-400">{t("products.manufactory")}</span>}>
                                        <div>{manufactory}</div>
                                    </AntForm.Item>
                                </div>
                            </Col>

                            <Col span={8}>
                                <div className="form-item">
                                    <AntForm.Item className={"form-item-item"} label={<span className="bold-400">{t("products.sell_status")}</span>}>
                                        {getFieldDecorator('active', { valuePropName: 'checked' })(
                                            <>
                                                <span className={`status-text ${lang}`}>{statusText}</span> <Switch checked={active} onChange={onChangeStatus} size="small" className="_product_status" />
                                            </>
                                        )}
                                    </AntForm.Item>
                                </div>
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col span={24}>
                                <div className="form-item">
                                    <AntForm.Item className={"form-item-item"} label={<span className="bold-400">{t("products.description")}</span>}>
                                        <div dangerouslySetInnerHTML={{__html: description}}></div>
                                    </AntForm.Item>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>

                <div className="white-box">
                    <Tabs defaultActiveKey="1" animated={false}>
                        <TabPane tab={t("products.properties")} key="property">
                            <div className="pd-20 pd-t-0">
                                <TabProperty {...this.props}/>
                            </div>
                        </TabPane>
                        {UserService.isAllowed(CAMPAIGNS_SCOPES.CAMPAIGNS_PRODUCT_ASSIGN) && (
                            <TabPane tab={t("products.permission")} key="permission">
                                <div className="pd-20 pd-t-0">
                                    <TabPermission {...this.props}/>
                                </div>
                            </TabPane>
                        )}
                    </Tabs>
                </div>
            </>
        )
    }
}

export {Form};