import React, {Component} from 'react';
import {Card} from 'antd';
import {objectGet} from "../../../utils/helper";

class SummaryProduct extends Component {

    renderRow = (key, value) => {
        return (
            <div className="info-row">
                <label className="label bold-400">{key}: </label>
                <span className="text">{value}</span>
            </div>
        );
    };

    render() {
        const {t, contact} = this.props;
        let product = objectGet(contact, 'product', {});
        return (
            <Card
                className={"_summary_info_box_container"}
                title={t('products.product')}
            >
                {this.renderRow(t('products.name'), objectGet(product, 'name', '---'))}
                {this.renderRow(t('products.manufactory'), objectGet(product, 'manufactory', '---'))}
                {this.renderRow(t('products.price'), objectGet(product, 'defaultPrice', '---'))}
            </Card>
        )
    }
}

export {SummaryProduct};