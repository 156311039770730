import React from 'react';
import {translate} from 'react-i18next';
import StandardLayout from "../../components/StandardLayout";
import {Header} from "./Header";
import {Filter} from "./Filter";
import {List} from "./List";
import * as _ from "lodash";
import {LocalStore} from "../../utils/LocalStore";
import {doCallBack, getCurrentModule, getParamsAsObject, getUrlQueryString} from "../../utils/helper";
import UserService from "../../services/UserService";
import {GROUPS_SCOPES, WAREHOUSE_SCOPES} from "../../utils/api/Scopes";
import {Url} from "../../utils/Url";
import VendorService from "../../services/VendorService";
import WareHouseService from "../../services/WareHouseService";
import {PopupCreateWarehouse} from "./PopupCreateWarehouse";
import WarehouseService from "../../services/WareHouseService";
import {notification} from "antd/lib/index";

class Warehouse extends React.Component {
    constructor(props) {
        super(props);
        this.moduleName = 'warehouse';
        this.mounted = false;
        this.state = {
            warehouses: [],
            vendor: LocalStore.getInstance().read('vendor'),
            currentPage: 1,
            totalItems: 0,
            pageSize: 100,
            pageCount: 0,
            loading: false,
            users: [],
            initialUsers: [],
            visible: false
        };
    }

    componentWillMount() {
        if (!UserService.isAllowed(WAREHOUSE_SCOPES.WAREHOUSE_VIEW)) {
            this.props.history.replace('/403')
        }
    };

    componentDidMount = () => {
        this.mounted = true;
        window.addEventListener("popstate", this.onPopstate.bind(this), false);
        this.loadData();
    };

    componentWillUnmount() {
        this.mounted = false;
        window.removeEventListener("popstate", this.onPopstate.bind(this), false);
    }

    onPopstate() {
        // Url thay đổi thì chỉ load lại list
        if(this.mounted && getCurrentModule(null, true) === this.moduleName) {
            let queryObject = getParamsAsObject(getUrlQueryString());
            this.fetchWarehouses(queryObject);
        }
    };

    fetchWarehouses = (filter = {}, cb) => {
        filter.vendorIds = this.state.vendor.id;
        WareHouseService.getAllWarehouses(filter, (error, response) => {
            if(!error) {
                let items = response.list;
                this.setState({
                    warehouses: items,
                    currentPage: response.metadata.currentPage+1,
                    totalItems: response.count,
                    pageSize: response.metadata.pageSize,
                    pageCount: response.metadata.pageCount
                });
            }
            doCallBack(cb, [error, response]);
        });
    };

    loadData = () => {
        let queryObject = getParamsAsObject(getUrlQueryString());
        this.fetchWarehouses(queryObject);
        this.fetchUsers({}, (error, response) => {
            if(!error) {
                this.setState({initialUsers: response.list});
            }
        });
    };

    fetchUsers = (filter = {}, cb) => {
        this.setState({loadingCreatedBy: true});
        const {vendor} = this.state;
        VendorService.getUserInVendor(vendor.id, filter, (error, response) => {
            this.setState({loadingCreatedBy: false});
            if(!error) {
                this.setState({
                    users: response.list
                });
            }
            doCallBack(cb, [error, response]);
        });
    };

    pushQueryString = (queryString) => {
        this.props.history.push(`/warehouse${queryString}`);
    };

    getUrlInstance = (newInstance = false) => {
        if(newInstance) {
            return new Url();
        }
        return new Url('?'+getUrlQueryString());
    };

    resetData = (key) => {
        let initialKey = `initial${_.upperFirst(key)}`;
        this.setState({
            [key]: this.state[initialKey]
        })  ;
    };

    onShowSizeChange = (current, pageSize) => {
        let uri = this.getUrlInstance();
        uri.replaceParam('page', 1);
        uri.replaceParam('pageSize', pageSize);
        this.pushQueryString(uri.getQueryString());
    };

    onChangePage = (page, pageSize) => {
        let uri = this.getUrlInstance();
        uri.replaceParam('page', page);
        uri.replaceParam('pageSize', pageSize);
        this.pushQueryString(uri.getQueryString());
    };

    isShowPopUpCreateWarehouse = () => {
        this.setState({
            visible: !this.state.visible
        })
    };

    handleCreateWarehouse = (data) => {
        const {t} = this.props;
        WarehouseService.createWarehouse(data, (err, res) => {
            if (err === null) {
                notification.success({
                    message: t('message.success')
                });
                if(UserService.isAllowed(WAREHOUSE_SCOPES.WAREHOUSE_STOCKS_VIEW)) {
                    this.props.history.push(`/warehouse/${res.code}/stocks`);
                } else {
                    if(UserService.isAllowed(WAREHOUSE_SCOPES.WAREHOUSE_ISSUES_VIEW)) {
                        this.props.history.push(`/warehouse/${res.code}/issues`);
                    } else if(UserService.isAllowed(WAREHOUSE_SCOPES.WAREHOUSE_RECEIPTS_VIEW)) {
                        this.props.history.push(`/warehouse/${res.code}/receipts`);
                    } else {
                        this.props.history.push(`/warehouse/${res.code}/permissions`);
                    }
                }


            } else {
                notification.error({
                    message: err.message
                });
            }
        });
    };

    render() {
        const {t} = this.props;
        return (
            <StandardLayout {...this.props} title={t("warehouse.title")}>
                <div className="warehouse-list-page list-page new-list-page">
                    <Header
                        {...this.props}
                        isShowPopUpCreateWarehouse={this.isShowPopUpCreateWarehouse}
                    />

                    <Filter
                        {...this.props}
                        resetData={this.resetData}
                        getUrlInstance={this.getUrlInstance}
                        pushQueryString={this.pushQueryString}
                    />

                    <List
                        {...this.props}
                        {...this.state}
                        onShowSizeChange={this.onShowSizeChange}
                        onChangePage={this.onChangePage}
                    />

                    {this.state.visible && (
                        <PopupCreateWarehouse
                            {...this.props}
                            {...this.state}
                            handleCreateWarehouse={this.handleCreateWarehouse}
                            isShowPopUpCreateWarehouse={this.isShowPopUpCreateWarehouse}
                        />
                    )}
                </div>
            </StandardLayout>
        );
    }
}

export default (translate())(Warehouse);