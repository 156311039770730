import React, {Component} from 'react';
import {Button, Col, Icon, Input, Row, Select} from 'antd';
import * as _ from 'lodash';

class Filter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            keyword: "",
            groupCode:[],
            createdBy: []
        };
    }

    onChangeInput = (key, e) => {
        if(key === 'groupCode') {
            this.setState({
                groupCode: e
            })
            return;
        }
        this.setState({
            [key]: e.target.value.trim()
        });
    };

    onFilter = () => {
        this.props.onFilter({
            keyword: this.state.keyword,
            created_by: _.map(this.state.createdBy, 'key').join(','),
            group_code: this.state.groupCode.toString()
        });
    };

    resetStateFilter = (cb = null) => {
        this.setState({
            keyword: "",
            groupCode: [],
            createdBy: []
        }, cb)
    };

    clearFilter = () => {
        this.resetStateFilter(() => {
           this.onFilter();
        });
    };

    getFilterCon = (key) => {
        if(key === "keyword"){
            return this.state.keyword?this.state.keyword:""
        }else if(key === "createdBy"){
            return this.state.createdBy?_.map(this.state.createdBy, 'key').join(','):""
        }
    };

    render() {
        const {t, loadingProductGroup, productGroup} = this.props;
        const {keyword, groupCode} = this.state;
        return (
            <div className="product-filter filter _product-filter">
                <div>
                    <Row gutter={20} className="form-filter">
                        <Col span={24} style={{paddingRight: 6, display:'flex', alignItems: 'center'}}>
                            <Col span={8} className="form-filter-control">
                                <label>{t("products.name_or_code")}</label>
                                <Input
                                    className={"_filter_keyword filter-input mg-r-5"}
                                    placeholder={t("products.name_or_code")}
                                    suffix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    value={keyword}
                                    onChange={this.onChangeInput.bind(this, 'keyword')}
                                    onPressEnter={this.onFilter}
                                    style={{width: '80%', marginRight: 5}}
                                />
                            </Col>

                            <Col span={8} className="form-filter-control">
                                <label>{t("product.product_group")}</label>
                                <Select 
                                    loading={loadingProductGroup} 
                                    placeholder={t("product.select_product_group")} 
                                    value={groupCode ? groupCode : []}
                                    style={{width: '100%', marginRight: 10}}
                                    mode={'multiple'}
                                    onChange={this.onChangeInput.bind(this, 'groupCode')}
                                    maxTagCount={1}
                                    filterOption={(inputValue, option) => {
                                        return option.props.children.toLowerCase().includes(inputValue.toLowerCase())
                                    }}
                                >
                                    {
                                        productGroup && productGroup.map(productGroup => {
                                            return (<Select.Option key={productGroup.code} value={productGroup.code}>{productGroup.name}</Select.Option>)
                                        })
                                    }
                                </Select>
                            </Col>

                            <Col span={8}>
                                <div style={{display: 'inline-block', float:'right'}}>
                                    <Button type="primary"  onClick={this.onFilter} className="_filter_button" style={{marginRight:5}}>{t("vendor.filter_button")}</Button>
                                    <Button onClick={this.clearFilter} className="_clear_button">{t("vendor.clear_button")}</Button>
                                </div>
                            </Col>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export {Filter}
