import React from 'react';
import {Modal, Table} from 'antd';
import ContactService from "../../services/ContactService";
import {objectGet} from "../../utils/helper";

class PopupConfirmCreateContact extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
    }

    onSubmit = () => {
        const {contact, onOk} = this.props;
        this.setState({loading: true});
        let data = contact;
        data.confirmCreate = true;
        ContactService.createPhoneContacts(data, (error, response) => {
            this.setState({loading: false});
            onOk(error, response);
        });
    };

    onCancel = () => {
        this.props.onCancel();
    };

    render() {
        const {visible, t, dataExisted} = this.props;
        const {loading} = this.state;
        const columns = [
            {title: t('project.title'), dataIndex: 'projectId', key: 'projectId', render: (text, record) => {
                return <span>{objectGet(record, 'campaignProject.name')}</span>;
            }},
            {title: t('campaign.title'), dataIndex: 'campaignId', key: 'campaignId', render: (text, record) => {
                return <span>{objectGet(record, 'campaign.name')}</span>;
            }},
            {title: t('project_list.count_adset'), dataIndex: 'adsetId', key: 'adsetId', render: (text, record) => {
                return <span>{objectGet(record, 'adset.name')}</span>;
            }},
            {title: t('project_list.count_ad'), dataIndex: 'adId', key: 'adId', render: (text, record) => {
                return <span>{objectGet(record, 'ad.name')}</span>;
            }}
        ];
        return (
            <Modal
                className="_modal_confirm_create_contact"
                title={t('message_add_contact.title')}
                okText={t('button.save')}
                cancelText={t('button.cancel')}
                onOk={this.onSubmit}
                onCancel={this.onCancel}
                visible={visible}
                okButtonProps={{
                    loading: loading,
                    className: "_btn_submit_create_contact"
                }}
                cancelButtonProps={{
                    className: "_btn_cancel_create_contact"
                }}
            >
                <Table
                    columns={columns}
                    dataSource={dataExisted}
                    rowKey={record => record.id}
                    pagination={false}
                />
            </Modal>
        );
    }
}

export {PopupConfirmCreateContact};