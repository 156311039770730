// components/NotFound.js
import React from 'react';
import lang from "../../resources/localization/Localization";
import {Link} from "react-router-dom";

// TODO: need remake up this page
const AccessDenied = () =>
        <div className="error-page">
            <h2 className="headline text-yellow"> 403</h2>
            <div className="error-content">
                <h3><i className="fa fa-warning text-yellow" />{lang.error.error403}.</h3>
                <div>{lang.error['Access Denied']}.</div>
                <div><Link to={"/"}>{lang.button.back_to_home}</Link></div>
            </div>
        </div>;

export default AccessDenied;