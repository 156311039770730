import React, {Component} from 'react';
import {notification} from 'antd';

import {Filter} from "./Filter";
import {LocalStore} from "../../../utils/LocalStore";
import UserService from "../../../services/UserService";
import {List} from "./List";
import ProductService from "../../../services/ProductService";
import * as _ from "lodash";
import {arrayConcat} from "../../../utils/helper";
import VendorService from "../../../services/VendorService";
import {CAMPAIGNS_SCOPES} from "../../../utils/api/Scopes";

class Product extends Component {

    constructor(props) {
        super(props);
        this.state = {
            vendor: LocalStore.getInstance().read('vendor'),
            currentPage: 1,
            totalItems: 50,
            pageSize: 100,
            pageCount: 0,
            initialUsers: [],
            products: [],
            users: [],
            loadingProduct: true,
            loadingUser: true,
        };
        this.filterProduct = null;
    }

    componentDidMount = () => {
        this.loadData();
    };

    resetDataSource = (key) => {
        this.setState({
            [key]: this.state[`initial${_.upperFirst(key)}`]
        });
    };

    loadData = () => {
        this.fetchProduct({});
        this.fetchUser({}, (error, response) => {
            if(!error) {
                this.setState({
                    initialUsers: response.list
                });
            }
        })
    };

    fetchUser = (filter = {}, cb) => {
        this.setState({loadingUser: true});
        const {vendor} = this.state;
        VendorService.getUserInVendor(vendor.id, filter, (error, response) => {
            if(!error) {
                this.setState({
                    loadingUser: false,
                    users: response.list,
                });
            }
            if(typeof cb === 'function') cb(error, response);
        });
    };

    fetchProduct = (filter = {}, cb) => {
        this.setState({loadingProduct: true});
        let {vendor} = this.state;
        filter = {
            order: ["createdAt DESC"],
            ...filter
        };
        VendorService.getProducts(vendor.id, filter, (error, response) => {
            if(!error) {
                this.setState({
                    products: response.list,
                    loadingProduct: false,
                    currentPage: response.metadata.currentPage,
                    totalItems: response.count,
                    pageSize: response.metadata.pageSize,
                    pageCount: response.metadata.pageCount
                });
            }
            if(typeof cb === 'function') cb(error, response);
        });
    };

    onToggleProductStatus = (id, product) => {
        const {t} = this.props;
        const {vendor} = this.state;
        if(UserService.isAllowed(CAMPAIGNS_SCOPES.CAMPAIGNS_PRODUCT_UPDATE)) {
            const data = {active: !product.active}
            VendorService.updateProduct(vendor.id, id, data,(err, response) => {
                if (!err) {
                    notification.success({
                        message: t("message.success"),
                    });
                    let products = this.state.products;
                    for(let item of products) {
                        if(item.id === id) {
                            item.active = response.active;
                        }
                    }
                    this.setState({products});
                } else {
                    if (err.code === 'PACKAGE_VALIDATE') {
                        notification.error({
                            message: t("message.upgrade_premium"),
                        });
                    } else {
                        notification.error({
                            message: err.message,
                        });
                    }
                }
            });
        } else {
            notification.error({
                message: t("error.Access Denied"),
            });
        }
    };

    onShowSizeChange = (current, pageSize) => {
        this.fetchProduct({
            page: 1,
            page_size: pageSize,
            keyword:this.filterProduct.getFilterCon("keyword"),
            created_by:this.filterProduct.getFilterCon("createdBy"),
        });
    };

    onChangePage = (page, pageSize) => {
        this.fetchProduct({
            page: page,
            page_size: pageSize,
            keyword:this.filterProduct.getFilterCon("keyword"),
            created_by:this.filterProduct.getFilterCon("createdBy"),
        });
    };

    onFilter = (filter = {}, cb) => {
        this.fetchProduct(filter, cb);
    };

    setRefFilter = (ref) => {
        this.filterProduct = ref;
    };

    render() {
        const {vendor, totalItems, currentPage, pageSize,pageCount, users, products, loadingProduct} = this.state;

        return (
            <div className="product-container _product-container">
                <Filter
                    {...this.props}
                    users={users}
                    resetDataSource={this.resetDataSource}
                    onFilter={this.onFilter}
                    fetchUser={this.fetchUser}
                    currentPage={currentPage}
                    pageSize={pageSize}
                    pageCount={pageCount}
                    totalItems={totalItems}
                    loading={loadingProduct}
                    ref={this.setRefFilter}
                />
                <List
                    {...this.props}
                    dataSource={products}
                    currentPage={currentPage}
                    pageSize={pageSize}
                    pageCount={pageCount}
                    totalItems={totalItems}
                    vendor={vendor}
                    onChangePage={this.onChangePage}
                    onShowSizeChange={this.onShowSizeChange}
                    loadingProduct={loadingProduct}
                    onToggleProductStatus={this.onToggleProductStatus}
                    getProductId={this.props.getProductId}
                    countProduct={this.props.countProduct}
                />
            </div>
        );
    }
}

export {Product}