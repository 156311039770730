import React from 'react'
import DocumentTitle from "react-document-title"
import {translate} from 'react-i18next';
import {LocalStore} from "../../utils/LocalStore";
import UserService from "../../services/UserService";


import loadingGif from '../../resources/images/loading.gif'
import errorPng from '../../resources/images/error.png'
import {AUTH} from "../../config/auth";

class Authentication extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            isNotPermission: false,
            msg: 'Đang kiểm tra xác thực, vui lòng đợi'
        }
    }

    componentWillMount () {
        const {history, t} = this.props;
        let queryString = history.location.search || '';
        if (queryString.indexOf('?') > -1) {
            queryString = queryString.replace(/\?/g, '')
        }

        const params = this.parseQueryStringToObject(queryString);

        // reset loginSession
        LocalStore.getInstance().save('loginSession', null);
        LocalStore.getInstance().save('vendor', null);
        LocalStore.getInstance().save("menuVendor", null);

        // check authentication
        if (params && params['access-token']) {
            //lưu access_token vào local
            LocalStore.getInstance().save('loginSession', {
                id: params['access-token'],
                userId: params['user-id']
            });
            this.setState({msg: t("login.redirectToDashboard")}, () => {
                UserService.showMe((err, me)=> {
                    if (!err) {
                        LocalStore.getInstance().save('loginSession', me.accessToken);
                        if(me.vendors.length !== 0){
                            LocalStore.getInstance().save("vendor", me.vendors[0]);
                        }
                        setTimeout(()=> {
                            let redirectPath = LocalStore.getInstance().read("fobizUrlBack") || "/#/";
                            LocalStore.getInstance().save("fobizUrlBack", null);
                            if(redirectPath.indexOf("user-not-in-any-vendor") > -1 && me.vendors.length !== 0){
                                window.location.href = "/#/";
                                return;
                            }
                            window.location.href = redirectPath;
                        }, 1000);
                    } else  {
                        LocalStore.getInstance().save('loginSession', null);
                        this.setState({
                            isNotPermission: true,
                            msg: err.message
                        })
                    }
                });
            })
        } else {
            this.setState({msg: t("login.redirectToLogin")}, () => {
                setTimeout(()=> {
                    history.replace("/login");
                }, 1000)
            });
        }
    }

    onClickLogout = async () => {
        await LocalStore.getInstance().save("loginSession", null);
        await LocalStore.getInstance().save("language", null);
        window.location = AUTH.LOGOUT_PATH;
    };

    /**
     * Parse query string to an object
     * @param queryString
     * @returns {{}}
     */
    parseQueryStringToObject( queryString ) {
        let params = {}, queries, temp, i, l;
        // Split into key/value pairs
        queries = queryString.split("&");
        // Convert the array of strings into an object
        for ( i = 0, l = queries.length; i < l; i++ ) {
            temp = queries[i].split('=');
            params[temp[0]] = temp[1];
        }
        return params;
    }

    render() {
        const {t} = this.props;
        return(
            <DocumentTitle title={`Xác thực`}>
                <div className={'splash-container'}>

                    {
                        !this.state.isNotPermission
                        ?
                        <div className={'text-center margin-auto'}>
                            <img src={loadingGif}/>
                            <p className="splash-text txt-color-black2 mgt25">
                                {this.state.msg}
                            </p>
                        </div>
                        :
                        <div className={'text-center margin-auto'}>
                            <div>
                                <img src={errorPng}/>
                            </div>
                            <div className="txt-center">
                                <p className="splash-text txt-color-black2 mgt20">
                                    {t("login.notPermission")}
                                </p>
                                <p className="splash-text txt-color-black2 mgt20">
                                    {t("login.contactAdmin")}
                                </p>
                                <div className={'mgt50'}>
                                    <a href="#" className="splash-text txt-color-blue2 ">
                                        <i className="fas fa-undo-alt mgr5"/> <span>{t("login.tryAgain")}</span>
                                    </a>
                                    <a onClick={this.onClickLogout} className="splash-text cursor-pointer txt-color-blue2 mgl25">
                                        <i className="fas fa-sign-out-alt mgr5"/> <span>{t("login.logout_btn")}</span>
                                    </a>
                                </div>

                            </div>
                        </div>
                    }
                </div>
            </DocumentTitle>
        );
    }
}

export default (translate())(Authentication);
