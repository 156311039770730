import React, {Component} from 'react';
import { Input } from 'antd';
import { Row, Col, Button, Empty } from 'antd';
import * as _ from "lodash";

const Search = Input.Search;

class FilterUser extends Component {

    constructor(props) {
        super(props);
        this.mounted = false;
        this.state = {
            keyword: ""
        };
    }

    componentDidMount = () => {

    };

    componentWillUnmount = () => {

    };

    onChangeInput = (key, e) => {
        this.setState({
            [key]: e.target.value
        });
    };

    onFilter = () => {
        this.props.onFilter(this.state.keyword);
    };

    clearFilter = () => {
        this.setState({
            keyword:''
        });
        this.props.onFilter('');
    };

    render() {
        const {keyword} = this.state;
        const {t} = this.props;
        return (
            <div className="username-filter filter">
                <Row gutter={24}>
                    <Col span={24} style={{fontWeight:600}}>{t("vendor.username")}:</Col>
                    <Col span={24} className="mg-t-10">
                        <Search
                            placeholder={t("vendor.username")}
                            onChange={this.onChangeInput.bind(this, 'keyword')}
                            onPressEnter={this.onFilter}
                            value={keyword}
                            className={"_input-search"}
                        />
                    </Col>
                    <Col span={6} className="mg-t-10 pd-t-5 username-filter__group-buttons">
                        <Button type="primary" style={{marginLeft:10}}  onClick={this.onFilter} className="_filter_button">{t("vendor.filter_button")}</Button>
                        <Button style={{marginLeft:10, fontWeight:600}} onClick={this.clearFilter} className="_clear_button">{t("vendor.clear_button")}</Button>
                    </Col>
                </Row>
            </div>
        );
    }
}

export {FilterUser}