import React, {Component} from 'react';
import {Col, Icon, Row, Table, Tag, Pagination, Popover, Checkbox, Button} from 'antd';
import * as moment from 'moment';
import {Link} from "react-router-dom";
import StockIssueService from "../../../../../services/StockIssueService";
import {toastr} from "../../../../../utils/toastr";

class List extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedRows: [],
            selectedRowKeys: [],
            sorter: {},
            loadingAddOrder: false
        };
    }

    componentDidMount = () => {

    };

    getConfigRowSelection = () => {
        return {
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({selectedRows,selectedRowKeys});
            }
        }
    };

    getColor = (status) => {
        if(status === "NEW"){
            return "cyan"
        }else if(status === "DRAFT"){
            return "green"
        }else if(status === "DELIVERED"){
            return "lime"
        }else if(status === "DELIVERING"){
            return "orange"
        }else if(status === "CANCELLED"){
            return "geekblue"
        }else if(status === "EXCHANGE"){
            return "red"
        }else{
            return null
        }
    };

    getNameById = (id) => {
        for(let i = 0; i < this.props.userCreatedBy.length; i++){
            if(this.props.userCreatedBy[i].id == id){
                return this.props.userCreatedBy[i].username
            }
        }
    };

    getConfigTableColumns = () => {
        const {t} = this.props;
        const renderColumn = (key, text, record) => {
            switch (key) {
                case 'createdAt':
                    return record.createdAt ? moment(record.createdAt).format("DD/MM/YY HH:mm") : "";
                case 'deliveryDate':
                    return record.deliveryDate ? moment(record.deliveryDate).format("DD/MM/YY HH:mm") : "";
                case "paid":
                    return record.paid ? t("order_list.paid_status") : t("order_list.unpaid_status");
                case "status":
                    return <Tag color={this.getColor(record.status)}>{record.status}</Tag>;
                case "stock_issue":
                    return <span style={{color: "#337ab7"}}>{record.issueId?record.issueId:"none"}</span>;
                case "createdBy":
                    return <span className="pointer _filter-createdBy-table" style={{color: "#337ab7"}} onClick={this.props.onFilterByName.bind(this,record.createdBy)}>{this.getNameById(record.createdBy)}</span>;
                case "assignTo":
                    return <span>{record.assignTo?record.assignTo:t("order_list.unassign_to")}</span>;
                case "campaign":
                    return <span className="nowrap-css">{record.campaign.name}</span>;
                case "code":
                    return <Link className="nowrap-css" to={`/orders/${record.id}`}>{record.code}</Link>;
                case "contactName":
                    return <span className="nowrap-css">{record.contactName}</span>;
            }
        };

        let columns = [
            {title: t("order_list.code"),dataIndex: 'code', key: 'code',sorter: true,sortDirections: ['descend','ascend','descend'],render: renderColumn.bind(this, 'code'),width:200,className:"_code _item"},
            {title: t("order_list.campaign"),dataIndex: 'campaign', key: 'campaign',render: renderColumn.bind(this, 'campaign'),width:200,className:"_campaign _item"},
            {title: t("order_list.customerName"),dataIndex: 'contactName', key: 'contactName',render: renderColumn.bind(this, 'contactName'),sorter: true,sortDirections: ['descend','ascend','descend'],width:200,className:"_contactName _item"},
            {title: t("order_list.customerPhone"),dataIndex: 'contactPhone', key: 'contactPhone',width:200,className:"_contactPhone _item"},
            {title: t("order_list.assign_to"),dataIndex: 'assignTo', key: 'assignTo',render: renderColumn.bind(this, 'assignTo'),width:200,className:"_assignTo _item"},
            {title: t("order_list.province"),dataIndex: 'provinceName', key: 'provinceName',sorter: true,sortDirections: ['descend','ascend','descend'],width:200,className:"_provinceName _item"},
            {title: t("order_list.district"),dataIndex: 'cityName', key: 'cityName',sorter: true,sortDirections: ['descend','ascend','descend'],width:200,className:"_cityName _item"},
            {title: t("order_list.created_at"),dataIndex: 'createdAt', key: 'createdAt',sorter: true,sortDirections: ['descend','ascend','descend'],render: renderColumn.bind(this, 'createdAt'),width:200,className:"_createdAt _item"},
            {title: t("order_list.deliveryDate"),dataIndex: 'deliveryDate', key: 'deliveryDate',sorter: true,sortDirections: ['descend','ascend','descend'], render: renderColumn.bind(this, 'deliveryDate'),width:200,className:"_deliveryDate _item"},
            {title: t("order_list.createdBy"),dataIndex: 'createdBy', key: 'createdBy',render: renderColumn.bind(this, 'createdBy'),width:200,className:"_createdBy _item"},
            {title: t("order_list.status"),dataIndex: 'status', key: 'status',render: renderColumn.bind(this, 'status'),width:200,className:"_status _item"},
            {title: t("order_list.stock_issue"),dataIndex: 'issueId', key: 'issueId',render: renderColumn.bind(this, 'stock_issue'),width:200,className:"_issueId _item"},
            {title: t("order_list.payment_status"),dataIndex: 'paid', key: 'paid',render: renderColumn.bind(this, 'paid'),width:200,className:"_paid _item"},
        ];

        return columns;
    };

    onCheckBox = (priority) => {
        this.props.isShowCheckbox(priority);
    };

    onChange = (pagination, filters, sorter)=>{
        let sortCondition = sorter.order;
        if(sortCondition === "descend"){
            sortCondition = "DESC";
        }else if(sortCondition === "ascend"){
            sortCondition = "ASC";
        }
        let uri = this.props.getUrlInstance();
        uri.replaceParam('order', `["${sorter.field} ${sortCondition}"]`);
        this.props.pushQueryString(uri.getQueryString());
    };

    submitAddOrder = () => {
        const {t} = this.props;
        const {selectedRows} = this.state;
        this.setState({loadingAddOrder: true});
        this.props.addOrder(selectedRows, (error, response) => {
            this.setState({loadingAddOrder: false});
            if (error === null) {
                toastr.success(t("message.success"));
                this.props.history.push(`/warehouse/issues/${this.props.getId()}`);
            }
            else {
                toastr.error(error.message);
            }
        });
    };

    render() {
        const {t, loading} = this.props;
        const {dataSource,currentPage,totalItems,pageSize,onShowSizeChange,onChangePage,pageCount} = this.props;
        const {loadingAddOrder, selectedRows} = this.state;
        let columns = this.getConfigTableColumns();
        let columnsInvisible = [];

        for (let i = 0; i < columns.length; i++) {
            if(this.props.visibleColumnsDisplay.indexOf(columns[i].key) > -1){
                columnsInvisible.push(columns[i]);
            }
        }

        const rowSelection = this.getConfigRowSelection();

        let content = this.props.visibleColumns.map((contact) =>
            <div className={`mg-bt-10 mg-r-10 mg-l-10 ${contact.name} _checkbox-item`} key={contact.priority}>
                <Checkbox onChange={this.onCheckBox.bind(this,contact.priority)} checked={contact.selected}>{t(contact.language)}</Checkbox>
                <br />
            </div>
        );

        return (
            <div className="list-container">
                <Row className="pd-bt-15">
                    <Col span={5}>
                        {!loading && !!totalItems && (
                            <span>{t("contact.page")} {currentPage}/{pageCount} ({totalItems})</span>
                        )}

                        {!loading && !totalItems && (
                            <span>{t("contact.page")} {1}/{1} ({totalItems})</span>
                        )}
                    </Col>
                    <Col span={19}>
                        <div className="flr">
                            <Button
                                type="primary"
                                className="mg-r-10"
                                onClick={this.submitAddOrder}
                                loading={loadingAddOrder}
                                disabled={!selectedRows.length}
                            >
                                {t('stock_issue.finish')}
                            </Button>
                            <Popover placement="bottomLeft" trigger="click" content={content}><Icon type="setting" className="pointer" /></Popover>
                        </div>
                    </Col>
                </Row>

                <Table
                    columns={columnsInvisible}
                    dataSource={dataSource}
                    rowKey={record => record.id}
                    pagination={false}
                    rowSelection={rowSelection}
                    scroll={{ x: 3000}}
                    loading={loading}
                    onChange={this.onChange}
                    locale={{
                        emptyText: t("table.empty")
                    }}
                />

                {!loading && totalItems > 0 && (
                    <Pagination
                        className="mg-t-15 text-r"
                        showSizeChanger
                        onShowSizeChange={onShowSizeChange}
                        onChange={onChangePage}
                        pageSizeOptions={['25', '50', '100', '150', '200']}
                        defaultCurrent={1}
                        current={currentPage}
                        pageSize={pageSize}
                        total={totalItems}
                    />
                )}
            </div>
        );
    }
}

export {List}