import React from 'react';
import {Table, Pagination, Button, Row, Col, Icon, Modal, Menu, Dropdown} from 'antd';
import {WAREHOUSE_SCOPES} from "../../../../../utils/api/Scopes";
import UserService from "../../../../../services/UserService";
import {notification} from "antd/lib/index";
import * as _ from "lodash";
import {PopupCreateProduct} from "./Add/PopupCreateProduct";
import {PopupUpdateProduct} from "./Update/PopupUpdateProduct";
import {doCallBack} from "../../../../../utils/helper";
import ProductService from "../../../../../services/ProductService";
import StockReceiptService from "../../../../../services/StockReceiptService";
import {formatCurrency} from "../../../../../utils/currency";

class ProductList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isShowPopOver: false,
            isShowModalDeleteReceiptProduct: false,
            isShowPopUpCreateProduct: false,
            idGroupDeleted: '',
            listProductInWarehouse: [],
            listProductInWarehouseDefault: [],
            loadingProductInWarehouse: false,
            isShowPopUpUpdateProduct: false,
            receiptProductUpdate: []
        };
    }

    NestedTable = (record) => {
        let idRow = record.id;
        const {t} = this.props;
        const renderColumn = (key, text, record) => {
            switch (key) {
                case 'price':
                    return (
                        <span>{formatCurrency(record.price)}</span>
                    )

            }
        };
        const columns = [
            {title: t("products.properties"), dataIndex: 'display', key: 'display',width: '20%',className:"_properties _item"},
            {title: 'SKU', dataIndex: 'sku', key: 'sku',width: '20%',className:"_sku _item"},
            {title: '', dataIndex: 'quantity', key: 'quantity',width: '20%',className:"_quantity _item"},
            {title: t("ads.price"), dataIndex: 'price', key: 'price',width: '20%',className:"_price _item",render: renderColumn.bind(this, 'price')},
            {title: "",dataIndex: 'empty_action', key: 'empty_action',className:"_action _item", width:"20%"},
        ];

        const data = [];
        if(this.props.receiptProducts.length){
            let items = this.props.receiptProducts;
            for (let j = 0; j < items.length; j++) {
                for (let i = 0; i < items[j].productSku.length; i++) {
                    if(items[j].id === idRow){
                        data.push({
                            display: items[j].productSku[i].display,
                            sku: items[j].productSku[i].sku,
                            quantity: items[j].productSku[i].quantity,
                            price: items[j].productSku[i].price
                        })
                    }
                }
            }
        }

        return (
            <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                rowKey={record => record.sku}
            />
        );
    };

    showModalDeleteProduct = (id) => {
        this.setState({
            isShowModalDeleteReceiptProduct: true,
            idProductDeleted: id
        });
    };

    getConfigTableColumns = () => {
        const {t} = this.props;
        const renderColumn = (key, text, record) => {
            switch (key) {
                case 'action':
                    const menu = (
                        <Menu>
                            {UserService.isAllowed(WAREHOUSE_SCOPES.WAREHOUSE_UPDATE) && (
                                <Menu.Item key="1">
                                    <span onClick={this.showPopupUpdateProduct.bind(this,true,record.id)}><Icon type="edit" /> {t("table.action_edit")}</span>
                                </Menu.Item>
                            )}
                            {UserService.isAllowed(WAREHOUSE_SCOPES.WAREHOUSE_UPDATE) && (
                                <Menu.Item key="2">
                                    <span onClick={this.showModalDeleteProduct.bind(this,record.id)}><Icon type="delete" /> {t("table.action_delete")}</span>
                                </Menu.Item>
                            )}
                        </Menu>
                    );
                    return (
                        <Dropdown overlay={menu} trigger={['contextMenu', 'click']}>
                            <span className="pointer"><Icon type="dash" /></span>
                        </Dropdown>
                    );
            }
        };
        return [
            {title: t("product_list.name"),dataIndex: 'name', key: 'name',className:"_name _item", width:"20%"},
            {title: "",dataIndex: 'empty_sku', key: 'empty_sku',className:"_empty_sku _item", width:"20%"},
            {title: t("ads.quantity"),dataIndex: 'totalQuantity', key: 'totalQuantity',className:"_totalQuantity _item", width:"20%"},
            {title: "",dataIndex: 'empty_quantity', key: 'empty_quantity',className:"_empty_quantity _item", width:"20%"},
            {title: "",dataIndex: 'action', key: 'action',render: renderColumn.bind(this, 'action'),className:"_action _item", width:"20%"},
        ];
    };

    handleCancelDeleteReceiptProduct = () => {
        this.setState({
            isShowModalDeleteReceiptProduct: false
        })
    };

    handleOkDeleteReceiptProduct = () => {
        let receiptProducts = _.cloneDeep(this.props.receiptProducts);
        let value = [];
        if(receiptProducts.length){
            for(let i = 0; i < receiptProducts.length; i++){
                if(receiptProducts[i].id !== this.state.idProductDeleted){
                    value.push(receiptProducts[i]);
                }
            }
        }

        this.handleCancelDeleteReceiptProduct();
        this.updateProduct(value);
    };

    showPopupCreateProduct = (active) => {
        if(active){
            this.fetchProductSku({},
                (context,response) => {
                    this.setState({
                        isShowPopUpCreateProduct: active,
                        listProductInWarehouseDefault: response.list
                    })
                }
            )
        }else{
            this.setState({
                isShowPopUpCreateProduct: active,
            })
        }
    };

    showPopupUpdateProduct = (active,id) => {
        if(active){
            let data;
            for(let i = 0; i < this.props.receiptProducts.length; i++){
                if(this.props.receiptProducts[i].id === id){
                    data = this.props.receiptProducts[i];
                }
            }
            this.setState({
                isShowPopUpUpdateProduct: active,
                receiptProductUpdate: data
            })
        }else{
            this.setState({
                isShowPopUpUpdateProduct: active,
            })
        }
    };

    fetchProductSku = (filter = {},cb) => {
        this.setState({
            loadingProductInWarehouse: true
        });
        let code = this.props.warehouse.code;
        let defaultFilter = {
            order: "id DESC",
            warehouse_code: code,
            include:["productSku"]
        };
        defaultFilter = Object.assign(defaultFilter, filter);
        ProductService.getProducts(defaultFilter, (error, response) => {
            if(!error) {
                this.setState({
                    listProductInWarehouse: response.list,
                    loadingProductInWarehouse: false
                });
            }
            doCallBack(cb, [error, response]);
        });
    };

    onFilterProduct = (name) => {
        this.fetchProductSku(name)
    };

    onCreateProduct = (id,listSku) => {
        const {t} = this.props;
        let warehouseProduct = id;
        let listProductSku = listSku;
        let listProductSkuInReceipt;
        let listProductSkuDefault;
        let listProductDefault;
        let productNotInReceipt = {};
        let skuNotInReceipt = {};
        let hasSku = false;
        let receiptProducts = _.cloneDeep(this.props.receiptProducts);

        let listProductInWarehouseDefault = this.state.listProductInWarehouseDefault;
        let flag = false;

        for(let i = 0; i < listProductSku.length; i++){
            if(listProductSku[i].price && listProductSku[i].quantity){
                hasSku = true
            }
        }

        if(!hasSku){
            notification.error({
                message: t('stock_receipt.validate_sku')
            });
            return false
        }


        for(let i = 0; i < receiptProducts.length; i++){
            if(receiptProducts[i].id === warehouseProduct.key){
                listProductSkuInReceipt = receiptProducts[i].productSku;
                flag = true
            }
        }
        if(!flag){
            productNotInReceipt.id = warehouseProduct.key;
            productNotInReceipt.name = warehouseProduct.label;
            let totalQuantity = 0;
            let productSku = [];
            let value = {};

            for(let i = 0; i < listProductInWarehouseDefault.length; i++){
                if(listProductInWarehouseDefault[i].id === warehouseProduct.key){
                    listProductSkuDefault = listProductInWarehouseDefault[i].productSku;
                }
            }

            for(let i = 0; i < listProductSku.length; i++){
                for(let j = 0; j < listProductSkuDefault.length; j++){
                    if(listProductSku[i].price && listProductSku[i].quantity && (listProductSku[i].sku === listProductSkuDefault[j].sku)){
                        totalQuantity = totalQuantity + listProductSku[i].quantity;
                        value.id = listProductSkuDefault[i].id;
                        value.display = listProductSkuDefault[i].display;
                        value.sku = listProductSkuDefault[i].sku;
                        value.price = listProductSku[i].price;
                        value.quantity = listProductSku[i].quantity;
                        value.amount = listProductSku[i].quantity*listProductSku[i].price;
                        productSku.push(value);
                        value = {};
                    }
                }
            }

            productNotInReceipt.totalQuantity = totalQuantity;
            productNotInReceipt.productSku = productSku;
            receiptProducts.push(productNotInReceipt);
        }else{
            let value2 = {};
            let productSku2 = [];

            for(let i = 0; i < receiptProducts.length; i++){
                if(receiptProducts[i].id === warehouseProduct.key){
                    listProductDefault = receiptProducts[i];
                }
            }

            for(let i = 0; i < listProductInWarehouseDefault.length; i++){
                if(listProductInWarehouseDefault[i].id === warehouseProduct.key){
                    listProductSkuDefault = listProductInWarehouseDefault[i].productSku;
                }
            }
            
            let SkuInReceiptProduct = [];
            for (let i = 0; i < listProductSkuInReceipt.length; i++){
                SkuInReceiptProduct.push(listProductSkuInReceipt[i].sku)
            }

            for(let i = 0; i < listProductSku.length; i++){
                for(let j = 0; j < listProductDefault.productSku.length; j++){
                    if(listProductSku[i].price && listProductSku[i].quantity && (listProductSku[i].sku === listProductDefault.productSku[j].sku)){
                        listProductDefault.productSku[j].price = listProductSku[i].price;
                        listProductDefault.productSku[j].quantity = listProductSku[i].quantity + listProductDefault.productSku[j].quantity;
                        listProductDefault.productSku[j].amount = listProductDefault.productSku[j].price*listProductDefault.productSku[j].quantity
                    }
                }
            }

            for(let i = 0; i < listProductSku.length; i++){
                if(listProductSku[i].price && listProductSku[i].quantity && SkuInReceiptProduct.indexOf(listProductSku[i].sku) < 0){
                    for(let j = 0; j < listProductSkuDefault.length; j++){
                        if(listProductSkuDefault[j].sku === listProductSku[i].sku){
                            value2.id = listProductSkuDefault[j].id;
                            value2.display = listProductSkuDefault[j].display;
                            value2.sku = listProductSkuDefault[j].sku;
                            value2.price = listProductSku[i].price;
                            value2.quantity = listProductSku[i].quantity;
                            value2.amount = listProductSku[i].quantity*listProductSku[i].price;
                            productSku2.push(value2);
                            value2 = {};
                        }
                    }
                }
            }

            for(let i = 0; i < productSku2.length; i++){
                skuNotInReceipt = productSku2[i];
                listProductDefault.productSku.push(skuNotInReceipt);
                skuNotInReceipt = {};
            }
        }
        this.showPopupCreateProduct(false);
        this.updateProduct(receiptProducts);
        return true
    };

    updateProduct = (data,cb) => {
        const {t} = this.props;
        let receiptId = this.props.receipt.id;
        StockReceiptService.updateProduct(receiptId,data,(error, response) => {
            if(!error) {
                notification.success({
                    message: t('message.success')
                });
                this.props.fetchStockReceiptDetail();
            }else{
                notification.error({
                    message: error.message
                });
            }
            doCallBack(cb, [error, response]);
        });
    };

    resetDataSource = () => {
        this.setState({
            listProductInWarehouse: this.state.listProductInWarehouseDefault
        });
    };

    onUpdateProduct = (id,listSku) => {
        const {t} = this.props;
        let listProductSku = listSku;
        let productId = id;
        let productReceipt = _.cloneDeep(this.state.receiptProductUpdate);
        let hasSku = false;

        for(let i = 0; i < listProductSku.length; i++){
            if(listProductSku[i].price && listProductSku[i].quantity){
                hasSku = true
            }
        }

        if(!hasSku){
            notification.error({
                message: t('stock_receipt.validate_sku')
            });
            return false
        }


        let skuHasPriceAndQuantity = [];

        for(let i = 0; i < listProductSku.length; i++){
            if(listProductSku[i].quantity && listProductSku[i].price){
                skuHasPriceAndQuantity.push(listProductSku[i].sku);
                for(let j = 0; j < productReceipt.productSku.length; j++){
                    if(listProductSku[i].sku === productReceipt.productSku[j].sku){
                        productReceipt.totalQuantity = productReceipt.totalQuantity - productReceipt.productSku[j].quantity + listProductSku[i].quantity;
                        productReceipt.productSku[j].quantity = listProductSku[i].quantity;
                        productReceipt.productSku[j].price = listProductSku[i].price;
                        productReceipt.productSku[j].amount = listProductSku[i].quantity*listProductSku[i].price;
                    }
                }
            }
        }

        let arr = [];
        for(let i = 0; i < productReceipt.productSku.length; i++){
            if(skuHasPriceAndQuantity.indexOf(productReceipt.productSku[i].sku) >= 0){
                arr.push(productReceipt.productSku[i]);
            }else{
                productReceipt.totalQuantity = productReceipt.totalQuantity - productReceipt.productSku[i].quantity
            }
        }
        productReceipt.productSku = arr;
        let listTotalReceiptProduct = _.cloneDeep(this.props.receiptProducts);
        for (let i = 0; i < listTotalReceiptProduct.length; i++){
            if(listTotalReceiptProduct[i].id === productId){
                listTotalReceiptProduct[i] = productReceipt;
            }
        }
        this.showPopupUpdateProduct(false);
        this.updateProduct(listTotalReceiptProduct);
        return true
    };

    clearValueWhenDelete = (sku) => {
        let receiptProductUpdate = _.cloneDeep(this.state.receiptProductUpdate);
        let listProductSku = receiptProductUpdate.productSku;
        for (let i = 0; i < listProductSku.length; i++){
            if(listProductSku[i].sku === sku){
                listProductSku[i].quantity = undefined;
                listProductSku[i].price = undefined;
                listProductSku[i].amount = undefined;
            }
        }
        this.setState({
            receiptProductUpdate:receiptProductUpdate
        })
    };

    render() {
        const {t, receiptProducts, loading} = this.props;
        const columns = this.getConfigTableColumns();
        return (
            <div className="order-list-table">
                <Row className="pd-bt-15">
                    <Col span={24} className="text-right">
                        <Button
                            type="primary"
                            className="_btn_add_product"
                            onClick={this.showPopupCreateProduct.bind(this,true)}
                            loading={this.state.loadingProductInWarehouse}
                        >
                            <span>{t('stock_receipts_detail.add_propduct')}</span>
                        </Button>
                    </Col>
                </Row>

                <Table
                    columns={columns}
                    dataSource={receiptProducts}
                    rowKey={record => record.id}
                    loading={loading}
                    locale={{
                        emptyText: t("table.empty")
                    }}
                    pagination={false}
                    expandedRowRender={this.NestedTable}
                />

                <Modal
                    visible={this.state.isShowModalDeleteReceiptProduct}
                    onOk={this.handleOkDeleteReceiptProduct}
                    onCancel={this.handleCancelDeleteReceiptProduct}
                    okText={t("popup-confirm.confirm")}
                    cancelText={t("popup-confirm.cancel")}
                >
                    <p>{t("warehouse.confirm_delete_message")}</p>
                </Modal>

                <PopupCreateProduct
                    {...this.props}
                    {...this.state}
                    isShowPopUpCreateProduct={this.state.isShowPopUpCreateProduct}
                    showPopupCreateProduct={this.showPopupCreateProduct}
                    onCreateProduct={this.onCreateProduct}
                    onFilterProduct={this.onFilterProduct}
                    resetDataSource={this.resetDataSource}
                />

                <PopupUpdateProduct
                    {...this.props}
                    {...this.state}
                    isShowPopUpUpdateProduct={this.state.isShowPopUpUpdateProduct}
                    receiptProductUpdate={this.state.receiptProductUpdate}
                    showPopupUpdateProduct={this.showPopupUpdateProduct}
                    onUpdateProduct={this.onUpdateProduct}
                    clearValueWhenDelete={this.clearValueWhenDelete}
                />
            </div>
        )
    }
}

export {ProductList}