import {Table} from "antd";
import * as moment from "moment";
import React from "react";
import lodash from 'lodash'

class DeliveryCaseTab extends React.Component {

    getConfigTableColumns = () => {
        const { t } = this.props;
        const renderColumn = (key, text, record) => {
            switch (key) {
                case 'id':
                    return record.id;
                case 'assigneeId':
                    return lodash.get(record, ['assignee', 'username']) || '---'
                case 'statusCode':
                    return record.statusCode;
                case 'createdAt':
                    return record.createdAt ? moment(record.createdAt).format("DD/MM/YY HH:mm") : "";
                case 'reason':
                    return record.reasonDetail ? record.reasonDetail : "---";
                case 'note':
                    return record.note ? record.note : "---";
                default:
                    break;
            }
        };

		return [
			{
				title: t("delivery_case.code"),
				dataIndex: 'id',
				key: 'id',
				render: renderColumn.bind(this, 'id')
			},
			{
				title: t("delivery_case.assignee"),
				dataIndex: 'assigneeId',
				key: 'assigneeId',
				render: renderColumn.bind(this, 'assigneeId')
			},
			{
				title: t("delivery_case.reason"),
				dataIndex: 'reason',
				key: 'reason',
				render: renderColumn.bind(this, 'reason')
			},
			{
				title: t("delivery_case.note"),
				dataIndex: 'note',
				key: 'note',
				render: renderColumn.bind(this, 'note')
			},
            {
				title: t("delivery_case.status"),
				dataIndex: 'statusCode',
				key: 'statusCode',
				render: renderColumn.bind(this, 'statusCode')
			},
			{
				title: t("delivery_case.createdAt"),
				dataIndex: 'createdAt',
				key: 'createdAt',
				render: renderColumn.bind(this, 'createdAt')
			},
		];
    };

    render(){
        const {order} = this.props;
        return (
            <div className="box-container">
                <div className="logs-container">
                    <Table
                        className="pd-t-10 pd-bt-50"
                        columns={this.getConfigTableColumns()}
                        dataSource={order && order.deliveryCases ? order.deliveryCases : []}
                        pagination={false}
                    />
                </div>
            </div>
        )
    }
}

export {DeliveryCaseTab};
