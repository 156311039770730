import React, {Component} from "react";
import DocumentTitle from 'react-document-title';
import ReactLoading from 'react-loading';
import logo from '../resources/images/logo.png'

class Loading extends Component {
    render() {
        return (
            <DocumentTitle title="Loading...">
                <div className="loading-layout">
                    <img src={logo} alt={"VelaOne"} className={"logo-image"}/>

                    <ReactLoading
                        type={'spinningBubbles'}
                        color={'#3498db'} height={64}
                        width={64}
                        delay={0}
                        className={'loading-icon'}/>
                    <span className={'fc-brownishGrey'}>Đang tải...</span>
                </div>
            </DocumentTitle>
        );
    }
}


export default Loading;
