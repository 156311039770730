import React, {Component} from 'react';
import {Button, Col, Icon, Input, notification, Pagination, Row, Select, Table, Tooltip, Upload} from 'antd';
import * as moment from 'moment';
import lodash from 'lodash'
import {Link} from 'react-router-dom';
import {API} from '../../config/api';
import {LocalStore} from '../../utils/LocalStore';
import {STATUS_UPLOAD_FILE} from '../../utils/const';
import UserService from "../../services/UserService";
import {DELIVERYCASE} from '../../utils/api/Scopes';


const { TextArea } = Input;
const InputGroup = Input.Group;

class List extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loadingUpload: false,
            selectedRows: 0,
            sorter: {},
			isChangeNote: false,
			isChangeTrackingNumber: false
        };
    }

    componentDidMount = () => {

    };

    onSelectChange = (key, record, event) => {
        const {t,updateDeliveryCase, listReason, setDataSource, dataSource} = this.props;
        if(key === 'assigneeId'){
            updateDeliveryCase({
				id: record.id,
				[key]: event
            })
            return;
        }
        if(key === 'statusCode'){
            updateDeliveryCase({
				id: record.id,
				[key]: event
            })
            return;
        }
        if(key === 'reasonId'){
            if(event === ''){
                updateDeliveryCase({
					id: record.id,
					[key]: null,
					reasonDetail: ''
                })
                return;
            }

            const reasonDetail = listReason.find(reason => reason.id === event);
            if(reasonDetail.name !== 'Others'){
                updateDeliveryCase({
					id: record.id,
					[key]: event, reasonDetail: reasonDetail.name
                })
                return;
            }


            if(reasonDetail.name === 'Others' && record.note){
                updateDeliveryCase({
					id: record.id,
					[key]: event,
					reasonDetail: reasonDetail.name
                });
                return;
            }

            if(reasonDetail.name === 'Others' && !record.note){
                notification.warning({
                    message: t("delivery_case.note_not_empty")
                });

                const newDataSource = [...dataSource].map(data => {
                    if(data.id === record.id){
                        data.reasonId = event;
                    }
                });

                document.querySelector(`#note-${record.id}`).focus();

                setDataSource(newDataSource);
            }
        }
    }

    onTextAreaChange = (record, event) => {
        const {dataSource, setDataSource} = this.props;

        const newDataSource = [...dataSource].map(data => {
            data['isChangeNote'] = false;
            if(data.id === record.id){
                data.note = event.target.value
                data['isChangeNote'] = true;
            }
            return data;
        });

        setDataSource(newDataSource);
    }

	onChangeTrackingNumber = (record, event) => {
		const {dataSource, setDataSource} = this.props;

		const newDataSource = [...dataSource].map(data => {
			data['isChangeTrackingNumber'] = false;
			if(data.id === record.id){
				data.trackingNumber = event.target.value
				data['isChangeTrackingNumber'] = true;
			}
			return data;
		});

		setDataSource(newDataSource);
	}

    onSaveNote = (record) => {
        const {t} = this.props;
        if(record.note && record.isChangeNote){
            const {updateDeliveryCase} = this.props;
            updateDeliveryCase({
				id: record.id,
				note: record.note
			});
        }
        if(!record.note){
            notification.warning({
                message: t("delivery_case.note_not_empty")
            })
        }
    }

	onSaveTrackingNumber = (record) => {
		if(record.isChangeTrackingNumber){
			const {updateDeliveryCase} = this.props;
			updateDeliveryCase({
				id: record.id,
				trackingNumber: record.trackingNumber
			});
		}
	}

    getConfigTableColumns = () => {
        const { t, deliveryCasesStatus, listReason, loadingListReason, assignees } = this.props;
        const renderColumn = (key, text, record) => {
            switch (key) {
                case 'orderCode':
                    return <Link className="nowrap-css" to={`/orders/${record.orderId}`} target="_blank">{record.orderCode}</Link>;
                case 'id':
                    return record.id;
                case 'assigneeId':
                    return (
                        <Select
                            style={{ width: '100%' }}
                            value={lodash.get(record, 'assignee.username')}
                            onChange={this.onSelectChange.bind(this, 'assigneeId', record)}
                            disabled={!UserService.isAllowed(DELIVERYCASE.DELIVERY_CASE_UPDATE) || !UserService.isAllowed(DELIVERYCASE.DELIVERY_CASE_VIEW_ALL)}
                        >
                            <Select.Option key={0} value={''}>
                                <span style={{color: 'rgba(0, 0, 0, 0.4)'}}> {t("delivery_case.select_unassigned")} </span>
                            </Select.Option>
                            {
								assignees.map(x => {
                                    return (
                                    	<Select.Option
											key={lodash.get(x, 'assignee.id')}
											value={lodash.get(x, 'assignee.id')}
										>{lodash.get(x, 'assignee.username')}</Select.Option>
									)
                                })
                            }
                        </Select>
                    )
                case 'statusCode':
                    return (
                        <Select
                            style={{ width: '100%' }}
                            value={record.statusCode}
                            disabled={(!record.reasonId || record.statusCode === 'DONE' || !UserService.isAllowed(DELIVERYCASE.DELIVERY_CASE_UPDATE)) ? true : false}
                            onChange={this.onSelectChange.bind(this, 'statusCode', record)}
                        >

                            {deliveryCasesStatus?.map((value) => <Select.Option key={value.key} value={value.key}><Tooltip title={value.value} placement="top">{value.value}</Tooltip></Select.Option>)}
                        </Select>
                    )
                case 'reasonId':
                    return (
                        <Select
                            style={{ width: '100%' }}
                            value={record.reasonId ? record.reasonId : ''}
                            loading={loadingListReason}
                            onChange={this.onSelectChange.bind(this, 'reasonId', record)}
                            disabled={!UserService.isAllowed(DELIVERYCASE.DELIVERY_CASE_UPDATE)}
                        >
                            <Select.Option key={0} value="">
                                <span style={{color: 'rgba(0, 0, 0, 0.4)'}}> {t("delivery_case.select_reason")} </span>
                            </Select.Option>
                            {
                                listReason && listReason.map(reason => {
                                    return <Select.Option key={reason.id} value={reason.id}><Tooltip title={reason.name} placement="top">{reason.name}</Tooltip></Select.Option>
                                })
                            }
                        </Select>
                    )
                case 'note':
                    return (
                        <InputGroup compact>
                            <TextArea
                                rows={1}
                                style={{ width: '80%' , textAlign:'left'}}
                                id={`note-${record.id}`}
                                value={record.note}
                                onChange={this.onTextAreaChange.bind(this,record)}
                                onBlur={this.onSaveNote.bind(this, record)}
                                disabled={!UserService.isAllowed(DELIVERYCASE.DELIVERY_CASE_UPDATE)}
                            />
                        </InputGroup>
                    )

				case 'trackingNumber':
					return (
						<InputGroup compact>
							<Input
								style={{ width: '80%' , textAlign:'left'}}
								id={`tracking-number-${record.id}`}
								value={record.trackingNumber}
								onChange={this.onChangeTrackingNumber.bind(this,record)}
								onPressEnter={this.onSaveTrackingNumber.bind(this,record)}
								onBlur={this.onSaveTrackingNumber.bind(this, record)}
								disabled={!UserService.isAllowed(DELIVERYCASE.DELIVERY_CASE_UPDATE)}
							/>
						</InputGroup>
					)

				case 'createdBy':
					return record.createdBy || "---";

                case 'createdAt':
                    return record.createdAt ? moment(record.createdAt).format("DD/MM/YY HH:mm") : "";
                default:
                    break;
            }
        };

		return [
			{
				title: t("delivery_case.code"),
				dataIndex: 'id',
				key: 'id',
				render: renderColumn.bind(this, 'id'),
				width: 100
			},
			{
				title: t("contact-detail.order_code"),
				dataIndex: 'orderCode',
				key: 'orderCode',
				render: renderColumn.bind(this, 'orderCode'),
				width: 200
			},
			{
				title: t("delivery_case.assignee"),
				dataIndex: 'assigneeId',
				key: 'assigneeId',
				render: renderColumn.bind(this, 'assigneeId'),
				width: 200
			},
			{
				title: t("delivery_case.reason"),
				dataIndex: 'reasonId',
				key: 'reasonId',
				render: renderColumn.bind(this, 'reasonId'),
				width: 350
			},
			{
				title: t("delivery_case.note"),
				dataIndex: 'note',
				key: 'note',
				render: renderColumn.bind(this, 'note'),
				width: 250
			},
			{
				title: t("delivery_case.trackingNumber"),
				dataIndex: 'trackingNumber',
				key: 'trackingNumber',
				render: renderColumn.bind(this, 'trackingNumber'),
				width: 250
			},
			{
				title: t("delivery_case.status"),
				dataIndex: 'statusCode',
				key: 'statusCode',
				render: renderColumn.bind(this, 'statusCode'),
				width: 150
			},
			{
				title: t("delivery_case.createdBy"),
				dataIndex: 'createdBy',
				key: 'createdBy',
				render: renderColumn.bind(this, 'createdBy'),
				width: 130
			},
			{
				title: t("delivery_case.createdAt"),
				dataIndex: 'createdAt',
				key: 'createdAt',
				render: renderColumn.bind(this, 'createdAt'),
				width: 130
			},
		];
    };

    handleUploadFile = ({ file, fileList }) => {
        const { t, onUploadDeliveryCase, getKeyMessageError } = this.props;
        // check handle upload file success or error
        if (file.status) {
            if (file.status === STATUS_UPLOAD_FILE.ERROR) {
                notification.error({
                    message: t("delivery_case.import_failed"),
                    description: getKeyMessageError(file.response.error.message)
                });

                this.setState({
                    loadingUpload: false
                })

                onUploadDeliveryCase.call(null, file.status)
            }
            if (file.status === STATUS_UPLOAD_FILE.DONE) {
                this.setState({
                    loadingUpload: false
                });

                onUploadDeliveryCase.call(null, file.status, file.response)
            }
            if (file.status === STATUS_UPLOAD_FILE.UPLOADING){
                onUploadDeliveryCase.call(null, file.status)
            }
        }

    }

    handleBeforeUploadFile = (file) => {
        this.setState({
            loadingUpload: true
        });

        const { t } = this.props;
        const isCsv = file.type === "text/csv";

        if (!isCsv) {
            notification.error({
                message: t("delivery_case.import_failed"),
                description: t("delivery_case.not_correct_format")
            });

            this.setState({
                loadingUpload: false
            });
        }

        return isCsv;
    }

	handleExport = () => {
		this.props.onExport();
	};

    render() {
        const { t, loading, dataSource, currentPage, pageSize, totalItems, onChangePage, onShowSizeChange, loadingUpdate } = this.props;
        const { loadingUpload } = this.state

        let columns = this.getConfigTableColumns();

        let token = LocalStore.getInstance().read('loginSession');
        let defaultLang = LocalStore.getInstance().read('language');

        defaultLang = defaultLang ? defaultLang : 'en';

        return (
            <div className="list-container">
                <Row className="pd-bt-15">
                    <Col span={5}>
                        <span>{t("contact.page")} {currentPage}/{pageSize} ({totalItems})</span>
                    </Col>
                    <Col span={19}>
						<div className="display-flex gap-5px align-items-center justify-end">
							<div>
								<a style={{paddingRight: '15px', textDecoration: 'underline', fontWeight: 500}} href="https://cdn.gobiz.vn/fobiz/Template_Upload_Delivery_Case.csv">Download CSV Template</a>
								<Upload
									action={`${API.BASE_URL}/DeliveryCases/import`}
									method={'POST'}
									headers={
										{
											'Authorization': token ? token.id : '',
											'Accept-Language': defaultLang,
										}
									}
									name={'file'}
									showUploadList={false}
									beforeUpload={this.handleBeforeUploadFile}
									onChange={this.handleUploadFile}
								>
									<Button
										type={'primary'}
										loading={loadingUpload}
									>
										{t("delivery_case.upload")} <Icon type="upload" />
									</Button>
								</Upload>
							</div>

							<Button
								loading={loading}
								disabled={loading}
								type={'primary'}
								icon={'cloud-download'}
								onClick={this.handleExport}
							>
								Export
							</Button>
						</div>
                    </Col>
                </Row>

                <div className="a-table a-table--responsive">
                    <Table
                        columns={columns}
                        dataSource={dataSource}
                        rowKey={record => record.id}
                        pagination={false}
                        loading={loading || loadingUpload || loadingUpdate}
                        locale={{
                            emptyText: t("table.empty")
                        }}
                        className={'delivery_case__table'}
                        scroll={{x:1000}}
                    />
                </div>

                <Pagination
                    className="mg-t-15 text-r"
                    showSizeChanger
                    onShowSizeChange={onShowSizeChange}
                    onChange={onChangePage}
                    pageSizeOptions={['25', '50', '100', '150', '200']}
                    defaultCurrent={1}
                    current={parseInt(currentPage)}
                    pageSize={parseInt(pageSize)}
                    total={parseInt(totalItems)}
                />
            </div>
        );
    }
}

export default List
