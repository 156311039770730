import EagleClient from "../utils/api/EagleClient";

export default class StockIssueService {

    /**
     * Tạo phiếu xuất
     * @param warehousesCode
     * @param data
     * @param cb
     */
    static createStockIssue(warehousesCode, data, cb) {
        EagleClient.request({
            endpoint: `/Warehouses/${warehousesCode}/issues`,
            method: 'POST',
            body: data
        }, cb);
    }

    /**
     * Lấy dữ liệu chi tiết phiếu
     * @param issueId
     * @param cb
     */
    static getStockIssueDetail(issueId, cb) {
        EagleClient.request({
            endpoint: `/StockIssues/${issueId}`,
            method: 'GET'
            // queryString: {filter: filter}
        }, cb);
    }

    /**
     * Kiểm tra đơn hàng tạo phiếu
     * @param orderIds
     * @param cb
     */
    static checkOrders(orderIds, cb) {
        let params = '';
        for (let i = 0; i < orderIds.length; i++) {
            if (i < orderIds.length - 1) {
                params = params + orderIds[i] + ',';
            } else {
                params = params + orderIds[i]
            }
        }
        EagleClient.request({
            // endpoint: `http://duonglq.hiee.us/api/check-orders?orderIds=${params}`,
            endpoint: `/v2/check-orders?orderIds=${params}`,
            method: 'GET',
        }, cb);
    }

    /**
     * Lấy dữ liệu danh sách đơn hàng trong chi tiết phiếu
     * @param issueId
     * @param cb
     */
    static getListOrderStockIssueDetail(issueId, cb) {
        EagleClient.request({
            endpoint: `/v2/issues/${issueId}/orders`,
            method: 'GET'
        }, cb);
    }

    /**
     * Xóa đơn khỏi phiếu xuất
     * @param orderIds
     * @param issueId
     * @param cb
     */
    static deleteOrderInStockIssue(issueId, orderIds, cb) {
        EagleClient.request({
            endpoint: `/StockIssues/${issueId}/orders?orderIds=${encodeURI(orderIds.join(','))}`,
            method: 'DELETE'
        }, cb);
    }

    /**
     * Thêm đơn khỏi phiếu xuất
     * @param orderIds
     * @param issueId
     * @param cb
     */
    static addOrderInStockIssue(issueId, data, cb) {
        EagleClient.request({
            endpoint: `/StockIssues/${issueId}/orders`,
            method: 'POST',
            body: data
        }, cb);
    }

    /**
     * Thay đổi trạng thái issue sang process
     * @param issueId
     * @param cb
     */
    static changeToProcess(issueId, cb) {
        EagleClient.request({
            endpoint: `/v2/issues/${issueId}/process`,
            method: 'PUT'
        }, cb);
    }

    /**
     * Thay đổi trạng thái issue sang done/finish
     * @param issueId
     * @param cb
     */
    static changeToFinish(issueId, cb) {
        EagleClient.request({
            endpoint: `/v2/issues/${issueId}/finish`,
            method: 'PUT'
        }, cb);
    }

    /**
     * Thay đổi LBC
     * @param courierId
     * @param issueId
     * @param cb
     */
    static changeCarrier(issueId, courierId, cb) {
        EagleClient.request({
            endpoint: `/StockIssues/${issueId}/updateCourier?courierId=${courierId}`,
            method: 'PUT'
        }, cb);
    }

    /**
     * Lấy danh sách đơn hàng chưa tạo phiếu
     * @param cb
     */
    static getAvailableOrders(cb) {
        EagleClient.request({
            endpoint: `/v2/issues/available-orders`,
            method: 'GET'
        }, cb);
    }

    /**
     * Update đơn hàng trong phiếu xuất
     * @param issueId
     * @param cb
     */
    static updateOrdersStockIssue(issueId, cb) {
        EagleClient.request({
            endpoint: `/v2/issues/${issueId}/orders/create-relationship`,
            method: 'POST'
        }, cb);
    }

    /**
     * Delete đơn hàng trong phiếu xuất
     * @param ids
     * @param cb
     */
    static deleteOrdersStockIssue(ids, cb) {
        EagleClient.request({
            endpoint: `/v2/issues/orders/remove-relationship`,
            method: 'DELETE'
        }, cb);
    }

    static changeIssueStatus(issueId, status ,cb) {
        EagleClient.request({
            endpoint: `StockIssues/${issueId}/${status}`,
            method: 'PUT'
        }, cb);
    }
}