import React, {Component} from 'react';
import lodash from 'lodash';
import {Button, Col, Form as AntForm, InputNumber, notification, Row, Select, Table} from 'antd';
import {LocalStore} from "../../utils/LocalStore";
import SettingService from "../../services/SettingService";
import UserService from "../../services/UserService";
import {SETTINGS_SCOPES} from "../../utils/api/Scopes";

const Option = Select.Option;

class Form extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            settings: [],
            zones:[
                {id:1, value:'Asia/Manila', title:'Asia/Manila (UTC+8)'},
                {id:2, value:'Asia/Ho_Chi_Minh', title:'Asia/Ho_Chi_Minh (UTC+7)'},
                {id:3, value:'America/Los_Angeles', title:'America/Los_Angeles (UTC-8)'}
            ],
            valueZone: 'Asia/Manila',
            settingItemValueChanged: {}
        };
    }

    componentDidMount() {
        this.getSettings()
    }

    getSettings() {
        this.setState({loading: true})
        SettingService.getList({key: "contact"},(error, response) => {
            this.setState({loading: false})
            if(!error) {
                this.setState({
                    settings: response,
                })
            }
        });
    }

    onChangeItem = (value) => {
        this.setState({
            valueZone: value
        });
    };

    onSaveTimeZone = () =>{
        LocalStore.getInstance().save('timezone', this.state.valueZone);
        setTimeout(function(){
            window.location.reload();
        }, 500);
    }

    handleSettingItemValueChanged = (id, key, value) => {
        const {settingItemValueChanged} = this.state
        lodash.set(settingItemValueChanged, [id, key], value)
        this.setState({
            settingItemValueChanged: {...settingItemValueChanged}
        })
    }

    handleUpdateSettingItem = (id) => {
        const {t} = this.props
        this.setState({loading: true})
        const data = this.state.settingItemValueChanged[id] || {}
        SettingService.updateSetting(id, {value: data}, (error, response) => {
            this.setState({loading: false})
            if (!error) {
                notification.success({
                    message: t('message.success')
                })
            }
            else {
                notification.error({
                    message: lodash.get(response, 'message') || t('message.fail')
                })
            }
        })
    }

    render() {
        const {t} = this.props;
        const {getFieldDecorator} = this.props.form;
        const {settings, loading, settingItemValueChanged} = this.state
        return (
            <>
                <Row gutter={20} className="pd-30">
                    <Col xs={24} xl={16}>
                        <div className="white-box white-box-setting">
                            <div className="form-header">
                                <h3 className="pd-l-30 pd-r-30 pd-t-15 pd-bt-15 mg-0">{t('setting.contact_setting')}</h3>
                                <div className="h-line" />
                            </div>
                            <div className="form-body pd-30 pd-t-10 pd-bt-10">
                                <Table
                                    columns={[
                                        {
                                            title: t("setting.contact_quantity"),
                                            key: "contact_quantity",
                                            render: row => {
                                                return <InputNumber
                                                    className={'width-100pc'}
                                                    value={lodash.get(settingItemValueChanged, [row.id, 'maxContactReceivePerRequest']) || lodash.get(row, 'value.maxContactReceivePerRequest')}
                                                    onChange={value => this.handleSettingItemValueChanged(row.id, 'maxContactReceivePerRequest', value)}
                                                />
                                            }
                                        },
                                        {
                                            title: t("setting.contact_maximum"),
                                            key: "contact_maximum",
                                            render: row => {
                                                return (
                                                    <InputNumber
                                                        className={'width-100pc'}
                                                        value={lodash.get(settingItemValueChanged, [row.id, 'maxContactNotCall4ReceiveContinuous']) || lodash.get(row, 'value.maxContactNotCall4ReceiveContinuous')}
                                                        onChange={value => this.handleSettingItemValueChanged(row.id, 'maxContactNotCall4ReceiveContinuous', value)}
                                                    />
                                                )
                                            }
                                        },
                                        {
                                            title: "Country",
                                            key: "country",
                                            render: row => lodash.get(row, 'country.name')
                                        },
                                        {
                                            title: "",
                                            key: "Action",
                                            render: row => (
                                                <Button
                                                    loading={loading}
                                                    disabled={!UserService.isAllowed(SETTINGS_SCOPES.SETTING_ASSIGN) || loading}
                                                    onClick={this.handleUpdateSettingItem.bind(this, row.id)}
                                                    type={'primary'}
                                                    size={'small'}
                                                >{t('button.update')}</Button>
                                            )
                                        }
                                    ]}
                                    dataSource={settings}
                                    loading={loading}
                                    pagination={false}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} xl={8}>
                        <div className="white-box white-box-setting">
                            <div className="form-header">
                                <h3 className="pd-l-30 pd-r-30 pd-t-15 pd-bt-15 mg-0">{t('setting.time_zone')}</h3>
                                <div className="h-line" />
                            </div>
                            <div className="form-body pd-30 pd-t-10 pd-bt-10">
                                <div className="form-item">
                                    <AntForm.Item className={"form-item-item"} label={<span className="bold-400">{t("setting.time_zone_select")}</span>}>
                                        {getFieldDecorator('zone',{
                                            initialValue: LocalStore.getInstance().read("timezone")?LocalStore.getInstance().read("timezone"):this.state.zones[0].value
                                        })(
                                            <Select
                                                className={"_zones-select"}
                                                style={{width: "100%"}}
                                                onSelect={this.onChangeItem.bind(this)}
                                            >
                                                {this.state.zones.map((item, index) => (
                                                    <Option key={item.id} value={item.value} className={`_zones-select-value_${item.id}`}>{item.title}</Option>
                                                ))}
                                            </Select>
                                        )}
                                    </AntForm.Item>

                                    <Button onClick={this.onSaveTimeZone} type={'primary'}>{t('button.update')}</Button>
                                </div>

                            </div>
                        </div>
                    </Col>
                </Row>



            </>
        )
    }
}

export {Form};
