import React from 'react';
import {Row, Col} from 'antd';

class BreadCrumb extends React.Component {
    static defaultProps = {
        leftContent: "",
        rightContent: ""
    };

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="bread-crumb-container">
                <Row>
                    <Col span={14}>
                        <div className="pd-t-3">{this.props.leftContent}</div>
                    </Col>
                    <Col span={10}>
                        <div className="flr">{this.props.rightContent}</div>
                    </Col>
                </Row>
            </div>
        )
    }
}

export {BreadCrumb}