import React from 'react';
import {Breadcrumb as AntBreadCrumb, Button, Icon} from 'antd';
import {BreadCrumb} from "../../components/BreadCrumb";
import {Link} from "react-router-dom";

class Header extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const {t, iscreate} = this.props;
        const leftContent = (
            <AntBreadCrumb>
                <AntBreadCrumb.Item>
                    <Link to={"/"}>{t("sidebar.dashboard")}</Link>
                </AntBreadCrumb.Item>
                <AntBreadCrumb.Item>{"Stringee"}</AntBreadCrumb.Item>
            </AntBreadCrumb>
        );

        const rightContent = (
            <Button.Group>
                { !iscreate &&
                    <Button type="primary" className={"_add-vendor-button"}><Link to={"/stringee/add"}>Thêm cấu hình</Link></Button>
                }
            </Button.Group>
        );

        return (
            <BreadCrumb
                {...this.props}
                leftContent={leftContent}
                rightContent={rightContent}
            />
        );
    }
}

export {Header};