import React, {Component} from 'react';
import {Breadcrumb as AntBreadCrumb, Button, Icon} from 'antd';
import {BreadCrumb} from "../../../components/BreadCrumb";
import {Link} from "react-router-dom";
import {objectGet} from "../../../utils/helper";

class Header extends Component {

    render() {
        const {t, group} = this.props;
        const leftContent = (
            <AntBreadCrumb>
                <AntBreadCrumb.Item>
                    <Link to={"/"}>{t('page.home')}</Link>
                </AntBreadCrumb.Item>
                <AntBreadCrumb.Item><Link to={"/groups"}>{t("group_user.title")}</Link></AntBreadCrumb.Item>
                <AntBreadCrumb.Item>{objectGet(group, 'name')}</AntBreadCrumb.Item>
            </AntBreadCrumb>
        );

        return (
            <BreadCrumb
                {...this.props}
                leftContent={leftContent}
            />
        );
    }
}

export {Header};