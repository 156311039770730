import React, {Component} from 'react'
import {Empty, Icon, Input, Layout, Popover} from "antd";
import UserService from "../../services/UserService";
import {LocalStore} from "../../utils/LocalStore";
import i18n from '../../i18n';
import vn from '../../resources/images/flag/vn.png';
import us from '../../resources/images/flag/us.png';
import indonesiaFlagImage from '../../resources/images/flag/id.png';
import thailandFlagImage from '../../resources/images/flag/th.png';
import VendorService from "../../services/VendorService";
import * as _ from "lodash";
import {getParamsAsObject, getUrlQueryString} from "../../utils/helper"
import {AUTH} from "../../config/auth";

class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lang: [
                {key: 'vi', title: "Viet Nam", flag: vn},
                {key: 'en', title: "English", flag: us},
                {key: 'indonesia', title: "Indonesia", flag: indonesiaFlagImage},
                {key: 'thailand', title: "Thailand", flag: thailandFlagImage}
            ],
            isShowPopOver: false,
            vendors: [],
            defaultVendors: [],
            vendorDisplay: LocalStore.getInstance().read('vendor') ? LocalStore.getInstance().read('vendor').name : "",
            isShowPopUp: false,
            vendorCode: LocalStore.getInstance().read('vendor') ? LocalStore.getInstance().read('vendor').code : "",
            vendorInputValue: ""
        }
    }

    changeLang = (lang) => {
        LocalStore.getInstance().save("language", lang);
        i18n.changeLanguage(lang);
        this.setState({isShowPopOver: false});
        window.location.reload();
    };

    onClickLogout = () => {
        UserService.logout();
        LocalStore.getInstance().save("fobizUrlBack", window.location.href);
        window.location = AUTH.LOGOUT_PATH;
    };

    handleVisibleChange = (visible) => {
        this.setState({ isShowPopOver: visible });
    };

    renderDropDownUser = () => {
        const {t} = this.props;
        return (
            <div onClick={this.onClickLogout} className={"pointer"}>{t("login.logout_btn")}</div>
        )
    };

    renderDropDownLang = () => {
        let items = this.state.lang;
        return (
            <>
                {items.map((item) => (
                    <div
                        key={item.key}
                        className={"pointer header-language"}
                        onClick={this.changeLang.bind(this, item.key)}
                    >
                        <img className={"image-language"} src={item.flag} alt={item.flag} /> {item.title}
                    </div>
                ))}
            </>
        )
    };

    componentDidMount() {
        this.apiGetVendorList();
    }

    apiGetVendorList = () => {
        let filter = {
            me_in: true,
            page_size: 1000,
            order: ["createdAt DESC"],
            include: ['country']
        };

        VendorService.getVendorList(filter, (error, response) => {
            if (!error) {
                let isShowPopUp = false;
                if (response.count === 1) {
                    // Nếu tạo vendor đầu tiên thì hiển thị luôn tên vừa tạo lên header, đồng thời không cho search vendor disable popup select vendor
                    isShowPopUp = true;
                    this.setState({
                        vendorDisplay: response.list[0].name,
                        vendorCode: response.list[0].code,
                        isShowPopUp: isShowPopUp,
                        vendors: response.list,
                        defaultVendors: response.list,
                    });
                    LocalStore.getInstance().save('vendor', response.list[0]);
                    return false
                }

                if (response.count > 1) {
                    isShowPopUp = true;
                    this.setState({
                        vendors: response.list,
                        defaultVendors: response.list,
                        isShowPopUp: isShowPopUp
                    });
                }
            }
        });
    };

    filterVendor = (e) => {
        let value = e.target.value.toLowerCase();
        let vendors = this.state.defaultVendors;
        let newVendors = [];

        if (value.length > 0) {
            _.forEach(vendors, (vendor) => {
                if (vendor.name.toLowerCase().indexOf(value) >= 0) {
                    newVendors.push(vendor);
                }
            });
        } else {
            newVendors = this.state.defaultVendors;
        }

        this.setState({vendors: newVendors});
    };

    setValue = (e) => {
        this.setState({
            vendorInputValue: e.target.value
        })
    };

    renderDropDownVendor = () => {
        const {t} = this.props;
        const content = (
            <div className="vendor-select">
                <Input
                    autoFocus={true}
                    onChange={this.setValue}
                    value={this.state.vendorInputValue}
                    onKeyUp={this.filterVendor}
                />
                {!!this.state.vendors.length ? (
                    <ul>
                        {this.state.vendors.map((item) => (
                            <li onClick={this.onSelectVendors.bind(this,item)} key={item.code} className={`${this.state.vendorCode===item.code?"_vendor-item vendor-active":"_vendor-item"}`}>{item.name}</li>
                        ))}
                    </ul>
                ) : <Empty className="no-data" description={t("table.empty")} />}


            </div>
        );
        return content;
    };

    onSelectVendors = (vendor) => {
        if(this.state.vendorCode === vendor.code){
            return false;
        }
        LocalStore.getInstance().save('vendor', vendor);
        let queryObject = getParamsAsObject(getUrlQueryString());
        let currentUrl = window.location.href;
        let lastIndex = currentUrl.lastIndexOf('?');
        if(lastIndex < 0) lastIndex = currentUrl.length;
        currentUrl = currentUrl.substring(currentUrl.indexOf('#')+1, lastIndex);
        this.props.history.push(`${currentUrl}?e_change_vendor=1&expanded=${queryObject.expanded}`);
        window.location.reload();
    };

    setDefaultVendor = () => {
        this.setState({
            vendors: this.state.defaultVendors,
            vendorInputValue: ""
        });
    };

    render() {
        let {user, showSelectVendor} = this.props;
        return (
            <Layout.Header className={"header"}>
                <div className="header-inner">
                    <div className="header-inner-inner">
                        <Icon
                            className="trigger"
                            type={this.props.collapsed ? 'menu-unfold' : 'menu-fold'}
                            onClick={this.props.toggle}
                        />
                        <div className="right-header flr" style={{paddingRight:20}}>
                            {showSelectVendor && this.state.isShowPopUp && (
                                <Popover
                                    content={this.renderDropDownVendor()}
                                    trigger="click"
                                    className="pointer"
                                    onVisibleChange={this.setDefaultVendor}
                                    overlayClassName="vendor_popover _vendor_popover"
                                >
                                    <div className="vendor-display">{this.state.vendorDisplay}</div> <Icon type="down" style={{ fontSize: '10px' }}/>
                                </Popover>
                            )}

                            <span className="user pointer item">
                                <Popover placement="bottom" content={this.renderDropDownUser()} trigger="click">
                                    <Icon type={"user"} /> <b>{user.fullName}</b>
                                </Popover>
                            </span>
                            <Popover
                                placement="bottom"
                                visible={this.state.isShowPopOver}
                                onVisibleChange={this.handleVisibleChange}
                                content={this.renderDropDownLang()}
                                trigger="click">
                                <Icon
                                    type={"global"}
                                    className={"pointer item"}
                                />
                            </Popover>
                        </div>
                    </div>
                </div>
            </Layout.Header>
        )
    }
}

Header.defaultProps = {
    showSelectVendor: true
};

export default Header;
