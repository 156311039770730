import React from 'react';
import {Input} from "antd";
import {objectGet, replaceString} from "../../../utils/helper";
import {formatQuantity} from "../../../utils/currency";
import * as _ from "lodash";
import {toastr} from "../../../utils/toastr";

class EditQuantityOrderItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            minQuantity: 1,
            maxQuantity: 100000000
        };
    }

    // static getDerivedStateFromProps = (props) => {
    //     const {orderItem, order} = props;
    //     // Get min-quantity and max-quantity from ads config
    //     const adsConfig = objectGet(order, 'adsConfig', []);
    //     let minQuantity = null;
    //     let maxQuantity = null;
    //     for(let conf of adsConfig) {
    //         if(conf.sku == orderItem.sku) {
    //             let priceConfig = conf.wholeSalePrice || [];
    //             minQuantity = _.minBy(priceConfig, 'from').from;
    //             maxQuantity = _.maxBy(priceConfig, 'to').to;
    //             break;
    //         }
    //     }
    //     return {
    //         minQuantity,
    //         maxQuantity: maxQuantity
    //     }
    // };

    onChange = (e) => {
        let value = e.target.value;
        value = replaceString(value);
        const {orderItem} = this.props;
        // const {minQuantity, maxQuantity} = this.state;

        // if(value) {
        //     if (value < minQuantity) value = minQuantity;
        //     if (value > maxQuantity) value = maxQuantity;
        // }

        let _orderItem = orderItem;
        _orderItem.quantity = value;
        _orderItem.amount = _orderItem.quantity * _orderItem.unitPrice;
        this.props.updateOrderItemState(_orderItem.id, _orderItem);
    };

    onSubmit = (e) => {
        let {orderItem, t} = this.props;
        this.setState({loading: true});
        const dataToUpdate = {
            quantity: _.parseInt(orderItem.quantity, 10),
            unitPrice: parseFloat(orderItem.unitPrice)
        }
        this.props.updateOrderItem(orderItem.id, dataToUpdate, (error) => {
            this.setState({loading: false});
            if (error === null) {
                toastr.success(t('message.success'));
                this.props.fetchOrder();
            }
            else {
                if(error.code !== 'INVALID_PHONE') {
                    toastr.error(error.message);
                }
            }
        });
    };

    render() {
        const {loading} = this.state;
        const {orderItem} = this.props;
        return (
            <Input
                value={formatQuantity(orderItem.quantity)}
                onChange={this.onChange}
                onPressEnter={this.onSubmit}
                disabled={loading}
                style={{width: '100%'}}
            />
        )
    }
}

export {EditQuantityOrderItem};