import React from 'react';
import {translate} from 'react-i18next';
import {notification} from "antd/lib/index";
import StandardLayout from "../../../components/StandardLayout";
import {Header} from "./Header";
import {List} from "./List";
import UserService from "../../../services/UserService";
import ReportService from "../../../services/ReportService";
import {LocalStore} from "../../../utils/LocalStore";
import {REPORT_SCOPES} from "../../../utils/api/Scopes";

class StatisticDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentWillMount() {
        if (!UserService.isAllowed(REPORT_SCOPES.REPORT_VIEW)) {
            this.props.history.replace('/')
        }
    };

    render() {
        const {t} = this.props;
        return (
            <StandardLayout {...this.props} title={t("statistics.title")}>
                <div className="statistics-list-page list-page new-list-page">
                    <Header
                        {...this.props}
                    />

                    <List
                        {...this.props}
                        {...this.state}
                    />
                </div>
            </StandardLayout>
        );
    }
}

export default (translate())(StatisticDetail);