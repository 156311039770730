import React, {Component} from 'react';

import {Filter} from "./Filter";
import {LocalStore} from "../../../utils/LocalStore";
import {List} from "./List";
import CampaignService from "../../../services/CampaignService";
import * as _ from "lodash";
import {arrayConcat} from "../../../utils/helper";
import VendorService from "../../../services/VendorService";
import UserService from "../../../services/UserService";
import {CAMPAIGNS_SCOPES} from "../../../utils/api/Scopes";

class Campaign extends Component {

    constructor(props) {
        super(props);
        this.state = {
            vendor: LocalStore.getInstance().read('vendor'),
            currentPage: 1,
            totalItems: 25,
            pageSize: 100,
            pageCount: 0,
            initialUsers: [],
            campaigns: [],
            users: [],
            loadingCampaign: true,
            loadingUser: true
        };
        this.filterCampaign = null;
    }

    componentDidMount = () => {
        this.loadData();
    };

    resetDataSource = (key) => {
        this.setState({
            [key]: this.state[`initial${_.upperFirst(key)}`]
        });
    };

    loadData = () => {
        this.fetchCampaign({
            product_id:this.props.productId,
            project_id:this.props.projectId
        });
        this.fetchUser({}, (error, response) => {
            if(!error) {
                this.setState({
                    initialUsers: response.list
                });
            }
        })
    };

    fetchUser = (filter = {}, cb) => {
        this.setState({loadingUser: true});
        const {vendor} = this.state;
        VendorService.getUserInVendor(vendor.id, filter, (error, response) => {
            if(!error) {
                this.setState({
                    loadingUser: false,
                    users: response.list,
                });
            }
            if(typeof cb === 'function') cb(error, response);
        });
    };

    fetchCampaign = (filter = {}, cb) => {
        this.setState({loadingCampaign: true});
        let {vendor} = this.state;
        let filterCond = {
            vendor_id: vendor.id,
            include: ['product'],
            order: ["createdAt DESC"],
            product_id: this.props.productId,
            project_id: this.props.projectId
        };
        filterCond = _.mergeWith(filterCond, filter, arrayConcat);
        CampaignService.getCampaigns(filterCond, (error, response) => {
            if(!error) {
                this.setState({
                    campaigns: response.list,
                    loadingCampaign: false,
                    currentPage: response.metadata.currentPage,
                    totalItems: response.count,
                    pageSize: response.metadata.pageSize,
                    pageCount: response.metadata.pageCount
                });
            }
            if(typeof cb === 'function') cb(error, response);
        });
    };

    onShowSizeChange = (current, pageSize) => {
        this.fetchCampaign({
            page: 1,
            page_size: pageSize,
            product_id:this.props.productId,
            project_id:this.props.projectId,
            keyword:this.filterCampaign.getFilterCon("keyword"),
            created_by:this.filterCampaign.getFilterCon("createdBy"),
            created_at_from:this.filterCampaign.getFilterCon("created_at_from"),
            created_at_to:this.filterCampaign.getFilterCon("created_at_to")
        });
    };

    onChangePage = (page, pageSize) => {
        this.fetchCampaign({
            page: page,
            page_size: pageSize,
            product_id:this.props.productId,
            project_id:this.props.projectId,
            keyword:this.filterCampaign.getFilterCon("keyword"),
            created_by:this.filterCampaign.getFilterCon("createdBy"),
            created_at_from:this.filterCampaign.getFilterCon("created_at_from"),
            created_at_to:this.filterCampaign.getFilterCon("created_at_to")
        });
    };

    onFilter = (filter = {}, cb) => {
        this.fetchCampaign(filter, cb);
    };

    componentDidUpdate(prevProps) {
        if(JSON.stringify(prevProps.productId) !== JSON.stringify(this.props.productId) ||
            JSON.stringify(prevProps.projectId) !== JSON.stringify(this.props.projectId)){
            this.fetchCampaign({
                product_id:this.props.productId,
                project_id:this.props.projectId,
                keyword:this.filterCampaign.getFilterCon("keyword"),
                created_by:this.filterCampaign.getFilterCon("createdBy"),
                created_at_from:this.filterCampaign.getFilterCon("created_at_from"),
                created_at_to:this.filterCampaign.getFilterCon("created_at_to")
            })
        }
    }

    setRefFilter = (ref) => {
        this.filterCampaign = ref;
    };

    updateCampaignQuickly = (id, data, cb) => {
        CampaignService.quickUpdateCampaign(id, data, cb);
    };

    toggleEditAbleFbCampaignId = (item) => {
        if(UserService.isAllowed(CAMPAIGNS_SCOPES.CAMPAIGNS_UPDATE)) {
            if (!('editableFbCampaignId' in item)) {
                item.editableFbCampaignId = false;
            }
            item.editableFbCampaignId = !item.editableFbCampaignId;
            let {campaigns} = this.state;
            for (let c of campaigns) {
                if (c.id === item.id) {
                    c = item;
                }
            }
            this.setState({campaigns});
        }
    };

    render() {
        const {vendor, totalItems, currentPage, pageSize,pageCount, users, campaigns, loadingCampaign} = this.state;

        return (
            <div className="product-container _campaign-container">
                <Filter
                    {...this.props}
                    users={users}
                    resetDataSource={this.resetDataSource}
                    onFilter={this.onFilter}
                    fetchUser={this.fetchUser}
                    currentPage={currentPage}
                    pageSize={pageSize}
                    pageCount={pageCount}
                    totalItems={totalItems}
                    loading={loadingCampaign}
                    productId={this.props.productId}
                    projectId={this.props.projectId}
                    ref={this.setRefFilter}
                />
                <List
                    {...this.props}
                    dataSource={campaigns}
                    currentPage={currentPage}
                    pageSize={pageSize}
                    pageCount={pageCount}
                    totalItems={totalItems}
                    vendor={vendor}
                    onChangePage={this.onChangePage}
                    onShowSizeChange={this.onShowSizeChange}
                    loadingCampaign={loadingCampaign}
                    onToggleProductStatus={this.onToggleProductStatus}
                    getCampaignId={this.props.getCampaignId}
                    countCampaign={this.props.countCampaign}
                    fetchCampaign={this.fetchCampaign}
                    updateCampaignQuickly={this.updateCampaignQuickly}
                    toggleEditAbleFbCampaignId={this.toggleEditAbleFbCampaignId}
                />
            </div>
        );
    }
}

export {Campaign}
