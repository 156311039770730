import React from 'react';
import {translate} from 'react-i18next';
import StandardLayout from "../../../../../components/StandardLayout";
import {Info} from "./Info";
import {OrderFilter} from "./OrderFilter";
import {OrderList} from "./OrderList";
import {LocalStore} from "../../../../../utils/LocalStore";
import {
    arrayConcat,
    doCallBack,
    getParamsAsObject,
    getUrlQueryString,
    getUrlSegment,
    objectGet
} from "../../../../../utils/helper";
import {CourierService} from "../../../../../services/CourierService";
import VendorService from "../../../../../services/VendorService";
import * as _ from "lodash";
import OrderService from "../../../../../services/OrderService";
import CampaignService from "../../../../../services/CampaignService";
import {Bcrumb} from "./Breadcrumb";
import StockIssueService from "../../../../../services/StockIssueService";
import {Url} from "../../../../../utils/Url";
import {notification} from "antd/lib/index";

class IssueDetail extends React.Component {

    constructor(props) {
        super(props);
        const {t} = this.props;
        this.moduleName = 'issues';
        this.state = {
            vendor: LocalStore.getInstance().read('vendor'),
            currentPage: 1,
            totalItems: 0,
            pageSize: 100,
            pageCount: 0,
            loading: false,
            issue: {},
            warehouse: {},
            orders: [],
            campaigns: [],
            courierServices: [],
            userCreatedBy: [],
            initialUserCreatedBy: [],
            initialCampaigns: [],
            loadingCampaign: false,
            loadingCreatedBy: false,
            loadingCourierService: false,
            loadingOrder: false,
            orderStatuses: [
                {key:'DRAFT', label: t("order_status.DRAFT")},
                {key:'NEW', label: t("order_status.NEW")},
                {key:'READY', label: t("order_status.READY")},
                {key:'HOLD', label: t("order_status.HOLD")},
                {key:'CANCELLED', label: t("order_status.CANCELLED")},
                {key:'PICKED_UP', label: t("order_status.PICKED_UP")},
                {key:'DELIVERING', label: t("order_status.DELIVERING")},
                {key:'DELIVERED', label: t("order_status.DELIVERED")},
                {key:'EXCHANGE', label: t("order_status.EXCHANGE")},
                {key:'RETURNING', label: t("order_status.RETURNING")},
                {key:'RETURNED', label: t("order_status.RETURNED")},
            ]
        };
    }

    componentDidMount = () => {
        this.mounted = true;
        window.addEventListener("popstate", this.onPopstate.bind(this), false);
        this.loadData();
    };

    componentWillUnmount() {
        this.mounted = false;
        window.removeEventListener("popstate", this.onPopstate.bind(this), false);
    }

    onPopstate() {
        let currentModule = getUrlSegment(2);
        // Url thay đổi thì chỉ load lại list
        if(this.mounted && currentModule === this.moduleName) {
            let queryObject = getParamsAsObject(getUrlQueryString());
            this.fetchOrders(queryObject);
        }
    };

    getId = () => {
        return this.props.match.params.id;
    };

    loadData = () => {
        let queryObject = getParamsAsObject(getUrlQueryString());
        this.fetchOrders(queryObject);
        this.fetchStockIssueDetail();
        this.fetchCampaign({}, (error, response) => {
            if(!error) {
                this.setState({initialCampaigns: response.list});
            }
        });
        this.fetchCourierService();
        this.fetchUsers({}, (error, response) => {
            if(!error) {
                this.setState({initialUserCreatedBy: response.list});
            }
        });
    };

    fetchOrders = (filter = {}, cb) => {
        this.setState({loadingOrder: true});
        let defaultFilter = {
            order: ["createdAt desc"],
            include: ["orderItems", "campaign", "creator"],
            issue_id: this.getId()
        };
        filter = _.mergeWith(defaultFilter, filter, arrayConcat);
        OrderService.getList(filter, (error, response) => {
            this.setState({loadingOrder: false});
            if(!error) {
                this.setState({
                    orders: response.list,
                    currentPage: response.metadata.currentPage,
                    totalItems: response.count,
                    pageSize: response.metadata.pageSize,
                    pageCount: response.metadata.pageCount
                });
            }
            doCallBack(cb, [error, response]);
        });
    };

    fetchCourierService = (filter = {}, cb) => {
        this.setState({loadingCourierService: true});
        CourierService.getList(filter, (error, response) => {
            this.setState({loadingCourierService: false});
            if(!error) {
                this.setState({courierServices: response.list});
            }
            doCallBack(cb, [error, response]);
        });
    };

    fetchUsers = (filter = {}, cb) => {
        this.setState({loadingCreatedBy: true});
        const {vendor} = this.state;
        VendorService.getUserInVendor(vendor.id, filter, (error, response) => {
            this.setState({loadingCreatedBy: false});
            if(!error) {
                this.setState({
                    userCreatedBy: response.list
                });
            }
            doCallBack(cb, [error, response]);
        });
    };

    fetchCampaign = (filter = {}, cb) => {
        this.setState({loadingCampaign: true});
        const {vendor} = this.state;
        let defaultFilter = {vendor_id: vendor.id, page_size: 20};
        defaultFilter = Object.assign(defaultFilter, filter);
        CampaignService.getCampaigns(defaultFilter, (error, response) => {
            if(!error) {
                this.setState({campaigns: response.list, loadingCampaign: false});
            }
            doCallBack(cb, [error, response]);
        });
    };

    fetchStockIssueDetail = (cb) => {
        const {vendor} = this.state;
        const id = this.getId();
        this.setState({loading: true});
        StockIssueService.getStockIssueDetail(id, (error, response) => {
            this.setState({loading: false});
            if(!error) {
                if(response.vendorId !== vendor.id){
                    this.props.history.replace('/404');
                }

                this.setState({
                    issue: response,
                    warehouse: objectGet(response, 'warehouse', {})
                });
            }
            doCallBack(cb, [error, response]);
        });
    };

    resetData = (key) => {
        let initialKey = `initial${_.upperFirst(key)}`;
        this.setState({
            [key]: this.state[initialKey]
        })  ;
    };

    pushQueryString = (queryString) => {
        this.props.history.push(`/warehouse/issues/${this.getId()}/${queryString}`);
    };

    getUrlInstance = (newInstance = false) => {
        if(newInstance) {
            return new Url();
        }
        return new Url('?'+getUrlQueryString());
    };

    onShowSizeChange = (current, pageSize) => {
        let uri = this.getUrlInstance();
        uri.replaceParam('page', 1);
        uri.replaceParam('pageSize', pageSize);
        this.pushQueryString(uri.getQueryString());
    };

    onChangePage = (page, pageSize) => {
        let uri = this.getUrlInstance();
        uri.replaceParam('page', page);
        uri.replaceParam('pageSize', pageSize);
        this.pushQueryString(uri.getQueryString());
    };

    changeIssueCourier = (courierId,cb) => {
        const {t} = this.props;
        const id = this.getId();
        this.setState({loading: true});
        StockIssueService.changeCarrier(id,courierId, (error, response) => {
            if(!error) {
                this.fetchStockIssueDetail();
                notification.success({
                    message: t('message.success')
                });
            }else{
                notification.error({
                    message: error.message
                });
            }
            doCallBack(cb, [error, response]);
        });
    };

    changeIssueStatus = (status,cb) => {
        const {t} = this.props;
        const id = this.getId();
        if(this.state.issue.status === 'NEW' && status === 'finish'){
            notification.error({
                message: t('error.receipt_detail_status')
            });
        }else if(this.state.issue.status === 'PROCESSING' && status === 'process'){
            return false
        }else{
            StockIssueService.changeIssueStatus(id,status, (error, response) => {
                if(!error) {
                    this.fetchStockIssueDetail();
                    notification.success({
                        message: t('message.success')
                    });
                }else{
                    notification.error({
                        message: error.message
                    });
                }

                let queryObject = getParamsAsObject(getUrlQueryString());
                this.fetchOrders(queryObject);

                doCallBack(cb, [error, response]);
            });
        }
    };

    deleteOrders = (orderIds, cb) => {
        const issueId = this.getId();
        StockIssueService.deleteOrderInStockIssue(issueId, orderIds, cb);
    };

    render() {
        const {t} = this.props;
        const {loading} = this.state;
        return (
            <StandardLayout
                {...this.props}
                title={t('stock_issue_detail.title')}
                loading={loading}
            >
                <div className="issue-detail-page">
                    <Bcrumb
                        {...this.props}
                        {...this.state}
                    />
                    <Info
                        {...this.props}
                        {...this.state}
                        getId={this.getId}
                        changeIssueCourier={this.changeIssueCourier}
                        changeIssueStatus={this.changeIssueStatus}
                    />

                    <div className="list-container">
                        <OrderFilter
                            {...this.props}
                            {...this.state}
                            pushQueryString={this.pushQueryString}
                            getUrlInstance={this.getUrlInstance}
                            onShowSizeChange={this.onShowSizeChange}
                            onChangePage={this.onChangePage}
                            resetData={this.resetData}
                            fetchUsers={this.fetchUsers}
                            fetchCampaign={this.fetchCampaign}
                        />

                        <OrderList
                            {...this.props}
                            {...this.state}
                            getId={this.getId}
                            onChangePage={this.onChangePage}
                            onShowSizeChange={this.onShowSizeChange}
                            deleteOrders={this.deleteOrders}
                            fetchOrders={this.fetchOrders}
                        />
                    </div>
                </div>
            </StandardLayout>
        );
    }
}

export default (translate())(IssueDetail);