import React, {Component} from 'react';
import {Editor as ReactEditor} from 'react-draft-wysiwyg';
import {ContentState, convertToRaw, EditorState, getDefaultKeyBinding} from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {Button} from 'antd';

class Editor extends Component {

    static defaultProps = {
        loading: false,
        onChange: (value, editor) => {},
        onSave: (value) => {},
        onCancel: () => {},
        data: "",
        autoFocus: false,
        showButton: true,
        buttonOkClassName: "",
        buttonCancelClassName: "",
        className: ""
    };

    constructor(props) {
        super(props);
        this.editorReferece = null;
        this.state = {
            editorState: EditorState.createEmpty(),
            isKeyDown: false,
			firstInitState: null
        };
    }

	componentDidMount() {
		if(this.props.autoFocus) {
			this.editorReferece.focus();
		}

		if(this.props.data){
			this.setDefaultState();
		}
	}

    componentDidUpdate = (prevProps, prevState) => {
        if(!prevProps.data && prevProps.data !== this.props.data && !prevState.firstInitState) {
        	this.setState({firstInitState: true}, () => {
				this.setDefaultState();
			})
        }
    };

    setDefaultState = () => {
        if(this.props.data) {
            const contentBlock = htmlToDraft(this.props.data);
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks, contentBlock.entityMap);
                const editorState = EditorState.createWithContent(contentState);
                this.setState({
                    editorState
                });
            }
        }
    };

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        }, () => {
			if(this.state.isKeyDown) {

				let content = editorState.getCurrentContent().getPlainText().length > 0
					? draftToHtml(convertToRaw(editorState.getCurrentContent())) : EditorState.createEmpty();

				this.props.onChange(content, editorState);
			}
		});
    };

    onKeyDown = (e) => {
        this.setState({isKeyDown: true});
        return getDefaultKeyBinding(e);
    };

    setEditorReference = (ref) => {
        this.editorReferece = ref;
    };

    render() {
        const {t, loading, showButton, onSave, onCancel, data, buttonOkClassName, buttonCancelClassName, className} = this.props;
        const {editorState} = this.state;

        return (
            <div className="container-fluid">
                <div className={`editor ${className}`}>
                    <ReactEditor
                        editorRef={this.setEditorReference}
                        editorState={this.state.editorState}
                        onEditorStateChange={this.onEditorStateChange}
                        keyBindingFn={this.onKeyDown}
                        toolbar={{
                            options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'image', 'remove', 'history']
                        }}
                    />
                </div>
                {showButton && (
                    <div className={"button-group"}>
                        <Button
                            type="primary"
                            loading={loading}
                            onClick={()=>onSave(draftToHtml(convertToRaw(editorState.getCurrentContent())))}
                            className={`btn button-ok mg-r-5 ${buttonOkClassName}`}
                            disabled={editorState.getCurrentContent().getPlainText().length > 0 && draftToHtml(convertToRaw(editorState.getCurrentContent()))===data}
                        > {t('button.update')}</Button>
                        <Button
                            onClick={()=>onCancel()}
                            className={`btn button-cancel ${buttonCancelClassName}`}
                        >{t('button.cancel')}</Button>
                    </div>
                )}
            </div>
        );
    }
}
export {Editor}
