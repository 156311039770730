import EagleClient from "../utils/api/EagleClient";

export class CourierService {

    static getList = (filter = {}, cb) => {
        let endpoint = "/CourierServices";
        EagleClient.request({
            endpoint: endpoint,
            method: "GET",
            mode: "free",
            queryString: {...filter}
        }, cb);
    }
}