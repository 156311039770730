import React from 'react';
import {Modal, Select, Form, Empty, Input, notification} from "antd";

class PopupReturningReason extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    }

    onOk = () => {
        const {validateFields} = this.props.form;
        validateFields((error, values) => {
            if(!error) {
                this.setState({loading: true});
                let payload = {
                    status: 'RETURNING',
                    subStatus: values.subStatus,
                    cancelledReason: values.cancelledReason
                };
                this.props.updateOrderStatus(payload, (error, response) => {
                    this.setState({loading: false});
                    if(!error) {
                        this.props.togglePopupReturning();
                    }
                    this.props.onUpdateOrderStatusResponse(error, response);
                });
            }
        });
    };

    onCancel = () => {
        const {resetFields} = this.props.form;
        resetFields();
        this.props.togglePopupReturning();
    };

    onChangeSubReason = () => {
        const {resetFields} = this.props.form;
        resetFields(['cancelledReason']);
    };

    render() {
        const {loading} = this.state;
        const {t, visible, orderStatuses} = this.props;
        const {getFieldDecorator, getFieldValue} = this.props.form;
        let subStatus = getFieldValue('subStatus');
        let reasonArr = [];
        let childReason = [];
        orderStatuses.map((item) => {
            if(item.key === 'RETURNING') {
                reasonArr = item.children;
            }
        });

        reasonArr.map((item) => {
            if (item.key === subStatus) {
                childReason = item.children;
            }
        });
        return (
            <Modal
                className="_modal_returning_reason"
                title={t('order_detail.add_reason')}
                okText={t('button.save')}
                cancelText={t('button.cancel')}
                visible={visible}
                onOk={this.onOk}
                onCancel={this.onCancel}
                okButtonProps={{
                    loading: loading,
                    className: "_btn_submit_order_status_returning"
                }}
                cancelButtonProps={{
                    className: "_btn_cancel_order_status_returning"
                }}
            >
                <Form layout="vertical">
                    <Form.Item label={t('order.source')}>
                        {getFieldDecorator('subStatus', {
                            rules: [
                                {required: true, message: t('message.required')}
                            ]
                        })(
                            <Select
                                className="_sub_status_returning_reason"
                                placeholder={'-Select-'}
                                defaultActiveFirstOption={false}
                                notFoundContent={<Empty description={t("table.empty")}/>}
                                onChange={this.onChangeSubReason}
                            >
                                {reasonArr.map((item) => (
                                    <Select.Option
                                        key={item.key}
                                        value={item.key}
                                        className="_option_sub_status_returning_reason"
                                    >
                                        {t(`order_status_cancelled.${item.key}`)}
                                    </Select.Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>

                    {subStatus !== 'OTHER' && (
                        <Form.Item label={t('order_detail.reason')}>
                            {getFieldDecorator('cancelledReason', {
                                rules: [
                                    {required: true, message: t('message.required')}
                                ]
                            })(
                                <Select
                                    placeholder={'-Select-'}
                                    defaultActiveFirstOption={false}
                                    notFoundContent={<Empty description={t("table.empty")}/>}
                                    disabled={!subStatus}
                                >
                                    {childReason.map((item) => (
                                        <Select.Option
                                            key={item.key}
                                            value={item.key}
                                            className="_option_child_sub_status_returning_reason"
                                        >
                                            {t(`order_status_cancelled.${item.key}`)}
                                        </Select.Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>
                    )}

                    {subStatus === 'OTHER' && (
                        <Form.Item label={t('order_detail.reason')}>
                            {getFieldDecorator('cancelledReason', {
                                rules: [
                                    {required: true, message: t('message.required')},
                                    {max: 255, message: t('error.length_255')}
                                ]
                            })(
                                <Input.TextArea className="_other_returning_cancelled_reason" />
                            )}
                        </Form.Item>
                    )}
                </Form>
            </Modal>
        )
    }
}

PopupReturningReason = Form.create()(PopupReturningReason);

export {PopupReturningReason};