import React from 'react';
import {Row, Col, Icon, Dropdown, Menu} from 'antd';
import {formatDate} from "../../../../../utils/helper";

class StockReceiptInfo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    getReceiptType = (type) => {
        const {t} = this.props;
       if(!type){
           return t('stock_issue_detail.select')
       }else{
           return t(`receipt_type.${type}`)
       }
    };

    render() {
        const {t, receipt} = this.props;
        const menu = (
            <Menu>
                <Menu.Item>
                    <span onClick={this.props.changeReceiptType.bind(this,"INTERNAL")}>{t("receipt_type.INTERNAL")}</span>
                </Menu.Item>
                <Menu.Item>
                    <span onClick={this.props.changeReceiptType.bind(this,"EXTERNAL")}>{t("receipt_type.EXTERNAL")}</span>
                </Menu.Item>
            </Menu>
        );

        const menuStatus = (
            <Menu>
                <Menu.Item>
                    <span>{t("stock_receipt_status.NEW")}</span>
                </Menu.Item>
                <Menu.Item>
                    <span onClick={this.props.changeReceiptStatus.bind(this,"process")}>{t("stock_receipt_status.PROCESSING")}</span>
                </Menu.Item>
                <Menu.Item>
                    <span onClick={this.props.changeReceiptStatus.bind(this,"finish")}>{t("stock_receipt_status.DONE")}</span>
                </Menu.Item>
            </Menu>
        );
        return (
            <div className="receipt-basic-info">
                <div className="white-box">
                    <h1>{receipt.receiptNumber}</h1>
                    <Row gutter={20} className="form-item">
                        <Col span={4} className={`_receipt_status status ${receipt.status}`}>
                            <div className="form-item-label">{t("stock_receipt.status")}</div>
                            <div className="form-item-control">
                                {receipt.products && !!receipt.products.length && receipt.status !== 'DONE' && (
                                    <Dropdown overlay={menuStatus} trigger={['click']}>
                                            <span className="text pointer">{t(`stock_issue_status.${receipt.status}`)}
                                                <Icon type="down" size="small" style={{fontSize: 10}} />
                                            </span>
                                    </Dropdown>
                                )}

                                {receipt.products && !!receipt.products.length && receipt.status === 'DONE' && (
                                    <span className="text">{t(`stock_issue_status.${receipt.status}`)}</span>
                                )}

                                {receipt.products && !receipt.products.length && (
                                    <span className="text">{t(`stock_issue_status.${receipt.status}`)}</span>
                                )}
                            </div>
                        </Col>
                        <Col span={4}>
                            <div className="form-item-label">{t("stock_receipt.created_at")}</div>
                            <div className="form-item-control">{formatDate(receipt.createdAt)}</div>
                        </Col>
                        <Col span={4}>
                            <div className="form-item-label">{t("stock_receipt.created_by")}</div>
                            <div className="form-item-control">{receipt.createdBy}</div>
                        </Col>
                        <Col span={4}>
                            <div className="form-item-label">{t("stock_receipt.receipt_type")}</div>
                            <div className="form-item-control">
                                {receipt.status === 'DONE' && (
                                    <span>{this.getReceiptType(receipt.receiptType)} </span>
                                )}

                                {receipt.status !== 'DONE' && (
                                    <Dropdown overlay={menu} trigger={['click']}>
                                        <span className="pointer">{this.getReceiptType(receipt.receiptType)} <Icon type="down" size="small" style={{fontSize: 10,color: 'rgb(171, 171, 171)'}} /></span>
                                    </Dropdown>
                                )}
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export {StockReceiptInfo}