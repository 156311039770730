import React, {Component} from 'react';
import {translate} from 'react-i18next';
import {Form as AntForm} from 'antd';
import StandardLayout from "../../../components/StandardLayout";
import {Header} from "./Header";
import {Form as FormUpdate} from "./Form";
import {LocalStore} from "../../../utils/LocalStore";
import {notification} from "antd/lib/index";
import {REPORT_SCOPES} from "../../../utils/api/Scopes";
import UserService from "../../../services/UserService";
import ReportService from "../../../services/ReportService";
import * as _ from "lodash";


class ReportUpdate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            vendor: LocalStore.getInstance().read('vendor'),
            isLoading: false,
            statistic: {},
            groups: [],
            groupsNotInReport: [],
            groupsNotInReportDefault: [],
            loadingButtonAdd: false,
            loadingButtonDelete: false
        };
        this.refForm = React.createRef();
    };

    componentWillMount() {
        if (!UserService.isAllowed(REPORT_SCOPES.REPORT_UPDATE)) {
            this.props.history.replace('/403')
        }
    };

    componentDidMount() {
        this.apiGetStatisticDetail();
        this.apiGetGroups();
        this.apiGetGroupsNotIn({},
            (message,response) => {
                this.setState({
                    groupsNotInReportDefault: response.list
                })
            }
        );
    }

    apiGetGroupsNotIn = (filter = {}, cb) => {
        let id = this.props.match.params.id;
        ReportService.getGroupsNotIn(id, filter, (err, response) => {
            if(!err){
                this.setState({
                    groupsNotInReport: response.list,
                });
            }else{
                notification.error({
                    message: err.message
                });
            }
            if(typeof cb === 'function') cb(err, response);
        });
    };

    apiAddGroup = (groupIds) => {
        this.setState({
            loadingButtonAdd: true
        });
        const {t} = this.props;
        let id = this.props.match.params.id;
        let payload = {
            groupIds: groupIds
        };
        ReportService.addGroups(id, payload, (err, response) => {
            this.setState({
                loadingButtonAdd: false
            });
            if (!err) {
                notification.success({
                    message: t('message.success')
                });
                this.apiGetGroups();
                this.apiGetGroupsNotIn();
            } else {
                notification.error({
                    message: err.message
                });
            }
        });
    };

    apiDeleteGroups = (groupsIds) => {
        this.setState({
            loadingButtonDelete: true
        });
        const {t} = this.props;
        let id = this.props.match.params.id;
        let payload = {groupIds: groupsIds};
        ReportService.removeGroups(id, payload,(err, response) => {
            this.setState({
                loadingButtonDelete: false
            });
            if (!err) {
                notification.success({
                    message: t('message.success')
                });
                this.apiGetGroups();
                this.apiGetGroupsNotIn({},
                    (message,response) => {
                        this.setState({
                            groupsNotInReportDefault: response.list
                        })
                    }
                );
            } else {
                notification.error({
                    message: err.message
                });
            }
        });
    };

    onSearchGroup = (value) => {
        this.apiGetGroupsNotIn({name: value});
    };

    apiGetGroups = (filter = {}, cb) => {
        let id = this.props.match.params.id;
        ReportService.getGroups(id, filter, (err, response) => {
            if(!err){
                this.setState({
                    groups: response.list,
                });
            }else{
                notification.error({
                    message: err.message
                });
            }
            if(typeof cb === 'function') cb(err, response);
        });
    };

    apiGetStatisticDetail = (cb) => {
        let id = this.props.match.params.id;
        let filter = {
            filter: {include: ['vendor']}
        };
        ReportService.detailReportById(id, filter, (err, response) => {
            if (!err) {
                if(response.vendorId !== this.state.vendor.id){
                    this.props.history.replace('/404');
                }
                this.setState({
                    statistic: response,
                })
            } else {
                if(err.statusCode === 404){
                    this.props.history.replace('/404');
                }else{
                    notification.error({
                        message: err.message
                    });
                }
            }
            if(typeof cb === 'function') cb(err, response);
        });
    };

    updateReport = (payload) => {
        let id = this.props.match.params.id;
        const {t} = this.props;
        this.setState({
            isLoading: true
        });
        ReportService.updateReport(id,payload, (error, response) => {
            if (!error) {
                notification.success({
                    message: t('message.success')
                });
                this.props.history.push('/statistics-list');
            } else {
                notification.error({
                    message: error.message
                });
            }
            this.setState({
                isLoading: false
            });
        });
    };

    onSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let payload = values;
                payload.script = payload.script.trim();
                payload.title = payload.title.trim();
                this.updateReport(payload);
            }
        });
    };

    render() {
        const {t} = this.props;
        return (
            <StandardLayout title={t('statistic.edit')} {...this.props}>
                <div className="ad-add-page _update-report">
                    <AntForm onSubmit={this.onSubmit}>
                        <Header
                            {...this.props}
                            {...this.state}
                        />
                        <FormUpdate
                            {...this.props}
                            {...this.state}
                            onSearchGroup={this.onSearchGroup}
                            apiAddGroup={this.apiAddGroup}
                            apiDeleteGroups={this.apiDeleteGroups}
                            ref={this.refForm}
                        />
                    </AntForm>
                </div>
            </StandardLayout>
        )
    }
}

ReportUpdate = AntForm.create()(ReportUpdate);
export default (translate())(ReportUpdate);