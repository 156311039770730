import React, {Component} from 'react';
import {Select} from 'antd';

class SelectRegion extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            value: undefined,
            regions: []
        }
    }

    static getDerivedStateFromProps(props, state) {
        let newState = {...state};
        if ('value' in props) {
            newState.value = props.value;
        }

        if ('items' in props) {
            newState.regions = props.items;
        }

        return newState;
    }

    handleChange = (value) => {
        this.setState({value});
        this.props.onChange(value);
    };

    render() {
        const {regions, loading, value} = this.state;
        const {selectProps} = this.props;

        return (
            <Select
                {...selectProps}
                allowClear={true}
                loading={loading}
                value={value}
                onChange={this.handleChange}
                className={'width-100pc'}
                placeholder={"Region"}
            >
                {regions.map(country => (
                    <Select.Option key={country.id} value={country.id}>{country.name}</Select.Option>
                ))}
            </Select>
        );
    }
}

SelectRegion.defaultProps = {
    onChange: (values) => {},
    selectProps: {}
};

export default SelectRegion;

