import React, {Component} from 'react';
import {
    Modal, Form, Table, Select, Icon, Input, InputNumber
} from 'antd';

const Option = Select.Option;

class FormUpdateProduct extends Component {

    clearValueWhenDelete = (sku) => {
        this.props.clearValueWhenDelete(sku)
    };

    getConfigTableColumns = () => {
        const {t} = this.props;
        const {getFieldDecorator} = this.props.form;
        const renderColumn = (key, text, record) => {
            switch (key) {
                case 'quantity':
                    return (
                        <Form.Item style={{marginBottom: 0}}>
                            {getFieldDecorator(`${record.sku}[quantity]`,
                                {
                                    initialValue: record.quantity?record.quantity:undefined
                                }
                            )(
                                <InputNumber
                                    min={1}
                                    max={999999}
                                    className="_product_quantity"
                                    style={{width: '100%'}}
                                    parser={value => value.replace(/\D+/gm, '')}
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                />
                            )}
                        </Form.Item>
                    );
                case 'price':
                    return (
                        <Form.Item style={{marginBottom: 0}}>
                            {getFieldDecorator(`${record.sku}[price]`,
                                {
                                    initialValue: record.price?record.price:undefined,
                                }
                            )(
                                <InputNumber
                                    min={1}
                                    max={999999}
                                    className="_product_price"
                                    style={{width: '100%'}}
                                    parser={value => value.replace(/\D+/gm, '')}
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                />
                            )}
                        </Form.Item>
                    );

                case 'sku':
                    return (
                        <Form.Item style={{marginBottom: 0}}>
                            {getFieldDecorator(`${record.sku}[sku]`,{
                                initialValue: record.sku?record.sku:undefined
                            })(
                                <span>{record.sku}</span>
                            )}
                        </Form.Item>
                    );
                case 'delete':
                    return (
                        <Form.Item style={{marginBottom: 0}}>
                            <Icon className="_delete-sku pointer" type="delete" onClick={this.clearValueWhenDelete.bind(this,record.sku)}/>
                        </Form.Item>
                    )

            }
        };
        return [
            {title: t("products.properties"),dataIndex: 'display', key: 'display',className:"_properties _item", width:150},
            {title: "SKU",dataIndex: 'sku', key: 'sku',className:"_sku _item", width:200, render: renderColumn.bind(this, 'sku')},
            {title: t("ads.quantity"),dataIndex: 'quantity', key: 'quantity',className:"_quantity _item", width:150, render: renderColumn.bind(this, 'quantity')},
            {title: t("ads.price"),dataIndex: 'price', key: 'price',className:"_price _item", width:200, render: renderColumn.bind(this, 'price')},
            {title: "",dataIndex: 'delete', key: 'delete',className:"_delete _item", width:50, render: renderColumn.bind(this, 'delete')},
        ];
    };

    render() {
        const {
            isShowPopUpUpdateProduct, onCancel, onUpdate,t,receiptProductUpdate, form
        } = this.props;
        const columns = this.getConfigTableColumns();
        const { getFieldDecorator } = form;
        return (
            <Modal
                className="modal-update-product-receipt"
                visible={isShowPopUpUpdateProduct}
                title={t("stock_receipts_detail.edit_propduct")}
                onCancel={onCancel}
                onOk={onUpdate}
                okButtonProps={{
                    className: "_update-product-save"
                }}
                cancelButtonProps={{
                    className: "_update-product-cancel",
                }}
                cancelText={t("button.cancel")}
                okText={t("button.save")}
                destroyOnClose={true}
                width={850}
                maskClosable={false}
            >
                <Form layout="vertical">
                    {isShowPopUpUpdateProduct && (
                        <Form.Item>
                            {getFieldDecorator('productInWarehouse', {
                                initialValue: receiptProductUpdate.id
                            })(
                                <Select
                                    disabled={isShowPopUpUpdateProduct}
                                    className="_select-update-product"
                                >
                                    <Select.Option key={receiptProductUpdate.name} value={receiptProductUpdate.id} className="_option-products">{receiptProductUpdate.name}</Select.Option>
                                </Select>
                            )}
                        </Form.Item>

                    )}

                    {isShowPopUpUpdateProduct && !!receiptProductUpdate.productSku.length && (
                        <Table
                            columns={columns}
                            dataSource={receiptProductUpdate.productSku}
                            rowKey={record => `table_sku_${record.sku}`}
                            locale={{
                                emptyText: t("table.empty")
                            }}
                            pagination={false}
                            scroll={{y: 400}}
                        />
                    )}
                </Form>
            </Modal>
        );
    }
}

FormUpdateProduct = Form.create()(FormUpdateProduct);
export {FormUpdateProduct}