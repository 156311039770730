import React from 'react';
import {Card, Icon} from 'antd';
import {formatCurrency} from "../../../utils/currency";
import {doCallBack} from "../../../utils/helper";
import {Link} from "react-router-dom";

class Invoice extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            order: {},
            loading: false
        };
    }

    componentDidUpdate = (prevProps) => {
        if(JSON.stringify(prevProps.contact) !== JSON.stringify(this.props.contact)) {
            this.fetchNewestOrder();
        }
    };

    componentDidMount = () => {
        this.fetchNewestOrder();
    };

    fetchNewestOrder = (cb) => {
        this.setState({loading: true});
        this.props.fetchNewestOrder((error, response) => {
            this.setState({loading: false});
            if(!error) {
                if(response.list.length) {
                    this.setState({
                        order: response.list[0]
                    });
                }
            }
            doCallBack(cb, [error, response]);
        });
    };

    renderRow = (key, value) => {
        return (
            <div className="info-row">
                <label className="label bold-400">{key}: </label>
                <span className="text">{value}</span>
            </div>
        );
    };

    render() {
        const {order, loading} = this.state;
        const {orderItems} = order;
        const {t, currencyUnit} = this.props;
        let totalQuantity = 0;
        let totalAmount = 0;
        if(Array.isArray(orderItems)) {
            for(let item of orderItems) {
                totalQuantity += parseFloat(item.quantity);
                totalAmount += parseFloat(item.amount);
            }
        }
        return (
            <Card
                className={"_summary_invoice_box_container"}
                title={t('contact-detail.invoice')}
                loading={loading}
            >
                {this.renderRow(t('contact-detail.order_code'), <Link to={`/orders/${order.id}`}>{order.code}</Link>)}
                {this.renderRow(t('contact-detail.status'), order.status)}
                <table className="table-order-newest">
                    <thead>
                        <tr>
                            <th className='properties-row'>{t('contact-detail.properties')}</th>
                            <th>{t('contact-detail.unit_price')} <b>({currencyUnit})</b></th>
                            <th>{t('contact-detail.quantity')}</th>
                            <th>{t('contact-detail.price')} <b>({currencyUnit})</b></th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(orderItems) && orderItems.map((item) => (
                            <tr key={item.id}>
                                <td>{item.gift && <Icon type="gift"/>} {item.skuDisplay}</td>
                                <td>{formatCurrency(item.unitPrice)}</td>
                                <td>{formatCurrency(item.quantity)}</td>
                                <td>{formatCurrency(item.amount)}</td>
                            </tr>
                        ))}

                        <tr>
                            <td/>
                            <td><b>{t('contact-detail.total_amount')}</b></td>
                            <td><b>{formatCurrency(totalQuantity)}</b></td>
                            <td style={{color: 'rgba(24, 144, 255, 1)'}}>{formatCurrency(totalAmount)}</td>
                        </tr>
                    </tbody>
                </table>
            </Card>
        )
    }
}

export {Invoice};