import React from 'react';
import {Table, Pagination, Button, Row, Col, Popconfirm} from 'antd';
import {formatDate, getParamsAsObject, getUrlQueryString, objectGet} from "../../../../../utils/helper";
import {Link} from "react-router-dom";
import UserService from "../../../../../services/UserService";
import {WAREHOUSE_SCOPES} from "../../../../../utils/api/Scopes";
import {toastr} from "../../../../../utils/toastr";

class OrderList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedRowKeys: [],
            loadingDeleteOrder: false,
            isShowConfirmDeleteOrder: false
        }
    }

    deleteOrders = () => {
        const {t} = this.props;
        const {selectedRowKeys} = this.state;
        this.props.deleteOrders(selectedRowKeys, (error, response) => {
            this.setState({selectedRowKeys: []});
            if (error === null) {
                toastr.success(t("message.success"));
                let queryObject = getParamsAsObject(getUrlQueryString());
                this.props.fetchOrders(queryObject);
            } else {
                toastr.error(error.message);
            }
        });
    };

    onSelectedRows = (selectedRowKeys) => {
        this.setState({selectedRowKeys});
    };

    onPopconfirmDeleteOrderVisibleChange = (visible) => {
        this.setState({isShowConfirmDeleteOrder: visible});
    };

    render() {
        const {t, issue, orders, loadingOrder, totalItems, currentPage, pageSize, pageCount, onShowSizeChange, onChangePage} = this.props;
        const {selectedRowKeys, loadingDeleteOrder, isShowConfirmDeleteOrder} = this.state;
        const renderCode = (text, record) => {
            return <Link to={`/orders/${record.id}`}>{text}</Link>
        };
        const renderCampaign = (text, record) => {
            return objectGet(record, 'campaign.name');
        };
        const renderCreatedAt = (text, record) => {
            return formatDate(text);
        };
        const renderCreatedBy = (text, record) => {
            return objectGet(record, 'creator.username');
        };
        const renderDeliveryDate = (text, record) => {
            return text ? formatDate(text) : "";
        };
        const renderStatus = (text) => {
            return <span className={`status ${text}`}>{text}</span>
        };
        const columns = [
            {title: t("order_list.code"),dataIndex: 'code', key: 'code', render: renderCode, width:200,className:"_code _item"},
            {title: t("order_list.customerName"),dataIndex: 'contactName', key: 'contactName',width:200,className:"_contactName _item"},
            {title: t("order_list.customerPhone"),dataIndex: 'contactPhone', key: 'contactPhone',width:200,className:"_contactPhone _item"},
            {title: t("order_list.campaign"),dataIndex: 'campaign', key: 'campaign', render: renderCampaign, width:200,className:"_campaign _item"},
            {title: t("order_list.created_at"),dataIndex: 'createdAt', key: 'createdAt', render: renderCreatedAt, width:200,className:"_createdAt _item"},
            {title: t("order_list.createdBy"),dataIndex: 'createdBy', key: 'createdBy', render: renderCreatedBy, width:200,className:"_createdBy _item"},
            {title: t("order_list.deliveryDate"),dataIndex: 'deliveryDate', key: 'deliveryDate', render: renderDeliveryDate, width:200,className:"_deliveryDate _item"},
            {title: t("order_list.status"),dataIndex: 'status', key: 'status', render: renderStatus, width:200,className:"_status _item"},
        ];

        const rowSelection = {
            selectedRowKeys: selectedRowKeys,
            onChange: this.onSelectedRows
        };

        return (
            <div className="order-list-table">
                <Row className="pd-bt-15">
                    <Col span={5}>
                        {!loadingOrder && !!totalItems && (
                            <span>{t("contact.page")} {currentPage}/{pageCount} ({totalItems})</span>
                        )}

                        {!loadingOrder && !totalItems && (
                            <span>{t("contact.page")} {1}/{1} ({totalItems})</span>
                        )}
                    </Col>
                    <Col span={19} className="text-right">
                        {issue.status !== "DONE" && UserService.isAllowed(WAREHOUSE_SCOPES.WAREHOUSE_ISSUES_UPDATE) && (
                            <>
                                <Button
                                    type="primary"
                                    className="_btn_add_stock_issue mg-r-10"
                                    onClick={this.addOrder}
                                >
                                    <Link to={`/warehouse/issues/${this.props.getId()}/add-order`}>{t('stock_issue_detail.addOrder')}</Link>
                                </Button>

                                <Popconfirm
                                    onVisibleChange={this.onPopconfirmDeleteOrderVisibleChange}
                                    onConfirm={this.deleteOrders}
                                    visible={isShowConfirmDeleteOrder}
                                    title={t("group_user.confirm_delete")}
                                    okText={t("popup-confirm.confirm")}
                                    cancelText={t("popup-confirm.cancel")}
                                >
                                    <Button
                                        type="danger"
                                        className="_btn_remove_order"
                                        icon="delete"
                                        disabled={!selectedRowKeys.length}
                                        loading={loadingDeleteOrder}
                                    >
                                        {t('stock_issue_detail.delete_order')}
                                    </Button>
                                </Popconfirm>
                            </>
                        )}
                    </Col>
                </Row>

                <Table
                    columns={columns}
                    dataSource={orders}
                    rowKey={record => record.id}
                    rowSelection={rowSelection}
                    pagination={false}
                    loading={loadingOrder}
                    locale={{
                        emptyText: t("table.empty")
                    }}
                />

                {!loadingOrder && totalItems > 0 && (
                    <Pagination
                        className="mg-t-15 text-right"
                        showSizeChanger
                        onShowSizeChange={onShowSizeChange}
                        onChange={onChangePage}
                        pageSizeOptions={['25', '50', '75', '100', '150', '200']}
                        defaultCurrent={1}
                        current={currentPage}
                        pageSize={pageSize}
                        total={totalItems}
                    />
                )}
            </div>
        )
    }
}

export {OrderList}