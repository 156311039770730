import React, {Component} from 'react';
import {translate} from 'react-i18next';
import {Form as AntForm} from 'antd';
import StandardLayout from "../../../../components/StandardLayout";
import {Header} from "./Header";
import {Form as FormAdd} from "./Form";
import {arrayConcat, doCallBack} from "../../../../utils/helper";
import * as _ from "lodash";
import {LocalStore} from "../../../../utils/LocalStore";
import CampaignService from "../../../../services/CampaignService";
import AdSetService from "../../../../services/AdSetService";
import AdService from "../../../../services/AdService";
import {notification} from "antd/lib/index";
import {CAMPAIGNS_SCOPES} from "../../../../utils/api/Scopes";
import UserService from "../../../../services/UserService";


class AdUpdate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            vendor: LocalStore.getInstance().read('vendor'),
            ads: null,
            wholeSale: [],
            productSku: "",
            configWholeSalePrice: [],
            validateErrors: {},
            isLoading: false,
            landingUrls: []
        };
        this.refForm = React.createRef();
    };

    componentWillMount() {
        if (!UserService.isAllowed(CAMPAIGNS_SCOPES.CAMPAIGNS_UPDATE)) {
            this.props.history.replace('/403')
        }
    };

    componentDidMount = () => {
        this.fetchAd();
    };

    fetchAd = (cb) => {
        this.setState({isLoading: true});
        let adId = this.getAdId();
        AdService.getById(adId, {}, (error, response) => {
            if(null === error) {
                this.setState({
                    ads: response
                });
            } else {
                notification.error({
                    message: error.message
                });
            }
            this.setState({isLoading: false});
            doCallBack(cb,[error, response]);
        });

        AdService.getLandingUrls(adId, (error, response) => {
            if (null === error) {
                this.setState({
                    landingUrls: response
                })
            }
            else {
                this.setState({
                    landingUrls: []
                })
            }
        })
    };

    fetchCampaign = (id, cb) => {
        this.setState({isLoading: true});
        CampaignService.getById(id,{}, (error, response) => {
            this.setState({isLoading: false});
            doCallBack(cb,[error, response]);
        });
    };

    getAdId = () => {
        return this.props.match.params.id;
    };

    fetchAdsets = (filter = {}, cb) => {
        let {vendor} = this.state;
        let filterCond = {
            vendor_id: vendor.id,
            include: ['product','campaign'],
            order: ["createdAt DESC"],
        };
        filterCond = _.mergeWith(filterCond, filter, arrayConcat);
        AdSetService.getAdSets(filterCond, cb);
    };

    onSearchAdset = (value) => {
        this.fetchAdsets(value);
    };

    resetDataSource = (key) => {
        this.setState({
            [key]: this.state[`initial${_.upperFirst(key)}`]
        });
    };

    resetValidateErrors = () => {
        this.setState({validateErrors: {}});
    };

    onSubmit = (e) => {
        const {t} = this.props;
        const {ads} = this.state;
        let checkedItems = this.refForm.current.getCheckedConfigItems();
        let skuChecked = _.map(checkedItems, 'sku');
        const landingUrls = this.refForm.current.getState('landingUrls');

        this.props.form.validateFields((err, values) => {
            if(!skuChecked.length) {
                let validateErrors = this.state.validateErrors;
                validateErrors.wholeSaleConfig = t('ads.sku_required');
                this.setState({validateErrors});
            } else {
                this.setState({validateErrors: {}});
            }

            if (!err && skuChecked.length) {
                let adset;
                if(values.adset) {
                    adset = JSON.parse(values.adset);
                }
                let configWholeSalePrice = [];
                let wholeSalePrice = values.wholeSalePrice || [];

                _.forEach(wholeSalePrice, (v,k) => {
                    if(skuChecked.indexOf(k) >= 0) {
                        let rowPrice = [];
                        _.forEach(v, (value) => {
                            rowPrice.push(value);
                        });
                        let item = {
                            sku: k,
                            wholeSalePrice: rowPrice
                        };
                        configWholeSalePrice.push(item);
                    }
                });

                let data = {
                    id: ads.id,
                    campaignId: ads.campaignId,
                    name: values.name,
                    adDefault: values.adDefault,
                    facebookIds: values.facebookIds,
                    productType: "WHOLESALE",
                    configWholeSalePrice: configWholeSalePrice,
                    landingUrls: landingUrls
                };
                if(adset) {
                    data.adsetId = adset.id
                }

                this.setState({isLoading: true});

                // Call API here
                AdService.updateAd(data, (error, response) => {
                    if (!error) {
                        notification.success({
                            message: t('message.success')
                        });
                        this.props.history.push('/campaigns?tab=AD');
                    } else {
                        if (error.code === 'PRODUCT_NOT_ACTIVE') {
                            notification.error({
                                message: t('error.product_is_not_active')
                            });
                        } else if(error.code === 'INVALID_FACEBOOK_AD_ID') {
                            notification.error({
                                message: t('ads.fb_ads_id')
                            });
                        } else if(error.code === 'FB_AD_EXISTED') {
                            notification.error({
                                message: t('ads.fb_ad_existed')
                            });
                        } else if(error.code === 'AD_EXISTED') {
                            notification.error({
                                message: t('ads.ad_existed')
                            });
                        } else {
                            notification.error({
                                message: error.message
                            });
                        }
                    }

                    this.setState({isLoading: false});
                });
            }
        });
    };


    render() {
        const {t} = this.props;
        const {ads, isLoading} = this.state;
        return (
            <StandardLayout title={t('ads.edit')} {...this.props} loading={isLoading}>
                <div className="ad-add-page">
                    <AntForm>
                        <Header
                            {...this.props}
                            {...this.state}
                            onSubmit={this.onSubmit}
                        />
                        <FormAdd
                            {...this.props}
                            {...this.state}
                            resetDataSource={this.resetDataSource}
                            onSearchCampaign={this.onSearchCampaign}
                            onSearchAdset={this.onSearchAdset}
                            getConfigWholeSale={this.getConfigWholeSale}
                            fetchCampaign={this.fetchCampaign}
                            fetchAdsets={this.fetchAdsets}
                            ref={this.refForm}
                            validateErrors={this.state.validateErrors}
                            resetValidateErrors={this.resetValidateErrors}
                            ads={ads}
                            adId={this.getAdId()}
                        />
                    </AntForm>
                </div>
            </StandardLayout>
        )
    }
}

AdUpdate = AntForm.create()(AdUpdate);
export default (translate())(AdUpdate);
