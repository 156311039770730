import React, {Component} from 'react';
import {Filter} from "./Filter";
import {List} from "./List";
import withLocationFilter from "../../utils/withLocationFilter";
import {BreadCrumb} from "../../components/BreadCrumb";
import {Breadcrumb as AntBreadCrumb} from "antd";
import {Link} from "react-router-dom";
import StandardLayout from "../../components/StandardLayout";
import {translate} from "react-i18next";

class ReturnLead extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };
    }

    render() {
        const {t} = this.props;
        return (
            <StandardLayout
                {...this.props}
                title={t("contact.title")}
                showSelectVendor={false}
            >
                <div className="contact-list-page list-page">
                    <BreadCrumb
                        {...this.props}
                        leftContent={
                            <AntBreadCrumb>
                                <AntBreadCrumb.Item>
                                    <Link to={"/"}>{t('page.home')}</Link>
                                </AntBreadCrumb.Item>
                                <AntBreadCrumb.Item>{t("contact.contact_customer")}</AntBreadCrumb.Item>
                            </AntBreadCrumb>
                        }
                    />
                    <Filter {...this.props} />
                    <List {...this.props} />
                </div>
            </StandardLayout>
        );
    }
}

export default (translate())(withLocationFilter(ReturnLead))