import React from 'react';
import {translate} from 'react-i18next';
import {Layout} from "../Layout";
import {Filter} from "./Filter";
import {List} from "./List";
import {Url} from "../../../../utils/Url";
import {
    doCallBack, getCurrentModule, getParamsAsObject, getUrlQueryString,
    getUrlSegment
} from "../../../../utils/helper";
import {LocalStore} from "../../../../utils/LocalStore";
import * as _ from "lodash";
import WareHouseService from "../../../../services/WareHouseService";

class WarehouseStock extends React.Component {

    constructor(props) {
        super(props);
        this.moduleName = 'stocks';
        this.mounted = false;
        this.state = {
            items: [],
            vendor: LocalStore.getInstance().read('vendor'),
            currentPage: 1,
            totalItems: 0,
            pageSize: 100,
            pageCount: 0,
            loading: false,
        };
    }

    componentDidMount = () => {
        this.mounted = true;
        window.addEventListener("popstate", this.onPopstate.bind(this), false);
        this.loadData();
    };

    loadData = () => {
        let queryObject = getParamsAsObject(getUrlQueryString());
        this.fetchStock(queryObject);
    };

    componentWillUnmount() {
        this.mounted = false;
        window.removeEventListener("popstate", this.onPopstate.bind(this), false);
    }

    onPopstate() {
        let currentModule = getUrlSegment(3);
        // Url thay đổi thì chỉ load lại list
        if(this.mounted && currentModule === this.moduleName) {
            let queryObject = getParamsAsObject(getUrlQueryString());
            this.fetchStock(queryObject);
        }
    };

    getCode = () => {
        return this.props.match.params.code;
    };

    fetchStock = (filter = {}, cb) => {
        this.setState({loading: true});
        WareHouseService.getProduct(this.getCode(), filter, (error, response) => {
            this.setState({loading: false});
            if(error === null) {
                this.setState({
                    items: response.list,
                    currentPage: response.metadata.currentPage+1,
                    totalItems: response.count,
                    pageSize: response.metadata.pageSize,
                    pageCount: response.metadata.pageCount
                });
            }
            doCallBack(cb, [error, response]);
        });
    };

    getUrlInstance = (newInstance = false) => {
        if(newInstance) {
            return new Url();
        }
        return new Url('?'+getUrlQueryString());
    };

    pushQueryString = (queryString) => {
        this.props.history.push(`/warehouse/${this.getCode()}/stocks${queryString}`);
    };

    onShowSizeChange = (current, pageSize) => {
        let uri = this.getUrlInstance();
        uri.replaceParam('page', 1);
        uri.replaceParam('pageSize', pageSize);
        this.pushQueryString(uri.getQueryString());
    };

    onChangePage = (page, pageSize) => {
        let uri = this.getUrlInstance();
        uri.replaceParam('page', page);
        uri.replaceParam('pageSize', pageSize);
        this.pushQueryString(uri.getQueryString());
    };

    render() {
        const content = (
            <div className="tab-pane-inner-box">
                <div className="list-page issue-list-page">
                    <Filter
                        {...this.props}
                        {...this.state}
                        pushQueryString={this.pushQueryString}
                        getUrlInstance={this.getUrlInstance}
                    />
                    <List
                        {...this.props}
                        {...this.state}
                        onShowSizeChange={this.onShowSizeChange}
                        onChangePage={this.onChangePage}
                        getCode={this.getCode}
                    />
                </div>
            </div>
        );

        return (
            <Layout
                {...this.props}
                tabActive={'stock'}
                tabContent={content}
            />
        )
    }
}

export default (translate())(WarehouseStock)