import EagleClient from "../utils/api/EagleClient";

export default class VendorService {
    static getVendorList(filter,cb) {
        EagleClient.request({
            endpoint: "/Vendors",
            method: "GET",
            queryString: filter,
            mode: "free"
        }, cb);
    }

    static createVendor(data,cb) {
        EagleClient.request({
            endpoint: "/Vendors",
            method: "POST",
            body: data,
        }, cb);
    }

    static getUserInVendor(id,filter = {},cb) {
        EagleClient.request({
            endpoint: `/Vendors/${id}/users`,
            method: "GET",
            queryString: filter,
            mode: 'free'
        }, cb);
    }

    static getUsersNotInVendor = (id, filter, cb) => {
        EagleClient.request({
            endpoint: `/Vendors/${id}/users-not-in`,
            method: "GET",
            queryString: filter,
        }, cb);
    };

    static getVendorById(id,filter = {}, cb) {
        if(typeof filter === 'function') {
            cb = filter;
            filter = {};
        }
        EagleClient.request({
            endpoint: `/Vendors/${id}`,
            method: "GET",
            mode: "free",
            queryString: {filter: filter}
        }, cb);
    }

    static getRolesByVendorId(id,cb) {
        EagleClient.request({
            endpoint: `/Vendors/${id}/roles`,
            method: "GET",
        }, cb);
    }

    static AddUserToVendor(id,data,cb) {
        EagleClient.request({
            endpoint: `/Vendors/${id}/users`,
            method: "POST",
            body: data,
        }, cb);
    }

    static deleteUserFromVendor(vendorId,userId,cb) {
        EagleClient.request({
            endpoint: `/Vendors/${vendorId}/users`,
            method: "DELETE",
            body: userId,
        }, cb);
    }

    static getProducts = (vendorId, filter = {}, cb) => {
        EagleClient.request({
            endpoint: `/Vendors/${vendorId}/products`,
            method: "GET",
            queryString: filter,
        }, cb);
    };

    static getDefaultRoles = (cb) => {
        EagleClient.request({
            endpoint: `/Vendors/default-roles`,
            method: "GET"
        }, cb);
    };

    static updateRoles = (vendorId, roles, cb) => {
        EagleClient.request({
            endpoint: `/Vendors/${vendorId}/roles`,
            method: "PATCH",
            body: roles
        }, cb);
    };

    static couriers = (vendorId, cb) => {
        EagleClient.request({
            endpoint: `/Vendors/${vendorId}/couriers`,
            method: "GET"
        }, cb);
    };

    static updateProduct = (vendorId, productId, data, cb) => {
        EagleClient.request({
            endpoint: `/Vendors/${vendorId}/products/${productId}`,
            method: "PATCH",
            body: data
        }, cb);
    };

    static getInfoProduct = (vendorId, productId, cb) => {
        EagleClient.request({
            endpoint: `/Vendors/${vendorId}/products/${productId}`,
            method: "GET"
        }, cb);
    };

    static getGroupInProduct = (vendorId, productId, filter, cb) => {
        EagleClient.request({
            endpoint: `/Vendors/${vendorId}/products/${productId}/groups`,
            method: "GET",
            queryString: filter
        }, cb);
    };

    static getGroupNotInProduct = (vendorId, productId, filter, cb) => {
        EagleClient.request({
            endpoint: `/Vendors/${vendorId}/products/${productId}/groups-not-in`,
            method: "GET",
            queryString: filter
        }, cb);
    };

    static addGroupsToProduct = (vendorId, productId, groupIds, cb) => {
        EagleClient.request({
            endpoint: `/Vendors/${vendorId}/products/${productId}/groups`,
            method: "POST",
            body: {
                groupIds: groupIds
            }
        }, cb);
    };

    static removeGroupsFromProduct = (vendorId, productId, groupIds, cb) => {
        EagleClient.request({
            endpoint: `/Vendors/${vendorId}/products/${productId}/groups`,
            method: "DELETE",
            body: {
                groupIds: groupIds
            }
        }, cb);
    };
}
