import React, {Component} from 'react';
import { Select } from 'antd';
import { Input } from 'antd';
import { Row, Col, Button, Empty, DatePicker,Icon } from 'antd';
import * as _ from "lodash";
import {getParamsAsObject,getUrlQueryString} from "../../utils/helper";
import * as moment from "moment/moment";

const Option = Select.Option;
const Search = Input.Search;
const {RangePicker} = DatePicker;

class Filter extends Component {

    constructor(props) {
        super(props);
        this.mounted = false;
        this.state = {
            name: "",
            createdBy: [],
            createdAt: []
        };
    }

    componentDidMount = () => {
        this.mounted = true;
        window.addEventListener("popstate", this.onPopstate.bind(this), false);
        this.setStateFromQueryString();
    };

    componentWillUnmount = () => {
        this.mounted = false;
        window.removeEventListener("popstate", this.onPopstate.bind(this), false);
    };

    onPopstate = () => {
        if(this.mounted) {
            this.setStateFromQueryString();
        }
    };

    setStateFromQueryString = () => {
        let queryObject = getParamsAsObject(getUrlQueryString());
        this.resetStateFilter(() => {
            _.forEach(queryObject, (v, k) => {
                if(v) {
                    if(k === 'name') {
                        this.setState({name: v});
                    }
                    if(k === 'created_by') {
                        this.setState({createdBy: v.toString().split(',')});
                    }
                    if (k === 'created_at_from') {
                        let createdAt = this.state.createdAt;
                        createdAt[0] = moment(v);
                        this.setState({createdAt});
                    }
                    if (k === 'created_at_to') {
                        let createdAt = this.state.createdAt;
                        createdAt[1] = moment(v);
                        this.setState({createdAt});
                    }
                }
            });
        });
    };

    onChangeInput = (key, e) => {
        this.setState({
            [key]: e.target.value
        });
    };

    onFilter = () => {
        let uri = this.props.getUrlInstance();
        const {createdAt, createdBy, name} = this.state;

        if(name){
            uri.replaceParam('name', name);
        }else{
            uri.removeParam('name', name);
        }
        if(createdBy) uri.replaceParam('createdBy', createdBy.join(','));
        if(Array.isArray(createdAt)) {
            if(createdAt[0]) {
                uri.replaceParam('createdAtFrom', createdAt[0].format("YYYY-MM-DD 00:00:00"));
            }
            if(createdAt[1]) {
                uri.replaceParam('createdAtTo', createdAt[1].format("YYYY-MM-DD 23:59:59"));
            }
        }
        uri.replaceParam('page', 1);

        this.props.pushQueryString(uri.getQueryString());
    };

    onSelect = (key, value) => {
        this.setState({
            [key]: value
        });
    };

    resetStateFilter = (cb = null) => {
        this.setState({
            name: "",
            createdBy: "",
            createdAt: []
        }, cb)
    };

    clearFilter = () => {
        this.resetStateFilter(() => {
            this.props.pushQueryString("")
        });
    };

    onChangeRangePicker = (key, ranges) => {
        this.setState({
            [key]: ranges
        });
    };

    onChangeCreatedBy = (values) => {
        this.setState({createdBy: values});
    };

    render() {
        const {name,createdBy,createdAt} = this.state;
        const {users,loading} = this.props;
        const {t} = this.props;
        return (
            <div className="contact-filter filter">
                <Row gutter={20} className="form-filter">
                    <Col xl={6} lg={6} className="form-filter-control">
                        <label>{t('statistic.search_name')}</label>
                        <Input.Search
                            value={name}
                            onChange={this.onChangeInput.bind(this, 'name')}
                            placeholder={t("statistic.name")}
                            className={"_filter_name"}
                            onPressEnter={this.onFilter}
                        />
                    </Col>
                    <Col xl={6} lg={6} className="form-filter-control">
                        <label>{t('stock_issue.created_by')}</label>
                        <Select
                            mode="multiple"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            className={"_filter_created_by filter-input mg-r-5"}
                            placeholder={t("statistic.created_by")}
                            notFoundContent={<Empty description={t("table.empty")} />}
                            value={createdBy?createdBy:undefined}
                            style={{width: '100%'}}
                            onChange={this.onChangeCreatedBy}
                        >
                            {users.map((user) => (
                                <Select.Option className={`_option_created_by _option_created_by_${user.username}`} key={user.username}>{user.username}</Select.Option>
                            ))}
                        </Select>
                    </Col>
                    <Col xl={6} lg={6} className="form-filter-control">
                        <label>{t('stock_issue.created_at')}</label>
                        <RangePicker
                            placeholder={[t("contact.from"), t("contact.to")]}
                            className={"_filter_created_at"}
                            value={createdAt}
                            onChange={this.onChangeRangePicker.bind(this, 'createdAt')}
                            style={{width: '100%'}}
                        />
                    </Col>
                    <Col xl={6} lg={6} className="form-filter-control filter-button">
                        <label>&nbsp;</label>
                        <div>
                            <span className="mg-r-10 pointer _filter_button_clear" onClick={this.clearFilter}>
                                <Icon type="sync" /> {t("contact.clear")}
                            </span>
                            <Button type="primary" className="_filter_button_filter" onClick={this.onFilter}>
                                {t("button.filter")}
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export {Filter}