import React, {Component} from 'react';
import {Dropdown, Icon, Menu, Pagination, Table} from 'antd';
import UserService from "../../../services/UserService";
import {CAMPAIGNS_SCOPES} from "../../../utils/api/Scopes";
import * as moment from "moment/moment";

class List extends Component {

    constructor(props) {
        super(props);
        this.state = {
            sorter: {}
        };
    }

    componentDidMount = () => {

    };

    getConfigRowSelection = () => {
        return {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: (selectedRowKeys) => {
                this.props.getAdsetId(selectedRowKeys);
                this.setState({
                    selectedRowKeys
                });
            }
        }
    };

    componentDidUpdate(prevProps) {
        if(JSON.stringify(prevProps.countAdset) !== JSON.stringify(this.props.countAdset)){
            if(!this.props.countAdset.length){
                this.setState({
                    selectedRowKeys: [],
                })
            }
        }
    }

    showQuickEditFbAdsetId = (item) => {
        this.props.setAdsetItem(item, {
            fbAdsetIdQuickEdit: true
        });
    };

    onChange = (item, e) => {
        this.props.setAdsetItem(item, {
            [e.target.name]: e.target.value
        });
    };

    onKeyUp = (item, e) => {
        if(e.keyCode === 13) {
            this.props.onQuickEditFbAdsetId(item);
        }
    };

    onBlurFbAdsetId = (item) => {
        this.props.setAdsetItem(item, {
            fbAdsetIdQuickEdit: false,
        });
    };

    getConfigTableColumns = () => {
        const {t} = this.props;
        const renderColumn = (key, text, record) => {
            switch (key) {
                case 'createdBy':
                    return record.createdBy || "System";
                case 'name':
                    return (
                        <>
                            <span className="product-name">{record.name}</span>
                            <div><span className="code"><i>{record.code}</i></span></div>
                        </>
                    );
                case "createdAt":
                    return moment(record.createdAt).format("DD/MM/YYYY HH:mm:ss");

                case "campaign":
                    return <span className="campaign-name">{record.campaign.name}</span>;
                case 'action':
                    const menu = (
                        <Menu>
                            {UserService.isAllowed(CAMPAIGNS_SCOPES.CAMPAIGNS_UPDATE) && (
                                <Menu.Item key="1">
                                    <span onClick={this.props.showPopupUpdateAdset.bind(this,true,record)}><Icon type="edit" /> Edit</span>
                                </Menu.Item>
                            )}
                        </Menu>
                    );
                    return (
                        <Dropdown overlay={menu} trigger={['contextMenu', 'click']}>
                            <span className="pointer"><Icon type="dash" /></span>
                        </Dropdown>
                    )
            }
        };

        let columns = [
            {title: t("ads_group_list.name"),dataIndex: 'name', key: 'name',width:150,className:"_name _item",render: renderColumn.bind(this, 'name')},
            // {title: t("ads_group_list.code"),dataIndex: 'code', key: 'code',width:150,className:"_code _item",render: renderColumn.bind(this, 'code')},
            // {title: "Facebook Adset ID",dataIndex: 'fbAdsetId', key: 'fbAdsetId',width:150,className:"_fbAdsetId _item",render: renderColumn.bind(this, 'fbAdsetId')},
            {title: t("campaign.title"),dataIndex: 'campaign', key: 'campaign',width:150,className:"_campaign _item",render: renderColumn.bind(this, 'campaign')},
            {title: t("ads_group_list.count_contact"),dataIndex: 'countContact', key: 'countContact',width:120,className:"_countContact _item"},
            {title: t("campaign_list.count_ad"),dataIndex: 'countAd', key: 'countAd',width:120,className:"_countAd _item"},
            {title: t("table.created_at"),dataIndex: 'createdAt', key: 'createdAt',render: renderColumn.bind(this, 'createdAt'),width:120,className:"_status _item"},
            {title: t("table.created_by"),dataIndex: 'createdBy', key: 'createdBy',render: renderColumn.bind(this, 'createdBy'),width:120,className:"_status _item"},
            {title: t("table.action"),dataIndex: 'action', key: 'action',render: renderColumn.bind(this, 'action'),width:120,className:"_action _item"},
        ];

        return columns;
    };

    render() {
        const {t, loadingAdset} = this.props;
        const {dataSource,currentPage,totalItems,pageSize,onShowSizeChange,onChangePage} = this.props;
        let columns = this.getConfigTableColumns();
        const rowSelection = this.getConfigRowSelection();

        return (
            <div className="list-container _list-adset">
                <div className="a-table a-table--responsive">
                    <Table
                        columns={columns}
                        dataSource={dataSource}
                        rowKey={record => record.id}
                        pagination={false}
                        rowSelection={rowSelection}
                        loading={loadingAdset}
                        locale={{
                            emptyText: t("table.empty")
                        }}
                    />
                </div>

                {!loadingAdset && totalItems > 0 && (
                    <Pagination
                        className="mg-t-15 text-al-r"
                        showSizeChanger
                        onShowSizeChange={onShowSizeChange}
                        onChange={onChangePage}
                        pageSizeOptions={['25', '50', '100', '150', '200']}
                        defaultCurrent={1}
                        current={currentPage}
                        pageSize={pageSize}
                        total={totalItems}
                    />
                )}
            </div>
        );
    }
}

export {List}
