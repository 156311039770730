import React from 'react';
import {Row, Col} from 'antd';
import {formatDate, objectGet} from "../../../utils/helper";

class Info extends React.Component {

    render() {
        const {t, vendor} = this.props;
        const renderFormItem = (props) => {
            const {label, control, className} = props;
            return (
                <div className={`form-item ${className}`}>
                    <label>{label}</label>
                    <div>{control}</div>
                </div>
            )
        };
        return (
            <div className="info">
                <h1>{vendor.name}</h1>
                <Row gutter={20}>
                    <Col span={4}>
                        {renderFormItem({
                            label: t('vendor.code'),
                            control: vendor.code
                        })}
                    </Col>
                    <Col span={4}>
                        {renderFormItem({
                            label: t('vendor.manager'),
                            control: vendor.owner
                        })}
                    </Col>
                    <Col span={4}>
                        {renderFormItem({
                            label: t('vendor.created_by'),
                            control: vendor.createdBy
                        })}
                    </Col>
                    <Col span={4}>
                        {renderFormItem({
                            label: t('vendor.created_at'),
                            control: formatDate(vendor.createdAt, true)
                        })}
                    </Col>
                    <Col span={4}>
                        {renderFormItem({
                            label: t('vendor.country'),
                            control: objectGet(vendor, 'country.name')
                        })}
                    </Col>
                    <Col span={4}>
                        {renderFormItem({
                            label: t('vendor.source_package'),
                            control: objectGet(vendor, 'package.name')
                        })}
                    </Col>
                </Row>
            </div>
        )
    }
}

export {Info};