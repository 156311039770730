import React, {Component} from 'react';

import {Filter} from "./Filter";
import {LocalStore} from "../../../utils/LocalStore";
import {List} from "./List";
import AdService from "../../../services/AdService";
import * as _ from "lodash";
import {arrayConcat} from "../../../utils/helper";
import VendorService from "../../../services/VendorService";
import {notification} from "antd/lib/index";
import UserService from "../../../services/UserService";
import {CAMPAIGNS_SCOPES} from "../../../utils/api/Scopes";

class Ad extends Component {

    constructor(props) {
        super(props);
        this.state = {
            vendor: LocalStore.getInstance().read('vendor'),
            currentPage: 1,
            totalItems: 25,
            pageSize: 100,
            pageCount: 0,
            initialUsers: [],
            ads: [],
            users: [],
            loadingAd: true,
            loadingUser: true
        };
        this.filterAd = null ;
    }

    componentDidMount = () => {
        this.loadData();
    };

    resetDataSource = (key) => {
        this.setState({
            [key]: this.state[`initial${_.upperFirst(key)}`]
        });
    };

    loadData = () => {
        this.fetchAd({
            product_id:this.props.productId,
            project_id:this.props.projectId,
            campaign_id:this.props.campaignId,
            adset_id:this.props.adsetId
        });
        this.fetchUser({}, (error, response) => {
            if(!error) {
                this.setState({
                    initialUsers: response.list
                });
            }
        })
    };

    fetchUser = (filter = {}, cb) => {
        this.setState({loadingUser: true});
        const {vendor} = this.state;
        VendorService.getUserInVendor(vendor.id, filter, (error, response) => {
            if(!error) {
                this.setState({
                    loadingUser: false,
                    users: response.list,
                });
            }
            if(typeof cb === 'function') cb(error, response);
        });
    };

    fetchAd = (filter = {}, cb) => {
        this.setState({loadingAd: true});
        let {vendor} = this.state;
        let filterCond = {
            vendor_id: vendor.id,
            include: ['campaignAdSet', 'campaign', 'product'],
            order: ["createdAt DESC"],
            product_id: this.props.productId,
            project_id: this.props.projectId,
            campaign_id: this.props.campaignId,
            adset_id: this.props.adsetId,
        };
        filterCond = _.mergeWith(filterCond, filter, arrayConcat);
        AdService.getAds(filterCond, (error, response) => {
            if(!error) {
                this.setState({
                    ads: response.list,
                    loadingAd: false,
                    currentPage: response.metadata.currentPage,
                    totalItems: response.count,
                    pageSize: response.metadata.pageSize,
                    pageCount: response.metadata.pageCount
                });
            }
            if(typeof cb === 'function') cb(error, response);
        });
    };

    updateAdQuickly = (data, cb) => {
        AdService.updateAdQuickly(data, cb);
    };

    onShowSizeChange = (current, pageSize) => {
        this.fetchAd({
            page: 1,
            page_size: pageSize,
            product_id:this.props.productId,
            project_id:this.props.projectId,
            campaign_id:this.props.campaignId,
            adset_id:this.props.adsetId,
            keyword:this.filterAd.getFilterCon("keyword"),
            created_by:this.filterAd.getFilterCon("createdBy"),
            created_at_from:this.filterAd.getFilterCon("created_at_from"),
            created_at_to:this.filterAd.getFilterCon("created_at_to")
        });
    };

    onChangePage = (page, pageSize) => {
        this.fetchAd({
            page: page,
            page_size: pageSize,
            product_id:this.props.productId,
            project_id:this.props.projectId,
            campaign_id:this.props.campaignId,
            adset_id:this.props.adsetId,
            keyword:this.filterAd.getFilterCon("keyword"),
            created_by:this.filterAd.getFilterCon("createdBy"),
            created_at_from:this.filterAd.getFilterCon("created_at_from"),
            created_at_to:this.filterAd.getFilterCon("created_at_to")
        });
    };

    onFilter = (filter = {}, cb) => {
        this.fetchAd(filter, cb);
    };

    componentDidUpdate(prevProps) {
        if(JSON.stringify(prevProps.productId) !== JSON.stringify(this.props.productId) ||
            JSON.stringify(prevProps.projectId) !== JSON.stringify(this.props.projectId) ||
            JSON.stringify(prevProps.campaignId) !== JSON.stringify(this.props.campaignId) ||
            JSON.stringify(prevProps.adsetId) !== JSON.stringify(this.props.adsetId)){
            this.fetchAd({
                product_id:this.props.productId,
                project_id:this.props.projectId,
                campaign_id:this.props.campaignId,
                adset_id:this.props.adsetId,
                keyword:this.filterAd.getFilterCon("keyword"),
                created_by:this.filterAd.getFilterCon("createdBy"),
                created_at_from:this.filterAd.getFilterCon("created_at_from"),
                created_at_to:this.filterAd.getFilterCon("created_at_to")
            })
        }
    }

    setRefFilter = (ref) => {
        this.filterAd = ref;
    };

    setAdItem = (item, attributes) => {
        _.forEach(attributes, (v, k) => {
            item[k] = v;
        });

        let items = this.state.ads;

        for(let i = 0; i < items.length;  i++) {
            let _item = items[i];
            if(_item.id === item.id) {
                _item = item;
                break;
            }
        }

        this.setState({
            ads: items
        });
    };

    setAllAdItem = (attributes, where = null) => {
        let items = this.state.ads;

        for(let i = 0; i < items.length;  i++) {
            let _item = items[i];
            if(where) {
                _.forEach(where, (v, k) => {
                    if(_item[k] === v) {
                        _.forEach(attributes, (v, k) => {
                            _item[k] = v;
                        });
                    }
                });
            } else {
                _.forEach(attributes, (v, k) => {
                    _item[k] = v;
                });
            }
        }

        this.setState({
            ads: items
        });
    };

    toggleDefault = (item) => {
        const {t} = this.props;
        AdService.toggleDefault(item.id, (error, response) => {
            if(!error){
                notification['success']({
                    message: t("message.success"),
                });
            }else{
                notification['error']({
                    message: error.message,
                });
            }
        });
    };

    toggleEditAbleFbAdId = (item) => {
        if(UserService.isAllowed(CAMPAIGNS_SCOPES.CAMPAIGNS_UPDATE)) {
            if (!('editableFbAdId' in item)) {
                item.editableFbAdId = false;
            }
            item.editableFbAdId = !item.editableFbAdId;
            let {ads} = this.state;
            for (let ad of ads) {
                if (ad.id === item.id) {
                    ad = item;
                }
            }
            this.setState({ads});
        }
    };

    render() {
        const {vendor, totalItems, currentPage, pageSize,pageCount, users, ads, loadingAd} = this.state;

        return (
            <div className="product-container _ad-container">
                <Filter
                    {...this.props}
                    users={users}
                    resetDataSource={this.resetDataSource}
                    onFilter={this.onFilter}
                    fetchUser={this.fetchUser}
                    currentPage={currentPage}
                    pageSize={pageSize}
                    pageCount={pageCount}
                    totalItems={totalItems}
                    loading={loadingAd}
                    productId={this.props.productId}
                    projectId={this.props.projectId}
                    campaignId={this.props.campaignId}
                    adsetId={this.props.adsetId}
                    ref={this.setRefFilter}
                />
                <List
                    {...this.props}
                    dataSource={ads}
                    currentPage={currentPage}
                    pageSize={pageSize}
                    pageCount={pageCount}
                    totalItems={totalItems}
                    vendor={vendor}
                    onChangePage={this.onChangePage}
                    onShowSizeChange={this.onShowSizeChange}
                    loadingAd={loadingAd}
                    onToggleProductStatus={this.onToggleProductStatus}
                    setAdItem={this.setAdItem}
                    setAllAdItem={this.setAllAdItem}
                    toggleDefault={this.toggleDefault}
                    getAdId={this.props.getAdId}
                    countAd={this.props.countAd}
                    toggleEditAbleFbAdId={this.toggleEditAbleFbAdId}
                    updateAdQuickly={this.updateAdQuickly}
                    fetchAd={this.fetchAd}
                />
            </div>
        );
    }
}

export {Ad}