import EagleClient from "../utils/api/EagleClient";

export default class StockReceiptService{


    /**
     * Tạo phiếu nhập
     * @param warehousesCode
     * @param data
     * @param cb
     */
    static createStockReceipt(warehousesCode, cb) {
        EagleClient.request({
            endpoint: `/Warehouses/${warehousesCode}/receipts`,
            method: 'POST'
        }, cb);
    }
    /**
     * Lấy dữ liệu chi tiết phiếu nhập
     * @param receiptId
     * @param cb
     */
    static getStockReceiptDetail(receiptId, cb) {
        EagleClient.request({
            endpoint: `/StockReceipts/${receiptId}`,
            method: 'GET'
        }, cb);
    }
    /**
     * Update loại nhập
     * @param receiptType
     * @param receiptId
     * @param cb
     */
    static updateTypeStockReceipt(receiptId, receiptType,cb) {
        EagleClient.request({
            endpoint: `/StockReceipts/${receiptId}/receiptType?receiptType=${receiptType}`,
            method: 'PUT'
        }, cb);
    }
    /**
     * Thay đổi trạng thái phiếu nhập sang process
     * @param receiptId
     * @param cb
     */
    static changeToProcess(receiptId, cb) {
        EagleClient.request({
            endpoint: `/v2/receipts/${receiptId}/process`,
            method: 'PUT'
        }, cb);
    }

    /**
     * Thay đổi trạng thái phiếu nhập sang done/finish
     * @param receiptId
     * @param cb
     */
    static changeToFinish(receiptId, cb) {
        EagleClient.request({
            endpoint: `/v2/receipts/${receiptId}/finish`,
            method: 'PUT'
        }, cb);
    }

    /**
     * Thêm/ sửa/ xóa product của phiếu nhập
     * @param receiptId
     * @param data
     * @param cb
     */
    static updateProduct(receiptId, data, cb) {
        EagleClient.request({
            endpoint: `/StockReceipts/${receiptId}/products`,
            method: 'POST',
            body:data
        }, cb);
    }

    static updateStatus(receiptId,status,cb) {
        EagleClient.request({
            endpoint: `/StockReceipts/${receiptId}/${status}`,
            method: 'PUT'
        }, cb);
    }
}