import React, {Component} from 'react';
import {translate} from 'react-i18next';
import * as _ from 'lodash';
import {Form as AntForm} from 'antd';
import StandardLayout from "../../../components/StandardLayout";
import {Header} from "./Header";
import {Form as FormAdd} from "./Form";
import {LocalStore} from "../../../utils/LocalStore";
import {makeVariants} from "../../../utils/helper";
import ProductService from "../../../services/ProductService";
import {notification} from "antd/lib/index";

class OriginalProductUpdate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: "",
            code: "",
            manufactory: "",
            description: "",
            defaultPrice: "",
            active: false,
            oldAttrItems: [],
            attrItems: [
                {key: "", attr: []},
                {key: "", attr: []},
                {key: "", attr: []}
            ],
            oldSkuItems: [],
            skuItems: [
                // {display: "", sku: ""},
                // {display: "", sku: ""},
                // {display: "", sku: ""}
            ],
            isLoading: false,
            vendorsSelected: [],
            vendor: LocalStore.getInstance().read("vendor"),
            loading: true,
            validateAttr: {},
            productGroup: [],
            loadingProductGroup: false,
            groupCode:""
        };
    }

    componentWillMount = () => {

    };

    componentDidMount = () => {
        this.fetchProduct(this.getId());
        this.getProductGroup();
    };

    getId = () => {
        return this.props.match.params.id;
    };

    fetchProduct = (id, cb) => {
        this.setState({loading: true});
        ProductService.getProductById(id, (error, response) => {
            this.setState({loading: false});
            if(!error) {
                let productAttr = response.productAttr;
                let productSku = response.productSku;
                let skuItems = [];
                let attrItems = [];
                let oldAttrItems = [];
                for(let i in productAttr) {
                    let attrItem = productAttr[i];
                    attrItems[i] = {
                        id: attrItem.id,
                        key: attrItem.name,
                        old: true,
                        attr: []
                    };
                    for(let p of attrItem.productProperties) {
                        attrItems[i].attr.push(p.propertyValue);
                    }
                }
                oldAttrItems = _.cloneDeep(attrItems);
                for(let i = attrItems.length; i < 3; i ++) {
                    attrItems.push({
                        key: "",
                        attr: []
                    });
                }

                for(let i in productSku) {
                    let skuItem = productSku[i];
                    skuItems[i] = {
                        m28Sku: skuItem.m28Sku,
                        display: skuItem.display,
                        sku: skuItem.sku,
                        checked: true,
                        old: true,
                        id: skuItem.id,
                        attr: skuItem.attr,
                        index: i,
                        uid: _.uniqueId()
                    };
                }

                this.setState({
                    name: response.name,
                    code: response.code,
                    defaultPrice: response.defaultPrice,
                    manufactory: response.manufactory,
                    active: response.active,
                    description: response.description,
                    attrItems: attrItems,
                    skuItems: skuItems,
                    oldAttrItems: oldAttrItems,
                    oldSkuItems: _.cloneDeep(skuItems),
                    vendorsSelected: response.vendors,
                    groupCode: response.groupCode ? response.groupCode : ''
                }, () => {
                    this.generateSku(() => {
                        let {skuItems} = this.state;
                        _.forEach(skuItems, (item) => {
                            if(!this.isSkuExisted(item.display)) {
                                item.checked = false;
                            }
                        });
                        this.setState({skuItems});
                    });
                });
            } else {
                if(error.statusCode === 404) {
                    notification.error({
                        message: "404 not found"
                    });

                    this.props.history.push('/404');
                } else if(error.statusCode === 403) {
                    notification.error({
                        message: "403 Access denied"
                    });

                    this.props.history.push('/403');
                }
            }
            if(typeof cb === 'function') cb(error, response);
        });
    };

    onChangeAttrKey = (index, e) => {
        let key = e.target.value;
        let attrItems = this.state.attrItems;
        attrItems[index].key = key;
        this.setState({attrItems});
    };

    onChangeAttrValue = (index, values) => {
        if(values.length <= 10) {
            let attrItems = this.state.attrItems;
            let oldValues = this.getOldAttrValueByKey(attrItems[index].key);
            attrItems[index].attr = _.union(oldValues, values);
            this.setState({attrItems}, () => {
                if(oldValues.length-1 !== values.length) {
                    this.generateSku();
                }
            });
        }
    };

    generateSku = (cb) => {
        let {attrItems, oldSkuItems} = this.state;
        let items = _.map(attrItems, 'attr');
        let arrayComb = makeVariants(items);
        let skuItems = _.cloneDeep(oldSkuItems);
        _.forEach(arrayComb, (arr, k) => {
            let display = arr.join('/');
            let skuItem = {
                display: display,
                sku: "SKU",
                attr: {},
                checked: true,
                index: k,
                uid: _.uniqueId()
            };
            for(let i in arr) {
                skuItem.attr[attrItems[i].key] = arr[i];
            }
            if(!this.isSkuExisted(skuItem.display)) {
                skuItems.push(skuItem);
            }
        });
        if(typeof cb === 'function') {
            this.setState({skuItems}, cb);
        } else {
            this.setState({skuItems});
        }
    };

    getSkuOldList = () => {
        let {oldSkuItems} = this.state;
        return _.map(oldSkuItems, 'display');
    };

    getAttrValueByKey = (key) => {
        let {attrItems} = this.state;
        for(let item of attrItems) {
            if(item.key == key) {
                return item.attr;
            }
        }
        return [];
    };

    getOldAttrValueByKey = (key) => {
        let {oldAttrItems} = this.state;
        for(let item of oldAttrItems) {
            if(item.key == key) {
                return item.attr;
            }
        }
        return [];
    };

    getSkuItemByUid = (uid) => {
        let {skuItems} = this.state;
        for(let item of skuItems) {
            if(item.uid == uid) {
                return item;
            }
        }
    };

    isAttrKeyExisted = (key) => {
        let {oldAttrItems} = this.state;
        let list = [];
        for(let item of oldAttrItems) {
            list.push(item.key);
        }
        return list.indexOf(key) >= 0;
    };

    isSkuExisted = (displayName) => {
        let oldList = this.getSkuOldList();
        return oldList.indexOf(displayName) >= 0;
    };

    onCheckSkuItem = (uid, checked) => {
        let {skuItems} = this.state;
        let skuItem = this.getSkuItemByUid(uid);
        if(skuItem && !this.isSkuExisted(skuItem.display)) {
            skuItem.checked = checked;
            this.setState({skuItems});
        }
    };

    onCheckAllSkuItem = () => {
        let {skuItems} = this.state;
        let isCheckAll = _.countBy(skuItems, 'checked')['true'] === skuItems.length;
        for(let item of skuItems) {
            if(!this.isSkuExisted(item.display)) {
                item.checked = !isCheckAll;
            }
        }
        this.setState({skuItems});
    };

    onSubmit = (e) => {
        this.setState({isLoading: true});
        this.props.form.validateFields((err, values) => {
            let id = this.props.match.params.id;
            const {t} = this.props;
            let {skuItems, attrItems, vendorsSelected} = this.state;
            values.vendorIds = [];
            if (!err) {
                values.productAttr = _.filter(attrItems, (item) => { return item.attr.length > 0 });
                values.productSku = _.filter(skuItems, (item) => { return item.checked });
                values.vendorIds = _.map(vendorsSelected, 'id');

                ProductService.updateProductDetail(id, values, (err, repsonse) => {
                    this.setState({isLoading: false});
                    if (err === null) {
                        notification.success({
                            message: t("message.success"),
                        });
                        this.props.history.push('/products');
                    } else {
                        if(err.code === 'PRODUCT_EXISTS') {
                            notification.error({
                                message: t("add_product.exist_product"),
                            });
                        }
                        else if (err.code === 'PACKAGE_VALIDATE') {
                            notification.error({
                                message: t("message.upgrade_premium"),
                            });
                        }
                        else {
                            notification.error({
                                message: err.message
                            });
                        }
                    }

                    this.setState({isLoading: false});
                });
            } else {
                this.setState({isLoading: false});
            }
        });
    };

    onResetForm = () => {
        let productId = this.props.match.params.id;
        this.fetchProduct(productId);
    };

    handleVendorSelected = (items) => {
        this.setState({
            vendorsSelected: items
        })
    };

    getProductGroup = () => {
        this.setState({
            loadingProductGroup: true
        });

        ProductService.getProductGroup((error, response) => {
            this.setState({
                loadingProductGroup: false
            });

            if(!error){
                this.setState({
                    productGroup: response
                })
            }
        })
    } 

    render() {
        const {t} = this.props;
        const {name, loading, productGroup, loadingProductGroup} = this.state;
        return (
            <StandardLayout title={t('products.product')+` ${name}`} {...this.props} loading={loading}>
                <div className="product-add-page">
                    <AntForm>
                        <Header
                            {...this.props}
                            {...this.state}
                            onResetForm={this.onResetForm}
                            onSubmit={this.onSubmit}
                        />
                        <FormAdd
                            {...this.props}
                            {...this.state}
                            onChangeAttrKey={this.onChangeAttrKey}
                            onChangeAttrValue={this.onChangeAttrValue}
                            onCheckSkuItem={this.onCheckSkuItem}
                            onCheckAllSkuItem={this.onCheckAllSkuItem}
                            onChangeVendors={this.handleVendorSelected}
                            productId={this.getId()}
                            productGroup={productGroup}
                            loadingProductGroup={loadingProductGroup}
                        />
                    </AntForm>
                </div>
            </StandardLayout>
        )
    }
}

OriginalProductUpdate = AntForm.create()(OriginalProductUpdate);
export default (translate())(OriginalProductUpdate);