import React from 'react';
import {Card, Form, Icon, Popover} from 'antd';
import {objectGet} from "../../../utils/helper";
import UserService from "../../../services/UserService";
import {ORDER_SCOPES} from "../../../utils/api/Scopes";
import {AssignOrder} from "../../../components/AssignOrder";

class WarehouseNote extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isShowPopupAssignTo: false
        }
    }

    onAssignOrder = () => {
        this.setState({isShowPopupAssignTo: false});
        this.props.fetchOrder();
    };

    onPopoverAssignTo = (visible) => {
        if(visible) {
            if (!UserService.isAllowed(ORDER_SCOPES.ORDER_ASSIGN)) {
                this.setState({isShowPopupAssignTo: false});
            } else {
                this.setState({isShowPopupAssignTo: true});
            }
        } else {
            this.setState({isShowPopupAssignTo: false});
        }
    };

    render() {
        const {t, order} = this.props;
        const {isShowPopupAssignTo} = this.state;
        let assignTo = objectGet(order, 'assignTo');
        if(!assignTo) assignTo = t('contact.unassign_to');
        let contentAssignTo = (
            <AssignOrder
                {...this.props}
                onAssignOrder={this.onAssignOrder}
            />
        );
        return (
            <Card
                className={'card-block warehouse-note'}
                title={t('order_list.warehouse_note')}
            >
                <span className="label">{t('contact-detail.assigned_to')}:</span>
                <Popover
                    content={contentAssignTo}
                    trigger="click"
                    placement="right"
                    visible={isShowPopupAssignTo}
                    onVisibleChange={this.onPopoverAssignTo}
                >
                    <span className={`_button_click_show_list_user_assign ${UserService.isAllowed(ORDER_SCOPES.ORDER_ASSIGN) ? 'pointer' : 'not-allowed'}`}>
                        <span className="text-blue form-item-text">
                            {assignTo}
                            &nbsp;<Icon type="down" style={{fontSize: 10}} />
                        </span>
                    </span>
                </Popover>
            </Card>
        )
    }
}

WarehouseNote = Form.create()(WarehouseNote);

export {WarehouseNote};