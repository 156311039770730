import React, {Component} from 'react';
import {Button, Card, Col, Tag, Timeline} from 'antd';
import {formatDate2, objectGet} from "../../../utils/helper";
import UserService from "../../../services/UserService";
import {CONTACT_SCOPES} from "../../../utils/api/Scopes";

class SummaryEvent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            callHistories: [],
            ads: []
        }
    }

    componentDidMount = () => {
        this.setState({loading: true});
        this.props.fetchCallHistories((error, response) => {
            this.setState({loading: false});
            if(!error) {
                this.setState({
                    callHistories: response
                })
            }
        });
        this.props.fetchAds((error, response) => {
            this.setState({loading: false});
            if(!error) {
                this.setState({
                    ads: response
                })
            }
        });
    };

    componentDidUpdate = (prevProps) => {
        if(prevProps.isCallHistories !== this.props.isCallHistories && this.props.isCallHistories === true){
            this.props.fetchCallHistories((error, response) => {
                this.setState({loading: false});
                if(!error) {
                    this.setState({
                        callHistories: response
                    })
                }
            });
        }
    };

    addEvent = () => {
        this.props.showPopupAddEvent(true);
    };

    getAdById = (item) => {
        const {t} = this.props;
        for (let i = 0; i < this.state.ads.length; i++) {
            if(this.state.ads[i].id === item.adsId && [1,2,3,8].indexOf(item.callStatusId) >= 0){
                return `${t('order_detail.ads')} : ${this.state.ads[i].name}`;
            }
        }
        return null
    };

    render() {
        const {loading, callHistories} = this.state;
        const {t} = this.props;

        let buttonAddEvent = null;
        if(UserService.isAllowed(CONTACT_SCOPES.CONTACT_ADD_EVENT)
            && Object.keys(this.props.contact).length
            && ["L1.3","L2.3"].indexOf(this.props.contact.contactStatus ? this.props.contact.contactStatus.key : -1) === -1
        ) {
            buttonAddEvent = <Button onClick={this.addEvent} icon="plus" className="_btn_add_event">{t('contact-detail.add_event')}</Button>
        }

        return (
            <Card
                title={t('contact-detail.activity')}
                extra={buttonAddEvent}
            >
                <Timeline pending={loading}>
                    {callHistories.map((item, index) => (
                        <div key={index}>
                            <Col span={5}>
                                <div style={{color: "rgba(0, 0, 0, 0.85)"}}>{formatDate2(item.callTime, true)}</div>
                            </Col>
                            <Col span={19}>
                                <Timeline.Item>
                                    <>
                                        <Tag color={objectGet(item, 'callStatus.color')}>{objectGet(item, 'callStatus.value')}</Tag>
                                        <div style={{color: "rgba(0, 0, 0, 0.85)", marginTop: 10}}>{this.getAdById(item)}</div>
                                        <div style={{color: "#808080"}}>{item.note}</div>
                                    </>
                                </Timeline.Item>
                            </Col>
                        </div>
                    ))}
                </Timeline>
            </Card>
        )
    }
}

export {SummaryEvent};