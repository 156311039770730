import React, {Component} from 'react';
import StandardLayout from "../../components/StandardLayout";
import {translate} from 'react-i18next';

class UserNotInAnyVendor extends Component {


    constructor(props) {
        super(props);
        this.state = {
            language : 'EN',
            langList : [{key : 'en', name : 'English'}, {key : 'vi', name : 'Vietnamese'}],
        };
    }

    render() {
        const {t} = this.props;

        return (
            <StandardLayout isShowSideBarItems={false} {...this.props}>
                <div style={{textAlign:"center", marginTop:50, fontSize:20, color:"red"}}>{t("vendor.not_in")}</div>
            </StandardLayout>
        );
    }
}

export default (translate())(UserNotInAnyVendor);
