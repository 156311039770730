import React from 'react';
import {Input, Form, Icon, notification, Card} from "antd";
import lodash from 'lodash';
import {isUrl} from "../../../../utils/helper";

class LandingUrls extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            urls: [],
            urlInputValue: ""
        }
    }

    static getDerivedStateFromProps(props, state) {
        let newState = {...state};
        if ('urls' in props) {
            newState.urls = props.urls;
        }

        return newState;
    }

    handleEnterUrl = (e) => {
        const {t} = this.props;
        let url = e.target.value;
        url = url.replace(/\/$/g, '');
        if (isUrl(url)) {
            let {urls} = this.state;
            urls.push(url);
            urls = lodash.uniq(urls);
            this.setState({
                urls,
                urlInputValue: ""
            });

            this.props.onChange(urls);
        } else {
            notification.error({
                message: t('ads.url_invalid')
            })
        }
    };

    handleRemoveUrl = (url) => {
        let {urls} = this.state;
        urls = urls.filter(u => u !== url);
        urls = lodash.uniq(urls);
        this.setState({urls});

        this.props.onChange(urls);
    };

    handleChangeInputUrl = (e) => {
        this.setState({
            urlInputValue: e.target.value
        });
    };

    render() {
        const {urls, urlInputValue} = this.state;
        const {t} = this.props;
        return (
            <Card title={t('ads.landing_url')}>
                <Form.Item
                    label={<span className="bold-400">{t('ads.landing_url')}</span>}
                >
                    {urls.map(url => (
                        <React.Fragment key={url}>
                            <Input readOnly={true} value={url} className={'url-item'}/>
                            &nbsp;<Icon onClick={this.handleRemoveUrl.bind(this, url)} className={'icon-remove-url'} type={'close'} />
                        </React.Fragment>
                    ))}
                    <Input
                        placeholder={"Input url then press enter"}
                        value={urlInputValue}
                        onChange={this.handleChangeInputUrl}
                        onPressEnter={this.handleEnterUrl}
                    />
                </Form.Item>
            </Card>
        );
    }
}

LandingUrls.defaultProps = {
    ads: {},
    onChange: (urls) => {}
};

export default LandingUrls;