import React from 'react';
import { Button, Input, Icon, Empty, Table, Spin, Pagination, Popover, Modal } from 'antd';
import * as moment from 'moment';
import {GROUPS_SCOPES, WAREHOUSE_SCOPES} from "../../../../utils/api/Scopes";
import UserService from "../../../../services/UserService";
import WareHouseService from "../../../../services/WareHouseService";
import * as _ from "lodash";
import {notification} from "antd/lib/index";
import {doCallBack} from "../../../../utils/helper";

class ListGroup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            currentPage: 1,
            totalItems: 0,
            pageSize: 25,
            loading: false,
            pageCount: 0,
            listGroupNotInWarehouse: [],
            listGroupNotInWarehouseDefault: [],
            isShowPopOver: false,
            isShowModalConfirm: false,
            idGroupDeleted: ''
        };
    }

    componentDidMount = () => {
        this.getGroupByWarehouse();
        this.getGroupNotInWarehouse({}, (err,res) => {
            this.setState({
                listGroupNotInWarehouseDefault: res.list,
            })
        });
    };

    onShowSizeChange = (current, pageSize) => {
        let filter = {
            page: 1,
            page_size: pageSize
        };
        this.getGroupByWarehouse(filter);
    };

    onChangePage = (page, pageSize) => {
        let filter = {
            page: page,
            page_size: pageSize
        };
        this.getGroupByWarehouse(filter)
    };

    getGroupByWarehouse = (filter = {},cb) => {
        let code = this.props.match.params.code;
        let defaultFilter = {
            page: filter.page || 1,
            page_size: filter.page_size || 25
        };

        if(Object.keys(filter).length) {
            defaultFilter = Object.assign(defaultFilter, filter);
        }
        WareHouseService.getGroups(code,defaultFilter,(error, response) => {
            if(!error){
                this.setState({
                    dataSource: response.list,
                    currentPage: response.metadata.currentPage,
                    totalItems: response.count,
                    pageSize: response.metadata.pageSize,
                    pageCount: response.metadata.pageCount,
                    loading: false
                });
            }
            doCallBack(cb, [error, response]);
        });
    };

    getGroupNotInWarehouse = (filter = {},cb) => {
        let code = this.props.match.params.code;
        WareHouseService.getGroupsNotIn(code,filter,(error, response) => {
            if(!error){
                this.setState({
                    listGroupNotInWarehouse: response.list,
                });
            }
            if(typeof cb === 'function') cb(error, response);
        });
    };

    deleteGroup = (id) => {
        this.setState({
            isShowModalConfirm: true,
            idGroupDeleted: id
        });
    };

    getConfigTableColumns = () => {
        const {t} = this.props;
        const renderColumn = (key, text, record) => {
            switch (key) {
                case 'createdAt':
                    return record.created_at ? moment(record.created_at).format("DD/MM/YY HH:mm") : "";
                case 'action':
                    return UserService.isAllowed(GROUPS_SCOPES.GROUPS_DELETE) &&
                        (<Icon onClick={this.deleteGroup.bind(this,record.id)} type="delete" theme="twoTone" />);

            }
        };
        return [
            {title: t("products.group_id"),dataIndex: 'id', key: 'id',className:"id _item"},
            {title: t("products.group_name"),dataIndex: 'name', key: 'name',className:"name _item"},
            {title: t("products.added_by"),dataIndex: 'created_by', key: 'created_by',className:"created_by _item"},
            {title: t("products.added_at"),dataIndex: 'created_at', key: 'created_at',render: renderColumn.bind(this, 'createdAt'),className:"created_at _item"},
            {title: "",dataIndex: 'action', key: 'action',render: renderColumn.bind(this, 'action'),className:"_action _item"},
        ];
    };

    setValue = (e) => {
        this.setState({
            groupSelected: e.target.value
        })
    };

    filterGroup = (e) => {
        let value = e.target.value.toLowerCase();
        this.getGroupNotInWarehouse({name:value})
    };

    onSelectGroup = (item) => {
        this.setState({
            isShowPopOver: false,
            loading: true
        });
        const {t} = this.props;
        let code = this.props.match.params.code;
        let groupIds = {groupIds:[item.id]};
        WareHouseService.addGroups(code,groupIds,(error, response) => {
            if(!error){
                notification.success({
                    message: t('message.success')
                });
                this.getGroupByWarehouse();
                this.getGroupNotInWarehouse({}, (err,res) => {
                    this.setState({
                        listGroupNotInWarehouseDefault: res.list,
                    })
                });
            }else{
                notification.error({
                    message: error.message
                });
            }
        });
    };

    renderDropDownVendor = () => {
        const {t} = this.props;
        const content = (
            <div className="vendor-select group-select">
                <Input
                    autoFocus={true}
                    onChange={this.setValue}
                    value={this.state.groupSelected}
                    onKeyUp={this.filterGroup}
                />
                <ul>
                    {this.state.listGroupNotInWarehouse.map((item) => (
                        <li onClick={this.onSelectGroup.bind(this,item)} key={item.id}>{item.name}</li>
                    ))}
                </ul>


            </div>
        );
        return content;
    };

    handleClickChange = () => {
        this.setState({
            isShowPopOver: !this.state.isShowPopOver,
            listGroupNotInWarehouse: this.state.listGroupNotInWarehouseDefault,
            groupSelected: ""
        })
    };

    handleCancel = () => {
        this.setState({
            isShowModalConfirm: false
        })
    };

    handleOk = () => {
        this.setState({
            isShowModalConfirm: false,
            loading: true
        });
        const {t} = this.props;
        let code = this.props.match.params.code;
        let groupIds = {groupIds:[this.state.idGroupDeleted]};
        WareHouseService.removeGroups(code,groupIds,(error, response) => {
            if(!error){
                notification.success({
                    message: t('message.success')
                });
                this.getGroupByWarehouse();
                this.getGroupNotInWarehouse({}, (err,res) => {
                    this.setState({
                        listGroupNotInWarehouseDefault: res.list,
                    })
                });
            }else{
                notification.error({
                    message: error.message
                });
            }
        });
    };

    render() {
        const {t} = this.props;
        const {dataSource,currentPage,totalItems,pageSize,pageCount,loading} = this.state;
        let columns = this.getConfigTableColumns();
        return (
            <>
                <div style={{overflow:"hidden", marginBottom:15}}>
                    <>
                        <div style={{float:"left",fontWeight:"bold",color:"black"}}>List Group - {totalItems}</div>
                        {UserService.isAllowed(WAREHOUSE_SCOPES.WAREHOUSE_UPDATE) && !!this.state.listGroupNotInWarehouseDefault.length &&(
                            <Popover
                                content={this.renderDropDownVendor()}
                                trigger="click"
                                className="pointer"
                                onVisibleChange={this.handleClickChange}
                                overlayClassName="group_popover _group_popover"
                                placement="bottom"
                                visible={this.state.isShowPopOver}
                            >
                                <Button loading={this.state.loading} style={{float:"right"}} type="primary">{t("products.add_group")}</Button>
                            </Popover>
                        )}
                    </>
                </div>

                <Table
                    columns={columns}
                    dataSource={dataSource}
                    rowKey={record => record.id}
                    pagination={false}
                    loading={loading}
                    onChange={this.onChange}
                    locale={{
                        emptyText: t("table.empty")
                    }}
                />
                <Pagination
                    className="mg-t-15 text-al-r"
                    showSizeChanger
                    onShowSizeChange={this.onShowSizeChange}
                    onChange={this.onChangePage}
                    pageSizeOptions={['25', '50', '75', '100']}
                    defaultCurrent={1}
                    current={currentPage}
                    pageSize={pageSize}
                    total={totalItems}
                />

                <Modal
                    visible={this.state.isShowModalConfirm}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    okText={t("popup-confirm.confirm")}
                    cancelText={t("popup-confirm.cancel")}
                >
                    <p>{t("contact-detail.confirm_change_message")}</p>
                </Modal>
            </>

        )
    }
}

export {ListGroup}