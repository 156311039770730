import React, {Component} from 'react';
import {Route, Switch} from "react-router";
import {HashRouter} from "react-router-dom";
import RequireLogin from "./components/RequireLogin";
import Home from "./pages/Home";
import Authentication from './pages/Authentication';
import Login from './pages/Login';
import UserNotInAnyVendor from './pages/UserNotInAnyVendor';
import NotFound from "./pages/404/404";
import AccessDenied from "./pages/403/403";
import Contact from "./pages/Contact";
import ContactDetail from './pages/Contact/Detail';
import Vendor from "./pages/Vendor";
import VendorAdd from "./pages/Vendor/Add";
import VendorDetail from './pages/Vendor/Detail';
import Product from "./pages/Product";


import AdAdd from './pages/Product/Ad/Add';
import AdUpdate from './pages/Product/Ad/Update';
import CampaignAdd from './pages/Product/Campaign/Add';
import CampaignUpdate from './pages/Product/Campaign/Update';
import Order from "./pages/Order";
import OrderDetail from "./pages/Order/Detail";
import GroupUser from "./pages/GroupUser";
import GroupUserDetail from "./pages/GroupUser/Detail";
import Warehouse from "./pages/Warehouse";
import WarehouseDetail from "./pages/Warehouse/Detail";
import WarehouseStock from "./pages/Warehouse/Detail/Stock";
import WarehouseIssue from "./pages/Warehouse/Detail/Issue";
import WarehouseReceipt from "./pages/Warehouse/Detail/Receipt";
import WarehousePermission from "./pages/Warehouse/Detail/Permission";
import IssueDetail from "./pages/Warehouse/Detail/Issue/Detail";
import IssueAddOrder from "./pages/Warehouse/Detail/Issue/AddOrder";
import ReceiptDetail from "./pages/Warehouse/Detail/Receipt/Detail";
import StatisticDetail from "./pages/Statistics/Detail";
import Statistic from "./pages/Statistics";
import StatisticAdd from "./pages/Statistics/Add";
import StatisticUpdate from "./pages/Statistics/Update";
import Setting from "./pages/Setting";

import OriginalProduct from './pages/OriginalProduct/List';
import OriginalProductAdd from './pages/OriginalProduct/Add';
import OriginalProductUpdate from './pages/OriginalProduct/Update';
import ProductUpdate from './pages/Product/Product/Update';
import Telesale from './pages/Telesale';
import AllOrder from "./pages/AllOrder";
import AllContact from "./pages/AllContact";
import ReturnLead from "./pages/ReturnLead";
import withTracker from "./components/WithGaTracker";
import DeliveryCase from './pages/DeliveryCase';
import Stringee from './pages/Stringee';
import StringeeAdd from './pages/Stringee/Add';

export default class Routes extends Component {
    render() {
        return (
            <HashRouter>
                <Switch>
                    <RequireLogin exact path="/" component={withTracker(Home)} />
                    <Route path="/login" component={withTracker(Login)}/>
                    <Route path="/authentication" component={withTracker(Authentication)} />
                    <RequireLogin path="/user-not-in-any-vendor" component={withTracker(UserNotInAnyVendor)} />
                    <RequireLogin path="/contacts/:id" component={withTracker(ContactDetail)}/>
                    <RequireLogin path="/contacts" component={withTracker(Contact)}/>
                    <RequireLogin path="/all-contacts" component={withTracker(AllContact)}/>
                    <RequireLogin path="/telesale" component={withTracker(Telesale)}/>
                    <RequireLogin path="/vendors/add" component={withTracker(VendorAdd)}/>
                    <RequireLogin path="/vendors/:id" exact component={withTracker(VendorDetail)}/>
                    <RequireLogin path="/vendors" component={withTracker(Vendor)}/>
                    <RequireLogin path="/campaigns/add" component={withTracker(CampaignAdd)}/>
                    <RequireLogin path="/campaigns/:id" component={withTracker(CampaignUpdate)}/>
                    <RequireLogin path="/campaigns" component={withTracker(Product)}/>

                    <RequireLogin path="/products/create" component={withTracker(OriginalProductAdd)}/>
                    <RequireLogin path="/products/:id" component={withTracker(OriginalProductUpdate)}/>
                    <RequireLogin path="/products" component={withTracker(OriginalProduct)}/>

                    <RequireLogin path="/v-products/:id" component={withTracker(ProductUpdate)}/>

                    <RequireLogin path="/ads/create" component={withTracker(AdAdd)}/>
                    <RequireLogin path="/ads/:id" component={withTracker(AdUpdate)}/>
                    <RequireLogin path="/orders/:id" component={withTracker(OrderDetail)}/>
                    <RequireLogin path="/orders" component={withTracker(Order)}/>
                    <RequireLogin path="/all-orders" component={withTracker(AllOrder)}/>

                    <RequireLogin path="/delivery-cases" component={withTracker(DeliveryCase)} />
                    
                    <RequireLogin path="/groups/:id" component={withTracker(GroupUserDetail)}/>
                    <RequireLogin path="/groups" component={withTracker(GroupUser)}/>
                    <RequireLogin path="/warehouse" exact component={withTracker(Warehouse)}/>
                    <RequireLogin path="/warehouse/issues/:id/add-order" component={withTracker(IssueAddOrder)}/>
                    <RequireLogin path="/warehouse/issues/:id" exact component={withTracker(IssueDetail)}/>
                    <RequireLogin path="/warehouse/:code" exact component={withTracker(WarehouseDetail)}/>
                    <RequireLogin path="/warehouse/:code/stocks" component={withTracker(WarehouseStock)}/>
                    <RequireLogin path="/warehouse/:code/issues" component={withTracker(WarehouseIssue)}/>
                    <RequireLogin path="/warehouse/:code/receipts" component={withTracker(WarehouseReceipt)}/>
                    <RequireLogin path="/warehouse/:code/permissions" component={withTracker(WarehousePermission)}/>
                    <RequireLogin path="/warehouse/issues/:id" component={withTracker(IssueDetail)}/>
                    <RequireLogin path="/warehouse/receipts/:id" component={withTracker(ReceiptDetail)}/>
                    <RequireLogin path="/warehouse" component={withTracker(Warehouse)}/>
                    <RequireLogin path="/statistics/add" component={withTracker(StatisticAdd)}/>
                    <RequireLogin path="/statistics/:id" component={withTracker(StatisticUpdate)}/>
                    <RequireLogin path="/statistics" component={withTracker(StatisticDetail)}/>
                    <RequireLogin path="/statistics-list" component={withTracker(Statistic)}/>
                    <RequireLogin path="/setting" component={withTracker(Setting)}/>
                    <RequireLogin path="/returned-leads" component={withTracker(ReturnLead)}/>
                    <RequireLogin path="/stringee/add" component={withTracker(StringeeAdd)}/>
                    <RequireLogin path="/stringee" component={withTracker(Stringee)}/>
                    <Route path="/404" component={NotFound}/>
                    <Route path="/403" component={AccessDenied}/>
                    <Route path="*" component={NotFound}/>
                </Switch>
            </HashRouter>
        )
    }
}
