import React, {Component} from 'react';
import {Breadcrumb as AntBreadCrumb, Button, Icon} from 'antd';
import {BreadCrumb} from "../../../../components/BreadCrumb";
import {Link} from "react-router-dom";

class Header extends Component {

    onResetForm = () => {
        this.props.form.resetFields();
    };

    handleSubmit = () => {
        this.props.onSubmit();
    };

    render() {
        const {t, isLoading} = this.props;
        const leftContent = (
            <AntBreadCrumb>
                <AntBreadCrumb.Item>
                    <Link to={"/"}>{t('page.home')}</Link>
                </AntBreadCrumb.Item>
                <AntBreadCrumb.Item>
                    <Link to={"/campaigns"}>{t("campaign.title")}</Link>
                </AntBreadCrumb.Item>
                <AntBreadCrumb.Item>{t('campaign.update_campaign')}</AntBreadCrumb.Item>
            </AntBreadCrumb>
        );

        const rightContent = (
            <div className="btn-group">
                <Button type="primary" className="mg-r-10" onClick={this.handleSubmit} loading={isLoading}>{t("button.save")}</Button>
                <Button type="default" onClick={this.onResetForm}>{t("button.cancel")}</Button>
            </div>
        );

        return (
            <BreadCrumb
                {...this.props}
                leftContent={leftContent}
                rightContent={rightContent}
            />
        );
    }
}

export {Header};