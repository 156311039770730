import React, {Component} from 'react';
import {Collapse, Icon, Button, Pagination} from 'antd';
import * as _ from 'lodash';
import {FaqEdit} from "./FaqEdit";
import {arrayConcat, doCallBack} from "../../../utils/helper";
import {FaqCreate} from "./FaqCreate";

const Panel = Collapse.Panel;

class Faq extends Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            isShowEdit: false,
            isShowCreate:false,
            editItem: null,
            loading: false,
            currentPage: 1,
            totalItems: 50,
            pageSize: 25,
            defaultPageSize: 25
        };
    }

    componentDidMount = () => {
        this.fetchFaq();
    };

    fetchFaq = (filter = {}, cb) => {
        this.setState({loading: true});
        let {defaultPageSize} = this.state;
        let defaultFilter = {
            page_size: defaultPageSize
        };
        filter = _.mergeWith(defaultFilter, filter, arrayConcat);
        this.props.fetchFaq(filter, (error, response) => {
            this.setState({loading: false});
            if(!error) {
                this.setState({
                    items: response.list,
                    totalItems: response.count,
                    pageSize: response.metadata.pageSize,
                    currentPage: response.metadata.currentPage
                });
            }
            doCallBack(cb, [error, response]);
        });
    };

    onUpdate = () => {
        this.setState({
            isShowEdit: false,
            editItem: null
        }, () => {
            this.fetchFaq();
        });
    };

    onCreate = () => {
        this.setState({
            isShowCreate: false
        }, () => {
            this.fetchFaq();
        });
    };

    onCancel = () => {
        this.setState({
            isShowEdit: false,
            isShowCreate: false,
            editItem: null
        });
    };

    showCreate = () => {
        this.setState({
            isShowCreate: true
        });
    };

    showEdit = (item, e) => {
        e.stopPropagation();
        this.setState({
            isShowEdit: true,
            editItem: item
        });
    };

    onShowSizeChange = (current, pageSize) => {
        this.fetchFaq({
            page: 1,
            page_size: pageSize
        })
    };

    onChangePage = (page, pageSize) => {
        this.fetchFaq({
            page: page,
            page_size: pageSize
        });
    };

    renderItem = (item) => {
        return (
            <Panel header={item.question} key={item.id} extra={<Icon type={"edit"} onClick={this.showEdit.bind(this, item)} />}>
                <div dangerouslySetInnerHTML={{__html: item.answer}} />
            </Panel>
        );
    };

    render() {
        const {t} = this.props;
        const {items, isShowEdit, editItem, isShowCreate, loading, totalItems, currentPage, pageSize} = this.state;
        return (
            <div className="box-container">
                <div className="flr mg-bt-10">
                    <Button icon="plus" onClick={this.showCreate}>{t('contact-detail.add_fqa')}</Button>
                </div>
                <div className="clearfix" />
                <Collapse defaultActiveKey={['1']}>
                    {items.map((item) => this.renderItem(item))}
                </Collapse>

                {!loading && totalItems > 0 && (
                    <Pagination
                        className="mg-t-15 text-right"
                        showSizeChanger
                        onShowSizeChange={this.onShowSizeChange}
                        onChange={this.onChangePage}
                        pageSizeOptions={['25','50', '100', '150', '200']}
                        defaultCurrent={1}
                        current={currentPage}
                        pageSize={pageSize}
                        total={totalItems}
                    />
                )}


                {isShowEdit && (
                    <FaqEdit
                        {...this.props}
                        item={editItem}
                        onSave={this.onUpdate}
                        onCancel={this.onCancel}
                    />
                )}

                {isShowCreate && (
                    <FaqCreate
                        {...this.props}
                        onSave={this.onCreate}
                        onCancel={this.onCancel}
                    />
                )}
            </div>
        )
    }
}

export {Faq};