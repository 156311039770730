import React from 'react';
import {Form, Modal, notification, Select} from "antd";
import SelectProduct from "../../../../components/SelectProduct";
import ProductService from "../../../../services/ProductService";
import AdService from "../../../../services/AdService";

class UpdateGiftModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            form: {},
            skuItems: []
        }
    }

    componentDidMount() {
        this.handleChangeProduct(this.props.gift.productId);
        this.setState({
            form: {
                productId: this.props.gift.productId,
                sku: this.props.gift.sku,
                quantity: this.props.gift.quantity
            }
        })
    }

    handleChangeProduct = (value) => {
        this.setState({
            form: {
                ...this.state.form,
                productId: value
            }
        })

        this.setState({loading: true});
        ProductService.getSku(value, (error, response) => {
            if (!error) {
                this.setState({skuItems: response});
            }
            this.setState({loading: false});
        })
    }

    handleChangeSku = (sku) => {
        this.setState({
            form: {
                ...this.state.form,
                sku: sku
            }
        })
    }

    handleChangeNumber = (field, value) => {
        this.setState({
            form: {
                ...this.state.form,
                [field]: value
            }
        })
    }

    handleSubmit = () => {
        const {form} = this.state;
        const {adId, gift} = this.props;
        if (form.productId && form.sku) {
            this.setState({loading: true});
            AdService.updateGift(adId, gift.id, {
                sku: form.sku
            }, (error) => {
                if (!error) {
                    notification.success({
                        message: 'Update gift success'
                    })

                    this.setState({
                        form: {},
                        loading: false
                    });
                    this.props.fetchGifts();
                    this.props.onVisibleChange(false);
                }
                else {
                    notification.error({
                        message: error.message
                    })
                }
                this.setState({loading: false});
            })
        }
        else {
            notification.error({
                message: 'Please fill out this form'
            })
        }
    }

    render() {
        const {form, loading, skuItems} = this.state;
        const {visible, t} = this.props;
        return (
            <Modal
                visible={visible}
                title={t('ads.update_gift')}
                okText={t('button.update')}
                cancelText={t('button.cancel')}
                okButtonProps={{
                    loading: loading,
                    disabled: loading
                }}
                cancelButtonProps={{
                    loading: loading,
                    disabled: loading
                }}
                onOk={this.handleSubmit}
                onCancel={() => this.props.onVisibleChange(false)}
            >
                <Form layout={'vertical'}>
                    <Form.Item label={t('ads.product')} required={true}>
                        <SelectProduct
                            t={this.props.t}
                            value={form.productId}
                            onChange={this.handleChangeProduct}
                        />
                    </Form.Item>

                    <Form.Item label={'Sku'} required={true}>
                        <Select
                            className={'width-100pc'}
                            value={form.sku}
                            onChange={this.handleChangeSku}
                        >
                            {skuItems.map(item => (
                                <Select.Option key={item.sku} value={item.sku}>{item.display} ({item.sku})</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

UpdateGiftModal.defaultProps = {
    gift: {},
    onVisibleChange: () => {},
    fetchGifts: () => {}
};


export default UpdateGiftModal;
