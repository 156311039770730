import React from 'react';
import {Modal, Form, Spin, Empty, Select} from 'antd';
import * as _ from 'lodash';
import VendorService from "../../../../services/VendorService";
import {doCallBack} from "../../../../utils/helper";

import {toastr} from "../../../../utils/toastr";
import {SelectRole} from "./SelectRole";

class AddUser extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            users: [],
            initialUsers: [],
            usersSelected: [],
            loading: false,
            loadingUser: false
        };
    }

    componentDidMount() {
        this.fetchUsers({}, (error, response) => {
            if(!error) {
                this.setState({initialUsers: response.list});
            }
        });
    }

    fetchUsers = (filter = {}, cb) => {
        const {vendorId} = this.props;
        this.setState({loadingUser: true});
        VendorService.getUsersNotInVendor(vendorId, filter, (error, response) => {
            this.setState({loadingUser: false});
            if(!error) {
                this.setState({
                    users: response.list
                });
            }
            doCallBack(cb, [error, response]);
        });
    };

    onCancel = (e) => {
        this.props.onCancel(e);
    };

    onSubmit = () => {
        const {form, t} = this.props;
        const {vendorId} = this.props;
        form.validateFields((error, values) => {
            if(!error) {
                const users = values.users;
                let payload = [];
                for(let userId of users) {
                    payload.push({
                        userId: userId
                    });
                }
                this.setState({loading: true});
                VendorService.AddUserToVendor(vendorId, payload, (error, response) => {
                    this.props.onSubmit(error, response);
                    this.setState({loading: false});
                    if(!error) {
                        toastr.success(t("message.success"));
                        this.props.history.replace(`/vendors/${vendorId}`);
                    }else{
                        toastr.error(error.message);
                    }
                })
            }
        })
    };

    onSearch = (keyword) => {
        this.fetchUsers({username: keyword});
    };

    onFocus = () => {
        this.setState({users: this.state.initialUsers});
    };

    onChangeUser = (values) => {
        this.setState({usersSelected: values});
    };

    render() {
        const {users, loadingUser, loading} = this.state;
        const {t} = this.props;
        const {getFieldDecorator} = this.props.form;
        return (
            <Modal
                className={"_modal_add_user_to_vendor modal-add-user-to-vendor"}
                maskClosable={false}
                visible={true}
                title={t("vendor.add_user_title")}
                onCancel={this.onCancel}
                onOk={this.onSubmit}
                okButtonProps={{
                    loading: loading,
                    className: "_btn_submit_add_user"
                }}
                cancelButtonProps={{
                    className: "_btn_cancel_add_user"
                }}
                cancelText={t("button.cancel")}
                okText={t("button.save")}
            >
                <Form layout="vertical">
                    <Form.Item label={t('vendor.search_user')}>
                        {getFieldDecorator('users', {
                            rules: [
                                {required: true, message: t('message.required')}
                            ]
                        })(
                            <Select
                                loading={loadingUser}
                                className={'_select_users'}
                                showSearch
                                mode="multiple"
                                allowClear={true}
                                defaultActiveFirstOption={false}
                                placeholder={t('vendor.search_user')}
                                notFoundContent={loadingUser ? <Spin size="small" /> : <Empty description={t("table.empty")}/>}
                                onSearch={this.onSearch}
                                onFocus={this.onFocus}
                                onChange={this.onChangeUser}
                                filterOption={false}
                            >
                                {users.map((user) => (
                                    <Select.Option value={user.id} key={user.id}>{user.username}</Select.Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

AddUser.defaultProps = {
    onCancel: (e) => {},
    onSubmit: (e) => {},
    defaultRoles: []
};

AddUser = Form.create()(AddUser);

export {AddUser};