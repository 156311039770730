import React from 'react';
import {Button, Card, Empty, Form, Input, notification, Select} from 'antd';
import lodash from 'lodash';
import {doCallBack, objectGet} from "../../../utils/helper";
import LocationService from '../../../services/LocationService';
import UserService from "../../../services/UserService";
import {ORDER_SCOPES} from "../../../utils/api/Scopes";
import {ORDER_STATUS} from "../../../utils/const";
import VendorService from "../../../services/VendorService";
import {LocalStore} from "../../../utils/LocalStore";

class ShippingAddress extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editMode: false,
            loadingProvince: false,
            provinces: [],
            cities: [],
            areas: [],
            couriers: [],
            initialProvinces: [],
            initialCities: [],
            initialAreas: [],
            submitting: false,
            vendor: LocalStore.getInstance().read('vendor')
        };
    }

    fetchProvinces = (cb) => {
        let {order} = this.props;
        let countryId = order.countryId;
        LocationService.getProvinceByCountry(countryId, (error, response) => {
            if(!error) {
                this.setState({
                    provinces: response
                });
            }
            doCallBack(cb, [error, response]);
        });
    };

    fetchCities = (provinceId, cb) => {
        LocationService.getCity(provinceId, (error, response) => {
            if(!error) {
                this.setState({
                    cities: response
                });
            }
            doCallBack(cb, [error, response]);
        });
    };

    fetchDistricts = (cityId, cb) => {
        LocationService.getDistrict(cityId, (error, response) => {
            if(!error) {
                this.setState({
                    areas: response
                });
            }
            doCallBack(cb, [error, response]);
        });
    };

    fetchCouriers = (cb) => {
        const {vendor} = this.state;
        VendorService.couriers(vendor.id, (error, response) => {
            if (!error) {
                this.setState({
                    couriers: response
                })
            }
            doCallBack(cb, [error, response]);
        })
    };

    resetDataSource = (key) => {
        let data = [];
        if(key === 'provinces') {
            data = this.state.initialProvinces;
        } else if(key === 'cities') {
            data = this.state.initialCities;
        } else if (key === 'areas') {
            data = this.state.initialAreas;
        }

        this.setState({
            [key]: data
        });
    };

    toggleEditMode = () => {
        const {order} = this.props;
        this.setState({editMode: !this.state.editMode}, () => {
            if(this.state.editMode) {
                this.fetchProvinces((error, response) => {
                    if (!error) {
                        this.setState({
                            initialProvinces: response
                        })
                    }
                });
                this.fetchCities(order.provinceId, (error, response) => {
                    if (!error) {
                        this.setState({
                            initialCities: response
                        })
                    }
                });
                this.fetchDistricts(order.cityId, (error, response) => {
                    if (!error) {
                        this.setState({
                            initialAreas: response
                        })
                    }
                });

                this.fetchCouriers();
            }
        });
    };

    handleChangeCourier = value => {
        const {resetFields, isFieldTouched, setFieldsValue} = this.props.form;
        const {order} = this.props;
        setFieldsValue({provinceId: undefined});
        setFieldsValue({cityId: undefined});
        setFieldsValue({areaId: undefined});
        if(isFieldTouched('provinceId')) {
            resetFields(['provinceId']);
        }
        if(isFieldTouched('cityId')) {
            resetFields(['cityId']);
        }
        if(isFieldTouched('areaId')) {
            resetFields(['areaId']);
        }
        if(value) {
            LocationService.getProvinceByCountry(order.countryId, (error, response) => {
                if (!error) {
                    this.setState({initialProvinces: response});
                }
            });
        }
    };

    onChangeProvince = (value) => {
        const {resetFields, isFieldTouched, setFieldsValue} = this.props.form;
        setFieldsValue({cityId: undefined});
        if(isFieldTouched('cityId')) {
            resetFields(['cityId']);
        }
        if(value) {
            this.fetchCities(value, (error, response) => {
                if (!error) {
                    this.setState({initialCities: response});
                }
            });
        }
    };

    onChangeCity = (value) => {
        const {resetFields, isFieldTouched, setFieldsValue} = this.props.form;
        setFieldsValue({areaId: undefined});
        if(isFieldTouched('areaId')) {
            resetFields(['areaId']);
        }
        if(value) {
            this.fetchDistricts(value, (error, response) => {
                if (!error) {
                    this.setState({initialAreas: response});
                }
            });
        }
    };


    filterProvince = (value, option) => {
        return option.props.children.toLowerCase().indexOf(value.toLowerCase()) >= 0
    };

    renderEditMode = () => {
        const {order, t, form} = this.props;
        const {provinces, cities, areas, couriers, submitting} = this.state;

        const {getFieldDecorator, getFieldValue} = form;
        const formOptions = {
            layout: "horizontal",
            className: "form-edit-mode",
            labelCol: {
                xs: { span: 24 },
                md: { span: 24 },
                lg: { span: 11 },
            },
            wrapperCol: {
                xs: { span: 24 },
                md: { span: 24 },
                lg: { span: 13 },
            },
        };
        const contactName = objectGet(order, 'contactName');
        const contactPhone = objectGet(order, 'contactPhone');
        const countryName = objectGet(order, 'country.name');
        const courierServiceId = objectGet(order, 'courierServiceId');
        const provinceId = objectGet(order, 'provinceId');
        const cityId = objectGet(order, 'cityId');
        const areaId = objectGet(order, 'districtId');
        const address = objectGet(order, 'address');
        return (
            <Form {...formOptions}>
                <Form.Item label={t('order_detail.fullname')}>
                    {getFieldDecorator('contactName', {
                        rules: [
                            {required: true, message: t('message.required')},
                            { max: 160, message: t("error.length_160")},
                            {whitespace:true, message: t("message.required")}
                        ],
                        initialValue: contactName
                    })(
                        <Input className="_input_contact_name" onPressEnter={this.onSubmit} disabled={submitting} />
                    )}
                </Form.Item>
                <Form.Item label={t('order_detail.phone')}>
                    {getFieldDecorator('contactPhone', {
                        rules: [
                            {required: true, message: t('message.required')},
                            { max: 160, message: t("error.length_160")},
                            {whitespace:true, message: t("message.required")}
                        ],
                        initialValue: contactPhone
                    })(
                        <Input className="_input_contact_phone" onPressEnter={this.onSubmit} disabled={submitting} />
                    )}
                </Form.Item>
                <Form.Item label={t('vendor.country')}>
                    <span className="_text_country_name">{countryName}</span>
                </Form.Item>
                <Form.Item label={t('vendor.courier')}>
                    {getFieldDecorator('courierServiceId', {
                        rules: [
                            {required: true, message: t('message.required')}
                        ],
                        initialValue: courierServiceId ? courierServiceId : undefined
                    })(
                        <Select
                            className="_input_courier_id"
                            defaultActiveFirstOption={false}
                            showSearch
                            notFoundContent={<Empty description={t("table.empty")}/>}
                            allowClear={false}
                            placeholder={t("order_detail.courier")}
                            filterOption={false}
                            onChange={this.handleChangeCourier}
                        >
                            {Array.isArray(couriers) && couriers.map((item) => (
                                <Select.Option key={item.id} value={item.id} className={"_option_courier"}>{item.name}</Select.Option>
                            ))}
                        </Select>
                    )}
                </Form.Item>
                <Form.Item label={t('order_detail.city')}>
                    {getFieldDecorator('provinceId', {
                        rules: [
                            {required: true, message: t('message.required')}
                        ],
                        initialValue: provinceId ? provinceId : undefined
                    })(
                        <Select
                            className="_input_province_id"
                            defaultActiveFirstOption={false}
                            showSearch
                            notFoundContent={<Empty description={t("table.empty")}/>}
                            allowClear={true}
                            placeholder={t("order_detail.city")}
                            onFocus={this.resetDataSource.bind(this, "provinces")}
                            onChange={this.onChangeProvince}
                            filterOption={this.filterProvince}
                        >
                            {provinces.map((item) => (
                                <Select.Option key={item.id} value={item.id} className={"_option_province"}>{item.name}</Select.Option>
                            ))}
                        </Select>
                    )}
                </Form.Item>
                <Form.Item label={t('order_detail.district')}>
                    {getFieldDecorator('cityId', {
                        rules: [
                            {required: true, message: t('message.required')}
                        ],
                        initialValue: cityId ? cityId : undefined
                    })(
                        <Select
                            className="_input_city_id"
                            defaultActiveFirstOption={false}
                            showSearch
                            notFoundContent={<Empty description={t("table.empty")}/>}
                            allowClear={true}
                            placeholder={t("order_detail.district")}
                            onFocus={this.resetDataSource.bind(this, "cities")}
                            onChange={this.onChangeCity}
                            filterOption={this.filterProvince}
                            disabled={!getFieldValue('provinceId')}
                        >
                            {cities.map((item) => (
                                <Select.Option key={item.id} value={item.id} className={"_option_city"}>{item.name}</Select.Option>
                            ))}
                        </Select>
                    )}
                </Form.Item>
                <Form.Item label={t('order_detail.area')}>
                    {getFieldDecorator('areaId', {
                        initialValue: areaId ? areaId : undefined
                    })(
                        <Select
                            className="_input_area_id"
                            defaultActiveFirstOption={false}
                            showSearch
                            notFoundContent={<Empty description={t("table.empty")}/>}
                            allowClear={true}
                            placeholder={t("order_detail.area")}
                            onFocus={this.resetDataSource.bind(this, "areas")}
                            filterOption={this.filterProvince}
                            disabled={!getFieldValue('cityId')}
                        >
                            {areas.map((item) => (
                                <Select.Option key={item.id} value={item.id} className={"_option_area"}>{item.name}</Select.Option>
                            ))}
                        </Select>
                    )}
                </Form.Item>
                <Form.Item label={t('order_detail.address_detail')}>
                    {getFieldDecorator('address', {
                        rules: [
                            {required: true, message: t('message.required')},
                            { max: 255, message: t("error.length_255")},
                            {whitespace:true, message: t("message.required")}
                        ],
                        initialValue: address
                    })(
                        <Input.TextArea className="_input_address" onPressEnter={this.onSubmit} disabled={submitting} />
                    )}
                </Form.Item>
            </Form>
        );
    };

    renderTextMode = () => {
        const {order, t} = this.props;
        const formOptions = {
            layout: "horizontal",
            className: "form-text-mode",
            labelCol: {
                xs: { span: 24 },
                md: { span: 24 },
                lg: { span: 11 },
            },
            wrapperCol: {
                xs: { span: 24 },
                md: { span: 24 },
                lg: { span: 13 },
            },
        };
        const contactName = objectGet(order, 'contactName');
        const contactPhone = objectGet(order, 'contactPhone');
        const countryName = objectGet(order, 'country.name');
        const provinceName = objectGet(order, 'provinceName');
        const cityName = objectGet(order, 'cityName');
        const address = objectGet(order, 'address');
		const postalCode = objectGet(order, 'postalCode');
        return (
            <Form {...formOptions}>
                <Form.Item label={t('order_detail.fullname')}>
                    <span className="_text_contact_name form-item-text">{contactName}</span>
                </Form.Item>
                <Form.Item label={t('order_detail.phone')}>
                    <span className="_text_contact_phone form-item-text">{contactPhone}</span>
                </Form.Item>
                <Form.Item label={t('order_detail.courier')}>
                    <span className="_text_contact_courier form-item-text">{lodash.get(order, 'courierService.name')}</span>
                </Form.Item>
                <Form.Item label={t('vendor.country')}>
                    <span className="_text_country_name form-item-text">{countryName}</span>
                </Form.Item>
                <Form.Item label={t('order_detail.city')}>
                    <span className="_text_province_name form-item-text">{provinceName}</span>
                </Form.Item>
                <Form.Item label={t('order_detail.district')}>
                    <span className="_text_city_name form-item-text">{cityName}</span>
                </Form.Item>
                <Form.Item label={t('order_detail.area')}>
                    <span className="_text_area_name form-item-text">{objectGet(order, 'area.name', objectGet(order, 'districtName', '--'))}</span>
                </Form.Item>
                <Form.Item label={t('order_detail.address_detail')}>
                    <span onCopy={this.handleCopyAddress} className="_text_address form-item-text">{address}</span>
                </Form.Item>
				<Form.Item label={t('order_detail.postal_code')}>
					<span className="_text_postal_code form-item-text">{postalCode}</span>
				</Form.Item>
            </Form>
        );
    };

    handleCopyAddress = (e) => {
        const {loggedUser} = this.props;
        const selection = document.getSelection();
        e.clipboardData.setData('text/plain', `${selection.toString()}${objectGet(loggedUser, 'id', '')}`);
        e.preventDefault();
    }

    onSubmit = () => {
        const {form, order, t, fetchOrder, updateOrder} = this.props;
        form.validateFields((error, values) => {
            if(!error) {
                let data = Object.assign(order, values);
                data.districtId = values.areaId;
                if(data.address) data.address = data.address.toString().trim();
                if(data.contactName) data.contactName = data.contactName.toString().trim();
                data = lodash.omitBy(data, v => v === undefined || v === 0);
                this.setState({submitting: true});
                updateOrder(data, (error) => {
                    this.setState({submitting: false});
                    fetchOrder(() => {
                        if(!error) {
                            this.toggleEditMode();
                            notification.success({
                                message: t('message.success')
                            });
                        } else {
                            notification.error({
                                message: error.message
                            });
                        }
                    })

                })
            }
        });
    };

    onCancel = () => {
        this.toggleEditMode();
    };

    render() {
        const {t, order} = this.props;
        const status = objectGet(order, 'status');
        const hasIssue = objectGet(order, 'hasIssue');
        const {editMode, submitting} = this.state;
        let hasPermission = UserService.isAllowed(ORDER_SCOPES.ORDER_UPDATE_ADDR);
        const statusAllowEdit = [ORDER_STATUS.NEW.key, ORDER_STATUS.HOLD.key, ORDER_STATUS.READY.key];
        let showButtonEdit = false;
        if(statusAllowEdit.indexOf(status) >= 0 && hasPermission && !hasIssue) {
            showButtonEdit = true;
        }

        let renderButton = (
            <Button
                size="small"
                icon="edit"
                onClick={this.toggleEditMode}
                className="_btn_toggle_edit_shipping_address"
            >
                {t('button.edit')}
            </Button>
        );
        if(editMode) {
            renderButton = (
                <>
                    <Button
                        size="small"
                        type="primary"
                        onClick={this.onSubmit}
                        className="mg-r-5 _btn_update_shipping_address"
                        loading={submitting}
                    >
                        {t('button.update')}
                    </Button>
                    <Button
                        size="small"
                        onClick={this.onCancel}
                        className="_btn_cancel_shipping_address"
                    >
                        {t('button.cancel')}
                    </Button>
                </>
            )
        }
        return (
            <Card
                className={'card-block'}
                title={t('order_detail.shipping_address')}
                extra={showButtonEdit && renderButton}
            >
                <div className="shipping-address-form">
                    {editMode ? this.renderEditMode() : this.renderTextMode()}
                </div>
            </Card>
        )
    }
}

ShippingAddress = Form.create()(ShippingAddress);
export {ShippingAddress};
