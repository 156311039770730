import React, {Component} from 'react';
import {Col, Input, notification, Row} from 'antd';
import lodash from 'lodash';
import ProductService from "../../../services/ProductService";

class TabM28SkuMapping extends Component {

    constructor(props) {
        super(props);
        this.state = {
            form: {}
        }
    }

    static getDerivedStateFromProps(props, state) {
        const newState = {...state};
        if ('skuItems' in props && !Object.keys(state.form).length) {
            for (let skuItem of props.skuItems) {
                newState.form[skuItem.sku] = skuItem.m28Sku;
            }
        }

        return newState;
    }

    handleChangeSku = (sku, e) => {
        const {form} = this.state;
        if (!form[sku]) form[sku] = "";
        form[sku] = e.target.value;
        this.setState({form});
    };

    handleSubmit = (sku) => {
        const {productId} = this.props;
        const {form} = this.state;

        ProductService.mappingM28Sku(productId, [{
            sku: sku,
            mapTo: form[sku]
        }], (error) => {
            if (!error) {
                notification.success({
                    message: "Mapping successful"
                })
            }
            else {
                notification.error({
                    message: "Mapping failed"
                })
            }
        })
    };

    render() {
        let {skuItems} = this.props;
        const {form} = this.state;
        return (
            <>
                <div className="m28-sku-mapping">
                    {skuItems.map(skuItem => (
                        <Row key={skuItem.sku} gutter={20} className={'mg-bt-5'}>
                            <Col xs={12} sm={8} lg={6}>
                                <div className={"text-al-r ant-input"}>{skuItem.sku}</div>
                            </Col>
                            <Col xs={12} sm={8} lg={6}>
                                <Input
                                    placeholder={"Press enter to submit"}
                                    value={lodash.get(form, skuItem.sku)}
                                    onChange={this.handleChangeSku.bind(this, skuItem.sku)}
                                    onPressEnter={this.handleSubmit.bind(this, skuItem.sku)}
                                />
                            </Col>
                        </Row>
                    ))}
                </div>
            </>
        )
    }
}

export default TabM28SkuMapping;