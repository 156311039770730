import React, {Component} from 'react';
import {Form, Input, Modal, notification, Select} from 'antd';
import AdSetService from "../../../../services/AdSetService";

const Option = Select.Option;

class FormUpdateAdset extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            adset: {}
        };
    }

    componentDidMount() {
        this.setState({loading: true})
        AdSetService.findById(this.props.adsetSelected.id, (error, response) => {
            this.setState({loading: false})
            if (!error) {
                this.setState({adset: {...response}})
            }
            else {
                notification.error({
                    message: 'Fetch adset error'
                })
            }
        })
    }

    onPressEnter = () => {
        if(this.props.checkPressEnterUpdate){
            this.props.onUpdate();
        }
    };

    render() {
        const {
            onCancel, onUpdate, form, t, adsetSelected
        } = this.props;
        const { getFieldDecorator } = form;
        const {adset, loading} = this.state;
        return (
            <Modal
                visible={true}
                title={t("ads_group_list.update")}
                onCancel={onCancel}
                onOk={onUpdate}
                okButtonProps={{
                    loading: loading,
                    disabled: loading,
                    className: "_update-adset-save"
                }}
                cancelButtonProps={{
                    loading: loading,
                    disabled: loading,
                    className: "_update-adset-cancel"
                }}
                cancelText={t("button.cancel")}
                okText={t("button.save")}
            >
                <Form layout="vertical">
                    <Form.Item label={t("ads_group.name")}>
                        {getFieldDecorator('name', {
                            rules: [{ required: true, message: t("ads_group.validate_name")},{ max: 160, message: t("ads_group.name_length")},{whitespace:true, message: t("ads_group.validate_name")}],
                            initialValue: adsetSelected?adsetSelected.name:""
                        })(
                            <Input
                                autoFocus
                                className="_adset_name_update"
                                placeholder={t("ads_group.input_name")}
                                onPressEnter={this.onPressEnter}
                            />
                        )}
                    </Form.Item>
                    <Form.Item label={"Facebook Adset ID"}>
                        {getFieldDecorator('facebookIds', {
                            initialValue: adset.facebookIds || []
                        })(
                            <Select
                                mode={'tags'}
                                className="_adset_fb_update"
                                placeholder={t("ads_group.face_placeholder")}
                            />
                        )}
                    </Form.Item>
                    <Form.Item label={t("ads_list.campaign")}>
                        {getFieldDecorator('campaignId', {
                            initialValue: adsetSelected?adsetSelected.campaignId:""
                        })(
                            <Select
                                className="_select-update-adset"
                                disabled={true}
                            >
                                <Select.Option value={adsetSelected?adsetSelected.campaignId:""} className="_option-campaigns-update">{adsetSelected.campaign?adsetSelected.campaign.name:""}</Select.Option>
                            </Select>
                        )}
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

FormUpdateAdset = Form.create()(FormUpdateAdset);
export {FormUpdateAdset}
