import * as _ from 'lodash';
import * as moment from 'moment';

export function isPage(page) {
    let params = window.location.href.split("#");
    let activeTab = (params[1] && params[1] !== '/') ? params[1].substr(1) : 'dashboard';
    if(page === activeTab)
        return true;
    return false
}

/**
 * Get query string from URL
 * @param url
 * @returns {*}
 */
export function getUrlQueryString(url) {
    if(!url) url = window.location.href
    if(url.indexOf('?') < 0) {
        return "";
    }
    return url.substr(url.indexOf('?')+1)
}

/**
 * Get params from url by key
 * @param name
 * @param url
 * @returns {*}
 */
export function getUrlParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[[\]]/g, "\\$&");
    let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

/**
 * Parse query string to an object
 * @param queryString
 * @returns {{}}
 */
export function parseQueryStringToObject( queryString ) {
    let params = {}, queries, temp, i, l;
    // Split into key/value pairs
    queries = queryString.split("&");
    // Convert the array of strings into an object
    for ( i = 0, l = queries.length; i < l; i++ ) {
        temp = queries[i].split('=');
        params[temp[0]] = temp[1];
    }
    return params;
}

/**
 * Replace url parameter
 * @param url
 * @param paramName
 * @param paramValue
 * @returns string newURL
 */
export function replaceUrlParam(url, paramName, paramValue)
{
    if(!url) url = window.location.href

    if (paramValue == null) {
        paramValue = '';
    }
    let pattern = new RegExp('\\b('+paramName+'=).*?(&|$)');
    if (url.search(pattern)>=0) {
        return url.replace(pattern,'$1' + paramValue + '$2');
    }
    url = url.replace(/\?$/,'');
    // return url + (url.indexOf('?')>0 ? '&' : '?') + paramName + '=' + paramValue;
    let queryString = paramName + '=' + paramValue;
    let fullURL = url + (url.indexOf('?')>0 ? '&' : '?') + queryString

    return fullURL
}

/**
 * Lấy module hiện tại trên URL
 * @returns {string}
 */
export function getCurrentModule(url=null, showParam = false) {
    if(!url) url = window.location.hash;
    let regex = new RegExp(/\#\/([a-z-]*)\/?([0-9]+)?/gm);
    let matches = regex.exec(url);
    if(matches && matches.length) {
        let currentModule = matches[1];
        let subModule;
        let param;
        if(matches[2]) {
            let regexNumber = new RegExp(/^[0-9]+$/gm);
            let matchesNumber = regexNumber.exec(matches[2]);
            if(matchesNumber && matchesNumber.length) {
                param = ":id";
            } else {
                subModule = matches[2];
            }
        }

        if(showParam === true) {
            if(subModule) {
                currentModule += `/${subModule}`;
            }
            if(param) {
                currentModule += `/${param}`;
            }
        }

        return currentModule
    }
    return null
}

/**
 * Parse an object to http_query_string
 * @param obj
 * @returns {string}
 */
export function httpBuildQueryString(obj) {
    let keys = Object.keys(obj);
    let queryArray = [];
    for(let i = 0; i < keys.length; i ++) {
        let key = keys[i];
        let value = obj[key];
        queryArray.push(`${key}=${value}`);
    }

    return queryArray.join('&');
}


/**
 * Parse an object to http_query_string theo đúng thứ tự trên URL
 */
export const getParamsAsObject = (query)=>{
    query = query.substring(query.indexOf('?') + 1);

    let re = /([^&=]+)=?([^&]*)/g;
    let decodeRE = /\+/g;

    let decode = function (str) {
        return decodeURIComponent(str.replace(decodeRE, " "));
    };

    let params = {}, e;
    while (e = re.exec(query)) {
        let k = decode(e[1]), v = decode(e[2]);
        if (k.substring(k.length - 2) === '[]') {
            k = k.substring(0, k.length - 2);
            (params[k] || (params[k] = [])).push(v);
        }
        else params[k] = v;
    }

    let assign = function (obj, keyPath, value) {
        let lastKeyIndex = keyPath.length - 1;
        for (let i = 0; i < lastKeyIndex; ++i) {
            let key = keyPath[i];
            if (!(key in obj))
                obj[key] = {};
            obj = obj[key];
        }
        obj[keyPath[lastKeyIndex]] = value;
    };

    for (let prop in params) {
        let structure = prop.split('[');
        if (structure.length > 1) {
            let levels = [];
            structure.forEach(function (item, i) {
                let key = item.replace(/[?[\]\\ ]/g, '');
                levels.push(key);
            });
            assign(params, levels, params[prop]);
            delete(params[prop]);
        }
    }
    return params;
};

export function remove_sign(str) {
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
    str = str.replace(/Đ/g, "D");
    return str;
}

export function arrayConcat(objValue, srcValue) {
    if (Array.isArray(objValue)) {
        return _.uniq(objValue.concat(srcValue));
    }
}

/**
 * Tạo biến thể từ nhiều mảng
 * @returns {Array}
 */
export function makeVariants(items) {
    let r = [], arg = items;
    arg = _.filter(arg, (item) => {
        return item.length !== 0;
    });

    let max = arg.length - 1;
    function helper(arr, i) {
        if(Array.isArray(arg[i])) {
            for (let j = 0, l = arg[i].length; j < l; j++) {
                let a = arr.slice(0); // clone arr
                a.push(arg[i][j]);
                if (i == max) {
                    r.push(a);
                }
                else {
                    helper(a, i + 1);
                }
            }
        }
    }
    helper([], 0);
    return r;
}

export function stripTag(html)
{
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
}

export function formatDate(date, showTime = false) {
    let format = "DD/MM/YYYY";
    if(showTime) format += ' HH:mm';
    return moment(date).format(format);
}

export function formatDate2(date, showTime = false) {
    let format = "";
    if(showTime) format += 'HH:mm ';
    format += "DD/MM/YYYY";
    return moment(date).format(format);
}

export function objectGet(obj, property, _default = null) {
    return _.get(obj, property, _default);
}

export function isUrl(str) {
    let pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
}

export function doCallBack(cb, args, context = null) {
    if (typeof cb === "function" && Array.isArray(args)) {
        cb.apply(context, args);
    }
}

export function replaceString(str) {
    return str.toString().replace(/\D/gm, '');
}

export function getUrlSegment(pos) {
    let url = window.location.hash;
    let n = url.indexOf("?");
    url = url.substr(0, n >= 0 ? n : url.length);
    let arr = url.split("/");
    return arr[pos];
}

export function toBoolean(value) {
    switch (value) {
        case 1:
        case '1':
        case true:
        case 'true':
            return true;
        default:
            return false;
    }
}

export function getOrderStatusColor(status) {
    if(status === "NEW"){
        return "cyan"
    }else if(status === "DRAFT"){
        return "green"
    }else if(status === "DELIVERED"){
        return "lime"
    }else if(status === "DELIVERING"){
        return "orange"
    }else if(status === "CANCELLED"){
        return "geekblue"
    }else if(status === "EXCHANGE"){
        return "red"
    }else{
        return null
    }
}