import React from 'react'
import {LocalStore} from "../utils/LocalStore/index";
import {Redirect, Route} from "react-router-dom";
import UserNotInAnyVendor from '../pages/UserNotInAnyVendor';

const RequireLogin = ({component: Component, ...rest}) => {
    let isLoggedIn = LocalStore.getInstance().read("loginSession") ;
    let vendor = LocalStore.getInstance().read("vendor");
    if(!isLoggedIn) {
        LocalStore.getInstance().save("fobizUrlBack", window.location.href);
    }

    return (
        <Route {...rest} render={props => {
            if (isLoggedIn) {
                let urlAllowRender = ['/vendors', '/vendors/add', '/vendors/:id', '/vendors/:id/add-user'];
                if(!vendor || vendor === "undefined" || vendor === undefined) {
                    if(isLoggedIn.userId == 1) {
                        if(urlAllowRender.indexOf(props.match.path) >= 0) {
                            return (<Component {...props}/>);
                        } else {
                            return <Redirect to={{
                                pathname: `/vendors`,
                                state: {from: props.location}
                            }}/>;
                        }
                    } else {
                        if(props.match.path !== '/user-not-in-any-vendor') {
                            return <Redirect to={{
                                pathname: `/user-not-in-any-vendor`,
                                state: {from: props.location}
                            }}/>;
                        } else {
                            return <UserNotInAnyVendor {...props} />
                        }
                    }
                } else {
                    return (<Component {...props}/>);
                }
            } else {
                // not login + path name #login
                return (
                    <Redirect to={{
                        pathname: `/login`,
                        state: {from: props.location}
                    }}/>);
            }
        }}/>
    )
};



export default RequireLogin;