import React, {Component} from 'react';
import {
    Form as AntForm, Row, Col, Input,
} from 'antd';
const { TextArea } = Input;

class Form extends Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    }

    validateCustom = (rule, value, callback) => {
        const {t} = this.props;
        if(value.length > 0){
            if(value.toString().trim().length > 160 || value.toString().trim().length < 6 || value.toString().trim().length === 0){
                callback(t('ads_group.name_length_validate'));
                return;
            }
        }
        callback();
    };

    render() {
        const {t} = this.props;
        const {getFieldDecorator} = this.props.form;
        return (
            <>
                <div className="white-box">
                    <div className="form-body pd-30 pd-t-10 pd-bt-10">
                        <Row gutter={20}>
                            <Col span={24}>
                                <div className="form-item" style={{width:"50%"}}>
                                    <AntForm.Item className={"form-item-item"}
                                                  label={<span className="bold-400">{t("statistic.name")}</span>}>
                                        {getFieldDecorator('title', {
                                            rules: [
                                                {required: true, message: t("message.required")},
                                                {validator: this.validateCustom.bind(this)},
                                            ],
                                        })(
                                            <Input autoFocus={true} placeholder={t("statistic.name_placeholder")} className="_report-name"/>
                                        )}
                                    </AntForm.Item>
                                </div>
                            </Col>
                            <Col span={24}>
                                <div className="form-item" style={{width:"50%"}}>
                                    <AntForm.Item className={"form-item-item"}
                                                  label={<span className="bold-400">{t("statistic.script")}</span>}>
                                        {getFieldDecorator('script', {
                                            rules: [
                                                {required: true, message: t("message.required")},
                                                {whitespace:true, message: t("message.required")},
                                            ],
                                        })(
                                            <TextArea rows={4} placeholder={t("statistic.script_placeholder")} className="_report-script"/>
                                        )}
                                    </AntForm.Item>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </>
        )
    }
}

export {Form};