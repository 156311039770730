import React, {Component} from 'react';
import {Dropdown, Icon, Menu, Pagination, Switch, Table} from 'antd';
import * as moment from "moment/moment";
import {Link} from "react-router-dom";
import {objectGet} from "../../../utils/helper";
import UserService from "../../../services/UserService";
import {CAMPAIGNS_SCOPES} from "../../../utils/api/Scopes";

class List extends Component {

    constructor(props) {
        super(props);
        this.state = {
            sorter: {}
        };
    }

    componentDidMount = () => {

    };


    getConfigRowSelection = () => {
        return {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: (selectedRowKeys) => {
                this.props.getAdId(selectedRowKeys);
                this.setState({
                    selectedRowKeys
                });
            }
        }
    };

    componentDidUpdate(prevProps) {
        if(JSON.stringify(prevProps.countAd) !== JSON.stringify(this.props.countAd)){
            if(!this.props.countAd.length){
                this.setState({
                    selectedRowKeys: [],
                })
            }
        }
    }

    toggleDefault = (item) => {
        this.props.setAllAdItem({
            adDefault: false
        }, {
            campaignId: item.campaignId
        });

        this.props.setAdItem(item, {
            adDefault: true
        });

        // item.default = !item.default;
        this.props.toggleDefault(item);
    };

    getConfigTableColumns = () => {
        const {t, toggleEditAbleFbAdId} = this.props;
        const renderColumn = (key, text, record) => {
            switch (key) {
                case 'createdBy':
                    return record.createdBy || "System";
                case 'name':
                    return (
                        <>
                            <span className="product-name">{record.name}</span>
                            <div><i>{record.code}</i></div>
                        </>
                    );
                case "createdAt":
                    return moment(record.createdAt).format("DD/MM/YYYY HH:mm:ss");

                case "campaign":
                    return <span className="campaign-name">{record.campaign.name}</span>;
                case "adset":
                    return <span className="adset-name">{objectGet(record.campaignAdSet, 'name', "")}</span>;
                case "adDefault":
                    return <Switch
                        checked={record.adDefault}
                        size="small"
                        onChange={this.toggleDefault.bind(this,record)}
                    />;
                case 'action':
                    const menu = (
                        <Menu>
                            {UserService.isAllowed(CAMPAIGNS_SCOPES.CAMPAIGNS_UPDATE) && (
                                <Menu.Item key="1">
                                    <Link to={`/ads/${record.id}`}><Icon type="edit" /> {t('button.edit')}</Link>
                                </Menu.Item>
                            )}
                        </Menu>
                    );
                    return (
                        <Dropdown overlay={menu} trigger={['contextMenu', 'click']}>
                            <span className="pointer"><Icon type="dash" /></span>
                        </Dropdown>
                    )
            }
        };

        let columns = [
            {title: t("ads_list.name"),dataIndex: 'name', key: 'name',width:150,className:"_name _item",render: renderColumn.bind(this, 'name')},
            // {title: t("ads_list.code"),dataIndex: 'code', key: 'code',width:150,className:"_code _item",render: renderColumn.bind(this, 'code')},
            // {title: "Facebook Ad ID",dataIndex: 'fbAdId', key: 'fbAdId',width:180,className:"_fbAdId _item",render: renderColumn.bind(this, 'fbAdId')},
            {title: t("ads_list.campaign"),dataIndex: 'campaign', key: 'campaign',width:150,className:"_campaign _item",render: renderColumn.bind(this, 'campaign')},
            {title: t("ads_list.adset"),dataIndex: 'adset', key: 'adset',width:150,className:"_adset _item",render: renderColumn.bind(this, 'adset')},
            {title: t("ads_list.count_contact"),dataIndex: 'countContact', key: 'countContact',width:70,className:"_countContact _item"},
            {title: t("ads_list.default"),dataIndex: 'adDefault', key: 'adDefault',width:80,className:"_adDefault _item",render: renderColumn.bind(this, 'adDefault')},
            // {title: t("table.created_at"),dataIndex: 'createdAt', key: 'createdAt',render: renderColumn.bind(this, 'createdAt'),width:120,className:"_status _item"},
            {title: t("table.created_by"),dataIndex: 'createdBy', key: 'createdBy',render: renderColumn.bind(this, 'createdBy'),width:120,className:"_status _item"},
            {title: t("table.action"),dataIndex: 'action', key: 'action',render: renderColumn.bind(this, 'action'),width:120,className:"_action"},
        ];

        return columns;
    };

    render() {
        const {t, loadingAd} = this.props;
        const {dataSource,currentPage,totalItems,pageSize,onShowSizeChange,onChangePage} = this.props;
        let columns = this.getConfigTableColumns();
        const rowSelection = this.getConfigRowSelection();

        return (
            <div className="list-container _list-ad">
                <div className="a-table a-table--responsive">
                    <Table
                        columns={columns}
                        dataSource={dataSource}
                        rowKey={record => record.id}
                        pagination={false}
                        rowSelection={rowSelection}
                        loading={loadingAd}
                        locale={{
                            emptyText: t("table.empty")
                        }}
                    />
                </div>

                {!loadingAd && totalItems > 0 && (
                    <Pagination
                        className="mg-t-15 text-al-r"
                        showSizeChanger
                        onShowSizeChange={onShowSizeChange}
                        onChange={onChangePage}
                        pageSizeOptions={['25', '50', '100', '150', '200']}
                        defaultCurrent={1}
                        current={currentPage}
                        pageSize={pageSize}
                        total={totalItems}
                    />
                )}
            </div>
        );
    }
}

export {List}
