import React, {Component} from 'react';
import {Editor} from "../Editor";

class EditorInput extends Component {
    static getDerivedStateFromProps(nextProps) {
        // Should be a controlled component.
        if ('value' in nextProps) {
            return {
                value: nextProps.value
            }
        }

        return null;
    }

    constructor (props) {
        super(props);
        const value = props.value || null;
        this.state = {
            value: value
        }
    }

    onChange = (value) => {
        if(!('value' in this.props)) {
            this.setState({value: value});
        }

        this.triggerChange(value);
    };

    triggerChange = (changedValue) => {
        // Should provide an event to pass value to Form.
        const onChange = this.props.onChange;
        if (onChange) {
            onChange(changedValue);
        }
    };

    render() {
        const {value} = this.state;
        return (
            <Editor
                {...this.props}
                data={value}
                onChange={this.onChange}
            />
        )
    }
}

export {EditorInput};
