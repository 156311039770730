import React, {Component} from 'react';
import {translate} from 'react-i18next';
import * as _ from 'lodash';
import {Form as AntForm} from 'antd';
import StandardLayout from "../../../components/StandardLayout";
import {Header} from "./Header";
import {Form as FormAdd} from "./Form";
import {LocalStore} from "../../../utils/LocalStore";
import {makeVariants} from "../../../utils/helper";
import ProductService from "../../../services/ProductService";
import {notification} from "antd/lib/index";
import UserService from "../../../services/UserService";
import {CAMPAIGNS_SCOPES} from "../../../utils/api/Scopes";

class OriginalProductAdd extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: "",
            code: "",
            manufactory: "",
            description: "",
            defaultPrice: "",
            active: false,
            attrItems: [
                {key: "", attr: []},
                {key: "", attr: []},
                {key: "", attr: []}
            ],
            skuItems: [
                // {display: "", sku: ""},
                // {display: "", sku: ""},
                // {display: "", sku: ""}
            ],
            vendorsSelected: [],
            isLoading: false,
            vendor: LocalStore.getInstance().read("vendor"),
            productGroup: [],
            loadingProductGroup: false
        };
    }

    componentWillMount = () => {
        // check quyền tạo
        if (!UserService.isAllowed(CAMPAIGNS_SCOPES.CAMPAIGNS_PRODUCT_CREATE)) {
            this.props.history.replace('/403');
        }
    };

    componentDidMount = () => {
        this.getProductGroup();
    }

    onChangeAttrKey = (index, e) => {
        let attrItems = this.state.attrItems;
        if(e.target.value.toString().length <= 15) {
            attrItems[index].key = e.target.value;
            this.setState({attrItems});
        }
    };

    onChangeAttrValue = (index, value) => {
        if(value.length <= 10) {
            let attrItems = this.state.attrItems;
            attrItems[index].attr = value;
            this.setState({attrItems}, () => {
                this.generateSku();
            });
        }
    };

    generateSku = () => {
        let {attrItems} = this.state;
        let items = _.map(attrItems, 'attr');
        let arrayComb = makeVariants(items);
        let skuItems = [];
        _.forEach(arrayComb, (arr, k) => {
            skuItems[k] = {
                display: arr.join('/'),
                sku: "SKU",
                attr: {},
                checked: true,
                id: k
            };
            for(let i in arr) {
                skuItems[k].attr[attrItems[i].key] = arr[i];
            }
        });
        this.setState({skuItems});
    };

    onCheckSkuItem = (index, checked) => {
        let {skuItems} = this.state;
        skuItems[index].checked = checked;
        this.setState({skuItems});
    };

    onCheckAllSkuItem = () => {
        let {skuItems} = this.state;
        let isCheckAll = _.countBy(skuItems, 'checked')['true'] === skuItems.length;
        for(let item of skuItems) {
            item.checked = !isCheckAll;
        }
        this.setState({skuItems});
    };

    onChangeStatus = (checked, e) => {
        this.setState({
            active: checked
        });
    };

    handleVendorSelected = (items) => {
        this.setState({
            vendorsSelected: items
        })
    };

    onSubmit = (e) => {
        this.setState({isLoading: true});
        this.props.form.validateFields((err, values) => {
            const {t} = this.props;
            let {skuItems, attrItems, vendorsSelected} = this.state;
            if (!err) {
                values.productAttr = _.filter(attrItems, (item) => { return item.attr.length > 0 });
                values.productSku = _.filter(skuItems, (item) => { return item.checked });
                values.vendorIds = _.map(vendorsSelected, 'id');

                ProductService.createProduct(values, (err, repsonse) => {
                    this.setState({isLoading: false});
                    if (err === null) {
                        notification.success({
                            message: t("message.success"),
                        });
                        this.props.history.push('/products');
                    } else {
                        if(err.code === 'PRODUCT_EXISTS') {
                            notification.error({
                                message: t("add_product.exist_product"),
                            });
                        }
                        else if (err.code === 'PACKAGE_VALIDATE') {
                            notification.error({
                                message: t("message.upgrade_premium"),
                            });
                        }
                        else {
                            notification.error({
                                message: err.message
                            });
                        }
                    }

                    this.setState({isLoading: false});
                });
            } else {
                this.setState({isLoading: false});
            }
        });
    };

    onResetForm = () => {
        this.setState({
            active: false,
            attrItems: [
                {key: "", attr: []},
                {key: "", attr: []},
                {key: "", attr: []}
            ],
            skuItems: [],
            vendorsSelected: [],
        });
    };

    getProductGroup = () => {
        this.setState({
            loadingProductGroup: true
        });

        ProductService.getProductGroup((error, response) => {
            this.setState({
                loadingProductGroup: false
            });

            if(!error){
                this.setState({
                    productGroup: response
                })
            }
        })
    }

    render() {
        const {t} = this.props;
        const {productGroup, loadingProductGroup} = this.state;
        return (
            <StandardLayout title={t('products.page_title')} {...this.props}>
                <div className="product-add-page">
                    <AntForm>
                        <Header
                            {...this.props}
                            {...this.state}
                            onResetForm={this.onResetForm}
                            onSubmit={this.onSubmit}
                        />
                        <FormAdd
                            {...this.props}
                            {...this.state}
                            onChangeStatus={this.onChangeStatus}
                            onChangeAttrKey={this.onChangeAttrKey}
                            onChangeAttrValue={this.onChangeAttrValue}
                            onCheckSkuItem={this.onCheckSkuItem}
                            onCheckAllSkuItem={this.onCheckAllSkuItem}
                            onChangeVendors={this.handleVendorSelected}
                            loadingProductGroup={loadingProductGroup}
                            productGroup={productGroup}
                        />
                    </AntForm>
                </div>
            </StandardLayout>
        )
    }
}

OriginalProductAdd = AntForm.create()(OriginalProductAdd);
export default (translate())(OriginalProductAdd);
