import React from 'react';
import {Row, Col, Input, Select, DatePicker, Icon, Empty, Button} from 'antd';
import * as _ from "lodash";
import {getParamsAsObject, getUrlQueryString} from "../../../../utils/helper";
import * as moment from "moment/moment";

const {RangePicker} = DatePicker;
class Filter extends React.Component {

    constructor(props) {
        super(props);
        this.mounted = false;
        this.state = {
            receiptNumber: "",
            createdAt: [],
            createdBy: [],
            status: []
        };
        this.onSearchUser = _.debounce(this.onSearchUser, 400);
    }

    onPopstate = () => {
        if(this.mounted) {
            this.setStateFromQueryString();
        }
    };

    setStateFromQueryString = () => {
        let queryObject = getParamsAsObject(getUrlQueryString());
        this.resetStateFilter(() => {
            _.forEach(queryObject, (v, k) => {
                if (v) {
                    if(k === 'receipt_number') {
                        this.setState({receiptNumber: v});
                    }
                    if(k === 'status') {
                        this.setState({status: v.toString().split(',')});
                    }
                    if(k === 'created_by') {
                        this.setState({createdBy: v.toString().split(',')});
                    }
                    if (k === 'created_at_from') {
                        let createdAt = this.state.createdAt;
                        createdAt[0] = moment(v);
                        this.setState({createdAt});
                    }
                    if (k === 'created_at_to') {
                        let createdAt = this.state.createdAt;
                        createdAt[1] = moment(v);
                        this.setState({createdAt});
                    }
                }
            });
        });
    };

    resetStateFilter = (cb = null) => {
        this.setState({
            receiptNumber: "",
            createdAt: [],
            createdBy: [],
            status: []
        }, cb)
    };

    componentWillUnmount() {
        this.mounted = false;
        window.removeEventListener("popstate", this.onPopstate.bind(this), false);
    }

    componentDidMount = () => {
        this.mounted = true;
        window.addEventListener("popstate", this.onPopstate.bind(this), false);
        this.setStateFromQueryString();
    };

    onFilter = () => {
        let uri = this.props.getUrlInstance(true);
        const {receiptNumber, createdAt, createdBy, status} = this.state;
        uri.replaceParam('receiptNumber', receiptNumber.toString().trim());
        uri.replaceParam('createdBy', createdBy.join(','));
        uri.replaceParam('status', status.join(','));
        if(Array.isArray(createdAt)) {
            if(this.state.createdAt[0]) {
                uri.replaceParam('createdAtFrom', createdAt[0].format("YYYY-MM-DD 00:00:00"));
            }
            if(this.state.createdAt[1]) {
                uri.replaceParam('createdAtTo', createdAt[1].format("YYYY-MM-DD 23:59:59"));
            }
        }
        uri.replaceParam('page', 1);
        uri.replaceParam('filter', true);

        this.props.pushQueryString(uri.getQueryString());
    };

    clearFilter = () => {
        this.resetStateFilter(() => {
            this.props.pushQueryString("");
        });
    };

    onSearchUser = (keyword) => {
        this.props.fetchUsers({username: keyword});
    };

    onChangeRangePicker = (key, ranges) => {
        this.setState({
            [key]: ranges
        });
    };

    onChangeInput = (key, e) => {
        let value = e.target.value;
        if(key === 'receiptNumber') {
            value = value.toString().replace(/\s+/gm, '');
        }
        this.setState({
            [key]: value
        });
    };

    onFocusCreatedBy = () => {
        this.props.resetData('users');
    };

    onChangeCreatedBy = (values) => {
        this.setState({createdBy: values});
    };

    onChangeStatus = (values) => {
        this.setState({status: values});
    };

    render() {
        const {t, users, loadingCreatedBy,statuses} = this.props;
        const {receiptNumber, createdAt, createdBy, status} = this.state;
        return (
            <div className="receipt-filter filter filter-inside-box">
                <Row gutter={20} className="form-filter">
                    <Col xl={8} lg={8} className="form-filter-control">
                        <label>{t('stock_receipt.search_id')}</label>
                        <Input.Search
                            value={receiptNumber}
                            onChange={this.onChangeInput.bind(this, 'receiptNumber')}
                            placeholder={t('stock_receipt.search_id')}
                            className={"_filter_code"}
                            onPressEnter={this.onFilter}
                        />
                    </Col>
                    <Col xl={8} lg={8} className="form-filter-control">
                        <label>{t('stock_receipt.created_at')}</label>
                        <RangePicker
                            placeholder={[t("contact.from"), t("contact.to")]}
                            className={"_filter_created_at"}
                            value={createdAt}
                            onChange={this.onChangeRangePicker.bind(this, 'createdAt')}
                            style={{width: '100%'}}
                        />
                    </Col>
                    <Col xl={8} lg={8} className="form-filter-control">
                        <label>{t('stock_receipt.created_by')}</label>
                        <Select
                            filterOption={false}
                            className={"_filter_created_by filter-input mg-r-5"}
                            placeholder={t("stock_receipt.created_by")}
                            notFoundContent={<Empty description={t("table.empty")} />}
                            mode="multiple"
                            value={createdBy}
                            loading={loadingCreatedBy}
                            style={{width: '100%'}}
                            defaultActiveFirstOption={false}
                            onChange={this.onChangeCreatedBy}
                            onSearch={this.onSearchUser}
                            onFocus={this.onFocusCreatedBy}
                        >
                            {users.map((user) => (
                                <Select.Option className={`_option_created_by _option_created_by_${user.username}`} key={user.username}>{user.username}</Select.Option>
                            ))}
                        </Select>
                    </Col>
                    <Col xl={8} lg={8} className="form-filter-control">
                        <label>{t('stock_receipt.status')}</label>
                        <Select
                            filterOption={false}
                            className={"_filter_status filter-input mg-r-5"}
                            placeholder={t("stock_receipt.status")}
                            notFoundContent={<Empty description={t("table.empty")} />}
                            mode="multiple"
                            value={status}
                            style={{width: '100%'}}
                            defaultActiveFirstOption={false}
                            onChange={this.onChangeStatus}
                        >
                            {statuses.map((item) => (
                                <Select.Option className={`_option_status _option_status_${item}`} key={item}>{item}</Select.Option>
                            ))}
                        </Select>
                    </Col>
                    <Col xl={8} lg={8} className="form-filter-control filter-button">
                        <label>&nbsp;</label>
                        <div>
                            <span className="mg-r-10 pointer _filter_button_clear" onClick={this.clearFilter}>
                                <Icon type="sync" /> {t("contact.clear")}
                            </span>
                            <Button type="primary" className="_filter_button_filter" onClick={this.onFilter}>
                                {t("button.filter")}
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}

export {Filter}