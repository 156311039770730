import React, {Component} from 'react';
import {notification} from 'antd';
import {translate} from "react-i18next";
import {Filter} from "./Filter";
import {LocalStore} from "../../../utils/LocalStore";
import UserService from "../../../services/UserService";
import {List} from "./List";
import ProductService from "../../../services/ProductService";
import * as _ from "lodash";
import {arrayConcat} from "../../../utils/helper";
import VendorService from "../../../services/VendorService";
import {CAMPAIGNS_SCOPES} from "../../../utils/api/Scopes";
import StandardLayout from "../../../components/StandardLayout";
import {Header} from "./Header";


class OriginalProduct extends Component {

    constructor(props) {
        super(props);
        this.state = {
            vendor: LocalStore.getInstance().read('vendor'),
            currentPage: 1,
            totalItems: 50,
            pageSize: 100,
            pageCount: 0,
            initialUsers: [],
            products: [],
            users: [],
            loadingProduct: false,
            loadingUser: true,
            authUser: LocalStore.getInstance().read('authUser'),
            productGroup: [],
            loadingProductGroup: false,
        };
        this.filterProduct = null;
    }

    componentDidMount = () => {
        this.loadData();
        this.getProductGroup();
    };

    resetDataSource = (key) => {
        this.setState({
            [key]: this.state[`initial${_.upperFirst(key)}`]
        });
    };

    loadData = () => {
        this.fetchProduct({});
        this.fetchUser({}, (error, response) => {
            if(!error) {
                this.setState({
                    initialUsers: response.list
                });
            }
        })
    };

    fetchUser = (filter = {}, cb) => {
        this.setState({loadingUser: true});
        const {vendor} = this.state;
        VendorService.getUserInVendor(vendor.id, filter, (error, response) => {
            if(!error) {
                this.setState({
                    loadingUser: false,
                    users: response.list,
                });
            }
            if(typeof cb === 'function') cb(error, response);
        });
    };

    fetchProduct = (filter = {}, cb) => {
        this.setState({
            loadingProduct: true
        })
        let filterCond = {
            order: ["createdAt DESC"],
            include: ["productGroup"]
        };
        filterCond = _.mergeWith(filterCond, filter, arrayConcat);

        ProductService.getProducts(filterCond, (error, response) => {
            this.setState({
                loadingProduct: false
            })
            if(!error) {
                this.setState({
                    products: response.list,
                    loadingProduct: false,
                    currentPage: response.metadata.currentPage,
                    totalItems: response.count,
                    pageSize: response.metadata.pageSize,
                    pageCount: response.metadata.pageCount
                });
            }
            if(typeof cb === 'function') cb(error, response);
        });
    };

    onToggleProductStatus = (id) => {
        const {t} = this.props;
        if(UserService.isAllowed(CAMPAIGNS_SCOPES.CAMPAIGNS_PRODUCT_UPDATE)) {
            ProductService.changeProductStatus(id, (err, response) => {
                if (!err) {
                    notification.success({
                        message: t("message.success"),
                    });
                    let products = this.state.products;
                    for(let item of products) {
                        if(item.id === id) {
                            item.active = response.active;
                        }
                    }
                    this.setState({products});
                } else {
                    if (err.code === 'PACKAGE_VALIDATE') {
                        notification.error({
                            message: t("message.upgrade_premium"),
                        });
                    } else {
                        notification.error({
                            message: err.message,
                        });
                    }
                }
            });
        } else {
            notification.error({
                message: t("error.Access Denied"),
            });
        }
    };

    onShowSizeChange = (current, pageSize) => {
        this.fetchProduct({
            page: 1,
            page_size: pageSize,
            keyword:this.filterProduct.getFilterCon("keyword"),
            created_by:this.filterProduct.getFilterCon("createdBy"),
        });
    };

    onChangePage = (page, pageSize) => {
        this.fetchProduct({
            page: page,
            page_size: pageSize,
            keyword:this.filterProduct.getFilterCon("keyword"),
            created_by:this.filterProduct.getFilterCon("createdBy"),
        });
    };

    onFilter = (filter = {}, cb) => {
        this.fetchProduct(filter, cb);
    };

    setRefFilter = (ref) => {
        this.filterProduct = ref;
    };

    getProductGroup = () => {
        this.setState({
            loadingProductGroup: true
        });

        ProductService.getProductGroup((error, response) => {
            this.setState({
                loadingProductGroup: false
            });

            if(!error){
                this.setState({
                    productGroup: response
                })
            }
        })
    }

    render() {
        const {t} = this.props;
        const {vendor, totalItems, currentPage, pageSize,pageCount, users, products, loadingProduct, loadingProductGroup, productGroup} = this.state;

        return (
            <StandardLayout {...this.props} title={t("page.product")}>
                <div className="list-page product-container _product-container">
                    <Header {...this.props} />
                    <Filter
                        {...this.props}
                        users={users}
                        resetDataSource={this.resetDataSource}
                        onFilter={this.onFilter}
                        fetchUser={this.fetchUser}
                        currentPage={currentPage}
                        pageSize={pageSize}
                        pageCount={pageCount}
                        totalItems={totalItems}
                        loading={loadingProduct}
                        ref={this.setRefFilter}
                        productGroup={productGroup}
                        loadingProductGroup={loadingProductGroup}
                    />
                    <List
                        {...this.props}
                        dataSource={products}
                        currentPage={currentPage}
                        pageSize={pageSize}
                        pageCount={pageCount}
                        totalItems={totalItems}
                        vendor={vendor}
                        onChangePage={this.onChangePage}
                        onShowSizeChange={this.onShowSizeChange}
                        loadingProduct={loadingProduct}
                        onToggleProductStatus={this.onToggleProductStatus}
                        getProductId={this.props.getProductId}
                        countProduct={this.props.countProduct}
                    />
                </div>
            </StandardLayout>
        );
    }
}

export default (translate())(OriginalProduct)