import React from 'react';
import {Breadcrumb as AntBreadCrumb, Button} from 'antd';
import {BreadCrumb} from "../../../components/BreadCrumb";
import {Link} from "react-router-dom";
import UserService from "../../../services/UserService";
import {REPORT_SCOPES} from "../../../utils/api/Scopes";

class Header extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const {t} = this.props;
        const leftContent = (
            <AntBreadCrumb>
                <AntBreadCrumb.Item>
                    <Link to={"/"}>{t('page.home')}</Link>
                </AntBreadCrumb.Item>
                <AntBreadCrumb.Item>{t("statistics.title")}</AntBreadCrumb.Item>
            </AntBreadCrumb>
        );

        let rightContent;
        if(UserService.isAllowed(REPORT_SCOPES.REPORT_VIEW)) {
            rightContent = (
                <Button.Group>
                    <Button type="primary">
                        <Link to={"/statistics-list"}>{t("statistic.list_title")}</Link>
                    </Button>
                </Button.Group>
            );
        }

        return (
            <BreadCrumb
                {...this.props}
                leftContent={leftContent}
                rightContent={rightContent}
            />
        );
    }
}

export {Header};