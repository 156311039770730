import React, {Component} from 'react';
import {Button, Checkbox, Col, Icon, Pagination, Popover, Row, Table, Tag} from 'antd';
import * as moment from 'moment';
import lodash from 'lodash';
import {Link} from "react-router-dom";
import UserService from "../../services/UserService";
import {CONTACT_SCOPES, ORDER_SCOPES} from "../../utils/api/Scopes";
import {AssignOrder} from "../../components/AssignOrder";
import {objectGet} from "../../utils/helper";

class List extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedRows: 0,
            sorter: {},
            isShowPopupAssignTo: {}
        };
    }

    componentDidMount = () => {

    };

    getConfigRowSelection = () => {
        return {
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({selectedRows: selectedRows.length});
            }
        }
    };

    getColor = (status) => {
        if(status === "NEW"){
            return "cyan"
        }else if(status === "DRAFT"){
            return "green"
        }else if(status === "DELIVERED"){
            return "lime"
        }else if(status === "DELIVERING"){
            return "orange"
        }else if(status === "CANCELLED"){
            return "geekblue"
        }else if(status === "EXCHANGE"){
            return "red"
        }else{
            return null
        }
    };

    getNameById = (id) => {
        for(let i = 0; i < this.props.userCreatedBy.length; i++){
            if(this.props.userCreatedBy[i].id == id){
                return this.props.userCreatedBy[i].username
            }
        }
    };

    togglePopupAssignTo = (order) => {
        let {isShowPopupAssignTo} = this.state;
        if(isShowPopupAssignTo.hasOwnProperty(order.id)) {
            isShowPopupAssignTo[order.id] = !isShowPopupAssignTo[order.id];
        } else {
            isShowPopupAssignTo[order.id] = false;
        }
        this.setState({isShowPopupAssignTo});
    };

    onVisiblePopoverAssignTo = (item, visible) => {
        let isShowPopupAssignTo = {
            [item.id]: visible
        };
        this.setState({isShowPopupAssignTo});
    };

    onAssignOrder = (order) => {
        this.togglePopupAssignTo(order);
    };

    getConfigTableColumns = () => {
        const {isShowPopupAssignTo} = this.state;
        const {t} = this.props;
        const renderColumn = (key, text, record) => {
            switch (key) {
                case 'createdAt':
                    return record.createdAt ? moment(record.createdAt).format("DD/MM/YY HH:mm") : "";
                case 'deliveryDate':
                    return record.deliveryDate ? moment(record.deliveryDate).format("DD/MM/YY HH:mm") : "";
                case "paid":
                    return record.paid ? t("order_list.paid_status") : t("order_list.unpaid_status");
                case "status":
                    return <Tag color={this.getColor(record.status)}>{record.status}</Tag>;
                case "stock_issue":
                    return <span style={{color: "#337ab7"}}>{record.issueId?record.issueId:"none"}</span>;
                case "createdBy":
                    return <span className="pointer _filter-createdBy-table" style={{color: "#337ab7"}} onClick={this.props.onFilterByName.bind(this,record.createdBy)}>{objectGet(record, 'creator.username')}</span>;
                case "assignTo":
                    if(UserService.isAllowed(ORDER_SCOPES.ORDER_ASSIGN)) {
                        let isShowPopup = objectGet(isShowPopupAssignTo, record.id, false);
                        let content = (
                            <AssignOrder
                                {...this.props}
                                fetchGroups={(filter = {}, cb) => {return this.props.fetchGroupsByProductId(record.productId, filter, cb)}}
                                showButtonUnAssign={true}
                                assignOrder={(userId, cb) => {return this.props.assignOrder(record.id, userId, cb)}}
                                onAssignOrder={this.onAssignOrder}
                                fetchOrder={this.props.loadOrder}
                                order={record}
                            />
                        );
                        return (
                            <Popover
                                title={t("order_list.assign_to")}
                                content={content}
                                trigger={["click"]}
                                visible={isShowPopup}
                                onVisibleChange={this.onVisiblePopoverAssignTo.bind(this, record)}
                            >
                                <span>{record.assignTo?record.assignTo:t("order_list.unassign_to")}</span>
                            </Popover>
                        )
                    }
                    return <span>{record.assignTo?record.assignTo:t("order_list.unassign_to")}</span>;
                case "campaign":
                    return <span className="nowrap-css">{lodash.get(record, 'campaign.name', '--')}</span>;
                case "code":
                    return <Link className="nowrap-css" to={`/orders/${record.id}`}>{record.code}</Link>;
                case "contactName":
                    return <span className="nowrap-css">{record.contactName}</span>;
                case "country":
                    return <span className="nowrap-css">{lodash.get(record, 'country.name')}</span>;
            }
        };

        let columns = [
            {title: t("order_list.code"),dataIndex: 'code', key: 'code',sorter: true,sortDirections: ['descend','ascend','descend'],render: renderColumn.bind(this, 'code'),className:"_code _item"},
            {title: t("order_list.campaign"),dataIndex: 'campaign', key: 'campaign',render: renderColumn.bind(this, 'campaign'),className:"_campaign _item"},
            {title: t("order_list.customerName"),dataIndex: 'contactName', key: 'contactName',render: renderColumn.bind(this, 'contactName'),sorter: true,sortDirections: ['descend','ascend','descend'],className:"_contactName _item"},
            {title: t("order_list.customerPhone"),dataIndex: 'contactPhone', key: 'contactPhone',className:"_contactPhone _item"},
            {title: t("order_list.assign_to"),dataIndex: 'assignTo', key: 'assignTo',render: renderColumn.bind(this, 'assignTo'),className:"_assignTo _item"},
            {title: t("order_list.country_column"),dataIndex: 'country', key: 'country', render: renderColumn.bind(this, 'country'), sorter: true,sortDirections: ['descend','ascend','descend'],className:"_countryName _item"},
            {title: t("order_list.province"),dataIndex: 'provinceName', key: 'provinceName',sorter: true,sortDirections: ['descend','ascend','descend'],className:"_provinceName _item"},
            {title: t("order_list.district"),dataIndex: 'cityName', key: 'cityName',sorter: true,sortDirections: ['descend','ascend','descend'],className:"_cityName _item"},
            {title: t("order_list.created_at"),dataIndex: 'createdAt', key: 'createdAt',sorter: true,sortDirections: ['descend','ascend','descend'],render: renderColumn.bind(this, 'createdAt'),className:"_createdAt _item"},
            {title: t("order_list.deliveryDate"),dataIndex: 'deliveryDate', key: 'deliveryDate',sorter: true,sortDirections: ['descend','ascend','descend'], render: renderColumn.bind(this, 'deliveryDate'),className:"_deliveryDate _item"},
            {title: t("order_list.createdBy"),dataIndex: 'createdBy', key: 'createdBy',render: renderColumn.bind(this, 'createdBy'),className:"_createdBy _item"},
            {title: t("order_list.status"),dataIndex: 'status', key: 'status',render: renderColumn.bind(this, 'status'),className:"_status _item"},
            {title: t("order_list.stock_issue"),dataIndex: 'issueId', key: 'issueId',render: renderColumn.bind(this, 'stock_issue'),className:"_issueId _item"},
            {title: t("order_list.payment_status"),dataIndex: 'paid', key: 'paid',render: renderColumn.bind(this, 'paid'),className:"_paid _item"},
        ];

        return columns;
    };

    onCheckBox = (priority) => {
        this.props.isShowCheckbox(priority);
    };

    onChange = (pagination, filters, sorter)=>{
        let sortCondition = sorter.order;
        if(sortCondition === "descend"){
            sortCondition = "DESC";
        }else if(sortCondition === "ascend"){
            sortCondition = "ASC";
        }
        let uri = this.props.getUrlInstance();
        uri.replaceParam('order', `["${sorter.field} ${sortCondition}"]`);
        this.props.pushQueryString(uri.getQueryString());
    };

    handleExport = () => {
        this.props.onExport();
    };

    handleExportM28 = () => {
        this.props.onExportForM28();
    };

    handleExportForCarrierUnit = () => {
        this.props.onExportForCarrierUnit();
    };

    render() {
        const {t, loading} = this.props;
        const {dataSource,currentPage,totalItems,pageSize,onShowSizeChange,onChangePage,pageCount} = this.props;
        const {selectedRows} = this.state;
        let columns = this.getConfigTableColumns();
        let columnsInvisible = [];

        for (let i = 0; i < columns.length; i++) {
            if(this.props.visibleColumnsDisplay.indexOf(columns[i].key) > -1){
                columnsInvisible.push(columns[i]);
            }
        }

        const rowSelection = this.getConfigRowSelection();

        let content = this.props.visibleColumns.map((contact) =>
            <div className={`mg-bt-10 mg-r-10 mg-l-10 ${contact.name} _checkbox-item`} key={contact.priority}>
                <Checkbox onChange={this.onCheckBox.bind(this,contact.priority)} checked={contact.selected}>{t(contact.language)}</Checkbox>
                <br />
            </div>
        );

        return (
            <div className="list-container">
                <Row className="pd-bt-15">
                    <Col span={5}>
                        {!loading && !!totalItems && (
                            <span>{t("contact.page")} {currentPage}/{pageCount} ({totalItems})</span>
                        )}

                        {!loading && !totalItems && (
                            <span>{t("contact.page")} {1}/{1} ({totalItems})</span>
                        )}
                    </Col>
                    <Col span={19}>
                        <div className="flr">
                            {UserService.isAllowed(CONTACT_SCOPES.CONTACT_EXPORT_ORDER) && (
                                <Button
                                    loading={loading}
                                    disabled={loading}
                                    className={'mg-r-10'}
                                    type={'primary'}
                                    icon={'cloud-download'}
                                    onClick={this.handleExport}
                                >
                                    {t('contact.export_order')}
                                </Button>
                            )}

                            {UserService.isAllowed(ORDER_SCOPES.ORDER_EXPORT_FINANCIAL) && (
                                <Button
                                    className={'mg-r-10'}
                                    type={'primary'}
                                    icon={'cloud-download'}
                                    loading={loading}
                                    disabled={loading}
                                    onClick={this.props.onExportForFinancial}
                                >
                                    Export for financial
                                </Button>
                            )}

                            {UserService.isAllowed(ORDER_SCOPES.ORDER_EXPORT_M28) && (
                                <Button
                                    loading={loading}
                                    disabled={loading}
                                    className={'mg-r-10'}
                                    type={'primary'}
                                    icon={'cloud-download'}
                                    onClick={this.handleExportM28}
                                >
                                    {t('contact.export_order')} M28
                                </Button>
                            )}

                            {UserService.isAllowed(ORDER_SCOPES.ORDER_EXPORT_PICKED_UP) && (
                                <Button
                                    loading={loading}
                                    disabled={loading}
                                    className={'mg-r-10'}
                                    type={'primary'}
                                    icon={'cloud-download'}
                                    onClick={this.handleExportForCarrierUnit}
                                >
                                    {t('order.export_order_for_carrier_unit')}
                                </Button>
                            )}

                            <Popover placement="bottomLeft" trigger="click" content={content}><Icon type="setting" className="pointer" /></Popover>
                        </div>
                    </Col>
                </Row>

                <div className="a-table a-table--responsive">
                    <Table
                        columns={columnsInvisible}
                        dataSource={dataSource}
                        rowKey={record => record.id}
                        pagination={false}
                        rowSelection={rowSelection}
                        loading={loading}
                        onChange={this.onChange}
                        locale={{
                            emptyText: t("table.empty")
                        }}
                    />
                </div>

                {!loading && totalItems > 0 && (
                    <Pagination
                        className="mg-t-15 text-r"
                        showSizeChanger
                        onShowSizeChange={onShowSizeChange}
                        onChange={onChangePage}
                        pageSizeOptions={['50', '100', '150', '200']}
                        defaultCurrent={1}
                        current={currentPage}
                        pageSize={pageSize}
                        total={totalItems}
                    />
                )}
            </div>
        );
    }
}

export {List}