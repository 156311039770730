import React, {Component} from 'react';
import {Select} from 'antd';
import lodash from 'lodash';
import CampaignService from "../../services/CampaignService";

class SelectCampaign extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: undefined,
            loading: false,
            campaigns: [],
            extraCampaigns: []
        }
    }

    static getDerivedStateFromProps(props, state) {
        let newState = {...state};
        if ('value' in props) {
            newState.value = props.value;
        }

        return newState;
    }

    componentDidMount() {
        if ('value' in this.props && this.props.value > 0) {
            this.setState({loading: true});
            CampaignService.suggest({
                ids: this.props.value
            }, (error, response) => {
                this.setState({loading: false});
                if(!error) {
                    this.setState({
                        extraCampaigns: response.list
                    });
                }
            });
        }

        this.fetchCampaigns();
    }

    fetchCampaigns = (filter = {}, cb) => {
        this.setState({loading: true});
        let defaultFilter = {page_size: 20};
        defaultFilter = {
            ...defaultFilter,
            ...filter
        };
        CampaignService.suggest(defaultFilter, (error, response) => {
            this.setState({loading: false});
            if(!error) {
                this.setState({
                    campaigns: response.list
                });
            }
            if(typeof cb === 'function') cb(error, response);
        });
    };

    handleChange = (values) => {
        this.setState({value: values});
        this.props.onChange(values);
    };

    handleSearch = (value) => {
        if (value.toString().length >= 3) {
            this.fetchCampaigns({
                name: value
            })
        }
    };

    render() {
        const {loading, value, extraCampaigns} = this.state;
        let {campaigns} = this.state;
        if (extraCampaigns.length) {
            campaigns = lodash.unionBy(campaigns, extraCampaigns, 'id');
        }

        const {t} = this.props;
        return (
            <Select
                allowClear={true}
                loading={loading}
                value={value}
                onChange={this.handleChange}
                className={'width-100pc'}
                placeholder={t("contact.select_campaign")}
                filterOption={false}
                showSearch={true}
                onSearch={this.handleSearch}
            >
                {campaigns.map(item => (
                    <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                ))}
            </Select>
        );
    }
}

SelectCampaign.defaultProps = {
    onChange: (values) => {}
};

export default SelectCampaign;

