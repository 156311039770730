import React, {Component} from 'react';
import {Filter} from "./Filter";
import {List} from "./List";
import withLocationFilter from "../../../utils/withLocationFilter";

class ReturnLead extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };
    }

    render() {
        return (
            <div className="contact-list-page list-page">
                <Filter {...this.props} />
                <List {...this.props} />
            </div>
        );
    }
}

export default withLocationFilter(ReturnLead)