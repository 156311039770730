import React from 'react';
import {Row, Col, Input, Select, Icon, Button, Spin, Empty} from 'antd';
import * as _ from "lodash";
import {getParamsAsObject, getUrlQueryString} from "../../utils/helper";
import * as moment from "moment/moment";

class Filter extends React.Component {

    constructor(props) {
        super(props);
        this.mounted = false;
        this.state = {
            name: "",
            createdBy: []
        };
        this.onSearchUser = _.debounce(this.onSearchUser, 400);
    }

    onPopstate = () => {
        if(this.mounted) {
            this.setStateFromQueryString();
        }
    };

    setStateFromQueryString = () => {
        let queryObject = getParamsAsObject(getUrlQueryString());
        this.resetStateFilter(() => {
            _.forEach(queryObject, (v, k) => {
                if (v) {
                    if(k === 'name') {
                        this.setState({
                            name: v
                        });
                    }
                    if(k === 'created_by') {
                        let arr = v.toString().split(',');
                        this.setState({
                            createdBy: arr
                        });
                    }
                }
            });
        });
    };

    resetStateFilter = (cb = null) => {
        this.setState({
            name: "",
            createdBy: []
        }, cb)
    };

    componentWillUnmount() {
        this.mounted = false;
        window.removeEventListener("popstate", this.onPopstate.bind(this), false);
    }

    componentDidMount = () => {
        this.mounted = true;
        window.addEventListener("popstate", this.onPopstate.bind(this), false);
        this.setStateFromQueryString();
    };

    onSelectChange = (key, value) => {
        this.setState({
            [key]: value
        });
    };

    onFilter = () => {
        let uri = this.props.getUrlInstance(true);
        uri.replaceParam('name', this.state.name);
        uri.replaceParam('createdBy', this.state.createdBy.join(','));
        uri.replaceParam('page', 1);
        uri.replaceParam('filter', true);

        this.props.pushQueryString(uri.getQueryString());
    };

    clearFilter = () => {
        this.resetStateFilter(() => {
            this.props.pushQueryString("")
        });
    };

    onSearchUser = (value) => {
        const {fetchUsers} = this.props;
        fetchUsers({username: value});
    };

    onChangeInput = (key, e) => {
        this.setState({
            [key]: e.target.value
        });
    };

    render() {
        const {t, users, loadingCreatedBy} = this.props;
        const {name, createdBy} = this.state;
        const optionInputSearch = {
            style: {width: '100%'},
            placeholder: t('group_user.search'),
            value: name,
            suffix: <Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />,
            onChange: this.onChangeInput.bind(this, 'name'),
            onPressEnter: this.onFilter
        };
        const optionInputCreatedBy = {
            style: {width: '100%'},
            value: createdBy,
            filterOption: false,
            defaultActiveFirst: false,
            placeholder: t('group_user.created_by'),
            notFoundContent: loadingCreatedBy ? <Spin size="small" /> : <Empty description={t("table.empty")} />,
            mode: "multiple",
            onChange: this.onSelectChange.bind(this, 'createdBy'),
            onSearch: this.onSearchUser,
            onFocus: () => {this.props.resetData('users')}
        };
        return (
            <div className="group-user-filter filter">
                <Row gutter={20}>
                    <Col span={6}>
                        <Input
                            {...optionInputSearch}
                        />
                    </Col>
                    <Col span={6}>
                        <Select
                            {...optionInputCreatedBy}
                        >
                            {Array.isArray(users) && users.map((item) => (
                                <Select.Option key={item.id} value={item.username}>{item.username}</Select.Option>
                            ))}
                        </Select>
                    </Col>
                    <Col span={12} className="form-filter-control filter-button">
                        <Button type="primary" className="mg-r-10 _filter_button_filter" onClick={this.onFilter}>{t("button.filter")}</Button>
                        <Button type="default" className="mg-r-10 _filter_button_clear" onClick={this.clearFilter}>{t("contact.clear")}</Button>
                    </Col>
                </Row>
            </div>
        )
    }
}

export {Filter};