import React, {Component} from 'react';
import {Button, Checkbox, Col, Divider, Icon, notification, Pagination, Popover, Row, Table, Tag, Tooltip} from 'antd';
import * as moment from 'moment';
import {doCallBack, getParamsAsObject, getUrlQueryString} from "../../../utils/helper";
import UserService from "../../../services/UserService";
import {CONTACT_SCOPES} from "../../../utils/api/Scopes";

class List extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedRows: 0,
            sorter: {},
            loadingRequestContact: false,
            isShowPopupAssignTo: {},
            groups: [],
            isShowPopupAutoAssign: false,
            loadingAutoAssign: false
        };
    }

    componentDidMount = () => {

    };

    getContactStatus = (id) => {
        let {contactStatuses} = this.props;
        for(let item of contactStatuses) {
            if(item.id === id){
                return item
            }
        }
        return null;
    };

    getCallStatus = (id) => {
        let {callStatuses} = this.props;
        for(let item of callStatuses) {
            if(item.id === id){
                return item
            }
        }
        return null;
    };

    getConfigRowSelection = () => {
        return {
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({selectedRows: selectedRows.length});
            }
        }
    };

    checkAppointmentAt = (appointmentAt) => {
        const {t} = this.props;
        if(appointmentAt){
            let timeEnd = moment(appointmentAt).toDate().valueOf();
            let timeNow = new Date().valueOf();
            if(timeEnd < timeNow){
                return {content:t("contact.contact_expired"),color:"#f5222d"};
            }else{
                let contactTime = moment(appointmentAt).format("DD/MM/YY HH:mm");
                return {content:`${t("contact.appointment")} ${contactTime}`,color:"#1890ff"};
            }
        }else{
            return {content:t("contact.new_contact"),color:"#52c41a"};
        }
    };

    onAssignContact = (contact) => {
        this.setState({
            isShowPopupAssignTo: {
                [contact.id]: false
            }
        });
        let queryObject = getParamsAsObject(getUrlQueryString());
        this.props.loadContact(queryObject);
    };

    getConfigTableColumns = () => {
        const {t} = this.props;
        const renderColumn = (key, text, record) => {
            switch (key) {
                case 'assignTo':
                    return record.user ? record.user.username: t("contact.unassign_to");
                case 'assignedBy':
                    return record.assignedBy ? record.assignedBy: "";
                case 'assignedAt':
                    return record.assignedAt ? moment(record.assignedAt).format("DD/MM/YY HH:mm") : "";
                case 'createdAt':
                    return record.createdAt ? moment(record.createdAt).format("DD/MM/YY HH:mm") : "";
                case 'campaignId':
                    return record.campaign ? <span className="campaign-name">{record.campaign.name}</span>: "";
                case "contactStatusId":
                    return this.getContactStatus(record.contactStatusId) ? this.getContactStatus(record.contactStatusId).key : "";
                case "callStatusId":
                    return this.getCallStatus(record.callStatusId) ? <Tag color={this.getCallStatus(record.callStatusId).color}>{this.getCallStatus(record.callStatusId).value}</Tag>: <Tag color={"cyan"}>New</Tag>;
                case 'deliveryDate':
                    return record.deliveryDate ? moment(record.deliveryDate).format("DD/MM/YY HH:mm") : "";
                case 'callTime':
                    return record.callTime ? moment(record.callTime).format("DD/MM/YY HH:mm") : "";
                case 'customerName':
                    let appointmentAt = this.checkAppointmentAt(record.appointmentAt);
                    return record.customerName ?
                        <span>
                            <Tooltip className="pointer" title={appointmentAt.content}>
                                <span className="contact-date" style={{background:appointmentAt.color}}/>
                            </Tooltip>
                            <span className="name-contact blue-link pointer bold" onClick={this.redirectToContactDetail.bind(this, record)}>
                                {record.customerName}
                            </span>
                        </span>  : "";
                case 'note':
                    return <span className="note">{record.note}</span>;

            }
        };

        let sortOrder = {};
        let queryObject = getParamsAsObject(getUrlQueryString());
        if(queryObject.order) {
            let order = JSON.parse(queryObject.order);
            if(Array.isArray(order)) {
                for(let cond of order) {
                    let condArr = cond.split(' ');
                    let field = condArr[0];
                    let type = condArr[1];
                    sortOrder[field] = type === 'ASC' ? 'ascend' : 'descend';
                }
            }
        }

        let columns = [
            {title: t("contact.fullname"),dataIndex: 'customerName', key: 'customerName',sorter: true,sortDirections: ['descend','ascend','descend'],render: renderColumn.bind(this, 'customerName'),className:"_customerName _item"},
            {title: t("contact.campaign"),dataIndex: 'campaignId', key: 'campaignId', render: renderColumn.bind(this, 'campaignId'),className:"_campaignId _item"},
            {title: t("contact.created_at"),dataIndex: 'createdAt', key: 'createdAt', render: renderColumn.bind(this, 'createdAt'),sorter: (a, b) => moment(a.createdAt) - moment(b.createdAt), sortDirections: ['ascend','descend','ascend'],className:"_createdAt _item"},
            {title: t("contact.phone"),dataIndex: 'customerPhone', key: 'customerPhone',className:"_customerPhone _item"},
            {title: t("contact.assign_to"),dataIndex: 'assignTo', key: 'assignTo', render: renderColumn.bind(this, 'assignTo'),className:"_assignTo _item"},
            {title: t("contact.assigned_at"),dataIndex: 'assignedAt', key: 'assignedAt', render: renderColumn.bind(this, 'assignedAt'),className:"_assignedAt _item"},
            {title: t("contact.assigned_by"),dataIndex: 'assignedBy', key: 'assignedBy', render: renderColumn.bind(this, 'assignedBy'),className:"_assignedBy _item"},
            {title: ()=> {return <Tooltip className="pointer" title={this.getListCallStatus}>{t("contact.stage")} <Icon type="info-circle" /></Tooltip>},dataIndex: 'contactStatusId', key: 'contactStatusId', render: renderColumn.bind(this, 'contactStatusId'),className:"_contactStatus _item"},
            {title: t("contact.calls"),dataIndex: 'totalCall', key: 'totalCall',sorter: true, sortDirections: ['descend','ascend','descend'],className:"_totalCall _item"},
            {title: t("contact.status"),dataIndex: 'callStatusId', key: 'callStatusId', render: renderColumn.bind(this, 'callStatusId'),className:"_callStatus _item"},
            {title: t("contact.callTime"),dataIndex: 'callTime', key: 'callTime',sorter: true, sortDirections: ['descend','ascend','descend'],render: renderColumn.bind(this, 'callTime'),className:"_callTime _item"},
            {title: t("contact.deliveryDate"),dataIndex: 'deliveryDate', key: 'deliveryDate', render: renderColumn.bind(this, 'deliveryDate'),className:"_deliveryDate _item"},
            {title: t("contact.note"),dataIndex: 'note', key: 'note',render: renderColumn.bind(this, 'note'),className:"_note _item"},
        ];

        for(let column of columns) {
            if(sortOrder.hasOwnProperty(column.key)) {
                column.sortOrder = sortOrder[column.key];
            }
        }

        return columns;
    };

    onCheckBox = (priority) => {
        this.props.isShowCheckbox(priority);
    };

    getListCallStatus = () => {
        return this.props.contactStatuses.map((list) =>
            <div className="gutter-example" key={list.key}>
                <Row>
                    <Col className="gutter-row" span={6}>
                        <div className="gutter-box">{list.key}</div>
                    </Col>
                    <Col className="gutter-row" span={18}>
                        <div className="gutter-box">{list.value}</div>
                    </Col>
                </Row>
            </div>
        );
    };

    onChange = (pagination, filters, sorter)=>{
        let sortCondition = sorter.order;
        if(sortCondition === "descend"){
            sortCondition = "DESC";
        }else if(sortCondition === "ascend"){
            sortCondition = "ASC";
        }
        let uri = this.props.getUrlInstance();
        uri.replaceParam('order', `["${sorter.field} ${sortCondition}"]`);
        this.props.pushQueryString(uri.getQueryString());
    };

    requestContact = (cb) => {
        const {t} = this.props;
        this.setState({loadingRequestContact: true});
        let data = {};
        this.props.requestContact(data, (error, response) => {
            this.setState({
                loadingRequestContact: false
            });
            if(!error) {
                if(response.count > 0){
                    if(response.count < response.setting.maxContactReceivePerRequest) {
                        notification.success({
                            message: `${t('contact.get_contact_success')} ${response.count} contact, ${t('contact.get_contact_messenger')} ${response.count} contact`
                        });
                    } else {
                        notification.success({
                            message: `${t('contact.you_have_got')} ${response.count} ${t('contact.new_contact')}`
                        });
                    }

                    if(this.props.history.location.search){
                        this.props.history.replace('/contacts');
                    }else{
                        this.props.loadContact({})
                    }

                } else {
                    notification.error({
                        message: t('contact.max_contact')
                    });
                }
            } else {
                if(error.code === 'USER_NOT_IN_ANY_PRODUCT') {
                    notification.error({
                        message: t('contact.permissiong_product')
                    });
                }else if(error.code === 'CALL_TO_CONTACT_REQUIRED'){
                    notification.error({
                        message: t('contact.many_contact')
                    });
                }else{
                    notification.error({
                        message: error.message
                    });
                }
            }
            doCallBack(cb, [error, response]);
        })
    };

    redirectToContactDetail = (contact) => {
        const url = `/contacts/${contact.id}`;
        this.props.history.push(url);
    };

    render() {
        const {loadingRequestContact} = this.state;
        const {t, loading} = this.props;
        const {dataSource,currentPage,totalItems,pageSize,onShowSizeChange,onChangePage,pageCount} = this.props;
        let columns = this.getConfigTableColumns();
        let columnsInvisible = [];

        for (let i = 0; i < columns.length; i++) {
            if(this.props.visibleColumnsDisplay.indexOf(columns[i].key) > -1){
                columnsInvisible.push(columns[i]);
            }
        }

        const rowSelection = this.getConfigRowSelection();

        let content = this.props.visibleColumns.map((contact) =>
            <div className={`mg-bt-10 mg-r-10 mg-l-10 ${contact.name} _checkbox-item`} key={contact.priority}>
                <Checkbox onChange={this.onCheckBox.bind(this,contact.priority)} checked={contact.selected}>{t(contact.language)}</Checkbox>
                <br />
            </div>
        );

        return (
            <div className="list-container">
                <Row className="pd-bt-15">
                    <Col span={5}>
                        {!loading && !!totalItems && (
                            <span>{t("contact.page")} {currentPage}/{pageCount} ({totalItems})</span>
                        )}

                        {!loading && !totalItems && (
                            <span>{t("contact.page")} {1}/{1} ({totalItems})</span>
                        )}
                    </Col>
                    <Col span={19}>
                        <div className="flr">
                            {UserService.isAllowed(CONTACT_SCOPES.CONTACT_GET) && (
                                <>
                                    <Button
                                        type="primary"
                                        className="mg-r-5"
                                        onClick={this.requestContact}
                                        loading={loadingRequestContact}
                                        disabled={loadingRequestContact}
                                    >
                                        {t('contact.get_contact')}
                                    </Button>
                                    <Divider type="vertical" className="mg-r-10" />
                                </>
                            )}

                            <Popover placement="bottomLeft" trigger="click" content={content}><Icon type="setting" className="pointer" /></Popover>
                        </div>
                    </Col>
                </Row>

                <div className="a-table a-table--responsive">
                    <Table
                        columns={columnsInvisible}
                        dataSource={dataSource}
                        rowKey={record => record.id}
                        pagination={false}
                        rowSelection={rowSelection}
                        loading={loading}
                        onChange={this.onChange}
                        locale={{
                            emptyText: t("table.empty")
                        }}
                    />
                </div>

                {!loading && totalItems > 0 && (
                    <Pagination
                        className="mg-t-15 text-r"
                        showSizeChanger
                        onShowSizeChange={onShowSizeChange}
                        onChange={onChangePage}
                        pageSizeOptions={['50', '100', '150', '200']}
                        defaultCurrent={1}
                        current={currentPage}
                        pageSize={pageSize}
                        total={totalItems}
                    />
                )}
            </div>
        );
    }
}

export {List}
