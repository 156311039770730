import React, {Component} from 'react';
import {Select} from 'antd';
import VendorService from "../../services/VendorService";

class SelectVendor extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: undefined,
            vendors: []
        }
    }

    static getDerivedStateFromProps(props, state) {
        let newState = {...state};
        if ('value' in props) {
            newState.value = props.value;
        }

        if ('items' in props) {
            newState.vendors = [...props.items];
        }

        return newState;
    }

    componentDidMount() {
        this.fetchVendors({
            ...this.props.filter
        });
    }

    fetchVendors = (filter = {}) => {
        this.setState({loading: true});
        filter = {
            ...filter,
            me_in: true,
            page_size: 1000
        };

        VendorService.getVendorList(filter, (error, response) => {
            this.setState({loading: false});
            if (!error) {
                this.setState({
                    vendors: response.list
                });
            }
        });
    };

    handleChange = (values) => {
        this.setState({value: values});
        this.props.onChange(values);
    };

    render() {
        const {vendors, loading, value} = this.state;
        const {t, selectProps} = this.props;
        return (
            <Select
                allowClear={true}
                loading={loading}
                value={value}
                onChange={this.handleChange}
                className={'width-100pc'}
                placeholder={t("telesale.select_vendor")}
                {...selectProps}
            >
                {vendors.map(vendor => (
                    <Select.Option key={vendor.id} value={vendor.id.toString()}>{vendor.name}</Select.Option>
                ))}
            </Select>
        );
    }
}

SelectVendor.defaultProps = {
    filter: {},
    selectProps: {},
    onChange: (values) => {}
};

export default SelectVendor;

