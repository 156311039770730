import React, {Component} from 'react';
import {Button, Popover, Select, Table, Popconfirm, Empty} from "antd";
import * as moment from "moment/moment";
import * as _ from 'lodash';

const Option = Select.Option;
class TabPermission extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isShowPopup: false,
            isShowPopupDelete: false,
            selectedRowKeys: [],
            selectedRows: []
        }
    }

    componentDidUpdate = (prevProps) => {
        if(JSON.stringify(prevProps.groupsSelected) !== JSON.stringify(this.props.groupsSelected)) {
            if(!this.props.groupsSelected.length) {
                this.setState({
                    selectedRowKeys: [],
                    selectedRows: []
                });
            }
        }
    };

    onChangeGroup = (value) => {
        this.setState({
            isShowPopup: false
        }, () => {
            this.props.onChangeGroup(value);
        })
    };

    onSearchGroup = (value) => {
        this.props.onSearchGroup(value);
    };

    onVisibleChange = (visible) => {
        this.setState({
            isShowPopup: visible
        }, () => {
            if(visible) {
                this.onSearchGroup("");
            }
        });
    };

    getConfigRowSelection = () => {
        return {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({selectedRowKeys, selectedRows});
            }
        }
    };

    onRemoveGroups = () => {
        let {selectedRows, selectedRowKeys} = this.state;
        this.props.onRemoveGroups(selectedRows);

        selectedRows = _.filter(selectedRows, (item) => {
            return selectedRowKeys.indexOf(item.id) < 0;
        });

        selectedRowKeys = _.filter(selectedRows, (item) => {
            return selectedRowKeys.indexOf(item.id) < 0;
        });
        this.setState({selectedRows, selectedRowKeys});
    };

    isShowGroup = (item) => {
        let {groupsSelected} = this.props;
        for(let group of groupsSelected) {
            if(group.id == item.id) {
                return false;
            }
        }
        return true;
    };

    isShowButtonAddGroup = () => {
        let {initialGroups} = this.props;
        return initialGroups.length > 0;
    };

    onPopconfirmChange = (visible) => {
        let {selectedRows} = this.state;
        if(selectedRows.length) {
            this.setState({
                isShowPopupDelete: visible
            })
        }
    };

    render() {
        const {isShowPopup, isShowPopupDelete, selectedRows} = this.state;
        const {groups, groupsSelected, t} = this.props;
        let columns = [
            {title: t("products.group_id"), key: "id", dataIndex: "id"},
            {title: t("products.group_name"), key: "name", dataIndex: "name"},
            {title: t("products.added_by"), key: "created_by", dataIndex: "created_by"},
            {title: t("products.added_at"), key: "created_at", dataIndex: "created_at", render: (text, record) => {
                return (<span>{record.created_at ? moment(record.created_at).format("DD/MM/YY HH:mm") : ""}</span>);
            }},
        ];
        let renderOptions = () => {
            let rs = [];
            for(let item of groups) {
                if(this.isShowGroup(item)) {
                    rs.push(<Option key={item.id} value={JSON.stringify(item)}>{item.name}</Option>);
                }
            }
            return rs;
        };
        let content = (
            <Select
                style={{width: 200}}
                placeholder={t("products.select_group")}
                notFoundContent={<Empty description={t("table.empty")} />}
                onChange={this.onChangeGroup}
                onSearch={this.onSearchGroup}
                showSearch={true}
                value={[]}
            >
                {renderOptions()}
            </Select>
        );

        return (
            <div className="permission-container">
                <div className="mg-bt-20">
                    <div className="flr">
                        {this.isShowButtonAddGroup() && (
                            <Popover placement="right" onVisibleChange={this.onVisibleChange} content={content} trigger="click" visible={isShowPopup}>
                                <Button type="primary" className="_btn_add_group mg-r-10">{t("products.add_group")}</Button>
                            </Popover>
                        )}
                        <Popconfirm
                            onVisibleChange={this.onPopconfirmChange}
                            onConfirm={() => {this.onRemoveGroups()}}
                            visible={isShowPopupDelete}
                            title={t("group_user.confirm_delete")}
                            okText={t("popup-confirm.confirm")}
                            cancelText={t("popup-confirm.cancel")}>
                            <Button
                                type="danger"
                                icon="delete"
                                className="_btn_remove_group"
                                disabled={!selectedRows.length}
                            />
                        </Popconfirm>
                    </div>
                    <div className="clearfix" />
                </div>

                <Table
                    dataSource={groupsSelected}
                    columns={columns}
                    pagination={false}
                    rowKey={(record) => record.id}
                    locale={{
                        emptyText: t("table.empty")
                    }}
                    rowSelection={this.getConfigRowSelection()}
                />
            </div>
        )
    }
}

export {TabPermission};