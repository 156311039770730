import React, {Component} from 'react';
import { Col, Input, message, Pagination, Row, Table, Icon, Button, notification } from "antd";
import moment from "moment";
import StringeeService from "../../../services/StringeeService";
import { LocalStore } from "../../../utils/LocalStore";

class HistoryCall extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            historyCalls: [],
            email: '',
            total: 0,
            pageSize: 100,
            currentPage: 1,
            accessToken: ''
        }
    }

    componentDidMount() {
        this.fetchHistoryCall();
    }

    componentDidUpdate() {

    }

    getConfigTableColumns = () => {
        const {t} = this.props
        return [
            { title: 'Nhân viên', dataIndex: 'username', key: 'username' },
            {
                title: 'Thời gian thực hiện cuộc gọi',
                key: 'timeStartCall',
                render: (row) => {
                    return moment(row.timestamp_ms).format("DD/MM/YYYY HH:mm:ss")
                }
            },
            {
                title: t('stringee.call_status'),
                key: 'statusCall',
                render: (row) => {
                    return t(`stringee.call_status_${row.call_status}`) || ''
                }
            },
            {
                title: 'Ghi âm',
                key: 'recorded',
                render: (row) => {
                    if (row.call_status === 'answered') {
                        const accessToken = LocalStore.getInstance().read('stringeeAccessToken')
                        const urlSource = `https://api.stringee.com/v1/call/play/${row.call_id}?access_token=${accessToken}`
                        return (
                            <>
                                <audio controls>
                                    <source src={urlSource} type="audio/mp3" />
                                </audio>
                            </>
                        )
                    } else {
                        return (
                            <>
                                <span>Không có ghi âm</span>
                            </>
                        )
                    }
                }
            },
            {
                title: 'Ngày tạo',
                key: 'createdAt',
                render: (row) => {
                    return moment(row.timestamp_ms).format("DD/MM/YYYY")
                }
            }
        ];
    };

    fetchHistoryCall = async (currentPage, pageSize, username = '') => {
        this.setState({
            loading: true
        })
        try {
            const {contact} = this.props;
            const result = await StringeeService.getHistoryCall(currentPage || this.state.currentPage, pageSize || this.state.pageSize, contact.standardPhoneNumber, contact.campaign.code, username, contact.vendor);
            const resultJson = await result.json();
            if (result.ok) {
                this.setState({
                    historyCalls: resultJson.data,
                    total: resultJson.metadata.total,
                    loading: false,
                    accessToken: resultJson.accessToken
                })
                notification.success({
                    message: resultJson.message
                })
            } else {
                notification.error({
                    message: resultJson.message
                })
            }
            this.setState({
                loading: false
            })
        } catch (error) {
            message.error(error.message);
            this.setState({
                loading: false,
                historyCalls: []
            })
        }
    }

    handlePageChange = (page, pageSize) => {
        this.fetchHistoryCall(page, pageSize);
        this.setState({
            currentPage: page,
            pageSize: pageSize
        })
    }

    clearFilter = () => {
        this.setState({
            email:''
        })
        this.fetchHistoryCall(this.state.currentPage, this.state.pageSize)
    }

    onFilter = () => {
        this.fetchHistoryCall(this.state.currentPage, this.state.pageSize, this.state.email)
    }

    handleOnchange = (event) => {
        this.setState({
            email:event.target.value
        })
    }

    render() {
        const { loading, total, currentPage, pageSize, historyCalls } = this.state;
        const { t } = this.props;

        return (
            <div className="list-container">
                <div className="group-user-filter filter mg-bt-30">
                    <Row gutter={20} className="form-filter">
                        <Col span={12} lg={16} xl={12} className="form-filter-control">
                            <label>{'Username nhân viên'}:</label>
                            <Input
                                value={this.state.email}
                                onChange={this.handleOnchange}
                            />
                        </Col>
                    <Col span={6} lg={8} xl={6} className="form-filter-control filter-button flr text-right">
                        <label>&nbsp;</label>
                        <div>
                            <span className="mg-r-10 pointer _filter_button_clear" onClick={this.clearFilter}>
                                <Icon type="sync" /> {t("contact.clear")}
                            </span>
                            <Button type="primary" className="_filter_button_filter" onClick={this.onFilter}>
                                {t("button.filter")}
                            </Button>
                        </div>
                    </Col>
                    </Row>
                </div>
                <div className="a-table a-table--responsive">
                    <Table
                        columns={this.getConfigTableColumns()}
                        dataSource={historyCalls}
                        rowKey={record => record.id}
                        pagination={false}
                        loading={loading}
                        locale={{
                            emptyText: t("table.empty")
                        }}
                    />
                </div>

                {!loading && total > 0 && (
                    <div className={'overflow-hidden'}>
                        <Pagination
                            size={'small'}
                            className="mg-t-15 text-r flr"
                            showSizeChanger
                            onShowSizeChange={this.handlePageChange}
                            onChange={this.handlePageChange}
                            pageSizeOptions={['50', '100', '150', '200']}
                            defaultCurrent={1}
                            current={currentPage}
                            pageSize={pageSize}
                            total={total}
                        />
                    </div>
                )}
            </div>
        )
    }
}

export { HistoryCall }