import EagleClient from "../utils/api/EagleClient";

export default class DeliveryCaseService {
    static getListDeliveryCase(filter={}, cb) {
        EagleClient.request({
            endpoint: '/DeliveryCases',
            method: 'GET',
            queryString: filter
        }, cb)
    }

    static getListReason(cb) {
        EagleClient.request({
            endpoint: 'DeliveryCaseReasons',
            method: 'GET'
        }, cb)
    }

    static updateDeliveryCase(deliveryCase, cb) {
        EagleClient.request({
            endpoint: `/DeliveryCases/${deliveryCase.id}`,
            method: 'PATCH',
            body: deliveryCase
        }, cb)
    }

	static getDeliveryCaseAssignees(filter = {}, cb) {
		EagleClient.request({
			endpoint: `/DeliveryCaseAssignees/autocomplete`,
			method: 'GET',
			queryString: filter
		}, cb)
	}

	static export(filter = {}, cb) {
		EagleClient.request({
			endpoint: `/DeliveryCases/export`,
			method: 'POST',
			body: filter
		}, cb)
	}
}
