import React, {Component} from 'react';
import {Button, Popover, Select, Table, Popconfirm, Empty, notification, Modal} from "antd";
import * as _ from 'lodash';
import {formatDate2} from "../../../utils/helper";
import VendorService from "../../../services/VendorService";
import ProductService from "../../../services/ProductService";

const Option = Select.Option;
class TabVendor extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            isShowPopup: false,
            isShowPopupDelete: false,
            selectedRowKeys: [],
            selectedRows: [],
            vendors: [],
            vendorsSelected: [],
            currentPage: 1,
            totalItems: 0,
            pageSize: 25,
            pageCount: 1
        }
    }

    componentDidMount() {
        this.fetchProductVendors();
    }

    componentDidUpdate = (prevProps) => {
        if(JSON.stringify(prevProps.groupsSelected) !== JSON.stringify(this.props.groupsSelected)) {
            if(!this.props.groupsSelected.length) {
                this.setState({
                    selectedRowKeys: [],
                    selectedRows: []
                });
            }
        }
    };

    fetchProductVendors = () => {
        const {productId} = this.props;
        this.setState({loading: true});
        ProductService.getVendors(productId, {}, (error, response) => {
            this.setState({loading: false});
            if (!error) {
                this.setState({vendorsSelected: response})
            }
        });
    };

    fetchVendors = (filter = {}) => {
        this.setState({
            loading: true
        });

        let defaultFilter = {
            include: ['country'],
            order: ["createdAt DESC"],
            page: filter.page || 1,
            page_size: filter.page_size || 25
        };

        if(Object.keys(filter).length) {
            defaultFilter = Object.assign(defaultFilter, filter);
        }

        VendorService.getVendorList(defaultFilter, (error, response) => {
            this.setState({loading: false});
            if(!error) {
                this.setState({
                    vendors: response.list,
                    currentPage: response.metadata.currentPage,
                    totalItems: response.count,
                    pageSize: response.metadata.pageSize,
                    pageCount: response.metadata.pageCount
                })
            }
        });
    };

    getConfigRowSelection = () => {
        return {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({selectedRows, selectedRowKeys});
            }
        }
    };

    onVisibleChange = (visible) => {
        this.setState({
            isShowPopup: visible
        }, () => {
            if(visible) {
                this.fetchVendors();
            }
        });
    };

    isShowVendor = (item) => {
        let {vendorsSelected} = this.state;
        for(let vendor of vendorsSelected) {
            if(vendor.id == item.id) {
                return false;
            }
        }
        return true;
    };

    onPopconfirmChange = (visible) => {
        let {selectedRows} = this.state;
        if(selectedRows.length) {
            this.setState({
                isShowPopupDelete: visible
            });
        }
    };

    onRemoveVendors = () => {
        const {t, productId} = this.props;
        let {selectedRows, selectedRowKeys} = this.state;

        this.setState({loading: true});

        ProductService.removeVendors(productId, selectedRowKeys, (error) => {
            this.setState({loading: false});
            if (!error) {
                notification.success({
                    message: t("products.remove_vendor_success")
                });

                selectedRows = _.filter(selectedRows, (item) => {
                    return selectedRowKeys.indexOf(item.id) < 0;
                });

                selectedRowKeys = _.filter(selectedRows, (item) => {
                    return selectedRowKeys.indexOf(item.id) < 0;
                });
                this.setState({selectedRows, selectedRowKeys});

                this.fetchProductVendors();
            } else {
                notification.error({
                    message: error.message
                })
            }
        })
    };

    handleSelectVendor = (value) => {
        const vendor = JSON.parse(value);
        const {productId, t} = this.props;

        this.setState({loading: true});
        ProductService.addVendors(productId, [vendor.id], (error) => {
            this.setState({loading: false});

            if (!error) {
                notification.success({
                    message: t("products.add_vendor_success")
                });

                this.fetchProductVendors();
            }
        })
    };

    handleSearchVendor = (value) => {
        if (value.toString().length >= 3) {
            this.fetchVendors({
                keyword: value
            });
        } else if (value.toString().length === 0) {
            this.fetchVendors({});
        }
    }

    render() {
        const {vendors, vendorsSelected, isShowPopup, isShowPopupDelete, selectedRows, loading} = this.state;
        const {t} = this.props;

        let columns = [
            {title: t("products.vendor_code"), key: "code", dataIndex: "code"},
            {title: t("products.vendor_name"), key: "name", dataIndex: "name"},
            {title: t("products.vendor_owner"), key: "owner", dataIndex: "owner"},
            {title: t("products.vendor_created_at"), key: "createdAt", dataIndex: "createdAt", render: text => formatDate2(text)},
        ];
        let renderOptions = () => {
            let rs = [];
            for(let item of vendors) {
                if(this.isShowVendor(item)) {
                    rs.push(<Option key={item.id} value={JSON.stringify(item)}>{item.name}</Option>);
                }
            }
            return rs;
        };
        let content = (
            <Select
                defaultActiveFirstOption={false}
                loading={loading}
                style={{width: 200}}
                placeholder={t("products.select_vendor")}
                notFoundContent={<Empty description={t("table.empty")} />}
                value={[]}
                filterOption={false}
                onSearch={this.handleSearchVendor}
                onChange={this.handleSelectVendor}
                showSearch={true}
            >
                {renderOptions()}
            </Select>
        );

        return (
            <div className="permission-container">
                <div className="mg-bt-20">
                    <div className="flr">
                        <Popover placement="right" onVisibleChange={this.onVisibleChange} content={content} trigger="click" visible={isShowPopup}>
                            <Button type="primary" className="_btn_add_group mg-r-10">{t("products.add_vendor")}</Button>
                        </Popover>

                        <Popconfirm
                            onVisibleChange={this.onPopconfirmChange}
                            onConfirm={() => {this.onRemoveVendors()}}
                            visible={isShowPopupDelete && selectedRows.length > 0}
                            title={t("group_user.confirm_delete")}
                            okText={t("popup-confirm.confirm")}
                            cancelText={t("popup-confirm.cancel")}>
                            <Button
                                type="danger"
                                icon="delete"
                                className="_btn_remove_group"
                                disabled={!selectedRows.length}
                            />
                        </Popconfirm>
                    </div>
                    <div className="clearfix" />
                </div>

                <Table
                    dataSource={vendorsSelected}
                    columns={columns}
                    pagination={false}
                    rowKey={record => record.id}
                    rowSelection={this.getConfigRowSelection()}
                    locale={{
                        emptyText: t("table.empty")
                    }}
                />
            </div>
        )
    }
}

TabVendor.defaultProps = {
    productId: null,
    onChangeVendors: (items) => {}
};

export {TabVendor};