import React, {Component} from 'react';
import {Button, Empty, Select, Spin} from 'antd';
import { Input, Icon, Row, Col, DatePicker, Checkbox } from 'antd';
import * as moment from 'moment';
import * as _ from 'lodash';
import {getParamsAsObject, getUrlQueryString} from "../../../../../utils/helper";

const Option = Select.Option;
const RangePicker = DatePicker.RangePicker;

class Filter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            expanded : false,
            keyword: "",
            code: "",
            campaignIds: [],
            productIds: [],
            provinceIds: [],
            cityIds: [],
            orderStatuses: [],
            adsCreatedBy: [],
            createdBy: [],
            assignTo: [],
            vendorId: "",
            createdAt: [],
            deliveryDate: [],
            courierServiceId: [],
            order: ["updatedAt desc"],
            gridKeyword: 8,
            gridCampaign: 8,
            gridProduct: 8,
            gridAssignTo: 8,
            gridTotalCall: 8,
            gridButton: 8,
            classButton: "",
            cityMetro: "",
            checked: false,
            hasIssue: null
        };
        this.onSelectSearch = _.debounce(this.onSelectSearch, 500);
        this.mounted = false;
    }

    onPopstate = () => {
        if(this.mounted) {
            this.setStateFromQueryString();
        }
    };

    componentWillUnmount() {
        this.mounted = false;
        window.removeEventListener("popstate", this.onPopstate.bind(this), false);
    }

    componentDidMount = () => {
        this.mounted = true;
        window.addEventListener("popstate", this.onPopstate.bind(this), false);
        this.setStateFromQueryString();
    };

    getCourierLabel = (id) => {
        const {t} = this.props;
        if (id == 0){
            return t("order_list.none_courier")
        }else if(id == 1){
            return "LBC"
        }else{
            return "Shippo"
        }
    };

    setStateFromQueryString = () => {
        let queryObject = getParamsAsObject(getUrlQueryString());
        let hasProvinceAndCity = false;
        if(queryObject.province_ids && queryObject.city_ids){
            hasProvinceAndCity = true
        }

        this.resetStateFilter(() => {
            const {t} = this.props;
            _.forEach(queryObject, (v, k) => {
                if (v) {
                    let arr = [];
                    if (['order_statuses'].indexOf(k) >= 0) {
                        for (let item of v.split(',')) {
                            arr.push({
                                key: item,
                                label: t(`order_status.${item}`)
                            });
                        }
                        v = arr;
                        this.setState({
                            [_.camelCase(k)]: v
                        });
                    }
                    else if (k === 'has_issue') {
                        let hasIssue = null;
                        if(v === 'true') {
                            hasIssue = 'exported';
                        } else if (v === 'false') {
                            hasIssue = 'not_export';
                        }
                        this.setState({
                            hasIssue
                        });
                    }
                    else if (['courier_service_id'].indexOf(k) >= 0) {
                        for (let item of v.split(',')) {
                            arr.push({
                                key: item,
                                label: this.getCourierLabel(item)
                            });
                        }
                        v = arr;
                        this.setState({
                            [_.camelCase(k)]: v
                        });
                    }
                    else if (['ads_created_by'].indexOf(k) >= 0) {
                        this.props.fetchUserAdsCreate({username: v}, (error, response) => {
                            if (!error) {
                                let arr = [];
                                for (let item of response.list) {
                                    arr.push({
                                        key: item.username,
                                        label: item.username
                                    });
                                }
                                this.setState({
                                    [_.camelCase(k)]: arr
                                })
                            }
                        });
                    }
                    else if (['created_by'].indexOf(k) >= 0) {
                        this.props.fetchUserCreatedBy({ids: v}, (error, response) => {
                            if (!error) {
                                let arr = [];
                                for (let item of response.list) {
                                    arr.push({
                                        key: item.id,
                                        label: item.username
                                    });
                                }
                                this.setState({
                                    [_.camelCase(k)]: arr
                                })
                            }
                        });
                    }
                    else if (['assign_to'].indexOf(k) >= 0) {
                        this.props.fetchUserAssignTo({username:v},(error, response) => {
                            if (!error) {
                                let arr = [];
                                for (let item of response.list) {
                                    arr.push({
                                        key: item.username,
                                        label: item.username
                                    });
                                }
                                this.setState({
                                    [_.camelCase(k)]: arr
                                })
                            }
                        });
                    }
                    else if (['province_ids'].indexOf(k) >= 0) {
                        if(!hasProvinceAndCity){
                            let provincesId = v;
                            let arr = [];
                            let provinceBelongVendor = [];
                            this.props.fetchProvinces((error, response) => {
                                if (!error) {
                                    for (let item of response) {
                                        if(provincesId.split(',').indexOf(item.id.toString()) >= 0){
                                            arr.push({
                                                key: item.id,
                                                label: item.name
                                            });
                                            provinceBelongVendor.push(item.id)
                                        }
                                    }

                                    this.setState({
                                        [_.camelCase(k)]: arr
                                    });

                                    if(provinceBelongVendor.length){
                                        this.fetchCityByIdProvince({province_id:provinceBelongVendor.join(",")});
                                    }
                                }
                            });
                        }
                    }
                    else if (['city_ids'].indexOf(k) >= 0) {
                        if(hasProvinceAndCity){
                            let cityId = v;
                            let provincesId = queryObject.province_ids;
                            let arr = [];
                            let provinceBelongVendor = [];
                            this.props.fetchProvinces((error, response) => {
                                if (!error) {
                                    for (let item of response) {
                                        if(provincesId.split(',').indexOf(item.id.toString()) >= 0){
                                            arr.push({
                                                key: item.id,
                                                label: item.name
                                            });
                                            provinceBelongVendor.push(item.id)
                                        }
                                    }

                                    this.setState({
                                        provinceIds: arr
                                    });

                                    // Nếu có cả province và city thì hiển thị city tại bộ lọc
                                    if(provinceBelongVendor.length){
                                        this.fetchCityByIdProvince({province_id:provinceBelongVendor.join(",")},{},
                                            (error, response) => {
                                                if (!error) {
                                                    let arr2 = [];
                                                    for (let item of response) {
                                                        if(cityId.split(',').indexOf(item.id.toString()) >= 0){
                                                            arr2.push({
                                                                key: item.id,
                                                                label: item.name
                                                            });
                                                        }
                                                    }
                                                    this.setState({
                                                        cityIds: arr2
                                                    });

                                                }
                                            });
                                    }
                                }
                            });

                        }
                    }
                    else if (['expanded'].indexOf(k) >= 0) {
                        v = v === 'true';
                        this.setState({
                            [_.camelCase(k)]: v
                        });
                    }
                    else if (['city_metro'].indexOf(k) >= 0) {
                        this.setState({
                            checked: v=='false'?true:false,
                            [_.camelCase(k)]: v

                        });
                    }
                    else if (k === 'campaign_ids') {
                        if(v){
                            v = v.split(',')
                        }
                        this.props.fetchCampaign({ids: v}, (error, response) => {
                            if (!error) {
                                let arr = [];
                                for (let item of response.list) {
                                    arr.push({
                                        key: item.id,
                                        label: item.name
                                    });
                                }
                                this.setState({
                                    [_.camelCase(k)]: arr
                                })
                            }
                        });
                    }
                    else if (k === 'product_ids') {
                        this.props.fetchProduct({ids: v}, (error, response) => {
                            if (!error) {
                                let arr = [];
                                for (let item of response.list) {
                                    arr.push({
                                        key: item.id,
                                        label: item.name
                                    });
                                }
                                this.setState({
                                    [_.camelCase(k)]: arr
                                })
                            }
                        });
                    }
                    else if (k === 'created_at_from') {
                        let createdAt = this.state.createdAt;
                        createdAt[0] = moment(v);
                        this.setState({createdAt});
                    }
                    else if (k === 'created_at_to') {
                        let createdAt = this.state.createdAt;
                        createdAt[1] = moment(v);
                        this.setState({createdAt});
                    }
                    else if (k === 'delivery_date_from') {
                        let deliveryDate = this.state.deliveryDate;
                        deliveryDate[0] = moment(v);
                        this.setState({deliveryDate});
                    }
                    else if (k === 'delivery_date_to') {
                        let deliveryDate = this.state.deliveryDate;
                        deliveryDate[1] = moment(v);
                        this.setState({deliveryDate});
                    }
                    else {
                        this.setState({
                            [_.camelCase(k)]: v
                        });
                    }
                }
            });
        });
    };

    toggleFormFilter = () => {
        this.setState({
            expanded: !this.state.expanded
        }, () => {
            let uri = this.props.getUrlInstance();
            uri.replaceParam('expanded', this.state.expanded);
            this.props.pushQueryString(uri.getQueryString());
        });
    };

    onChangeInput = (key, e) => {
        this.setState({
            [key]: e.target.value
        });
    };

    onSelect = (key, value) => {
        let oldList = this.state[key] || [];
        oldList.push(value);
        this.setState({
            [key]: oldList
        });

        if(key === "provinceIds"){
            let provinceIds = "";
            for (let item of oldList) {
                provinceIds += `${item.key},`;
            }
            provinceIds = provinceIds.substring(0, provinceIds.lastIndexOf(','));
            this.fetchCityByIdProvince({province_id:provinceIds})
        }
    };

    onFilterByCreatedByFromTable = (id) => {
        let checked = false;
        let createdBy = this.state.createdBy;
        let user = {};
        let that = this;
        for (let item of this.props.userCreatedBy) {
            if(item.id == id){
                user.key = item.id;
                user.label = item. created_by;
            }
        }

        for (let item of createdBy) {
            if(item.key == id){
                checked = true
            }
        }

        if(!checked){
            createdBy.push(user);

            this.setState({
                createdBy: createdBy
            },function () {
                let uri = this.props.getUrlInstance();
                uri.replaceParam('createdBy', that.extractIdsFrom('createdBy').join(','));
                this.props.pushQueryString(uri.getQueryString());
            });
        }

    };

    fetchCityByIdProvince = (provincesId = {},cityId = {},cb = null) => {
        this.props.fetchCities(provincesId,cityId,cb)
    };

    onSelectSearch = (key, value) => {
        if(key === 'campaignIds') {
            this.props.fetchCampaign({
                name: value
            });
        }

        if(key === 'productIds') {
            this.props.fetchProduct({
                name: value
            });
        }
        if(key === 'adsCreatedBy') {
            this.props.fetchUserAdsCreate({
                username: value
            });
        }
        if(key === 'createdBy') {
            this.props.fetchUserCreatedBy({
                username: value
            });
        }
        if(key === 'assignTo') {
            this.props.fetchUserAssignTo({
                username: value
            });
        }
    };

    onChangeRangePicker = (key, ranges) => {
        this.setState({
            [key]: ranges
        });
    };

    extractIdsFrom = (key) => {
        if(key === "assignTo"){
            let items = this.state[key];
            let arr = [];
            for(let item of items) {
                if(item.key === "Chưa phụ trách"){
                    arr.push("Unassign")
                }else{
                    arr.push(item.key);
                }
            }
            return arr;
        }else{
            let items = this.state[key];
            let arr = [];
            for(let item of items) {
                arr.push(item.key);
            }
            return arr;
        }

    };

    onFilter = () => {
        let uri = this.props.getUrlInstance(true);
        uri.replaceParam('expanded', this.state.expanded);
        uri.replaceParam('keyword', this.state.keyword);
        uri.replaceParam('code', this.state.code);
        uri.replaceParam('cityMetro', this.state.cityMetro);
        uri.replaceParam('campaignIds', this.extractIdsFrom('campaignIds').join(','));
        uri.replaceParam('productIds', this.extractIdsFrom('productIds').join(','));
        uri.replaceParam('adsCreatedBy', this.extractIdsFrom('adsCreatedBy').join(','));
        uri.replaceParam('createdBy', this.extractIdsFrom('createdBy').join(','));
        uri.replaceParam('orderStatuses', this.extractIdsFrom('orderStatuses').join(','));
        uri.replaceParam('assignTo', this.extractIdsFrom('assignTo').join(','));
        uri.replaceParam('provinceIds', this.extractIdsFrom('provinceIds').join(','));
        uri.replaceParam('cityIds', this.extractIdsFrom('cityIds').join(','));
        let hasIssue = this.state.hasIssue;
        if(hasIssue === 'exported') {
            uri.replaceParam('hasIssue', true);
        } else if(hasIssue === 'not_export') {
            uri.replaceParam('hasIssue', false);
        }
        uri.replaceParam('courierServiceId', this.extractIdsFrom('courierServiceId').join(','));
        uri.replaceParam('page', 1);
        uri.replaceParam('filter', true);

        if(Array.isArray(this.state.createdAt)) {
            if(this.state.createdAt[0]) {
                uri.replaceParam('createdAtFrom', this.state.createdAt[0].format("YYYY-MM-DD 00:00:00"));
            }
            if(this.state.createdAt[1]) {
                uri.replaceParam('createdAtTo', this.state.createdAt[1].format("YYYY-MM-DD 23:59:59"));
            }
        }

        if(Array.isArray(this.state.deliveryDate)) {
            if(this.state.deliveryDate[0]) {
                uri.replaceParam('deliveryDateFrom', this.state.deliveryDate[0].format("YYYY-MM-DD 00:00:00"));
            }
            if(this.state.deliveryDate[1]) {
                uri.replaceParam('deliveryDateTo', this.state.deliveryDate[1].format("YYYY-MM-DD 23:59:59"));
            }
        }


        this.props.pushQueryString(uri.getQueryString());
    };

    resetStateFilter = (cb = null) => {
        this.setState({
            keyword: "",
            code:"",
            campaignIds: [],
            productIds: [],
            createdAt: [],
            adsCreatedBy: [],
            createdBy: [],
            orderStatuses: [],
            hasIssue: null,
            assignTo: [],
            deliveryDate: [],
            provinceIds: [],
            cityIds: [],
            courierServiceId: [],
            checked: false,
            cityMetro: ""
        }, cb);
        this.props.resetCities();
    };

    clearFilter = () => {
        this.resetStateFilter(() => {
            this.props.pushQueryString(`?expanded=${this.state.expanded}`)
        });
    };

    onFocus = (key) => {
        switch (key) {
            case 'campaignIds':
                this.props.resetDataSource('campaigns');
                break;
            case 'productIds':
                this.props.resetDataSource('products');
                break;
            case 'adsCreatedBy':
                this.props.resetDataSource('userAdsCreate');
                break;
            case 'createdBy':
                this.props.resetDataSource('userCreatedBy');
                break;
            case 'assignTo':
                this.props.resetDataSource('userAssignTo');
                break;
        }
    };
    
    getOrderStatus = () => {
        const {t} = this.props;
        return [
            {key:'DRAFT', label: t("order_status.DRAFT")},
            {key:'NEW', label: t("order_status.NEW")},
            {key:'READY', label: t("order_status.READY")},
            {key:'HOLD', label: t("order_status.HOLD")},
            {key:'CANCELLED', label: t("order_status.CANCELLED")},
            {key:'PICKED_UP', label: t("order_status.PICKED_UP")},
            {key:'DELIVERING', label: t("order_status.DELIVERING")},
            {key:'DELIVERED', label: t("order_status.DELIVERED")},
            {key:'EXCHANGE', label: t("order_status.EXCHANGE")},
            {key:'RETURNING', label: t("order_status.RETURNING")},
            {key:'RETURNED', label: t("order_status.RETURNED")},
        ]
    };

    getStockIssueStatus = () => {
        const {t} = this.props;
        return [
            {key: 'exported',label: t("stock_issue.exported")},
            {key: 'not_export',label: t("stock_issue.not_export")}
        ]
    };

    getCourier = () => {
        const {t} = this.props;
        return [
            {key: 0,label: t("order_list.none_courier")},
            {key: 1,label: "LBC"},
            {key: 2,label: "Shippo"}
        ]
    };

    onSelectChange = (key, value) => {
        this.setState({
            [key]: value
        },function () {
            if(key === "provinceIds"){
                if(!value.length){
                    this.setState({
                        cityIds: []
                    });
                    this.props.resetCities();
                }
            }
        });
    };

    onChangeManila = () => {
        let cityMetro = this.state.cityMetro;
        if(!cityMetro){
            cityMetro = "false"
        }else if(cityMetro === "false"){
            cityMetro = ""
        }

        this.setState({
            checked: !this.state.checked,
            cityMetro: cityMetro
        })
    };

    render() {
        const {campaigns, products, userAssignTo, userAdsCreate, userCreatedBy, t,
            loadingCampaign, loadingProduct, loadingUserAdsCreate, loadingUserCreatedBy, loadingUserAssignTo,
            loadingProvinces,provinces,loadingCities,cities} = this.props;
        const {expanded, keyword, campaignIds, code,
            productIds, adsCreatedBy, createdBy, assignTo, createdAt, deliveryDate, provinceIds, cityIds, orderStatuses, hasIssue, courierServiceId} = this.state;
        let orderStatusesList = this.getOrderStatus();
        let StockIssueStatusList = this.getStockIssueStatus();
        let courierList = this.getCourier();
        return (
            <div className="contact-filter filter _order-filter">
                <div>
                    <Row gutter={20} className="form-filter">
                        <Col span={8} className="form-filter-control">
                            <label>{t("order_list.code")}</label>
                            <Input
                                className={"_filter_code"}
                                placeholder={t("order_list.code")}
                                suffix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                value={code}
                                onChange={this.onChangeInput.bind(this, 'code')}
                                onPressEnter={this.onFilter}
                            />
                        </Col>

                        <Col span={8} className="form-filter-control">
                            <label>{t("contact.fullname_phone_number")}</label>
                            <Input
                                className={"_filter_phone"}
                                placeholder={t("contact.fullname_phone_number")}
                                suffix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                value={keyword}
                                onChange={this.onChangeInput.bind(this, 'keyword')}
                                onPressEnter={this.onFilter}
                            />
                        </Col>

                        <Col span={8} className="form-filter-control">
                            <label>{t("contact.campaign")}</label>
                            <Select
                                labelInValue
                                filterOption={false}
                                className={"_filter_campaign"}
                                placeholder={t("contact.select_campaign")}
                                notFoundContent={(loadingCampaign ? <Spin size="small" /> : <Empty description={t("table.empty")} />)}
                                mode="multiple"
                                style={{width: "100%"}}
                                value={loadingCampaign ? [] : campaignIds}
                                loading={loadingCampaign}
                                onSelect={this.onSelect.bind(this, 'campaignIds')}
                                onChange={this.onSelectChange.bind(this, 'campaignIds')}
                                onSearch={this.onSelectSearch.bind(this, 'campaignIds')}
                                onFocus={this.onFocus.bind(this, 'campaignIds')}
                            >
                                {campaigns.map((item) => (
                                    <Option key={item.id} value={item.id} className={`_campaign_item _campaign_item_${item.id}`}>{item.name}</Option>
                                ))}
                            </Select>
                        </Col>
                        <Col span={8} className="form-filter-control">
                            <label>{t("contact.product")}</label>
                            <Select
                                labelInValue
                                filterOption={false}
                                className={"_filter_product"}
                                placeholder={t("contact.select_product")}
                                notFoundContent={loadingProduct ? <Spin size="small" /> :(<Empty description={t("table.empty")} />)}
                                mode="multiple"
                                style={{width: "100%"}}
                                value={loadingProduct ? [] : productIds}
                                loading={loadingProduct}
                                onSelect={this.onSelect.bind(this, 'productIds')}
                                onChange={this.onSelectChange.bind(this, 'productIds')}
                                onSearch={this.onSelectSearch.bind(this, 'productIds')}
                                onFocus={this.onFocus.bind(this, 'productIds')}
                            >
                                {products.map((item, index) => (
                                    <Option key={index} value={item.id} className={`_product_item _product_item_${item.id}`}>{item.name}</Option>
                                ))}
                            </Select>
                        </Col>

                        <Col span={8} className="form-filter-control">
                            <label>{t("order_list.status")}</label>
                            <Select
                                labelInValue
                                className={"_filter_order_status"}
                                placeholder={t("contact.select_status")}
                                notFoundContent={<Empty description={t("table.empty")} />}
                                mode="multiple"
                                value={orderStatuses}
                                style={{width: "100%"}}
                                onSelect={this.onSelect.bind(this, 'orderStatuses')}
                                onChange={this.onSelectChange.bind(this, 'orderStatuses')}
                            >
                                {orderStatusesList.map((item) => (
                                    <Option key={item.key} value={item.key} className={`_call_status_item _call_status_item_${item.key}`}>{item.label}</Option>
                                ))}
                            </Select>
                        </Col>

                        <Col span={8} className="form-filter-control">
                            <label>{t("order_list.assign_to")}</label>
                            <Select
                                labelInValue
                                filterOption={false}
                                className={"_filter_assign_to"}
                                placeholder={t("contact.select_assign_to")}
                                notFoundContent={(loadingUserAssignTo ? <Spin size="small" /> : <Empty description={t("table.empty")} />)}
                                mode="multiple"
                                style={{width: "100%"}}
                                value={loadingUserAssignTo ? [] : assignTo}
                                loading={loadingUserAssignTo}
                                onSelect={this.onSelect.bind(this, 'assignTo')}
                                onChange={this.onSelectChange.bind(this, 'assignTo')}
                                onSearch={this.onSelectSearch.bind(this, 'assignTo')}
                                onFocus={this.onFocus.bind(this, 'assignTo')}
                            >
                                {userAssignTo.map((item, index) => (
                                    <Option key={index} value={item.username} className={`_assign_to_item _assign_to_item_${item.id}`}>{item.username}</Option>
                                ))}
                            </Select>
                        </Col>
                        {(expanded === true) && (
                            <>
                                <Col span={8} className="form-filter-control">
                                    <label>{t("order_list.ad_creator")}</label>
                                    <Select
                                        labelInValue
                                        filterOption={false}
                                        className={"_filter_ads_created_by"}
                                        placeholder={t("contact.select_ad_creator")}
                                        notFoundContent={(loadingUserAdsCreate ? <Spin size="small" /> : <Empty description={t("table.empty")} />)}
                                        mode="multiple"
                                        style={{width: "100%"}}
                                        value={loadingUserAdsCreate ? [] : adsCreatedBy}
                                        loading={loadingUserAdsCreate}
                                        onSelect={this.onSelect.bind(this, 'adsCreatedBy')}
                                        onChange={this.onSelectChange.bind(this, 'adsCreatedBy')}
                                        onSearch={this.onSelectSearch.bind(this, 'adsCreatedBy')}
                                        onFocus={this.onFocus.bind(this, 'adsCreatedBy')}
                                    >
                                        {userAdsCreate.map((item, index) => (
                                            <Option key={index} value={item.username} className={`_ads_created_by_item _ads_created_by_item_${item.id}`}>{item.username}</Option>
                                        ))}
                                    </Select>
                                </Col>

                                <Col span={8} className="form-filter-control">
                                    <label>{t("order_detail.created_by")}</label>
                                    <Select
                                        labelInValue
                                        filterOption={false}
                                        className={"_filter_created_by"}
                                        placeholder={t("order_list.created_by_placeholder")}
                                        notFoundContent={(loadingUserCreatedBy ? <Spin size="small" /> : <Empty description={t("table.empty")} />)}
                                        mode="multiple"
                                        style={{width: "100%"}}
                                        value={loadingUserCreatedBy ? [] : createdBy}
                                        loading={loadingUserCreatedBy}
                                        onSelect={this.onSelect.bind(this, 'createdBy')}
                                        onChange={this.onSelectChange.bind(this, 'createdBy')}
                                        onSearch={this.onSelectSearch.bind(this, 'createdBy')}
                                        onFocus={this.onFocus.bind(this, 'createdBy')}
                                    >
                                        {userCreatedBy.map((item, index) => (
                                            <Option key={index} value={item.id} className={`_created_by_item _created_by_item_${item.id}`}>{item.username}</Option>
                                        ))}
                                    </Select>
                                </Col>

                                <Col span={8} className="form-filter-control">
                                    <label>{t("order_list.province")}</label>
                                    <Select
                                        labelInValue
                                        className={"_filter_provinces"}
                                        placeholder={t("order_list.district_placeholder")}
                                        notFoundContent={(loadingProvinces ? <Spin size="small" /> : <Empty description={t("table.empty")} />)}
                                        mode="multiple"
                                        style={{width: "100%"}}
                                        value={loadingProvinces ? [] : provinceIds}
                                        loading={loadingProvinces}
                                        onSelect={this.onSelect.bind(this, 'provinceIds')}
                                        onChange={this.onSelectChange.bind(this, 'provinceIds')}
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                        {provinces.map((item, index) => (
                                            <Option key={index} value={item.id} className={`_province_${item.id}`}>{item.name}</Option>
                                        ))}
                                    </Select>
                                </Col>

                                <Col span={8} className="form-filter-control">
                                    <label>{t("order_list.district")}</label>
                                    <Select
                                        labelInValue
                                        className={"_filter_cities"}
                                        placeholder={t("order_list.city_placeholder")}
                                        notFoundContent={(loadingCities ? <Spin size="small" /> : <Empty description={t("table.empty")} />)}
                                        mode="multiple"
                                        style={{width: "100%"}}
                                        value={loadingCities ? [] : cityIds}
                                        loading={loadingCities}
                                        onSelect={this.onSelect.bind(this, 'cityIds')}
                                        onChange={this.onSelectChange.bind(this, 'cityIds')}
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                        {cities && cities.map((item, index) => (
                                            <Option key={index} value={item.id} className={`_city_${item.id}`}>{item.name}</Option>
                                        ))}
                                    </Select>
                                </Col>

                                <Col span={8} className="form-filter-control">
                                    <label>{t("order_list.hasIssue")}</label>
                                    <Select
                                        className={"_filter_stock_status"}
                                        placeholder={t("order_list.stock_issue_placeholder")}
                                        notFoundContent={<Empty description={t("table.empty")} />}
                                        value={hasIssue}
                                        allowClear={true}
                                        showSearch={false}
                                        style={{width: "100%"}}
                                        onChange={this.onSelectChange.bind(this, 'hasIssue')}
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                        {StockIssueStatusList.map((item) => (
                                            <Option key={item.key} value={item.key} className={`_stock_status_item _stock_status_item_${item.key}`}>{item.label}</Option>
                                        ))}
                                    </Select>
                                </Col>

                                <Col span={8} className="form-filter-control">
                                    <label>{t("order_list.courier")}</label>
                                    <Select
                                        labelInValue
                                        className={"_filter_courier"}
                                        placeholder={t("order_list.courier_placeholder")}
                                        notFoundContent={<Empty description={t("table.empty")} />}
                                        mode="multiple"
                                        value={courierServiceId}
                                        style={{width: "100%"}}
                                        onSelect={this.onSelect.bind(this, 'courierServiceId')}
                                        onChange={this.onSelectChange.bind(this, 'courierServiceId')}
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                        {courierList.map((item) => (
                                            <Option key={item.key} value={item.key} className={`_courier_item _courier_item_${item.key}`}>{item.label}</Option>
                                        ))}
                                    </Select>
                                </Col>

                                <Col span={8} className="form-filter-control">
                                    <label>{t("contact.created_at")}</label>
                                    <RangePicker
                                        placeholder={[t("contact.from"), t("contact.to")]}
                                        className={"_filter_created_at"}
                                        value={createdAt}
                                        onChange={this.onChangeRangePicker.bind(this, 'createdAt')}
                                        style={{width: '100%'}}
                                    />
                                </Col>

                                <Col span={8} className="form-filter-control">
                                    <label>{t("order_list.deliveryDate")}</label>
                                    <RangePicker
                                        placeholder={[t("contact.from"), t("contact.to")]}
                                        className={"_filter_delivery_date"}
                                        value={deliveryDate}
                                        onChange={this.onChangeRangePicker.bind(this, 'deliveryDate')}
                                        style={{width: '100%'}}
                                    />
                                </Col>

                                {this.props.countryId && this.props.countryId === 1726 && (
                                    <Col span={8} className="form-filter-control pd-t-30">
                                        <Checkbox
                                            className="_filter_manila"
                                            checked={this.state.checked}
                                            onChange={this.onChangeManila}
                                            size="small"
                                        >
                                            <span>{t("stock_issue_detail.not_in_manila")}</span>
                                        </Checkbox>

                                    </Col>
                                )}
                            </>
                        )}

                        <Col span={8} xs={24} className={`form-filter-control filter-button ${expanded ? 'expand' : ''}`}>
                            <Button type="primary" className="mg-r-10 _filter_button_filter" onClick={this.onFilter}>{t("button.filter")}</Button>
                            <Button type="default" className="mg-r-10 _filter_button_clear" onClick={this.clearFilter}>{t("contact.clear")}</Button>
                            <a className={"_filter_show_more"} onClick={this.toggleFormFilter}>{expanded ? t("contact.filter_less") : t("contact.filter_more")} <Icon className="expand-icon" type={expanded ? "up" : "down"} /></a>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export {Filter}