import React from 'react';
import {translate} from 'react-i18next';
import {Button, notification, Popconfirm, Tabs} from 'antd';
import * as _ from 'lodash';
import StandardLayout from "../../../components/StandardLayout";
import OrderService from "../../../services/OrderService";
import {BreadCrumb} from "./Breadcrumb";
import {Header} from "./Header";
import {Summary} from "./Summary";
import {Activity} from "./Activity";
import ProductService from "../../../services/ProductService";
import UserGroupService from "../../../services/UserGroupService";
import {doCallBack, objectGet} from "../../../utils/helper";
import {PopupCancelledReason} from "./PopupCancelledReason";
import {PopupReturningReason} from "./PopupReturningReason";
import UserService from "../../../services/UserService";
import {ORDER_SCOPES} from "../../../utils/api/Scopes";
import {toastr} from "../../../utils/toastr";
import VendorService from "../../../services/VendorService";
import {LocalStore} from "../../../utils/LocalStore";
import { DeliveryCaseTab } from './Deliverycase';

class OrderDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            order: null,
            orderStatuses: [],
            vendor: null,
            currencyUnit:  null,
            estimateShippingCod: 0,
            logs: [],
            loadingLogs: false,
            isShowPopupCancelled: false,
            isShowPopupReturning: false,
            productSkuItems: [],
            isShowPopConfirmCreateExchangeOrder: false,
            loadingCreateExchangeOrder: false,
            orderItemGifts: [],
            loggedUser: LocalStore.getInstance().read("authUser")
        }
    }

    componentDidMount = () => {
        this.fetchOrder();
        this.fetchEstimateShippingCost();
        this.fetchOrderStatuses();
    };

    getId = () => {
        return this.props.match.params.id;
    };

    fetchOrder = (cb) => {
        this.setState({loading: true});
        let orderId = this.getId();
        let filter = {include: [{vendor: ["country"]},"product", "orderItems", "creator", "courierService", "campaignAd", "country", "area"]};

        OrderService.getOrderDetail(filter, orderId, (err, response) => {
            this.setState({loading: false});
            if (err === null) {
                let data = response;
                _.forEach(data.orderItems, (item) => {
                    item.markedDelete = false;
                });

                this.setState({
                    order: data,
                    vendor: data.vendor,
                    currencyUnit: objectGet(data.vendor, 'country.currencyUnit')
                });

                ProductService.getSku(data.productId, (error, response) => {
                    if(!error) {
                        this.setState({
                            productSkuItems: response
                        });
                    }
                });

                this.fetchOrderItemGifts();
            }
            else {
                if (err.statusCode === 404 || err.statusCode === 403) {
                    this.props.history.replace('/'+err.statusCode);
                } else {
                    notification.error({
                        message: err.message
                    });
                }
            }
            doCallBack(cb, [err, response]);
        });
    };

    fetchOrderStatuses = () => {
        OrderService.getOrderStatuses((err, response) => {
            if (err === null) {
                let orderStatuses = [];
                _.forEach(response, (value, key) => {
                    orderStatuses.push(value);
                });
                this.setState({orderStatuses});
            }
        });
    };

    fetchEstimateShippingCost = () => {
        OrderService.getEstimateShippingCost(this.getId(), (error, response) => {
            if(null === error) {
                this.setState({estimateShippingCod: response.cost});
            }
        });
    };

    fetchLogs = () => {
        this.setState({loadingLogs: true});
        OrderService.getOrderActivityHistory(this.getId(), 25, 0, (error, response) => {
            this.setState({loadingLogs: false});
            if(!error) {
                this.setState({logs: response});
            }
        });
    };

    fetchGroups = (filter = {}, cb) => {
        const {order, vendor} = this.state;
        VendorService.getGroupInProduct(vendor.id, order.productId, filter, cb);
    };

    fetchUsersByGroupId = (groupId, cb) => {
        UserGroupService.getMembers(groupId, cb);
    };

    fetchOrderItemGifts = () => {
        OrderService.getGift(this.getId(), (err, response) => {
            if (err === null) {
                this.setState({
                    orderItemGifts: response
                });
            }
        });
    };

    updateOrder = (data, cb) => {
        const {order} = this.state;
        OrderService.updataOrder(order.id, data, cb);
    };

    updateOrderCollectedCod = (collectedCod, cb) => {
        const {order} = this.state;
        OrderService.updateCollectedCod(order.id, collectedCod, cb);
    }

    assignOrder = (userId, cb) => {
        const {order} = this.state;
        OrderService.assignOrderToSaleNormal({orderId: order.id, userId: userId}, cb);
    };

    updateOrderStatus = (data, cb) => {
        OrderService.changeOrderStatus(this.getId(), data, cb);
    };

    togglePay = (cb) => {
        const {t} = this.props;
        const order = this.state.order;

        let statusAllow = ["NEW", "READY", "HOLD", "CANCELLED"];
        let hasIssue = order.hasIssue;
        let isAllow = statusAllow.indexOf(order.status) >= 0;
        if(!hasIssue && isAllow && UserService.isAllowed(ORDER_SCOPES.ORDER_PAY_STATUS)) {
            OrderService.togglePay(order.id, (err, response) => {
                if(!err) {
                    notification.success({
                        message: t('message.success')
                    });
                    this.fetchOrder()
                } else {
                    notification.error({
                        message: err.message
                    });
                }
                doCallBack(cb, [err,response]);
            });
        }
    };

    onUpdateOrderStatusResponse = (error, response) => {
        const {t} = this.props;
        if(!error) {
            notification.success({
                message: t('message.success')
            });
            this.fetchOrder();
        } else {
            let message = error.message;

            if(error.code === 'PREVENT_UPDATE_PREVIOUS_STATUS') {
                message = t('order.prevent_update_previous_status');
            } else if(error.code === 'ADDED_TO_STOCK_ISSUE') {
                message = t('order_detail.added_to_stock_issue');
            }
            notification.error({
                message: message
            });
        }
    };

    onChangeTab = (key) => {
        if(key === 'activity') {
            this.fetchLogs();
        }
    };

    togglePopupCancelled = () => {
        this.setState({isShowPopupCancelled: !this.state.isShowPopupCancelled});
    };

    togglePopupReturning = () => {
        this.setState({isShowPopupReturning: !this.state.isShowPopupReturning});
    };

    updateOrderItemState = (id, data) => {
        const {order} = this.state;

        for (let i = 0; i < order.orderItems.length; i++) {
            if (order.orderItems[i].id === id) {
                order.orderItems[i] = {...data};
            }
        }

        this.setState({order});
    };

    addOrderItemState = (data) => {
        let {order} = this.state;
        if(order) {
            order.orderItems.push(data);
        }
        this.setState({order});
    };

    addOrderItem = (data, cb) => {
        OrderService.addOrderItems(this.getId(), data, cb);
    };

    deleteOrderItem = (orderItemId, cb) => {
        OrderService.deleteOrderItem(this.getId(), orderItemId, cb);
    };

    updateOrderItem = (orderItemId, data, cb) => {
        OrderService.updateOrderItems(this.getId(), orderItemId, data, cb);
    };

    onConfirmMakeExchangeOrder = () => {
        const {t} = this.props;
        const {order} = this.state;
        let data = {
            id: order.id,
            paid: order.paid
        };
        this.setState({isShowPopConfirmCreateExchangeOrder: false});
        this.setState({loadingCreateExchangeOrder: true});
        OrderService.createExchangeOrder(data, (error, response) => {
            this.setState({loadingCreateExchangeOrder: false});
            if(null === error) {
                toastr.success(t('message.success'));
                this.props.history.push('/orders');
            } else {
                toastr.error(error.message);
            }
        });
    };

    onPopConfirmCreateExchangeOrderVisibleChange = (visible) => {
        this.setState({isShowPopConfirmCreateExchangeOrder: visible});
    };

    onChangeOrderField = (field, e) => {
        let {order} = this.state;
        order[field] = e.target ? e.target.value : e;
        this.setState({order})
    };

    render() {
        const {t} = this.props;
        const {order, isShowPopConfirmCreateExchangeOrder, loadingCreateExchangeOrder, orderItemGifts} = this.state;
        const orderStatus = objectGet(order, 'status');
        const {loading, isShowPopupCancelled, isShowPopupReturning, loadingLogs} = this.state;
        let tabBarExtraContent;
        if(UserService.isAllowed(ORDER_SCOPES.ORDER_CREATE_EXCHANGE) && ['DELIVERED', 'EXCHANGE', 'RETURNING', 'RETURNED'].indexOf(orderStatus) >= 0) {
            tabBarExtraContent = (
                <Popconfirm
                    onVisibleChange={this.onPopConfirmCreateExchangeOrderVisibleChange}
                    onConfirm={() => {this.onConfirmMakeExchangeOrder()}}
                    visible={isShowPopConfirmCreateExchangeOrder}
                    title={t("order_detail.order_exchange_confirm_title")}
                    okText={t("popup-confirm.confirm")}
                    cancelText={t("popup-confirm.cancel")}
                >
                    <Button
                        className="_btn_create_exchange_order"
                        loading={loadingCreateExchangeOrder}
                        icon="swap"
                        size="small"
                        type="primary"
                        style={{marginRight: 10,position: 'relative',top: 2}}
                    >
                        <span>{t('order_detail.btn_create_exchange')}</span>
                    </Button>
                </Popconfirm>
            );
        }
        return (
            <StandardLayout {...this.props} loading={loading} title={t('order_detail.page_title')}>
                <div className="order-detail-page _order_detail_page">
                    <BreadCrumb
                        {...this.props}
                        {...this.state}
                    />
                    <Header
                        {...this.props}
                        {...this.state}
                        togglePopupCancelled={this.togglePopupCancelled}
                        togglePopupReturning={this.togglePopupReturning}
                        updateOrderStatus={this.updateOrderStatus}
                        onUpdateOrderStatusResponse={this.onUpdateOrderStatusResponse}
                        togglePay={this.togglePay}
                    />

                    <Tabs className={"summary-tabs white-box"} onChange={this.onChangeTab} tabBarExtraContent={tabBarExtraContent}>
                        <Tabs.TabPane tab={t('order_detail.summary')} key={"summary"}>
                            <Summary
                                {...this.props}
                                {...this.state}
                                fetchGroups={this.fetchGroups}
                                fetchUsersByGroupId={this.fetchUsersByGroupId}
                                fetchOrder={this.fetchOrder}
                                updateOrder={this.updateOrder}
                                assignOrder={this.assignOrder}
                                addOrderItemState={this.addOrderItemState}
                                updateOrderItemState={this.updateOrderItemState}
                                addOrderItem={this.addOrderItem}
                                deleteOrderItem={this.deleteOrderItem}
                                updateOrderItem={this.updateOrderItem}
                                onChangeOrderField={this.onChangeOrderField}
                                onUpdateOrderCollectedCod={this.updateOrderCollectedCod}
                            />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={t('order_detail.tab_activity')} key={"activity"}>
                            <Activity
                                {...this.props}
                                {...this.state}
                                loading={loadingLogs}
                            />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={t('delivery_case.title')} key={"delivery_case"}>
                            <DeliveryCaseTab
                                {...this.props}
                                order={order}
                            />
                        </Tabs.TabPane>
                    </Tabs>

                    <PopupCancelledReason
                        {...this.props}
                        {...this.state}
                        visible={isShowPopupCancelled}
                        togglePopupCancelled={this.togglePopupCancelled}
                        updateOrderStatus={this.updateOrderStatus}
                        fetchOrder={this.fetchOrder}
                        onUpdateOrderStatusResponse={this.onUpdateOrderStatusResponse}
                    />

                    <PopupReturningReason
                        {...this.props}
                        {...this.state}
                        visible={isShowPopupReturning}
                        togglePopupReturning={this.togglePopupReturning}
                        updateOrderStatus={this.updateOrderStatus}
                        fetchOrder={this.fetchOrder}
                        onUpdateOrderStatusResponse={this.onUpdateOrderStatusResponse}
                    />
                </div>
            </StandardLayout>
        )
    }
}

export default (translate())(OrderDetail);