import React, {Component} from 'react';
import {Button, Checkbox, Col, Icon, notification, Pagination, Popover, Row, Select, Table, Tag, Tooltip} from 'antd';

import * as moment from 'moment';
import lodash from 'lodash';
import {getOrderStatusColor, getParamsAsObject, getUrlQueryString, objectGet} from "../../utils/helper";
import {Link} from "react-router-dom";
import UserService from "../../services/UserService";
import {CONTACT_SCOPES} from "../../utils/api/Scopes";
import ContactService from "../../services/ContactService";
import {AssignContactManual} from "../../components/AssignContactManual";
import {AutoAssignContact} from "./AutoAssignContact";
import {LocalStore} from "../../utils/LocalStore";

const Option = Select.Option;

class List extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loadingExport: false,
            selectedRows: 0,
            sorter: {},
            loadingRequestContact: false,
            isShowPopupAssignTo: {},
            groups: [],
            isShowPopupAutoAssign: false,
            loadingAutoAssign: false
        };
    }

    componentDidMount = () => {

    };

    getContactStatus = (id) => {
        let {contactStatuses} = this.props;
        for(let item of contactStatuses) {
            if(item.id === id){
                return item
            }
        }
        return null;
    };

    getCallStatus = (id) => {
        let {callStatuses} = this.props;
        for(let item of callStatuses) {
            if(item.id === id){
                return item
            }
        }
        return null;
    };

    getConfigRowSelection = () => {
        return {
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({selectedRows: selectedRows.length});
            }
        }
    };

    checkAppointmentAt = (appointmentAt) => {
        const {t} = this.props;
        if(appointmentAt){
            let timeEnd = moment(appointmentAt).toDate().valueOf();
            let timeNow = new Date().valueOf();
            if(timeEnd < timeNow){
                return {content:t("contact.contact_expired"),color:"#f5222d"};
            }else{
                let contactTime = moment(appointmentAt).format("DD/MM/YY HH:mm");
                return {content:`${t("contact.appointment")} ${contactTime}`,color:"#1890ff"};
            }
        }else{
            return {content:t("contact.new_contact"),color:"#52c41a"};
        }
    };

    togglePopupAssignTo = (order) => {
        let {isShowPopupAssignTo} = this.state;
        if(isShowPopupAssignTo.hasOwnProperty(order.id)) {
            isShowPopupAssignTo[order.id] = !isShowPopupAssignTo[order.id];
        } else {
            isShowPopupAssignTo[order.id] = false;
        }
        this.setState({isShowPopupAssignTo});
    };

    onVisiblePopoverAssignTo = (item, visible) => {
        let isShowPopupAssignTo = {
            [item.id]: visible
        };
        this.setState({isShowPopupAssignTo});
    };

    onAssignContact = (contact) => {
        this.setState({
            isShowPopupAssignTo: {
                [contact.id]: false
            }
        });
        let queryObject = getParamsAsObject(getUrlQueryString());
        this.props.loadContact(queryObject);
    };

    getConfigTableColumns = () => {
        const {t} = this.props;
        const {isShowPopupAssignTo} = this.state;
        const renderColumn = (key, text, record) => {
            switch (key) {
                case 'assignTo':
                    let contactStatus = this.getContactStatus(record.contactStatusId);
                    if(UserService.isAllowed(CONTACT_SCOPES.CONTACT_ASSIGN) && contactStatus && !contactStatus.isEnd) {
                        let isShowPopup = objectGet(isShowPopupAssignTo, record.id, false);
                        let content = (
                            <AssignContactManual
                                {...this.props}
                                contact={record}
                                fetchGroups={(filter = {}, cb) => {return this.props.fetchGroupsByProductId(record.productId, filter, cb)}}
                                fetchUsersByGroupId={this.props.fetchUsersByGroupId}
                                assignContact={(userId, cb) => {return this.props.assignContact(record.id, userId, cb)}}
                                onChangeUser={() => this.togglePopupAssignTo(record)}
                                onAssignContact={this.onAssignContact.bind(this, record)}
                                showButtonUnAssign={record.assignTo > 0}
                            />
                        );
                        return (
                            <Popover
                                title={t("order_list.assign_to")}
                                content={content}
                                trigger={["click"]}
                                visible={isShowPopup}
                                onVisibleChange={this.onVisiblePopoverAssignTo.bind(this, record)}
                            >
                                <span className="pointer">{record.user ? record.user.username: t("contact.unassign_to")} <Icon type="caret-down" style={{fontSize: 10}} /></span>
                            </Popover>
                        );
                    }
                    return record.user ? record.user.username: t("contact.unassign_to");
                case 'assignedBy':
                    return record.assignedBy ? record.assignedBy: "";
                case 'assignedAt':
                    return record.assignedAt ? moment(record.assignedAt).format("DD/MM/YY HH:mm") : "";
                case 'createdAt':
                    return record.createdAt ? moment(record.createdAt).format("DD/MM/YY HH:mm") : "";
                case 'campaignId':
                    return record.campaign ? <span className="campaign-name">{record.campaign.name}</span>: "";
                case "contactStatusId":
                    return this.getContactStatus(record.contactStatusId) ? this.getContactStatus(record.contactStatusId).key : "";
                case "callStatusId":
                    return this.getCallStatus(record.callStatusId) ? <Tag color={this.getCallStatus(record.callStatusId).color}>{this.getCallStatus(record.callStatusId).value}</Tag>: <Tag color={"cyan"}>New</Tag>;
                case 'deliveryDate':
                    return record.deliveryDate ? moment(record.deliveryDate).format("DD/MM/YY HH:mm") : "";
                case 'callTime':
                    return record.callTime ? moment(record.callTime).format("DD/MM/YY HH:mm") : "";
                case 'customerName':
                    let appointmentAt = this.checkAppointmentAt(record.appointmentAt);
                    return (
                        <span>
                            <Tooltip className="pointer" title={appointmentAt.content}>
                                <span className="contact-date" style={{background:appointmentAt.color}}/>
                            </Tooltip>
                            <span className="name-contact">
                                <Link to={`/contacts/${record.id}`}>{record.customerName ? (record.customerName || '------') : '------'}</Link>
                            </span>
                        </span>
                    );
                case 'note':
                    return <span className="note">{record.note}</span>;
                case 'orderStatus':
                    return <span className="order-status">
                        <Tag color={getOrderStatusColor(lodash.get(record, 'lastOrder.status'))}>{lodash.get(record, 'lastOrder.status')}</Tag>
                    </span>

            }
        };

        let sortOrder = {};
        let queryObject = getParamsAsObject(getUrlQueryString());
        if(queryObject.order) {
            let order = JSON.parse(queryObject.order);
            if(Array.isArray(order)) {
                for(let cond of order) {
                    let condArr = cond.split(' ');
                    let field = condArr[0];
                    let type = condArr[1];
                    sortOrder[field] = type === 'ASC' ? 'ascend' : 'descend';
                }
            }
        }

        let columns = [
            {title: t("contact.fullname"),dataIndex: 'customerName', key: 'customerName',sorter: true,sortDirections: ['descend','ascend','descend'],render: renderColumn.bind(this, 'customerName'),className:"_customerName _item"},
            {title: t("contact.campaign"),dataIndex: 'campaignId', key: 'campaignId', render: renderColumn.bind(this, 'campaignId'),className:"_campaignId _item"},
            {title: t("contact.created_at"),dataIndex: 'createdAt', key: 'createdAt', render: renderColumn.bind(this, 'createdAt'),sorter: (a, b) => moment(a.createdAt) - moment(b.createdAt), sortDirections: ['ascend','descend','ascend'],className:"_createdAt _item"},
            {title: t("contact.phone"),dataIndex: 'customerPhone', key: 'customerPhone',className:"_customerPhone _item"},
            {title: t("contact.assign_to"),dataIndex: 'assignTo', key: 'assignTo', render: renderColumn.bind(this, 'assignTo'),className:"_assignTo _item"},
            {title: t("contact.assigned_at"),dataIndex: 'assignedAt', key: 'assignedAt', render: renderColumn.bind(this, 'assignedAt'),className:"_assignedAt _item"},
            {title: t("contact.assigned_by"),dataIndex: 'assignedBy', key: 'assignedBy', render: renderColumn.bind(this, 'assignedBy'),className:"_assignedBy _item"},
            {title: ()=> {return <Tooltip className="pointer" title={this.getListCallStatus}>{t("contact.stage")} <Icon type="info-circle" /></Tooltip>},dataIndex: 'contactStatusId', key: 'contactStatusId', render: renderColumn.bind(this, 'contactStatusId'),className:"_contactStatus _item"},
            {title: t("contact.calls"),dataIndex: 'totalCall', key: 'totalCall',sorter: true, sortDirections: ['descend','ascend','descend'],className:"_totalCall _item"},
            {title: t("contact.status"),dataIndex: 'callStatusId', key: 'callStatusId', render: renderColumn.bind(this, 'callStatusId'),className:"_callStatus _item"},
            {title: t('contact.order_status'),dataIndex: 'orderStatus', key: 'orderStatus', render: renderColumn.bind(this, 'orderStatus'),className:"_orderStatus _item"},
            {title: t("contact.callTime"),dataIndex: 'callTime', key: 'callTime',sorter: true, sortDirections: ['descend','ascend','descend'],render: renderColumn.bind(this, 'callTime'),className:"_callTime _item"},
            {title: t("contact.deliveryDate"),dataIndex: 'deliveryDate', key: 'deliveryDate', render: renderColumn.bind(this, 'deliveryDate'),className:"_deliveryDate _item"},
            {title: t("contact.note"),dataIndex: 'note', key: 'note',render: renderColumn.bind(this, 'note'),className:"_note _item"},
        ];

        for(let column of columns) {
            if(sortOrder.hasOwnProperty(column.key)) {
                column.sortOrder = sortOrder[column.key];
            }
        }

        return columns;
    };

    onCheckBox = (priority) => {
        this.props.isShowCheckbox(priority);
    };

    getListCallStatus = () => {
        return this.props.contactStatuses.map((list) =>
            <div className="gutter-example" key={list.key}>
                <Row>
                    <Col className="gutter-row" span={6}>
                        <div className="gutter-box">{list.key}</div>
                    </Col>
                    <Col className="gutter-row" span={18}>
                        <div className="gutter-box">{list.value}</div>
                    </Col>
                </Row>
            </div>
        );
    };

    onChange = (pagination, filters, sorter)=>{
        let sortCondition = sorter.order;
        if(sortCondition === "descend"){
            sortCondition = "DESC";
        }else if(sortCondition === "ascend"){
            sortCondition = "ASC";
        }
        let uri = this.props.getUrlInstance();
        uri.replaceParam('order', `["${sorter.field} ${sortCondition}"]`);
        this.props.pushQueryString(uri.getQueryString());
    };

    onExportOrder = () => {
        this.setState({loadingExport: true});
        let filter = getParamsAsObject(getUrlQueryString());
        const {vendor} = this.props;
        filter.vendor_id = vendor.id;
        filter.time_zone = LocalStore.getInstance().read("timezone");
        ContactService.exportOrder(filter, (error, response) => {
            this.setState({loadingExport: false});
            if (!error) {
                window.open(response.downloadUrl, '_blank');
            }
            else {
                notification.error({
                    message: error.message
                })
            }
        })
    };

    handleExportContact = () => {
        let filter = getParamsAsObject(getUrlQueryString());
        const {vendor, t} = this.props;
        filter.vendor_id = vendor.id;
        let payload = {
            filter: {
                ...filter,
                time_zone: lodash.get(filter, 'time_zone', LocalStore.getInstance().read("timezone"))
            }
        };
        ContactService.requestToExportContact(payload, (error, response) => {
            if (!error) {
                notification.success({
                    message: t('contact.export_contact_send_to_mail')
                });
            } else {
                notification.error({
                    message: 'Error'
                });
            }
        })
    };

    onPopupAutoAssignVisibleChange = (visible) => {
        this.setState({isShowPopupAutoAssign: visible});
    };

    togglePopupAutoAssign = () => {
        this.setState({isShowPopupAutoAssign: !this.state.isShowPopupAutoAssign});
    };

    onAssignedAuto = (error, response) => {
        if(!error) {
            this.togglePopupAutoAssign();
            let url = window.location.href;
            if(url.indexOf('?') >= 0) {
                this.props.history.push('/contacts');
            } else {
                this.props.loadContact({});
            }
        }
    };

    render() {
        const {t, loading} = this.props;
        const {dataSource,currentPage,totalItems,pageSize,onShowSizeChange,onChangePage,pageCount} = this.props;
        const {selectedRows, loadingRequestContact, isShowPopupAutoAssign, loadingExport} = this.state;
        let columns = this.getConfigTableColumns();
        let columnsInvisible = [];

        for (let i = 0; i < columns.length; i++) {
            if(this.props.visibleColumnsDisplay.indexOf(columns[i].key) > -1){
                columnsInvisible.push(columns[i]);
            }
        }
        const rowSelection = this.getConfigRowSelection();

        let content = this.props.visibleColumns.map((contact) =>
            <div className={`mg-bt-10 mg-r-10 mg-l-10 ${contact.name} _checkbox-item`} key={contact.priority}>
                <Checkbox onChange={this.onCheckBox.bind(this,contact.priority)} checked={contact.selected}>{t(contact.language)}</Checkbox>
                <br />
            </div>
        );

        return (
            <div className="list-container">
                <Row className="pd-bt-15">
                    <Col span={5}>
                        {!loading && !!totalItems && (
                            <span>{t("contact.page")} {currentPage}/{pageCount} ({totalItems})</span>
                        )}

                        {!loading && !totalItems && (
                            <span>{t("contact.page")} {1}/{1} ({totalItems})</span>
                        )}
                    </Col>
                    <Col span={19}>
                        <div className="flr">

                            {UserService.isAllowed(CONTACT_SCOPES.CONTACT_EXPORT_ORDER) && (
                                <Button
                                    loading={loadingExport}
                                    disabled={loadingExport}
                                    type={'primary'}
                                    className={'mg-r-5'}
                                    icon={'cloud-download'}
                                    onClick={this.onExportOrder}
                                >
                                    {t('contact.export_order')}
                                </Button>
                            )}

                            {UserService.isAllowed(CONTACT_SCOPES.CONTACT_EXPORT_CONTACT) && (
                                <Button
                                    className={'mg-r-5'}
                                    type={'primary'}
                                    icon={'cloud-download'}
                                    onClick={this.handleExportContact}
                                >
                                    {t('contact.export_contact')}
                                </Button>
                            )}

                            {UserService.isAllowed(CONTACT_SCOPES.CONTACT_ASSIGN) && (
                                <>
                                {isShowPopupAutoAssign && (
                                    <Popover
                                        placement="left"
                                        trigger="click"
                                        visible={isShowPopupAutoAssign}
                                        onVisibleChange={this.onPopupAutoAssignVisibleChange}
                                        content={(
                                            <AutoAssignContact
                                                {...this.props}
                                                fetchGroups={this.props.fetchGroups}
                                                onClose={this.togglePopupAutoAssign}
                                                onAssigned={this.onAssignedAuto}
                                            />
                                        )}
                                    >
                                        <span />
                                    </Popover>
                                )}

                                <Button
                                    type="primary"
                                    className="mg-r-5 _btn_show_popup_auto_assign_contact"
                                    onClick={this.togglePopupAutoAssign}
                                >
                                    {t('contact.assign_contacts')}
                                </Button>
                                </>
                            )}

                            {/*<Button type="primary" className="mg-r-5">Add contact</Button>*/}

                            <Popover placement="bottomLeft" trigger="click" content={content}><Icon type="setting" className="pointer" /></Popover>
                        </div>
                    </Col>
                </Row>

                <div className="a-table a-table--responsive">
                    <Table
                        columns={columnsInvisible}
                        dataSource={dataSource}
                        rowKey={record => record.id}
                        pagination={false}
                        rowSelection={rowSelection}
                        loading={loading}
                        onChange={this.onChange}
                        locale={{
                            emptyText: t("table.empty")
                        }}
                    />
                </div>

                {!loading && totalItems > 0 && (
                    <Pagination
                        className="mg-t-15 text-r"
                        showSizeChanger
                        onShowSizeChange={onShowSizeChange}
                        onChange={onChangePage}
                        pageSizeOptions={['50', '100', '150', '200']}
                        defaultCurrent={1}
                        current={currentPage}
                        pageSize={pageSize}
                        total={totalItems}
                    />
                )}
            </div>
        );
    }
}

export {List}