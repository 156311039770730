import React, {Component} from 'react';
import {
    Modal, Form, Input, Select, Empty, Spin
} from 'antd';

const Option = Select.Option;

class FormCreateProject extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    onFocus = () => {
        this.props.resetDataSource('products');
    };

    onSearch = (value) => {
        this.props.fetchProduct({
            name:value.trim()
        });
    };

    onPressEnter = () => {
        if(this.props.isShowPopUpCreateProject){
            this.props.onCreate();
        }
    };

    render() {
        const {
            isShowPopUpCreateProject, onCancel, onCreate, form,t,products,loadingProduct
        } = this.props;
        const { getFieldDecorator } = form;
        return (
            <Modal
                visible={isShowPopUpCreateProject}
                title={t("project.create")}
                onCancel={onCancel}
                onOk={onCreate}
                okButtonProps={{
                    className: "_create-project-save"
                }}
                cancelButtonProps={{
                    className: "_create-project-cancel",
                }}
                cancelText={t("button.cancel")}
                okText={t("button.save")}
                destroyOnClose={true}
            >
                <Form layout="vertical">
                    <Form.Item label={t("project.name")}>
                        {getFieldDecorator('name', {
                            rules: [{ required: true, message: t("project.validate_name")},{ max: 160, message: t("products.name_length")},{whitespace:true, message: t("project.validate_name")}],
                        })(
                            <Input
                                autoFocus
                                className="_project_name_create"
                                placeholder={t("project.name_placeholder")}
                                onPressEnter={this.onPressEnter}
                                disabled={!isShowPopUpCreateProject}
                            />
                        )}
                    </Form.Item>
                    <Form.Item label={t("products.product")}>
                        {getFieldDecorator('productId', {
                            rules: [{ required: true, message: t("project.validate_product") }],
                            initialValue: products.length===1?products[0].id:undefined
                        })(
                            <Select
                                showSearch
                                notFoundContent={loadingProduct ? <div style={{textAlign:"center",}} className="mg-t-50 mg-bt-50"><Spin size="small"/> </div>:(<Empty description={t("table.empty")} />)}
                                placeholder={t("stock_receipt.select_product")}
                                onFocus={this.onFocus.bind(this)}
                                onSearch={this.onSearch.bind(this)}
                                className="_select-create-project"
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                                {products.length && products.map((item, index) => (
                                    <Select.Option key={index} value={item.id} className="_option-products">{item.name}</Select.Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

FormCreateProject = Form.create()(FormCreateProject);
export {FormCreateProject}