import React, {Component} from 'react';
import { Select, Col, Icon, Row, Table, Menu, Pagination, Dropdown, Modal } from 'antd';
import * as moment from 'moment';
import {REPORT_SCOPES} from "../../utils/api/Scopes";
import UserService from "../../services/UserService";
import {Link} from "react-router-dom";
import ReportService from "../../services/ReportService";
import {notification} from "antd/lib/index";


const Option = Select.Option;

class List extends Component {

    constructor(props) {
        super(props);
        this.state = {
            idReportDeleted: [],
            isShowModalDeleteReport: false
        };
    }

    showModalDeleteReport = (id) => {
        let arr = [];
        arr.push(id);
        this.setState({
            isShowModalDeleteReport: true,
            idReportDeleted: arr
        });
    };

    handleOkDeleteReport = () => {
        const {t} = this.props;
        let idReportDeleted = this.state.idReportDeleted;
        ReportService.deleteReport(idReportDeleted, (error, response) => {
            if(!error) {
                this.props.loadReport();
                notification.success({
                    message: t('message.success')
                });
            }else{
                notification.error({
                    message: error.message
                });
            }
        });

        this.handleCancelDeleteReport();
    };

    handleCancelDeleteReport = () => {
        this.setState({
            isShowModalDeleteReport: false
        })
    };

    getConfigTableColumns = () => {
        const {t} = this.props;
        const renderColumn = (key, text, record) => {
            switch (key) {
                case 'createdAt':
                    return record.createdAt ? moment(record.createdAt).format("DD/MM/YY HH:mm") : "";

                case 'action':
                    const menu = (
                        <Menu>
                            {UserService.isAllowed(REPORT_SCOPES.REPORT_UPDATE) && (
                                <Menu.Item key="1">
                                    <Link to={`/statistics/${record.id}`}>
                                        <span><Icon type="edit" /> {t("table.action_edit")}</span>
                                    </Link>
                                </Menu.Item>
                            )}
                            {UserService.isAllowed(REPORT_SCOPES.REPORT_DELETE) && (
                                <Menu.Item key="2">
                                    <span onClick={this.showModalDeleteReport.bind(this,record.id)}><Icon type="delete" /> {t("table.action_delete")}</span>
                                </Menu.Item>
                            )}
                        </Menu>
                    );
                    return (
                        <Dropdown overlay={menu} trigger={['contextMenu', 'click']}>
                            <span className="pointer"><Icon type="dash" /></span>
                        </Dropdown>
                    );

                case 'title':
                    return <span className={"nowrap-css"}>{record.title}</span>;

            }
        };
        return [
            {title: t("statistic.id"),dataIndex: 'id', key: 'id',className:"report-id _item",sorter: true,sortDirections: ['descend','ascend','descend'],width:120},
            {title: t("statistic.name"),dataIndex: 'title', key: 'title',className:"title _item",width:200,render: renderColumn.bind(this, 'title')},
            {title: t("statistic.created_by"),dataIndex: 'createdBy', key: 'createdBy',className:"createdBy _item",width:150},
            {title: t("statistic.created_at"),dataIndex: 'createdAt', key: 'createdAt',render: renderColumn.bind(this, 'createdAt'),className:"createdAt _item",sorter: true,sortDirections: ['descend','ascend','descend'],width:150},
            {title: "",dataIndex: 'action', key: 'action',render: renderColumn.bind(this, 'action'),className:"_action _item", width:150},

        ];
    };

    onChange = (pagination, filters, sorter)=>{
        let sortCondition = sorter.order;
        if(sortCondition === "descend"){
            sortCondition = "DESC";
        }else if(sortCondition === "ascend"){
            sortCondition = "ASC";
        }
        let uri = this.props.getUrlInstance();
        uri.replaceParam('order', `["${sorter.field} ${sortCondition}"]`);
        this.props.pushQueryString(uri.getQueryString());
    };

    render() {
        const {t} = this.props;
        const {dataSource,currentPage,totalItems,pageSize,onShowSizeChange,onChangePage,pageCount,loading} = this.props;
        let columns = this.getConfigTableColumns();

        return (
            <div className="list-container">
                {!loading && !!totalItems && (
                    <Row className="pd-bt-15">
                        <Col span={5}>
                            <span>{t("contact.page")} {currentPage}/{pageCount} ({totalItems})</span>
                        </Col>
                    </Row>
                )}

                {!loading && !totalItems && (
                    <Row className="pd-bt-15">
                        <Col span={5}>
                            <span>{t("contact.page")} {1}/{1} ({totalItems})</span>
                        </Col>
                    </Row>
                )}

                <Table
                    columns={columns}
                    dataSource={dataSource}
                    rowKey={record => record.id}
                    pagination={false}
                    loading={loading}
                    onChange={this.onChange}
                    locale={{
                        emptyText: t("table.empty")
                    }}
                    scroll={{x: 1200,y: 550}}
                />
                <Pagination
                    className="mg-t-15 text-al-r"
                    showSizeChanger
                    onShowSizeChange={onShowSizeChange}
                    onChange={onChangePage}
                    pageSizeOptions={['25', '50', '75', '100']}
                    defaultCurrent={1}
                    current={currentPage}
                    pageSize={pageSize}
                    total={totalItems}
                />

                <Modal
                    visible={this.state.isShowModalDeleteReport}
                    onOk={this.handleOkDeleteReport}
                    onCancel={this.handleCancelDeleteReport}
                    okText={t("popup-confirm.confirm")}
                    cancelText={t("popup-confirm.cancel")}
                >
                    <p>{t("warehouse.confirm_delete_message")}</p>
                </Modal>
            </div>
        );
    }
}

export {List}