import React, {Component} from 'react';
import {FormUpdateProduct} from "./FormUpdateProduct";
import * as _ from "lodash";

class PopupUpdateProduct extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    handleCancel = () => {
        this.props.showPopupUpdateProduct(false);
        this.formRef.props.form.resetFields();
    };

    handleUpdate = () => {
        let warehouseProductId;
        let listProductSku;
        const form = this.formRef.props.form;
        form.validateFields((err, values) => {
            if(!err) {
                if(values.hasOwnProperty("productInWarehouse")){
                    warehouseProductId = values.productInWarehouse;
                    listProductSku = _.values(_.omit(values, ['productInWarehouse']));
                }
                if(this.props.onUpdateProduct(warehouseProductId,listProductSku)){
                    form.resetFields();
                }
            }
        });
    };

    saveFormRef = (formRef) => {
        this.formRef = formRef;
    };

    render() {
        return (
            <>
                <div>
                    <FormUpdateProduct
                        {...this.props}
                        wrappedComponentRef={this.saveFormRef}
                        onCancel={this.handleCancel}
                        onUpdate={this.handleUpdate}
                    />
                </div>
            </>
        );
    }
}

export {PopupUpdateProduct}