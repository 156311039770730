import React from 'react';
import * as _ from 'lodash';
import {Table, Pagination, Button, Popover, Popconfirm, Select, Empty, notification} from 'antd';
import {doCallBack, formatDate} from "../../../utils/helper";
import UserService from "../../../services/UserService";
import {GROUPS_SCOPES} from "../../../utils/api/Scopes";

const Option = Select.Option;
class User extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            users: [],
            usersNotIn: [],
            initialUsersNotIn: [],
            loading: false,
            totalItems: 0,
            currentPage: 0,
            pageSize: 0,
            selectedRowKeys: [],
            selectedRows: [],
            isShowPopup: false,
            isShowPopupDelete: false,
            loadingAddUser: false,
            loadingDeleteUser: false
        };
        this.onSearchUser = _.debounce(this.onSearchUser, 400);
    }

    componentDidMount = () => {
        this.fetchUsers();
        this.fetchUsersNotIn({}, this.setInitialUsersNotInFromCallBack);
    };

    setInitialUsersNotInFromCallBack = (error, response) => {
        if(!error) {
            this.setState({initialUsersNotIn: response.list});
        }
    };

    fetchUsers = (filter = {}, cb) => {
        this.setState({loading: true});
        this.props.fetchUsers(filter, (error, response) => {
            this.setState({loading: false});
            if(!error) {
                this.setState({
                    users: response.list
                });
            }
            doCallBack(cb, [error, response]);
        });
    };

    fetchUsersNotIn = (filter = {}, cb) => {
        this.props.fetchUsersNotIn(filter, (error, response) => {
            if(!error) {
                this.setState({
                    usersNotIn: response.list
                });
            }
            doCallBack(cb, [error, response]);
        });
    };

    onShowSizeChange = (page, pageSize) => {

    };

    onChangePage = (page, pageSize) => {

    };

    getConfigRowSelection = () => {
        return {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({selectedRowKeys, selectedRows});
            }
        }
    };

    resetSelectedRowAndKeys = () => {
        this.setState({
            selectedRowKeys: [],
            selectedRows: []
        });
    };

    onChangeUser = (value) => {
        const {t} = this.props;
        const {users} = this.state;
        let type = 'MEMBER';
        if(!users.length) {
            type = "LEADER";
        }
        this.togglePopoverUser();
        this.setState({loadingAddUser: true});
        this.props.addUser(value, type, (error) => {
            this.setState({loadingAddUser: false});
            this.resetSelectedRowAndKeys();
            if(!error) {
                notification.success({
                    message: t('message.success')
                });
                this.props.fetchGroup();
                this.fetchUsers();
                this.fetchUsersNotIn({}, this.setInitialUsersNotInFromCallBack);
            } else {
                notification.error({
                    message: error.message
                });
            }
        });
    };

    onSearchUser = (value) => {
        this.fetchUsersNotIn({username: value});
    };

    onVisibleChange = (visible) => {
        this.setState({
            isShowPopup: visible
        }, () => {
            if(visible) {
                this.onSearchUser("");
            }
        });
    };

    onRemoveUser = () => {
        const {t} = this.props;
        const {selectedRows} = this.state;

        let userIds = _.map(selectedRows, 'id');
        this.setState({loadingDeleteUser: true});
        this.props.removeUser(userIds, (error) => {
            this.setState({loadingDeleteUser: false});
            this.resetSelectedRowAndKeys();
            if(!error) {
                notification.success({
                    message: t('message.success')
                });
                this.props.fetchGroup();
                this.fetchUsers();
                this.fetchUsersNotIn({}, this.setInitialUsersNotInFromCallBack);
            } else {
                notification.error({
                    message: error.message
                });
            }
        });
    };

    onPopconfirmChange = (visible) => {
        let {selectedRows} = this.state;
        if(selectedRows.length) {
            this.setState({
                isShowPopupDelete: visible
            })
        }
    };

    togglePopoverUser = () => {
        this.setState({isShowPopup: !this.state.isShowPopup});
    };


    render() {
        const {t} = this.props;
        const {users, usersNotIn, initialUsersNotIn, loading, totalItems, pageSize, currentPage, selectedRows, isShowPopup, isShowPopupDelete, loadingAddUser, loadingDeleteUser} = this.state;

        const columns = [
            {title: t("user_list.id"),dataIndex: 'id', key: 'id', width:200,className:"_id _item"},
            {
                title: t("user_list.username"),
                dataIndex: 'username',
                key: 'username',
                width:200,
                className:"_username _item",
                render: (text, item) => {
                    let affix = "";
                    if (item.type === 'LEADER') {
                        affix = <span> ({t('group_user.leader')})</span>
                    }
                    return <span>{text}{affix}</span>;
                }
            },
            {title: t("group_user.display_name"),dataIndex: 'full_name', key: 'full_name', width:200,className:"_full_name _item"},
            {title: t("user_list.email"),dataIndex: 'email', key: 'email', width:200,className:"_email _item"},
            {title: t("table.added_by"),dataIndex: 'created_by', key: 'createdBy', width:200,className:"_created_by _item"},
            {title: t("table.added_at"),dataIndex: 'created_at', key: 'createdAt', render: (text, record) => {return formatDate(text,true)}, width:200,className:"_created_at _item"},
        ];
        let renderOptions = () => {
            let rs = [];
            for(let item of usersNotIn) {
                rs.push(<Option key={item.id} value={item.id}>{item.username}</Option>);
            }
            return rs;
        };
        let content = (
            <Select
                placeholder={t('group_user.add')}
                style={{width: 200}}
                filterOption={false}
                defaultActiveFirstOption={false}
                notFoundContent={<Empty description={t("table.empty")} />}
                onChange={this.onChangeUser}
                onSearch={this.onSearchUser}
                showSearch={true}
                value={[]}
            >
                {renderOptions()}
            </Select>
        );
        return (
            <div className="user-list white-box">
                <div className="text-right mg-bt-10 mg-t-10 mg-bt-20">
                    {initialUsersNotIn.length > 0 && UserService.isAllowed(GROUPS_SCOPES.GROUPS_UPDATE) && (
                        <Popover
                            placement="right"
                            content={content}
                            trigger="click"
                            onVisibleChange={this.onVisibleChange}
                            visible={isShowPopup}
                        >
                            <Button
                                type="primary"
                                className="_btn_add_user mg-r-10"
                                loading={loadingAddUser}
                            >
                                {t("products.add_user")}
                            </Button>
                        </Popover>
                    )}

                    {UserService.isAllowed(GROUPS_SCOPES.GROUPS_UPDATE) && (
                        <Popconfirm
                            onVisibleChange={this.onPopconfirmChange}
                            onConfirm={() => {this.onRemoveUser()}}
                            visible={isShowPopupDelete}
                            title={t("group_user.confirm_delete")}
                            okText={t("popup-confirm.confirm")}
                            cancelText={t("popup-confirm.cancel")}
                        >
                            <Button
                                type="danger"
                                icon="delete"
                                className="_btn_remove_user"
                                disabled={!selectedRows.length}
                                loading={loadingDeleteUser}
                            />
                        </Popconfirm>
                    )}
                </div>

                <Table
                    columns={columns}
                    dataSource={Array.isArray(users) ? users: []}
                    rowKey={record => record.id}
                    pagination={false}
                    loading={loading}
                    rowSelection={this.getConfigRowSelection()}
                    locale={{
                        emptyText: t("table.empty")
                    }}
                />

                {!loading && totalItems > 0 && (
                    <Pagination
                        className="mg-t-15 text-right"
                        showSizeChanger
                        onShowSizeChange={this.onShowSizeChange}
                        onChange={this.onChangePage}
                        pageSizeOptions={['25', '50', '100', '150', '200']}
                        defaultCurrent={1}
                        current={currentPage}
                        pageSize={pageSize}
                        total={totalItems}
                    />
                )}
            </div>
        )
    }
}

export {User};