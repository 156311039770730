import React from 'react';
import {Checkbox, InputNumber, Form, Alert} from "antd";
import * as _ from 'lodash';

class WholeSaleConfig extends React.Component {

    validateFrom = (sku, uid, rule, value, callback) => {
        const {t} = this.props;
        let {getFieldValue, isFieldTouched, validateFields, resetFields} = this.props.form;
        let to = getFieldValue(`wholeSalePrice[${sku}][${uid}][to]`);
        let toPrev;
        if(value || value === 0) {
            if (Number.isInteger(to) && value > to) {
                callback(t('ads.to_must_greater_from'));
                return;
            }

            let indexConfig = this.getIndexRowPriceByUid(sku, uid);

            if (indexConfig > 0 && indexConfig - 1 >= 0) {
                let uidToPrev = this.getUidRowPriceByIndex(sku, indexConfig-1);
                let keyToPrev = `wholeSalePrice[${sku}][${uidToPrev}][to]`;
                toPrev = getFieldValue(keyToPrev);
                if(Number.isInteger(toPrev)) {
                    if (value - toPrev !== 1) {
                        callback(t('ads.quantities_must_be_consecutive'));
                        return;
                    }

                    if (value - toPrev === 1) {
                        if(isFieldTouched(keyToPrev)) {
                            resetFields([keyToPrev]);
                            validateFields([keyToPrev]);
                        }
                    }
                }
            }
        }

        callback();
    };

    validateTo = (sku, uid, rule, value, callback) => {
        const {t} = this.props;
        let {getFieldValue, isFieldTouched,resetFields, validateFields} = this.props.form;

        let from = getFieldValue(`wholeSalePrice[${sku}][${uid}][from]`);
        let fromNext;
        if(value || value === 0) {
            if (Number.isInteger(from) && value < from) {
                callback(t('ads.to_must_greater_from'));
                return;
            }

            let indexConfig = this.getIndexRowPriceByUid(sku, uid);

            if (indexConfig + 1 < this.getWholeSaleConfigArrayBySku(sku).length) {
                let uidFromNext = this.getUidRowPriceByIndex(sku, indexConfig+1);
                let keyFromNext = `wholeSalePrice[${sku}][${uidFromNext}][from]`;
                fromNext = getFieldValue(keyFromNext);
                if(Number.isInteger(fromNext)) {
                    if(fromNext - value !== 1) {
                        callback(t('ads.quantities_must_be_consecutive'));
                        return;
                    }

                    if(fromNext - value === 1) {
                        if(isFieldTouched(keyFromNext)) {
                            resetFields([keyFromNext]);
                            validateFields([keyFromNext]);
                        }
                    }
                }
            }
        }
        callback();
    };

    getWholeSaleConfigArrayBySku = (sku) => {
        const {configWholeSalePrice} = this.props;
        for(let item of configWholeSalePrice) {
            if(item.sku == sku) return item.wholeSalePrice;
        }
        return [];
    };

    getIndexRowPriceByUid = (sku, uid) => {
        let arr = this.getWholeSaleConfigArrayBySku(sku);
        for(let i in arr) {
            let item = arr[i];
            if(item.uid == uid) {
                return parseInt(i);
            }
        }
    };

    getUidRowPriceByIndex = (sku, index) => {
        let arr = this.getWholeSaleConfigArrayBySku(sku);
        return arr[index].uid;
    };

    render() {
        let {configWholeSalePrice, t, onRemoveWholeSaleConfig,
            onAddWholeSaleConfig, toggleCheckAllConfig, toggleCheckConfig, form, validateErrors} = this.props;
        let {getFieldDecorator, getFieldValue} = this.props.form;

        const renderInputFrom = (config, item) => {
            let rules = !config.checked ? [] : [
                {required: true, message: t('message.required')},
                {validator: this.validateFrom.bind(this, config.sku, item.uid)}
            ];
            let fieldKey = `wholeSalePrice[${config.sku}][${item.uid}][from]`;
            let oldValue = getFieldValue(fieldKey);

            if(!config.checked) {
                form.resetFields([fieldKey]);
            }

            return (
                <>
                    {getFieldDecorator(fieldKey, {
                        rules: rules,
                        initialValue: oldValue ? oldValue : item.from
                    })(
                        <InputNumber
                            min={1}
                            placeholder={t("ads.from")}
                            style={{width: '100%'}}
                            className={`_whole_sale_config_from_input`}
                            parser={value => value.replace(/\D+/gm, '')}
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        />
                    )}
                </>
            )
        };

        const renderInputTo = (config, item) => {
            let rules = !config.checked ? [] : [
                {required: true, message: t('message.required')},
                {validator: this.validateTo.bind(this, config.sku, item.uid)}
            ];
            let fieldKey = `wholeSalePrice[${config.sku}][${item.uid}][to]`;
            let oldValue = getFieldValue(fieldKey);

            if(!config.checked) {
                form.resetFields([fieldKey]);
            }

            return (
                <>
                    {getFieldDecorator(fieldKey, {
                        rules: rules,
                        initialValue: oldValue ? oldValue : item.to
                    })(
                        <InputNumber
                            min={1}
                            placeholder={t("ads.to")}
                            style={{width: '100%'}}
                            className={`_whole_sale_config_to`}
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => value.replace(/\D+/gm, '')}
                        />
                    )}
                </>
            )
        };

        const renderInputPrice = (config, item) => {
            let rules = !config.checked ? [] : [
                {required: true, message: t('message.required')},
            ];
            let fieldKey = `wholeSalePrice[${config.sku}][${item.uid}][price]`;
            let oldValue = getFieldValue(fieldKey);

            if(!config.checked) {
                form.resetFields([fieldKey]);
            }

            return (
                <>
                    {getFieldDecorator(fieldKey, {
                        rules: rules,
                        initialValue: oldValue ? oldValue : item.price
                    })(
                        <InputNumber
                            min={0}
                            placeholder={t("ads.price")}
                            style={{width: '100%'}}
                            className={`_whole_sale_config_price`}
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        />
                    )}
                </>
            )
        };

        let checkedAll = configWholeSalePrice.length === _.countBy(configWholeSalePrice, 'checked').true;

        return (
            <>
                {!!configWholeSalePrice.length && (
                    <>
                        <div className="mg-bt-5">
                            {Object.keys(validateErrors).length > 0 && (
                                <Alert message={validateErrors.wholeSaleConfig} type="error" />
                            )}
                        </div>
                        <div className="table-responsive">
                            <table className={"table-config _table_config"}>
                                <thead>
                                    <tr className={"_table_config_header"}>
                                        <th className="pd-5 pd-l-5" width="35">
                                            <Checkbox
                                                className={"_checkbox_all"}
                                                checked={checkedAll}
                                                onChange={toggleCheckAllConfig}
                                            />
                                        </th>
                                        <th className="pd-5 pd-l-5">{t("ads.properties")}</th>
                                        <th className="pd-5 pd-l-5 thead-quantity">
                                            <span className="label-quantity">{t("ads.quantity")}</span>
                                            <span className="label-price">{t("ads.price")}</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                {Array.isArray(configWholeSalePrice) && configWholeSalePrice.map((config, index) => (
                                    <tr key={config.sku}>
                                        <td className="v-text-top pd-l-15 _td_checkbox_item">
                                            <Checkbox
                                                className={`_checkbox_item _checkbox_item_${config.sku}`}
                                                checked={config.checked}
                                                onChange={toggleCheckConfig.bind(this, index)}
                                            />
                                        </td>
                                        <td className="v-text-top pd-l-5 _td_sku_name">
                                            <span
                                                className={"pointer"}
                                                onClick={toggleCheckConfig.bind(this, index)}
                                            >
                                                {config.skuName}
                                                <div><small>{config.sku}</small></div>
                                            </span>
                                        </td>
                                        <td colSpan={2}>
                                            <table className={"table-config-sku-prices"}>
                                                <tbody>
                                                    {config.wholeSalePrice.map((item, index1) => (
                                                        <tr key={`_config_${index1}`} className={`_price_config_row _price_config_row_${index1}`}>
                                                            <td colSpan={2} className={"pd-bt-15"}>
                                                                <Form.Item style={{width: '22%', display: 'inline-block'}} className={"_whole_sale_config_from"}>
                                                                    {renderInputFrom(config, item)}
                                                                </Form.Item>
                                                                <span className="mg-r-15 mg-l-15 mg-t-9 d-inline-block">{t('ads.to')}</span>
                                                                <Form.Item style={{width: '22%', display: 'inline-block', marginRight: 30}} className={"_whole_sale_config_to"}>
                                                                    {renderInputTo(config, item)}
                                                                </Form.Item>
                                                                <Form.Item style={{width: '25%', display: 'inline-block'}} className={"_whole_sale_config_price"}>
                                                                    {renderInputPrice(config, item)}
                                                                </Form.Item>
                                                                <span className="actions">
                                                                {index1 !== 0 && (
                                                                    <span className="delete action _delete_action" onClick={onRemoveWholeSaleConfig.bind(this, index, item.uid)}>{t("button.delete")}</span>
                                                                )}

                                                                    {index1 === (config.wholeSalePrice.length-1) && (
                                                                        <>
                                                                            {index1 !== 0 && index1 !== 4 && (
                                                                                <i className="divider" />
                                                                            )}

                                                                            {index1 !== 4 && (
                                                                                <span className="add action _add_action" onClick={onAddWholeSaleConfig.bind(this, index)}>{t("button.add")}</span>
                                                                            )}
                                                                        </>
                                                                    )}
                                                            </span>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </>
                )}
            </>
        );
    }
}

WholeSaleConfig.defaultProps = {
    configWholeSalePrice: [],
    onRemoveWholeSaleConfig: (index) => {},
    onAddWholeSaleConfig: (index) => {}
};

export {WholeSaleConfig};