import React, {Component} from 'react';
import {Form as AntForm, Row, Col, Input, Tabs, Select} from 'antd';
import {TabProperty} from "./TabProperty";
import {TabVendor} from "./TabVendor";
import {LocalStore} from "../../../utils/LocalStore";
import {EditorInput} from "../../../components/EditorInput";

const TabPane = Tabs.TabPane;
const TAB = {
    property: 'property',
    permission: 'permission'
};

class Form extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const { t, productGroup, loadingProductGroup } = this.props;
        const { getFieldDecorator } = this.props.form;
        return (
            <>
                <div className="white-box">
                    <div className="form-header">
                        <h3 className="pd-l-30 pd-r-30 pd-t-15 pd-bt-15 mg-0">{t('products.page_title')}</h3>
                        <div className="h-line" />
                    </div>
                    <div className="form-body pd-30 pd-t-10 pd-bt-10">
                        <Row gutter={20}>
                            <Col span={8}>
                                <div className="form-item">
                                    <AntForm.Item className={"form-item-item"} label={<span className="bold-400">{t("products.name")}</span>}>
                                        {getFieldDecorator('name', {
                                            rules: [
                                                { required: true, message: t("message.required") },
                                                { max: 160, message: t("products.name_length")}
                                            ],
                                        })(
                                            <Input autoFocus={true} placeholder={t("products.enter_name")} className="_product_name" />
                                        )}
                                    </AntForm.Item>
                                </div>
                            </Col>
                            <Col span={8}>
                                <div className="form-item">
                                    <AntForm.Item className={"form-item-item"} label={<span className="bold-400">{t("products.code")}</span>}>
                                        {getFieldDecorator('code', {
                                            rules: [
                                                { required: true, message: t("message.required") },
                                                { max: 160, message: t("products.code_length")}
                                            ],
                                        })(
                                            <Input placeholder={t("products.enter_code")} className="_product_code" />
                                        )}
                                    </AntForm.Item>
                                </div>
                            </Col>
                            <Col span={8}>
                                <div className="form-item">
                                    <AntForm.Item className={"form-item-item"} label={<span className="bold-400">{t("products.manufactory")}</span>}>
                                        {getFieldDecorator('manufactory', {
                                            rules: [
                                                { max: 160, message: t("products.manufactory")}
                                            ],
                                        })(
                                            <Input placeholder={t("products.enter_manufactory")} className="_product_manufactory" />
                                        )}
                                    </AntForm.Item>
                                </div>
                            </Col>
                            <Col span={8}>
                                <div className="form-item">
                                    <AntForm.Item className={"form-item-item"} label={<span className="bold-400">{t("product.product_group")}</span>}>
                                        {
                                            getFieldDecorator('groupCode')(
                                                <Select loading={loadingProductGroup} placeholder={t("product.select_product_group")}>
                                                    {
                                                        productGroup && productGroup.map(productGroup => {
                                                            return (<Select.Option key={productGroup.code} value={productGroup.code}>{productGroup.name}</Select.Option>)
                                                        })
                                                    }
                                                </Select>
                                            )
                                        }
                                    </AntForm.Item>
                                </div>
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col span={24}>
                                <AntForm.Item className={"form-item-item"} label={<span className="bold-400">{t("products.description")}</span>}>
                                    {getFieldDecorator('description', {})(
                                        <EditorInput
                                            {...this.props}
                                            className="_product_description"
                                            showButton={false}
                                        />
                                    )}
                                </AntForm.Item>
                            </Col>
                        </Row>
                    </div>
                </div>

                <div className="white-box">
                    <Tabs defaultActiveKey="1" animated={false}>
                        <TabPane tab={t("products.properties")} key="property">
                            <div className="pd-20 pd-t-0">
                                <TabProperty {...this.props}/>
                            </div>
                        </TabPane>

                        <TabPane tab={t("products.vendor")} key="permission">
                            <div className="pd-20 pd-t-0">
                                <TabVendor
                                    {...this.props}
                                />
                            </div>
                        </TabPane>
                    </Tabs>
                </div>
            </>
        )
    }
}

export {Form};