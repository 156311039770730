import React, {Component} from 'react';
import {Empty, Form, Input, Modal, Select, Spin} from 'antd';

const Option = Select.Option;

class FormCreateAdset extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    onFocus = () => {
        this.props.resetDataSource('campaigns');
    };

    onSearch = (value) => {
        this.props.fetchCampaign({
            name:value.trim()
        });
    };

    onPressEnter = () => {
        if(this.props.checkPressEnterCreate){
            this.props.onCreate();
        }
    };

    render() {
        const {
            isShowPopUpCreateAdset, onCancel, onCreate, form,t,campaigns,loadingCampaign
        } = this.props;
        const { getFieldDecorator } = form;
        return (
            <Modal
                visible={isShowPopUpCreateAdset}
                title={t("ads_group.create")}
                onCancel={onCancel}
                onOk={onCreate}
                okButtonProps={{
                    className: "_create-adset-save"
                }}
                cancelButtonProps={{
                    className: "_create-adset-cancel",
                }}
                cancelText={t("button.cancel")}
                okText={t("button.save")}
                destroyOnClose={true}
            >
                <Form layout="vertical">
                    <Form.Item label={t("ads_group.name")}>
                        {getFieldDecorator('name', {
                            rules: [{ required: true, message: t("ads_group.validate_name")},{ max: 160, message: t("ads_group.name_length")},{whitespace:true, message: t("ads_group.validate_name")}],
                        })(
                            <Input
                                autoFocus
                                className="_adset_name_create"
                                placeholder={t("ads_group.input_name")}
                                onPressEnter={this.onPressEnter}
                                disabled={!isShowPopUpCreateAdset}
                            />
                        )}
                    </Form.Item>
                    <Form.Item label={"Facebook Adset ID"}>
                        {getFieldDecorator('facebookIds')(
                            <Select
                                mode={'tags'}
                                className="_adset_fb_create"
                                placeholder={t("ads_group.face_placeholder")}
                                disabled={!isShowPopUpCreateAdset}
                            />
                        )}
                    </Form.Item>
                    <Form.Item label={t("ads_list.campaign")}>
                        {getFieldDecorator('campaignId', {
                            rules: [{ required: true, message: t("ads_group.validate_campaign") }],
                        })(
                            <Select
                                showSearch
                                notFoundContent={loadingCampaign ? <div style={{textAlign:"center",}} className="mg-t-50 mg-bt-50"><Spin size="small"/> </div>:(<Empty description={t("table.empty")} />)}
                                placeholder={t("ads_group.select_name")}
                                onFocus={this.onFocus.bind(this)}
                                onSearch={this.onSearch.bind(this)}
                                onSelect={this.onCheckCampaignUseFacebook}
                                className="_select-create-adset"
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                                {campaigns.length && campaigns.map((item, index) => (
                                    <Select.Option key={index} value={item.id} className="_option-campaigns-create">{item.name}</Select.Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

FormCreateAdset = Form.create()(FormCreateAdset);
export {FormCreateAdset}
