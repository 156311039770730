import React, {Component} from 'react';
import {Select} from 'antd';
import {debounce} from 'lodash';
import UserService from "../../services/UserService";
import VendorService from "../../services/VendorService";

class SelectUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: undefined,
            users: [],
            extraUsers: []
        };

        this.handleSearch = debounce(this.handleSearch, 500);
    }

    static getDerivedStateFromProps(props, state) {
        let newState = {...state};
        if ('value' in props) {
            newState.value = props.value;
        }

        return newState;
    }

    componentDidMount() {
        if ('value' in this.props && this.props.value) {
            this.fetchUsers({
                [this.props.searchTerm]: this.props.value
            });
        }
        this.fetchUsers();
    }

    fetchUsers = (filter = {}) => {
        this.setState({loading: true});

        if ('vendorId' in this.props) {
            VendorService.getUserInVendor(this.props.vendorId, filter, (error, response) => {
                this.setState({loading: false});
                if (!error) {
                    this.setState({
                        users: response.list
                    });
                }
            });
        }
        else {
            UserService.getUsers(filter, (error, response) => {
                this.setState({loading: false});
                if (!error) {
                    this.setState({
                        users: response.list
                    });
                }
            });
        }

    };

    handleChange = (values) => {
        this.setState({value: values});
        this.props.onChange(values);
    };

    handleSearch = (keyword) => {
        this.fetchUsers({
            username: keyword
        })
    };

    render() {
        const {users, loading, value} = this.state;
        const {t} = this.props;
        return (
            <Select
                allowClear={true}
                loading={loading}
                showSearch={true}
                filterOption={false}
                onSearch={this.handleSearch}
                value={value}
                onChange={this.handleChange}
                className={`width-100pc ${this.props.className}`}
                placeholder={t("placeholder.select_user")}
            >
                {users.map(item => (
                    <Select.Option key={item.id} value={item[this.props.valueField]}>{item.username}</Select.Option>
                ))}
            </Select>
        );
    }
}

SelectUser.defaultProps = {
    searchTerm: "username",
    valueField: "username",
    className: "",
    onChange: (values) => {}
};

export default SelectUser;

