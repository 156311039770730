import * as _ from 'lodash';
import EagleClient from "../utils/api/EagleClient";

export default class WareHouseService {

    static getAllIssue(filter, cb) {
        let endpoint = `/v2/issues`;
        endpoint += filter;

        EagleClient.request({
            endpoint: endpoint,
            method: "GET",
            mode: "free"
        }, cb);
    }

    static WarehouseActive(cb) {
        let endpoint = "/v2/warehouses/active";

        EagleClient.request({
            endpoint: endpoint,
            method: "GET",
            mode: "free"
        }, cb);
    }


    static deleteWarehouse(filter, cb) {
        let endpoint = "/v2/issues";
        endpoint += filter;

        EagleClient.request({
            endpoint: endpoint,
            method: "DELETE",
            mode: "free"
        }, cb);
    }


    static getProduct(code, filter, cb) {
        let endpoint = `/warehouses/${code}/products`;

        EagleClient.request({
            endpoint: endpoint,
            method: "GET",
            mode: "free",
            queryString: filter
        }, cb);
    }

    static deleteProduct(filter, cb) {

    }

    static getStockReceipt(filter, cb) {
        let endpoint = `/v2/receipts`;
        endpoint += filter;

        EagleClient.request({
            endpoint: endpoint,
            method: "GET",
            mode: "free"
        }, cb);
    }

    static deleteStockReceipt(filter, cb) {
        let endpoint = "/v2/receipts";
        endpoint += filter;

        EagleClient.request({
            endpoint: endpoint,
            method: "DELETE",
            mode: "free"
        }, cb);
    }

    static getAllWarehouses(filter, cb) {
        let defaultFilter = {
            sort: 'createdAt',
            order: 'desc'
        };
        filter = _.merge(defaultFilter, filter);
        let endpoint = "/Warehouses";
        EagleClient.request({
            endpoint: endpoint,
            method: 'GET',
            queryString: filter
        }, cb)
    }

    static createWarehouse(data, cb) {
        let endpoint = "/warehouses";
        EagleClient.request({
            endpoint: endpoint,
            method: 'POST',
            body: data
        }, cb)
    }

    static getDetailWareHouse = (code, cb) => {
        let endpoint = `/Warehouses/${code}`;
        EagleClient.request({
            endpoint: endpoint,
            method: 'GET'
        }, cb);
    };

    static getWareHouseIssues = (wareHouseCode, filterObject = {}, cb) => {
        let endpoint = `/Warehouses/${wareHouseCode}/issues`;
        EagleClient.request({
            endpoint: endpoint,
            method: 'GET',
            queryString: filterObject
        }, cb);
    };

    static getWareHouseReceipts = (wareHouseCode, filterObject = {}, cb) => {
        let endpoint = `/Warehouses/${wareHouseCode}/receipts`;
        EagleClient.request({
            endpoint: endpoint,
            method: 'GET',
            queryString: filterObject
        }, cb);
    };

    static addUserToWareHouse = (wareHouseCode, userIds, cb) => {
        let config = {
            endpoint: `/v2/warehouses/${wareHouseCode}/users`,
            method: "POST",
            body: userIds
        };
        EagleClient.request(config, cb);
    };

    static removeUserFromWareHouse = (wareHouseCode, userIds, cb) => {
        let config = {
            endpoint: `/v2/warehouses/${wareHouseCode}/users`,
            method: "DELETE",
            body: userIds
        };
        EagleClient.request(config, cb);
    };

    static getListUserInWareHouse = (wareHouseCode, filter, cb) => {
        let config = {
            endpoint: `/v2/warehouses/${wareHouseCode}/users`,
            method: "GET",
            queryString: filter
        };
        EagleClient.request(config, cb);
    };

    static getListUserNotInWareHouse = (wareHouseCode, filter, cb) => {
        let config = {
            endpoint: `/v2/warehouses/${wareHouseCode}/users-not-in`,
            method: "GET",
            queryString: filter
        };
        EagleClient.request(config, cb);
    };

    static updateWareHouse = (code, data, cb) => {
        let config = {
            endpoint: `/Warehouses/${code}`,
            method: "PUT",
            body: data
        };
        EagleClient.request(config, cb);
    };

    static addGroups = (code, payload, cb) => {
        let config = {
            endpoint: `/warehouses/${code}/groups`,
            method: "POST",
            body: payload
        };
        EagleClient.request(config, cb);
    };

    static removeGroups = (code, payload, cb) => {
        let config = {
            endpoint: `/warehouses/${code}/groups`,
            method: "DELETE",
            body: payload
        };
        EagleClient.request(config, cb);
    };

    static getGroups = (code, filter, cb) => {
        let config = {
            endpoint: `/warehouses/${code}/groups`,
            method: "GET",
            queryString: filter
        };
        EagleClient.request(config, cb);
    };

    static getGroupsNotIn = (code, filter, cb) => {
        let config = {
            endpoint: `/warehouses/${code}/groups-not-in`,
            method: "GET",
            queryString: filter
        };
        EagleClient.request(config, cb);
    };
}