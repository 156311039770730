import EagleClient from "../utils/api/EagleClient";
import _ from 'lodash';

export default class AdService {
    static getAds(filter, cb) {
        let endpoint = "/CampaignAds";
        EagleClient.request({
            endpoint: endpoint,
            method: "GET",
            mode: "free",
            queryString: filter
        }, cb);
    }

    static createAd(data, cb) {
        EagleClient.request({
            endpoint: "/CampaignAds/createCampaignAdCustom",
            method: "POST",
            body: data
        }, cb);
    }

    static updateAd(data, cb) {
        EagleClient.request({
            endpoint: "/CampaignAds/"+data.id+"/updateCampaignAd",
            method: "PATCH",
            body: data
        }, cb);
    }

    static updateAdQuickly(data, cb) {
        EagleClient.request({
            endpoint: "/CampaignAds/"+data.id+"/updateQuickly",
            method: "PUT",
            body: data
        }, cb);
    }

    static getById(id, filter = {}, cb) {
        if(typeof filter === 'function') {
            cb = filter;
            filter = {};
        }
        let endpoint = "/CampaignAds/"+id;
        EagleClient.request({
            endpoint: endpoint,
            method: "GET",
            queryString: filter
        }, cb);
    }

    static toggleDefault(id, cb) {
        EagleClient.request({
            endpoint: "/CampaignAds/"+id+"/toggle-default",
            method: "POST"
        }, cb);
    }

    static deleteAd(id, cb) {
        EagleClient.request({
            endpoint: `/CampaignAds/${id}`,
            method: "DELETE",
        }, cb);
    }

    static getLandingUrls(id, cb) {
        let endpoint = "/CampaignAds/"+id+"/landing-urls";
        EagleClient.request({
            endpoint: endpoint,
            method: "GET"
        }, cb);
    }

    static getGifts(id, query, cb) {
        let endpoint = "/CampaignAds/"+id+"/gifts";
        EagleClient.request({
            endpoint: endpoint,
            method: "GET",
            queryString: query
        }, cb);
    }

    static addGift(id, data, cb) {
        let endpoint = "/CampaignAds/"+id+"/gifts";
        EagleClient.request({
            endpoint: endpoint,
            method: "POST",
            body: data
        }, cb);
    }

    static updateGift(id, giftId, data, cb) {
        let endpoint = `/CampaignAds/${id}/gifts/${giftId}`;
        EagleClient.request({
            endpoint: endpoint,
            method: "PATCH",
            body: data
        }, cb);
    }

    static deleteGift(id, giftId, cb) {
        let endpoint = `/CampaignAds/${id}/gifts/${giftId}`;
        EagleClient.request({
            endpoint: endpoint,
            method: "DELETE"
        }, cb);
    }
}