import React, {Component} from 'react';
import {translate} from 'react-i18next';
import {notification, Tabs} from 'antd';
import * as _ from 'lodash';
import StandardLayout from "../../../components/StandardLayout";
import {Header} from "./Header";
import {BasicInfo} from './BasicInfo';
import {Summary} from "./Summary";
import {Product} from "./Product";
import {Strategy} from "./Strategy";
import {MarketingTarget} from "./MarketingTarget";
import {Faq} from "./Faq";
import {Activity} from "./Activity";
import ContactService from "../../../services/ContactService";
import {arrayConcat, doCallBack} from "../../../utils/helper";
import CampaignService from "../../../services/CampaignService";
import ProductService from "../../../services/ProductService";
import UserGroupService from "../../../services/UserGroupService";
import OrderService from "../../../services/OrderService";
import {PopupAddEvent} from "./PopupAddEvent";
import AdService from "../../../services/AdService";
import VendorService from "../../../services/VendorService";
import ReturnLead from "./ReturnLead";
import { HistoryCall } from './HistoryCall';
import UserService from '../../../services/UserService';
import {CONTACT_SCOPES} from "../../../utils/api/Scopes";

const TabPane = Tabs.TabPane;

class ContactDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            contact: {},
            vendor: {},
            logs: [],
            loadingLog: false,
            loading: false,
            listProductSku: [],
            listProductSkuDefault: [],
            listSku: [],
            listPrice: [],
            callStatus: [],
            listAds: [],
            loadingProduct: true,
            isCallHistories: false,
            isContactStatus: false,
            currencyUnit: "",
            adGifts: [],
            duplicateOrders: [],
            loadingDuplicateOrders: false
        }
    }

    componentDidMount = () => {
        this.fetchContact((error, response) => {
            this.fetchCampaign();
            this.fetchFaq();
            this.callApiGetCallStatus();
            this.callApiGetAds();
            this.fetchDuplicateOrders();
            if (!error) {
                this.fetchAdGifts(response.adsId);
            }
        });
    };

    callApiGetCallStatus(cbSuccess, cbError) {
        ContactService.callStatuses((error, response) => {
            if(!error) {
                this.setState({callStatus: response});
                if(typeof cbSuccess === 'function') cbSuccess(response);
            } else {
                if(typeof cbError === 'function') cbError(error);
            }
        });
    }

    callApiGetAds(cbSuccess, cbError) {
        ContactService.getAds(this.props.match.params.id, (error, response) => {
            if(!error) {
                this.setState({listAds: response});
                if(typeof cbSuccess === 'function') cbSuccess(response);
            } else {
                if(typeof cbError === 'function') cbError(error);
            }
        });
    }

    fetchContact = (cb) => {
        this.setState({loading: true});
        let id = this.props.match.params.id;
        let filter = {
            include: ['vendor', 'user', 'userCreated', 'country', 'campaign', 'product', 'contactStatus', 'callStatus', 'lastOrder']
        };
        ContactService.getCustomerDetail(filter, id, (error, response) => {
            this.setState({loading: false});
            if(!error) {
                // this.formatPrize(response);
                this.setState({
                    contact: response,
                    vendor: response.vendor,
                    currencyUnit: response.country.currencyUnit
                }, () => {
                    doCallBack(cb, [error, response]);
                });
            } else {
                if (error.statusCode === 404) {
                    this.props.history.replace('/404');
                }
                else if (error.statusCode === 403) {
                    this.props.history.replace('/403');
                }
            }
        });
    };

    displayWholeSalePrice = (configWholeSale) => {
        let list = _.cloneDeep(configWholeSale);
        for (let i = 0; i < list.length; i++) {
            list[i].configWholeSaleDisplay = [{unitPrice:0, quantity:"", total:0}];
            list[i].selected = false;
            list[i].disabled = true;
        }
        return list
    };

    displayListSku = (configWholeSale) => {
        let listSku = _.cloneDeep(this.state.listSku);
        let listSkuDisplay = [];
        for (let i = 0; i < listSku.length; i++) {
            for (let j = 0; j < configWholeSale.length; j++) {
                if(listSku[i].sku === configWholeSale[j].sku) {
                    listSkuDisplay.push(listSku[i])
                }
            }
        }
        return listSkuDisplay
    };

    formatPriceWhenSelectSku = (sku,index) => {
        let list = _.cloneDeep(this.state.listProductSku);
        let listDefault = _.cloneDeep(this.state.listProductSkuDefault);
        for (let i = 0; i < listDefault[0].productSku.length; i++) {
            if(listDefault[0].productSku[i].sku === sku){
                listDefault[0].productSku[i].selected = false;
            }
        }

        for (let i = 0; i < list.length; i++) {
            if(list[i].index === index){
                for (let j = 0; j < list[i].productPrice.length; j++) {
                    if(list[i].productPrice[j].sku === sku){
                        list[i].productPrice[j].configWholeSaleDisplay[0].unitPrice = list[i].productPrice[j].wholeSalePrice[0].price;
                        list[i].productPrice[j].configWholeSaleDisplay[0].quantity = list[i].productPrice[j].wholeSalePrice[0].from;
                        list[i].productPrice[j].configWholeSaleDisplay[0].total = (list[i].productPrice[j].wholeSalePrice[0].price * list[i].productPrice[j].wholeSalePrice[0].from);
                        list[i].productPrice[j].selected = true;
                        list[i].productPrice[j].disabled = false;
                    }
                }

                for (let j = 0; j < list[i].productSku.length; j++) {
                    if(list[i].productSku[j].sku === sku){
                        list[i].productSku[j].disabled = true;
                        list[i].productSku[j].selected = false;
                    }
                }
            }else{
                for (let j = 0; j < list[i].productSku.length; j++) {
                    if(list[i].productSku[j].sku === sku){
                        list[i].productSku[j].selected = false;
                    }
                }
            }
        }

        this.setState({
            listProductSku: list,
            listProductSkuDefault: listDefault
        });
    };

    /**
     * Hàm này thay đổi được giá, và tổng tiền nhưng sẽ theo cấu hình wholeSalePrice của ad
     * @param index
     * @param quantity
     */
    formatPriceWhenChangeQuantityInput = (index,quantity) => {
        let list = _.cloneDeep(this.state.listProductSku);
        let min = 0, max = 0, value = quantity;
        for (let i = 0; i < list.length; i++) {
            if(list[i].index === index){
                for (let j = 0; j < list[i].productPrice.length; j++) {
                    if(list[i].productPrice[j].selected){
                        for (let k = 0; k < list[i].productPrice[j].wholeSalePrice.length; k++) {
                            if(k === 0){
                                min = list[i].productPrice[j].wholeSalePrice[k].from;
                                if(list[i].productPrice[j].wholeSalePrice.length === 1){
                                    max = list[i].productPrice[j].wholeSalePrice[k].to;
                                }
                            }else if(k === list[i].productPrice[j].wholeSalePrice.length - 1){
                                max = list[i].productPrice[j].wholeSalePrice[k].to;
                            }
                        }

                        if(value > max){
                            value = max;
                        }else{
                            if(value < min){
                                value = min;
                            }
                        }
                    }
                }
            }
        }

        for (let i = 0; i < list.length; i++) {
            if(list[i].index === index){
                for (let j = 0; j < list[i].productPrice.length; j++) {
                    if(list[i].productPrice[j].selected){
                        for (let k = 0; k < list[i].productPrice[j].wholeSalePrice.length; k++) {
                            if(value >= list[i].productPrice[j].wholeSalePrice[k].from && value <= list[i].productPrice[j].wholeSalePrice[k].to){
                                list[i].productPrice[j].configWholeSaleDisplay[0].unitPrice = list[i].productPrice[j].wholeSalePrice[k].price;
                                list[i].productPrice[j].configWholeSaleDisplay[0].quantity = value;
                                list[i].productPrice[j].configWholeSaleDisplay[0].total =  list[i].productPrice[j].wholeSalePrice[k].price * value;

                            }
                        }
                    }
                }
            }
        }

        this.setState({
            listProductSku: list,
        });
    };

    /**
     * Thay đổi số lượng và cập nhật tổng tiền
     * @param index
     * @param quantity
     */
    handleChangeSkuQuantity = (index, quantity) => {
        let list = _.cloneDeep(this.state.listProductSku);
        for (let i = 0; i < list.length; i++) {
            if(list[i].index === index){
                for (let j = 0; j < list[i].productPrice.length; j++) {
                    if(list[i].productPrice[j].selected){
                        list[i].productPrice[j].configWholeSaleDisplay[0]['quantity'] = quantity;
                        list[i].productPrice[j].configWholeSaleDisplay[0]['total'] = quantity * list[i].productPrice[j].configWholeSaleDisplay[0]['unitPrice'];
                    }
                }
            }
        }

        this.setState({
            listProductSku: list,
        });
    };

    handleChangeSkuPrice = (index, price) => {
        let list = _.cloneDeep(this.state.listProductSku);
        for (let i = 0; i < list.length; i++) {
            if(list[i].index === index){
                for (let j = 0; j < list[i].productPrice.length; j++) {
                    if(list[i].productPrice[j].selected){
                        list[i].productPrice[j].configWholeSaleDisplay[0]['unitPrice'] = price;
                        list[i].productPrice[j].configWholeSaleDisplay[0]['total'] = price * list[i].productPrice[j].configWholeSaleDisplay[0]['quantity'];
                    }
                }
            }
        }

        this.setState({
            listProductSku: list,
        });
    };

    addSku = () => {
        let index = this.getskuId();
        let listProductSku = _.cloneDeep(this.state.listProductSku);

        let listProductSkuDefault = _.cloneDeep(this.state.listProductSkuDefault);
        let max = listProductSkuDefault[0].productSku.length;

        listProductSkuDefault[0].index = index;

        if(listProductSku.length === 0){
            listProductSku = listProductSku.concat(listProductSkuDefault);
            this.setState({
                listProductSku: listProductSku
            })
        }else if(listProductSku.length < max){
            listProductSku = listProductSku.concat(listProductSkuDefault);
            this.setState({
                listProductSku: listProductSku
            })
        }


    };

    deleteSku = (index,skuDeleted) => {
        let listProductSku = _.cloneDeep(this.state.listProductSku);
        let listProductSkuDefault = _.cloneDeep(this.state.listProductSkuDefault);
        for (let i = 0; i < listProductSku.length; i++) {
            if(listProductSku[i].index === index){
                listProductSku.splice(i,1);
            }
        }

        if(skuDeleted){
            for (let i = 0; i < listProductSku.length; i++) {
                for (let j = 0; j < listProductSku[i].productSku.length; j++) {
                    if(listProductSku[i].productSku[j].sku === skuDeleted){
                        listProductSku[i].productSku[j].selected = true;
                    }
                }
            }

            for (let i = 0; i < listProductSkuDefault.length; i++) {
                for (let j = 0; j < listProductSkuDefault[i].productSku.length; j++) {
                    if(listProductSkuDefault[i].productSku[j].sku === skuDeleted){
                        listProductSkuDefault[i].productSku[j].selected = true;
                    }
                }
            }
        }

        this.setState({
            listProductSku: listProductSku,
            listProductSkuDefault: listProductSkuDefault
        })
    };

    setSku = (data) => {
        for (let i = 0; i < data.length; i++) {
            data[i].selected = true;
            data[i].disabled = false
        }
        return data
    };

    deleteSkuBeforeFetch = (cb) => {
        if(this.state.listProductSku.length){
            this.setState({
                listProductSku: []
            },cb())
        }
    };

    fetchProductSku = (contact,cb = null) => {
        this.setState({loadingProduct:true});
        let that = this;
        let listProductSku = [];
        ProductService.getSku(contact.productId, (error, res) => {
            if (!error) {
                this.setState({
                    listSku: this.setSku(res)
                },function(){
                    AdService.getById(contact.adsId,{}, (error, res) => {
                        if (!error) {
                            let listSkuDisplay = that.displayListSku(res.configWholeSalePrice);
                            this.setState({
                                listPrice: that.displayWholeSalePrice(res.configWholeSalePrice),
                                listSku: listSkuDisplay
                            },function(){
                                listProductSku.push({
                                    productSku: this.state.listSku,
                                    productPrice: this.state.listPrice,
                                    index: that.getskuId()
                                });

                                this.setState({
                                    loadingProduct: false,
                                    listProductSku: listProductSku,
                                    listProductSkuDefault: listProductSku,
                                },function () {
                                    if(typeof cb === 'function'){
                                        cb();
                                    }
                                });
                            })
                        }
                    });
                })
            }
        });
    };

    getOrderInfomation = (listOrderItems,index) => {
        let totalList = [];
        let listProductSku = _.cloneDeep(this.state.listProductSku);
        let listPrice = listProductSku[0].productPrice;
        let listSku = listProductSku[0].productSku;
        let productPrice = [];
        let productSku = [];
        let orderItems = _.chunk(listOrderItems, 1);
        let flagPrice = false;
        let flagSku = false;

        for (let j = 0; j < 1; j++) {
            productPrice = [];
            productSku = [];
            for (let i = 0; i < listPrice.length; i++) {
                if(listPrice[i].sku === orderItems[index][0].sku){
                    listPrice[i].selected = true;
                    listPrice[i].disabled = false;
                    listPrice[i].configWholeSaleDisplay[0].quantity = orderItems[index][0].quantity;
                    listPrice[i].configWholeSaleDisplay[0].unitPrice = orderItems[index][0].unitPrice;
                    listPrice[i].configWholeSaleDisplay[0].total = orderItems[index][0].amount;
                    productPrice.push(listPrice[i]);
                    flagPrice = true;
                }else{
                    productPrice.push(listPrice[i]);
                }
            }

            if(flagPrice === false){
                let dataPrice = {};
                let configWholeSaleDisplayData = [{
                    quantity: orderItems[index][0].quantity,
                    unitPrice: orderItems[index][0].unitPrice,
                    total: orderItems[index][0].amount
                }];

                let wholeSalePriceData = [{}];
                dataPrice.sku = orderItems[index][0].sku;
                dataPrice.wholeSalePrice = wholeSalePriceData;
                dataPrice.configWholeSaleDisplay = configWholeSaleDisplayData;
                dataPrice.selected = true;
                dataPrice.disabled = false;
                productPrice.push(dataPrice);
            }

            for (let i = 0; i < listSku.length; i++) {
                if(listSku[i].sku === orderItems[index][0].sku){
                    listSku[i].disabled = true;
                    listSku[i].selected = false;
                    listSku[i].existed = true;
                    productSku.push(listSku[i]);
                    flagSku = true;
                }else{
                    productSku.push(listSku[i]);
                }
            }

            if(flagSku === false){
                let dataSku = {};
                dataSku.sku = orderItems[index][0].sku;
                dataSku.display = orderItems[index][0].property.size;
                dataSku.disabled = true;
                dataSku.selected = false;
                dataSku.existed = true;
                productSku.push(dataSku);
            }

            totalList.push({
                productSku: productSku,
                productPrice: productPrice,
                index: this.getskuId()
            });
        }
        return totalList
    };

    onChangeOrderItems = (listOrderItems) => {
        let totalList = [];
        let skuSelected = [];
        let listProductSkuDefault = _.cloneDeep(this.state.listProductSkuDefault);

        for (let i = 0; i < listOrderItems.orderItems.length; i++) {
            totalList.push(this.getOrderInfomation(listOrderItems.orderItems,i))
        }

        totalList = _.flattenDepth(totalList);

        for (let i = 0; i < totalList.length; i++) {
            for ( let j = 0; j < totalList[i].productPrice.length; j++){
                for ( let k = 0; k < listOrderItems.adsConfig.length; k++){
                    if(totalList[i].productPrice[j].sku === listOrderItems.adsConfig[k].sku && totalList[i].productPrice[j].disabled === false){
                        for(let m = 0; m < listOrderItems.adsConfig[k].wholeSalePrice.length; m++){
                            totalList[i].productPrice[j].wholeSalePrice[m] = listOrderItems.adsConfig[k].wholeSalePrice[m]
                        }
                    }
                }
            }
        }

        for (let i = 0; i < totalList.length; i++) {
            for (let j = 0; j < totalList[i].productSku.length; j++) {
                if(totalList[i].productSku[j].selected === false){
                    skuSelected.push(totalList[i].productSku[j].sku)
                }
            }
        }

        for (let i = 0; i < listProductSkuDefault.length; i++) {
            for (let j = 0; j < listProductSkuDefault[i].productSku.length; j++) {
                if(skuSelected.indexOf(listProductSkuDefault[i].productSku[j].sku) >= 0){
                    listProductSkuDefault[i].productSku[j].selected = false
                }
            }
        }

        this.setState({
            listProductSku: totalList,
            listProductSkuDefault: listProductSkuDefault
        });

    };

    getskuId = () => {
        return _.uniqueId('index')
    };

    getId = () => {
        return this.props.match.params.id;
    };

    fetchOrders = (filter = {}, cb) => {
        let defaultFilter = {
            contact_id: this.getId(),
            order: ["createdAt desc"]
        };
        filter = _.mergeWith(defaultFilter, filter, arrayConcat);
        OrderService.getList(filter, cb);
    };

    fetchNewestOrder = (cb) => {
        const {contact} = this.state;
        this.fetchOrders({
            adsId: contact.adsId,
            include: ["orderItems"]
        }, cb);
    };

    fetchAds = (cb) => {
        ContactService.getAds(this.getId(), (error, response) => {
            doCallBack(cb, [error, response]);
        });
    };

    fetchCallHistories = (cb) => {
        let contactId = this.getId();
        ContactService.getCallHistory(contactId, (err, res) => {
            doCallBack(cb, [err, res]);
        });
    };

    fetchCampaign = (cb) => {
        let {contact} = this.state;
        CampaignService.getById(contact.campaignId,{},(error, response) => {
            this.setState({campaign: response});
            doCallBack(cb, [error, response]);
        });
    };

    updateCampaign = (data, cb) => {
        const {t} = this.props;
        const {contact} = this.state;
        CampaignService.quickUpdateCampaign(contact.campaignId, data, (error, response) => {
            if (error === null) {
                this.fetchCampaign();
                notification.success({
                    message: t("message.success")
                });
            } else {
                if(error.code === 'PRODUCT_NOT_ACTIVE') {
                    notification.error({
                        message: t('error.product_is_not_active')
                    });
                } else {
                    notification.error({
                        message: error.message
                    });
                }
            }
            doCallBack(cb, [error, response])
        });
    };

    fetchFaq = (filter = {}, cb) => {
        let {contact} = this.state;
        let defaultFilter = {
            order: ["createdAt desc"]
        };
        defaultFilter = _.mergeWith(defaultFilter, filter, arrayConcat);
        ProductService.getProductFAQ(contact.productId, defaultFilter, (error, res) => {
            doCallBack(cb, [error, res]);
        });
    };

    updateFaq = (id, data, cb) => {
        let {contact} = this.state;
        let productId = contact.productId;
        ProductService.putProductFAQ(productId, id, data, cb);
    };

    createFaq = (data, cb) => {
        let {contact} = this.state;
        let productId = contact.productId;
        ProductService.postProductFAQ(productId, data, cb);
    };

    fetchLogs = (cb) => {
        this.setState({loadingLog: true});
        ContactService.getContactActivityHistory(this.getId(),(error, response) => {
            this.setState({loadingLog: false});
            if(!error) {
                this.setState({
                    logs: response
                })
            }
            doCallBack(cb, [error, response]);
        });
    };

    fetchContactStatuses = (cb) => {
        ContactService.getContactStatuses(cb);
    };

    updateContactStatus = (id, cb) => {
        let phoneContactId = this.getId();
        const {t} = this.props;
        ContactService.updateContactStatus(phoneContactId, {contactStatusId: id}, (err, response) => {
            if (err === null) {
                notification.success({
                    message: t('message.success')
                });
            } else {
                if(err.code === 404) {
                    this.props.history.replace('/404');
                } else {
                    if(err.code === 'PRODUCT_NOT_ACTIVE') {
                        notification.error({
                            message: t('error.product_is_not_active')
                        });
                    } else {
                        notification.error({
                            message: err.message
                        });
                    }
                }
            }
            doCallBack(cb, [err, response]);
        })
    };

    fetchGroups = (filter = {}, cb) => {
        let {contact, vendor} = this.state;
        VendorService.getGroupInProduct(vendor.id, contact.productId, filter, cb);
    };

    fetchUsersByGroupId = (groupId, cb) => {
        UserGroupService.getMembers(groupId, cb);
    };

    fetchAdGifts = (adId) => {
        AdService.getGifts(adId, {},(error, response) => {
            if (!error) {
                this.setState({
                    adGifts: response
                })
            }
        })
    }

    assignContact = (userId, cb) => {
        let {contact} = this.state;
        ContactService.asignContactManual(contact.id, userId, cb);
    };

    updateDeliveryDate = (data, cb) => {
        let {contact} = this.state;
        ContactService.updateContactDelivery(contact.id, data, cb);
    };

    onChangeTab = (key) => {
        if(key === 'log') {
            this.fetchLogs();
        }
    };

    updateContact = (data, cb) => {
        ContactService.updateContact(data, cb);
    };

    showPopupAddEvent = (active) => {
        this.setState({
            isShowPopUpAddEvent: active,
            isCallHistories: false,
            isContactStatus: false
        })
    };

    handleAddEvent = (data) => {
        const {t} = this.props;
        let phoneContactId = data.phoneContactId;

        this.setState({loading: true});
        ContactService.addEvent(phoneContactId, data, (err, response) => {

            if (!err) {
                this.setState({
                    isCallHistories: true,
                    isContactStatus: true,
                    isShowPopUpAddEvent: false,
                    loading: true
                })

                notification.success({
                    message: t('message.success'),
                    duration: 0.3,
                    onClose: () => {
                        window.location.reload();
                    }
                });
            } else {
                this.setState({loading: false});
                if (err.statusCode === 404) {
                    this.props.history.replace('/404');
                } else {
                    if (err.message.indexOf("does not belong to Ad") >= 0) {
                        let skuName = err.message.split(" ")[1];
                        notification.error({
                            message: `Sku ${skuName} ${t('contact-detail.err_message')}`
                        });
                    } else {
                        notification.error({
                            message: err.message
                        });
                    }
                }
            }
        });

    };

    fetchDuplicateOrders = (cb) => {
        let {contact} = this.state;
        this.setState({loadingDuplicateOrders: true});
        OrderService.getDuplicateOrders(contact.customerPhone, contact.productId, (error, response) => {
            this.setState({duplicateOrders: response});
            this.setState({loadingDuplicateOrders: false});
            doCallBack(cb, [error, response]);
        });
    };

    changeLoading = (val) => {
        this.setState({
            loading: val
        })
    }

    render() {
        const {t} = this.props;
        const {logs, loadingLog, contact, loading, adGifts} = this.state;
        return (
            <StandardLayout {...this.props} loading={loading} title={`Contact detail | ${contact.customerName}`}>
                <div className="contact-detail-page">
                    <Header
                        {...this.props}
                        {...this.state}
                    />
                    <BasicInfo
                        {...this.props}
                        {...this.state}
                    />
                    <div className="tabs-container">
                        <Tabs
                            defaultActiveKey="summary"
                            animated={false}
                            onChange={this.onChangeTab}
                        >
                            <TabPane tab={t('contact-detail.summary')} key="summary">
                                <Summary
                                    {...this.props}
                                    {...this.state}
                                    fetchAds={this.fetchAds}
                                    fetchContact={this.fetchContact}
                                    fetchCallHistories={this.fetchCallHistories}
                                    fetchContactStatuses={this.fetchContactStatuses}
                                    updateContactStatus={this.updateContactStatus}
                                    fetchGroups={this.fetchGroups}
                                    fetchUsersByGroupId={this.fetchUsersByGroupId}
                                    assignContact={this.assignContact}
                                    updateDeliveryDate={this.updateDeliveryDate}
                                    fetchOrders={this.fetchOrders}
                                    updateContact={this.updateContact}
                                    showPopupAddEvent={this.showPopupAddEvent}
                                    fetchNewestOrder={this.fetchNewestOrder}
                                    fetchDuplicateOrders={this.fetchDuplicateOrders}
                                    changeLoading={this.changeLoading}
                                />
                            </TabPane>
                            <TabPane tab={t('contact-detail.product_detail')} key="product">
                                <Product
                                    {...this.props}
                                    {...this.state}
                                />
                            </TabPane>
                            <TabPane tab={t('contact-detail.strategy')} key="strategy">
                                <Strategy
                                    {...this.props}
                                    {...this.state}
                                    updateCampaign={this.updateCampaign}
                                />
                            </TabPane>
                            <TabPane tab={t('contact-detail.marketting_target')} key="target">
                                <MarketingTarget
                                    {...this.props}
                                    {...this.state}
                                    updateCampaign={this.updateCampaign}
                                />
                            </TabPane>
                            <TabPane tab={t('contact-detail.fqa')} key="faq">
                                <Faq
                                    {...this.props}
                                    {...this.state}
                                    fetchFaq={this.fetchFaq}
                                    createFaq={this.createFaq}
                                    updateFaq={this.updateFaq}
                                />
                            </TabPane>
                            <TabPane tab={t('contact-detail.activity_logs')} key="log">
                                <Activity
                                    {...this.props}
                                    logs={logs}
                                    loading={loadingLog}
                                />
                            </TabPane>
                            <TabPane tab={t('contact.return_lead')} key="returnLead">
                                <ReturnLead
                                    {...this.props}
                                />
                            </TabPane>
                            {
                                UserService.isAllowed(CONTACT_SCOPES.CONTACT_HISTORY_CALL) && 
                                (
                                    <TabPane tab='Lịch sử cuộc gọi' key='historyCall'>
                                        <HistoryCall 
                                            {...this.props}
                                            contact={this.state.contact}
                                        />
                                    </TabPane>
                                )
                            }
                        </Tabs>

                        <PopupAddEvent
                            {...this.props}
                            loading={loading}
                            isShowPopUpAddEvent={this.state.isShowPopUpAddEvent}
                            showPopupAddEvent={this.showPopupAddEvent}
                            onCreateProject={this.onCreateProject}
                            contact={this.state.contact}
                            listProductSku={this.state.listProductSku}
                            formatPriceWhenSelectSku={this.formatPriceWhenSelectSku}
                            formatPriceWhenChangeQuantityInput={this.formatPriceWhenChangeQuantityInput}
                            addSku={this.addSku}
                            deleteSku={this.deleteSku}
                            callStatus={this.state.callStatus}
                            fetchProductSku={this.fetchProductSku}
                            listAds={this.state.listAds}
                            deleteSkuBeforeFetch={this.deleteSkuBeforeFetch}
                            onChangeOrderItems={this.onChangeOrderItems}
                            handleAddEvent={this.handleAddEvent}
                            loadingProduct={this.state.loadingProduct}
                            listSku={this.state.listSku}
                            onChangeSkuPrice={this.handleChangeSkuPrice}
                            onChangeSkuQuantity={this.handleChangeSkuQuantity}
                            adGifts={adGifts}
                            fetchAdGifts={this.fetchAdGifts}
                        />

                    </div>
                </div>
            </StandardLayout>
        )
    }
}

export default (translate())(ContactDetail);