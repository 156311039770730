import EagleClient from "../utils/api/EagleClient";
import * as moment from 'moment';
import { getQueryString } from "../utils/api";

export default class OrderService {
    static countOrder(filter, cb) {
        EagleClient.request({
            endpoint: "/Orders/count",
            queryString: filter,
            method: "GET",
            mode: "free"
        }, cb);
    }

    static countNewOrderToday(cb) {
        let start = moment().format("YYYY-MM-DD 00:00:00");
        let end = moment().format("YYYY-MM-DD 23:59:59");
        let filter = {
            where: {
                and: [
                    {createdAt: {gt: start}},
                    {createdAt: {lt: end}}
                ]
            }
        };
        return this.countOrder(filter, cb);
    }

    static countNewOrderYesterday(cb) {
        let start = moment().subtract(1, 'days').format("YYYY-MM-DD 00:00:00");
        let end = moment().subtract(1, 'days').format("YYYY-MM-DD 23:59:59");
        let filter = {
            where: {
                and: [
                    {createdAt: {gt: start}},
                    {createdAt: {lt: end}}
                ]
            }
        };
        return this.countOrder(filter, cb);
    }

    static countDeliveredOrderToday(cb) {
        let start = moment().format("YYYY-MM-DD 00:00:00");
        let end = moment().format("YYYY-MM-DD 23:59:59");
        let filter = {
            where: {
                and: [
                    {deliveryDate: {gt: start}},
                    {deliveryDate: {lt: end}}
                ]
            }
        };
        return this.countOrder(filter, cb);
    }

    static countDeliveredOrderYesterday(cb) {
        let start = moment().subtract(1, 'days').format("YYYY-MM-DD 00:00:00");
        let end = moment().subtract(1, 'days').format("YYYY-MM-DD 23:59:59");
        let filter = {
            where: {
                and: [
                    {deliveryDate: {gt: start}},
                    {deliveryDate: {lt: end}}
                ]
            }
        };
        return this.countOrder(filter, cb);
    }

    static countScheduledOrderYesterday(cb) {
        let start = moment().subtract(1, 'days').format("YYYY-MM-DD 00:00:00");
        let end = moment().subtract(1, 'days').format("YYYY-MM-DD 23:59:59");
        let filter = {
            where: {
                and: [
                    {createdAt: {gt: start}},
                    {createdAt: {lt: end}}
                ]
            }
        };
        return this.countOrder(filter, cb);
    }

    /**
     * Get Order Detail
     * @param filter
     * @param cb
     * @param orderId
     */
    static getOrderDetail(filter, orderId, cb) {
        EagleClient.request({
            endpoint: `Orders/${orderId}`,
            method: 'GET',
            queryString: {filter: filter}
        }, cb);
    }

    /**
     * Get Order Statuses
     * @param cb
     */
    static getOrderStatuses(cb) {
        EagleClient.request({
            endpoint: `Orders/statuses`,
            method: 'GET'
        }, cb);
    }

    /**
     * Update Order Items
     * @param orderId
     * @param orderItemId
     * @param data
     * @param cb
     */
    static updateOrderItems(orderId, orderItemId, data, cb) {
        EagleClient.request({
            endpoint: `Orders/${orderId}/orderItems/${orderItemId}`,
            method: 'PUT',
            body: data
        }, cb);
    }

    /**
     * Delete orderItem
     * @param orderId
     * @param orderItemId
     * @param cb
     */
    static deleteOrderItem(orderId, orderItemId, cb) {
        EagleClient.request({
            endpoint: `Orders/${orderId}/orderItems/${orderItemId}`,
            method: 'DELETE'
        }, cb);
    }

    /**
     * Add Order Items
     * @param orderId
     * @param data
     * @param cb
     */
    static addOrderItems(orderId, data, cb) {
        EagleClient.request({
            endpoint: `Orders/${orderId}/orderItems`,
            method: 'POST',
            body: data
        }, cb);
    }

    /**
     * Change order status
     * @param orderId
     * @param data
     * @param cb
     */
    static changeOrderStatus(orderId, data, cb) {
        EagleClient.request({
            endpoint: `Orders/${orderId}/status`,
            method: 'PATCH',
            body: data
        }, cb);
    }

    /**
     * update Order
     * @param orderId
     * @param data
     * @param cb
     */
    static updataOrder(orderId, data, cb) {
        EagleClient.request({
            endpoint: `Orders/${orderId}`,
            method: 'PATCH',
            body: data
        }, cb);
    }

    /**
     * Get Product Sku
     * @param cb
     * @param productId
     */
    static getProductSku(productId, cb) {
        EagleClient.request({
            endpoint: `Products/${productId}/productSku`,
            method: 'GET'
        }, cb);
    }

    /**
     * Add Oder
     * @param data
     * @param cb
     */

    static createOrder(data, cb) {
        EagleClient.request({
            endpoint: `/Orders/createOrder`,
            method: 'POST',
            body: data
        }, cb);
    }


    /**
     * Change order deliver
     * @param orderId
     * @param data
     * @param cb
     */
    static changeOrderDeliverDate(orderId, data, cb) {
        EagleClient.request({
            endpoint: `/Orders/${orderId}`,
            method: 'PATCH',
            body: data
        }, cb);
    }

    /**
     * Import tracking number
     * @param filter
     * @param file
     * @param courier
     * @param cb
     */
    static importTrackingNumber(filter = {},file, courier, cb) {
        let formData = new FormData();
        formData.append('file', file);
        if(!filter) filter = {};
        filter.courier = courier;
        EagleClient.request({
            endpoint: `/Orders/import`,
            method: 'POST',
            file: formData,
            queryString: filter
        }, cb);
    }
    /**
     * Lấy lịch sử hành động của đơn
     * @param orderId
     * @param limit
     * @param offset
     * @param cb
     */
    static getOrderActivityHistory(orderId,limit,offset,cb) {
        EagleClient.request({
            endpoint: `Orders/${orderId}/activityHistory?limit=${limit}&offset=${offset}`,
            method: 'GET'
        }, cb);
    }

    /**
     * Get estimate shipping code
     * @param orderId
     * @param cb
     */
    static getEstimateShippingCost(orderId, cb) {
        EagleClient.request({
            endpoint: `/Orders/${orderId}/estimate-shipping-cost`,
            method: 'GET'
        }, cb);
    }

    static createExchangeOrder(data, cb) {
        EagleClient.request({
            endpoint: `/Orders/${data.id}/create-exchange-order`,
            method: 'POST',
            body: data,
            mode: 'free'
        }, cb);
    }

    static togglePay(orderId, cb) {
        EagleClient.request({
            endpoint: `/Orders/${orderId}/paid`,
            method: 'POST',
            mode: 'free'
        }, cb);
    }

    static assignOrderToSaleNormal(data, cb) {
        EagleClient.request({
            endpoint: `Orders/${data.orderId}/assign-to`,
            method: 'POST',
            body: {userId:data.userId},
            mode: EagleClient.REQUEST_MODE.ALL
        }, cb);
    }

    static unAssignOrder(orderId, cb) {
        EagleClient.request({
            endpoint: `Orders/${orderId}/assign-to`,
            method: 'DELETE'
        }, cb);
    }

    static getList(filter = {}, cb) {
        EagleClient.request({
            endpoint: 'Orders',
            method: 'GET',
            queryString: filter
        }, cb);
    }

    static exportOrder(filter = {}, cb) {
        EagleClient.request({
            endpoint: 'Orders/export',
            method: 'POST',
            body: {...filter}
        }, cb);
    }

    static exportOrderForFinancial(filter = {}, cb) {
        EagleClient.request({
            endpoint: `Orders/export-for-financial`,
            method: 'POST',
            body: {...filter}
        }, cb);
    }

    static exportOrderForCarrierUnit(filter = {}, cb) {
        EagleClient.request({
            endpoint: `Orders/export-for-carrier-unit`,
            method: 'POST',
            body: {...filter}
        }, cb);
    }

    static addGift(orderId, data, cb) {
        EagleClient.request({
            endpoint: `Orders/${orderId}/gifts`,
            method: 'POST',
            body: data
        }, cb);
    }

    static getGift(orderId, cb) {
        EagleClient.request({
            endpoint: `Orders/${orderId}/gifts`,
            method: 'GET'
        }, cb);
    }

    static syncM28(orderId, cb) {
        EagleClient.request({
            endpoint: `Orders/${orderId}/sync-m28`,
            method: 'POST'
        }, cb);
    }

    static updateCollectedCod(orderId, collectedCod, cb) {
        EagleClient.request({
            endpoint: `Orders/${orderId}/collected-cod`,
            method: 'PUT',
            body: {
                collectedCod
            }
        }, cb);
    }

    static getDuplicateOrders(customerPhone, productId,cb) {
        const dateTo = moment().endOf('day').toISOString();
        const dateFrom = moment().subtract(30,'days').startOf('day').toISOString();
        const queryString = getQueryString({
            date_from: dateFrom,
            date_to: dateTo,
            customer_phone: customerPhone,
            product_id: productId
        });
        EagleClient.request({
            endpoint: `Orders/duplicate-orders${queryString}`,
            method: 'GET'
        }, cb)
    }
}