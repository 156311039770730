export const CONTACT_SCOPES = {
    CONTACT_CREATE: 'contact:create',
    CONTACT_VIEW: 'contact:view:restrict',
    CONTACT_VIEW_ALL: 'contact:view:all',
    CONTACT_ADD_EVENT: 'contact:event:create',
    CONTACT_CHANGE_STATUS: 'contact:update:status',
    CONTACT_CHANGE_SCHEDULED_DELIVERY_DATE: 'contact:update:schedule',
    CONTACT_ASSIGN: 'contact:assign',
    CONTACT_EDIT_NAME: 'contact:update:name',
    CONTACT_EDIT_NOTE: 'contact:update:note',
    CONTACT_EXPORT_ORDER: 'contact:export:order',
    CONTACT_EXPORT_CONTACT: 'contact:export:contact',
    CONTACT_EDIT_FACEBOOK: 'contact:update:fblink',
    CONTACT_GET: 'contact:getcontact',
    CONTACT_DELETE: 'contact:update:delete',
    CONTACT_UPDATE_INFO: 'contact:update:info',
    CONTACT_UPDATE_ADDRESS: 'contact:update:address',
    CONTACT_UPDATE_PRICE: 'contact:update:price',
    CONTACT_TO_CALL: 'contact:clicktocall',
    CONTACT_HISTORY_CALL: 'contact:historycall',
};

export const ORDER_SCOPES = {
    ORDER_VIEW: 'order:view',
    ORDER_VIEW_ALL: "order:view:all",
    ORDER_CHANGE_STATUS: 'order:update:status',
    ORDER_UPDATE_ATTRS: 'order:update:attrs',
    ORDER_CREATE_EXCHANGE: 'order:create:exchange',
    ORDER_UPDATE_ADDR: 'order:update:addr',
    ORDER_UPDATE_NOTE: 'order:update:note',
    ORDER_PAY_STATUS: 'order:financial:mark_as_paid',
    ORDER_ASSIGN: 'order:assign',
    ORDER_UPDATE_PRICE: 'order:update:price',
    ORDER_EXPORT_PICKED_UP: 'order:export:picked_up',
    ORDER_EXPORT_M28: 'export_to_m28',
    ORDER_EXPORT_FINANCIAL: "order:export:financial",
    ORDER_SYNC_M28: "order:sync:m28"
};

export const CAMPAIGNS_SCOPES = {
    CAMPAIGNS_CREATE: 'campaign:create',
    CAMPAIGNS_IMPORT: 'campaign:import',
    CAMPAIGNS_UPDATE: 'campaign:update',
    CAMPAIGNS_VIEW: 'campaign:view',
    CAMPAIGNS_PRODUCT_CREATE: 'campaign:product:create',
    CAMPAIGNS_PRODUCT_DELETE: 'campaign:product:delete',
    CAMPAIGNS_PRODUCT_UPDATE: 'campaign:product:update',
    CAMPAIGNS_PRODUCT_VIEW: 'campaign:product:view',
    CAMPAIGNS_PRODUCT_ASSIGN: 'campaign:product:assign',
    CAMPAIGNS_DELETE: 'campaign:update:delete',
    ADSET_DELETE: 'campaign:update:delete_adset',
    ADS_DELETE: 'campaign:update:delete_ads',
};

export const REPORT_SCOPES = {
    REPORT_CREATE: 'report:create',
    REPORT_DELETE: 'report:delete',
    REPORT_UPDATE: 'report:update',
    REPORT_VIEW: 'report:view',
};

export const WAREHOUSE_SCOPES = {
    WAREHOUSE_VIEW: 'warehouse:view',
    WAREHOUSE_CREATE: 'warehouse:create',
    WAREHOUSE_UPDATE: 'warehouse:update',
    WAREHOUSE_DELETE: 'warehouse:delete',

    WAREHOUSE_STOCKS_VIEW: 'warehouse:stocks',

    WAREHOUSE_ISSUES_VIEW: 'warehouse:issue:view',
    WAREHOUSE_ISSUES_UPDATE: 'warehouse:issue:update',
    WAREHOUSE_ISSUES_CREATE: 'warehouse:issue:create',
    WAREHOUSE_ISSUES_PROCESS: 'warehouse:issue:process',

    WAREHOUSE_RECEIPTS_VIEW: 'warehouse:receipt:view',
    WAREHOUSE_RECEIPTS_UPDATE: 'warehouse:receipt:update',
    WAREHOUSE_RECEIPTS_CREATE: 'warehouse:receipt:create',
    WAREHOUSE_RECEIPTS_PROCESS: 'warehouse:receipt:process'
};

export const GROUPS_SCOPES = {
    GROUPS_CREATE: 'user:group:create',
    GROUPS_UPDATE: 'user:group:update',
    GROUPS_DELETE: 'user:group:delete',
    GROUPS_VIEW: 'user:group:view'
};

export const VENDORS_SCOPES = {
    VENDORS_CREATE: 'vendor:create',
    VENDORS_ADD_USER: 'vendor:user:add',
    VENDORS_SET_USER_ROLE: 'vendor:user:setrole',
    VENDORS_VIEW: 'vendor:view',
    VENDORS_UPDATE_ROLES: 'vendor:setting:roles'
};

export const SETTINGS_SCOPES = {
    SETTING_ASSIGN: 'system:config:get_contact',
    SETTING_VIEW: 'system:config:view',
};

export const PRODUCT_SCOPES = {
    PRODUCT_MANAGE: "management:product"
};

export const DELIVERYCASE = {
    DELIVERY_CASE_UPDATE: "delivery_case:update:all",
    DELIVERY_CASE_VIEW_ALL: "delivery_case:view:all",
    DELIVERY_CASE_VIEW_RESTRICT: "delivery_case:view:restrict"
}

export const STRINGEE = {
    VIEW_ADD_PAGE: "stringee:view:add:page"
}