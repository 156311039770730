import React, {Component} from 'react';
import {Button, Card, DatePicker, Empty, Form, Icon, Input, message, Modal, Popconfirm, Popover, Select, Tooltip} from 'antd';
import * as moment from 'moment';
import * as _ from 'lodash';
import {doCallBack, formatDate, isUrl, objectGet} from "../../../utils/helper";
import {notification} from "antd/lib/index";
import UserService from "../../../services/UserService";
import {CONTACT_SCOPES} from "../../../utils/api/Scopes";
import {AssignContactManual} from "../../../components/AssignContactManual";
import {SummaryContactStatuses} from "./SummaryContactStatuses";
import StringeeService from '../../../services/StringeeService';
import {parseSecondToTime} from '../../../utils/common';
import { CALLSTATUS, STRINGEEAUTH, STRINGEE_ERROR } from '../../../utils/api/Consts';
import LocationService from '../../../services/LocationService';
import { LocalStore } from '../../../utils/LocalStore';
import {APP} from "../../../config/app";

const Option = Select.Option;

class SummaryInfo extends Component {

    constructor(props) {
        super(props);
        let vendor = LocalStore.getInstance().read('vendor');
        this.state = {
            editMode: false,
            loading: false,
            listAds: [],
            contactStatuses: [],
            contactStatusSelected: objectGet(this.props.contact, 'contactStatus', {}),
            initialContactStatusSelected: {},
            isShowPopupContactStatus: false,
            isShowPopupAssignTo: false,
            isShowModalConfirmChangeContactStatus: false,
            isShowEditContactStatus: false,
            groups: [],
            users: [],
            isStringeeAuthen: false,
            isCalling: false,
            callState: '',
            customerPhone: '',
            countTime: 0,
            isAnswerPhone: false,
            isMute: false,
            provinces: [],
            cities: [],
            areas: [],
            vendor: vendor,
            addressDetail: null,
            districtName: null
        };
        this.assignContactRef = React.createRef();
        this.countTimeInterval = '';
    };

    static getDerivedStateFromProps(nextProps, state) {
        let item = objectGet(nextProps.contact, 'contactStatus');
        // Should be a controlled component.
        if (!Object.keys(state.contactStatusSelected).length && item) {
            let contactStatusSelected = {
                id: item.id,
                key: item.key,
                isEnd: item.isEnd
            };
            return {
                contactStatusSelected,
                initialContactStatusSelected: contactStatusSelected
            };
        }

        return null;
    }

    componentDidMount = () => {
        this.props.fetchAds((error, response) => {
            if(!error) {
                this.setState({
                    listAds: response
                })
            }
        });

        this.fetchContactStatuses();

        const {vendor} = this.state;
        this.callApiGetProvince(vendor.countryId);
    };

    fetchContactStatuses = (cb) => {
        this.props.fetchContactStatuses((error, response) => {
            if(!error) {
                this.setState({
                    contactStatuses: response
                })
            }
            doCallBack(cb, [error, response]);
        });
    };

    componentDidUpdate = (prevProps) => {
        if(prevProps.isContactStatus !== this.props.isContactStatus && this.props.isContactStatus === true){
            this.props.fetchContact(
                () => {
                    this.setState({
                        contactStatusSelected: objectGet(this.props.contact, 'contactStatus', {})
                    });
                }
            );
        }

        if(this.state.isCalling){
            document.body.style.overflow = 'auto'
        }

        if(!this.state.isCalling){
            document.body.style.removeProperty('overflow');
        }

        if(prevProps.contact !== this.props.contact && this.props.contact.provinceId){
            const id = this.props.contact.provinceId;
            if(id){
                this.callApiGetCity(id);    
            }
        }

        if(prevProps.contact !== this.props.contact && this.props.contact.campaign){
            const campaignCode = this.props.contact.campaign.code;

            this.connectStringee(campaignCode);  
        }
    };

    toggleEditMode = () => {
        this.setState({
            editMode: !this.state.editMode
        });
    };

    onSubmit = (e) => {
        e.preventDefault();
        const {t} = this.props;
        this.props.form.validateFields((err, values) => {
            if(!err) {
                _.each(values, (v, k) => {
                    if(v) values[k] = v.toString().trim();
                });
                values.id = this.props.match.params.id;
                this.setState({loading: true});
                this.props.updateContact(values, (error) => {
                    this.setState({loading: false});
                    if(!error) {
                        notification.success({
                            message: t("message.success"),
                        });
                        this.setState({editMode: false});
                        this.props.fetchContact();
                    } else {
                        if(error.code === 'PRODUCT_NOT_ACTIVE') {
                            notification.error({
                                message: t('error.product_is_not_active')
                            });
                        } else if (error.code === "ACCESS_DENIED_UPDATE_CONTACT_ADDRESS") {
                            notification.error({
                                message: t('error.access_denied_update_contact_address')
                            });
                        } else {
                            notification.error({
                                message: error.message
                            });
                        }
                    }
                })
            }
        });
    };

    onChangeContactStatus = (value, option) => {
        this.setState({
            contactStatusSelected: value
        }, () => {
            this.toggleModalConfirmChangeContactStatus();
        });
    };

    toggleModalConfirmChangeContactStatus = () => {
        const {t} = this.props;
        this.setState({
            isShowModalConfirmChangeContactStatus: !this.state.isShowModalConfirmChangeContactStatus
        }, () => {
            Modal.confirm({
                title: t('contact-detail.confirm_change_message'),
                okText: t('button.yes'),
                cancelText: t('button.no'),
                onOk: this.onConfirmChangeContactStatus,
                onCancel: this.onCancelChangeContactStatus
            });
        });
    };

    showChangeContactStatus = () => {
        if(UserService.isAllowed(CONTACT_SCOPES.CONTACT_CHANGE_STATUS)) {
            this.setState({isShowEditContactStatus: true});
        }
    };

    toggleChangeContactStatus = () => {
        this.setState({isShowEditContactStatus: !this.state.isShowEditContactStatus});
    };

    onConfirmChangeContactStatus = () => {
        let {contactStatusSelected} = this.state;
        this.props.updateContactStatus(contactStatusSelected.id, () => {
            this.props.fetchContact(() => {
                this.setState({contactStatusSelected: {}});
            });
        });
    };

    onCancelChangeContactStatus = () => {
        this.setState({
            contactStatusSelected: this.state.initialContactStatusSelected
        });
    };
    renderRow = (key, value, keyWrapperClass = "", valueWrapperClass = "", contact, isContactPhone = false) => {
        if(isContactPhone && UserService.isAllowed(CONTACT_SCOPES.CONTACT_TO_CALL) && this.state.isStringeeAuthen){
            return (
                <div className="info-row">
                    <label className={`label bold-400 ${keyWrapperClass}`}>{key}: </label>
                    <span className={`text ${valueWrapperClass}`}>
                        {value}
                        <i onClick={this.handleCallStringee.bind(this, contact)} data-icon-call-type="" className="fa fa-phone" aria-hidden="true" style={{ color: 'black', fontSize: '18px' }} />
                    </span>
                </div>
            )
        }
        return (
            <div className="info-row">
                <label className={`label bold-400 ${keyWrapperClass}`}>{key}: </label>
                <span className={`text ${valueWrapperClass}`}>{value}</span>
            </div>
        );
    };

    onPopoverAssignTo = (visible) => {
        let {contactStatusSelected} = this.state;
        if(visible) {
            if(!UserService.isAllowed(CONTACT_SCOPES.CONTACT_ASSIGN) || contactStatusSelected.isEnd) {
                this.setState({isShowPopupAssignTo: false});
            } else {
                this.setState({isShowPopupAssignTo: true}, () => {
                    this.props.fetchGroups({}, (error, response) => {
                        if(!error) {
                            this.setState({groups: response.list});
                        }
                    });
                });
            }
        } else {
            this.setState({isShowPopupAssignTo: false});
        }
    };

    onChangeUser = () => {
        this.setState({isShowPopupAssignTo: false});
    };

    onAssignContact = () => {
        this.props.fetchContact();
    };

    onConfirmUnAssign = () => {
        this.onUnAssign();
    };

    onUnAssign = () => {
        const {t} = this.props;
        this.props.assignContact(0, (error) => {
            if(!error) {
                notification.success({
                    message: t('message.success')
                });
            } else {
                notification.error({
                    message: error.message
                });
            }
            
            if(this.assignContactRef.current){
                this.assignContactRef.current.resetState();
            }
            
            this.props.fetchContact();
        });
    };

    onChangeDeliveryDate = (date, dateString) => {
        const {t} = this.props;
        this.props.updateDeliveryDate({
            deliveryDate: date.format('YYYY-MM-DD')
        }, (error) => {
            if(!error) {
                notification.success({
                    message: t('message.success')
                });
                this.props.fetchContact();
            } else {
                if(error.code === 'PRODUCT_NOT_ACTIVE') {
                    notification.error({
                        message: t('error.product_is_not_active')
                    });
                } else {
                    notification.error({
                        message: error.message
                    });
                }
            }
        });
    };

    renderTextMode = () => {
        const {t, contact} = this.props;
        let {listAds, contactStatuses, contactStatusSelected,
            isShowPopupAssignTo, isShowEditContactStatus, provinces, cities, areas} = this.state;
        let contentAssignTo, linkFacebook, renderDeliveryDate;
        let isShowAreaId;
        let renderListAds = (
            <ul className="list-unstyled">
                {listAds.map((item, index) => (
                    <li key={index}>{item.name}</li>
                ))}
            </ul>
        );

        let renderContactStatus = (
            <>
                {!isShowEditContactStatus && (
                    <span className={UserService.isAllowed(CONTACT_SCOPES.CONTACT_CHANGE_STATUS) ? `pointer` : 'not-allowed'}>
                        <span className="text-blue" onClick={this.showChangeContactStatus}>
                            {objectGet(contactStatusSelected, 'key')} <Icon type="down" style={{fontSize: 10}} />
                        </span>
                    </span>
                )}
                {isShowEditContactStatus && (
                    <SummaryContactStatuses
                        {...this.props}
                        value={contact.contactStatus}
                        items={contactStatuses}
                        onChange={this.onChangeContactStatus}
                        onHide={() => this.toggleChangeContactStatus()}
                    />
                )}
            </>
        );

        contentAssignTo = (
            <AssignContactManual
                {...this.props}
                onChangeUser={this.onChangeUser}
                onAssignContact={this.onAssignContact}
                ref={this.assignContactRef}
            />
        );

        let renderAssignTo = (
            <>
                <Popover
                    content={contentAssignTo}
                    trigger="click"
                    placement="right"
                    visible={isShowPopupAssignTo}
                    onVisibleChange={this.onPopoverAssignTo}
                >
                    <span className={`_button_click_show_list_user_assign ${UserService.isAllowed(CONTACT_SCOPES.CONTACT_ASSIGN) && !contactStatusSelected.isEnd ? 'pointer' : 'not-allowed'}`}>
                        <span className="text-blue">
                            {contact.user ? contact.user.username: t('contact.unassign_to')}
                            &nbsp;{!contactStatusSelected.isEnd && (<Icon type="down" style={{fontSize: 10}} />)}
                        </span>
                    </span>
                </Popover>

                {contact.assignTo > 0 && !contactStatusSelected.isEnd && (
                    <>
                        &nbsp;<Tooltip title={t('contact.unassign_to')}>
                            <Popconfirm
                                title={t('contact-detail.confirm_change_message')}
                                okText={t('button.yes')}
                                cancelText={t('button.no')}
                                onConfirm={this.onConfirmUnAssign}
                            >
                                <Icon
                                    className={`_button_unassign ${UserService.isAllowed(CONTACT_SCOPES.CONTACT_ASSIGN) && !contactStatusSelected.isEnd ? 'pointer' : 'not-allowed'}`}
                                    type="close-circle"
                                    style={{fontSize: 14, color: 'red'}}/>
                            </Popconfirm>
                        </Tooltip>
                    </>
                )}
            </>
        );

        linkFacebook = objectGet(contact, 'facebookLink');
        if(!linkFacebook) {
            linkFacebook = '---';
        } else {
            linkFacebook = <a href={linkFacebook} target={"_blank"}>{linkFacebook}</a>;
        }

        let isAllowChangeDeliveryDate = UserService.isAllowed(CONTACT_SCOPES.CONTACT_CHANGE_SCHEDULED_DELIVERY_DATE)
            && (contactStatusSelected.key === "L6.1" || contactStatusSelected.key === "L7.1");

        let disabledDate = (current) => {
            if(parseInt(contact.callStatusId) !== 8) {
                return current && current <= moment().subtract(1, 'day');
            }
            return current <= moment().add(2, 'days');
        };
        renderDeliveryDate = (
            <DatePicker
                placeholder={t('contact-detail.select_deliverydate')}
                onChange={this.onChangeDeliveryDate}
                disabled={!isAllowChangeDeliveryDate}
                disabledDate={disabledDate}
                format="DD/MM/YYYY"
                allowClear={false}
                value={contact.deliveryDate ? moment(formatDate(contact.deliveryDate), "DD/MM/YYYY") : null}
            />
        );

        if (contact.countryId === Number(APP.COUNTRY_PHILIPPINE_ID) || contact.countryId === Number(APP.COUNTRY_INDONESIA_ID)) {
            isShowAreaId = true;
        } else {
            isShowAreaId = areas.length > 0;
        }
        
        return (
            <>
                {this.renderRow(t('contact-detail.fullname'), contact.customerName, "", "_summary_contact_name")}
                {this.renderRow(t('contact-detail.phone'), contact.customerPhone, "", "_summary_contact_phone", contact, true)}
                {this.renderRow('Facebook', linkFacebook, "", "text-blue _summary_facebook")}
                {this.renderRow(t('product_list.count_ad'), renderListAds, "", "_summary_list_ads")}
                {this.renderRow(t('contact-detail.address'), contact.address, "", "_summary_address")}
                {this.renderRow(t('contact-detail.last_contact'), contact.callTime ? moment(contact.callTime).format('DD/MM/YYYY HH:mm') : t('contact-detail.last_contact_never'), "", "_summary_call_time")}
                {this.renderRow(t('contact.deliveryDate'), renderDeliveryDate, "", "_summary_delivery_date")}
                {this.renderRow(t('contact-detail.stage'), renderContactStatus, "", "_summary_contact_status width-200px text-blue")}
                {this.renderRow(t('contact-detail.assigned_to'), renderAssignTo, '', 'text-blue _summary_assign_to')}
                {this.renderRow(t('contact.assigned_at'), contact.assignedAt ? formatDate(contact.asssignedAt) : '---', '', '_summary_assigned_at')}
                {this.renderRow(t('contact.assigned_by'), objectGet(contact, 'assignedBy', '---'), "", "_summary_assigned_by")}
                {this.renderRow(t('contact-detail.note'), objectGet(contact, 'note', '---'), "", "_summary_note")}
                {this.renderRowSelectOrTextArea('provinceId', provinces, 'contact-detail.province', 'contact-detail.province_pl')}
                {this.renderRowSelectOrTextArea('cityId', cities, 'contact-detail.district', 'contact-detail.district_pl', !contact.provinceId)}
                {
                    isShowAreaId
                    ?
                        this.renderRowSelectOrTextArea('districtId', areas, 'contact-detail.area', 'placeholder.select_area', !contact.cityId)
                    :
                        this.renderRowSelectOrTextArea('districtName', null, 'contact-detail.area', 'placeholder.select_area', !contact.cityId, false)
                }
                {this.renderRowSelectOrTextArea('addressDetail', null, 'contact-detail.address_detail', 'contact_detail.complete_address_pl', false, false)}
            </>
        )
    };

    renderEditMode = () => {
        const {t, contact} = this.props;
        const {getFieldDecorator} = this.props.form;

        let validateUrl = (rule, value, callback) => {
            if (value && !isUrl(value)) {
                callback(t('error.not_url'));
            } else {
                callback();
            }
        };

        return (
            <>
                <Form.Item label={t('contact-detail.fullname')}>
                    {getFieldDecorator('customerName', {
                        rules: [
                            {required: true, message: t('error.cannot empty')},
                            {max: 160, message: t('error.length_160')}
                        ],
                        initialValue: contact.customerName
                    })(
                        <Input className={"_contact_name"} />
                    )}
                </Form.Item>

                <Form.Item label={'Facebook'}>
                    {getFieldDecorator('facebookLink', {
                        rules: [
                            {validator: validateUrl}
                        ],
                        initialValue: contact.facebookLink
                    })(
                        <Input className={"_contact_facebook"} />
                    )}
                </Form.Item>

                {UserService.isAllowed(CONTACT_SCOPES.CONTACT_UPDATE_ADDRESS) && (
                    <Form.Item label={t('contact-detail.address')}>
                        {getFieldDecorator('address', {
                            rules: [
                                {max: 255, message: t('error.length_255')}
                            ],
                            initialValue: contact.address
                        })(
                            <Input
                                className={"_contact_address"}
                            />
                        )}
                    </Form.Item>
                )}

                <Form.Item label={t('contact-detail.note')}>
                    {getFieldDecorator('note', {
                        rules: [
                            {max: 255, message: t('error.length_255')}
                        ],
                        initialValue: contact.note
                    })(
                        <Input.TextArea
                            autosize={{
                                minRows: 4
                            }}
                            className={"_contact_note"}
                        />
                    )}
                </Form.Item>
            </>
        )
    };

    //stringee
    connectStringee = async () => {
        try {
            this.handleAuthenStringee();
        } catch (error) {
            notification.error({
                message: error.message
            });
            this.setState({
                isStringeeAuthen: false
            })
        }
    }

    handleCallStringee = async (contact) => {
        let customerPhone = '';
        if(contact.standardPhoneNumber){
            customerPhone = contact.standardPhoneNumber;
        } else {
            customerPhone = `${contact.country.callingCode}${contact.customerPhone.substring(1)}`;
        }
        this.setState({
            customerPhone: customerPhone
        });
        try {
            const listNumber = await StringeeService.getListNumber(1,999, contact.vendor);
            const listNumberJson = await listNumber.json();
            const loggedUser = LocalStore.getInstance().read("authUser");
            if(!listNumber.ok || listNumberJson.data.numbers.length <= 0){
                message.error(STRINGEE_ERROR.NOT_FOUND_HOTLINE);
                return;
            }
            const hotLine = listNumberJson.data.numbers[0].number.substring(1);
            window.call = new window.StringeeCall(window.client, hotLine, customerPhone);
            window.call.custom = btoa(`${(loggedUser && loggedUser.username) ? loggedUser?.username : 'anonymous'},${contact?.vendor?.code},${this.props.contact.campaign.code}`)
            this.settingCallEvents(window.call);
            window.call.makeCall((res) => {
                if (res.r !== 0) {
                    message.error(STRINGEE_ERROR[`${res.message}`] ?? res.message)
                }
            })
        } catch (error) {
            message.error(error)
        }
    }

    handleModalClose = (e) => {
        window.remoteVideo.srcObject = null;
        window.call.hangup((res) => {
            this.setState({
                callState: ''
            })
        });
    }

    handleMuteCall = () => {
        window.call.mute(!this.state.isMute);
        this.setState({
            isMute: !this.state.isMute
        })
    }

    //stringee set event
    settingCallEvents = (call1) => {
        call1.on('error', (info) => {
            message.error(info.message)
        });

        call1.on('addremotestream', (stream) => {
            // reset srcObject to work around minor bugs in Chrome and Edge.
            window.remoteVideo.srcObject = null;
            window.remoteVideo.srcObject = stream;
        });

        call1.on('signalingstate', (state) => {
            this.setState({
                isCalling: true,
                callState: state.reason
            });
            if (state.code === CALLSTATUS.BUSY || state.code === CALLSTATUS.ENDED) {
                this.setState({
                    isAnswerPhone: false
                })
                clearInterval(this.countTimeInterval);
            }

            if (state.code === CALLSTATUS.ANSWERED) {
                this.setState({
                    isAnswerPhone: true
                })
                this.countTimeInterval = setInterval(() => {
                    let countTime = this.state.countTime;
                    this.setState({
                        countTime: countTime += 1
                    })
                }, 1000);
            }
        });

        call1.on('mediastate', (state) => {
        
        });
    }

    settingClientEvents = (client) => {
        client.on('connect', () => {

        });

        client.on('authen', (res) => {
            if (res.r === STRINGEEAUTH.SUCCESS || res.r === STRINGEEAUTH.IS_AUTHEN) {
                this.setState({
                    isStringeeAuthen: true
                })
            } else {
                this.setState({
                    isStringeeAuthen: false
                })
            }
        });

        client.on('requestnewtoken', async () => {
            //please get new access_token from YourServer and call: 
            try {
                this.handleAuthenStringee();
            } catch (error) {
                notification.error({
                    message: error
                })
                this.setState({
                    isStringeeAuthen: false
                })
            }
        });
    }

    callApiGetProvince = (countryId, cb) => {
        if(countryId) {
            LocationService.getProvinceByCountry(countryId, (error, response) => {
                if (!error) {
                    this.setState({
                        provinces: response
                    });
                }
                if (typeof cb === 'function') cb(error, response);
            });
        }
    };

    callApiGetCity = (id) => {
        const {contact} = this.props;

        LocationService.getCity(id, (error, response) => {
            if (error === null) {
                this.setState({
                    cities: response,
                });

                if(contact.cityId){
                    this.callApiGetAreas(contact.cityId);
                }
            }
        });
    };

    callApiGetAreas = (cityId) => {
        LocationService.getDistrict(cityId, (error, response) => {
            if (error === null) {
                this.setState({
                    areas: response,
                });
            }
        });
    };

    onSelect = (key, val) => {
        const {t, contact, changeLoading} = this.props;

        let body = {};

        if(key === 'provinceId'){
            body = {
                provinceId: val,
                districtId: null,
                cityId: null,
                districtName: null,
                addressDetail: null,
            }
        }

        if(key === 'cityId'){
            body = {
                cityId: val,
                districtName: null,
                districtId: null,
                addressDetail: null,
            }
        }

        if(key === 'districtId'){
            body = {
                districtId: val,
                addressDetail: null,
            }
        }

        if(key === 'districtName'){
            body = {
                districtName: val.target.value,
                addressDetail: null,
            }
        }

        if(key === 'addressDetail'){
            body = {
                addressDetail: val.target.value
            }
        }

        changeLoading(true);

        this.props.updateContact({
            ...body,
            id: contact.id
        },(error) => {
            changeLoading(false);

            this.setState({
                addressDetail: null,
                districtName: null
            })

            if (!error) {
                notification.success({
                    message: t("message.success"),
                });
                this.props.fetchContact();
            } else {
                notification.error({
                    message: error.message
                });
            }
        })
    }

    renderRowSelectOrTextArea = (key, values, keyLabel, keyPlace, isDisable = false, isSelectComp = true) => {
        const {t, contact} = this.props

        return (
            <>
                <div className="info-row">
                    <label className={`label bold-400`}>{t(keyLabel)}: </label>
                    <span className={`text width-300px`}>
                        {
                            isSelectComp
                            ?
                                <>
                                    <Select
                                        placeholder={t(keyPlace)}
                                        showSearch
                                        className={"_province"}
                                        notFoundContent={<Empty description={t("table.empty")} />}
                                        style={{width: "100%"}}
                                        disabled={isDisable}
                                        onSelect={this.onSelect.bind(this, key)}
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        value={contact[key] || undefined}
                                    >
                                        {values.length && values.map((item) => (
                                            <Option key={item.id} value={item.id} className={`_province_item _province_item_${item.key}`}>{item.name}</Option>
                                        ))}
                                    </Select>
                                </>
                            :
                                <>
                                    <Input.TextArea
                                        autosize={{
                                            minRows: key === 'addressDetail' ? 2 : 1
                                        }}
                                        className={"_contact_note"}
                                        onChange={val => { this.setState({[key]: val.target.value}) }}
                                        onBlur={this.onSelect.bind(this, key)}
                                        value={this.state[key] == null ? contact[key] : this.state[key]}
                                        placeholder={t(keyPlace)}
                                        disabled={key === 'districtName' && isDisable}
                                        maxLength={ key === 'districtName' ? 128 : 256 }
                                    />
                                </>
                        }
                    </span>
                </div>
            </>
        )
    }

    handleAuthenStringee = async () => {
        const { contact } = this.props;
        const stringeeAccessToken = await StringeeService.getAccessToken(contact.vendor.id);
        const stringeeAccessTokenJson = await stringeeAccessToken.json();
        if (stringeeAccessToken && stringeeAccessToken.ok) {
            LocalStore.getInstance().save('stringeeAccessToken', stringeeAccessTokenJson.data.accessToken)
        }
        if (stringeeAccessToken && stringeeAccessToken.ok && !window.client.hasConnected) {
            this.settingClientEvents(window.client);
            window.client.connect(stringeeAccessTokenJson.data.accessToken);
            this.setState({
                isStringeeAuthen: true
            })
            return;
        }
        if (stringeeAccessToken.ok && window.client.hasConnected) {
            this.setState({
                isStringeeAuthen: true
            })
            return;
        }
        if (!stringeeAccessToken.ok) {
            throw new Error(stringeeAccessTokenJson.message);
        }
    }

    render() {
        const {t} = this.props;
        const {editMode, loading} = this.state;
        let renderEditButton;

        if(UserService.isAllowed(CONTACT_SCOPES.CONTACT_UPDATE_INFO)) {
            renderEditButton = (
                <Button icon={"edit"} onClick={this.toggleEditMode}
                    className={"_btn_show_edit_form_contact"}>{t('button.edit')}</Button>
            );
        }
        if(editMode) {
            renderEditButton = (
                <>
                    <Button type={"primary"} loading={loading} className={"mg-r-5 _btn_submit_form_contact"} htmlType={"submit"}>{t('button.update')}</Button>
                    <Button onClick={this.toggleEditMode} className={"_btn_cancel_form_contact"}>{t('button.cancel')}</Button>
                </>
            );
        }
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
                lg: { span: 6 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
                lg: { span: 18 }
            }
        };

        let content = (
            <>
                <Card
                    className={"_summary_info_box_container"}
                    title={t('contact-detail.key_field')}
                    extra={renderEditButton}
                >
                    {editMode ? this.renderEditMode() : this.renderTextMode()}
                </Card>
                <Modal
                    title={
                        <>
                            <Icon type="info-circle" style={{color:'#1890ff', fontSize:'25px'}}/>
                            <span className={"pd-l-15"}>
                                Info Call
                            </span>
                        </>
                    }
                    visible={this.state.isCalling}
                    footer={null}
                    width={400}
                    afterClose={this.handleModalClose}
                    style={{top:15, left:10}}
                    className={"fll"}
                    transitionName="slide-left"
                    onCancel={() => {this.setState({ isCalling: false }) }}
                    mask={false}
                    wrapClassName={'calling-info__container'}
                >
                    <div className='calling-info'>
                        <div className='calling-info__title'>
                            <h3>
                                {this.state.callState}
                            </h3>
                            <p className='mg-bt-5'>
                                
                                {this.state.customerPhone}
                            </p>
                            {(this.state.isCalling && this.state.isAnswerPhone) && <p>{parseSecondToTime(+this.state.countTime)}</p>}
                        </div>
                        <div>
                            {
                                (this.state.isCalling && this.state.isAnswerPhone) &&
                                // warning not found href attribute
                                // eslint-disable-next-line
                                <a onClick={this.handleMuteCall} className={this.state.isMute ? 'calling-info__speaker mute' : 'calling-info__speaker'}>
                                    <span></span>
                                </a>
                            }
                            <Button className='calling-info__btn' size='large' onClick={() => { this.setState({ isCalling: false }) }}>
                                <i data-icon-call-type="" className="fa fa-phone" aria-hidden="true"></i>
                            </Button>
                        </div>
                    </div>
                </Modal>
            </>
        );

        if(editMode) {
            content = (
                <Form {...formItemLayout} onSubmit={this.onSubmit}>
                    {content}
                </Form>
            )
        }

        return content;
    }
}

SummaryInfo = Form.create({})(SummaryInfo);

export {SummaryInfo};