import EagleClient from "../utils/api/EagleClient";

export default class UserGroupService {

    static getUserGroup(filter, cb) {
        let endpoint = "/SaleGroups";
        EagleClient.request({
            endpoint: endpoint,
            method: "GET",
            mode: 'free',
            queryString: filter
        }, cb);
    }

    static getMembers(id, cb) {
        let endpoint = `/SaleGroups/${id}/members`;
        EagleClient.request({
            endpoint: endpoint,
            method: "GET",
            mode: 'free'
        }, cb);
    }

    static addMembers(group_id,data, cb) {
        let endpoint = `/SaleGroups/${group_id}/members`;
        EagleClient.request({
            endpoint: endpoint,
            method: "POST",
            mode: 'free',
            body: data
        }, cb);
    }

    static getSpecialGroup(id, cb) {
        let endpoint = `/SaleGroups/${id}`;
        let filter = {
            filter: {include: ['vendor']}
        };
        EagleClient.request({
            endpoint: endpoint,
            method: "GET",
            mode: 'free',
            queryString: filter
        }, cb);
    }

    static createUserGroup(data,cb) {
        let endpoint = "/SaleGroups";
        EagleClient.request({
            endpoint: endpoint,
            method: "POST",
            mode: 'free',
            body: data
        }, cb);
    }

    static updateUserGroup(data,id,cb) {
        let endpoint = "/SaleGroups/"+id;

        EagleClient.request({
            endpoint: endpoint,
            method: "PATCH",
            mode: 'free',
            body: data
        }, cb);
    }

    static deleteUserFromGroup(id,userId,cb) {
        let endpoint = `/SaleGroups/${id}/members`;

        EagleClient.request({
            endpoint: endpoint,
            method: "DELETE",
            mode: 'free',
            body: userId
        }, cb);
    }

    static deleteGroup(id,cb) {
        let endpoint = `/SaleGroups`;

        EagleClient.request({
            endpoint: endpoint,
            method: "DELETE",
            mode: 'free',
            body: id
        }, cb);
    }


    static getMembersNotInAnyGroup(filter = {},cb) {
        let endpoint = `/SaleGroups/members-not-in-any-group`;
        EagleClient.request({
            endpoint: endpoint,
            method: "GET",
            mode: 'free',
            queryString: filter
        }, cb);
    }

    static getMembersNotInGroup(groupId, filter = {},cb) {
        let endpoint = `/SaleGroups/${groupId}/users-not-in`;
        EagleClient.request({
            endpoint: endpoint,
            method: "GET",
            mode: 'free',
            queryString: filter
        }, cb);
    }

}