/**
 * Created by Taobao dev team 27/1/2018
 */
import i18n from '../../i18n';

export const parseErrors = errors => {
    const submitErrors = {};
    if (errors.details) {
        const {messages} = errors.details;
        if(messages) {
            Object.keys(messages).map(field => {
                submitErrors[field] = (i18n.t(messages[field][0]));

                return 0;
            });
        }
    }

    if (Object.keys(submitErrors).length === 0) {
        submitErrors['_error'] = i18n.t(errors.message)
    }
    return submitErrors;
};