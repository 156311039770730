import React, {Component} from 'react';
import {Table, Pagination, Col, Row, Button} from 'antd';
import {Link} from "react-router-dom";
import {formatDate2} from "../../../utils/helper";

class List extends Component {

    constructor(props) {
        super(props);
        this.state = {
            sorter: {}
        };
    }

    componentDidMount = () => {

    };

    getConfigRowSelection = () => {
        return {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: (selectedRowKeys) => {
                this.props.getProductId(selectedRowKeys);
                this.setState({
                    selectedRowKeys
                });
            }
        }
    };

    componentDidUpdate(prevProps) {
        if(JSON.stringify(prevProps.countProduct) !== JSON.stringify(this.props.countProduct)) {
            if(!this.props.countProduct.length){
                this.setState({
                    selectedRowKeys: [],
                })
            }
        }
    }

    getConfigTableColumns = () => {
        const {t} = this.props;

        return [
            {
                title: t("product_list.name"),
                dataIndex: 'name',
                key: 'name',
                width:150,
                className:"_name _item",
                render: (text, record) => {
                    return <Link to={`/products/${record.id}`}>{text}</Link>
                }
            },
            {
                title: t("product_list.code"),
                dataIndex: 'code',
                key: 'code',
                width:150,
                className:"_code _item",
                render: (text, record) => {
                    return <Link to={`/products/${record.id}`}>{text}</Link>
                }
            },
            {
                title: t("products.manufactory"),
                dataIndex: 'manufactory',
                key: 'manufactory'
            },
            {
                title: t("product.product_group"),
                dataIndex: 'groupCode',
                key: 'groupCode',
                render: (text, record) => {
                    return record.productGroup ? record.productGroup.name : ''
                }
            },
            {
                title: t("products.created_by"),
                dataIndex: "createdBy",
                key: "createdBy"
            },
            {
                title: t("products.added_at"),
                dataIndex: "createdAt",
                key: "createdAt",
                render: value => formatDate2(value)
            }
        ];
    };

    render() {
        const {t, loadingProduct} = this.props;
        const {dataSource,currentPage,pageCount,totalItems,pageSize,onShowSizeChange,onChangePage} = this.props;
        let columns = this.getConfigTableColumns();
        const rowSelection = this.getConfigRowSelection();

        return (
            <div className="list-container _list-product">
                <Row className="pd-bt-15">
                    <Col span={5}>
                        {!loadingProduct && !!totalItems && (
                            <span>{t("contact.page")} {currentPage}/{pageCount} ({totalItems})</span>
                        )}

                        {!loadingProduct && !totalItems && (
                            <span>{t("contact.page")} {1}/{1} ({totalItems})</span>
                        )}
                    </Col>
                    <Col span={19} className={'text-right'}>
                        <Link to={'/products/create'}>
                            <Button
                                type="primary"
                                className="_btn_add_product btn-add-product mg-r-5"
                            >{t("stock_receipts_detail.add_propduct")}</Button>
                        </Link>
                    </Col>
                </Row>

                <div className="a-table a-table--responsive">
                    <Table
                        columns={columns}
                        dataSource={dataSource}
                        rowKey={record => record.id}
                        pagination={false}
                        rowSelection={rowSelection}
                        loading={loadingProduct}
                        locale={{
                            emptyText: t("table.empty")
                        }}
                    />
                </div>

                {!loadingProduct && totalItems > 0 && (
                    <Pagination
                        className="mg-t-15 text-al-r"
                        showSizeChanger
                        onShowSizeChange={onShowSizeChange}
                        onChange={onChangePage}
                        pageSizeOptions={['25', '50', '120', '150', '200']}
                        defaultCurrent={1}
                        current={currentPage}
                        pageSize={pageSize}
                        total={totalItems}
                    />
                )}
            </div>
        );
    }
}

export {List}