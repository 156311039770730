import React from 'react';
import {translate} from 'react-i18next';
import UserService from "../../../services/UserService";
import {WAREHOUSE_SCOPES} from "../../../utils/api/Scopes";

class WarehouseDetail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            warehouse: {},
        }
    }

    getCode = () => {
        return this.props.match.params.code;
    };

    componentDidMount = () => {
        if(UserService.isAllowed(WAREHOUSE_SCOPES.WAREHOUSE_STOCKS_VIEW)) {
           return this.props.history.replace(`/warehouse/${this.getCode()}/stocks`);
        }
        if(UserService.isAllowed(WAREHOUSE_SCOPES.WAREHOUSE_ISSUES_VIEW)) {
            return this.props.history.replace(`/warehouse/${this.getCode()}/issues`);
        }
        if(UserService.isAllowed(WAREHOUSE_SCOPES.WAREHOUSE_RECEIPTS_VIEW)) {
            return this.props.history.replace(`/warehouse/${this.getCode()}/receipts`);
        }

        return this.props.history.replace(`/warehouse/${this.getCode()}/permissions`);
    };


    render() {
        return (
            <div />
        );
    }
}

export default (translate())(WarehouseDetail);