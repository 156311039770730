import React from 'react';
import {Row, Col, Icon, Popconfirm, notification} from 'antd';
import {formatDate, objectGet} from "../../../utils/helper";
import {EditableName} from "./EditableName";
import UserService from "../../../services/UserService";
import {GROUPS_SCOPES} from "../../../utils/api/Scopes";

class BasicInfo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editMode: false
        };
    }

    toggleEdit = () => {
        if(UserService.isAllowed(GROUPS_SCOPES.GROUPS_UPDATE)) {
            this.setState({editMode: !this.state.editMode});
        }
    };

    removeGroup = () => {
        const {t} = this.props;
        this.props.removeGroup((error) => {
            if(!error) {
                notification.success({
                    message: t('message.success')
                });

                this.props.history.replace(`/groups/`);
            } else {
                notification.error({
                    message: error.message
                })
            }
        });
    };

    render() {
        const {group, t} = this.props;
        const {editMode} = this.state;

        let renderName = (
            <div className="group-name-container">
                <span onClick={this.toggleEdit} className="_btn_container_toggle_edit name-edit-container">
                    <h1 className="name _group_name">{objectGet(group, 'name')}</h1>
                    {UserService.isAllowed(GROUPS_SCOPES.GROUPS_UPDATE) && (
                        <Icon className="edit-icon _btn_toggle_edit" type="edit" />
                    )}
                </span>

                {UserService.isAllowed(GROUPS_SCOPES.GROUPS_DELETE) && (
                    <Popconfirm
                        onConfirm={() => {this.removeGroup()}}
                        title={t("group_user.confirm_delete")}
                        okText={t("popup-confirm.confirm")}
                        cancelText={t("popup-confirm.cancel")}
                    >
                        <Icon className="delete-icon _btn_delete_group" type="delete" />
                    </Popconfirm>
                )}
            </div>
        );

        if(editMode) {
            renderName = (
                <EditableName
                    {...this.props}
                    value={objectGet(group, 'name')}
                    onHide={() => {this.toggleEdit()}}
                />
            );
        }

        return (
            <div className="basic-info">
                <div>
                    {renderName}
                </div>
                <Row gutter={20} className={"other-info"}>
                    <Col span={8}>
                        <label className="label">{t('group_user.member')}</label>
                        <div className="value">{objectGet(group, 'countMember')}</div>
                    </Col>
                    <Col span={8}>
                        <label className="label">{t('group_user.created_by')}</label>
                        <div className="value">{objectGet(group, 'createdBy')}</div>
                    </Col>
                    <Col span={8}>
                        <label className="label">{t('group_user.created_at')}</label>
                        <div className="value">{objectGet(group, 'createdAt') ? formatDate(objectGet(group, 'createdAt')) : ""}</div>
                    </Col>
                </Row>
            </div>
        )
    }
}

export {BasicInfo};