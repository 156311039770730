import React from 'react';
import queryString from "query-string";
import lodash from "lodash";

const withLocationFilter = (WrappedComponent) => {
    return class extends React.Component {

        getFilterFromLocation = () => {
            return queryString.parse(this.props.location.search);
        };

        pushFilterToHistory = (filter) => {
            this.props.history.push(this.props.location.pathname + '?' + queryString.stringify(filter));
        };

        pushCleanFilterToHistory = (filter) => {
            filter = lodash.omitBy(filter, value => value === '');
            this.pushFilterToHistory(filter);
        };

        render() {
            return <WrappedComponent
                getFilterFromLocation={this.getFilterFromLocation}
                pushFilterToHistory={this.pushFilterToHistory}
                pushCleanFilterToHistory={this.pushCleanFilterToHistory}
                {...this.props}
            />
        }
    }
};

export default withLocationFilter;