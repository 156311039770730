import React, {Component} from 'react';
import {objectGet} from "../../../utils/helper";

class Product extends Component {


    render() {
        const product = objectGet(this.props.contact, 'product', {});
        return (
            <div className="box-container">
                <div dangerouslySetInnerHTML={{__html: product.description}} />
            </div>
        )
    }
}

export {Product};