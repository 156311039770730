import React, {Component} from 'react';
import {formatDate} from "../../../utils/helper";
import {Timeline} from "antd";

class Activity extends Component {

    formatLog = (item) => {
        let {data} = item;
        let result = item.message;
        for (let _item in data) {
            let param = data[_item];
            result = result.replace('%' + _item + '%', param);
        }
        return result;
    };

    render() {
        const {logs, loading} = this.props;
        return (
            <div className="box-container">
                <div className="logs-container">
                    <Timeline pending={loading}>
                        {Array.isArray(logs) && logs.map((item, index) => (
                            <Timeline.Item key={index}>
                                <span>{formatDate(item.timestamp, true)}</span>&nbsp; <span>{this.formatLog(item)}</span>
                            </Timeline.Item>
                        ))}
                    </Timeline>
                </div>
            </div>
        )
    }
}

export {Activity};