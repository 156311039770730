import React from 'react';
import {Select, Spin, Empty, notification, Button} from "antd";
import * as _ from 'lodash';
import {doCallBack, objectGet} from "../../utils/helper";
import OrderService from "../../services/OrderService";

const styles = {
    btnUnAssignOrder: {
        backgroundColor: '#FAAD14',
        color: "#fff",
        borderRadius: '4px',
        width: '100%',
        display: 'block',
        marginBottom: '10px',
        border: 'transparent'
    }
};

class AssignOrder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            groups: [],
            initialGroups: [],
            users: [],
            initialUsers: [],
            groupSelected: null,
            userSelected: null,
            loadingGroup: false
        };
        this.onSearchGroup = _.debounce(this.onSearchGroup, 300);
    }

    componentDidMount = () => {
        this.fetchGroups({}, (error, response) => {
            if(!error) {
                this.setState({initialGroups: response.list});
            }
        });
    };

    fetchGroups = (filter = {}, cb) => {
        this.props.fetchGroups(filter, (error, response) => {
            if(!error) {
                this.setState({groups: response.list});
            }
            doCallBack(cb, [error, response]);
        })
    };

    fetchUsersInGroup = (cb) => {
        let {groupSelected} = this.state;
        this.props.fetchUsersByGroupId(groupSelected, (error, response) => {
            if(!error) {
                this.setState({users: response.list});
            }
            doCallBack(cb, [error, response]);
        });
    };

    onChangeGroup = (value) => {
        this.setState({
            groupSelected: value
        }, () => {
            this.fetchUsersInGroup();
        });
    };

    onSearchGroup = (value) => {
        this.fetchGroups({name: value});
    };

    onFocusGroup = () => {
        this.setState({
            groups: this.state.initialGroups
        });
    };

    onChangeUser = (value) => {
        const {t, order} = this.props;
        this.setState({
            userSelected: value
        }, () => {
            this.props.onAssignOrder(order);
            let {userSelected} = this.state;
            this.props.assignOrder(userSelected, (error) => {
                if(!error) {
                    notification.success({
                        message: t('message.success')
                    });
                    this.props.fetchOrder();
                } else {
                    if(error.code === 'PRODUCT_NOT_ACTIVE') {
                        notification.error({
                            message: t('error.product_is_not_active')
                        });
                    } else {
                        notification.error({
                            message: error.message
                        });
                    }
                }
            });
        });
    };

    unAssignOrder = () => {
        const {order, t} = this.props;
        this.props.onAssignOrder(order);
        OrderService.unAssignOrder(order.id, (error) => {
            this.setState({groupSelected: null});
            if(!error) {
                notification.success({
                    message: t('message.success')
                });
                this.props.fetchOrder();
            } else {
                if(error.code === 'PRODUCT_NOT_ACTIVE') {
                    notification.error({
                        message: t('error.product_is_not_active')
                    });
                } else {
                    notification.error({
                        message: error.message
                    });
                }
            }
        });
    };

    render() {
        const {t, order} = this.props;
        const {groupSelected, groups, users, loadingGroup} = this.state;
        let assignTo = objectGet(order, 'assignTo');
        let showButtonUnAssign = true;
        if(!assignTo) {
            showButtonUnAssign = false;
            assignTo = t('contact-detail.select_user');
        }
        return (
            <div className="popup-assign-contact-manual-container">
                <div className="mg-bt-10">
                    {showButtonUnAssign && (
                        <Button className="btn-unassign-order" style={styles.btnUnAssignOrder} onClick={this.unAssignOrder}>{t('order_list.unassign_to')}</Button>
                    )}
                    <Select
                        className={'_select_group_user'}
                        style={{width: 200}}
                        showSearch={true}
                        placeholder={t('contact-detail.select_group')}
                        onFocus={this.onFocusGroup}
                        onChange={this.onChangeGroup}
                        onSearch={this.onSearchGroup}
                        filterOption={false}
                        value={groupSelected ? [groupSelected] : []}
                        notFoundContent={(loadingGroup ? <Spin size="small" /> : <Empty description={t("table.empty")} />)}
                    >
                        {Array.isArray(groups) && groups.map((item) => (
                            <Select.Option
                                key={item.id}
                                value={item.id}
                                className={`_group_user_item _group_user_item_${item.id}`}
                            >{item.name}</Select.Option>
                        ))}
                    </Select>
                </div>
                <div>
                    <Select
                        className={`_select_user`}
                        style={{width: 200}}
                        placeholder={assignTo}
                        disabled={!groupSelected}
                        onChange={this.onChangeUser}
                        value={[]}
                        notFoundContent={<Empty description={t("table.empty")} />}
                    >
                        {Array.isArray(users) && users.map((item) => (
                            <Select.Option
                                key={item.id}
                                value={item.id}
                                className={`_user_item _user_item_${item.id}`}
                            >{item.username}</Select.Option>
                        ))}
                    </Select>
                </div>
            </div>
        );
    }
}

AssignOrder.defaultProps = {
    order: {},
    fetchGroups: () => {},
    fetchUsersByGroupId: () => {},
    assignOrder: () => {},
    onAssignOrder: () => {},
    fetchOrder: () => {}
};

export {AssignOrder};