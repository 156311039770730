import React, {Component} from "react";
import {translate} from "react-i18next";
import StandardLayout from "../../components/StandardLayout";
import DeliveryCaseService from "../../services/DeliveryCaseService";
import {getCurrentModule, getParamsAsObject, getUrlQueryString} from "../../utils/helper";
import {Url} from "../../utils/Url";
import {Filter} from "./Filter";
import {Header} from "./Header";
import List from "./List";
import * as _ from 'lodash';
import {LocalStore} from "../../utils/LocalStore";
import VendorService from "../../services/VendorService";
import {DELIVERY_CASE_STATUS, MESSAGE_ERROR_DELIVERY_CASE} from "../../utils/const";
import {Modal, notification, Table} from "antd";
import LocationService from "../../services/LocationService";
import {DELIVERYCASE} from "../../utils/api/Scopes";
import UserService from "../../services/UserService";

class DeliveryCase extends Component {

    constructor(props) {
        super(props);
        this.mounted = false;
        this.moduleName = 'delivery-cases';
        this.state = {
            loading: false,
            visible: false,
            dataSource: [],
            currentPage: 1,
            totalItems: 0,
            pageSize: 25,
            deliveryCasesStatus: DELIVERY_CASE_STATUS,
            vendor: LocalStore.getInstance().read('vendor'),
            loadingListReason: false,
            listReason: [],
            loadingUpdate: false,
            isShowInfoImportModal: false,
            importMessageResponse: [],
            vendors: [],
            loadingVendor: false,
            countries: [],
            loadingCountries: false,
			assignees: []
        }
    }

    onPopstate = () => {
        if(this.mounted) {
            this.setStateFromQueryString();
        }
        // Url thay đổi thì chỉ load lại list
        if(this.mounted && getCurrentModule(null, true) === this.moduleName) {
            let queryObject = getParamsAsObject(getUrlQueryString());
            this.loadData(queryObject);
        }
    };

    componentWillMount = () => {
        if (!UserService.isAllowed(DELIVERYCASE.DELIVERY_CASE_VIEW_ALL) && !UserService.isAllowed(DELIVERYCASE.DELIVERY_CASE_VIEW_RESTRICT)) {
            this.props.history.replace('/403')
        }
    }

    componentDidMount = () => {
        this.mounted = true;
        window.addEventListener("popstate", this.onPopstate.bind(this), false);
        this.loadData();
        this.getAllVendor();
        this.setStateFromQueryString();
        this.callApiGetCountries();
        this.getDeliveryCaseAssignees()
    };

    componentWillUnmount() {
        this.mounted = false;
        window.removeEventListener("popstate", this.onPopstate.bind(this), false);
    }

    getDeliveryCaseAssignees = (filter = {}) => {
    	DeliveryCaseService.getDeliveryCaseAssignees(filter, (error, response) => {
    		if (!error) {
    			this.setState({
					assignees: response
				})
			}
    		else {
    			this.setState({
					assignees: []
				})
			}
		})
	}

    loadData = () => {
        let queryObject = getParamsAsObject(getUrlQueryString());
        this.loadListDeliveryCases(queryObject);
        this.loadListReason();
    }

    loadListDeliveryCases = (filter = {}) => {
        this.setState({loading: true});
        let defaultFilter = {
            page: filter.page || this.state.currentPage,
            page_size: filter.page_size || this.state.pageSize
        };
        if(Object.keys(filter).length){
            Object.assign(defaultFilter, filter)
        }
        DeliveryCaseService.getListDeliveryCase(defaultFilter, (error, response) => {
            if(!error){
                this.setState({
                    dataSource: response.list,
                    pageSize: response.metadata.pageSize,
                    pageCount: response.metadata.pageCount,
                    currentPage: response.metadata.currentPage,
                    totalItems: response.count,
                    loading: false
                })
            } else {
                this.setState({
                    loading: false
                })
            }
        })
    }

    loadListReason = (cb) => {
        this.setState({
            loadingListReason: true
        })
        DeliveryCaseService.getListReason((error, response) => {
            this.setState({
                loadingListReason: false
            })
            if(!error){
                this.setState({
                    listReason: response ? response : []
                })
            }
        })
    }

    setStateFromQueryString = () => {
        let queryObject = getParamsAsObject(getUrlQueryString());
        _.forEach(queryObject, (value, key) => {
            if(key === 'page'){
                this.setState({
                    currentPage: +value
                })
            }
            if(key === 'page_size'){
                this.setState({
                    pageSize: +value
                })
            }
        })
    };

    onUploadDeliveryCase = (action, data) => {
        const {t} = this.props;

        if(action === 'done'){
           if(data && data.errors.length > 0){
                this.setState({
                    isShowInfoImportModal: true
                });
                this.setState({
                    importMessageResponse: data && data.errors ? data.errors : []
                })
           }

           if(data && data.errors.length <= 0){
                notification.success({
                    message: t("delivery_case.import_success"),
                    description: `${data.success_count} ${t("delivery_case.import_line")}`
                })
           }

            this.loadData();
        }
    }

    getUrlInstance = (newInstance = false) => {
        if(newInstance) {
            return new Url();
        }
        return new Url('?'+getUrlQueryString());
    };

    pushQueryString = (queryString) => {
        this.props.history.push(`/delivery-cases${queryString}`);
    };

    onChangePage = (page, pageSize) => {
        let uri = this.getUrlInstance();
        uri.replaceParam('page', page);
        uri.replaceParam('page_size', pageSize);
        this.pushQueryString(uri.getQueryString());
    };

    onShowSizeChange = (current, pageSize) => {
        let uri = this.getUrlInstance();
        uri.replaceParam('page', 1);
        uri.replaceParam('page_size', pageSize);
        this.pushQueryString(uri.getQueryString());
    };

    updateDeliveryCase = (data) => {
        const {t} = this.props;
        this.setState({
            loadingUpdate: true
        })
        DeliveryCaseService.updateDeliveryCase(data, (error, response) => {
            this.setState({
                loadingUpdate: false
            })
            if(!error){
                notification.success({
                    message: t("delivery_case.update_success")
                })
                let url = window.location.href;
                if(url.indexOf('?') >= 0){
                    this.loadListDeliveryCases(getParamsAsObject(getUrlQueryString()));
                } else {
                    this.loadListDeliveryCases({})
                }
                return;
            }
            notification.error({
                message: t("delivery_case.update_error"),
                description: error.message
            })
        })
    }

    setDataSource = (datasource) => {
        this.setState({
            datasource: datasource ? datasource : []
        })
    }

    getKeyMessageError = (message) => {
        const {t} = this.props;

        if(message.toLowerCase().includes(MESSAGE_ERROR_DELIVERY_CASE.DUPLICATE_ERROR.toLowerCase())){
            return t('delivery_case.order_code_duplicate');
        }

        if(message.toLowerCase().includes(MESSAGE_ERROR_DELIVERY_CASE.NOT_FOUND_ORDER_ERROR.toLowerCase())){
            const code = message.split(" ");
            return `${t('delivery_case.order_code_not_found')}: ${code[code.length - 1]}`;
        }

        if(message.toLowerCase().includes(MESSAGE_ERROR_DELIVERY_CASE.FILE_NOT_ROWS.toLowerCase())){
            return t('delivery_case.file_not_row');
        }

        if(message.toLowerCase().includes(MESSAGE_ERROR_DELIVERY_CASE.FILE_MAXIMUN_ROWS.toLowerCase())){
            return t('delivery_case.file_error_maximun');
        }

        if(message.toLowerCase().includes(MESSAGE_ERROR_DELIVERY_CASE.FILE_EXPECT_ORDER_CODE_COLUMN.toLowerCase())){
            return t('delivery_case.file_expect_order_code');
        }

        if(message.toLowerCase().includes(MESSAGE_ERROR_DELIVERY_CASE.ORDER_STARUS_NOT_CORRECT.toLowerCase())){
            return t('delivery_case.order_status_not_correct');
        }

        if(message.toLowerCase().includes(MESSAGE_ERROR_DELIVERY_CASE.ACCESS_DENIED.toLowerCase())){
            return t('error.Access Denied');
        }

        return message;
    }

    getAllVendor = () => {
        this.setState({
            loadingVendor: true
        });

        VendorService.getVendorList({
            page:1,
            page_size: 9999
        }, (error, response) => {
            this.setState({
                loadingVendor: false
            });

            if(!error){
                this.setState({
                    vendors: response.list
                })
            }
        })
    }

    callApiGetCountries = (cb) => {
        this.setState({
            loadingCountries: true
        })
        LocationService.getCountries({},(error, response) => {
            this.setState({
                loadingCountries: false
            })
            if(!error){
                this.setState({
                    countries: response
                });
            }
            if(typeof cb === 'function') cb(error, response);
        });
    };

	handleExport = () => {
		let filter = getParamsAsObject(getUrlQueryString());
		this.setState({loading: true});

		DeliveryCaseService.export(filter, (error, response) => {
			this.setState({loading: false});
			if (!error) {
				window.open(response.downloadUrl, '_blank');
			}
			else {
				notification.error({
					message: error.message || 'Có lỗi xảy ra. Vui lòng thử lại sau'
				})
			}
		})
	}

    render() {
        const { t } = this.props;
        const {deliveryCasesStatus, listReason, loadingListReason, loadingUpdate, isShowInfoImportModal,
			importMessageResponse, vendors, loadingVendor, countries, loadingCountries, assignees} = this.state;
        return (
            <StandardLayout {...this.props} title={t("delivery_case.title")} showSelectVendor={false}>
                <div className="contact-list-page list-page">
                    <Header {...this.props} />

                    <Filter
                        {...this.props}
                        getUrlInstance={this.getUrlInstance}
                        pushQueryString={this.pushQueryString}
                        deliveryCasesStatus={deliveryCasesStatus}
                        vendors={vendors}
                        loadingVendor={loadingVendor}
                        countries={countries}
                        loadingCountries={loadingCountries}
                        listReason={listReason}
                        loadListReason={loadingListReason}
						assignees={assignees}
                    />

                    <List
                        {...this.props}
                        onUploadDeliveryCase={this.onUploadDeliveryCase}
                        dataSource={this.state.dataSource}
                        currentPage={this.state.currentPage}
                        pageSize={this.state.pageSize}
                        totalItems={this.state.totalItems}
                        loading={this.state.loading}
                        onChangePage={this.onChangePage}
                        onShowSizeChange={this.onShowSizeChange}
                        deliveryCasesStatus={deliveryCasesStatus}
                        loadingListReason={loadingListReason}
                        listReason={listReason}
                        updateDeliveryCase={this.updateDeliveryCase}
                        loadingUpdate={loadingUpdate}
                        setDataSource={this.setDataSource}
                        getKeyMessageError={this.getKeyMessageError}
						assignees={assignees}
						onExport={this.handleExport}
                    />
                </div>

                <Modal
                    title={t("delivery_case.import")}
                    footer={false}
                    visible={isShowInfoImportModal}
                    onCancel={() => {this.setState({isShowInfoImportModal: false})}}
                    width={1000}
                >
                    <Table
                        columns={[
                            {
                                title: t("delivery_case.import_line"),
                                dataIndex: "line",
                                align:'center',
                                key: "line",
                                width: 100
                            },
                            {
                                title: t("delivery_case.import_status"),
                                dataIndex: "success",
                                key: "success",
                                align:'center',
                                width:200,
                                render: (record) => {
                                    return record.success ? t("delivery_case.import_success") : t("delivery_case.import_failed")
                                }
                            },
                            {
                                title: t("delivery_case.import_message"),
                                dataIndex: "message",
                                key: "message",
                                align:'center',
                                render: (record) => {
                                       return this.getKeyMessageError(record)
                                }
                            }
                        ]}
                        dataSource={importMessageResponse}
                        rowKey={record => record.line}
                        pagination={false}
                        scroll={{ y: 400 }}
                    />
                </Modal>
            </StandardLayout>
        )
    }

}

export default (translate())(DeliveryCase)
