import React, {Component} from 'react';

import {Filter} from "./Filter";
import {LocalStore} from "../../../utils/LocalStore";
import {List} from "./List";
import * as _ from "lodash";
import {arrayConcat} from "../../../utils/helper";
import VendorService from "../../../services/VendorService";
import {PopupCreateAdset} from "./Add/PopupCreateAdset";
import CampaignService from "../../../services/CampaignService";
import {notification} from "antd/lib/index";
import AdSetService from "../../../services/AdSetService";
import {PopupUpdateAdset} from "./Update/PopupUpdateAdset";

class Adset extends Component {

    constructor(props) {
        super(props);
        this.state = {
            vendor: LocalStore.getInstance().read('vendor'),
            currentPage: 1,
            totalItems: 25,
            pageSize: 100,
            pageCount: 0,
            initialUsers: [],
            adsets: [],
            users: [],
            loadingAdset: true,
            loadingUser: true,
            isShowPopUpCreateAdset: false,
            campaigns: [],
            initialCampaigns: [],
            adsetSelected: {},
            isShowPopUpUpdateAdset: false,
            loadingCampaign: true,
            checkPressEnterCreateCreate: false,
            checkPressEnterCreateUpdate: false
        };
        this.filterAdset = null;
    }

    componentDidMount = () => {
        this.loadData();
    };

    resetDataSource = (key) => {
        this.setState({
            [key]: this.state[`initial${_.upperFirst(key)}`]
        });
    };

    loadData = () => {
        this.fetchAdset({
            product_id:this.props.productId,
            project_id:this.props.projectId,
            campaign_id:this.props.campaignId
        });
        this.fetchUser({}, (error, response) => {
            if(!error) {
                this.setState({
                    initialUsers: response.list
                });
            }
        });
        this.fetchCampaign({}, (error, response) => {
            if(!error) {
                this.setState({
                    initialCampaigns: response.list
                });
            }
        })
    };

    fetchUser = (filter = {}, cb) => {
        this.setState({loadingUser: true});
        const {vendor} = this.state;
        VendorService.getUserInVendor(vendor.id, filter, (error, response) => {
            if(!error) {
                this.setState({
                    loadingUser: false,
                    users: response.list,
                });
            }
            if(typeof cb === 'function') cb(error, response);
        });
    };

    fetchAdset = (filter = {}, cb) => {
        this.setState({loadingAdset: true});
        let {vendor} = this.state;
        let filterCond = {
            vendor_id: vendor.id,
            include: ['product','campaign'],
            order: ["createdAt DESC"],
        };
        filterCond = _.mergeWith(filterCond, filter, arrayConcat);
        AdSetService.getAdSets(filterCond, (error, response) => {
            if(!error) {
                for (let i = 0; i < response.list.length; i++) {
                    response.list[i].fbAdsetIdQuickEdit = false;
                }
                this.setState({
                    adsets: response.list,
                    loadingAdset: false,
                    currentPage: response.metadata.currentPage,
                    totalItems: response.count,
                    pageSize: response.metadata.pageSize,
                    pageCount: response.metadata.pageCount
                });
            }
            if(typeof cb === 'function') cb(error, response);
        });
    };

    fetchCampaign = (filter = {}, cb) => {
        this.setState({loadingCampaign: true});
        let {vendor} = this.state;
        let filterCond = {
            vendor_id: vendor.id,
            include: ['product'],
            order: ["createdAt DESC"],
            ids:this.props.campaignId.length?this.props.campaignId:"",
            product_active: true
        };
        filterCond = _.mergeWith(filterCond, filter, arrayConcat);
        CampaignService.getCampaigns(filterCond, (error, response) => {
            if(!error) {
                this.setState({
                    campaigns: response.list,
                    loadingCampaign: false
                });
            }
            if(typeof cb === 'function') cb(error, response);
        });
    };

    onCreateAdset = (data) => {
        const {t} = this.props;
        this.setState({checkPressEnterCreate:false});
        AdSetService.createAdSet(data, (error, response) => {
            if(!error) {
                this.setState({
                    isShowPopUpCreateAdset: false
                });
                notification['success']({
                    message: t("message.success"),
                });
                this.filterAdset.resetStateFilter();
                this.fetchAdset({
                    product_id:this.props.productId,
                    project_id:this.props.projectId,
                    campaign_id:this.props.campaignId
                });
            } else {
                this.setState({checkPressEnterCreate:true});
                notification['error']({
                    message: error.message,
                });
            }
        });
    };

    onUpdateAdset = (data) => {
        data.id = this.state.adsetSelected.id;
        const {t} = this.props;
        this.setState({checkPressEnterUpdate:false});
        AdSetService.updateAdSet(data, (error, response) => {
            if(!error) {
                this.setState({
                    isShowPopUpUpdateAdset: false
                });
                notification['success']({
                    message: t("message.success"),
                });
                this.filterAdset.resetStateFilter();
                this.fetchAdset({
                    product_id:this.props.productId,
                    project_id:this.props.projectId,
                    campaign_id:this.props.campaignId
                });
            } else {
                this.setState({checkPressEnterUpdate:true});
                notification['error']({
                    message: error.message,
                });
            }
        });
    };

    onShowSizeChange = (current, pageSize) => {
        this.fetchAdset({
            page: 1,
            page_size: pageSize,
            product_id:this.props.productId,
            project_id:this.props.projectId,
            campaign_id:this.props.campaignId,
            keyword:this.filterAdset.getFilterCon("keyword"),
            created_by:this.filterAdset.getFilterCon("createdBy"),
            created_at_from:this.filterAdset.getFilterCon("created_at_from"),
            created_at_to:this.filterAdset.getFilterCon("created_at_to")
        });
    };

    onChangePage = (page, pageSize) => {
        this.fetchAdset({
            page: page,
            page_size: pageSize,
            product_id:this.props.productId,
            project_id:this.props.projectId,
            campaign_id:this.props.campaignId,
            keyword:this.filterAdset.getFilterCon("keyword"),
            created_by:this.filterAdset.getFilterCon("createdBy"),
            created_at_from:this.filterAdset.getFilterCon("created_at_from"),
            created_at_to:this.filterAdset.getFilterCon("created_at_to")
        });
    };

    onFilter = (filter = {}, cb) => {
        this.fetchAdset(filter, cb);
    };

    componentDidUpdate(prevProps) {
        if(JSON.stringify(prevProps.productId) !== JSON.stringify(this.props.productId) ||
            JSON.stringify(prevProps.projectId) !== JSON.stringify(this.props.projectId) ||
            JSON.stringify(prevProps.campaignId) !== JSON.stringify(this.props.campaignId)){
            this.fetchAdset({
                product_id:this.props.productId,
                project_id:this.props.projectId,
                campaign_id:this.props.campaignId,
                keyword:this.filterAdset.getFilterCon("keyword"),
                created_by:this.filterAdset.getFilterCon("createdBy"),
                created_at_from:this.filterAdset.getFilterCon("created_at_from"),
                created_at_to:this.filterAdset.getFilterCon("created_at_to")
            });

            this.fetchCampaign({
                product_id:this.props.productId,
                project_id:this.props.projectId,
            },(error, response) => {
                if(!error) {
                    this.setState({
                        initialCampaigns: response.list
                    });
                }
            })
        }
    }

    setRefFilter = (ref) => {
        this.filterAdset = ref;
    };

    showPopupCreateAdset = (active) => {
        this.setState({
            isShowPopUpCreateAdset: active,
            checkPressEnterCreate: active
        })
    };

    showPopupUpdateAdset = (active,item = {}) => {
        this.setState({
            isShowPopUpUpdateAdset: active,
            adsetSelected: item,
            checkPressEnterUpdate: active
        })
    };

    setAdsetItem = (item, attributes) => {
        _.forEach(attributes, (v, k) => {
            item[k] = v;
        });
        let items = this.state.adsets;

        for(let i = 0; i < items.length; i++) {
            let _item = items[i];
            if(_item.id === item.id) {
                _item = item;
                break;
            }
        }

        this.setState({
            adsets: items
        });
    };

    onQuickEditFbAdsetId = (item) => {
        const {t} = this.props;
        AdSetService.updateAdSet(item, (error, response) => {
            if(null === error) {
                notification['success']({
                    message: t("message.success"),
                });
                this.setAdsetItem(item, {
                    fbAdsetIdQuickEdit: false,
                });
            }else{
                notification['error']({
                    message: error.message,
                });
            }
        });
    };

    render() {
        const {vendor, totalItems, currentPage, pageSize,pageCount, users, adsets, loadingAdset} = this.state;

        return (
            <div className="product-container _adset-container">
                <Filter
                    {...this.props}
                    users={users}
                    resetDataSource={this.resetDataSource}
                    onFilter={this.onFilter}
                    fetchUser={this.fetchUser}
                    currentPage={currentPage}
                    pageSize={pageSize}
                    pageCount={pageCount}
                    totalItems={totalItems}
                    loading={loadingAdset}
                    productId={this.props.productId}
                    projectId={this.props.projectId}
                    campaignId={this.props.campaignId}
                    ref={this.setRefFilter}
                    showPopupCreateAdset={this.showPopupCreateAdset}
                />
                <List
                    {...this.props}
                    dataSource={adsets}
                    currentPage={currentPage}
                    pageSize={pageSize}
                    pageCount={pageCount}
                    totalItems={totalItems}
                    vendor={vendor}
                    onChangePage={this.onChangePage}
                    onShowSizeChange={this.onShowSizeChange}
                    loadingAdset={loadingAdset}
                    onToggleProductStatus={this.onToggleProductStatus}
                    getAdsetId={this.props.getAdsetId}
                    countAdset={this.props.countAdset}
                    showPopupUpdateAdset={this.showPopupUpdateAdset}
                    setAdsetItem={this.setAdsetItem}
                    onQuickEditFbAdsetId={this.onQuickEditFbAdsetId}
                />
                <PopupCreateAdset
                    {...this.props}
                    isShowPopUpCreateAdset={this.state.isShowPopUpCreateAdset}
                    showPopupCreateAdset={this.showPopupCreateAdset}
                    campaigns={this.state.campaigns}
                    onCreateAdset={this.onCreateAdset}
                    resetDataSource={this.resetDataSource}
                    fetchCampaign={this.fetchCampaign}
                    loadingCampaign={this.state.loadingCampaign}
                    checkPressEnterCreate={this.state.checkPressEnterCreate}
                />

                {this.state.isShowPopUpUpdateAdset && (
                    <PopupUpdateAdset
                        {...this.props}
                        showPopupUpdateAdset={this.showPopupUpdateAdset}
                        onUpdateAdset={this.onUpdateAdset}
                        adsetSelected={this.state.adsetSelected}
                        checkPressEnterUpdate={this.state.checkPressEnterUpdate}
                    />
                )}
            </div>
        );
    }
}

export {Adset}
