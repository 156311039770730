import EagleClient from "../utils/api/EagleClient";

export default class PackageService {
    static getPackage = (filter = {}, cb) => {
        let endpoint = "/Packages";
        EagleClient.request({
            endpoint: endpoint,
            method: "GET",
            mode: "free",
            queryString: filter
        }, cb);
    };


}