import React, {Component} from 'react';
import {Card, Row, Table, Col} from 'antd';
import { Link } from 'react-router-dom';

class SummaryOrderDuplicate extends Component {
    
    constructor(props){
        super(props);
        this.state = {}
    }

    render() {
        const {t, duplicateOrders, loadingDuplicateOrders} = this.props;

        const columns = [
            {
                title: t("contact-detail.order_code"),
                key: "code",
                render: (row) => {
                    return  <Link to={`/orders/${row.id}`} target={'_blank'}>{row.code}</Link>
                }
            },
            {
                title: t("order_list.customerName"),
                dataIndex: "contactName",
                key: "contactName"
            },
            {
                title: t("order.customer_phone"),
                dataIndex: "contactPhone",
                key: "contactPhone"
            },
            {
                title: t("order_detail.status"),
                dataIndex: "status",
                key: "status"
            }
        ];

        return (
            <Card
                className={"mg-t-30"}
                title={t('duplicate_order.title')}
            >
                <Row>
                    <Col span={24}>    
                        <Table 
                            columns={columns}
                            dataSource={duplicateOrders || []}
                            bordered
                            pagination={false}
                            loading={loadingDuplicateOrders}
                            rowKey={record => record.id}
                        />
                    </Col>
                </Row>
            </Card>
        )
    }
}

export {SummaryOrderDuplicate};