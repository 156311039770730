import React from 'react';
import {Row, Col, Input, Select, Icon, Button, Spin, Empty} from 'antd';
import * as _ from "lodash";
import {getParamsAsObject, getUrlQueryString} from "../../utils/helper";
import * as moment from "moment/moment";

class Filter extends React.Component {

    constructor(props) {
        super(props);
        const {t} = this.props;
        this.mounted = false;
        this.state = {
            name: "",
            type: null,
            createdBy: [],
            types: [
                {key: 'LOGICAL', text: t('warehouse.LOGICAL')},
                {key: 'PHYSICAL', text: t('warehouse.PHYSICAL')}
            ]
        };
        this.onSearchUser = _.debounce(this.onSearchUser, 400);
    }

    onPopstate = () => {
        if(this.mounted) {
            this.setStateFromQueryString();
        }
    };

    setStateFromQueryString = () => {
        let queryObject = getParamsAsObject(getUrlQueryString());
        this.resetStateFilter(() => {
            _.forEach(queryObject, (v, k) => {
                if (v) {
                    if(k === 'keyword') {
                        this.setState({name: v});
                    }
                    if(k === 'type') {
                        this.setState({type: v});
                    }
                }
            });
        });
    };

    resetStateFilter = (cb = null) => {
        this.setState({
            name: "",
            code: "",
            type: "",
            createdBy: []
        }, cb)
    };

    componentWillUnmount() {
        this.mounted = false;
        window.removeEventListener("popstate", this.onPopstate.bind(this), false);
    }

    componentDidMount = () => {
        this.mounted = true;
        window.addEventListener("popstate", this.onPopstate.bind(this), false);
        this.setStateFromQueryString();
    };

    onSelectChange = (key, value) => {
        this.setState({
            [key]: value
        });
    };

    onFilter = () => {
        let uri = this.props.getUrlInstance(true);
        uri.replaceParam('keyword', this.state.name);
        uri.replaceParam('type', this.state.type);
        uri.replaceParam('page', 1);
        uri.replaceParam('filter', true);

        this.props.pushQueryString(uri.getQueryString());
    };

    clearFilter = () => {
        this.resetStateFilter(() => {
            this.props.pushQueryString("")
        });
    };

    onSearchUser = (value) => {
        const {fetchUsers} = this.props;
        fetchUsers({username: value});
    };

    onChangeInput = (key, e) => {
        this.setState({
            [key]: e.target.value
        });
    };

    render() {
        const {t} = this.props;
        const {name, type, types} = this.state;
        const optionInputName = {
            style: {width: '100%'},
            placeholder: t('warehouse.name_code'),
            value: name,
            suffix: <Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />,
            onChange: this.onChangeInput.bind(this, 'name'),
            onPressEnter: this.onFilter
        };

        const optionInputType = {
            style: {width: '100%'},
            value: type ? [type] : [],
            filterOption: false,
            defaultActiveFirst: false,
            placeholder: t('warehouse.type'),
            notFoundContent: <Empty description={t("table.empty")} />,
            onChange: this.onSelectChange.bind(this, 'type')
        };
        return (
            <div className="group-user-filter filter">
                <Row gutter={20} className="form-filter">
                    <Col span={6} lg={8} xl={6} className="form-filter-control">
                        <label>{t('warehouse.name_code')}:</label>
                        <Input
                            {...optionInputName}
                        />
                    </Col>
                    <Col span={6} lg={8} xl={6} className="form-filter-control">
                        <label>{t('warehouse.type')}:</label>
                        <Select
                            {...optionInputType}
                        >
                            {Array.isArray(types) && types.map((item) => (
                                <Select.Option key={item.key} value={item.key}>{item.text}</Select.Option>
                            ))}
                        </Select>
                    </Col>
                    <Col span={6} lg={8} xl={6} className="form-filter-control filter-button flr text-right">
                        <label>&nbsp;</label>
                        <div>
                            <span className="mg-r-10 pointer _filter_button_clear" onClick={this.clearFilter}>
                                <Icon type="sync" /> {t("contact.clear")}
                            </span>
                            <Button type="primary" className="_filter_button_filter" onClick={this.onFilter}>
                                {t("button.filter")}
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}

export {Filter};