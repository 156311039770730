export const CONTACT_STATUS = {
    'L1': {id: 1, key: 'L1', name: 'NEW', isEnd: false},
    'L1.3': {id: 14, key: 'L1.3', name:'INVALID', isEnd: true},
    'L2': {id: 4, key: 'L2', name: 'VALID_NUMBER', isEnd: false},
    'L2.1': {id: 6, key: 'L2.1', name: 'NOT_ANSWERED', isEnd: false},
    'L2.2': {id: 11, key: 'L2.2', name: 'CALL_LATER', isEnd: false},
    'L2.3': {id: 12, key: 'L2.3', name: 'WRONG_PERSON', isEnd: true},
    'L2.4': {id: 204, key: 'L2.4', name: 'FAILED_CALL', isEnd: true},
    'L3': {id: 5, key: 'L3', name: 'TALKED', isEnd: false},
    'L3.1': {id: 13, key: 'L3.1', name: 'CONSIDERING', isEnd: false},
    'L3.4': {id: 15, key: 'L3.4', name: 'RECALL_FAILED', isEnd: true},
    'L6': {id: 8, key: 'L6', name: 'SCHEDULED', isEnd: false},
    'L6.1': {id: 19, key: 'L6.1', name: 'SCHEDULED_DELIVERY', isEnd: false},
    'L7': {id: 18, key: 'L7', name:'DECIDED', isEnd: true},
    'L7.1': {id: 9, key:'L7.1',name: 'AGREED', isEnd: true},
    'L7.3': {id: 7, key:'L7.3',name:'DECLINED', isEnd: true},
    'L7.10': {id: 20, key:'L7.10',name:'DELIVERING', isEnd: true},
    'L8': {id: 10, key:'L8',name:'DELIVERY', isEnd: true},
    'L8.1': {id: 16, key:'L8.1',name:'DELIVERY_SUCCESS', isEnd: true},
    'L8.3': {id: 17, key:'L8.3',name:'DELIVERY_FAILED', isEnd: true},
};

export function getContactStatus(id) {
    for (let statusKey in CONTACT_STATUS) {
        if (CONTACT_STATUS[statusKey].id === id) {
            return CONTACT_STATUS[statusKey];
        }
    }
    return null;
}

export const WAREHOUSE_TYPE = {
    'LOGICAL': {key: 'LOGICAL', name: "LOGICAL"},
    'PHYSICAL': {key: 'PHYSICAL', name: 'PHYSICAL'}
};

export const WAREHOUSE_STATUS = {
    'ACTIVE': {key: 'ACTIVE', name: 'ACTIVE'},
    'INACTIVE': {key: 'INACTIVE', name: 'INACTIVE'}
};

export function getListWareHouseType() {
    let arr = [];
    for(let key in WAREHOUSE_TYPE) {
        arr.push(WAREHOUSE_TYPE[key]);
    }
    return arr;
}

export function getListWareHouseStatus() {
    let arr = [];
    for(let key in WAREHOUSE_STATUS) {
        arr.push(WAREHOUSE_STATUS[key]);
    }
    return arr;
}

export const CALLSTATUS = {
    INIT: 0,
    CALLING: 1,
    RINGING: 2,
    ANSWERED: 3,
    CONNECTED: 4,
    BUSY: 5,
    ENDED: 6 
}

export const STRINGEEAUTH = {
    SUCCESS: 0,
    IS_AUTHEN: 1
}

export const STRINGEE_ERROR = {
    FROM_NUMBER_OR_TO_NUMBER_INVALID_FORMAT: "Invalid number format",
    GET_USER_MEDIA_ERROR: "You need to check for permissions on your microphone",
    NOT_FOUND_HOTLINE: "Error. Please try again"
}