import Tracker from "@openreplay/tracker";
import {oneReplayConfig} from "../config/onereplay";
import trackerAssist from "@openreplay/tracker-assist";

const tracker = new Tracker({
    projectKey: oneReplayConfig.projectKey,
    ingestPoint: oneReplayConfig.ingestPoint,
});

tracker.use(trackerAssist());

export {tracker};