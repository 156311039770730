import React, {Component} from 'react';
import {Button, Col, Empty, Input, Row, Select} from 'antd';
import * as _ from "lodash";
import {getParamsAsObject, getUrlQueryString} from "../../utils/helper";

const Option = Select.Option;
const Search = Input.Search;

class Filter extends Component {

    constructor(props) {
        super(props);
        this.mounted = false;
        this.state = {
            keyword: "",
            countryId: ""
        };
    }

    componentDidMount = () => {
        this.mounted = true;
        window.addEventListener("popstate", this.onPopstate.bind(this), false);
        this.setStateFromQueryString();
    };

    componentWillUnmount = () => {
        this.mounted = false;
        window.removeEventListener("popstate", this.onPopstate.bind(this), false);
    };

    onPopstate = () => {
        if(this.mounted) {
            this.setStateFromQueryString();
        }
    };

    setStateFromQueryString = () => {
        let queryObject = getParamsAsObject(getUrlQueryString());
        this.resetStateFilter(() => {
            _.forEach(queryObject, (v, k) => {
                if(v) {
                    if(k === "country_id"){
						this.setState({
							countryId: v
						});
                    }else if(k === "keyword"){
                        this.setState({
                            [k]: v
                        });
                    }
                }
            });
        });
    };

    onChangeInput = (key, e) => {
        this.setState({
            [key]: e.target.value
        });
    };

    onFilter = () => {
        let uri = this.props.getUrlInstance();

        if(this.state.keyword){
            uri.replaceParam('keyword', this.state.keyword);
        }else{
            uri.removeParam('keyword', this.state.keyword);
        }
        if(this.state.countryId) uri.replaceParam('country_id', this.state.countryId);
        uri.replaceParam('page', 1);

        this.props.pushQueryString(uri.getQueryString());
    };

    onSelect = (key, value) => {
        this.setState({
            [key]: value
        });
    };

    resetStateFilter = (cb = null) => {
        this.setState({
            keyword: "",
			countryId: "",
        }, cb)
    };

    clearFilter = () => {
        this.resetStateFilter(() => {
            this.props.pushQueryString("")
			this.setState({
				keyword: null,
				countryId: null
			})
        });
    };

    render() {
        const {keyword,countryId} = this.state;
        const {countries,loading} = this.props;
        const {t} = this.props;
        return (
            <div className="contact-filter filter">
                <Row gutter={24}>
                    <Col span={9} style={{fontWeight:600}}>{t("vendor.name_vendor")} / {t("vendor.code_vendor")}:</Col>
                    <Col span={9} style={{fontWeight:600}}>{t("vendor.country")}:</Col>
                    <Col span={9} className="mg-t-10">
                        <Search
                            placeholder={t("vendor.name_code_filter")}
                            onChange={this.onChangeInput.bind(this, 'keyword')}
                            onPressEnter={this.onFilter}
                            value={keyword}
                            className={"_input-search"}
                        />
                    </Col>
                    <Col span={9} className="mg-t-10">
                        <Select
                            style={{ width: "100%"}}
                            showSearch
                            placeholder={t("vendor.select_country")}
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            onSelect={this.onSelect.bind(this, 'countryId')}
                            value={countryId ? parseInt(countryId) : undefined}
                            notFoundContent={<Empty description={t("table.empty")} />}
                            loading={loading}
                            className={"_select-country"}
                        >
                            {countries.map((item, index) => (
                                <Option className={"_list-country"} key={index} value={item.id}>{item.name}</Option>
                            ))}
                        </Select>
                    </Col>
                    <Col span={6} className="mg-t-10">
                        <Button type="primary" style={{marginLeft:10}}  onClick={this.onFilter} className="_filter_button">{t("vendor.filter_button")}</Button>
                        <Button style={{marginLeft:10, fontWeight:600}} onClick={this.clearFilter} className="_clear_button">{t("vendor.clear_button")}</Button>
                    </Col>
                </Row>
            </div>
        );
    }
}

export {Filter}
