import React from 'react';
import {Button, Card, Modal, notification} from 'antd';
import OrderService from "../../../services/OrderService";

export class SyncM28 extends React.Component {

    static defaultProps = {
        order: {}
    }

    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    }

    handleSyncM28 = () => {
        const {order} = this.props;
        Modal.confirm({
            title: "Are you sure to do this action",
            onOk: () => {
                this.setState({loading: true});
                OrderService.syncM28(order.id, (error) => {
                    this.setState({loading: false})
                    if (!error) {
                        notification.success({
                            message: 'Sync successfully'
                        })
                    }
                    else {
                        notification.error({
                            message: error.message
                        })
                    }
                })
            }
        })
    }

    render() {
        const {loading} = this.state;
        return (
            <Card
                className={'card-block warehouse-note'}
                title={'Sync M28'}
            >
                <Button loading={loading} disabled={loading} type={'primary'} onClick={this.handleSyncM28}>Sync M28</Button>
            </Card>
        )
    }
}