import React from 'react';
import * as _ from 'lodash';
import {Modal, Form, Input, Select, Spin, Empty, notification} from "antd";
import CampaignService from "../../services/CampaignService";
import {arrayConcat, doCallBack} from "../../utils/helper";
import ContactService from "../../services/ContactService";
import {PopupConfirmCreateContact} from "./PopupConfirmCreateContact";

class PopupCreateContact extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            loadingCampaign: false,
            campaigns: [],
            initialCampaigns: [],
            isShowPopupConfirm: false,
            dataContact: {}
        };
    }

    fetchCampaign = (filter = {}, cb) => {
        this.setState({loadingCampaign: true});
        const {vendor} = this.props;
        let defaultFilter = {
            order: ['id DESC'],
            vendor_id: vendor.id,
            product_active: true,
            have_ad_default: true
        };

        filter = _.mergeWith(defaultFilter, filter, arrayConcat);
        CampaignService.getCampaigns(filter, (error, response) => {
            this.setState({loadingCampaign: false});
            if(!error) {
                this.setState({campaigns: response.list});
            }
            doCallBack(cb, [error, response]);
        });
    };

    componentDidMount = () => {
        this.fetchCampaign({}, (error, response) => {
            if(!error) {
                this.setState({initialCampaigns: response.list});
            }
        })
    };

    onSubmit = () => {
        const {form, toggle, t, fetchContact} = this.props;
        form.validateFields((error, values) => {
            if(!error) {
                values.confirmCreate = false;
                this.setState({loading: true});
                ContactService.createPhoneContacts(values, (error) => {
                    this.setState({loading: false});
                    if(!error) {
                        notification.success({message: t('message.success')});
                        toggle();
                        if(this.props.history.location.search){
                            this.props.history.replace('/contacts');
                        }else{
                            fetchContact();
                        }
                    } else {
                        if(error.statusCode === 409) {
                            // Show list confirm
                            this.setState({
                                isShowPopupConfirm: true,
                                dataContact: values,
                                dataExisted: error.details
                            });
                        } else {
                            let message = error.message;
                            if (error.code === 'INVALID_PHONE') {
                                message = t('contact.invalid_phone');
                            } else if(error.code === 'EXITS') {
                                message = t('contact.existed');
                            }
                            notification.error({message: message});
                        }
                    }
                });
            }
        });
    };

    onCancel = () => {
        const {form, toggle} = this.props;
        form.resetFields();
        toggle();
    };

    onSearchCampaign = (keyword) => {
        this.fetchCampaign({name: keyword});
    };

    onFocusCampaign = () => {
        this.setState({
            campaigns: this.state.initialCampaigns
        });
    };

    togglePopupConfirm = () => {
        this.setState({isShowPopupConfirm: !this.state.isShowPopupConfirm});
    };

    onConfirmed = (error, response) => {
        const {t, fetchContact, toggle} = this.props;
        if(!error) {
            notification.success({message: t('message.success')});
            toggle();
            this.togglePopupConfirm();
            fetchContact();
        } else {
            notification.error({message: error.message});
        }
    };

    render() {
        const {t, visible} = this.props;
        const {getFieldDecorator} = this.props.form;
        const {loadingCampaign, loading, campaigns, isShowPopupConfirm, dataContact, dataExisted} = this.state;
        return (
            <>
            <Modal
                className="_modal_create_contact"
                title={t('contact.add_contact')}
                okText={t('button.save')}
                cancelText={t('button.cancel')}
                onOk={this.onSubmit}
                onCancel={this.onCancel}
                visible={visible}
                okButtonProps={{
                    loading: loading,
                    disabled: loading,
                    className: "_btn_submit_create_contact"
                }}
                cancelButtonProps={{
                    loading: loading,
                    disabled: loading,
                    className: "_btn_cancel_create_contact"
                }}
            >
                <Form layout="vertical">
                    <Form.Item label={t('contact.select_campaign')}>
                        {getFieldDecorator('campaignId', {
                            rules: [
                                {required: true, message: t('message.required')}
                            ]
                        })(
                            <Select
                                loading={loadingCampaign}
                                className={'_select_campaign_to_create_contact'}
                                showSearch
                                allowClear={true}
                                defaultActiveFirstOption={false}
                                placeholder={t('contact.select_campaign')}
                                notFoundContent={loadingCampaign ? <Spin size="small" /> : <Empty description={t("table.empty")}/>}
                                onSearch={this.onSearchCampaign}
                                onFocus={this.onFocusCampaign}
                                filterOption={false}
                            >
                                {campaigns.map((item) => (
                                    <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item label={t('contact.fullname')}>
                        {getFieldDecorator('customerName', {
                            rules: [
                                {required: true, message: t('message.required')},
                                {max: 160, message: t('error.length_160')}
                            ]
                        })(
                            <Input
                                className="_customer_name"
                                placeholder={t('contact.fullname')}
                                onPressEnter={this.onSubmit} />
                        )}
                    </Form.Item>
                    <Form.Item label={t('contact.phone')}>
                        {getFieldDecorator('customerPhone', {
                            rules: [
                                {required: true, message: t('message.required')}
                            ]
                        })(
                            <Input
                                className="_customer_phone"
                                placeholder={t('contact.phone')}
                                onPressEnter={this.onSubmit} />
                        )}
                    </Form.Item>
                    <Form.Item label={t('contact.note')}>
                        {getFieldDecorator('note', {
                            rules: [
                                {max: 255, message: t('error.length_255')}
                            ]
                        })(
                            <Input.TextArea
                                className="_customer_note"
                                placeholder={t('contact.note')}
                                onPressEnter={this.onSubmit} />
                        )}
                    </Form.Item>
                </Form>
            </Modal>

            {isShowPopupConfirm && (
                <PopupConfirmCreateContact
                    {...this.props}
                    visible={true}
                    contact={dataContact}
                    dataExisted={dataExisted}
                    onOk={this.onConfirmed}
                    onCancel={this.togglePopupConfirm}
                />
            )}

            </>
        )
    }
}

PopupCreateContact = Form.create()(PopupCreateContact);

export {PopupCreateContact};