import React, {Component} from 'react';
import {FormCreateProduct} from "./FormCreateProduct";
import * as _ from "lodash";

class PopupCreateProduct extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    handleCancel = () => {
        this.props.showPopupCreateProduct(false);
        this.formRef.closeTable();
        this.formRef.props.form.resetFields();
    };

    handleCreate = () => {
        let warehouseProductId;
        let listProductSku;
        const form = this.formRef.props.form;
        form.validateFields((err, values) => {
            if(!err) {
                if(values.hasOwnProperty("productInWarehouse")){
                    warehouseProductId = values.productInWarehouse;
                    listProductSku = _.values(_.omit(values, ['productInWarehouse']));
                }
                if(this.props.onCreateProduct(warehouseProductId,listProductSku)){
                    this.formRef.closeTable();
                    form.resetFields();
                }
            }
        });
    };

    saveFormRef = (formRef) => {
        this.formRef = formRef;
    };

    render() {
        return (
            <>
                <div>
                    <FormCreateProduct
                        {...this.props}
                        wrappedComponentRef={this.saveFormRef}
                        onCancel={this.handleCancel}
                        onCreate={this.handleCreate}
                    />
                </div>
            </>
        );
    }
}

export {PopupCreateProduct}