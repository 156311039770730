import React, {Component} from 'react';
import {Select} from 'antd';
import LocationService from "../../services/LocationService";

class SelectCity extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            value: undefined,
            cities: []
        }
    }

    static getDerivedStateFromProps(props, state) {
        let newState = {...state};
        if ('value' in props) {
            newState.value = props.value;
        }

        return newState;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ('provinceId' in this.props && this.props.provinceId !== prevProps.provinceId && this.props.provinceId > 0) {
            this.fetchCities();
        }
    }

    componentDidMount() {
        this.fetchCities();
    }

    fetchCities = () => {
        const {provinceId} = this.props;
        if (provinceId) {
            this.setState({
                loading: true,
                value: undefined
            });
            LocationService.getCity(provinceId, (error, response) => {
                this.setState({loading: false});
                if (!error) {
                    this.setState({
                        cities: response
                    });
                }
            });
        }
    };

    handleChange = (value) => {
        this.setState({value});
        this.props.onChange(value);
    };

    render() {
        const {cities, loading, value} = this.state;
        const {selectProps} = this.props;
        const {t} = this.props;
        return (
            <Select
                {...selectProps}
                allowClear={true}
                showSearch={true}
                loading={loading}
                value={value}
                onChange={this.handleChange}
                className={'width-100pc'}
                placeholder={t("placeholder.select_city")}
            >
                {cities.map(item => (
                    <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                ))}
            </Select>
        );
    }
}

SelectCity.defaultProps = {
    selectProps: {},
    onChange: (values) => {}
};

export default SelectCity;

